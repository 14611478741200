import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';

export const SnackbarCloseButton = (snackbarKey: string): JSX.Element => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      aria-label="dismiss"
      size="small"
      onClick={() => {
        closeSnackbar(snackbarKey);
      }}
    >
      <DoDisturbOnOutlinedIcon fontSize="inherit" />
    </IconButton>
  );
};
