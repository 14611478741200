import { useEffect, useState } from 'react';

import { ClassNames } from '@emotion/react';
import { CardContent } from '@mui/material';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useBalancesChildComponentsSlices from '../../Balances/Redux/CustomHooks/useBalancesChildComponentsSlices';
import {
  getAggregatedBalancesByMonth,
  getLabels,
} from '../../Balances/utils/table/helpers';
import BalanceChart from '../BalanceChart/BalanceChart';

import { BalanceChartCardPropsType } from './types';

const quantityTypeUppercase =
  process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum' ? 'Quantity' : 'Volume';

const BalanceChartCard = ({ openOrRetire }: BalanceChartCardPropsType) => {
  const [cardTitle, setCardTitle] = useState('');
  const [cardSubtitle, setCardSubtitle] = useState('');
  const { startDate, endDate, loading, balances, user, ozOrGramsQuantityUnit } =
    useBalancesChildComponentsSlices();

  const labels = getLabels(startDate, endDate);
  const balancesByMonthWithUnit = getAggregatedBalancesByMonth(
    balances,
    user,
    ozOrGramsQuantityUnit,
    startDate,
    endDate
  );

  useEffect(() => {
    const processCardInfos = () => {
      switch (openOrRetire) {
        case 'open':
          setCardTitle('Open');
          setCardSubtitle(
            `${quantityTypeUppercase} of Certificates still available`
          );
          break;
        case 'retire':
          setCardTitle('Retired');
          setCardSubtitle(
            `${quantityTypeUppercase} of Certificates retired for your benefits`
          );
          break;
        default:
          break;
      }
    };
    processCardInfos();
  }, [openOrRetire]);

  return (
    <ClassNames>
      {() => (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          style={{ paddingTop: 40, marginBottom: 40 }}
          data-testid={`balance-chart-card-id-${openOrRetire}`}
        >
          <Card style={{ marginTop: 0, height: 320, marginBottom: 44 }}>
            <CardContent>
              <Typography
                variant="subtitle2"
                style={{ marginTop: 10, position: 'relative', top: 236 }}
              >
                {cardTitle}
                {loading && (
                  <CircularProgress size={16} style={{ marginLeft: 10 }} />
                )}
              </Typography>
              <Typography
                variant="caption"
                style={{ position: 'relative', top: 236 }}
              >
                {cardSubtitle}
              </Typography>
            </CardContent>
          </Card>
          <BalanceChart
            balancesByMonthWithUnit={balancesByMonthWithUnit}
            labels={labels}
            openOrRetire={openOrRetire}
          />
        </Grid>
      )}
    </ClassNames>
  );
};

export default BalanceChartCard;
