import { DialogContent, Typography } from '@mui/material';
import { cancelCommentChanged } from 'actions';
import useCertificesSlicesCombiner from 'components/Certificates/Redux/Slices/useCertificatesSlicesCombiner';
import { useTranslation } from 'react-i18next';
import { TextValidator } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const RemoveCertificateDialogContent = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const {
    cancelComment,
    displayCancelCertificateFormInDialog,
    retiringCertificateId,
  } = useCertificesSlicesCombiner();

  const handleCancelCommentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(cancelCommentChanged(event.target.value));
  };

  return displayCancelCertificateFormInDialog ? (
    <DialogContent>
      <Typography variant="body2" gutterBottom>
        <TextValidator
          style={{ width: 300, marginTop: 20 }}
          label={t('Enter comment : ')}
          onChange={handleCancelCommentChange}
          name="remove-comment"
          value={cancelComment}
          validators={['required']}
          errorMessages={['comment is required']}
        />
      </Typography>
    </DialogContent>
  ) : (
    <DialogContent>
      <Typography variant="body2" gutterBottom>
        Please check if the data are Ok before proceeding
      </Typography>
      <Typography variant="body2" gutterBottom>
        Certificate id : {retiringCertificateId ? retiringCertificateId : ''}
      </Typography>
      <Typography variant="body2" gutterBottom>
        Comment : {cancelComment ? cancelComment : ''}
      </Typography>
    </DialogContent>
  );
};

export default RemoveCertificateDialogContent;
