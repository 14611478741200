import { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';

import {
  getM2MAppCredentials,
  deleteM2MApp,
  getUserStart,
  openSuccessSnack,
  openErrorSnack,
} from '../../actions';

export const M2MCredentials = ({
  client_id,
  user_id,
}: {
  client_id: string;
  user_id: string;
}): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const connectedUser = useSelector(
    (state: RootState) => state.loginReducer.user
  );
  const isAuthenticated = useSelector(
    (state: RootState) => state.loginReducer.isAuthenticated
  );

  const [clientSecret, setClientSecret] = useState('');
  const [loadingClientSecret, setLoadingClientSecret] = useState(false);
  const [loadingDeleteClient, setLoadingDeleteClient] = useState(false);

  const isSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, connectedUser);

  const deleteClient = async () => {
    if (window.confirm('Are you sure you want to delete this API ?')) {
      setLoadingDeleteClient(true);
      try {
        await deleteM2MApp(user_id, client_id);
        dispatch(openSuccessSnack('M2M App deleted'));
        dispatch(getUserStart(user_id.replace('auth0|', '')));
      } catch (error) {
        console.error(error);
        dispatch(openErrorSnack('Error while deleting M2M Application.'));
      }
      setLoadingDeleteClient(false);
    }
  };

  const revealClientSecret = async () => {
    setLoadingClientSecret(true);
    try {
      const m2mcredentials = await getM2MAppCredentials(user_id, client_id);
      setClientSecret(m2mcredentials.client_secret);
    } catch (error) {
      console.error(error);
      dispatch(openErrorSnack('Error while fetching client_secret.'));
    }
    setLoadingClientSecret(false);
  };

  const hideClientSecret = async () => {
    setClientSecret('');
  };

  return (
    <Grid container id={client_id} style={{ marginTop: 20 }}>
      <Grid item xs={10}>
        <Typography
          variant="body2"
          style={{
            color: 'rgba(0, 0, 0, 0.54)',
            fontWeight: 400,
            fontSize: '0.8125rem',
          }}
        >
          Credentials
        </Typography>
      </Grid>
      {isSuperAdmin && (
        <Grid item xs={2}>
          <IconButton
            aria-label="Delete client"
            style={{ marginLeft: 20 }}
            onClick={deleteClient}
            edge="end"
          >
            {!loadingDeleteClient && <DeleteOutlineIcon />}
            {loadingDeleteClient && <CircularProgress size={10} />}
          </IconButton>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          label="Client Id"
          id="client-id-adornment"
          sx={{ m: 1, width: '100ch' }}
          disabled
          defaultValue={client_id}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="copy client_id to clipboard"
                  onClick={() => {
                    navigator.clipboard.writeText(client_id);
                    dispatch(openSuccessSnack('client_id copied.'));
                  }}
                  edge="end"
                >
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Client secret"
          id="filled-adornment-client_secret"
          sx={{ m: 1, width: '100ch' }}
          disabled
          value={
            clientSecret
              ? clientSecret
              : '********************************************'
          }
          type={clientSecret ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={clientSecret ? hideClientSecret : revealClientSecret}
                  edge="end"
                >
                  {!loadingClientSecret && clientSecret ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                  {loadingClientSecret && <CircularProgress size={10} />}
                </IconButton>
                {clientSecret && (
                  <IconButton
                    aria-label="copy client_secret to clipboard"
                    style={{ marginLeft: 20 }}
                    onClick={() => {
                      navigator.clipboard.writeText(clientSecret);
                      dispatch(openSuccessSnack('client_secret copied.'));
                    }}
                    edge="end"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};
