/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CardHeader,
  CardContent,
  Grid,
} from '@mui/material';
import { archiveProductStart, closeActionCommentDialog } from 'actions';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';
import DOMPurify from 'dompurify';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Form } from 'react-router-dom';
import { withRouter } from 'utils/migrationHelper';

const Message = ({ message }) => {
  const cleanMessage = DOMPurify.sanitize(message);
  return (
    <DialogContentText dangerouslySetInnerHTML={{ __html: cleanMessage }} />
  );
};

const ArchiveCommentGroup = (props) => {
  const { open, currentProduct, closeActionCommentDialog } = props;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Dialog open={open}>
      <Formik
        initialValues={{
          comment: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(archiveProductStart(currentProduct, navigate, values));
        }}
        validate={(values) => {
          const errors = {};
          if (!values.comment) {
            errors.comment = 'Required';
          }
          return errors;
        }}
      >
        {({ submitForm, values, isSubmitting, errors, setFieldValue }) => {
          return (
            <>
              <DialogContent>
                <Field
                  style={{ width: 300 }}
                  component={TextField}
                  name="comment"
                  label="Archive Comment"
                  type="text"
                  onChange={(e) => {
                    setFieldValue('comment', e.target.value);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={closeActionCommentDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={submitForm}
                  variant="contained"
                  color="secondary"
                >
                  ARCHIVE
                </Button>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const mapStateToProps = ({ productsReducer, administrationReducer }) => {
  const { currentProduct, comment, closeActionCommentDialog } = productsReducer;
  const { actionDialogOpen } = administrationReducer;
  return {
    currentProduct,
    comment,
    closeActionCommentDialog,
    actionDialogOpen,
  };
};

const mapDispatchToProps = {
  closeActionCommentDialog,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArchiveCommentGroup)
);
