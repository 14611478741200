import { Box, Grid } from '@mui/material';
import { standardFormIsBeingFilledOut } from 'actions';
import { Field } from 'formik';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import SetTextField from '../Texts/SetTextField';

type DynamicDataFieldsPropsType = {
  index: number;
};

const DynamicDataFields = ({ index }: DynamicDataFieldsPropsType) => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      gap={1}
      style={{
        paddingBottom: 10,
      }}
    >
      <Grid item xs={3}>
        <Field
          style={{
            width: 200,
            marginTop: 20,
            marginLeft: 120,
          }}
          component={SetTextField}
          label={`attribute ${index + 1} table title`}
          name={`infoTexts[${index}].tableTitle`}
          type="text"
          formIsBeingFilledOutHandler={() =>
            dispatch(standardFormIsBeingFilledOut())
          }
        />
      </Grid>

      <Grid item xs={3}>
        <Field
          style={{ width: 200, marginTop: 20, marginLeft: 50 }}
          component={SetTextField}
          label={`attribute ${
            index + 1
          } source name in upload - attribute identifier`}
          name={`infoTexts[${index}].sourceName`}
          type="text"
          formIsBeingFilledOutHandler={() =>
            dispatch(standardFormIsBeingFilledOut())
          }
        />
      </Grid>

      <Grid item xs={3}>
        <Field
          style={{ width: 200, marginTop: 20 }}
          component={SetTextField}
          label={`attribute ${index + 1} unit`}
          name={`infoTexts[${index}].unit`}
          type="text"
          formIsBeingFilledOutHandler={() =>
            dispatch(standardFormIsBeingFilledOut())
          }
        />
      </Grid>
    </Box>
  );
};

export default DynamicDataFields;
