import CreateIcon from '@mui/icons-material/Create';
import { Fab } from '@mui/material';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { useNavigate } from 'react-router';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';

const UpdateButton = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user, currentProduct } = useProductSlicesCombiner();

  if (
    !(
      checkUserIsSuperAdmin(isAuthenticated, user) ||
      checkUserHasRight(isAuthenticated, user, RightReferences.PRODUCTS, [
        CrudPermissions.UPDATE,
      ])
    )
  )
    return <></>;
  return (
    <Fab
      data-testid="update-btn-id"
      color="primary"
      aria-label="add"
      style={{
        margin: 0,
        bottom: 'auto',
        right: 48,
        top: 85,
        left: 'auto',
        position: 'fixed',
      }}
      onClick={() => {
        currentProduct && navigate('/products/update/' + currentProduct._id);
      }}
    >
      <CreateIcon />
    </Fab>
  );
};

export default UpdateButton;
