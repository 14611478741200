import { Box, Switch, Typography } from '@mui/material';
type AddAttributeButtonPropsType = {
  onFormJsonButtonClick: () => void;
  jsonActivated: boolean;
};
const FormJsonSwitch = ({
  onFormJsonButtonClick,
  jsonActivated,
}: AddAttributeButtonPropsType) => {
  const label = {
    inputProps: {
      'aria-label': jsonActivated ? 'Form view' : 'JSON view',
    },
  };
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      }}
    >
      <Switch
        {...label}
        size="small"
        checked={jsonActivated}
        onChange={onFormJsonButtonClick}
      />
      <Typography variant="subtitle2" color="grey" style={{ marginTop: 2 }}>
        JSON view
      </Typography>
    </Box>
  );
};

export default FormJsonSwitch;
