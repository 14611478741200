import moment from 'moment';
import numeral from 'numeral';

import { Units } from '../enums/Units';

import * as convert from './convert';
export const convertWhInkWh = convert.convertWhInkWh;

//Products helpers

let displayPlatinumUI = process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';

export const getProductOrganizationName = (params) => {
  let organization = params?.row?.organization;
  return organization?.name;
};

export const getProductProductTypeName = (params) => {
  let standard = params?.row?.standard;
  return standard?.productType?.name;
};

//Users Helpers Start
export const getFullname = (params) => {
  let user_metadata = params?.row?.user_metadata;

  return user_metadata?.firstname + ' ' + user_metadata?.lastname;
};

export const getScheduledTasksMonitoringName = (params) => {
  let scheduledTasksMonitoringName = params?.row?.name;

  return scheduledTasksMonitoringName;
};

export const getScheduledTasksMonitoringSource = (params) => {
  let scheduledTasksMonitoringSource = params?.row?.source;

  return scheduledTasksMonitoringSource;
};

export const getScheduledTasksMonitoringTaskType = (params) => {
  let scheduledTasksMonitoringTaskType = params?.row?.task_type;

  return scheduledTasksMonitoringTaskType;
};

export const getScheduledTasksMonitoringStatusType = (params) => {
  let scheduledTasksMonitoringStatusType = params?.row?.statusType;

  return scheduledTasksMonitoringStatusType;
};

export const getScheduledTasksMonitoringLastUpdateDate = (params) => {
  let scheduledTasksMonitoringLastUpdateDate = params?.row?.lastUpdateDate;

  return scheduledTasksMonitoringLastUpdateDate;
};

export const getOrganizationName = (params) => {
  let user_metadata = params?.row?.user_metadata;
  return user_metadata?.organization?.name;
};

//Balances Helpers Start
export const getOriginName = (params) => {
  let _origin = params?.row?._origin;
  return _origin.displayedName;
};

export const getProductId = (params) => {
  let _origin = params?.row?._origin;
  return _origin.productId;
};

export const getFromName = (params) => {
  let _from = params?.row?._from;
  return _from.name;
};

export const getIssuerName = (params) => {
  let _origin = params?.row?._origin;
  return _origin.organization.name;
};

export const getMonthAndYear = (params) => {
  let _year = params?.row?._year;
  let _month = params?.row?._month;
  return _year + '-' + _month;
};

export const getDateFromMonthAndYear = (params) => {
  let _year = params?.row?._year;
  let _month = params?.row?._month;
  return moment(_year + '-' + _month, 'YYYY-MM').toDate();
};

export const getDateFromMonthAndYearText = (params) => {
  let _year = params?.row?._year;
  let _month = params?.row?._month;
  let value = moment(_year + '-' + _month, 'YYYY-MM').toDate();

  let valueToRender = moment(value).format('MMM-YYYY');
  if (displayPlatinumUI) {
    let year = moment(value).format('YY');
    let month = parseInt(moment(value).format('M'));
    let halfYear = 1;
    if (month > 6) halfYear = 2;
    valueToRender = `${halfYear}H${year}`;
  }

  return valueToRender;
};

export const getOriginUnit = (params) => {
  let _origin = params?.row?._origin;
  return _origin.unit;
};

export const getQuantity = (params) => {
  let unit = params?.row?._origin?.unit;
  let convertedInUnitValue =
    unit === Units.MMBTU ||
    unit === Units.GRAMS ||
    unit === Units.KG ||
    unit === Units.VOYAGES ||
    unit === Units.TONS
      ? params.value
      : convertWhInkWh(params.value);
  return convertedInUnitValue;
};

export const getAllocatedQuantity = (params) => {
  let unit = params?.row?._origin?.unit;
  let convertedInUnitValue =
    unit === Units.MMBTU ||
    unit === Units.GRAMS ||
    unit === Units.KG ||
    unit === Units.VOYAGES ||
    unit === Units.TONS
      ? params.value
      : convertWhInkWh(params.value);
  return convertedInUnitValue;
};

export const quantityRenderer = (params) => {
  return numeral(params.value).format('0,0.[00]');
};
//Balance Helpers End

//Certificates helpers starts
export const getCertificateOriginName = (params) => {
  let origin = params?.row?.origin;
  return origin && origin?.displayedName ? origin.displayedName : '';
};
export const getCertificateProductId = (params) => {
  let _origin = params?.row?.origin;
  return _origin?.productId;
};

export const getTransactionUser = (params) => {
  let _user = params?.row?.user;
  return _user?.username ? _user.username : '-';
};

export const getCertificateInfo = (params, pos) => {
  let prop = 'info' + pos;
  let origin = params?.row?.origin;
  if (!origin) origin = params?.row?._origin;

  const selectedStandardValueEntry =
    origin && origin?.standardValues?.find(({ key }) => key === prop)?.value;

  let infoX = selectedStandardValueEntry || '';

  let infoXAsText = infoX;

  if (Array.isArray(infoX)) {
    infoXAsText = '';
    infoX.map((item, index) => {
      infoXAsText += index === infoX.length - 1 ? item : item + ', ';
    });
  }

  return infoXAsText;
};

export const getCertificateClientName = (params) => {
  let client = params?.row?.client;
  return client && client.name ? client.name : '';
};

export const getCertificateIssuerName = (params) => {
  let organization = params?.row?.issuer;
  return organization && organization.name ? organization.name : '';
};

export const getCertificateFromName = (params) => {
  let organization = params?.row?.from;
  return organization && organization.name ? organization.name : '';
};

export const getCertificateToName = (params) => {
  let organization = params?.row?.to;
  return organization && organization.name ? organization.name : '';
};

export const getCertificateMonthAndYear = (params) => {
  let _year = params?.row?.year;
  let _month = params?.row?.month;
  return _year + '-' + _month;
};

export const getCertificateDateFromMonthAndYear = (params) => {
  let _year = params?.row?.year;
  let _month = params?.row?.month;
  return moment(_year + '-' + _month, 'YYYY-MM').toDate();
};

export const getCertificateDateFromMonthAndYearText = (params) => {
  let _year = params?.row?.year;
  let _month = params?.row?.month;
  let value = moment(_year + '-' + _month, 'YYYY-MM').toDate();

  let valueToRender = moment(value).format('MMM-YYYY');
  if (displayPlatinumUI) {
    let year = moment(value).format('YY');
    let month = parseInt(moment(value).format('M'));
    let halfYear = 1;
    if (month > 6) halfYear = 2;
    valueToRender = `${halfYear}H${year}`;
  }

  return valueToRender;
};

export const monthYearComparator = (v1, v2) => {
  let v1Date;
  let v2Date;
  //"MMM-YYYY" on gas
  if (displayPlatinumUI) {
    //"HxYYYY" on platinum
    let v1Year = v1.slice(-2);
    let v1Semester = v1.charAt(1);
    let v1Month = v1Semester > 1 ? 7 : 1;
    v1Date = moment(v1Month + '-' + v1Year, 'M-Y').toDate();

    let v2Year = v2.slice(-2);
    let v2Semester = v2.charAt(1);
    let v2Month = v2Semester > 1 ? 7 : 1;
    v2Date = moment(v2Month + '-' + v2Year, 'M-YY').toDate();
  } else {
    v1Date = moment(v1, 'MMM-YYYY').toDate();
    v2Date = moment(v2, 'MMM-YYYY').toDate();
  }
  return v1Date - v2Date;
};

export const getCertificateOriginUnit = (params) => {
  let unit = params?.row?.origin?.unit;
  return unit;
};

export const getCertificateQuantity = (params) => {
  let unit = params?.row?.origin?.unit;
  let convertedInUnitValue =
    unit === Units.MMBTU ||
    unit === Units.GRAMS ||
    unit === Units.KG ||
    unit === Units.VOYAGES ||
    unit === Units.TONS
      ? params.value
      : convertWhInkWh(params.value);
  return convertedInUnitValue;
};

export const certificateQuantityRenderer = (params) => {
  return numeral(params.value).format('0,0');
};
//Certificates helpers end

//Transactions helpers start
export const getTransactionTypeName = (params) => {
  let type = params?.row?.type;
  let typeDisplayed = '';

  switch (type) {
    case 'transfer_certificate':
      typeDisplayed = 'Transfer certificates';
      break;
    case 'issue_certificate':
      typeDisplayed = 'Issue certificates';
      break;
    case 'allocate_certificate':
      typeDisplayed = 'Retire certificates';
      break;
    case 'cancel_certificate':
      typeDisplayed = 'Cancel certificates';
      break;
    case 'increase_balance':
      typeDisplayed = 'Increase balance';
      break;
    case 'decrease_balance':
      typeDisplayed = 'Decrease balance';
      break;
    default:
      typeDisplayed = '';
  }

  return typeDisplayed;
};
//Transactions helpers end

//Standards helpers start

export const getStandardProductTypeName = (params) => {
  let productType = params?.row?.productType;
  return productType?.name;
};
//Standards helpers end
