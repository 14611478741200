import { LinearProgress } from '@mui/material';
import { DataGridPro as Table } from '@mui/x-data-grid-pro';
import useDynamicLists from 'components/DynamicList/Redux/CustomHooks/useDynamicLists';
import useDynamicListsSlicesCombiner from 'components/DynamicList/Redux/Slices/useDynamicListsSlicesCombiner';
import getDynamicListsColumns from 'components/DynamicList/utils/table/columns';
import getRows from 'components/DynamicList/utils/table/rows';
import {
  FILTER_MODE_CLIENT_SIDE,
  PAGINATION_MODE_SERVER_SIDE,
} from 'components/Transactions/utils/constants';
import { useNavigate } from 'react-router';
import './Styles/index.css';

const DynamicListsTable = (): JSX.Element => {
  const navigate = useNavigate();
  useDynamicLists();

  const { loading, dynamicLists } = useDynamicListsSlicesCombiner();

  return (
    <Table
      disableSelectionOnClick
      loading={loading}
      onRowClick={(rowData: any) => {
        const _id = rowData.row._id;
        navigate('/lists/update/' + _id);
      }}
      pagination
      paginationMode={PAGINATION_MODE_SERVER_SIDE}
      filterMode={FILTER_MODE_CLIENT_SIDE}
      rows={getRows(dynamicLists)}
      columns={getDynamicListsColumns()}
      components={{
        LoadingOverlay: LinearProgress,
      }}
    />
  );
};

export default DynamicListsTable;
