import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const useOperationsSlices = () => {
  const { operations, loading, allOperations, totalCount } = useSelector(
    (state: RootState) => state.operationsReducer
  );

  return {
    operations,
    loading,
    allOperations,
    totalCount,
  };
};

export default useOperationsSlices;
