import { Collapse, FormControl, Grid } from '@mui/material';
import { RightReferences } from 'enums/RightReferences';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Right } from 'types/Right';
import { RightsListElement } from 'types/RightsListElement';

import { RightsCollapsedSectionTitle } from './RightsCollapsedSectionTitle';
import { RightsFormGroup } from './RightsFormGroup';
import { RightsSectionPropsType } from './types';

export const RightsSimpleSection = ({
  category,
  expanded,
  capitalizeFirstLetter,
  handleExpandClick,
  checkIfConnectedUserHasRight,
}: RightsSectionPropsType): JSX.Element => {
  const allUserRights = useSelector(
    (state: RootState) => state.usersReducer.rights
  );
  const selectedUserRights = useSelector(
    (state: RootState) => state.usersReducer.selectedRights
  );

  return (
    <Grid container id={category}>
      <Grid container>
        <Grid item xs={2}>
          <RightsCollapsedSectionTitle
            category={category}
            expanded={expanded}
            capitalizeFirstLetter={capitalizeFirstLetter}
            handleExpandClick={handleExpandClick}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={0.5} />
        <Grid item xs={11.5}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <RightsFormGroup
                data={
                  allUserRights &&
                  allUserRights.filter(
                    (right: Right) => right.category === category
                  )
                }
                capitalizeFirstLetter={(element: Right) => {
                  return capitalizeFirstLetter(
                    element.ref === RightReferences.ADMINISTRATION
                      ? 'administration'
                      : element.ref
                  );
                }}
                verifyIfChecked={(element: Right) => {
                  return Boolean(
                    selectedUserRights &&
                      selectedUserRights.find((r: RightsListElement) => {
                        return r?.type && r.type._id === element._id;
                      })
                  );
                }}
                verifyIfCurrentUserHasRight={(element: Right) => {
                  return checkIfConnectedUserHasRight(element);
                }}
              />
            </FormControl>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );
};
