import useFormStandardsSlicesCombiner from 'components/FormStandards/Redux/Slices/Combiner/useFormStandardsCombiner';
import GenericField from 'components/GenericComponents/Grid/Field/Field/GenericField';

const ProductTypeField = () => {
  const { productTypes } = useFormStandardsSlicesCombiner();
  return (
    <GenericField
      name="productType"
      label="Product Type"
      select
      items={productTypes}
      errorKey="productType"
    />
  );
};

export default ProductTypeField;
