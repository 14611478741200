import { useState } from 'react';

import useFormStandardsSlicesCombiner from 'components/FormStandards/Redux/Slices/Combiner/useFormStandardsCombiner';
import { getStandardInfoTexts } from 'components/FormStandards/utils/utils';
import { enums } from 'enums';

import FormikJsonInputsContent from './FormikJsonInputsContent';
import { StandardFormik } from './Generic/StandardFormik';

type FormikStandardContentTypeProps = {
  props: any;
};
const FormikStandardContent = ({ props }: FormikStandardContentTypeProps) => {
  const { currentStandard } = useFormStandardsSlicesCombiner();
  const params = props.router?.params;

  const updatingStandard = Boolean(params?.standardId) || false;

  const initialValues = {
    name: updatingStandard ? currentStandard.name : '',
    standardType: updatingStandard ? currentStandard.standardType : '',
    productType: updatingStandard ? currentStandard.productType?._id : '',
    infoTexts: updatingStandard
      ? getStandardInfoTexts(currentStandard)
      : [
          {
            infoProp: 'info0',
            infoStandardText: '',
            infoStandardDesc: '',
            type: enums.InfoTypes.TEXT,
            isNew: true,
          },
        ],
    valueToCreate: '',
    idToCreate: '',
  };

  const [useJsonView, setUseJsonView] = useState(false);

  const toggleJsonView = () => {
    setUseJsonView(!useJsonView);
  };

  return (
    <StandardFormik initialValues={initialValues} params={params}>
      {({ values, setFieldValue }) => (
        <FormikJsonInputsContent
          props={{
            ...props,
            initialValues,
            toggleJsonView,
            values,
            setFieldValue,
            useJsonView,
          }}
        />
      )}
    </StandardFormik>
  );
};

export default FormikStandardContent;
