import { useEffect, useState } from 'react';

import { displayPlatinumUI } from 'components/CreateOrUpdateProduct/utils/utils';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from 'components/Transactions/utils/constants';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';

import {
  getDefaultBalancesPaginationThreshold,
  getOrganizations,
  getProductTypesStart,
  getUniqueStandardsFromBalancesStart,
  setBalanceProductType,
  setBalanceStandard,
  setBalanceStandardType,
} from '../../../../actions';

import { getBalancesByStandardAndStartDateAndEndDates } from './helpers/helpers';
import { UseBalancesType } from './types';
import useBalancesChildComponentsSlices from './useBalancesChildComponentsSlices';

const useBalances = (): UseBalancesType => {
  //Redux hooks
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [page, setPage] = useState<number>(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const {
    isAuthenticated,
    user,
    selectedStandardId,
    selectedStandardType,
    selectedProductTypeId,
    loading,
    organizations,
    customButtonPressed,
    startDate,
    endDate,
  } = useBalancesChildComponentsSlices();

  const handlePageChange = (page: any) => {
    setPage(page);
  };
  const handlePageSizeChange = (size: any) => {
    setPageSize(size);
    setPage(0);
  };

  useEffect(() => {
    const initDefaultPaginationThreshold = async () => {
      if (!isAuthenticated || !user) {
        navigate('/');
      } else {
        await dispatch(getDefaultBalancesPaginationThreshold());
      }
    };
    initDefaultPaginationThreshold();
  }, []);

  useEffect(() => {
    const initStandards = async () => {
      await dispatch(getUniqueStandardsFromBalancesStart());
    };
    initStandards();
  }, []);

  // Reset pageSize when selectedStandardId changes
  useEffect(() => {
    setPageSize(DEFAULT_PAGE_SIZE);
    setPage(DEFAULT_PAGE_NUMBER);
  }, [selectedStandardId]);

  useEffect(() => {
    const initComponent = async () => {
      if (!isAuthenticated || !user) {
        navigate('/');
      } else {
        dispatch(getOrganizations());
        // block balances rendering if location state is available (if user is getting back to this page from the organizations form)
        if (location?.state?.blockBalancesRendering) {
          return;
        }
        if (displayPlatinumUI) dispatch(getProductTypesStart());
        if (selectedStandardId)
          dispatch(setBalanceStandard(selectedStandardId));
        if (selectedStandardType)
          dispatch(setBalanceStandardType(selectedStandardType));
        if (selectedProductTypeId)
          dispatch(setBalanceProductType(selectedProductTypeId));
      }
    };
    initComponent();
  }, []);

  useEffect(() => {
    const initComponent = async () => {
      if (!isAuthenticated || !user) {
        navigate('/');
      } else {
        dispatch(
          getBalancesByStandardAndStartDateAndEndDates(
            selectedStandardId as string,
            pageSize as number,
            customButtonPressed,
            startDate,
            endDate
          )
        );
      }
    };
    initComponent();
  }, [selectedStandardId, pageSize]);

  return {
    organizations,
    loading,
    page,
    pageSize,
    handlePageChange,
    handlePageSizeChange,
  };
};

export default useBalances;
