import { openSuccessSnack } from 'actions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import copyIcon from '../../../../../../../assets/images/icons/copy.png';

type CopyButtonProps = {
  text: string;
  message: string;
};

const CopyButton = ({ text, message }: CopyButtonProps) => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => dispatch(openSuccessSnack(message))}
    >
      <img
        src={copyIcon}
        alt={'copy'}
        title={'copy'}
        style={{ width: 16, height: 16, cursor: 'pointer' }}
      />
    </CopyToClipboard>
  );
};

export default CopyButton;
