import { useContext } from 'react';

import { EnumOperationTransferOrRetire } from 'components/Balances/CustomComponents/Dialogs/RetireDialog/enums';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import { FieldAttributes } from 'formik';
import { TextField } from 'formik-mui';

import { checkUserIsSuperAdmin } from '../../../../../../../utils/user/checkAdmin/CheckAdmin';
import GenericSelectField from '../../../../../../GenericComponents/Forms/Formik/Fields/Select/BalancesGenericSelectField';
import GenericConditionalMenuItem from '../../../../../../GenericComponents/Forms/MenuItems/GenericConditionalMenuItem';
import useBalancesChildComponentsSlices from '../../../../../Redux/CustomHooks/useBalancesChildComponentsSlices';
import OrganizationsContext, {
  OrganizationContextType,
} from '../../../../Dialogs/CombinedDialogs/OrganizationsContext';

import { IOrganizationSelectFieldProps } from './types';

type SelectOrganizationListPropsType = {
  label?: string;
  type?: string;
};
const SelectOrganizationsList = ({
  label,
  type,
}: SelectOrganizationListPropsType) => {
  const {
    organizationsInAllocateMenu,
    organizationsInTransferMenu,
  }: OrganizationContextType = useContext(OrganizationsContext);

  const {
    isAuthenticated,
    user,
    organizationsInAllocateMenuFiltredForSuperAdmin,
    organizationsInTransferMenuFiltredForSuperAdmin,
  }: UseBalancesChildComponentsSlicesType = useBalancesChildComponentsSlices();

  const organizationSelectFieldProps: FieldAttributes<IOrganizationSelectFieldProps> =
    {
      label: label as string,
      component: TextField,
      type: 'text',
      name: 'organization',
      select: true,
      variant: 'standard',
      margin: 'normal',
      style: { width: 390 },
      helperText: '',
      InputLabelProps: {
        shrink: true,
      },
    };

  const organizationsInMenuRender =
    type === EnumOperationTransferOrRetire.RETIRE
      ? organizationsInAllocateMenu
      : organizationsInTransferMenu;

  const organizationsInMenuFiltredForSuperAdmin =
    type === EnumOperationTransferOrRetire.RETIRE
      ? organizationsInAllocateMenuFiltredForSuperAdmin
      : organizationsInTransferMenuFiltredForSuperAdmin;

  return (
    <GenericSelectField customFieldProps={organizationSelectFieldProps}>
      {GenericConditionalMenuItem({
        condition: checkUserIsSuperAdmin(isAuthenticated, user),
        iterator: organizationsInMenuFiltredForSuperAdmin,
      })}
      {GenericConditionalMenuItem({
        condition: !checkUserIsSuperAdmin(isAuthenticated, user),
        iterator: organizationsInMenuRender,
      })}
    </GenericSelectField>
  );
};

export default SelectOrganizationsList;
