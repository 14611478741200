/* eslint-disable react/no-unknown-property */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Typography from '@mui/material/Typography';

import addFileIcon from '../../../../../assets/images/icons/add-file.png';
import closeIcon from '../../../../../assets/images/icons/close.png';

const UploadTitle = (props: any) => {
  return (
    <div css={containerStyle}>
      <div css={iconAndTitleContainerStyle}>
        <img src={addFileIcon} css={addImgStyle} alt="" />
        <Typography variant="body2" display="block" style={{ fontSize: 20 }}>
          Upload file items
        </Typography>
      </div>
      <img
        data-testid="close-upload-dialog"
        src={closeIcon}
        css={closeIconStyle}
        alt="Close"
        onClick={props.handleClose}
      />
    </div>
  );
};

const containerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: 30,
  paddingBottom: 10,
  paddingLeft: 10,
  paddingRight: 10,
});

const iconAndTitleContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'flex-start',
  alignItems: 'center',
  justifyContent: 'center',
});

const addImgStyle = css({
  height: 22,
  width: 22,
  marginRight: 5,
  paddingLeft: 10,
  paddingRight: 10,
});

const closeIconStyle = css({
  height: 24,
  width: 24,
  marginRight: 10,
  cursor: 'pointer',
});

export default UploadTitle;
