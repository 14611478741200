import {
  displayNotificationFutureIssuanceFormInDialog,
  notifyFutureIssueStart,
} from 'actions';
import GenericTypography from 'components/Administration/Generic/Form/Labels/GenericTypography';
import { formatMonthYear } from 'components/Administration/Generic/Form/utils/utils';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';

import IssuanceList from '../../../../../../../CustomComponents/IssueCertificateInBulkForm/IssuanceList';
import { FormFieldsConfigType } from '../../../../../utils/types/formFieldsTypes';
import { FooterModalPropsType } from '../../../../../utils/types/types';

const useIssuanceEmailNotificationConfirmationModalConfig = () => {
  const {
    notifyFutureIssuanceData,
    notificationFutureIssuanceList,
    loadingNotificationFutureIssue,
  } = useAdministrationSlicesCombiner();

  //modal components
  const modalHeader = {
    title: '',
  };
  const modalFooter: FooterModalPropsType = {
    cancelLabel: 'Back',
    submitLabel: 'Send notification',
    onCancelHandler: displayNotificationFutureIssuanceFormInDialog,
    onSubmitHandler: notifyFutureIssueStart(
      notificationFutureIssuanceList,
      notifyFutureIssuanceData._year,
      notifyFutureIssuanceData._month,
      notifyFutureIssuanceData._organization
    ),
    displaySubmitBtnElementCondition:
      notificationFutureIssuanceList?.length > 0,
    loading: loadingNotificationFutureIssue,
  };
  const formFieldsConfig: FormFieldsConfigType = [
    [
      {
        component: GenericTypography,
        name: 'Please check if the data are Ok before sending the notification email',
        styleGrid: { marginBottom: 10 },
        styleLabel: { paddingBottom: 10 },
        size: 12,
      },
      {
        component: GenericTypography,
        name: 'Organization',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${notifyFutureIssuanceData._organization.name}`,
      },
      {
        component: GenericTypography,
        name: 'Period',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${formatMonthYear(
          notifyFutureIssuanceData._year + '-' + notifyFutureIssuanceData._month
        )}`,
      },
      { component: IssuanceList, issuanceList: notificationFutureIssuanceList },
    ],
  ];
  return {
    header: modalHeader,
    content: formFieldsConfig,
    footer: modalFooter,
  };
};

export default useIssuanceEmailNotificationConfirmationModalConfig;
