import GenericModalTitle from 'components/Administration/Generic/Modal/Title/GenericModalTitle';

import uploadIcon from '../../../../../../../../assets/images/icons/upload.png';

import ModalContentUploadConfirmationDataForm from './Form/Content/ModalContentUploadConfirmationDataForm';
import ModalFooterUploadConfirmationDataForm from './Form/Footer/ModalFooterUploadConfirmationDataForm';

const UploadConfirmationDataForm = () => {
  return (
    <>
      <GenericModalTitle
        title={'Upload volume data for multiple products and periods'}
        icon={uploadIcon}
      />
      <ModalContentUploadConfirmationDataForm />
      <ModalFooterUploadConfirmationDataForm />
    </>
  );
};

export default UploadConfirmationDataForm;
