/* eslint-disable */

import React from 'react';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import { CardHeader, CardContent } from '@mui/material';

import { DataGridPro as XGrid } from '@mui/x-data-grid-pro';
import { CommodityTypes } from '../../enums/CommodityTypes';
import {
  getProductOrganizationName,
  getProductProductTypeName,
} from '../../utils/datagridHelpers';
import gasIcon from '../../assets/images/icons/gasRig.png';
import platinumIcon from '../../assets/images/icons/platinum-ore.png';

let productIcon =
  process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum'
    ? platinumIcon
    : gasIcon;

let OrganizationProductsListComponent = ({
  isSuperAdmin,
  organizationProducts,
  loading,
}) => {
  let navigate = useNavigate();
  const dataGridRows = organizationProducts ? organizationProducts : [];
  let dataGridColumns = [
    {
      field: 'displayedName',
      headerName: 'Product',
      editable: false,
      width: 200,
    },
    {
      field: 'productId',
      headerName: 'Product Id',
      editable: false,
      width: 200,
    },
  ];

  if (isSuperAdmin) {
    dataGridColumns = [
      {
        field: 'displayedName',
        headerName: 'Product',
        editable: false,
        width: 200,
      },
      {
        field: 'productId',
        headerName: 'Product Id',
        editable: false,
        width: 200,
      },
      {
        field: '_productTypeName',
        headerName: CommodityTypes.upperCase,
        editable: false,
        width: 300,
        valueGetter: getProductProductTypeName,
      },
      {
        field: '_organizationName',
        headerName: 'Organization',
        editable: false,
        width: 300,
        valueGetter: getProductOrganizationName,
      },
    ];
  }

  return (
    <Card>
      <CardHeader
        avatar={<img src={productIcon} style={{ maxHeight: 20 }} />}
        title={'Products'}
      />
      <CardContent>
        <div style={{ height: 300, padding: 20 }}>
          <XGrid
            pagination
            disableSelectionOnClick={true}
            loading={loading}
            rows={dataGridRows}
            columns={dataGridColumns}
            onRowClick={(rowData) => {
              let _id = rowData.row._id;
              navigate('/products/read/' + _id);
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default OrganizationProductsListComponent;
