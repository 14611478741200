let initialInfoTexts = [];
for (let index = 1; index < 12; index++) {
  initialInfoTexts.push({
    infoProp: 'info' + index,
    infoWellText: '',
    infoWellDesc: '',
    type: 'text',
  });
}

const INITIAL_STATE = {
  wells: [],
  well: null,
  loadingWells: false,
  loadingWell: false,
  removeListItemDialogOpen: false,
  createListItemDialogOpen: false,
  listItemData: undefined,
  listItemlength: undefined,
  currentWell: {
    name: '',
    wellType: '',
    productType: null,
    infoTexts: initialInfoTexts,
  },
};

const wellsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_WELLS_START':
      return { ...state, loadingWells: true };

    case 'OPEN_LIST_ITEM_DIALOG':
      return {
        ...state,
        listItemDialogOpen: true,
        listItemData: action.payload,
      };

    case 'CLOSE_LIST_ITEM_DIALOG':
      return { ...state, listItemDialogOpen: false, listItemData: undefined };

    case 'OPEN_CREATE_LIST_ITEM_DIALOG':
      return {
        ...state,
        createListItemDialogOpen: true,
        listItemlength: action.payload,
      };

    case 'CLOSE_CREATE_LIST_ITEM_DIALOG':
      return {
        ...state,
        createListItemDialogOpen: false,
        listItemData: undefined,
      };

    case 'OPEN_REMOVE_LIST_ITEM_DIALOG':
      return {
        ...state,
        removeListItemDialogOpen: true,
        listItemData: action.payload,
      };

    case 'CLOSE_REMOVE_LIST_ITEM_DIALOG':
      return {
        ...state,
        removeListItemDialogOpen: false,
        listItemData: undefined,
      };

    case 'REMOVE_LIST_ITEM':
      return { ...state, removeListItemDialogOpen: false };

    case 'GET_WELLS_SUCCESS':
      return {
        ...state,
        wells: action.payload,
        loadingWells: false,
      };

    case 'GET_WELLS_FAIL':
      return { ...state, loadingWells: false };

    //Get well
    case 'GET_WELL_START':
      return { ...state, loadingWell: true };

    case 'GET_WELL_SUCCESS':
      return {
        ...state,
        well: action.payload,
        currentWell: action.payload,
        loadingWell: false,
        formIsFilledOut: false,
      };

    case 'GET_WELL_FAIL':
      return { ...state, loadingWell: false };

    //Update well
    case 'SET_WELL_START':
      return { ...state, loadingWell: true };

    case 'SET_WELL_SUCCESS':
      return {
        ...state,
        wells: action.payload,
        loadingWells: false,
        formIsFilledOut: false,
      };

    case 'SET_WELL_FAIL':
      return { ...state, loadingWell: false };

    //Delete well
    case 'DELETE_WELL_START':
      return { ...state, loadingWell: true };

    case 'DELETE_WELL_SUCCESS':
      return {
        ...state,
        wells: action.payload,
        loadingWells: false,
        formIsFilledOut: false,
      };

    case 'DELETE_WELL_FAIL':
      return { ...state, loadingWell: false };

    //Create well
    case 'CREATE_WELL_START':
      return { ...state, loadingWell: true };

    case 'CREATE_WELL_SUCCESS':
      return {
        ...state,
        wells: action.payload,
        loadingWells: false,
        formIsFilledOut: false,
      };

    case 'CREATE_WELL_FAIL':
      return { ...state, loadingWell: false };

    case 'RESET_WELL':
      return {
        ...state,
        currentWell: {
          name: '',
          wellType: '',
          productType: null,
          infoTexts: initialInfoTexts,
        },
      };

    case 'WELL_FORM_IS_BEING_FILLED_OUT':
      return {
        ...state,
        formIsFilledOut: true,
      };
    default:
      return state;
  }
};

export default wellsReducer;
