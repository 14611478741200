/* eslint-disable */

import React, { useEffect } from 'react';
import { useTheme, css, ClassNames } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DataGridPro as XGrid } from '@mui/x-data-grid-pro';
import { getFullname, getOrganizationName } from '../utils/datagridHelpers';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import usersIcon from '../assets/images/icons/user-groups.png';

import Fab from '@mui/material/Fab';

//TODO : why is this icon not used
import unverifiedMailIcon from '../assets/images/icons/mail-error.png';

import { withRouter } from '../utils/migrationHelper';
import { withTranslation } from 'react-i18next';
import PageTitle from './commons/PageTitle';

import { getUsersStart } from '../actions';

import { checkUserIsSuperAdmin } from '../utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';
import { RightReferences } from 'enums/RightReferences';
import { CrudPermissions } from 'enums/CrudPermissions';

let Users = ({ isAuthenticated, getUsersStart, user, users, loading, t }) => {
  let navigate = useNavigate();
  const classes = useTheme();

  useEffect(() => {
    if (isAuthenticated) {
      getUsersStart();
    } else {
      navigate('/');
    }
  }, []);

  const renderCreateButton = () => {
    if (
      checkUserIsSuperAdmin(isAuthenticated, user) ||
      checkUserHasRight(isAuthenticated, user, RightReferences.USERS, [
        CrudPermissions.UPDATE,
      ])
    ) {
      return (
        <Fab
          color="primary"
          aria-label="add"
          style={{
            right: 48,
            top: 85,
            left: 'auto',
            position: 'fixed',
          }}
          onClick={() => {
            navigate('/users/create');
          }}
        >
          <AddIcon />
        </Fab>
      );
    }
  };

  const dataGridRows = users.map((u) => {
    return { ...u, id: u.user_id };
  });
  const dataGridColumns = [
    {
      field: '_fullname',
      headerName: 'Name',
      editable: false,
      width: 300,
      valueGetter: getFullname,
    },
    {
      field: '_organizationName',
      headerName: 'Organization',
      editable: false,
      width: 600,
      valueGetter: getOrganizationName,
    },
  ];

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <div
            style={{
              height: document.documentElement.clientHeight - 200,
              paddingBottom: 83,
            }}
          >
            <PageTitle title={'Users'} loading={loading} image={usersIcon} />
            <XGrid
              pagination
              disableSelectionOnClick={true}
              loading={loading}
              rows={dataGridRows}
              columns={dataGridColumns}
              onRowClick={(rowData) => {
                let clickedUserId = rowData.row.identities[0].user_id;
                navigate('/users/read/' + clickedUserId);
              }}
            />
          </div>

          {renderCreateButton()}
        </>
      )}
    </ClassNames>
  );
};

const mapStateToProps = ({ usersReducer, loginReducer }) => {
  const { users, loading } = usersReducer;
  const { isAuthenticated, user } = loginReducer;
  return {
    users,
    loading,
    isAuthenticated,
    user,
  };
};

const mapDispatchToProps = {
  getUsersStart,
};

Users = withTranslation('translations')(Users);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
