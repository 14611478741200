import { FC } from 'react';

import { Grid } from '@mui/material';

import { CustomButton, FromStartButton } from './index';

const TimeNavigation: FC = () => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      style={{ minWidth: '100%' }}
      data-testid="time-navigation-id"
    >
      <div style={{ float: 'left' }}>
        <FromStartButton />
        <CustomButton />
      </div>
    </Grid>
  );
};

export default TimeNavigation;
