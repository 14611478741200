/* eslint-disable */

import React, { useEffect } from 'react';
import { useTheme, css, ClassNames } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { CommodityTypes } from '../enums/CommodityTypes';

import {
  DataGridPro as XGrid,
  GridToolbarContainer,
  gridClasses,
} from '@mui/x-data-grid-pro';

import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TableCell from '@mui/material/TableCell';
import createIcon from '../assets/images/icons/create.png';
import PageTitle from './commons/PageTitle';
import productTypeIcon from '../assets/images/icons/productType.png';
import metalTypeIcon from '../assets/images/icons/platinum-bars.png';

import { withRouter } from '../utils/migrationHelper';

import { getProductTypesStart } from '../actions';

import { withTranslation } from 'react-i18next';

import { listStyles } from '../assets/styles/listStyles';

function GridToolbar() {
  return (
    <GridToolbarContainer
      className={gridClasses.toolbarContainer}
    ></GridToolbarContainer>
  );
}

let ProductTypes = ({
  isAuthenticated,
  user,
  getProductTypesStart,
  productTypes,
  loadingProductTypes,
  t,
}) => {
  let navigate = useNavigate();
  const classes = listStyles;

  useEffect(() => {
    async function initComponent() {
      if (!isAuthenticated || !user) {
        navigate('/');
      } else {
        await getProductTypesStart();
      }
    }
    initComponent();
  }, []);

  const renderCreateButton = () => {
    return (
      <Fab
        color="primary"
        aria-label="add"
        style={{
          right: 48,
          top: 85,
          left: 'auto',
          position: 'fixed',
        }}
        onClick={() => {
          navigate('/product-types/create');
        }}
      >
        <AddIcon />
      </Fab>
    );
  };

  const dataGridColumns = [];
  dataGridColumns.push({
    field: 'name',
    headerName: 'Name',
    editable: false,
    width: 300,
  });
  dataGridColumns.push({
    field: 'shortName',
    headerName: 'Short name',
    editable: false,
    width: 200,
  });

  dataGridColumns.push({
    field: 'updateButton',
    headerName: 'Update',
    editable: false,
    width: 130,
    disableExport: true,
    renderCell: (params) => (
      <ClassNames>
        {({ css }) => (
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Fab
              size="small"
              color="primary"
              aria-label="update"
              className={css(classes.margin)}
              style={{ backgroundColor: '#FFFFFF' }}
              onClick={() => {
                navigate('/product-types/update/' + params.row._id);
              }}
            >
              <img
                src={createIcon}
                alt={`Update ${CommodityTypes.lowerCase}`}
                title={`Update ${CommodityTypes.lowerCase}`}
                style={{ width: 22, height: 22 }}
              />
            </Fab>
          </div>
        )}
      </ClassNames>
    ),
  });

  let dataGridRows =
    productTypes && Array.isArray(productTypes)
      ? productTypes.map((c, index) => {
          c.id = index;
          return c;
        })
      : [];

  const displayPlatinumUI =
    process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';
  const title = `${CommodityTypes.upperCasePlural}`;
  const icon = displayPlatinumUI ? metalTypeIcon : productTypeIcon;

  return (
    <div
      style={{
        width: '100%',
        height: document.documentElement.clientHeight - 200,
        paddingBottom: 0,
      }}
    >
      <PageTitle title={title} loading={loadingProductTypes} image={icon} />
      <XGrid
        loading={loadingProductTypes}
        pagination
        rows={dataGridRows}
        columns={dataGridColumns}
        components={{
          Toolbar: GridToolbar,
        }}
      />
      {renderCreateButton()}
    </div>
  );
};

const mapStateToProps = ({ loginReducer, productTypesReducer }) => {
  const { productTypes, loadingProductTypes } = productTypesReducer;

  const { isAuthenticated, user } = loginReducer;
  return {
    productTypes,
    loadingProductTypes,
    isAuthenticated,
    user,
  };
};

const mapDispatchToProps = {
  getProductTypesStart,
};

ProductTypes = withTranslation('translations')(ProductTypes);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductTypes)
);
