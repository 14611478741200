import { useEffect } from 'react';

import { getDynamicListsStart, getProductTypesStart } from 'actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';

import useFormStandardsSlicesCombiner from '../Slices/Combiner/useFormStandardsCombiner';

const useFormCreateStandard = () => {
  const { isAuthenticated, user, formIsFilledOut } =
    useFormStandardsSlicesCombiner();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    async function initComponent() {
      if (checkUserIsSuperAdmin(isAuthenticated, user)) {
        await dispatch(getProductTypesStart());
        await dispatch(getDynamicListsStart());
      } else navigate('/');
    }
    initComponent();
  }, []);

  useEffect(() => {
    if (formIsFilledOut) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [formIsFilledOut]);
};

export default useFormCreateStandard;
