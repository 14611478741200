import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
  displayJSONDiffCheckProductAttributesModal,
  getS3URLForProduct,
  socket,
} from 'actions';
import JSONDiffModal from './JSONDiffModal';

const SummaryPreviewJSONProductAttributesButton = () => {
  const { currentProduct, openJSONDiffCheckProductDialog } =
    useProductSlicesCombiner();

  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleUpdateStart = () => {
      setLoading(true);
    };

    const handleUpdateSuccess = () => {
      setLoading(false);
    };

    socket.on('updateProductJSONInBCStart', handleUpdateStart);
    socket.on('updateProductJSONInBCSuccess', handleUpdateSuccess);

    return () => {
      socket.off('updateProductJSONInBCStart', handleUpdateStart);
      socket.off('updateProductJSONInBCSuccess', handleUpdateSuccess);
    };
  }, []);

  const getProductHistory = async () => {
    if (currentProduct?.productId) {
      await dispatch(getS3URLForProduct(currentProduct.productId));
    }
  };

  const handleClick = async () => {
    await getProductHistory();
    dispatch(displayJSONDiffCheckProductAttributesModal());
  };

  if (!currentProduct) return null;

  return (
    <Box
      sx={{
        marginLeft: 2,
        marginRight: 2.5,
        marginBottom: 2,
        marginTop: 2,
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Preview product in JSON">
        <Button
          size="small"
          variant="contained"
          aria-label="history"
          color="primary"
          sx={{ minWidth: 50, position: 'relative' }}
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={20} sx={{ color: 'white' }} />
          ) : (
            <CompareArrowsIcon style={{ width: 20, height: 20 }} />
          )}
        </Button>
      </Tooltip>
      {openJSONDiffCheckProductDialog && <JSONDiffModal />}
    </Box>
  );
};

export default SummaryPreviewJSONProductAttributesButton;
