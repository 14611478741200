import useCreateOrUpdateProductSlicesCombiner from 'components/CreateOrUpdateProduct/Redux/Slices/useCreateOrUpdateProductSlicesCombiner';
type ErrorContentPropsType = {
  field: string;
};
const ErrorContent = ({ field }: ErrorContentPropsType) => {
  const { productsFormError } = useCreateOrUpdateProductSlicesCombiner();

  return (
    productsFormError &&
    productsFormError[field] && (
      <div>
        <span style={{ fontSize: 10, color: 'red' }}>
          {productsFormError[field]}
        </span>
      </div>
    )
  );
};

export default ErrorContent;
