import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const useWellsSlices = () => {
  const { wells, loadingWells } = useSelector(
    (state: RootState) => state.wellsReducer
  );

  return { wells, loadingWells };
};

export default useWellsSlices;
