import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useIncreaseBalanceFormConfirmationModalConfig from '../../Configuration/useIncreaseBalanceFormConfirmationModalConfig';

const ModalFooterIncreaseBalanceConfirmation = () => {
  const { footer } = useIncreaseBalanceFormConfirmationModalConfig();

  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterIncreaseBalanceConfirmation;
