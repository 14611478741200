import { useSelector } from 'react-redux';
import { LoginReducerStateType } from 'reducers/loginReducer/types';
import { RootState } from 'redux/store';

const useLoginSlices = (): LoginReducerStateType => {
  //Redux Login slices
  const { user, isAuthenticated }: LoginReducerStateType = useSelector(
    (state: RootState) => state.loginReducer
  );
  return { user, isAuthenticated };
};

export default useLoginSlices;
