import { Button, Grid } from '@mui/material';
import useFormStandardsSlicesCombiner from 'components/FormStandards/Redux/Slices/Combiner/useFormStandardsCombiner';
import { FormikValues, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

type UpdateOrCreateStandardPropsType = {
  params: any;
};
const UpdateOrCreateStandard = ({
  params,
}: UpdateOrCreateStandardPropsType) => {
  const updatingStandard = Boolean(params?.standardId);
  const { t } = useTranslation();
  const { submitForm, isSubmitting } = useFormikContext<FormikValues>();
  const { loadingStandard } = useFormStandardsSlicesCombiner();
  return (
    <Grid item xs={12} md={6} lg={3} style={{ marginRight: '-95px' }}>
      <Button
        onClick={submitForm}
        variant="contained"
        color="primary"
        disabled={loadingStandard || isSubmitting}
      >
        {updatingStandard ? t('Update standard') : t('Create standard')}
      </Button>
    </Grid>
  );
};

export default UpdateOrCreateStandard;
