import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import infoIcon from '../../../../../assets/images/icons/info.png';

type GenericFieldLabelPropsType = {
  label: string;
  tooltipLabel?: string;
};
const GenericFieldLabel = ({
  label,
  tooltipLabel,
}: GenericFieldLabelPropsType) => {
  if (tooltipLabel) {
    return (
      <div style={tooltipContainer}>
        <Typography variant="caption">
          {label?.trim()}{' '}
          <Tooltip
            title={tooltipLabel?.trim()}
            placement="right"
            enterDelay={100}
          >
            <img src={infoIcon} style={tooltipImage} alt={`info`} />
          </Tooltip>
        </Typography>
      </div>
    );
  }
  return <Typography variant="caption">{label?.trim()}</Typography>;
};

const tooltipContainer = { display: 'inline' };
const tooltipImage = { height: 16, marginLeft: 6, marginBottom: -2 };

export default GenericFieldLabel;
