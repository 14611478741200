/* eslint-disable */

import React, { useEffect } from 'react';
import { useTheme, css, ClassNames } from '@emotion/react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { CommodityTypes } from '../../enums/CommodityTypes';

import { DataGridPro as XGrid } from '@mui/x-data-grid-pro';
import {
  getProductOrganizationName,
  getProductProductTypeName,
} from '../../utils/datagridHelpers';
import Box from '@mui/material/Box';

import CircularProgress from '@mui/material/CircularProgress';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { withRouter } from '../../utils/migrationHelper';
import { withTranslation } from 'react-i18next';

import { getOrganizationProducts } from '../../actions';

import { listStyles } from '../../assets/styles/listStyles';

import { checkUserIsSuperAdmin } from '../../utils/user/checkAdmin/CheckAdmin';

let OrganizationProductsList = ({
  isAuthenticated,
  user,
  getOrganizationProducts,
  organizationProducts,
  loading,
  t,
}) => {
  let navigate = useNavigate();
  let params = useParams();
  const classes = listStyles;

  useEffect(() => {
    if (isAuthenticated) {
      getOrganizationProducts(params.organizationId);
    } else {
      navigate('/');
    }
  }, []);

  const dataGridRows = organizationProducts ? organizationProducts : [];
  let dataGridColumns = [
    {
      field: 'displayedName',
      headerName: 'Product',
      editable: false,
      width: 200,
    },
    {
      field: 'productId',
      headerName: 'Product Id',
      editable: false,
      width: 200,
    },
  ];

  if (checkUserIsSuperAdmin(isAuthenticated, user)) {
    dataGridColumns = [
      {
        field: 'displayedName',
        headerName: 'Product',
        editable: false,
        width: 200,
      },
      {
        field: 'productId',
        headerName: 'Product Id',
        editable: false,
        width: 200,
      },
      {
        field: '_productTypeName',
        headerName: CommodityTypes.upperCase,
        editable: false,
        width: 300,
        valueGetter: getProductProductTypeName,
      },
      {
        field: '_organizationName',
        headerName: 'Organization',
        editable: false,
        width: 300,
        valueGetter: getProductOrganizationName,
      },
    ];
  }
  return (
    <ClassNames>
      {({ css }) => (
        <div className={css(classes.root)}>
          <Paper className={css(classes.root)} style={{ marginTop: 0 }}>
            <div
              style={{
                height: document.documentElement.clientHeight - 200,
                paddingBottom: 83,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <Toolbar className={css(classes.root)}>
                <Box flexGrow={1}>
                  <div className={css(classes.title)}>
                    <Typography variant="inherit">
                      {t('Products')}
                      {loading && (
                        <CircularProgress
                          size={16}
                          style={{ marginLeft: 10 }}
                        />
                      )}
                    </Typography>
                  </div>
                </Box>
              </Toolbar>
              <XGrid
                pagination
                disableSelectionOnClick={true}
                loading={loading}
                rows={dataGridRows}
                columns={dataGridColumns}
                onRowClick={(rowData) => {
                  let _id = rowData.row._id;
                  navigate('/products/read/' + _id);
                }}
              />
            </div>
          </Paper>
        </div>
      )}
    </ClassNames>
  );
};

const mapStateToProps = ({ organizationsReducer, loginReducer }) => {
  const { organizationProducts, loading } = organizationsReducer;

  const { isAuthenticated, user } = loginReducer;

  let activeProducts = organizationProducts.filter((product) => product.active);
  return {
    organizationProducts: activeProducts,
    loading,
    isAuthenticated,
    user,
  };
};

const mapDispatchToProps = {
  getOrganizationProducts,
};

OrganizationProductsList = withTranslation('translations')(
  OrganizationProductsList
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationProductsList)
);
