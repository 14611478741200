import React from 'react';

import { Grid } from '@mui/material';

type GenericErrorPropsType = {
  error: string;
  displayError?: boolean;
  size?: number;
};

const GenericError = ({
  error = '',
  displayError = true,
  size = 12,
}: GenericErrorPropsType) => {
  return (
    displayError &&
    error !== '' && (
      <Grid item xs={size}>
        <div>
          <span style={{ fontSize: 10, color: 'red' }}>{error}</span>
        </div>
      </Grid>
    )
  );
};

export default GenericError;
