import React from 'react';

import { GridCellEditStopReasons, useGridApiRef } from '@mui/x-data-grid-pro';
import {
  addOrSetDynamicListItemStart,
  flushLocalDynamicListItems,
  setDynamicListGridApiRef,
  openErrorSnack,
  setLocalDynamicListItemField,
  updateDynamicListItemsOrderStart,
  updateLocalDynamicListItemsOrderStart,
} from 'actions';
import { UseDynamicListsSlicesCombinerType } from 'components/DynamicList/Redux/CustomHooks/types';
import useDynamicListsSlicesCombiner from 'components/DynamicList/Redux/Slices/useDynamicListsSlicesCombiner';
import { getAllDataGridColumns } from 'components/DynamicListItems/utils/columns';
import {
  getAllRows,
  checkRowOnCommit,
} from 'components/DynamicListItems/utils/rows';
import GenericTable from 'components/GenericComponents/Tables/GenericTable/GenericTable';
import { useDispatch } from 'react-redux';
import { DynamicListItemType } from 'reducers/dynamicListsReducer/types';
import { AppDispatch } from 'redux/store';

const DynamicListItemsTable = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    currentDynamicList,
    loading,
    localDynamicListItems,
    deletingDynamicListItemIds,
  }: UseDynamicListsSlicesCombinerType = useDynamicListsSlicesCombiner();

  const [rows, setRows] = React.useState<any[]>([]);

  const apiRef = useGridApiRef();

  React.useEffect(() => {
    const updatedDatagridRows: any[] = getAllRows(
      currentDynamicList?.items as DynamicListItemType[],
      localDynamicListItems as any[]
    );
    setRows(updatedDatagridRows);
  }, [currentDynamicList, localDynamicListItems]);

  React.useEffect(() => {
    for (const row of rows) {
      if (!row.key) {
        apiRef.current.setCellMode(row.id, 'key', 'edit');
      } else if (row.key && !row.value) {
        apiRef.current.setCellMode(row.id, 'value', 'edit');
      }
    }
  }, [rows]);

  React.useEffect(() => {
    //Flush localDynamicListItems on first time form is displayed
    dispatch(flushLocalDynamicListItems());
    dispatch(setDynamicListGridApiRef(apiRef));
  }, []);

  const handleCheckRowOnCommitError = (err: string) => {
    dispatch(openErrorSnack(err));
  };

  const handleOnCommitError = (id: string, field: string, error: Error) => {
    console.error(error);
    const value = apiRef.current.getCellValue(id, field);
    apiRef.current.setEditCellValue({ id, field, value });
  };

  const handleCellEditCommitSuccess = async (
    dynamicListId: string,
    id: string,
    field: string,
    newValue: any
  ) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        const newRow = { ...row };
        newRow[field] = newValue;
        return newRow;
      }
      return row;
    });
    const row = updatedRows.find((r) => r.id === id);

    if (dynamicListId && row.key && row.value) {
      dispatch(await addOrSetDynamicListItemStart(row, dynamicListId));
    }
    setRows(updatedRows);
  };

  const handleNewLocalListItemEdit = (
    field: string,
    id: string,
    newValue: any
  ) => {
    dispatch(setLocalDynamicListItemField(id, field, newValue));
  };

  const handleCellEditCommit = React.useCallback(
    async (data: any) => {
      const { field, id, _id, value } = data;
      const row = rows.find((r) => r.id === id || r._id === _id);
      const otherRows = rows.filter((r) => !(r.id === id || r._id === _id));
      try {
        checkRowOnCommit(field, value, otherRows, handleCheckRowOnCommitError);
        if (!row._id) handleNewLocalListItemEdit(field, id, value);
        handleCellEditCommitSuccess(currentDynamicList?._id, id, field, value);
      } catch (error: any) {
        handleOnCommitError(id, field, error);
      }
    },
    [rows]
  );

  const handleRowOrderChange = async (args: any) => {
    console.log(args, apiRef.current.getSortedRows());
    const dynamicListId = currentDynamicList?._id;
    const items = apiRef.current.getSortedRows();
    if (dynamicListId) {
      //Update order of items in dynamic list
      dispatch(updateDynamicListItemsOrderStart(dynamicListId, items));
      return;
    }
    //Update order of items in local dynamic list
    dispatch(updateLocalDynamicListItemsOrderStart(items));
  };

  const dataGridColumns = getAllDataGridColumns(deletingDynamicListItemIds);

  return (
    <GenericTable
      pagination
      autoHeight
      clickableRow={true}
      disableSelectionOnClick={true}
      loading={loading}
      tableColumns={dataGridColumns}
      tableRows={rows}
      isCellEditable={() => true}
      apiRef={apiRef}
      onCellEditCommit={handleCellEditCommit}
      onCellEditStop={(params, event) => {
        if (params.reason === GridCellEditStopReasons.cellFocusOut) {
          event.defaultMuiPrevented = true;
        }
      }}
      rowReordering={true}
      onRowOrderChange={handleRowOrderChange}
    />
  );
};

export default DynamicListItemsTable;
