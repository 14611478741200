import React from 'react';

import { Grid } from '@mui/material';

import BalanceChartCard from '../../../commons/BalanceChartCard/BalanceChartCard';
import GramsToOzToggleSwitch from '../../../commons/GramsToOzToggleSwitch';
import { TimeNavigation, DateFormContainer } from '../DateAndTimeNavigation';

const displayPlatinumUI = process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';
export const Charts: React.FC = () => {
  return !displayPlatinumUI ? (
    <Grid container spacing={2} data-testid="charts-container-id">
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <TimeNavigation />
        <GramsToOzToggleSwitch />
      </Grid>
      <DateFormContainer />
      <BalanceChartCard openOrRetire="open" />
      <BalanceChartCard openOrRetire="retire" />
    </Grid>
  ) : (
    <></>
  );
};
