import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { RightsListElement } from 'types/RightsListElement';

export const checkUserHasRight = (
  authentication: boolean,
  user: any,
  rightReference: RightReferences,
  permissions?: CrudPermissions[]
) => {
  return (
    authentication &&
    user?.user_metadata &&
    Array.isArray(user?.user_metadata?.rights) &&
    user.user_metadata.rights.some((r: RightsListElement) => {
      return Array.isArray(permissions)
        ? r.type.ref === rightReference &&
            permissions.every((p) => r.activePermissions.indexOf(p) >= 0)
        : r.type.ref === rightReference;
    })
  );
};
