import checkAttributesIcon from '../../../assets/images/icons/check-document.png';
import decacheIcon from '../../../assets/images/icons/cloud-refresh.png';
import decreaseIcon from '../../../assets/images/icons/decrease.png';
import increaseIcon from '../../../assets/images/icons/increase.png';
import notifyIcon from '../../../assets/images/icons/notify.png';
import { quantity } from '../Generic/Form/utils/utils';

export const getActions = (loadingUpdateBalancesCache: boolean) => {
  return [
    {
      name: 'increase_balance',
      title: `Increase balances`,
      subtitle: `Same as decrease, but here you can add a ${quantity} to the initial ${quantity} that has been issued`,
      icon: increaseIcon,
    },
    {
      name: 'decrease_balance',
      title: `Decrease balances`,
      subtitle: `In case of error and you have already issued certificates, then you can explain why you need to decrease the initial ${quantity} that has been issued`,
      icon: decreaseIcon,
    },
    {
      name: 'force_decache_balances',
      title: `Decache balances`,
      subtitle: `Sometimes, balances are not up to date after some actions : update the cache by clicking on the action button below !`,
      displayAllFeaturesOnly: true,
      loading: loadingUpdateBalancesCache,
      icon: decacheIcon,
    },
    {
      name: 'check_attributes',
      title: `Check product attributes`,
      subtitle: `Here you can check the attributes of a specific product as they were recorded in the blockchain at the time of its creation/update`,
      displayAllFeaturesOnly: true,
      icon: checkAttributesIcon,
    },
    {
      name: 'issuance_email_notification',
      title: `Notify organization for issuance`,
      subtitle: `Send an email to people of an organization to notify them of a future issuance and ask confirmation for volumes.`,
      icon: notifyIcon,
    },
  ];
};
