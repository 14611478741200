import { routerMiddleware } from 'react-router-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';

import history from '../history';
import reducers from '../reducers';

export const createReduxStore = () => {
  // Create a history of your choosing (we're using a browser history in this case)

  // Build the middleware for intercepting and dispatching navigation actions
  const RouterMiddleware = routerMiddleware(history);
  const middlewares = [ReduxThunk, RouterMiddleware];

  const store = createStore(
    reducers,
    {},
    applyMiddleware(...(middlewares as any))
  );

  return store;
};

export const store = createReduxStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
