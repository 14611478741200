import FormikInputsStandardContent from './Inputs/FormikInputsStandardContent';
import FormikJsonStandardContent from './Json/FormikJsonStandardContent';

type FormikJsonInputsContentPropsType = {
  props: any;
};
const FormikJsonInputsContent = ({
  props,
}: FormikJsonInputsContentPropsType) => {
  const { useJsonView } = props;
  if (useJsonView) return <FormikJsonStandardContent props={props} />;
  return <FormikInputsStandardContent props={props} />;
};

export default FormikJsonInputsContent;
