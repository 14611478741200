import { Player } from '@lottiefiles/react-lottie-player';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import * as connectionError from '../../assets/images/lottie/connection-error.json';

const OffLine = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection={'column'}
    >
      <Player
        autoplay
        loop
        src={connectionError}
        style={{ width: '30%' }}
      ></Player>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        id="no-internet-message"
      >
        Seems there is no internet connection
      </Typography>
    </Box>
  );
};

export default OffLine;
