export type ItemType = {
  _id: string;
  name: string;
  shortName?: string;
};

const itemMapper = (items: ItemType[]) => {
  return items.map((item) => ({
    key: item._id,
    value: item._id,
    label: `${item.name}${item.shortName ? ` ${item.shortName}` : ''}`,
  }));
};

export default itemMapper;
