import { FC } from 'react';

import { Typography } from '@mui/material';
import { Field } from 'formik';

const BeneficiaryCheckbox: FC = () => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: 390,
          paddingTop: 20,
          paddingBottom: 20,
          marginLeft: 10,
        }}
      >
        <Field
          data-testid="checkbox-id"
          type="checkbox"
          name="showRetailer"
          className="form-check-input"
        />
        <Typography
          variant="body2"
          gutterBottom
          style={{ marginTop: 4, marginLeft: 10 }}
        >
          For the benefit of
        </Typography>
      </div>
    </div>
  );
};

export default BeneficiaryCheckbox;
