/* eslint-disable */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField, Select } from 'formik-mui';

import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';
import testsIcon from '../assets/images/icons/tests.png';
import PageTitle from './commons/PageTitle';
import {
  DataGridPro as XGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid';
import { withRouter } from '../utils/migrationHelper';
import { withTranslation } from 'react-i18next';

import { checkUserIsSuperAdmin } from '../utils/user/checkAdmin/CheckAdmin';
import {
  startTests,
  removeTestData,
  resetLogs,
  sendNotifications,
} from '../actions';
import { enums } from '../enums';

/**
 * Common component import
 */

import LoadingCircle from '../components/commons/LoadingCircle';
import { useTheme, css, ClassNames } from '@emotion/react';
import { checkUserHasRight } from 'utils/user/checkRights';
import { RightReferences } from 'enums/RightReferences';

function GridToolbar() {
  return (
    <GridToolbarContainer
      className={gridClasses.toolbarContainer}
    ></GridToolbarContainer>
  );
}

let Tests = ({
  isAuthenticated,
  user,
  loading,
  startTests,
  removeTestData,
  resetLogs,
  sendNotifications,
  logs,
  nbOfOperationsDone,
  nbOfOperationsToDo,
}) => {
  const classes = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    } else {
      let isSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, user);
      let userHasTestRight = checkUserHasRight(
        isAuthenticated,
        user,
        RightReferences.TEST
      );
      if (!(isSuperAdmin || userHasTestRight)) {
        navigate('/');
      }
    }
  }, [isAuthenticated, user]);

  const getDurationFromOperation = (operation) => {
    let operationDuration = 0;

    operation.steps.map((step) => {
      let stepDuration = getDurationFromStep(step);
      operationDuration += stepDuration;
    });

    return operationDuration;
  };

  const getMinMaxFromOperationsArray = (operations) => {
    let durations = operations.map((operation) => {
      return getDurationFromOperation(operation);
    });
    let validDurations = durations.filter(
      (duration) => !isNaN(duration) && duration > 0
    );
    return {
      min: Math.min(...validDurations),
      max: Math.max(...validDurations),
    };
  };

  const getDurationFromStep = (step) => {
    let duration = 0;

    if (
      step.startedAt &&
      step.finishedAt &&
      moment(step.startedAt).isValid() &&
      moment(step.finishedAt).isValid()
    ) {
      let startMoment = moment(step.startedAt);
      let endMoment = moment(step.finishedAt);
      duration = endMoment.clone().diff(startMoment, 'ms', true);
    }

    return duration;
  };

  const getMinMaxFromStepsArray = (steps) => {
    let durations = steps.map((step) => {
      return getDurationFromStep(step);
    });
    let validDurations = durations.filter(
      (duration) => !isNaN(duration) && duration > 0
    );
    return {
      min: Math.min(...validDurations),
      max: Math.max(...validDurations),
    };
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          organizationsNumber: 1,
          productsNumber: 1,
          level: '1',
          nbMonths: 1,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.organizationsNumber) {
            errors.organizationsNumber = 'Required';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          startTests(values);
        }}
      >
        {({ submitForm, errors }) => (
          <Form>
            <div style={{ margin: 20, float: 'left' }}>
              <Field
                style={{ width: 150 }}
                component={TextField}
                label={`Number of organizations`}
                name="organizationsNumber"
                type="text"
              />
              <ErrorMessage name="organizationsNumber" component="div" />
            </div>

            <div style={{ margin: 20, float: 'left' }}>
              <Field
                style={{ width: 150 }}
                component={TextField}
                label={`Number of products by organization`}
                name="productsNumber"
                type="text"
              />
              <ErrorMessage name="productsNumber" component="div" />
            </div>

            <div style={{ margin: 20, float: 'left' }}>
              <Field
                style={{ width: 150 }}
                component={TextField}
                label={`Number of months`}
                name="nbMonths"
                type="text"
              />
              <ErrorMessage name="nbMonths" component="div" />
            </div>

            <div style={{ margin: 20, float: 'left' }}>
              <Field component={Select} name="level" label="Level of tests">
                <MenuItem key={'0'} value={'0'}>
                  Create products
                </MenuItem>
                <MenuItem key={'1'} value={'1'}>
                  Upload data
                </MenuItem>
                <MenuItem key={'2'} value={'2'}>
                  Issue Certificates
                </MenuItem>
                <MenuItem key={'3'} value={'3'}>
                  Transfer Certificates
                </MenuItem>
                <MenuItem key={'4'} value={'4'}>
                  Retire Certificates
                </MenuItem>
              </Field>
            </div>
            <div style={{ clear: 'both', margin: 20 }}>
              <Button
                onClick={submitForm}
                variant="contained"
                color="primary"
                disabled={JSON.stringify(errors) !== '{}' || loading}
              >
                Launch test
              </Button>

              <Button
                onClick={() => {
                  removeTestData();
                }}
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                disabled={JSON.stringify(errors) !== '{}' || loading}
              >
                Delete test data
              </Button>

              <Button
                onClick={() => {
                  resetLogs();
                }}
                style={{ marginLeft: 20 }}
                variant="contained"
                color="primary"
                disabled={JSON.stringify(errors) !== '{}' || loading}
              >
                Reset log variable
                {loading && (
                  <LoadingCircle
                    type="empty"
                    classes={classes}
                    style={{ margin: 0 }}
                    size={10}
                  />
                )}
              </Button>
            </div>
            <div style={{ clear: 'both', margin: 20, marginTop: 40 }}>
              <Typography type="body2" className={css(classes.listItemText)}>
                Developer tools
              </Typography>

              <Typography
                variant="caption"
                style={{ marginBottom: 20, marginTop: 20 }}
              >
                This button is used to send notifications to users set in the
                database as reference documents users. It will send them an
                email with the list of the expired documents. This action is
                usually done by a cron job.
              </Typography>
              <Button
                onClick={() => {
                  sendNotifications();
                }}
                variant="contained"
                color="primary"
                disabled={JSON.stringify(errors) !== '{}' || loading}
              >
                Launch reference documents notifications
                {loading && (
                  <LoadingCircle
                    type="empty"
                    classes={classes}
                    style={{ margin: 0 }}
                    size={10}
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const renderDuration = (steps, logId) => {
    return (
      <div>
        {steps &&
          Array.isArray(steps) &&
          steps.map((step, index) => {
            let duration = '-';

            if (step.startedAt && step.finishedAt) {
              let startMoment = moment(step.startedAt);
              let endMoment = moment(step.finishedAt);
              duration = endMoment.clone().diff(startMoment, 'ms', true);
            }
            return <div key={'duration' + index + logId}>{duration}</div>;
          })}
      </div>
    );
  };

  const renderStatusIcon = (status) => {
    const classes = useTheme();
    switch (status) {
      case 'processing':
        return (
          <LoadingCircle
            type="empty"
            classes={classes}
            style={{ margin: 0 }}
            size={10}
          />
        );
      case 'pending':
        return '⏳';
      case 'success':
        return '✅';

      case 'aborted':
        return '⚠️';

      case 'error':
        return '❌';

      default:
        return '';
    }
  };

  const renderStatus = (steps, logId) => {
    return (
      <div>
        {steps &&
          Array.isArray(steps) &&
          steps.map((step, index) => {
            let status = '-';

            if (step.status) status = step.status;
            return (
              <div key={'status' + index + logId}>
                {status} {renderStatusIcon(status)}
              </div>
            );
          })}
      </div>
    );
  };

  const renderSteps = (steps) => {
    return (
      <div>
        {steps &&
          Array.isArray(steps) &&
          steps.map((step, index) => {
            let errorMessage = ``;
            if (step.message) {
              errorMessage = ` : ${step.message}`;
            }
            return (
              <div key={'step' + index}>
                {step.text} {errorMessage}
              </div>
            );
          })}
      </div>
    );
  };

  const dataGridRows = [...logs].sort(function (a, b) {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  const dataGridColumns = [];
  dataGridColumns.push({
    field: 'text',
    headerName: 'Log',
    editable: false,
    width: 400,
  });
  dataGridColumns.push({
    field: 'status',
    headerName: 'Status',
    editable: false,
    width: 120,
    renderCell: (params) => {
      if (params.row.steps)
        return renderStatus(params.row.steps, params.row.id);
      let stepIcon = '';
      switch (params.row.status) {
        case 'processing':
          stepIcon = '⏳';
          break;
        case 'pending':
          stepIcon = '⏳';
          break;
        case 'success':
          stepIcon = '✅';
          break;

        case 'aborted':
          stepIcon = '⚠️';
          break;

        case 'error':
          stepIcon = '❌';
          break;

        default:
          stepIcon = '';
          break;
      }

      return `${params.row.status} ${stepIcon}`;
    },
  });

  dataGridColumns.push({
    field: 'steps',
    headerName: 'Steps',
    editable: false,
    width: 300,
    renderCell: (params) => {
      if (params.row.steps) return renderSteps(params.row.steps);
      return '';
    },
  });
  dataGridColumns.push({
    field: 'duration',
    headerName: 'Time',
    editable: false,
    width: 300,
    renderCell: (params) => {
      if (params.row.steps)
        return renderDuration(params.row.steps, params.row.id);
      return params.row.duration;
    },
  });
  dataGridColumns.push({
    field: 'type',
    headerName: 'Type',
    editable: false,
    width: 200,
  });

  let blockchainOperations = [];
  let decacheOperations = [];
  let createProductOperations = [];
  let updateProductOperations = [];
  let uploadDataOperations = [];
  let issueCertificateOperations = [];
  let transferCertificatesOperations = [];
  let retireCertificatesOperations = [];

  let nbBlockchainOperations = 0;
  let nbDecacheOperations = 0;
  let nbCreateProductOperations = 0;
  let nbUpdateProductOperations = 0;
  let nbUploadDataOperations = 0;
  let nbIssueCertificatesOperations = 0;
  let nbTransferCertificatesOperations = 0;
  let nbRetireCertificatesOperations = 0;

  let totalTimeBlockchainOperations = 0;
  let totalTimeDecacheOperations = 0;
  let totalTimeCreateProductOperations = 0;
  let totalTimeUpdateProductOperations = 0;
  let totalTimeUploadDataOperations = 0;
  let totalTimeIssueCertificatesOperations = 0;
  let totalTimeTransferCertificatesOperations = 0;
  let totalTimeRetireCertificatesOperations = 0;

  let averageTimeBlockchainOperations = 0;
  let averageTimeDecacheOperations = 0;
  let averageTimeCreateProductOperations = 0;
  let averageTimeUpdateProductOperations = 0;
  let averageTimeUploadDataOperations = 0;
  let averageTimeIssueCertificatesOperations = 0;
  let averageTimeTransferCertificatesOperations = 0;
  let averageTimeRetireCertificatesOperations = 0;

  logs.map((l) => {
    if (l.type === 'Create product') {
      createProductOperations.push(l);
      if (Array.isArray(l.steps))
        l.steps.map((step) => {
          totalTimeCreateProductOperations += getDurationFromStep(step);
        });
    }
    if (l.type === 'Update product') {
      updateProductOperations.push(l);
      if (Array.isArray(l.steps))
        l.steps.map((step) => {
          totalTimeUpdateProductOperations += getDurationFromStep(step);
        });
    }
    if (l.type === 'Upload data') {
      nbUploadDataOperations++;
      uploadDataOperations.push(l);
      if (Array.isArray(l.steps))
        l.steps.map((step) => {
          totalTimeUploadDataOperations += getDurationFromStep(step);
        });
    }
    if (l.type === 'Issue certificate') {
      nbIssueCertificatesOperations++;
      issueCertificateOperations.push(l);
      if (Array.isArray(l.steps))
        l.steps.map((step) => {
          totalTimeIssueCertificatesOperations += getDurationFromStep(step);
        });
    }
    if (l.type === 'Transfer certificate') {
      nbTransferCertificatesOperations++;
      transferCertificatesOperations.push(l);
      if (Array.isArray(l.steps))
        l.steps.map((step) => {
          totalTimeTransferCertificatesOperations += getDurationFromStep(step);
        });
    }
    if (l.type === 'Retire certificate') {
      nbRetireCertificatesOperations++;
      retireCertificatesOperations.push(l);
      if (Array.isArray(l.steps))
        l.steps.map((step) => {
          totalTimeRetireCertificatesOperations += getDurationFromStep(step);
        });
    }

    if (Array.isArray(l.steps))
      l.steps.map((step) => {
        if (step.type === 'bc') {
          blockchainOperations.push(step);
          totalTimeBlockchainOperations += getDurationFromStep(step);
        }
        if (step.type === 'cache') {
          decacheOperations.push(step);
          totalTimeDecacheOperations += getDurationFromStep(step);
        }
      });
  });

  nbBlockchainOperations = blockchainOperations.length;
  nbDecacheOperations = decacheOperations.length;
  nbCreateProductOperations = createProductOperations.length;
  nbUpdateProductOperations = updateProductOperations.length;

  averageTimeBlockchainOperations =
    !isNaN(nbBlockchainOperations) &&
    !isNaN(totalTimeBlockchainOperations) &&
    nbBlockchainOperations > 0
      ? parseInt(totalTimeBlockchainOperations / nbBlockchainOperations, 10)
      : '-';
  averageTimeDecacheOperations =
    !isNaN(nbDecacheOperations) &&
    !isNaN(totalTimeDecacheOperations) &&
    nbDecacheOperations > 0
      ? parseInt(totalTimeDecacheOperations / nbDecacheOperations, 10)
      : '-';
  averageTimeCreateProductOperations =
    !isNaN(nbCreateProductOperations) &&
    !isNaN(totalTimeCreateProductOperations) &&
    nbCreateProductOperations > 0
      ? parseInt(
          totalTimeCreateProductOperations / nbCreateProductOperations,
          10
        )
      : '-';
  averageTimeUpdateProductOperations =
    !isNaN(nbUpdateProductOperations) &&
    !isNaN(totalTimeUpdateProductOperations) &&
    nbUpdateProductOperations > 0
      ? parseInt(
          totalTimeUpdateProductOperations / nbUpdateProductOperations,
          10
        )
      : '-';
  averageTimeUploadDataOperations =
    !isNaN(nbUploadDataOperations) &&
    !isNaN(totalTimeUploadDataOperations) &&
    nbUploadDataOperations > 0
      ? parseInt(totalTimeUploadDataOperations / nbUploadDataOperations, 10)
      : '-';
  averageTimeIssueCertificatesOperations =
    !isNaN(nbIssueCertificatesOperations) &&
    !isNaN(totalTimeIssueCertificatesOperations) &&
    nbIssueCertificatesOperations > 0
      ? parseInt(
          totalTimeIssueCertificatesOperations / nbIssueCertificatesOperations,
          10
        )
      : '-';
  averageTimeTransferCertificatesOperations =
    !isNaN(nbTransferCertificatesOperations) &&
    !isNaN(totalTimeTransferCertificatesOperations) &&
    nbTransferCertificatesOperations > 0
      ? parseInt(
          totalTimeTransferCertificatesOperations /
            nbTransferCertificatesOperations,
          10
        )
      : '-';
  averageTimeRetireCertificatesOperations =
    !isNaN(nbRetireCertificatesOperations) &&
    !isNaN(totalTimeRetireCertificatesOperations) &&
    nbRetireCertificatesOperations > 0
      ? parseInt(
          totalTimeRetireCertificatesOperations /
            nbRetireCertificatesOperations,
          10
        )
      : '-';

  if (isNaN(averageTimeBlockchainOperations))
    averageTimeBlockchainOperations = '';
  if (isNaN(averageTimeDecacheOperations)) averageTimeDecacheOperations = '';
  if (isNaN(averageTimeCreateProductOperations))
    averageTimeCreateProductOperations = '';
  if (isNaN(averageTimeUpdateProductOperations))
    averageTimeUpdateProductOperations = '';
  if (isNaN(averageTimeUploadDataOperations))
    averageTimeUploadDataOperations = '';
  if (isNaN(averageTimeIssueCertificatesOperations))
    averageTimeIssueCertificatesOperations = '';
  if (isNaN(averageTimeTransferCertificatesOperations))
    averageTimeTransferCertificatesOperations = '';
  if (isNaN(averageTimeRetireCertificatesOperations))
    averageTimeRetireCertificatesOperations = '';

  let minMaxBlockchainOperations =
    getMinMaxFromStepsArray(blockchainOperations);
  let minMaxDecacheOperations = getMinMaxFromStepsArray(decacheOperations);
  let minMaxCreateProductOperations = getMinMaxFromOperationsArray(
    createProductOperations
  );
  let minMaxUpdateProductOperations = getMinMaxFromOperationsArray(
    updateProductOperations
  );
  let minMaxUploadDataOperations =
    getMinMaxFromOperationsArray(uploadDataOperations);
  let minMaxIssueCertificateOperations = getMinMaxFromOperationsArray(
    issueCertificateOperations
  );
  let minMaxTransferCertificatesOperations = getMinMaxFromOperationsArray(
    transferCertificatesOperations
  );
  let minMaxRetireCertificatesOperations = getMinMaxFromOperationsArray(
    retireCertificatesOperations
  );

  return (
    <ClassNames>
      {({ css }) => (
        <div className={css(classes.root)}>
          <PageTitle title={'Tests'} loading={loading} image={testsIcon} />
          <Grid container spacing={10}>
            <Grid item xs={12}>
              {renderForm()}
              {logs && logs.length > 0 && (
                <div>
                  <div>
                    <div>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Progress : {nbOfOperationsDone}/{nbOfOperationsToDo}{' '}
                        operations executed
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Blockchain transactions (all types) time : avr{' '}
                        {averageTimeBlockchainOperations} ms | min{' '}
                        {minMaxBlockchainOperations.min} ms | max{' '}
                        {minMaxBlockchainOperations.max} ms
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Decache operations (all types) time : avr{' '}
                        {averageTimeDecacheOperations} ms | min{' '}
                        {minMaxDecacheOperations.min} ms | max{' '}
                        {minMaxDecacheOperations.max} ms
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Create product time : avr{' '}
                        {averageTimeCreateProductOperations} ms | min{' '}
                        {minMaxCreateProductOperations.min} ms | max{' '}
                        {minMaxCreateProductOperations.max} ms
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Update product time : avr{' '}
                        {averageTimeUpdateProductOperations} ms | min{' '}
                        {minMaxUpdateProductOperations.min} ms | max{' '}
                        {minMaxUpdateProductOperations.max} ms
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Data upload time : avr {averageTimeUploadDataOperations}{' '}
                        ms | min {minMaxUploadDataOperations.min} ms | max{' '}
                        {minMaxUploadDataOperations.max} ms
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Issue time (full process): avr{' '}
                        {averageTimeIssueCertificatesOperations} ms | min{' '}
                        {minMaxIssueCertificateOperations.min} ms | max{' '}
                        {minMaxIssueCertificateOperations.max} ms
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Transfer time (full process): avr{' '}
                        {averageTimeTransferCertificatesOperations} ms | min{' '}
                        {minMaxTransferCertificatesOperations.min} ms | max{' '}
                        {minMaxTransferCertificatesOperations.max} ms
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Retire average time (full process): avr{' '}
                        {averageTimeRetireCertificatesOperations} ms | min{' '}
                        {minMaxRetireCertificatesOperations.min} ms | max{' '}
                        {minMaxRetireCertificatesOperations.max} ms
                      </Typography>
                    </div>
                  </div>
                  <div className={css(classes.root)} style={{ width: '100%' }}>
                    <Paper
                      className={css(classes.root)}
                      style={{
                        marginTop: 0,
                        width: '100%',
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: document.documentElement.clientHeight - 200,
                          paddingBottom: 0,
                        }}
                      >
                        <XGrid
                          getRowHeight={(row) => {
                            let numberOfRows = row?.model?.steps?.length || 2;
                            return 25 * numberOfRows;
                          }}
                          loading={loading}
                          pagination
                          rows={dataGridRows}
                          columns={dataGridColumns}
                          components={{
                            Toolbar: GridToolbar,
                          }}
                        />
                      </div>
                    </Paper>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </ClassNames>
  );
};

Tests.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = ({ testReducer, loginReducer }) => {
  const { results, loading, logs, nbOfOperationsDone, nbOfOperationsToDo } =
    testReducer;
  const { isAuthenticated, user } = loginReducer;
  return {
    results,
    loading,
    isAuthenticated,
    user,
    logs,
    nbOfOperationsDone,
    nbOfOperationsToDo,
  };
};

const mapDispatchToProps = {
  startTests,
  removeTestData,
  resetLogs,
  sendNotifications,
};

Tests = withTranslation('translations')(Tests);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tests));
