import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useIssueCertificatesConfirmationModalConfig from '../../Configuration/useIssueCertificatesConfirmationModalConfig';

const ModalFooterIssueCertificatesSecondForm = () => {
  const { footer } = useIssueCertificatesConfirmationModalConfig();
  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterIssueCertificatesSecondForm;
