import useLoginSlices from 'components/Balances/Redux/Slices/useLoginSlices';
import useOrganizationsSlices from 'components/Balances/Redux/Slices/useOrganizationsSlices';
import useProductTypesSlices from 'components/Balances/Redux/Slices/useProductTypesSlices';
import useStandardsSlices from 'components/Balances/Redux/Slices/useStandardsSlices';
import useDynamicListsSlices from 'components/DynamicList/Redux/Slices/useDynamicListsSlices';
import useProductsSlices from 'components/Products/Redux/Slices/useProductsSlices';

import { UseCreateOrUpdateProductSlicesType } from '../CustomHooks/types';

import useWellsSlices from './useWellsSlices';

const useCreateOrUpdateProductSlicesCombiner =
  (): UseCreateOrUpdateProductSlicesType => {
    return {
      ...useProductsSlices(),
      ...useLoginSlices(),
      ...useOrganizationsSlices(),
      ...useProductTypesSlices(),
      ...useDynamicListsSlices(),
      ...useStandardsSlices(),
      ...useWellsSlices(),
    };
  };

export default useCreateOrUpdateProductSlicesCombiner;
