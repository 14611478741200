import { Toolbar } from '@mui/material';

import ToolbarTitle, {
  ToolbarPropsType,
} from './ToolbarContent/ToolbarTitle/ToolbarTitle';

const ReferenceFilesToolbar = ({ title }: ToolbarPropsType): JSX.Element => {
  return (
    <Toolbar>
      <ToolbarTitle title={title} />
    </Toolbar>
  );
};

export default ReferenceFilesToolbar;
