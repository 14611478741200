import useLoginSlices from 'components/Balances/Redux/Slices/useLoginSlices';
import useOrganizationsSlices from 'components/Balances/Redux/Slices/useOrganizationsSlices';
import useProductTypesSlices from 'components/Balances/Redux/Slices/useProductTypesSlices';
import useDynamicListsSlices from 'components/DynamicList/Redux/Slices/useDynamicListsSlices';

import useStandardsReducerSlices from '../useStandardsReducerSlices';

const useFormStandardsSlicesCombiner = () => {
  return {
    ...useStandardsReducerSlices(),
    ...useLoginSlices(),
    ...useOrganizationsSlices(),
    ...useProductTypesSlices(),
    ...useDynamicListsSlices(),
  };
};

export default useFormStandardsSlicesCombiner;
