/* eslint-disable */

import { withSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { LicenseInfo } from '@mui/x-data-grid-pro';
import './App.css';
import './assets/fonts/Inter/Inter-Regular.ttf';
import './assets/fonts/Inter/Inter-SemiBold.ttf';
import { useCompanyLogo, useUserLogo } from './components/Drawer/utils/logo';

import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import AppRoutes from './AppRoutes';
import AuthCallback from './components/AuthCallback';
import TermsOfUseDialog, {
  termsOfUseDialog,
} from './components/commons/TermsOfUseDialog';
import Landing from './components/Landing/Landing';
import Notifier from './components/Notifier';

import { ClassNames, useTheme } from '@emotion/react';

import MenuIcon from '@mui/icons-material/Menu';

import { drawerWidth } from './theme';
import { withRouter } from './utils/migrationHelper';

import {
  activateSocket,
  checkAuthentication,
  deactivateSocket,
  logoutStart,
  setUserConsent,
} from './actions';
import DrawerContainer from 'components/Drawer/DrawerContainer';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const unknownUserLogo = require('./assets/images/icons/default-user-avatar.png');
const unknownOrganizationLogo = require('./assets/images/teoLogoBleu.png');

let App = (props) => {
  const {
    checkAuthentication,
    cookies,
    activateSocket,
    isAuthenticated,
    user,
    t,
    loadingCheck,
    deactivateSocket,
    setUserConsent,
    logoutStart,
  } = props;

  let navigate = useNavigate();
  let location = useLocation();
  const classes = useTheme();

  let currentLink = `/${location.pathname.split('/')[1]}`;
  const [activeLink, setActiveLink] = useState(currentLink);
  const [userHasConsent, setUserHasConsent] = useState(false);

  const [width, setWidth] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    async function initApp() {
      await checkAuthentication(navigate, location, cookies);
      await activateSocket(navigate, location);
      window.addEventListener('resize', handleWindowSizeChange);
    }
    initApp();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      deactivateSocket();
    };
  }, []);

  useEffect(() => {
    const _userConnectedWithoutConsent =
      isAuthenticated &&
      user &&
      user.user_metadata &&
      !user.user_metadata.consentGiven;

    setUserHasConsent(!_userConnectedWithoutConsent);
  }, [user, isAuthenticated]);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const handleDrawerToggle = () => {
    if (width && width <= 768) setMobileOpen(!mobileOpen);
  };

  const renderAppBar = () => {
    const { companyLogo } = useCompanyLogo(user);
    const { userLogo } = useUserLogo(isAuthenticated, user);
    if (!isAuthenticated) {
      return (
        <ClassNames>
          {({ css }) => (
            <AppBar className={css(classes.appBarLanding)}>
              <Toolbar></Toolbar>
            </AppBar>
          )}
        </ClassNames>
      );
    }

    const organization = user?.user_metadata?.organization;

    return (
      <ClassNames>
        {({ css }) => (
          <AppBar className={css(classes.appBar)}>
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                className={css(classes.navIconHide)}
              >
                <MenuIcon />
              </IconButton>
              <div style={{ position: 'absolute', left: 62 }}>
                <img src={companyLogo} style={{ maxHeight: 28 }} />
              </div>
              <div style={{ position: 'absolute', right: 20 }}>
                <NavLink
                  to={'/landing-private/'}
                  style={{ textDecoration: 'none' }}
                >
                  <Avatar
                    src={userLogo}
                    style={{ border: '1px solid rgba(0, 0, 0, 0.54)' }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = unknownUserLogo;
                    }}
                  />
                </NavLink>
              </div>
            </Toolbar>
          </AppBar>
        )}
      </ClassNames>
    );
  };

  const imageBackgroundStyle = !isAuthenticated
    ? classes?.imageBackgroundNotAuthenticated
    : {};

  if (loadingCheck) {
    return <CircularProgress size={24} />;
  }

  if (!isAuthenticated) {
    return (
      <div>
        <Notifier />
        <Routes>
          <Route
            exact
            path="/"
            props={props}
            element={<Landing props={props} />}
          />
          <Route
            exact
            path="/get-user-on-authentication"
            props={props}
            element={<AuthCallback props={props} />}
          />
        </Routes>
      </div>
    );
  }

  if (user && !userHasConsent) {
    //fired when clicking on the "Agree" button of the TermsOfUseDialog
    termsOfUseDialog(async () => {
      setUserHasConsent(true);
      return await setUserConsent(user, true);
    });
  }

  return (
    <div key={isAuthenticated} style={imageBackgroundStyle}>
      <Notifier />
      <TermsOfUseDialog />

      <ClassNames>
        {({ css }) => (
          <div className={css(classes.appRoot)}>
            {renderAppBar()}
            <DrawerContainer
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
              isAuthenticated={isAuthenticated}
              user={user}
              cookies={cookies}
            />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
              }}
            >
              <div className={css(classes.toolbar)} />
              {!userHasConsent && <CircularProgress size={24} />}
              {userHasConsent && <AppRoutes props={props} />}
            </Box>
          </div>
        )}
      </ClassNames>
    </div>
  );
};

const mapStateToProps = ({ loginReducer }) => {
  const { isAuthenticated, user, loadingCheck } = loginReducer;
  return { isAuthenticated, user, loadingCheck };
};

const mapDispatchToProps = {
  checkAuthentication,
  logoutStart,
  setUserConsent,
  activateSocket,
  deactivateSocket,
};

App = withSnackbar(App);
App = withTranslation('translations')(App);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(App))
);
