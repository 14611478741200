import React from 'react';

import GenericModalContent from 'components/Administration/Generic/Modal/Content/GenericModalContent';

import useIssueCertificatesConfirmationModalConfig from '../../Configuration/useIssueCertificatesConfirmationModalConfig';

const ModalContentIssueCertificatesSecondForm = () => {
  const { header, content } = useIssueCertificatesConfirmationModalConfig();

  return <GenericModalContent header={header} content={content} />;
};

export default ModalContentIssueCertificatesSecondForm;
