import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import {
  displayConfirmReferenceFileDeletionModal,
  removeLocalProductReferenceFile,
  openSuccessSnack,
} from 'actions';
import MenuItemIcon from 'components/commons/MenuItemIcon/MenuItemIcon';
import { blockchainExplorerUrl } from 'config/blockchainExplorerConfig';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import blockchainLogo from '../../../../assets/images/blockchain.png';
import copyIcon from '../../../../assets/images/icons/copy.png';
import deleteFileIcon from '../../../../assets/images/icons/delete-file.png';

const MoreButton = (props: any): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const { row, userCanEditReferenceFiles } = props;
  const { txHash, id, _id, dataHash } = row;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    if (_id) {
      dispatch(displayConfirmReferenceFileDeletionModal(row));
    } else {
      dispatch(removeLocalProductReferenceFile(id));
    }
  };

  const handleCheckTransaction = () => {
    window.open(`${blockchainExplorerUrl}/tx/${txHash}`, '_blank');
  };

  const handleCopyFileHash = () => {
    navigator.clipboard.writeText(dataHash);
    dispatch(openSuccessSnack('File hash copied.'));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip
        title={'Click to see more actions'}
        placement="bottom"
        enterDelay={100}
      >
        <IconButton aria-label="open more menu" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {userCanEditReferenceFiles && (
          <MenuItemIcon
            key={'deleteFile'}
            icon={deleteFileIcon}
            onClick={handleDelete}
            title={'Delete Document'}
            imgTitle={'Delete reference document'}
          />
        )}
        {dataHash && (
          <MenuItemIcon
            key={'checkTransaction'}
            icon={blockchainLogo}
            onClick={handleCheckTransaction}
            title={'Check transaction'}
          />
        )}
        {txHash && (
          <MenuItemIcon
            key={'copyFileHash'}
            icon={copyIcon}
            onClick={handleCopyFileHash}
            title={'Copy file hash'}
          />
        )}
      </Menu>
    </>
  );
};

export default MoreButton;
