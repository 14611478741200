import { closeActionDialog, readAndUploadBulkOperationsDataset } from 'actions';
import GenericUploadField from 'components/Administration/Generic/Form/Fields/Upload/GenericUploadField';
import GenericUploadLink from 'components/Administration/Generic/Form/Fields/Upload/GenericUploadLink';
import { handleBulkOperationsDatasetChange } from 'components/Administration/Generic/Form/utils/handlers';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import { default as bulkOperationsGasExample } from '../../../../../../../../../assets/sheets/bulk_operations_example_gt.xlsx';
import { default as bulkOperationsPopExample } from '../../../../../../../../../assets/sheets/bulk_operations_example_pop.xlsx';
import { FormFieldsConfigType } from '../../../../../utils/types/formFieldsTypes';
import { FooterModalPropsType } from '../../../../../utils/types/types';

const bulkOperationsExample =
  process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum'
    ? bulkOperationsPopExample
    : bulkOperationsGasExample;

const useBulkOperationsModalConfig = () => {
  const { localBulkOperationsDataset, loadingBulkOperationsDataset } =
    useAdministrationSlicesCombiner();

  const dispatch: AppDispatch = useDispatch();
  const handleUploadBulkOperationsDataset = async () => {
    localBulkOperationsDataset &&
      dispatch(readAndUploadBulkOperationsDataset(localBulkOperationsDataset));
  };
  //modal components
  const modalHeader = {
    title: '',
  };
  const modalFooter: FooterModalPropsType = {
    cancelLabel: 'Cancel',
    submitLabel: 'Submit multiple operations',
    onCancelHandler: closeActionDialog,
    onSubmitHandler: handleUploadBulkOperationsDataset,
    disabled: !localBulkOperationsDataset || loadingBulkOperationsDataset,
    loading: loadingBulkOperationsDataset,
  };
  const formFieldsConfig: FormFieldsConfigType = [
    [
      {
        component: GenericUploadField,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          handleBulkOperationsDatasetChange(e, dispatch),
        idSelector: 'upload-confirmation-dataset-button-ref',
        buttonLabel: 'Select excel dataset',
        disabled: loadingBulkOperationsDataset,
      },
      {
        component: GenericUploadLink,
        noDocumentFoundLabel: 'No dataset selected',
        documentToUploadRef: bulkOperationsExample,
        documentToUpload: localBulkOperationsDataset,
        linkExampleToDisplay: true,
      },
    ],
  ];

  return {
    header: modalHeader,
    content: formFieldsConfig,
    footer: modalFooter,
  };
};

export default useBulkOperationsModalConfig;
