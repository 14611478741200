import React from 'react';

import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useIssueCertificatesInBulkFormModalConfig from '../../Configuration/useIssueCertificatesInBulkFirstModalConfig';

const ModalFooterIssueCertificatesInBulkForm = () => {
  const { footer } = useIssueCertificatesInBulkFormModalConfig();
  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterIssueCertificatesInBulkForm;
