import GenericModalContent from 'components/Administration/Generic/Modal/Content/GenericModalContent';

import useIncreaseBalanceFormModalConfig from '../../Configuration/useIncreaseBalanceFormModalConfig';

const ModalContentIncreaseBalanceForm = () => {
  const { header, content } = useIncreaseBalanceFormModalConfig();

  return <GenericModalContent header={header} content={content} />;
};

export default ModalContentIncreaseBalanceForm;
