import React from 'react';

import { GridCellParams } from '@mui/x-data-grid-pro';

import RemoveButton from '../Buttons/Atomic/RemoveButton';

type AddAttributeColumnsPropsType = {
  index: number;
};
const AddAttributeColumns = ({ index }: AddAttributeColumnsPropsType) => {
  return [
    {
      field: 'ref',
      headerName: 'Id',
      editable: true,
      flex: 1,
    },
    {
      field: 'value',
      headerName: 'Value',
      editable: true,
      flex: 1,
    },
    {
      field: 'removeButton',
      headerName: 'Remove',
      editable: false,
      flex: 1,
      disableExport: true,
      renderCell: (params: GridCellParams) => (
        <RemoveButton params={params} index={index} />
      ),
    },
  ];
};
export default AddAttributeColumns;
