import { Button, DialogActions } from '@mui/material';

const ProductActions = (props: any) => {
  return (
    <DialogActions>
      <Button onClick={props.handleClose} color="primary">
        Close
      </Button>
    </DialogActions>
  );
};

export default ProductActions;
