import { useEffect } from 'react';

import { getScheduledTasksMonitoringDispatch } from 'actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const useScheduledTasksApi = () => {
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    const init = async () => {
      await dispatch(getScheduledTasksMonitoringDispatch());
    };
    init();
  }, [getScheduledTasksMonitoringDispatch]);
};

export default useScheduledTasksApi;
