import { FC } from 'react';

import { ClassNames } from '@emotion/react';
import { Card, CardHeader, Grid } from '@mui/material';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { checkUserHasRight } from 'utils/user/checkRights';

import { checkUserIsSuperAdmin } from '../../../../../utils/user/checkAdmin/CheckAdmin';
import useBalancesChildComponentsSlices from '../../../Redux/CustomHooks/useBalancesChildComponentsSlices';

export const Unauthorized: FC = () => {
  const { isAuthenticated, user }: UseBalancesChildComponentsSlicesType =
    useBalancesChildComponentsSlices();
  if (
    !checkUserIsSuperAdmin(isAuthenticated, user) &&
    !checkUserHasRight(isAuthenticated, user, RightReferences.BALANCES, [
      CrudPermissions.VIEW,
    ])
  ) {
    return (
      <ClassNames>
        {({ css }) => (
          <div className={css()}>
            <Grid container spacing={10}>
              <Grid item xs={12}>
                <Card className={css()}>
                  <CardHeader title="Unauthorized" />
                </Card>
              </Grid>
            </Grid>
          </div>
        )}
      </ClassNames>
    );
  }
  return <></>;
};
