import { useContext } from 'react';

import { Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Loading from 'components/GenericComponents/Async/Loading/Loading';
import AggregationCardContext from 'components/Product/Context/AggregationCardContext';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';

const AggregationToolbar = () => {
  const { loadingProductAggregations } = useProductSlicesCombiner();
  const { title } = useContext(AggregationCardContext);

  return (
    <Toolbar>
      <Box flexGrow={1}>
        <div>
          <Typography variant="subtitle1">
            {title}
            {loadingProductAggregations && (
              <Loading size={16} style={{ marginLeft: 10 }} />
            )}
          </Typography>
        </div>
      </Box>
    </Toolbar>
  );
};

export default AggregationToolbar;
