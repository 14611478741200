/* eslint-disable */

import React, { useEffect } from 'react';
import { useTheme, css, ClassNames } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { DataGridPro as XGrid } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { withRouter } from '../../utils/migrationHelper';
import { withTranslation, Trans } from 'react-i18next';
import PageTitle from '../commons/PageTitle';
import BackButton from '../commons/BackButton/BackButton';
import trashIcon from '../../assets/images/icons/trash.png';

import { getArchivedOrganizations } from '../../actions';

import { listStyles } from '../../assets/styles/listStyles';

let ArchivedOrganizationList = ({
  isAuthenticated,
  getArchivedOrganizations,
  t,
  loading,
  archivedOrganizations,
}) => {
  let navigate = useNavigate();
  const classes = listStyles;

  useEffect(() => {
    if (isAuthenticated) {
      getArchivedOrganizations();
    } else {
      navigate('/');
    }
  }, []);

  const dataGridRows = archivedOrganizations.map((o) => {
    return { ...o, id: o._id };
  });
  const dataGridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      width: 600,
    },
  ];

  return (
    <>
      <BackButton />
      <div
        style={{
          height: document.documentElement.clientHeight - 200,
          paddingBottom: 83,
        }}
      >
        <PageTitle
          title={'Archived organizations'}
          loading={loading}
          image={trashIcon}
        />
        <XGrid
          pagination
          disableSelectionOnClick={true}
          loading={loading}
          rows={dataGridRows}
          columns={dataGridColumns}
          onRowClick={(rowData) => {
            let organizationId = rowData.row._id;
            navigate('/organizations/read/' + organizationId);
          }}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ organizationsReducer, loginReducer }) => {
  const { archivedOrganizations, loading } = organizationsReducer;
  const { isAuthenticated, user } = loginReducer;
  return {
    archivedOrganizations,
    loading,
    isAuthenticated,
    user,
  };
};

const mapDispatchToProps = {
  getArchivedOrganizations,
};

ArchivedOrganizationList = withTranslation('translations')(
  ArchivedOrganizationList
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArchivedOrganizationList)
);
