import React from 'react';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import useTransactionsCombiner from 'components/Transactions/Redux/Slices/Combiner/useTransactionsCombiner';
import { useTranslation } from 'react-i18next';

const PdfDialog = () => {
  const { t } = useTranslation();
  const { dialogLoadingPdfOpen } = useTransactionsCombiner();
  return (
    <Dialog
      fullScreen={false}
      open={dialogLoadingPdfOpen}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {t('Generating your PDF...')}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          {t('Loading the data, please wait')}{' '}
          <CircularProgress size={16} style={{ paddingLeft: 10 }} />
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default PdfDialog;
