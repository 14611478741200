import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import { UseProductsSlicesCombinerType } from 'components/Products/Redux/CustomHooks/types';
import useProductsSlicesCombiner from 'components/Products/Redux/Slices/useProductsSlicesCombiner';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { useNavigate } from 'react-router';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import './Styles/index.css';
import { checkUserHasRight } from 'utils/user/checkRights';

const CreateButton = (): JSX.Element => {
  const navigate = useNavigate();
  const { isAuthenticated, user }: UseProductsSlicesCombinerType =
    useProductsSlicesCombiner();

  if (
    !(
      checkUserIsSuperAdmin(isAuthenticated, user) ||
      checkUserHasRight(isAuthenticated, user, RightReferences.PRODUCTS, [
        CrudPermissions.UPDATE,
      ])
    )
  )
    return <></>;
  return (
    <Fab
      color="primary"
      aria-label="add"
      className="add-btn"
      id="createProductButton"
      onClick={() => {
        navigate('/products/create');
      }}
    >
      <AddIcon />
    </Fab>
  );
};

export default CreateButton;
