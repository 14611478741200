import { Grid, Checkbox, FormControlLabel } from '@mui/material';

import { GenericCheckboxPropsType } from '../../utils/types/types';

const GenericCheckbox = ({
  name = '',
  label = '',
  checked = false,
  gridItemSize = 12,
  onChange,
}: GenericCheckboxPropsType) => {
  return (
    <Grid item xs={gridItemSize}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            name={name}
            onChange={onChange}
            size="small"
          />
        }
        label={label}
      />
    </Grid>
  );
};

export default GenericCheckbox;
