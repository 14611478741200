/* eslint-disable */

import { AnyAction } from 'redux';
import { ProductTypesReducerStateType } from './types';

const INITIAL_STATE: ProductTypesReducerStateType = {
  productType: null,
  currentProductType: null,
  productTypes: [],
  error: { message: '', isVisible: false },
  loadingProductTypes: false,
  loadingProductType: false,
  formIsBeingFilledOut: false,
};

const productTypesReducer = (
  state: ProductTypesReducerStateType = INITIAL_STATE,
  action: AnyAction
) => {
  switch (action.type) {
    //Getting productTypes
    case 'GET_PRODUCT_TYPES_START':
      return { ...state, loadingProductTypes: true };

    case 'GET_PRODUCT_TYPES_SUCCESS':
      return {
        ...state,
        productTypes: action.payload,
        loadingProductTypes: false,
      };

    case 'GET_PRODUCT_TYPES_FAIL':
      return { ...state, loadingProductTypes: false };

    //Get productType
    case 'GET_PRODUCT_TYPE_START':
      return { ...state, loadingProductType: true };

    case 'GET_PRODUCT_TYPE_SUCCESS':
      return {
        ...state,
        productType: action.payload,
        currentProductType: action.payload,
        loadingProductType: false,
        formIsFilledOut: false,
      };

    case 'GET_PRODUCT_TYPE_FAIL':
      return { ...state, loadingProductType: false };

    //Update productType
    case 'SET_PRODUCT_TYPE_START':
      return { ...state, loadingProductType: true };

    case 'SET_PRODUCT_TYPE_SUCCESS':
      return {
        ...state,
        productTypes: action.payload,
        loadingProductType: false,
        formIsFilledOut: false,
      };

    case 'SET_PRODUCT_TYPE_FAIL':
      return { ...state, loadingProductType: false };

    //Delete productType
    case 'DELETE_PRODUCT_TYPE_START':
      return { ...state, loadingProductType: true };

    case 'DELETE_PRODUCT_TYPE_SUCCESS':
      return {
        ...state,
        productTypes: action.payload,
        loadingProductType: false,
        formIsFilledOut: false,
      };

    case 'DELETE_PRODUCT_TYPE_FAIL':
      return { ...state, loadingProductType: false };

    //Create productType
    case 'CREATE_PRODUCT_TYPE_START':
      return { ...state, loadingProductType: true };

    case 'CREATE_PRODUCT_TYPE_SUCCESS':
      return {
        ...state,
        productTypes: action.payload,
        loadingProductType: false,
        formIsFilledOut: false,
      };

    case 'CREATE_PRODUCT_TYPE_FAIL':
      return { ...state, loadingProductType: false };

    case 'RESET_PRODUCT_TYPE':
      return {
        ...state,
        currentProductType: {
          id: undefined,
          name: '',
          shortName: '',
        },
      };

    case 'PRODUCT_TYPE_FORM_IS_BEING_FILLED_OUT':
      return {
        ...state,
        formIsFilledOut: true,
      };
    default:
      return state;
  }
};

export default productTypesReducer;
