import React from 'react';

import GenericModalContent from 'components/Administration/Generic/Modal/Content/GenericModalContent';

import useCheckAttributesConfirmationModalConfig from '../../Configuration/Configuration/useCheckAttributesConfirmationModalConfig';

const ModalContentCheckAttributesConfirmation = () => {
  const { header, content } = useCheckAttributesConfirmationModalConfig();

  return <GenericModalContent header={header} content={content} />;
};

export default ModalContentCheckAttributesConfirmation;
