import React from 'react';

import { Grid } from '@mui/material';

import GenericErrorInRed from '../../../../../GenericComponents/Errors/GenericErrorInRed';
import useBalancesChildComponentsSlices from '../../../../Redux/CustomHooks/useBalancesChildComponentsSlices';
import SelectOrganizationsList from '../../common/Fields/SelectList/OrganizationsList';
type RetireOrganizationsListPropsType = {
  values: any;
  errors: any;
};
const RetireOrganizationsList = ({
  values,
  errors,
}: RetireOrganizationsListPropsType) => {
  const { allocateCertificatesFormError } = useBalancesChildComponentsSlices();
  return (
    <Grid item xs={12}>
      {values.showRetailer && <SelectOrganizationsList type="retire" />}
      <div>
        <GenericErrorInRed
          condition={errors?.organization}
          errors={errors}
          customKey={'organization'}
        />
        <GenericErrorInRed
          condition={
            values.showRetailer &&
            allocateCertificatesFormError &&
            allocateCertificatesFormError?._to
          }
          errors={allocateCertificatesFormError}
          customKey={'_to'}
        />
      </div>
    </Grid>
  );
};

RetireOrganizationsList.propTypes = {};

export default RetireOrganizationsList;
