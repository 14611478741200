import { useEffect, useState } from 'react';

import { getArchivedProductsStart } from 'actions';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from 'components/Transactions/utils/constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';

const useArchivedProducts = () => {
  const { isAuthenticated } = useProductSlicesCombiner();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const handlePageChange = (page: any) => {
    setPage(page);
  };
  const handlePageSizeChange = (size: any) => {
    setPageSize(size);
    setPage(0);
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getArchivedProductsStart(page, pageSize));
    } else {
      navigate('/');
    }
  }, [pageSize]);

  return { handlePageChange, handlePageSizeChange, page, pageSize };
};

export default useArchivedProducts;
