import { hideJSONDiffCheckProductAttributesModal } from 'actions';

import { FooterModalPropsType } from '../../../../../utils/types/types';

const useJSONDiffCheckAttributesModalConfig = () => {
  //modal components
  const modalHeader = {
    title: '',
  };
  const modalFooter: FooterModalPropsType = {
    submitLabel: 'Close',
    onSubmitHandler: hideJSONDiffCheckProductAttributesModal(),
  };

  return {
    header: modalHeader,
    footer: modalFooter,
  };
};

export default useJSONDiffCheckAttributesModalConfig;
