import { useContext } from 'react';

import { Button, DialogActions, DialogContent } from '@mui/material';
import { TextField as MaterialUiTextField } from '@mui/material';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';

import {
  closeAllocateFormDialog,
  displayAllocateWarningInDialog,
} from '../../../../../actions';
import useBalancesChildComponentsSlices from '../../../Redux/CustomHooks/useBalancesChildComponentsSlices';
import OrganizationsContext, {
  OrganizationContextType,
} from '../../Dialogs/CombinedDialogs/OrganizationsContext';

import AddNewBeneficiaryBtn from './Fields/AddNewBeneficiaryBtn';
import BeneficiaryCheckbox from './Fields/BeneficiaryCheckbox';
import OrganizationsList from './Fields/RetireOrganizationsList';
import VolumeToRetire from './Fields/VolumeToRetire';
import {
  getInitialValues,
  onValidateHandler,
  retrieveCurrentAllocateCertificatesData,
} from './tools/utils';
import { RetireNumericFormatPropsType } from './types';

const quantityTypeUppercase =
  process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum' ? 'Quantity' : 'Volume';

const RetireForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const {
    organizationsInAllocateMenu,
    activateOzConversion,
  }: OrganizationContextType = useContext(OrganizationsContext);
  const {
    allocateCertificatesData,
    selectedBalanceData,
    user,
  }: UseBalancesChildComponentsSlicesType = useBalancesChildComponentsSlices();
  const onSubmitHandler = (values: any): void => {
    const currentAllocateCertificatesData =
      retrieveCurrentAllocateCertificatesData(
        location,
        values,
        activateOzConversion,
        user.user_metadata.organization
      );
    dispatch(displayAllocateWarningInDialog(currentAllocateCertificatesData));
  };

  const retireHandler = (): void => {
    navigate('/organizations/create');
  };

  const retireFormikProps = {
    initialValues: getInitialValues(
      allocateCertificatesData,
      organizationsInAllocateMenu,
      activateOzConversion,
      location
    ),
    onSubmit: (values: any) => onSubmitHandler(values),
    validate: (values: any) =>
      onValidateHandler(
        values,
        selectedBalanceData,
        activateOzConversion,
        quantityTypeUppercase
      ),
  };

  const getRetireNumericFormatProps = (
    values: any,
    setFieldValue: (v: string, vv: any) => void
  ): RetireNumericFormatPropsType => {
    return {
      style: { width: 380, marginTop: 20 },
      name: 'volume',
      label: `${quantityTypeUppercase} (${
        activateOzConversion ? 'oz' : allocateCertificatesData._origin?.unit
      })`,
      value: values.volume,
      thousandSeparator: ' ',
      customInput: MaterialUiTextField,
      onValueChange: (val: any) => {
        setFieldValue('volume', val.floatValue);
      },
    };
  };

  return (
    <div>
      <Formik {...retireFormikProps}>
        {({ submitForm, values, errors, setFieldValue }) => {
          return (
            <>
              <DialogContent
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: 20,
                }}
              >
                <VolumeToRetire
                  getRetireNumericFormatProps={getRetireNumericFormatProps}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />
                <BeneficiaryCheckbox />
                <OrganizationsList values={values} errors={errors} />
                <AddNewBeneficiaryBtn
                  retireHandler={retireHandler}
                  values={values}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => dispatch(closeAllocateFormDialog())}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={submitForm}
                  variant="contained"
                  color="primary"
                  disabled={
                    errors?.volume !== undefined ||
                    errors?.organization !== undefined
                  }
                >
                  Retire
                </Button>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default RetireForm;
