import { uploadSourceAndData } from 'actions';
import ModalFooterUploadDataForm from 'components/Administration/Generic/Form/CustomForms/Upload/CustomComponents/UploadDataForm/Form/Footer/ModalFooterUploadDataForm';
import GenericModalTitle from 'components/Administration/Generic/Modal/Title/GenericModalTitle';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { Formik, FormikValues } from 'formik';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import uploadIcon from '../../../../../../../../assets/images/icons/upload.png';

import ModalContentUploadDataForm from './Form/Content/ModalContentUploadDataForm';
import { getValidationSchema } from './Form/FormSchemaValidation/validationForm';

const UploadDataForm = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    products,
    localSourceDocument,
    minVolumeToUpload,
    alreadyUploadedVolume,
  } = useAdministrationSlicesCombiner();
  const onSubmitHandler = (values: FormikValues) => {
    const fullOrigin = (products as any[]).find(
      (product) => product._id === values.origin
    );
    const dataset = {
      origin: fullOrigin,
      month: values.month,
      timezone: values.timezone,
      value: values.volume,
      addedVolume: values.volumeToIncrement,
      year: values.year,
      type: values.type,
    };
    dispatch(
      uploadSourceAndData({
        dataset: dataset,
        sourceDocument: localSourceDocument,
      })
    );
  };
  const initialValues = {
    origin: '',
    volume: 0,
    year: moment().format('YYYY'),
    month: '01',
    type: 'quantity',
    timezone: 'America/New_York',
  };

  return (
    <>
      <GenericModalTitle
        title={'Upload volume data for a single product'}
        icon={uploadIcon}
      />

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onSubmitHandler(values);
        }}
        validate={(values: FormikValues) => {
          return getValidationSchema(
            values,
            products as any[],
            minVolumeToUpload,
            alreadyUploadedVolume
          );
        }}
      >
        {() => {
          return (
            <>
              <ModalContentUploadDataForm />
              <ModalFooterUploadDataForm />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default UploadDataForm;
