import React from 'react';

import GenericModalContent from 'components/Administration/Generic/Modal/Content/GenericModalContent';

import useIssueCertificatesInBulkFormModalConfig from '../../Configuration/useIssueCertificatesInBulkFirstModalConfig';

const ModalContentIssueCertificatesFirstInBulkForm = () => {
  const { header, content } = useIssueCertificatesInBulkFormModalConfig();

  return <GenericModalContent header={header} content={content} />;
};

export default ModalContentIssueCertificatesFirstInBulkForm;
