import React, { useContext } from 'react';

import { DialogContent, Grid, Typography } from '@mui/material';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import { CertificatesDataType } from 'reducers/balancesReducer/types';

import { convertGramsInOz } from '../../../../../../utils/convert';
import useBalancesChildComponentsSlices from '../../../../Redux/CustomHooks/useBalancesChildComponentsSlices';
import { getTransferOrAllocateToName } from '../../../../utils/data/utils';
import {
  addThousandsSeparatorToNumber,
  formatMonthYear,
} from '../../../../utils/table/helpers';
import OrganizationsContext, {
  OrganizationContextType,
} from '../../CombinedDialogs/OrganizationsContext';
import { EnumOperationTransferOrRetire } from '../../RetireDialog/enums';

import { NextTransferOrAllocateConfirmContentPropsType } from './types';

const displayPlatinumUI: boolean =
  process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';
const periodTitleInSummary: string = displayPlatinumUI ? 'Half Year' : 'Month';

const NextTransferOrAllocateConfirmFormContent = ({
  operationName = EnumOperationTransferOrRetire.TRANSFER,
}: NextTransferOrAllocateConfirmContentPropsType) => {
  const message = 'Please check if the data are Ok before proceeding to the ';
  const {
    activateOzConversion,
    organizationsInTransferMenu: organizationsInTransferMenuRender,
    organizationsInAllocateMenu: organizationsInAllocateMenuRender,
  }: OrganizationContextType = useContext(OrganizationsContext);

  const {
    organizationsInTransferMenuFiltredForSuperAdmin,
    organizationsInAllocateMenuFiltredForSuperAdmin,
    user,
    isAuthenticated,
    transferCertificatesData,
    allocateCertificatesData,
  }: UseBalancesChildComponentsSlicesType = useBalancesChildComponentsSlices();

  const cerficatesData: CertificatesDataType =
    operationName === EnumOperationTransferOrRetire.TRANSFER
      ? transferCertificatesData
      : allocateCertificatesData;

  const organizationsInTransferOrAllocateMenuRender =
    operationName === EnumOperationTransferOrRetire.TRANSFER
      ? organizationsInTransferMenuRender
      : organizationsInAllocateMenuRender;

  const organizationsInTransferOrAllocateMenuFiltredForSuperAdmin =
    operationName === EnumOperationTransferOrRetire.TRANSFER
      ? organizationsInTransferMenuFiltredForSuperAdmin
      : organizationsInAllocateMenuFiltredForSuperAdmin;

  const transferOrAllocateToName: string = React.useMemo(
    () =>
      getTransferOrAllocateToName(
        cerficatesData,
        organizationsInTransferOrAllocateMenuRender,
        organizationsInTransferOrAllocateMenuFiltredForSuperAdmin,
        user,
        isAuthenticated,
        operationName
      ),
    [
      cerficatesData,
      isAuthenticated,
      operationName,
      organizationsInTransferOrAllocateMenuFiltredForSuperAdmin,
      organizationsInTransferOrAllocateMenuRender,
      user,
    ]
  );
  return (
    <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Typography variant="body2" style={{ paddingBottom: 10 }}>
            {`${message}${operationName}`}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ marginBottom: 6 }}>
          <Typography
            variant="caption"
            style={{ color: 'rgba(0, 0, 0, 0.54)' }}
          >
            Product name (Product id)
          </Typography>
          <Typography variant="subtitle2" color="primary">
            {cerficatesData._origin?.displayedName} (
            {cerficatesData._origin?.productId})
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ marginBottom: 6 }}>
          <Typography
            variant="caption"
            style={{ color: 'rgba(0, 0, 0, 0.54)' }}
          >
            {periodTitleInSummary}
          </Typography>
          <Typography variant="subtitle2" color="primary">
            {formatMonthYear(
              cerficatesData._year + '-' + cerficatesData._month
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ marginBottom: 6 }}>
          <Typography
            variant="caption"
            style={{ color: 'rgba(0, 0, 0, 0.54)' }}
          >
            From
          </Typography>
          <Typography variant="subtitle2" color="primary">
            {cerficatesData._from?.name}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ marginBottom: 6 }}>
          <Typography
            variant="caption"
            style={{ color: 'rgba(0, 0, 0, 0.54)' }}
          >
            To
          </Typography>
          <Typography variant="subtitle2" color="primary">
            {transferOrAllocateToName}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 6 }}>
          <Typography
            variant="caption"
            style={{ color: 'rgba(0, 0, 0, 0.54)' }}
          >
            Quantity
          </Typography>
          <Typography variant="subtitle2" color="primary">
            {activateOzConversion
              ? `${addThousandsSeparatorToNumber(
                  parseInt(convertGramsInOz(cerficatesData._volume).toString())
                )} oz`
              : `${addThousandsSeparatorToNumber(
                  parseInt(cerficatesData._volume.toString())
                )}  ${cerficatesData._origin?.unit}`}
          </Typography>
          {activateOzConversion && (
            <div style={{ paddingLeft: 0 }}>
              <span
                style={{
                  fontSize: 10,
                  color: 'rgba(0, 0, 0, 0.6)',
                }}
              >
                ≈ {parseInt(cerficatesData._volume.toString())} g (data are
                recorded in grams)
              </span>
            </div>
          )}
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default NextTransferOrAllocateConfirmFormContent;
