import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const PageTitle = (props: any): JSX.Element => {
  const loading = props.loading;
  const title = props.title;
  const image = props.image;

  const titleContainerStyle = {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  };
  return (
    <Box flexGrow={1} style={titleContainerStyle as React.CSSProperties}>
      {image && <img src={image} style={{ maxHeight: 20, marginRight: 20 }} />}
      <Typography variant="h6">{title}</Typography>
      {loading && <CircularProgress size={16} style={{ marginLeft: 10 }} />}
    </Box>
  );
};

export default PageTitle;
