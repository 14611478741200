import { Box, Button, Grid } from '@mui/material';
import { openCreateListItemDialog } from 'actions';
import { FormikValues, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const AddItemToListButton = () => {
  const { values } = useFormikContext<FormikValues>();
  const formCurrentStandard = values;
  const dispatch: AppDispatch = useDispatch();
  return (
    <Grid
      item
      xs={12}
      style={{
        paddingBottom: 10,
      }}
    >
      <Box
        sx={{ display: 'flex' }}
        style={{
          width: '100%',
          paddingBottom: 10,
          marginTop: 30,
          marginLeft: 72,
          marginBottom: -30,
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 20,
        }}
      >
        <span style={{ fontStyle: 'oblique', paddingLeft: 10 }}>
          List of items
        </span>
        <Button
          onClick={() => {
            dispatch(
              openCreateListItemDialog(formCurrentStandard.infoTexts.length)
            );
          }}
          variant="contained"
          color="primary"
        >
          + Add
        </Button>
      </Box>
    </Grid>
  );
};

export default AddItemToListButton;
