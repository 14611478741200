const downloadFileWithCookie = async (fileName) => {
  const url = `${process.env.REACT_APP_API_URL}/static/data/${fileName}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok for ${fileName}`);
    }
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error(`Error downloading file ${fileName}:`, error);
  }
};

export default downloadFileWithCookie;
