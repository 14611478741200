import { useEffect, useState } from 'react';

import { getOrganizationsInIncreaseDecreaseMenu } from 'components/Administration/Generic/Form/utils/utils';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { FormikValues, useFormikContext } from 'formik';

const useDecreaseBalanceOrganizationsMenu = () => {
  const { values } = useFormikContext<FormikValues>();
  const { products, organizations } = useAdministrationSlicesCombiner();
  const [organizationsInDecreaseMenu, setOrganizationsInDecreaseMenu] =
    useState([]);

  useEffect(() => {
    const organizationsInDecreaseMenu = getOrganizationsInIncreaseDecreaseMenu(
      values?.origin,
      products as any[],
      organizations
    );
    setOrganizationsInDecreaseMenu(organizationsInDecreaseMenu);
  }, [values, products, organizations]);
  return { organizationsInDecreaseMenu };
};

export default useDecreaseBalanceOrganizationsMenu;
