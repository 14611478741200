import { useEffect, useState } from 'react';

import { getTimeZones } from 'utils/timeZone/TimeZones';

export const useTimezones = () => {
  const [timezones, setTimezones] = useState<string[]>([]);
  useEffect(() => {
    setTimezones(getTimeZones().map((tz) => tz.value));
  }, [getTimeZones]);

  return { timezones };
};
