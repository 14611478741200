import {
  closeActionDialog,
  closeBulkIssuanceForm,
  closeCheckAttributesForm,
  closeDecreaseBalanceFormDialog,
  closeIncreaseBalanceFormDialog,
  closeIssuanceForm,
  closeNotificationOfIssuanceForm,
} from 'actions';

export type ActionKey =
  | 'increase_balance'
  | 'decrease_balance'
  | 'force_decache_balances'
  | 'check_attributes'
  | 'issuance_email_notification'
  | 'set_quantity_single'
  | 'set_quantity_multiple'
  | 'issue_certificate'
  | 'issue_certificate_bulk'
  | 'transfer_retire_bulk'
  | 'get_scheduled_tasks_list'
  | 'get_user_logs';

type CloseAction = () => (dispatch: any) => void;

type CloseActionWithArg = (dialogIdentifier?: any) => (dispatch: any) => void;

export const closeActionsOnBackropMap: Record<
  ActionKey,
  CloseAction | CloseActionWithArg
> = {
  //Admin
  increase_balance: closeIncreaseBalanceFormDialog,
  decrease_balance: closeDecreaseBalanceFormDialog,
  force_decache_balances: closeActionDialog,
  check_attributes: closeCheckAttributesForm,
  issuance_email_notification: closeNotificationOfIssuanceForm,
  //Operations
  set_quantity_single: closeActionDialog,
  set_quantity_multiple: closeActionDialog,
  issue_certificate: closeIssuanceForm,
  issue_certificate_bulk: closeBulkIssuanceForm,
  transfer_retire_bulk: closeActionDialog,
  //Monitoring
  get_scheduled_tasks_list: closeActionDialog,
  get_user_logs: closeActionDialog,
};
