import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import useBalancesChildComponentsSlices from 'components/Balances/Redux/CustomHooks/useBalancesChildComponentsSlices';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import {
  setBalanceProductType,
  setBalanceStandard,
  setBalanceStandardType,
} from '../../../../actions';
import walletIcon from '../../../../assets/images/icons/wallet2.png';
import GramsToOzToggleSwitch from '../../../commons/GramsToOzToggleSwitch';
import LoadingCircle from '../../../commons/LoadingCircle';
import PageTitle from '../../../commons/PageTitle';

type BalancesToolbarPropsType = {
  loading: boolean;
};
export const BalancesToolbar = ({ loading }: BalancesToolbarPropsType) => {
  const displayPlatinumUI =
    process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';

  const dispatch: AppDispatch = useDispatch();

  const {
    loadingUniqueStandardsFromBalances,
    uniqueStandardsFromBalances,
    selectedStandardId,
    selectedStandardType,
    selectedProductTypeId,
    productTypes,
    loadingProductTypes,
  }: UseBalancesChildComponentsSlicesType = useBalancesChildComponentsSlices();

  const handleProductTypeChange = (event: SelectChangeEvent<string>) => {
    const newProductTypeId = event.target.value;
    dispatch(setBalanceProductType(newProductTypeId));
  };

  const handleBalanceStandardChange = (event: SelectChangeEvent<string>) => {
    if (!displayPlatinumUI) {
      const newStandardId = event.target.value;
      dispatch(setBalanceStandard(newStandardId));
    } else {
      const newStandardType = event.target.value;
      dispatch(setBalanceStandardType(newStandardType));
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
      }}
      data-testid="balances-toolbar-id"
    >
      <PageTitle title={'Balances'} loading={loading} image={walletIcon} />
      <Box>
        {displayPlatinumUI && (
          <div style={{ float: 'left', marginRight: 20, marginTop: 14 }}>
            <GramsToOzToggleSwitch />
          </div>
        )}
        {loadingUniqueStandardsFromBalances && (
          <LoadingCircle style={{ marginLeft: 20 }} />
        )}
        {!loadingUniqueStandardsFromBalances && !displayPlatinumUI && (
          <Select
            data-testid="select-all-standards"
            labelId="select-balances-standard"
            name="select-balances-standard"
            id="select-balances-standard"
            value={selectedStandardId ? selectedStandardId : `allStandards`}
            onChange={(e) => handleBalanceStandardChange(e)}
          >
            <MenuItem key={`allStandards`} value={`allStandards`}>
              All standards
            </MenuItem>
            {uniqueStandardsFromBalances.map((s) => (
              <MenuItem key={s._id} value={s._id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        )}
        {!loadingProductTypes && displayPlatinumUI && (
          <Select
            data-testid="platinum-select-product-type-id"
            labelId="select-product-type"
            id="select-product-type"
            value={
              selectedProductTypeId ? selectedProductTypeId : `allProductTypes`
            }
            onChange={(e: SelectChangeEvent<string>) =>
              handleProductTypeChange(e)
            }
            style={{ marginRight: 20 }}
          >
            <MenuItem key={`allProductTypes`} value={`allProductTypes`}>
              All metals
            </MenuItem>
            {productTypes.map((p) => (
              <MenuItem key={p._id} value={p._id}>
                {p.name} {p.shortName}
              </MenuItem>
            ))}
          </Select>
        )}
        {!loadingUniqueStandardsFromBalances && displayPlatinumUI && (
          <Select
            data-testid="platinum-select-balances-standard-id"
            labelId="select-balances-standard"
            id="select-balances-standard"
            value={selectedStandardType ? selectedStandardType : `allStandards`}
            onChange={(e: SelectChangeEvent<string>) =>
              handleBalanceStandardChange(e)
            }
          >
            <MenuItem key={`allStandards`} value={`allStandards`}>
              All standards
            </MenuItem>
            {uniqueStandardsFromBalances
              .filter(
                (standard, index, array) =>
                  array.findIndex(
                    (std) => std.standardType === standard.standardType
                  ) === index
              )
              .map((s) => (
                <MenuItem key={s.standardType} value={s.standardType}>
                  {s.standardType}
                </MenuItem>
              ))}
          </Select>
        )}
      </Box>
    </div>
  );
};
