import { enums } from '../enums';

export const OrganizationTypes = [
  {
    value: enums.OrganizationTypes.INDEPENDANT,
    label: 'Independant',
  },
  {
    value: enums.OrganizationTypes.TEO,
    label: 'The energy Origin',
  },
];
