import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useUploadConfirmationDataForm from '../../Configuration/useUploadConfirmationDataForm';

const ModalFooterUploadConfirmationDataForm = () => {
  const { footer } = useUploadConfirmationDataForm();

  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterUploadConfirmationDataForm;
