/* eslint-disable */

import React, { useEffect } from 'react';
import { useTheme, css, ClassNames } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { DataGridPro as XGrid } from '@mui/x-data-grid-pro';
import PageTitle from '../commons/PageTitle';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import archiveIcon from '../../assets/images/icons/open-box.png';

import organizationsIcon from '../../assets/images/icons/company.png';
import { withRouter } from '../../utils/migrationHelper';
import { withTranslation } from 'react-i18next';

import { getOrganizations } from '../../actions';

import { checkUserIsSuperAdmin } from '../../utils/user/checkAdmin/CheckAdmin';

import { listStyles } from '../../assets/styles/listStyles';
import { checkUserHasRight } from 'utils/user/checkRights';
import { RightReferences } from 'enums/RightReferences';
import { CrudPermissions } from 'enums/CrudPermissions';

let OrganizationList = ({
  isAuthenticated,
  user,
  getOrganizations,
  organizations,
  loading,
  t,
}) => {
  let navigate = useNavigate();
  const classes = listStyles;

  useEffect(() => {
    if (isAuthenticated) {
      getOrganizations();
    } else {
      navigate('/');
    }
  }, []);

  const renderCreateButton = () => {
    if (
      checkUserIsSuperAdmin(isAuthenticated, user) ||
      checkUserHasRight(isAuthenticated, user, RightReferences.ORGANIZATIONS, [
        CrudPermissions.UPDATE,
      ])
    ) {
      return (
        <Fab
          color="primary"
          aria-label="add"
          style={{
            right: 48,
            top: 85,
            left: 'auto',
            position: 'fixed',
          }}
          onClick={() => {
            navigate('/organizations/create');
          }}
        >
          <AddIcon />
        </Fab>
      );
    } else return false;
  };

  const renderArchivedOrganizationsButton = () => {
    if (
      !(
        checkUserIsSuperAdmin(isAuthenticated, user) ||
        checkUserHasRight(
          isAuthenticated,
          user,
          RightReferences.ORGANIZATIONS,
          [CrudPermissions.UPDATE]
        )
      )
    )
      return false;

    return (
      <ClassNames>
        {({ css }) => (
          <Button
            size="small"
            color="primary"
            aria-label="archived-organizations"
            className={css(classes.button)}
            onClick={() => {
              navigate('/organizations/archived');
            }}
            style={{
              marginTop: -98,
              marginRight: 35,
              marginLeft: 35,
              marginBottom: 6,
            }}
          >
            <img src={archiveIcon} style={{ maxHeight: 20, marginRight: 10 }} />{' '}
            Archived organizations
          </Button>
        )}
      </ClassNames>
    );
  };

  const dataGridRows = organizations.map((o) => {
    return { ...o, id: o._id };
  });
  const dataGridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      width: 600,
    },
  ];
  return (
    <>
      <div
        style={{
          height: document.documentElement.clientHeight - 200,
          paddingBottom: 83,
        }}
      >
        <PageTitle
          title={'Organizations'}
          loading={loading}
          image={organizationsIcon}
        />
        <XGrid
          pagination
          disableSelectionOnClick={true}
          loading={loading}
          rows={dataGridRows}
          columns={dataGridColumns}
          onRowClick={(rowData) => {
            let organizationId = rowData.row._id;
            navigate('/organizations/read/' + organizationId);
          }}
        />
      </div>
      {renderArchivedOrganizationsButton()}
      {renderCreateButton()}
    </>
  );
};

const mapStateToProps = ({ organizationsReducer, loginReducer }) => {
  const { organizations, loading } = organizationsReducer;
  const { isAuthenticated, user } = loginReducer;
  return {
    organizations,
    loading,
    isAuthenticated,
    user,
  };
};

const mapDispatchToProps = {
  getOrganizations,
};

OrganizationList = withTranslation('translations')(OrganizationList);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationList)
);
