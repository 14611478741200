import { Box, InputAdornment, OutlinedInput, Typography } from '@mui/material';

import CopyButton from '../CopyButton/CopyButton';

type SecretWordInputProps = {
  label: string;
  value: string;
  message: string;
};

const SecretWordInput = ({ label, value, message }: SecretWordInputProps) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" mb={2}>
      <Box width={100}>
        <Typography variant="body2" gutterBottom>
          {label}
        </Typography>
      </Box>
      <OutlinedInput
        type="text"
        disabled
        inputProps={{
          style: {
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: 12,
            fontWeight: 100,
            paddingTop: 12,
            paddingBottom: 12,
          },
        }}
        value={value}
        endAdornment={
          <InputAdornment position="end">
            <CopyButton text={value} message={message} />
          </InputAdornment>
        }
        style={{
          minWidth: 400,
          backgroundColor: 'rgba(0, 0, 0, 0.09)',
        }}
      />
    </Box>
  );
};

export default SecretWordInput;
