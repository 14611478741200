import {
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import { closeRemoveListItemDialog, removeListItem } from 'actions';
import useFormStandardsSlicesCombiner from 'components/FormStandards/Redux/Slices/Combiner/useFormStandardsCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const RemoveItemDialog = () => {
  const { removeListItemDialogOpen, listItemData } =
    useFormStandardsSlicesCombiner();
  const dispatch: AppDispatch = useDispatch();
  return (
    <Dialog
      disableEnforceFocus
      fullScreen={false}
      open={removeListItemDialogOpen as boolean}
      aria-labelledby="action-dialog-title"
    >
      <DialogContent>
        <CardHeader
          title={`Are you sure ?`}
          subheader={`Confirm that you want to remove this item`}
        />
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={6}>
                  Reference
                </Grid>
                <Grid item xs={6}>
                  {listItemData?.ref}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={6}>
                  Value
                </Grid>
                <Grid item xs={6}>
                  {listItemData?.value}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => dispatch(closeRemoveListItemDialog())}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            dispatch(closeRemoveListItemDialog());
            dispatch(removeListItem(listItemData));
          }}
          variant="contained"
          color="primary"
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveItemDialog;
