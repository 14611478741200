export const Units = {
  KWH: 'kWh',
  MMBTU: 'MMBTU',
  GRAMS: 'Grams',
  MWH: 'MWh',
  GWH: 'GWh',
  KG: 'kg',
  TONS: 'Tons',
  OZ: 'oz',
  VOYAGES: 'Voyages',
};
