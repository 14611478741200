import * as math from 'mathjs';
import moment from 'moment';

import { enums } from '../../../../enums';
import {
  convertGramsInOz,
  convertMmbtuInKwh,
  convertWhInMmbtu,
} from '../../../../utils/convert';
import { getCertificateInfo } from '../../../../utils/datagridHelpers';

let displayPlatinumUI = process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';

let quantityUnit = '';

export const dateRange = (startDate, endDate) => {
  var start = startDate.split('-');
  var end = endDate.split('-');
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? '0' + month : month;
      dates.push([i, displayMonth, '01'].join('-'));
    }
  }
  return dates;
};

export const getLabels = (startDate, endDate) => {
  let labels = [];
  let startDateForLabels = moment(startDate).format('YYYY-MM-DD');
  let endDateForLabels = moment(endDate).format('YYYY-MM-DD');
  let dates = dateRange(startDateForLabels, endDateForLabels);

  dates.forEach((date) => {
    labels.push(moment(date, 'YYYY-MM-DD').format('YYYY-MM'));
  });
  return labels;
};

const processQuantityUnitBasedOnUserOganizationUnit = (
  userOrganizationUnit
) => {
  switch (userOrganizationUnit) {
    case enums.Units.MMBTU:
      quantityUnit = enums.Units.MMBTU;
      break;
    case enums.Units.KWH:
      quantityUnit = enums.Units.KWH;
      break;
    case enums.Units.GRAMS:
      quantityUnit = enums.Units.GRAMS;
      break;
    case enums.Units.VOYAGES:
      quantityUnit = enums.Units.VOYAGES;
      break;
    case enums.Units.TONS:
      quantityUnit = enums.Units.TONS;
      break;
    case enums.Units.KG:
      quantityUnit = enums.Units.KG;
      break;
    default:
      quantityUnit = enums.Units.KWH;
      break;
  }
  return quantityUnit;
};

const processQuantityUnitBasedOnKWH = (maxQuantity, quantityUnit) => {
  if (quantityUnit === enums.Units.KWH) {
    if (maxQuantity < 1000) {
      quantityUnit = enums.Units.KWH;
    } else if (maxQuantity >= 1000 && maxQuantity < 1000000) {
      quantityUnit = 'MWh';
    } else if (maxQuantity >= 1000000) {
      quantityUnit = 'GWh';
    }
  }
  return quantityUnit;
};

const processQuantityUnitBasedOnGrams = (
  maxQuantity,
  ozOrGramsQuantityUnit,
  quantityUnit
) => {
  if (quantityUnit === enums.Units.GRAMS) {
    if (ozOrGramsQuantityUnit === enums.Units.GRAMS) {
      if (maxQuantity < 1000) {
        quantityUnit = enums.Units.GRAMS;
      } else if (maxQuantity >= 1000 && maxQuantity < 1000000) {
        quantityUnit = 'kg';
      } else if (maxQuantity >= 1000000) {
        quantityUnit = 'Ton';
      }
    } else {
      quantityUnit = 'oz';
    }
  }
  return quantityUnit;
};

const processQuantityValuesBasedOnQuantityUnit = (
  quantityValues,
  quantityUnit
) => {
  switch (quantityUnit) {
    case 'MWh':
      quantityValues = quantityValues.map((q) => q / 1000);
      break;

    case 'GWh':
      quantityValues = quantityValues.map((q) => q / 1000000);
      break;

    case 'kg':
      quantityValues = quantityValues.map((q) => q / 1000);
      break;

    case 'Ton':
      quantityValues = quantityValues.map((q) => q / 1000000);
      break;

    case 'oz':
      quantityValues = quantityValues.map((q) => convertGramsInOz(q));
      break;

    default:
      break;
  }
  return quantityValues;
};

const processAllocatedUnitBasedOnKWH = (
  maxAllocatedQuantity,
  allocatedUnit
) => {
  if (allocatedUnit === enums.Units.KWH) {
    if (maxAllocatedQuantity < 1000) {
      allocatedUnit = enums.Units.KWH;
    } else if (maxAllocatedQuantity >= 1000 && maxAllocatedQuantity < 1000000) {
      allocatedUnit = 'MWh';
    } else if (maxAllocatedQuantity >= 1000000) {
      allocatedUnit = 'GWh';
    }
  }
  return allocatedUnit;
};

const processAllocatedUnitBasedOnGrams = (
  ozOrGramsQuantityUnit,
  maxAllocatedQuantity,
  allocatedUnit
) => {
  if (allocatedUnit === enums.Units.GRAMS) {
    if (ozOrGramsQuantityUnit === enums.Units.GRAMS) {
      if (maxAllocatedQuantity < 1000) {
        allocatedUnit = enums.Units.GRAMS;
      } else if (
        maxAllocatedQuantity >= 1000 &&
        maxAllocatedQuantity < 1000000
      ) {
        allocatedUnit = 'kg';
      } else if (maxAllocatedQuantity >= 1000000) {
        allocatedUnit = 'Ton';
      }
    } else {
      allocatedUnit = 'oz';
    }
  }
  return allocatedUnit;
};

const processAllocatedQuantityValuesBasedOnAllocatedUnit = (
  allocatedQuantityValues,
  convertGramsInOz,
  allocatedUnit
) => {
  switch (allocatedUnit) {
    case 'MWh':
      allocatedQuantityValues = allocatedQuantityValues.map((q) => q / 1000);
      break;
    case 'GWh':
      allocatedQuantityValues = allocatedQuantityValues.map((q) => q / 1000000);
      break;
    case 'kg':
      allocatedQuantityValues = allocatedQuantityValues.map((q) => q / 1000);
      break;
    case 'Ton':
      allocatedQuantityValues = allocatedQuantityValues.map((q) => q / 1000000);
      break;
    case 'oz':
      allocatedQuantityValues = allocatedQuantityValues.map((q) =>
        convertGramsInOz(q)
      );
      break;
    default:
      break;
  }
  return allocatedQuantityValues;
};

export const getAggregatedBalancesByMonth = (
  balances,
  user,
  ozOrGramsQuantityUnit,
  startDate,
  endDate
) => {
  let labels = getLabels(startDate, endDate);
  let allocatedQuantityValues = [];
  let quantityValues = [];

  let userOrganizationUnit = user.user_metadata.organization.unit;

  labels.forEach((label, index) => {
    quantityValues[index] = 0;
    allocatedQuantityValues[index] = 0;
    balances.forEach((balance) => {
      if (
        moment(balance._year + '-' + balance._month, 'YYYY-MM').format(
          'YYYY-MM'
        ) === label
      ) {
        let productUnit = balance._origin.unit;
        //We can't manage the sum of grams with Wh or MMBTU
        if (
          productUnit === userOrganizationUnit &&
          (productUnit === enums.Units.MMBTU ||
            productUnit === enums.Units.GRAMS ||
            productUnit === enums.Units.TONS ||
            productUnit === enums.Units.VOYAGES ||
            productUnit === enums.Units.KG)
        ) {
          //No conversion to do
          quantityValues[index] += balance._quantity;
          allocatedQuantityValues[index] += balance._allocatedQuantity;
        } else if (
          productUnit === userOrganizationUnit &&
          productUnit === enums.Units.KWH
        ) {
          //Convert Wh to kWh
          quantityValues[index] += balance._quantity / 1000;
          allocatedQuantityValues[index] += balance._allocatedQuantity / 1000;
        } else {
          //This test is useless and not used anymore, need to be removed in a future MR dedicated to cleaning Units
          if (productUnit === enums.Units.MMBTU) {
            //Convert MMBTU in kWh
            let quantityInkWh = convertMmbtuInKwh(balance._quantity);
            quantityValues[index] += quantityInkWh;

            let allocatedQuantityInkWh = convertMmbtuInKwh(
              balance._allocatedQuantity
            );
            allocatedQuantityValues[index] += allocatedQuantityInkWh;
          } else if (productUnit === enums.Units.KWH) {
            //Convert Wh in MMBTU
            let quantityInMmbtu = convertWhInMmbtu(balance._quantity);
            quantityValues[index] += quantityInMmbtu;

            let allocatedQuantityInMmbtu = convertWhInMmbtu(
              balance._allocatedQuantity
            );
            allocatedQuantityValues[index] += allocatedQuantityInMmbtu;
          }
        }
      }
    });
  });

  let maxQuantity = Math.max(...quantityValues);
  quantityUnit =
    processQuantityUnitBasedOnUserOganizationUnit(userOrganizationUnit);

  quantityUnit = processQuantityUnitBasedOnKWH(maxQuantity, quantityUnit);

  quantityUnit = processQuantityUnitBasedOnGrams(
    maxQuantity,
    ozOrGramsQuantityUnit,
    quantityUnit
  );

  quantityValues = processQuantityValuesBasedOnQuantityUnit(
    quantityValues,
    quantityUnit
  );

  let maxAllocatedQuantity = Math.max(...allocatedQuantityValues);

  let allocatedUnit = quantityUnit;
  allocatedUnit = processAllocatedUnitBasedOnKWH(
    maxAllocatedQuantity,
    allocatedUnit
  );

  allocatedUnit = processAllocatedUnitBasedOnGrams(
    ozOrGramsQuantityUnit,
    maxAllocatedQuantity,
    allocatedUnit
  );

  allocatedQuantityValues = processAllocatedQuantityValuesBasedOnAllocatedUnit(
    allocatedQuantityValues,
    convertGramsInOz,
    allocatedUnit
  );
  maxAllocatedQuantity = Math.max(...allocatedQuantityValues);
  maxQuantity = Math.max(...quantityValues);
  return {
    quantityValues,
    maxQuantity,
    quantityUnit,
    allocatedQuantityValues,
    maxAllocatedQuantity,
    allocatedUnit,
  };
};

export const formatMonthYear = (value) => {
  let valueToRender = moment(value, 'YYYY-MM').format('MMM-YYYY');
  if (displayPlatinumUI) {
    let year = moment(value, 'YYYY-MM').format('YY');
    let month = parseInt(moment(value, 'YYYY-MM').format('M'));
    let halfYear = 1;
    if (month > 6) halfYear = 2;
    valueToRender = `${halfYear}H${year}`;
  }

  return valueToRender;
};

export const getAllocatedInitialVolume = (
  location,
  allocateCertificatesData,
  activateOzConversion
) => {
  let initialVolume = location?.state?.allocateVolume
    ? location?.state?.allocateVolume
    : allocateCertificatesData?._volume; //set popup values with location state if available
  let volumeToSend = activateOzConversion
    ? parseInt(convertGramsInOz(initialVolume))
    : initialVolume;
  return volumeToSend;
};

export const addThousandsSeparatorToNumber = (num) => {
  let entry = num ? num : 0;
  return entry.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const addInfoColumn = (
  selectedStandardId,
  selectedStandardType,
  standards
) => {
  let selectedStandard = undefined;
  let dataGridColumns = [];

  if (selectedStandardId) {
    selectedStandard = standards.find((s) => s._id === selectedStandardId);
  }
  if (selectedStandard) {
    for (
      let infoIndex = 0;
      infoIndex < selectedStandard.infoTexts.length;
      infoIndex++
    ) {
      let property = 'info' + infoIndex;
      let infoTextItem = selectedStandard.infoTexts.find(
        (t) => t.infoProp === property
      );
      let infoText =
        infoTextItem && !infoTextItem.isHidden
          ? infoTextItem.infoStandardText
          : '';

      if (infoText) {
        dataGridColumns.push({
          field: '_info' + infoIndex,
          headerName: infoText,
          editable: false,
          width: 200,
          valueGetter: (params) =>
            getCertificateInfo(params, infoIndex, infoTextItem),
        });
      }
    }
  }

  if (selectedStandardType) {
    const myStandard = standards.find(
      (s) => s.standardType === selectedStandardType
    );
    for (
      let infoIndex = 0;
      infoIndex < myStandard.infoTexts.length;
      infoIndex++
    ) {
      let property = 'info' + infoIndex;
      let infoTextItem = myStandard.infoTexts.find(
        (t) => t.infoProp === property
      );
      let infoText =
        infoTextItem && !infoTextItem.isHidden
          ? infoTextItem.infoStandardText
          : '';

      if (infoText) {
        dataGridColumns.push({
          field: '_info' + infoIndex,
          headerName: infoText,
          editable: false,
          width: 200,
          valueGetter: (params) =>
            getCertificateInfo(params, infoIndex, infoTextItem),
        });
      }
    }
  }

  return dataGridColumns;
};

export const convertVolumeFromBlockchain = (
  initialUnit,
  quantity,
  targetUnit = enums.Units.KWH
) => {
  return initialUnit === enums.Units.MMBTU ||
    initialUnit === enums.Units.GRAMS ||
    initialUnit === enums.Units.VOYAGES ||
    initialUnit === enums.Units.TONS ||
    initialUnit === enums.Units.KG
    ? parseFloat(quantity)
    : parseFloat(convertUnits(quantity, 'Wh', targetUnit)); //We receive Wh from the blockchain
};

export const convertUnits = (quantity, fromUnit, toUnit) => {
  try {
    let result = math.unit(quantity, fromUnit).to(toUnit);

    return result.toNumber();
  } catch (error) {
    // Handle cases where the conversion isn't possible
    console.error('Conversion error: ', error.message);
    return null;
  }
};
