import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { AppDispatch } from 'redux/store';
import { getProductAggregationsStart } from 'actions';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';
import useProductSlicesCombiner from '../Slices/useProductSlicesCombiner';
import usePagination from './usePagination';

export const useProductAggregations = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { assetId } = useParams();
  const { isAuthenticated, user } = useProductSlicesCombiner();
  const { page, pageSize, handlePageChange, handlePageSizeChange } =
    usePagination();

  useEffect(() => {
    const initComponent = async () => {
      if (!isAuthenticated) navigate('/');
      else {
        if (
          checkUserIsSuperAdmin(isAuthenticated, user) ||
          checkUserHasRight(isAuthenticated, user, RightReferences.PRODUCTS, [
            CrudPermissions.VIEW,
          ])
        ) {
          await dispatch(getProductAggregationsStart(assetId, page, pageSize));
        }
      }
    };
    initComponent();
  }, [pageSize, page, isAuthenticated, user, assetId, dispatch, navigate]);

  return { handlePageChange, handlePageSizeChange, page, pageSize };
};
