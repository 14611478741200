import { useState } from 'react';

import { ClassNames } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { AppDispatch } from 'redux/store';

import { logoutStart } from '../../actions';
import logoutIcon from '../../assets/images/icons/logout.png';

import DrawerLink from './DrawerLink';
import { LinkType } from './types/LinkType';
import { getDrawerLinks } from './utils/links';
import { useCompanyLogo } from './utils/logo';

const DrawerContent = (props: {
  isAuthenticated: boolean;
  user: {
    user_metadata?: {
      organization?: string;
    };
  };
  cookies: any;
  handleDrawerToggle: any;
  mobileOpen: boolean;
}): JSX.Element => {
  const { isAuthenticated, user, cookies, mobileOpen, handleDrawerToggle } =
    props;
  const location = useLocation();
  const currentLink = `/${location.pathname.split('/')[1]}`;
  const [activeLink, setActiveLink] = useState(currentLink);
  const dispatch: AppDispatch = useDispatch();
  const { companyLogo } = useCompanyLogo(user);

  if (!isAuthenticated) return <></>;

  const linksToDisplay = getDrawerLinks({ isAuthenticated, user });

  return (
    <ClassNames>
      {() => (
        <div>
          <div style={{ padding: 10, maxHeight: 68, minHeight: 54 }}>
            <img
              src={companyLogo}
              alt=""
              style={{
                maxHeight: 28,
                float: 'left',
                position: 'absolute',
                top: 20,
                left: 61,
              }}
            />
            {mobileOpen && (
              <CloseIcon
                style={{ float: 'right', margin: 20, cursor: 'pointer' }}
                onClick={() => {
                  handleDrawerToggle();
                }}
              />
            )}
          </div>
          <List>
            {linksToDisplay.map((link) => (
              <DrawerLink
                key={link.to}
                link={link}
                activeLink={activeLink}
                onClick={(_link: LinkType) => {
                  handleDrawerToggle();
                  setActiveLink(_link.to);
                }}
              />
            ))}
            <ListItemButton
              id="logoutButton"
              onClick={() => {
                handleDrawerToggle();
                dispatch(logoutStart(cookies));
              }}
            >
              <ListItemIcon>
                <img src={logoutIcon} alt="" style={{ maxHeight: 20 }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <>
                    <Typography style={styles.listItemText}>Logout</Typography>
                  </>
                }
              />
            </ListItemButton>
          </List>
        </div>
      )}
    </ClassNames>
  );
};

const styles = {
  activeListItem: {
    backgroundColor: '#F5F5F5',
    textDecoration: 'none',
    '&:focus': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '&:visited': {
      textDecoration: 'none',
    },
    '&:active': {
      textDecoration: 'none',
    },
  },
  listItemText: {
    fontSize: '0.8rem',
    fontFamily: [
      'Inter-Regular',
      'Avenir',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
};
export default DrawerContent;
