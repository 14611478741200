import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { closeActionDialog, setStandardStart } from 'actions';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import LoadingCircle from 'components/commons/LoadingCircle';
import { areFieldsUntouchedOrEmpty } from 'components/FormStandards/utils/utils';
import useStandards from 'components/Standards/Redux/CustomHooks/useStandards';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';

const CleanStandardsForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { standards, loadingStandards } = useAdministrationSlicesCombiner();
  useStandards();

  const cleanStandardsFromEmptyInfoTexts = (standards: any[]) => {
    return standards.map((standard: any) => {
      const cleanedInfoTexts = standard.infoTexts.filter(
        (_: any, index: number) => !areFieldsUntouchedOrEmpty(index, standard)
      );
      return {
        ...standard,
        infoTexts: cleanedInfoTexts,
      };
    });
  };
  const cleanedStandards = cleanStandardsFromEmptyInfoTexts(standards);

  return (
    <>
      <DialogTitle id="responsive-dialog-title">
        {t('Clean standards form')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            You will clean standards from empty attributes
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(closeActionDialog())} color="primary">
          Close
        </Button>
        <Button
          onClick={async () => {
            const promises = cleanedStandards.map((standard) => {
              return dispatch(setStandardStart(standard, navigate));
            });

            try {
              await Promise.all(promises);
              navigate('/standards/');
            } catch (error) {
              console.error('An error occurred:', error);
            }
          }}
          variant="contained"
          color="primary"
        >
          Clean standards from empty attributes
        </Button>
      </DialogActions>
      {loadingStandards && (
        <div>
          <LoadingCircle style={{ marginLeft: 20 }} />
        </div>
      )}
    </>
  );
};

export default CleanStandardsForm;
