import React from 'react';

import { closeActionDialog } from 'actions';
import { FooterModalPropsType } from 'components/Administration/Generic/Form/utils/types/types';
import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import { withRouter } from '../../utils/migrationHelper';

import OperationsTable from './CustomComponents/Table/OperationsTable';

const Operations = () => {
  const footer: FooterModalPropsType = {
    cancelLabel: 'Close',
    onCancelHandler: closeActionDialog,
    displaySubmitBtnElementCondition: false,
    wideModal: true,
  };
  return (
    <>
      <div>
        <OperationsTable />
        <GenericModalFooter footer={footer} />
      </div>
    </>
  );
};

export default withRouter(Operations);
