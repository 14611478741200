import { Grid } from '@mui/material';

import NameField from './AtomicFields/NameField';
import ProductTypeField from './AtomicFields/ProductTypeField';
import StandardTypeField from './AtomicFields/StandardTypeField';

type CommonFieldsPropsType = {
  token: any;
};

const CommonFields = ({ token }: CommonFieldsPropsType) => {
  return (
    <Grid item xs={12}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <NameField token={token} />
        <ProductTypeField />
        <StandardTypeField />
      </Grid>
    </Grid>
  );
};

export default CommonFields;
