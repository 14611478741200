import Paper from '@mui/material/Paper';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';

import DataAcquisitonForm from './CustomComponents/Form/DataAcquisitonForm';
import DataAcquisitonTable from './CustomComponents/Table/DataAcquisitonTable';
import DataAcquisitionToolbar from './CustomComponents/Toolbar/DataAcquisitionToolbar';

import './Styles/index.css';

const ProductDataAcquisitionCard = () => {
  return (
    <GenericGrid
      isItem
      xsLength={12}
      dataTestId="product-data-acquisition-card-id"
    >
      <Paper className="paper">
        <div className="acquisition-content-wrapper">
          <DataAcquisitionToolbar />
          <DataAcquisitonForm />
          <DataAcquisitonTable />
        </div>
      </Paper>
    </GenericGrid>
  );
};

export default ProductDataAcquisitionCard;
