import CircularProgress from '@mui/material/CircularProgress';

type LoadingCirclePropsType = {
  style?: React.CSSProperties;
  size?: number;
};

const LoadingCircle = ({
  style,
  size = 16,
}: LoadingCirclePropsType): JSX.Element => {
  return <CircularProgress style={style} size={size} />;
};

export default LoadingCircle;
