import React from 'react';

import { GridCellEditStopReasons, useGridApiRef } from '@mui/x-data-grid-pro';
import {
  updateProductReferenceFileAttributeStart,
  flushLocalProductReferenceFiles,
  openErrorSnack,
  setLocalReferenceFileLabel,
  setLocalReferenceFileValidityStart,
  setLocalReferenceFileValidityEnd,
  setLocalReferenceFileNotify,
} from 'actions';
import GenericTable from 'components/GenericComponents/Tables/GenericTable/GenericTable';
import { UseProductSlicesCombinerType } from 'components/Product/Redux/CustomHooks/types';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { ProductReferenceFilesPropsType } from 'components/ReferenceFiles/ProductReferenceFiles';
import { getAllDataGridColumns } from 'components/ReferenceFiles/utils/columns';
import {
  getAllRows,
  checkRowOnCommit,
} from 'components/ReferenceFiles/utils/rows';
import { useDispatch } from 'react-redux';
import { ProductFileType } from 'reducers/productsReducer/types';
import { AppDispatch } from 'redux/store';

const ProductReferenceFilesTable = ({
  userCanEditReferenceFiles,
}: ProductReferenceFilesPropsType) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    currentProduct,
    loading,
    localProductReferenceFiles,
    deletingFileIds,
  }: UseProductSlicesCombinerType = useProductSlicesCombiner();

  const [rows, setRows] = React.useState<any[]>([]);

  React.useEffect(() => {
    const updatedDatagridRows: any[] = getAllRows(
      currentProduct?.referenceFiles as ProductFileType[],
      localProductReferenceFiles as any[],
      currentProduct?.balancePeriod as string
    );
    setRows(updatedDatagridRows);
  }, [currentProduct, localProductReferenceFiles]);

  React.useEffect(() => {
    //Flush localProductReferenceFiles on first time form is displayed
    dispatch(flushLocalProductReferenceFiles());
  }, []);

  const apiRef = useGridApiRef();

  const handleCheckRowOnCommitError = (err: string) => {
    dispatch(openErrorSnack(err));
  };

  const handleOnCommitError = (id: string, field: string, error: Error) => {
    console.error(error);
    const value = apiRef.current.getCellValue(id, field);
    apiRef.current.setEditCellValue({ id, field, value });
  };

  const handleCellEditCommitSuccess = (
    productMongoId: string,
    id: string,
    field: string,
    newValue: any
  ) => {
    if (productMongoId) {
      dispatch(
        updateProductReferenceFileAttributeStart(
          productMongoId,
          id,
          field,
          newValue
        )
      );
    }
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        const newRow = { ...row };
        newRow[field] = newValue;
        return newRow;
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleNewLocalFileEdit = (field: string, id: string, newValue: any) => {
    switch (field) {
      case 'label':
        dispatch(setLocalReferenceFileLabel(id, newValue));
        break;
      case 'validityStart':
        dispatch(setLocalReferenceFileValidityStart(id, newValue));
        break;
      case 'validityEnd':
        dispatch(setLocalReferenceFileValidityEnd(id, newValue));
        break;

      default:
        break;
    }
  };

  const handleCellEditCommit = React.useCallback(
    async (data: any) => {
      const { field, id, _id, value } = data;
      const row = rows.find(
        (r) => (r.id && id && r.id === id) || (r._id && _id && r._id === _id)
      );
      if (
        field === 'label' ||
        field === 'validityStart' ||
        field === 'validityEnd'
      ) {
        try {
          checkRowOnCommit(field, value, row, handleCheckRowOnCommitError);
          if (!row._id) handleNewLocalFileEdit(field, id, value);
          handleCellEditCommitSuccess(currentProduct?._id, id, field, value);
        } catch (error: any) {
          handleOnCommitError(id, field, error);
        }
      }
    },
    [rows]
  );

  const handleOnSwitchChange = (event: any, params: any) => {
    if (params.row.id === params.row._id) {
      dispatch(
        updateProductReferenceFileAttributeStart(
          currentProduct?._id,
          params.row._id,
          params.field,
          event.target.checked
        )
      );

      const updatedRows = rows.map((row) => {
        if (row.id === params.row.id) {
          const newRow = { ...row };
          newRow[params.field] = event.target.checked;
          return newRow;
        }
        return row;
      });
      setRows(updatedRows);
    } else {
      dispatch(
        setLocalReferenceFileNotify(params.row.id, event.target.checked)
      );
    }
  };

  const dataGridColumns = getAllDataGridColumns(
    currentProduct,
    deletingFileIds,
    userCanEditReferenceFiles,
    handleOnSwitchChange
  );

  return (
    <GenericTable
      pagination
      autoHeight
      clickableRow={true}
      disableSelectionOnClick={true}
      loading={loading}
      tableColumns={dataGridColumns}
      tableRows={rows}
      isCellEditable={() => true}
      apiRef={apiRef}
      onCellEditCommit={handleCellEditCommit}
      onCellEditStop={(params, event) => {
        if (params.reason === GridCellEditStopReasons.cellFocusOut) {
          event.defaultMuiPrevented = true;
        }
      }}
    />
  );
};

export default ProductReferenceFilesTable;
