import GramsToOzToggleSwitch from 'components/commons/GramsToOzToggleSwitch';
import ProductionChart from 'components/commons/ProductionChart';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';

const ChartContent = () => {
  const { currentProduct, graphDataProducer, ozOrGramsQuantityUnit } =
    useProductSlicesCombiner();
  return (
    <div
      style={{
        height: 300,
      }}
    >
      <ProductionChart
        currentProduct={currentProduct}
        graphDataProducer={graphDataProducer}
        ozOrGramsQuantityUnit={ozOrGramsQuantityUnit}
      />
      <div style={{ float: 'left', margin: 30 }}>
        <GramsToOzToggleSwitch />
      </div>
    </div>
  );
};

export default ChartContent;
