import { CardHeader } from '@mui/material';

import createIcon from '../../../../../../../../assets/images/icons/create.png';

const ProductDetailsCardTitle = () => {
  return (
    <CardHeader
      avatar={<img src={createIcon} style={{ maxHeight: 20 }} alt="" />}
      title="Product details"
    />
  );
};

export default ProductDetailsCardTitle;
