const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const sanitize = (s) => {
  if (typeof s !== 'string') return '';
  return s
    .trim()
    .normalize('NFD')
    .toLowerCase()
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9]+/g, '_');
};

module.exports = {
  capitalize,
  sanitize,
};
