export const InfoTypes = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Dynamic data',
    value: 'dynamicData',
  },
  {
    label: 'List',
    value: 'list',
  },
  {
    label: 'List multi',
    value: 'listMulti',
  },
  {
    label: 'Collection',
    value: 'collection',
  },
  {
    label: 'Collection multi',
    value: 'collectionMulti',
  },
  {
    label: 'Number',
    value: 'number',
  },
];
