import { TableRow, TableCell } from '@mui/material';
import useNominationsGuards from 'components/Nominations/Redux/CustomHooks/useNominationsGuards';
import {
  addThousandsSeparatorToNumber,
  removeNonNumeric,
} from 'components/Nominations/utils/utils';
import moment from 'moment';

type NominationsTableRowPropsType = { nominationsRequestToDo: any };

const NominationsTableRow = ({
  nominationsRequestToDo,
}: NominationsTableRowPropsType) => {
  const { canManageMultipleOrganizationNominations } = useNominationsGuards();
  return (
    <TableRow key={nominationsRequestToDo._id + '-validation'}>
      <TableCell align="right">
        {nominationsRequestToDo.periodType === 'year'
          ? nominationsRequestToDo.year
          : moment(
              nominationsRequestToDo.year + '-' + nominationsRequestToDo.month,
              'YYYY-MM'
            ).format('MMM-YYYY')}
      </TableCell>
      {canManageMultipleOrganizationNominations && (
        <TableCell align="right">
          {nominationsRequestToDo._min
            ? addThousandsSeparatorToNumber(
                removeNonNumeric(nominationsRequestToDo._min)
              )
            : ''}
        </TableCell>
      )}
      {canManageMultipleOrganizationNominations && (
        <TableCell align="right">
          {nominationsRequestToDo._max
            ? addThousandsSeparatorToNumber(
                removeNonNumeric(nominationsRequestToDo._max)
              )
            : ''}
        </TableCell>
      )}
      <TableCell align="right">
        {nominationsRequestToDo._requested
          ? addThousandsSeparatorToNumber(
              removeNonNumeric(nominationsRequestToDo._requested)
            )
          : ''}
      </TableCell>
    </TableRow>
  );
};

export default NominationsTableRow;
