import { Autocomplete, Grid, TextField } from '@mui/material';
import { FormikErrors, useFormikContext } from 'formik';

import { GenericAutocompleteFieldPropsType } from '../../utils/types/types';

const GenericAutocompleteField = ({
  name,
  label,
  selectedValue,
  options,
  onChange,
  showErrors = true,
}: GenericAutocompleteFieldPropsType) => {
  const { errors, touched, setFieldTouched } =
    useFormikContext<FormikErrors<any>>();
  const handleBlur = () => {
    setFieldTouched(name, true, true);
  };
  if (!selectedValue) selectedValue = null;
  return (
    <>
      <Grid item xs={12}>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.key === value.key}
          value={selectedValue}
          options={options}
          sx={{ width: 750, marginBottom: 1 }}
          onChange={onChange}
          getOptionLabel={(option) => option.label || ''}
          onBlur={handleBlur}
          renderInput={(params: any) => {
            return (
              <TextField
                {...params}
                name={name}
                label={label}
                variant="standard"
                error={Boolean(showErrors && errors?.[name] && touched?.[name])}
              />
            );
          }}
        />
      </Grid>
      {showErrors && errors?.[name] && touched?.[name] && (
        <div style={{ marginLeft: 14 }}>
          <span style={{ fontSize: 11, color: '#d32f2f' }}>{errors[name]}</span>
        </div>
      )}
    </>
  );
};

export default GenericAutocompleteField;
