import { Fab, TableCell, Tooltip } from '@mui/material';

import removeDataIcon from '../../../../assets/images/icons/remove-data.png';

const getDataGridColumns = (removeSourceHistoryItem) => [
  {
    field: 'type',
    headerName: 'Type',
    width: 150,
    valueGetter: (params) =>
      params.row.startDate ? 'Source history' : 'Current source',
  },
  {
    field: 'source',
    headerName: 'Source name',
    width: 150,
  },
  {
    field: 'sourceID',
    headerName: 'sourceID',
    width: 150,
  },
  {
    field: 'acquisition',
    headerName: 'Aquisition',
    editable: false,
    width: 150,
    renderCell: (params) =>
      `Every ${params.row.periodNumber} ${params.row.periodType} `,
  },
  {
    field: 'aggregation',
    headerName: 'Aggregation',
    editable: false,
    width: 150,
    renderCell: (params) =>
      `Every ${params.row.aggregationPeriodNumber} ${params.row.aggregationPeriodType} `,
  },
  {
    field: 'startDate',
    headerName: 'Source starts at',
    editable: false,
    width: 150,
    type: 'date',
  },
  {
    field: 'delete',
    headerName: 'Delete',
    editable: false,
    width: 100,
    renderCell: (params) => {
      if (params.row.startDate) {
        return (
          <TableCell style={{ textAlign: 'center' }}>
            <Tooltip
              title={'Remove source'}
              placement="bottom"
              enterDelay={100}
            >
              <Fab
                size="small"
                color="primary"
                aria-label="delete"
                style={{ backgroundColor: '#FFFFFF' }}
                onClick={() => {
                  removeSourceHistoryItem(params.row);
                }}
              >
                <img
                  src={removeDataIcon}
                  alt={'Remove source'}
                  title={'Remove source'}
                  style={{
                    width: 22,
                    height: 22,
                    marginTop: 6,
                    marginLeft: 2,
                  }}
                />
              </Fab>
            </Tooltip>
          </TableCell>
        );
      }
    },
  },
];

export default getDataGridColumns;
