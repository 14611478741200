import { LinearProgress } from '@mui/material';
import { getGridStringOperators } from '@mui/x-data-grid-pro';
import { DataGridPro as Table } from '@mui/x-data-grid-pro';
import useBalances from 'components/Balances/Redux/CustomHooks/useBalances';
import LoadAll from 'components/commons/LoadAll/LoadAll';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppDispatch } from 'redux/store';

import {
  openAllocateFormDialog,
  openTransferFormDialog,
  setSelectedBalanceData,
} from '../../../../../actions';
import { Units } from '../../../../../enums/Units';
import GridToolbar from '../../../../GenericComponents/Toolbar/GridToolbar';
import useBalancesChildComponentsSlices from '../../../Redux/CustomHooks/useBalancesChildComponentsSlices';
import { getBalancesData } from '../../../utils/data/utils';
import {
  prepareModalFormsDataForAllocation,
  prepareModalFormsDataForTransfer,
} from '../../../utils/modals/helpers';
import BalanceColumns from '../../../utils/table/columns';
import { addInfoColumn } from '../../../utils/table/helpers';
import ProductDialog from '../../Dialogs/ProductDialog/ProductDialog';
import { BalancesToolbar } from '../../Toolbar/BalancesToolbar';

const displayPlatinumUI = process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';

const BalancesTable = () => {
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const { page, pageSize, handlePageSizeChange, handlePageChange } =
    useBalances();
  //redux selectors
  const {
    organizations,
    loading,
    balances,
    selectedStandardId,
    selectedStandardType,
    user,
    isAuthenticated,
    ozOrGramsQuantityUnit,
    uniqueStandardsFromBalances,
    businessOrganizations,
    organizationsInTransferMenuFiltredForSuperAdmin,
    organizationsInAllocateMenuFiltredForSuperAdmin,
    allBalancesCount,
    defaultBalancesPaginationThreshold,
    paginatedBalancesCount,
  } = useBalancesChildComponentsSlices();

  const dataAreLoading: boolean =
    loading || !organizations || organizations.length < 1;

  //handlers
  const handleOpenTransferFormDialog = (
    _origin: any,
    _from: any,
    _organizationsInTransferMenu: any[],
    _to: any,
    _year: any,
    _month: any,
    _quantity: number
  ) => {
    const _toOrganization = prepareModalFormsDataForTransfer(
      _origin,
      _organizationsInTransferMenu,
      _to,
      businessOrganizations,
      organizationsInTransferMenuFiltredForSuperAdmin,
      user,
      isAuthenticated,
      dispatch
    );
    dispatch(
      openTransferFormDialog(
        _origin,
        _from,
        _toOrganization,
        _year,
        _month,
        _quantity
      )
    );
    dispatch(setSelectedBalanceData(_origin, _from, _year, _month, _quantity));
  };

  const handleOpenAllocateFormDialog = (
    _origin: any,
    _from: any,
    _organizationsInAllocateMenu: any[],
    _to: any,
    _year: any,
    _month: any,
    _quantity: number
  ) => {
    const _toOrganization = prepareModalFormsDataForAllocation(
      _origin,
      _organizationsInAllocateMenu,
      _to,
      organizationsInAllocateMenuFiltredForSuperAdmin,
      user,
      isAuthenticated,
      dispatch
    );
    //reset location state
    if (location) {
      location.state = null;
    }
    dispatch(
      openAllocateFormDialog(
        _origin,
        _from,
        _toOrganization,
        _year,
        _month,
        _quantity
      )
    );
    dispatch(setSelectedBalanceData(_origin, _from, _year, _month, _quantity));
  };

  const activateOzConversion =
    displayPlatinumUI &&
    ozOrGramsQuantityUnit &&
    ozOrGramsQuantityUnit !== Units.GRAMS;

  const dataGridRows = getBalancesData(
    loading,
    balances,
    organizations,
    businessOrganizations,
    uniqueStandardsFromBalances,
    selectedStandardId,
    user,
    isAuthenticated
  );

  const dynamicColumns = addInfoColumn(
    selectedStandardId,
    selectedStandardType,
    uniqueStandardsFromBalances
  );

  const dataGridColumns = [
    ...BalanceColumns(
      activateOzConversion,
      handleOpenTransferFormDialog,
      handleOpenAllocateFormDialog,
      getGridStringOperators(),
      dynamicColumns
    ),
  ];
  const isTesting = process.env.NODE_ENV === 'test';
  const useServerSidePagination =
    allBalancesCount > (defaultBalancesPaginationThreshold as number);

  return (
    <div
      data-testid="balances-table-id"
      style={{
        height: document.documentElement.clientHeight - 200,
        paddingBottom: 83,
      }}
    >
      <BalancesToolbar loading={dataAreLoading} />
      <Table
        disableVirtualization={isTesting}
        pagination={true}
        paginationMode={useServerSidePagination ? 'server' : 'client'}
        loading={loading}
        columns={dataGridColumns}
        rows={dataGridRows}
        components={{
          LoadingOverlay: LinearProgress,
          Toolbar: GridToolbar,
          Footer: useServerSidePagination
            ? () => (
                <LoadAll
                  onClick={() => {
                    handlePageSizeChange(allBalancesCount);
                  }}
                  loadedRows={paginatedBalancesCount}
                  totalRows={allBalancesCount}
                  typeOfData="balances"
                />
              )
            : undefined,
        }}
        page={useServerSidePagination ? page : undefined}
        pageSize={useServerSidePagination ? pageSize : undefined}
        rowCount={paginatedBalancesCount}
        onPageChange={useServerSidePagination ? handlePageChange : undefined}
        onPageSizeChange={
          useServerSidePagination ? handlePageSizeChange : undefined
        }
      />
      <ProductDialog />
    </div>
  );
};

export default BalancesTable;
