import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { useNavigate } from 'react-router-dom';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';

import businessReportIcon from '../../assets/images/icons/business-report.png';
import { withRouter } from '../../utils/migrationHelper';
import { Block } from '../commons/Block/Block';
import PageTitle from '../commons/PageTitle';

const Reports = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useProductSlicesCombiner();

  const actions = [
    {
      key: 'reference-documents',
      title: `Reference documents reports`,
      subtitle: `Get an overview of the validity status of your reference documents`,
      icon: businessReportIcon,
      onClick: () => {
        navigate('/reports/reference-documents');
      },
      displayed:
        checkUserHasRight(isAuthenticated, user, RightReferences.REPORTS, [
          CrudPermissions.VIEW,
        ]) || checkUserIsSuperAdmin(isAuthenticated, user),
    },
  ];

  const blocksToDisplay = actions.filter((a) => a.displayed);
  if (blocksToDisplay.length === 0) {
    return (
      <>
        <PageTitle title="Reports" loading={false} image={businessReportIcon} />
        <Box display="flex">
          <span
            style={{
              fontFamily:
                '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 'bold',
              fontSize: 16,
            }}
          >
            Sorry, you don&apos;t have access to any report
          </span>
        </Box>
      </>
    );
  }

  return (
    <>
      <PageTitle title="Reports" loading={false} image={businessReportIcon} />
      <Grid container spacing={2}>
        {blocksToDisplay.map((action) => {
          const { key, title, subtitle, icon, onClick } = action;
          return (
            <Block
              key={key}
              title={title}
              subtitle={subtitle}
              icon={icon}
              onClick={onClick}
            />
          );
        })}
      </Grid>
    </>
  );
};

export default withRouter(Reports);
