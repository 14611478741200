/* eslint-disable */

import { AnyAction } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { DynamicListsReducerStateType } from './types';

const INITIAL_STATE: DynamicListsReducerStateType = {
  dynamicList: null,
  currentDynamicList: null,
  dynamicLists: [],
  error: { message: '', isVisible: false },
  loadingDynamicLists: false,
  loadingDynamicList: false,
  formIsBeingFilledOut: false,
  dynamicListItems: [],
  localDynamicListItems: [],
  openConfirmDynamicListItemDeletion: false,
  dynamicListItemToDelete: {
    _id: '',
    key: '',
    value: '',
  },
  loadingDynamicListItems: false,
  deletingDynamicListItemIds: [],
  openUploadDynamicListItemModal: false,
  dynamicListItemsGridApiRef: undefined,
  uploadingFile: false,
};

const dynamicListsReducer = (
  state: DynamicListsReducerStateType = INITIAL_STATE,
  action: AnyAction
) => {
  switch (action.type) {
    //Getting dynamicLists
    case 'GET_DYNAMIC_LISTS_START':
      return { ...state, loadingDynamicLists: true };

    case 'GET_DYNAMIC_LISTS_SUCCESS':
      return {
        ...state,
        dynamicLists: action.payload,
        loadingDynamicLists: false,
      };

    case 'GET_DYNAMIC_LISTS_FAIL':
      return { ...state, loadingDynamicLists: false };

    //Get dynamicList
    case 'GET_DYNAMIC_LIST_START':
      return { ...state, loadingDynamicList: true };

    case 'GET_DYNAMIC_LIST_SUCCESS':
      return {
        ...state,
        dynamicList: action.payload,
        currentDynamicList: action.payload,
        loadingDynamicList: false,
        formIsFilledOut: false,
      };

    case 'GET_DYNAMIC_LIST_ITEMS_SUCCESS':
      let _currentDynamicList =  { ...state.currentDynamicList};
      _currentDynamicList.items = action.payload;
      return {
        ...state,
        dynamicList: _currentDynamicList,
        currentDynamicList: _currentDynamicList,
        localDynamicListItems: [],
      };
      

    case 'GET_DYNAMIC_LIST_FAIL':
      return { ...state, loadingDynamicList: false };

    //Update dynamicList
    case 'SET_DYNAMIC_LIST_START':
      return { ...state, loadingDynamicList: true };

    case 'SET_DYNAMIC_LIST_FIELD':
      const { field: listField, value: listFieldValue } = action.payload;
      let newCurrentDynamicList = { ...state.currentDynamicList };
      newCurrentDynamicList[listField] = listFieldValue;
      return {
        ...state,
        currentDynamicList :newCurrentDynamicList,
      };

    case 'SET_DYNAMIC_LIST_SUCCESS':
      return {
        ...state,
        dynamicLists: action.payload,
        loadingDynamicList: false,
        formIsFilledOut: false,
      };

    case 'SET_DYNAMIC_LIST_FAIL':
      return { ...state, loadingDynamicList: false };

    //Delete dynamicList
    case 'DELETE_DYNAMIC_LIST_START':
      return { ...state, loadingDynamicList: true };

    case 'DELETE_DYNAMIC_LIST_SUCCESS':
      return {
        ...state,
        dynamicLists: action.payload,
        loadingDynamicList: false,
        formIsFilledOut: false,
      };

    case 'DELETE_DYNAMIC_LIST_FAIL':
      return { ...state, loadingDynamicList: false };

    //Create dynamicList
    case 'CREATE_DYNAMIC_LIST_START':
      return { ...state, loadingDynamicList: true };

    case 'CREATE_DYNAMIC_LIST_SUCCESS':
      return {
        ...state,
        dynamicLists: action.payload,
        loadingDynamicList: false,
        formIsFilledOut: false,
      };

    case 'CREATE_DYNAMIC_LIST_FAIL':
      return { ...state, loadingDynamicList: false };

    case 'RESET_DYNAMIC_LIST':
      return {
        ...state,
        currentDynamicList: {
          id: undefined,
          name: '',
          description: '',
        },
      };

    case 'DYNAMIC_LIST_FORM_IS_BEING_FILLED_OUT':
      return {
        ...state,
        formIsFilledOut: true,
      };

    case 'ADD_LOCAL_LIST_ITEMS':
      let newLocalDynamicListItemWithAddedListItems = [
        ...(state.localDynamicListItems as any[]),
      ];
      const listItemsToAdd = action.payload;

      for (const listItem of listItemsToAdd) {
        let idForlistItemToAdd = uuidv4();
        newLocalDynamicListItemWithAddedListItems.push({ ...listItem, id: idForlistItemToAdd });
      }

      return {
        ...state,
        localDynamicListItems: newLocalDynamicListItemWithAddedListItems,
      };

    case 'ADD_LOCAL_LIST_ITEM':
      let newLocalDynamicListItemWithAddedListItem = [
        ...(state.localDynamicListItems as any[]),
      ];
      let listItemToAdd = action.payload;
      if(!listItemToAdd.id) listItemToAdd = {...listItemToAdd, id: uuidv4()}
      newLocalDynamicListItemWithAddedListItem.push(listItemToAdd);

      return {
        ...state,
        localDynamicListItems: newLocalDynamicListItemWithAddedListItem,
      };
  
    case 'SET_DATAGRID_API_REF':
      return {
        ...state,
        dynamicListItemsGridApiRef: action.payload,
        };
    case 'REMOVE_LOCAL_LIST_ITEMS':
      return {
        ...state,
        localDynamicListItems: [],
      };

    case 'REMOVE_LOCAL_LIST_ITEM':
      let newLocalDynamicListItemsWithoutRemovedListItem = [
        ...(state.localDynamicListItems as any[]),
      ];

      newLocalDynamicListItemsWithoutRemovedListItem =
      newLocalDynamicListItemsWithoutRemovedListItem.filter(
          (lI) => lI.id !== action.payload.localDynamicListItemId
        );
      return {
        ...state,
        localDynamicListItems: newLocalDynamicListItemsWithoutRemovedListItem,
      };

    case 'SET_LOCAL_LIST_ITEM_FIELD':
      let newLocalDynamicListItemsWithAddedKey = [
        ...(state.localDynamicListItems as any[]),
      ];

      const { field, fieldValue, dynamicListItemId: ___listItemId } = action.payload;

      newLocalDynamicListItemsWithAddedKey = newLocalDynamicListItemsWithAddedKey.map(lI => {
        if(___listItemId === lI.id) {
          lI[field] = fieldValue;
        }
        return lI ;
      })

      return {
        ...state,
        localDynamicListItems: newLocalDynamicListItemsWithAddedKey,
      };

    case 'SET_LOCAL_LIST_ITEMS':
      const newLocalDynamicListItems = action.payload;
      return {
        ...state,
        localDynamicListItems: newLocalDynamicListItems,
      };

    case 'DISPLAY_CONFIRM_LIST_ITEM_DELETION_MODAL':
      let dynamicListItem = action.payload.dynamicListItem;
      return {
        ...state,
        openConfirmDynamicListItemDeletion: true,
        dynamicListItemToDelete: dynamicListItem,
      };
    case 'HIDE_CONFIRM_LIST_ITEM_DELETION_MODAL':
      return {
        ...state,
        openConfirmDynamicListItemDeletion: false,
      };

    case 'DISPLAY_UPLOAD_LIST_ITEM_MODAL':
      return {
        ...state,
        openUploadDynamicListItemModal: true,
      };
    case 'HIDE_UPLOAD_LIST_ITEM_MODAL':
      return {
        ...state,
        openUploadDynamicListItemModal: false,
      };

    case 'DELETE_LIST_ITEM_START':
      let dynamicListItemId = action.payload.dynamicListItemId;
      let deletingDynamicListItemIdsUpdated = [...(state.deletingDynamicListItemIds as string[])];
      deletingDynamicListItemIdsUpdated.indexOf(dynamicListItemId) === -1
        ? deletingDynamicListItemIdsUpdated.push(dynamicListItemId)
        : console.log('This list item is already deleting');

      return { ...state, deletingDynamicListItemIds: deletingDynamicListItemIdsUpdated };

    case 'DELETE_LIST_ITEM_SUCCESS':
      let listItemIdToRemoveFromList = action.payload.dynamicListItemId;

      let currentDynamicListWithoutListItemIdInDynamicListItems = {
        ...state.currentDynamicList,
      };
      currentDynamicListWithoutListItemIdInDynamicListItems.items =
      currentDynamicListWithoutListItemIdInDynamicListItems?.items?.filter(
          (listItem: any) => {
            return listItem._id !== listItemIdToRemoveFromList;
          }
        );
      return {
        ...state,
        deletingDynamicListItemIds: [...(state.deletingDynamicListItemIds as string[])].filter(
          (f) => f !== listItemIdToRemoveFromList
        ),
        currentDynamicList: currentDynamicListWithoutListItemIdInDynamicListItems,
      };

    case 'DELETE_LIST_ITEM_FAIL':
      let listItemIdToRemoveBecauseOfFailure = action.payload.dynamicListItemId;
      return {
        ...state,
        deletingDynamicListItemIds: [...(state.deletingDynamicListItemIds as string[])].filter(
          (f) => f !== listItemIdToRemoveBecauseOfFailure
        ),
      };
    default:
      return state;
  }
};

export default dynamicListsReducer;
