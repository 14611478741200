import { Fab, TableCell, Tooltip } from '@mui/material';

import downloadIcon from '../../../../assets/images/icons/download-from-cloud.png';

const DownloadCell = (props: any): JSX.Element => {
  const { url, blob } = props;

  let fileUrl;
  if (url) {
    const s3FileUrl = new URL(url);
    fileUrl = `${process.env.REACT_APP_API_URL}/api/files/s3${s3FileUrl.pathname}`;
  } else {
    fileUrl = URL.createObjectURL(blob);
  }

  return (
    <TableCell style={{ textAlign: 'center' }}>
      <Tooltip title={'Download source'} placement="bottom" enterDelay={100}>
        <Fab size="small" color="primary" aria-label="add" style={style.fab}>
          <a href={`${fileUrl}`} target="_blank" rel="noreferrer">
            <img
              src={downloadIcon}
              alt={'Download reference document'}
              title={'Download reference document'}
              style={style.icon}
            />
          </a>
        </Fab>
      </Tooltip>
    </TableCell>
  );
};

const style = {
  fab: { backgroundColor: '#FFFFFF' },
  icon: {
    width: 22,
    height: 22,
    marginTop: 6,
    marginLeft: 2,
  },
};

export default DownloadCell;
