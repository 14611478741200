import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { closeActionDialog } from 'actions';

type GenericNoActionPropsType = { action: string };

const GenericNoAction = ({ action }: GenericNoActionPropsType) => {
  return (
    <>
      <DialogTitle id="responsive-dialog-title">Error</DialogTitle>
      <DialogContent>{`The action '${action}' is not managed`}</DialogContent>
      <DialogActions>
        <Button onClick={closeActionDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

export default GenericNoAction;
