import { Dialog } from '@mui/material';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import {
  ActionKey,
  closeActionsOnBackropMap,
} from 'components/Administration/utils/closeActionsMapper';
import { formsComponentMap } from 'components/Administration/utils/formsMapper';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import GenericNoAction from './GenericNoAction';
import '../Styles/modals.css';

//check_attributes
const GenericAdministrationOperationsMonotoringModal = () => {
  const dispatch: AppDispatch = useDispatch();
  const { formToDisplayInActionDialog, actionDialogOpen } =
    useAdministrationSlicesCombiner();

  const AdministrationFormComponent =
    formsComponentMap[formToDisplayInActionDialog] || GenericNoAction;
  const modalCloseActionOnBackrop =
    closeActionsOnBackropMap[formToDisplayInActionDialog as ActionKey];

  const applyWideModal = Boolean(
    formToDisplayInActionDialog === 'get_user_logs' ||
      formToDisplayInActionDialog === 'get_scheduled_tasks_list'
  );
  if (!AdministrationFormComponent) return <></>;

  return (
    <Dialog
      open={actionDialogOpen}
      fullScreen={applyWideModal}
      onClose={() => dispatch(modalCloseActionOnBackrop())}
      classes={{ paper: 'custom-dialog-width' }}
    >
      <AdministrationFormComponent />
    </Dialog>
  );
};

export default GenericAdministrationOperationsMonotoringModal;
