/* eslint-disable */

import moment from 'moment';
import { NominationsReducerStateType } from './types';

const INITIAL_STATE: NominationsReducerStateType = {
  nominationsRequests: undefined,
  nominationsRequestsToDo: [],
  nominationsRequestsToDoLength: 0,
  error: { message: '', isVisible: false },
  independantsWithNominationsFeature: [],
  isWarningDialogOpen: false,
  buyerOrganization: undefined,
  selectedSellerOrganization: undefined,
  sellerOrganizations: [],
  year: moment().format('YYYY'),
  loading: false,
  isLoadingClickedNominationHistory: false,
  isHistoryDialogOpen: false,
  clickedNominationId: undefined,
  selectedNominationId: undefined,
  clickedNominationHistoryArray: [],
  minMaxRequestedHistory: undefined,
  selected: [],
};

const nominationsRequestsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case 'GET_ISSUER_ORGANIZATIONS_SUCCESS':
      return {
        ...state,
        sellerOrganizations: action.payload,
        loading: false,
      };
    //Getting transactions
    case 'GET_NOMINATION_HISTORY_START':
      return {
        ...state,
        isLoadingClickedNominationHistory: true,
        selectedNominationId: undefined,
      };

    case 'GET_NOMINATION_HISTORY_SUCCESS':
      return {
        ...state,
        clickedNominationHistoryArray:
          action.payload.clickedNominationHistoryArray,
        minMaxRequestedHistory: action.payload.minMaxRequestedHistory,
        isLoadingClickedNominationHistory: false,
      };

    case 'GET_NOMINATION_HISTORY_END':
      return { ...state, isLoadingClickedNominationHistory: false };

    case 'GET_NOMINATIONS_REQUESTS_START':
      return { ...state, loading: true };

    case 'GET_NOMINATIONS_REQUESTS_SUCCESS':
      return {
        ...state,
        nominationsRequests: action.payload,
        nominationsRequestsToDo: [],
        loading: false,
      };

    case 'GET_NOMINATIONS_REQUESTS_FAIL':
      return { ...state, loading: false };

    case 'GET_NOMINATIONS_ORGANIZATIONS_START':
      return { ...state, loading: true };

    case 'GET_NOMINATIONS_ORGANIZATIONS_END':
      return { ...state, loading: false };

    case 'GET_NOMINATIONS_INDEPENDANTS_SUCCESS':
      return { ...state, independantsWithNominationsFeature: action.payload };

    case 'GET_NOMINATIONS_INDEPENDANTS_FAIL':
      return { ...state, loading: false };

    case 'NOMINATIONS_ORGANIZATION_CHANGED':
      return { ...state, buyerOrganization: action.payload };
    case 'NOMINATIONS_ISSUER_ORGANIZATION_CHANGED':
      return { ...state, selectedSellerOrganization: action.payload };

    case 'NOMINATIONS_YEAR_CHANGED':
      return { ...state, year: action.payload };

    case 'NOMINATIONS_TOGGLE_WARNING_DIALOG':
      let isWarningDialogOpen = !state.isWarningDialogOpen;
      return { ...state, isWarningDialogOpen };

    case 'NOMINATIONS_TOGGLE_HISTORY_DIALOG':
      let isHistoryDialogOpen = !state.isHistoryDialogOpen;
      let clickedNominationId = undefined;
      if (isHistoryDialogOpen) clickedNominationId = action.payload;
      return { ...state, isHistoryDialogOpen, clickedNominationId };

    case 'NOMINATIONS_CLOSE_HISTORY_DIALOG':
      let clickedNominationIdOnClose = undefined;
      return {
        ...state,
        isHistoryDialogOpen: false,
        clickedNominationId: clickedNominationIdOnClose,
      };

    case 'UPDATE_NOMINATIONS_REQUEST_TO_DO':
      let nominationsRequestToDo = action.payload.nominationsRequestToDo;
      let minMaxRequested = action.payload.minMaxRequested;
      let nominationsRequestsToDo = state.nominationsRequestsToDo;

      let isNominationsRequestInArray = false;

      nominationsRequestsToDo.map((flexRequest) => {
        if (flexRequest._id === nominationsRequestToDo._id)
          isNominationsRequestInArray = true;
      });

      switch (minMaxRequested) {
        case 'min':
          if (
            !isNominationsRequestInArray &&
            parseInt(nominationsRequestToDo._min) > 0
          ) {
            nominationsRequestsToDo.push(nominationsRequestToDo);
          } else if (
            isNominationsRequestInArray &&
            parseInt(nominationsRequestToDo._min) > 0
          ) {
            nominationsRequestsToDo = nominationsRequestsToDo.map(
              (nominationsRequest) => {
                if (nominationsRequest._id === nominationsRequestToDo._id)
                  return nominationsRequestToDo;
                return nominationsRequest;
              }
            );
          } else if (
            isNominationsRequestInArray &&
            (parseInt(nominationsRequestToDo._min) === 0 ||
              !nominationsRequestToDo._min)
          ) {
            nominationsRequestsToDo = nominationsRequestsToDo.filter(
              (nominationsRequest) => {
                return nominationsRequest._id !== nominationsRequestToDo._id;
              }
            );
          }
          break;

        case 'max':
          if (
            !isNominationsRequestInArray &&
            parseInt(nominationsRequestToDo._max) > 0
          ) {
            nominationsRequestsToDo.push(nominationsRequestToDo);
          } else if (
            isNominationsRequestInArray &&
            parseInt(nominationsRequestToDo._max) > 0
          ) {
            nominationsRequestsToDo = nominationsRequestsToDo.map(
              (nominationsRequest) => {
                if (nominationsRequest._id === nominationsRequestToDo._id)
                  return nominationsRequestToDo;
                return nominationsRequest;
              }
            );
          } else if (
            isNominationsRequestInArray &&
            (parseInt(nominationsRequestToDo._max) === 0 ||
              !nominationsRequestToDo._max)
          ) {
            nominationsRequestsToDo = nominationsRequestsToDo.filter(
              (nominationsRequest) => {
                return nominationsRequest._id !== nominationsRequestToDo._id;
              }
            );
          }
          break;

        case 'requested':
          if (
            !isNominationsRequestInArray &&
            parseInt(nominationsRequestToDo._requested) > 0
          ) {
            nominationsRequestsToDo.push(nominationsRequestToDo);
          } else if (
            isNominationsRequestInArray &&
            parseInt(nominationsRequestToDo._requested) > 0
          ) {
            nominationsRequestsToDo = nominationsRequestsToDo.map(
              (nominationsRequest) => {
                if (nominationsRequest._id === nominationsRequestToDo._id)
                  return nominationsRequestToDo;
                return nominationsRequest;
              }
            );
          } else if (
            isNominationsRequestInArray &&
            (parseInt(nominationsRequestToDo._requested) === 0 ||
              !nominationsRequestToDo._requested)
          ) {
            nominationsRequestsToDo = nominationsRequestsToDo.filter(
              (nominationsRequest) => {
                return nominationsRequest._id !== nominationsRequestToDo._id;
              }
            );
          }
          break;

        default:
          break;
      }

      return {
        ...state,
        nominationsRequestsToDo,
        nominationsRequestsToDoLength: nominationsRequestsToDo.length,
      };

    case 'LOGOUT_SUCCESS':
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default nominationsRequestsReducer;
