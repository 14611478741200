import { quantity } from 'components/Administration/Generic/Form/utils/utils';
import { RightReferences } from 'enums/RightReferences';
import { RightsListElement } from 'types/RightsListElement';

import issueIcon from '../../../assets/images/icons/issue-certificate-green.png';
import issueInBulkIcon from '../../../assets/images/icons/issue-certificate-green.png';
import transferInBulkIcon from '../../../assets/images/icons/transfer-certificate-green.png';
import uploadInBulkIcon from '../../../assets/images/icons/upload.png';
import uploadIcon from '../../../assets/images/icons/upload.png';

export const getOperationsActions = (
  userIsSuperAdmin: boolean,
  userRights: RightsListElement[]
) => {
  const operationsArray: any[] = [
    {
      name: 'set_quantity_single',
      title: `Upload volume data for a single product`,
      subtitle: `Select a product and a period and insert production ${quantity}`,
      icon: uploadIcon,
      display:
        userIsSuperAdmin ||
        userRights.some(
          (rightObject) => rightObject.type.ref === RightReferences.DATA_UPLOAD
        ),
    },
    {
      name: 'set_quantity_multiple',
      title: `Upload volume data for multiple products and periods`,
      subtitle: `Create an Excel file, insert ${quantity} for multiple products and periods and upload it`,
      icon: uploadInBulkIcon,
      display:
        userIsSuperAdmin ||
        userRights.some(
          (rightObject) => rightObject.type.ref === RightReferences.DATA_UPLOAD
        ),
    },
    {
      name: 'issue_certificate',
      title: `Issue balance`,
      subtitle: `If volume data are already uploaded, issue balance for a single product `,
      icon: issueIcon,
      display:
        userIsSuperAdmin ||
        userRights.some(
          (rightObject) => rightObject.type.ref === RightReferences.ISSUE
        ),
    },
    {
      name: 'issue_certificate_bulk',
      title: `Issue balance for multiple products`,
      subtitle: `If volume data are already uploaded, issue balance for multiple products for one period`,
      icon: issueInBulkIcon,
      display:
        userIsSuperAdmin ||
        userRights.some(
          (rightObject) => rightObject.type.ref === RightReferences.ISSUE
        ),
    },
    {
      name: 'transfer_retire_bulk',
      title: `Issue/Transfer/Retire for multiple products`,
      subtitle: `Upload an Excel file and launch issue, transfer and/or retire operations for multiple products, periods and beneficiary organizations`,
      icon: transferInBulkIcon,
      display:
        userIsSuperAdmin ||
        userRights.some(
          (rightObject) =>
            rightObject.type.ref === RightReferences.MULTIPLE_OPERATIONS
        ),
    },
  ];
  return operationsArray.filter((operation) => operation.display);
};
