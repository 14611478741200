import { useSelector } from 'react-redux';
import { StandardsReducerStateType } from 'reducers/standardsReducer/types';
import { RootState } from 'redux/store';

const useStandardsSlices = (): StandardsReducerStateType => {
  //Redux Standards slices
  const {
    standards,
    uniqueStandardsFromBalances,
    loadingStandards,
    currentStandard,
  }: StandardsReducerStateType = useSelector(
    (state: RootState) => state.standardsReducer
  );
  return {
    standards,
    loadingStandards,
    currentStandard,
    uniqueStandardsFromBalances,
  };
};

export default useStandardsSlices;
