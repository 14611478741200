/* eslint-disable */
import Paper from '@mui/material/Paper';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';
import ReferenceFilesToolbar from './CustomComponents/Toolbar/ReferenceFilesToolbar';
import ReferenceFilesTable from './CustomComponents/Table/ReferenceFilesTable';

const ReferenceFiles = (): JSX.Element => {
  return (
    <GenericGrid isItem xsLength={12}>
      <Paper style={{ marginTop: 20 }} data-testid="references-files-card-id">
        <div style={{ paddingBottom: 63, paddingLeft: 20, paddingRight: 20 }}>
          <ReferenceFilesToolbar
            title={'Reference documents expiring in one month'}
          />
          <ReferenceFilesTable status={'will-expire-in-1-month'} />
          <ReferenceFilesToolbar
            title={'Reference documents expiring in 3 months'}
          />
          <ReferenceFilesTable status={'will-expire-in-3-months'} />
          <ReferenceFilesToolbar
            title={'Reference documents without validity date'}
          />
          <ReferenceFilesTable status={'missing-validity-date'} />
        </div>
      </Paper>
    </GenericGrid>
  );
};

export default ReferenceFiles;
