import { Grid, MenuItem } from '@mui/material';
import useCreateOrUpdateProductSlicesCombiner from 'components/CreateOrUpdateProduct/Redux/Slices/useCreateOrUpdateProductSlicesCombiner';
import ProductInfoMultipleSelect from 'components/ProductInfoMultipleSelect';
import { enums } from 'enums';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { DefaultProductType } from 'reducers/productsReducer/types';

import CustomTooltip from '../../CustomTooltip/CustomTooltip';
import ErrorContent from '../ErrorContent/ErrorContent';

import {
  getFullListInfosArray,
  getInitialInfosArray,
  handleListMultiChange,
  processFieldLabel,
  processFieldType,
} from './utils';

type FieldMultiListPropsType = {
  infoX?: any;
  index?: number;
  values?: any;
  setValues?: any;
  fieldType?: string | undefined;
  style?: any;
  withTooltip?: boolean;
};

const CommonProductDetailsField = ({
  infoX,
  index,
  values,
  setValues,
  fieldType,
  style,
  withTooltip = true,
}: FieldMultiListPropsType) => {
  const { t } = useTranslation();
  const { currentProduct, dynamicLists } =
    useCreateOrUpdateProductSlicesCombiner();

  let collectionInfoArray = [];
  if (fieldType && fieldType === enums.InfoTypes.COLLECTION) {
    const selectedCollection = dynamicLists?.find(
      (list: any) => list._id === infoX?.collection
    );
    collectionInfoArray = selectedCollection?.items;
  }

  let collectionMultiInfoArray = [];
  if (fieldType && fieldType === enums.InfoTypes.COLLECTION_MULTI) {
    const selectedCollection = dynamicLists?.find(
      (list: any) => list._id === infoX?.collection
    );
    collectionMultiInfoArray = selectedCollection?.items.map(
      (item: any) => item.value
    );
  }

  return (
    <Grid item key={`infoX${index}`} xs={12} md={6} lg={4}>
      {!fieldType && (
        <Field
          style={style}
          component={TextField}
          label={processFieldLabel(infoX, fieldType, t)}
          name={infoX.key}
          type="text"
        />
      )}

      {fieldType && fieldType === enums.InfoTypes.COLLECTION_MULTI && (
        <ProductInfoMultipleSelect
          infoArray={collectionMultiInfoArray}
          initialInfos={getInitialInfosArray(
            currentProduct as DefaultProductType,
            infoX
          )}
          label={infoX.label}
          id={infoX.key}
          onChange={(e: any) =>
            handleListMultiChange(e, values, setValues, infoX)
          }
        />
      )}

      {fieldType && fieldType === enums.InfoTypes.LIST_MULTI && (
        <ProductInfoMultipleSelect
          infoArray={getFullListInfosArray(infoX)}
          initialInfos={getInitialInfosArray(
            currentProduct as DefaultProductType,
            infoX
          )}
          label={infoX.label}
          id={infoX.key}
          onChange={(e: any) =>
            handleListMultiChange(e, values, setValues, infoX)
          }
        />
      )}

      {fieldType &&
        fieldType !== enums.InfoTypes.LIST_MULTI &&
        fieldType !== enums.InfoTypes.COLLECTION_MULTI && (
          <Field
            {...(fieldType === enums.InfoTypes.DYNAMIC_DATA && {
              disabled: true,
            })}
            style={style}
            component={TextField}
            label={processFieldLabel(infoX, fieldType, t)}
            name={infoX.key}
            type={processFieldType(fieldType)}
            {...((fieldType === enums.InfoTypes.LIST ||
              fieldType === enums.InfoTypes.COLLECTION) && { select: true })}
            {...(fieldType === enums.InfoTypes.DYNAMIC_DATA && {
              value: 'Dynamic data',
            })}
            InputLabelProps={{ shrink: true }}
          >
            {fieldType === enums.InfoTypes.LIST &&
              infoX?.listItems?.map((isItem: any) => (
                <MenuItem key={isItem.value} value={isItem.value}>
                  {isItem.value}
                </MenuItem>
              ))}
            {fieldType === enums.InfoTypes.COLLECTION &&
              collectionInfoArray?.map((item: any) => (
                <MenuItem key={item.key} value={item.value}>
                  {item.value}
                </MenuItem>
              ))}
          </Field>
        )}

      <ErrorContent field={infoX.key} />
      {withTooltip && <CustomTooltip infoX={infoX} field={infoX.key} />}
    </Grid>
  );
};

export default CommonProductDetailsField;
