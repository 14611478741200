import { TableHead, TableRow, TableCell } from '@mui/material';
import useNominationsGuards from 'components/Nominations/Redux/CustomHooks/useNominationsGuards';

const NominationsTableHeader = () => {
  const { canManageMultipleOrganizationNominations } = useNominationsGuards();
  return (
    <TableHead>
      <TableRow>
        <TableCell align="right">Period</TableCell>
        {canManageMultipleOrganizationNominations && (
          <TableCell align="right">Min</TableCell>
        )}
        {canManageMultipleOrganizationNominations && (
          <TableCell align="right">Max</TableCell>
        )}
        <TableCell align="right">Requested</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default NominationsTableHeader;
