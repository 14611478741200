import {
  getScheduledTasksMonitoringSource,
  getScheduledTasksMonitoringStatusType,
  getScheduledTasksMonitoringLastUpdateDate,
  getScheduledTasksMonitoringName,
} from '../../utils/datagridHelpers';

export const renderDataGridTaskMonitoringColumns = () => {
  const dataGridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      width: 300,
      valueGetter: getScheduledTasksMonitoringName,
    },
    {
      field: 'source',
      headerName: 'Source',
      editable: false,
      width: 300,
      valueGetter: getScheduledTasksMonitoringSource,
    },
    {
      field: 'status_type',
      headerName: 'Status',
      editable: false,
      width: 300,
      valueGetter: getScheduledTasksMonitoringStatusType,
    },
    {
      field: 'lastUpdateDate',
      headerName: 'LastUpdateDate',
      editable: false,
      width: 300,
      valueGetter: getScheduledTasksMonitoringLastUpdateDate,
    },
  ];
  return dataGridColumns;
};
