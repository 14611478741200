import Operations from 'components/Operations/Operations';

import DecreaseBalanceDialog from '../Generic/Form/CustomForms/Balances/DecreaseBalanceForm/DecreaseBalanceForm';
import IncreaseBalanceDialog from '../Generic/Form/CustomForms/Balances/IncreaseBalanceForm/IncreaseBalanceForm';
import UpdateBalancesCacheForm from '../Generic/Form/CustomForms/Balances/UpdateBalancesCacheForm/UpdateBalancesCacheForm';
import CheckAttributesForm from '../Generic/Form/CustomForms/Blockchain/CheckAttributesForm/CheckAttributesForm';
import IssuanceEmailNotificationForm from '../Generic/Form/CustomForms/IssueCertificate/CustomComponents/IssuanceEmailNotificationForm/IssuanceEmailNotificationForm';
import IssueCertificateForm from '../Generic/Form/CustomForms/IssueCertificate/CustomComponents/IssueCertificateForm/IssueCertificateForm';
import IssueCertificatesInBulkForm from '../Generic/Form/CustomForms/IssueCertificate/CustomComponents/IssueCertificateInBulkForm/IssueCertificatesInBulkForm';
import CleanStandardsForm from '../Generic/Form/CustomForms/Standards/CleanStandardsForm';
import TaskMonitoringList from '../Generic/Form/CustomForms/TaskMonitoring/TaskMonitoringList';
import TransferRetireInBulkForm from '../Generic/Form/CustomForms/TransferRetire/TransferRetireInBulkForm';
import UploadConfirmationDataForm from '../Generic/Form/CustomForms/Upload/CustomComponents/UploadConfirmationDataForm/UploadConfirmationDataForm';
import UploadDataForm from '../Generic/Form/CustomForms/Upload/CustomComponents/UploadDataForm/UploadDataForm';

export const formsComponentMap: Record<string, React.FC> = {
  set_quantity_single: UploadDataForm,
  set_quantity_multiple: UploadConfirmationDataForm,
  issue_certificate: IssueCertificateForm,
  increase_balance: IncreaseBalanceDialog,
  decrease_balance: DecreaseBalanceDialog,
  check_attributes: CheckAttributesForm,
  issue_certificate_bulk: IssueCertificatesInBulkForm,
  issuance_email_notification: IssuanceEmailNotificationForm,
  transfer_retire_bulk: TransferRetireInBulkForm,
  force_decache_balances: UpdateBalancesCacheForm,
  clean_standards_from_empty_attributes: CleanStandardsForm,
  get_scheduled_tasks_list: TaskMonitoringList,
  get_user_logs: Operations,
};
