/* eslint-disable */

import moment from 'moment';
import { getOrganizations } from '../actions';
import {
  apiPut,
  apiPost,
  apiGet,
  openSuccessSnack,
  openErrorSnack,
  apiDelete,
} from './UtilsActions';
import { handleErrors, handleCatchedErrors } from './ErrorActions';
import {
  checkObjectIsArray,
  checkObjectIsNonEmptyArray,
} from 'utils/general/utils';
import { capitalize } from 'utils/text';
let displayPlatinumUI = process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';

export const getProducts = async (page, pageSize) => {
  let url =
    process.env.REACT_APP_API_URL + `/api/prod?page=${page}&limit=${pageSize}`;
  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
  handleErrors(response);

  const result = await response.json();
  return result;
};

export const getReferenceDocuments = async () => {
  let url = process.env.REACT_APP_API_URL + `/api/files`;
  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
  handleErrors(response);

  const result = await response.json();
  return result;
};

export const getReferenceDocumentsStart = () => async (dispatch) => {
  try {
    dispatch({ type: 'GET_REFERENCE_DOCUMENTS_START' });
    const { referenceDocuments, totalCount } = await getReferenceDocuments();

    const referenceDocumentsWithNotify = referenceDocuments.filter(
      (doc) => doc.notify
    );
    dispatch({
      type: 'GET_REFERENCE_DOCUMENTS_SUCCESS',
      payload: referenceDocumentsWithNotify,
    });
    dispatch({
      type: 'GET_REFERENCE_DOCUMENTS_TOTAL_COUNT_SUCCESS',
      payload: referenceDocumentsWithNotify.length,
    });
  } catch (error) {
    dispatch({
      type: 'GET_REFERENCE_DOCUMENTS_FAIL',
      payload: referenceDocuments,
    });
    dispatch(openErrorSnack(error?.message));
  }
};

export const fetchProductDataForMonth = async (id, year, month) => {
  let url =
    process.env.REACT_APP_API_URL +
    '/api/data/' +
    id +
    '/' +
    year +
    '/' +
    month +
    '/';

  return await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const toggleDisplaySourceForm = () => (dispatch) =>
  dispatch({ type: 'DATA_ACQUISITON_TOGGLE_SET_SOURCE_FORM_VISIBLE' });

export const fetchProductionForLastYear = async (id) => {
  let url = process.env.REACT_APP_API_URL + '/api/data/last-year/' + id;

  return await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const fetchProductionForLastSixMonths = async (id) => {
  let url = process.env.REACT_APP_API_URL + '/api/data/last-six-months/' + id;

  return await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const fetchProductionForLastThreeYears = async (mongoId) => {
  let url =
    process.env.REACT_APP_API_URL + '/api/data/last-three-years/' + mongoId;

  return await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getProductAggregations = async (mongoId, page, pageSize) => {
  let url =
    process.env.REACT_APP_API_URL +
    '/api/data/' +
    mongoId +
    `?page=${page}&limit=${pageSize}`;

  return await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleErrors)
    .then((res) => res.json())
    .catch((error) => {
      console.error(error);
    });
};

export const getProductGraphData = async (mongoId) => {
  let reqSiteTimeSeries;

  if (displayPlatinumUI) {
    reqSiteTimeSeries = await fetchProductionForLastThreeYears(mongoId);
  } else {
    reqSiteTimeSeries = await fetchProductionForLastYear(mongoId);
  }

  let barChartLabels = reqSiteTimeSeries?.dates;
  let barChartData = {
    data: reqSiteTimeSeries?.production,
    label: reqSiteTimeSeries?.asset.displayedName,
  };

  let graphData = {
    barChartData,
    barChartLabels,
  };

  return graphData;
};

export const closeActionCommentDialog = (dialogIdentifier) => (dispatch) => {
  dispatch({
    type: 'CLOSE_ACTION_COMMENT_DIALOG',
    payload: { dialogIdentifier },
  });
};
export const archiveProductStart =
  (product, navigate, comment) => async (dispatch) => {
    dispatch({ type: 'ARCHIVE_PRODUCT_START' });
    const archiveResult = await archiveProduct(product, comment).catch(
      (error) => {
        //Catch generic error
        console.error(error);
        dispatch({ type: 'ARCHIVE_PRODUCT_FAIL' });
        dispatch(openErrorSnack(error?.message));
      }
    );

    if (archiveResult) {
      //No error
      dispatch({ type: 'ARCHIVE_PRODUCT_SUCCESS' });
      dispatch(openSuccessSnack('Product archived'));
      navigate('/products/');
    }
  };

export const displayComment = (currentProduct) => async (dispatch) => {
  dispatch({ type: 'DISPLAY_COMMENT_INPUT', payload: currentProduct });
};

const archiveProduct = async (product, comment) => {
  let response = await apiPut('/api/prod/archive/' + product._id, comment);
  if (response.status >= 200 && response.status <= 299) {
    const archiveResult = await response.json();
    return archiveResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const restoreProductStart = (product, navigate) => async (dispatch) => {
  dispatch({ type: 'RESTORE_PRODUCT_START' });
  const restoreResult = await restoreProduct(product).catch((error) => {
    //Catch generic error
    console.error(error);
    dispatch({ type: 'RESTORE_PRODUCT_FAIL' });
    dispatch(openErrorSnack(error?.message));
  });

  if (restoreResult) {
    //No error
    dispatch({ type: 'RESTORE_PRODUCT_SUCCESS' });
    dispatch(openSuccessSnack('Product restored !'));
    navigate('/products/');
  }
};

const restoreProduct = async (product) => {
  const response = await apiPut('/api/prod/restore/' + product._id);
  if (response.status >= 200 && response.status <= 299) {
    const restoreResult = await response.json();
    return restoreResult;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const setLocalReferenceFileLabel = (fileId, label) => (dispatch) => {
  dispatch({
    type: 'SET_LOCAL_REFERENCE_FILE_LABEL',
    payload: { label, fileId },
  });
};

export const setLocalReferenceFileNotify = (fileId, notify) => (dispatch) => {
  dispatch({
    type: 'SET_LOCAL_REFERENCE_FILE_NOTIFY',
    payload: { notify, fileId },
  });
};

export const setLocalReferenceFileValidityStart =
  (fileId, validityStart) => (dispatch) => {
    dispatch({
      type: 'SET_LOCAL_REFERENCE_FILE_VALIDITY_START_DATE',
      payload: { validityStart, fileId },
    });
  };

export const setLocalReferenceFileValidityEnd =
  (fileId, validityEnd) => (dispatch) => {
    dispatch({
      type: 'SET_LOCAL_REFERENCE_FILE_VALIDITY_END_DATE',
      payload: { validityEnd, fileId },
    });
  };

export const productReferenceFilesChanged = (files) => (dispatch) => {
  dispatch({ type: 'ADD_LOCAL_REFERENCE_FILES', payload: files });
  dispatch({ type: 'HIDE_UPLOAD_REFERENCE_FILE_MODAL' });
};

export const productPictureChanged = (file) => (dispatch) => {
  dispatch({ type: 'PRODUCT_PICTURE_CHANGED', payload: file });
};

export const uploadProductPicture = async (file, _id) => {
  var formData = new FormData();
  formData.append('files', file);

  const res = await fetch(
    process.env.REACT_APP_API_URL + '/api/prod/upload-file/' + _id,
    {
      method: 'POST',
      credentials: 'include',
      body: formData,
    }
  )
    .then(function (response) {
      return response.json();
    })
    //.then(handleErrors)
    .then(function (body) {
      return body;
    });

  return res;
};

export const addLocalProductReferenceFile = (file) => async (dispatch) => {
  dispatch({ type: 'ADD_LOCAL_REFERENCE_FILE', payload: { file } });
};

export const removeLocalProductReferenceFile =
  (localFileId) => async (dispatch) => {
    dispatch({ type: 'REMOVE_LOCAL_REFERENCE_FILE', payload: { localFileId } });
  };

export const deleteProductReferenceFileS3 = async (fileId, productMongoId) => {
  let apiDeleteFileUrl = `/api/files/s3/references/${productMongoId}/${fileId}`;

  let response = await apiDelete(apiDeleteFileUrl, {});

  if (response.status >= 200 && response.status <= 299) {
    const deleteReferenceFileResult = await response.json();
    return deleteReferenceFileResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const displayConfirmReferenceFileDeletionModal =
  (referenceFile) => async (dispatch) => {
    dispatch({
      type: 'DISPLAY_CONFIRM_REFERENCE_FILE_DELETION_MODAL',
      payload: { referenceFile },
    });
  };

export const hideConfirmReferenceFileDeletionModal = () => async (dispatch) => {
  dispatch({
    type: 'HIDE_CONFIRM_REFERENCE_FILE_DELETION_MODAL',
  });
};

export const displayUploadReferenceFileDialog = () => async (dispatch) => {
  dispatch({
    type: 'DISPLAY_UPLOAD_REFERENCE_FILE_MODAL',
  });
};

export const hideUploadReferenceFileModal = () => async (dispatch) => {
  dispatch({
    type: 'HIDE_UPLOAD_REFERENCE_FILE_MODAL',
  });
};

export const deleteProductReferenceFileS3Start =
  (fileId, productMongoId) => async (dispatch) => {
    dispatch({ type: 'DELETE_REFERENCE_FILE_START', payload: { fileId } });

    let deleteResult = await deleteProductReferenceFileS3(
      fileId,
      productMongoId
    ).catch((error) => {
      //Catch generic error
      console.error(error);
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'DELETE_REFERENCE_FILE_FAIL', payload: { fileId } });
    });
    if (deleteResult) {
      dispatch(openSuccessSnack('File deleted'));
      dispatch({
        type: 'DELETE_REFERENCE_FILE_SUCCESS',
        payload: { fileId },
      });
      dispatch({
        type: 'HIDE_CONFIRM_REFERENCE_FILE_DELETION_MODAL',
      });
    }
  };

export const uploadProductReferenceDocumentS3 = async (file, _id) => {
  var formData = new FormData();
  formData.append('files', file);
  if (file.validityStart) formData.append('validityStart', file.validityStart);
  if (file.validityEnd) formData.append('validityEnd', file.validityEnd);
  if (file.notify) formData.append('notify', file.notify);

  const res = await fetch(
    process.env.REACT_APP_API_URL + '/api/files/s3/references/' + _id,
    {
      method: 'POST',
      credentials: 'include',
      body: formData,
    }
  )
    .then(async function (response) {
      if (!response.ok) {
        let jsonError = await response.json();
        let errorMessage =
          jsonError && jsonError.message
            ? jsonError.message
            : 'An error occured, please try later or reload the page';
        throw Error(errorMessage);
      }
      return response;
    })
    .then(function (body) {
      return body;
    });

  return res;
};

export const createProductStart = (args) => async (dispatch) => {
  const { product, file, referenceFiles, navigate, sourceHistory } = args;
  dispatch({ type: 'CREATE_PRODUCT_START' });

  let socketId = localStorage.getItem('socketId');

  const createProductBody = {
    socketId: socketId,
    active: true,
    sourceHistory: sourceHistory,

    deprecationComment:
      product.deprecated && product.deprecationComment
        ? product.deprecationComment
        : '',
    ...product,
  };

  const createProductResult = await createProduct(createProductBody).catch(
    (error) => {
      //Catch generic error
      console.error(error);
      dispatch(openErrorSnack(error?.message));
    }
  );

  if (createProductResult && createProductResult.error) {
    //Catch form error
    dispatch({ type: 'CREATE_PRODUCT_FAIL' });
    dispatch({
      type: 'PRODUCTS_FORM_ERROR',
      payload: createProductResult.error,
    });
  } else if (createProductResult) {
    //No form error
    if (file) {
      createProductResult.pictureFileName = await uploadProductPicture(
        file,
        createProductResult._id
      );

      await updateProduct(createProductResult._id, createProductResult).catch(
        (error) => {
          dispatch({ type: 'CREATE_PRODUCT_FAIL' });
          dispatch(openErrorSnack(error?.message));
        }
      );
    }

    if (referenceFiles && referenceFiles.length > 0) {
      for (const refFile of referenceFiles) {
        await uploadProductReferenceDocumentS3(
          refFile,
          createProductResult._id
        ).catch((error) => {
          let errorMessage = error?.message ? error?.message : 'Unknow error';
          let errorToDisplay = `Error uploading "${refFile.name}" : ${errorMessage}`;
          dispatch(openErrorSnack(errorToDisplay));
        });
      }
      dispatch({ type: 'REMOVE_LOCAL_REFERENCE_FILES' });
    }

    await createProductAsJSON(createProductResult.productId);

    dispatch({ type: 'PRODUCT_PICTURE_CHANGED', payload: null });
    dispatch({ type: 'CREATE_PRODUCT_SUCCESS' });
    if (displayPlatinumUI) {
      dispatch({
        type: 'OPEN_GO_TO_ADMINISTRATION_MODAL_AFTER_ADDING_PRODUCT',
      });
    } else {
      navigate('/products/read/' + createProductResult._id);
    }
  }
};

export const closeGoToAdministrationAfterAddingProductModal =
  () => (dispatch) => {
    dispatch({ type: 'CLOSE_GO_TO_ADMINISTRATION_MODAL_AFTER_ADDING_PRODUCT' });
  };

export const updateProduct = async (mongoId, updateProductBody) => {
  let response = await apiPut('/api/prod/' + mongoId, updateProductBody);
  if (response.status >= 200 && response.status <= 299) {
    const updateProductResult = await response.json();
    return updateProductResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};
export const createProductAsJSON = async (productId) => {
  let response = await apiPost('/api/prod/createProductAsJSON', { productId });
  if (response.status >= 200 && response.status <= 299) {
    const createdJSONProductResult = await response.json();
    return createdJSONProductResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const flushLocalProductReferenceFiles = () => async (dispatch) => {
  dispatch({ type: 'REMOVE_LOCAL_REFERENCE_FILES' });
};

export const updateProductReferenceFileAttributeStart =
  (mongoId, referenceFileId, attribute, newValue) => async (dispatch) => {
    let attributeText = '';
    switch (attribute) {
      case 'label':
        attributeText = attribute;
        break;
      case 'validityStart':
        attributeText = 'validity start date';
        break;
      case 'validityEnd':
        attributeText = 'validity end date';
        break;

      default:
        break;
    }

    let product = await dispatch(getProduct(mongoId));
    if (!product) return;
    let productReferenceFiles =
      product.referenceFiles && Array.isArray(product.referenceFiles)
        ? product.referenceFiles
        : [];

    let newReferenceFiles = [...productReferenceFiles].map(
      (productReferenceFile) => {
        if (productReferenceFile._id === referenceFileId) {
          let updatedReferenceFile = { ...productReferenceFile };
          updatedReferenceFile[attribute] = newValue;
          return updatedReferenceFile;
        }
        return productReferenceFile;
      }
    );

    const updateProductBody = { ...product, referenceFiles: newReferenceFiles };

    const updateProductResult = await updateProduct(
      product._id,
      updateProductBody
    ).catch((error) => {
      //Catch generic error
      console.error(error);
      const errorMessage = error.message
        ? `File's ${attributeText} update failed : ${error.message}`
        : `File's ${attributeText} update failed`;
      dispatch(openErrorSnack(errorMessage));
      throw new Error(errorMessage);
    });
    if (updateProductResult && updateProductResult.error) {
      //Catch form error
      const errorMessage = updateProductResult?.error?.message
        ? `File's ${attributeText} update failed : ${updateProductResult?.error?.message}`
        : `File's ${attributeText} update failed`;

      dispatch(openErrorSnack(errorMessage));
      throw new Error(errorMessage);
    } else if (updateProductResult) {
      //No error
      dispatch(openSuccessSnack(`${capitalize(attributeText)} updated`));
      dispatch({
        type: 'UPDATE_REFERENCE_FILES_SUCCESS',
        payload: newReferenceFiles,
      });
    }
  };

export const updateProductStart = (args) => async (dispatch) => {
  dispatch({ type: 'UPDATE_PRODUCT_START' });
  const { product, file, referenceFiles, navigate, sourceHistory } = args;

  let socketId = localStorage.getItem('socketId');

  const updateProductBody = {
    socketId: socketId,
    ...product,
    sourceHistory: sourceHistory,
    deprecationComment:
      product.deprecated && product.deprecationComment
        ? product.deprecationComment
        : '',
  };

  const updateProductResult = await updateProduct(
    product._id,
    updateProductBody
  ).catch((error) => {
    //Catch generic error
    console.error(error);
    dispatch({ type: 'UPDATE_PRODUCT_FAIL' });
  });
  if (updateProductResult && updateProductResult.error) {
    //Catch form error
    dispatch({ type: 'UPDATE_PRODUCT_FAIL' });
    dispatch({
      type: 'PRODUCTS_FORM_ERROR',
      payload: updateProductResult.error,
    });
  } else if (updateProductResult) {
    //No form error
    if (file) {
      updateProductResult.pictureFileName = await uploadProductPicture(
        file,
        updateProductResult._id
      );

      await updateProduct(updateProductResult._id, updateProductResult).catch(
        (error) => {
          dispatch({ type: 'UPDATE_PRODUCT_FAIL' });
          dispatch(openErrorSnack(error?.message));
        }
      );
    }

    if (referenceFiles && referenceFiles.length > 0) {
      for (const refFile of referenceFiles) {
        await uploadProductReferenceDocumentS3(refFile, product._id).catch(
          (error) => {
            let errorMessage = error?.message ? error?.message : 'Unknow error';
            let errorToDisplay = `Error uploading "${refFile.name}" : ${errorMessage}`;
            dispatch(openErrorSnack(errorToDisplay));
          }
        );
      }
      dispatch({ type: 'REMOVE_LOCAL_REFERENCE_FILES' });
    }

    await updateProductHistoryInJSON(product.productId);
    //No error
    dispatch({ type: 'CREATE_PRODUCT_SUCCESS' });
    dispatch({ type: 'PRODUCT_PICTURE_CHANGED', payload: null });
    navigate('/products/read/' + updateProductResult._id);
  }
};

export const updateProductHistoryInJSON = async (productId) => {
  let socketId = localStorage.getItem('socketId');
  const updateProductBody = {
    socketId: socketId,
  };
  let response = await apiPut('/api/prod/json/' + productId, updateProductBody);
  if (response.status >= 200 && response.status <= 299) {
    const updateProductResult = await response.json();
    return updateProductResult;
  } else if (response.status === 422) {
    let res = await response.json();
    return res;
  } else {
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const getOffchainProdSources = (type) => async (dispatch) => {
  dispatch({ type: 'GET_PROD_SOURCES_START' });
  try {
    let response = await fetch(
      process.env.REACT_APP_API_URL + '/api/source/' + type,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }
    );

    handleErrors(response);

    let data = await response.json();
    if (data) {
      dispatch({ type: 'GET_PROD_SOURCES_SUCCESS', payload: data });
      return data;
    }
  } catch (error) {
    handleCatchedErrors(error);
    dispatch({ type: 'GET_PROD_SOURCES_FAIL' });
  }
};

export const setProductsWells = (selectedWellIds) => async (dispatch) => {
  dispatch({ type: 'SET_PRODUCT_WELLS', payload: selectedWellIds });
};

export const getProductProductionStart =
  (mongoId, navigate) => async (dispatch) => {
    dispatch({ type: 'GET_PRODUCT_PRODUCTION_START' });
    const productGraphData = await getProductGraphData(mongoId);
    if (productGraphData) {
      dispatch({
        type: 'GET_PRODUCT_PRODUCTION_SUCCESS',
        payload: productGraphData,
      });
    } else {
      dispatch({ type: 'GET_PRODUCT_PRODUCTION_FAIL' });
      navigate('/unauthorized');
      return { status: 'unauthorized' };
    }
  };

export const getProductAggregationsStart =
  (mongoId, page, pageSize) => async (dispatch) => {
    dispatch({ type: 'GET_PRODUCT_AGGREGATIONS_START' });
    const paginatedResults = await getProductAggregations(
      mongoId,
      page,
      pageSize
    );
    let { productAggregations, totalCountAggregations } = paginatedResults || {
      productAggregations: [],
      totalCountAggregations: 0,
    };

    if (checkObjectIsArray(productAggregations)) {
      dispatch({
        type: 'GET_PRODUCT_AGGREGATIONS_SUCCESS',
        payload: productAggregations || [],
      });
      dispatch({
        type: 'SET_PRODUCT_AGGREGATIONS_TOTAL_COUNT',
        payload: totalCountAggregations,
      });
    } else {
      dispatch({ type: 'GET_PRODUCT_AGGREGATIONS_FAIL' });
      const errorMessage = 'An error occured, try later or refresh the page';
      dispatch(openErrorSnack(errorMessage));
    }
  };

export const getProductStart = (mongoId) => async (dispatch) => {
  dispatch({ type: 'GET_PRODUCT_START' });

  let product = await dispatch(getProduct(mongoId));
  if (!product) {
    dispatch({ type: 'GET_PRODUCT_FAIL' });
    return;
  }
  await dispatch(getOrganizations());

  dispatch({ type: 'GET_PRODUCT_SUCCESS', payload: product });

  const productGraphData = await getProductGraphData(mongoId);

  dispatch({
    type: 'GET_PRODUCT_DATA_SUCCESS',
    payload: productGraphData,
  });
  return product;
};

export const displayAggregationTransactionsModal =
  (aggregationId) => async (dispatch) => {
    dispatch({
      type: 'DISPLAY_AGGREGATION_TRANSACTIONS_MODAL',
    });
    dispatch({
      type: 'SET_AGGREGATION_ID',
      payload: aggregationId,
    });
  };

export const hideAggregationTransactionsModal = () => async (dispatch) => {
  dispatch({
    type: 'HIDE_AGGREGATION_TRANSACTIONS_MODAL',
  });
  dispatch({
    type: 'SET_AGGREGATION_ID',
    payload: undefined,
  });
};

export const displayCheckProductAttributesModal = () => async (dispatch) => {
  dispatch({
    type: 'DISPLAY_CHECK_PRODUCT_ATTRIBUTES_MODAL',
  });
};
export const displayJSONDiffCheckProductAttributesModal =
  () => async (dispatch) => {
    dispatch({
      type: 'DISPLAY_JSON_DIFF_CHECK_PRODUCT_ATTRIBUTES_MODAL',
    });
  };

export const fetchHash = async (string) => {
  const response = await apiGet(`/api/tokens/hash/${string}`);
  if (response.status >= 200 && response.status <= 299) {
    return await response.json();
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const hideCheckProductAttributesModal = () => async (dispatch) => {
  dispatch({
    type: 'HIDE_CHECK_PRODUCT_ATTRIBUTES_MODAL',
  });
};

export const hideJSONDiffCheckProductAttributesModal =
  () => async (dispatch) => {
    dispatch({
      type: 'HIDE_JSON_DIFF_CHECK_PRODUCT_ATTRIBUTES_MODAL',
    });
  };

export const getProductFormStart = (mongoId) => async (dispatch) => {
  dispatch({ type: 'GET_PRODUCT_FORM_START' });

  let product = await dispatch(getProduct(mongoId));

  dispatch({
    type: 'GET_PRODUCT_FORM_SUCCESS',
    payload: product,
  });

  return product;
};

export const resetCurrentProduct = () => async (dispatch) => {
  dispatch({ type: 'RESET_PRODUCT' });
};

export const getProduct = (mongoId) => async (dispatch) => {
  try {
    const url = process.env.REACT_APP_API_URL + '/api/prod/' + mongoId;
    let response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });

    if (response.status === 401 || !response.ok) {
      response = await response.json();
      throw new Error(response.message);
    } else {
      let product = await response.json();
      if (!product.productId) throw new Error(response.message);
      return product;
    }
  } catch (err) {
    console.error(err);
    dispatch(openErrorSnack('Get product failed'));
    return;
  }
};

export const getProductsTotalCount = () => async (dispatch) => {
  try {
    const { totalCount } = await getProducts();
    return totalCount;
  } catch (error) {
    dispatch(openErrorSnack(error?.message));
  }
};

export const getProductsStart = (page, pageSize) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_PRODUCTS_START' });
    const { products, totalCount } = await getProducts(page, pageSize);
    dispatch({ type: 'GET_PRODUCTS_SUCCESS', payload: products });
    dispatch({ type: 'GET_PRODUCTS_TOTAL_COUNT_SUCCESS', payload: totalCount });
  } catch (error) {
    dispatch({ type: 'GET_PRODUCTS_FAIL' });
    dispatch(openErrorSnack(error?.message));
  }
};

const getArchivedProducts = async (page, pageSize) => {
  const response = await apiGet(
    `/api/prod/archived?page=${page}&limit=${pageSize}`
  );
  if (response.status >= 200 && response.status <= 299) {
    return await response.json();
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const getArchivedProductsStart =
  (page, pageSize) => async (dispatch) => {
    dispatch({ type: 'GET_ARCHIVED_PRODUCTS_START' });
    try {
      const { archivedProducts, totalCount } = await getArchivedProducts(
        page,
        pageSize
      );
      if (checkObjectIsNonEmptyArray(archivedProducts)) {
        dispatch({
          type: 'GET_ARCHIVED_PRODUCTS_SUCCESS',
          payload: archivedProducts,
        });
        dispatch({
          type: 'GET_PRODUCTS_TOTAL_COUNT_SUCCESS',
          payload: totalCount,
        });
      }
    } catch (error) {
      //Catch generic error
      console.error(error);
      dispatch({ type: 'GET_ARCHIVED_PRODUCTS_FAIL' });
    }
  };

export const fetchMaxProductNumber = async () => {
  try {
    let response = await fetch(
      process.env.REACT_APP_API_URL + '/api/prod/maxId',
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }
    );

    handleErrors(response);

    let data = await response.json();

    return data;
  } catch (error) {
    handleCatchedErrors(error);
  }
};

export const createProduct = async (createProductBody) => {
  const response = await apiPost('/api/prod/add', createProductBody);
  if (response.status >= 200 && response.status <= 299) {
    const createProductResult = await response.json();
    return createProductResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const setOpenAggregationDetails = (bool) => (dispatch) => {
  dispatch({ type: 'SET_OPEN_AGGREGATION_DETAILS_DIALOG', payload: bool });
};
export const setAssetsMeteringToDisplayInDialog = (data) => (dispatch) => {
  dispatch({ type: 'SET_ASSETS_METERING_TO_DISPLAY_IN_DIALOG', payload: data });
};

export const getS3URLForProduct = (productId) => async (dispatch) => {
  dispatch({ type: 'LOADING_PRODUCT_JSON_S3_URL_START' });

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/fields/jsonPreview/${productId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }
    );

    if (response.status >= 200 && response.status <= 299) {
      const productHistory = await response.json();

      dispatch({
        type: 'SET_PRODUCT_JSON_HISTORY',
        payload: productHistory,
      });
      dispatch({
        type: 'LOADING_PRODUCT_JSON_S3_URL_SUCCESS',
      });
    } else {
      const errorMessage =
        'An error occured, please try later or refresh the page';
      dispatch(openErrorSnack(errorMessage));
    }
  } catch (error) {
    console.error(error);
    dispatch(openErrorSnack('Failed to get S3 URL for product JSON'));
  }
};
