import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import useFormStandardsSlicesCombiner from 'components/FormStandards/Redux/Slices/Combiner/useFormStandardsCombiner';
import {
  getJsonEditorStandard,
  getStandardFromContent,
  getJsonEditorStandardFromValues,
} from 'components/FormStandards/utils/utils';

import CommonFields from '../../Fields/Common/CommonFields';
import FormJsonSwitch from '../../Grid/Buttons/Atomic/FormJsonSwitch';
import StandardCTABloc from '../../Grid/Buttons/Bloc/StandardCTABloc';
import VanillaJSONEditor from '../../JSONEditor/VanillaJSONEditor';

type FormikJsonStandardContentTypeProps = {
  props: any;
};
const FormikJsonStandardContent = ({
  props,
}: FormikJsonStandardContentTypeProps) => {
  const { currentStandard } = useFormStandardsSlicesCombiner();

  const { cookies, toggleJsonView, setFieldValue, values } = props;
  const csrfToken = cookies?.cookies?._csrf;

  const [content, setContent] = useState({
    json: getJsonEditorStandard(currentStandard),
    text: undefined,
  });

  useEffect(() => {
    setContent({
      json: getJsonEditorStandard(currentStandard),
      text: undefined,
    });
  }, [currentStandard]);

  useEffect(() => {
    setContent({
      json: getJsonEditorStandardFromValues(values),
      text: undefined,
    });
  }, [values]);

  return (
    <>
      <FormJsonSwitch
        onFormJsonButtonClick={() => {
          const updatedStandard = getStandardFromContent({
            content,
            currentStandard,
          });
          if (updatedStandard) {
            setFieldValue('infoTexts', updatedStandard.infoTexts);
            toggleJsonView();
          }
        }}
        jsonActivated={true}
      />
      <Grid item xs={12} className="common-fields">
        <CommonFields token={csrfToken} />
      </Grid>
      <VanillaJSONEditor
        content={content}
        readOnly={false}
        onChange={(_content: any) => {
          const updatedStandard = getStandardFromContent({
            content: _content,
            currentStandard,
          });
          if (updatedStandard) {
            setFieldValue('infoTexts', updatedStandard.infoTexts);
            setContent(_content);
          }
        }}
      />
      <StandardCTABloc params={props.router?.params} />
    </>
  );
};

export default FormikJsonStandardContent;
