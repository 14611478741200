import { DialogContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import useDynamicListsSlicesCombiner from 'components/DynamicList/Redux/Slices/useDynamicListsSlicesCombiner';

const ConfirmDeletionContent = () => {
  const { dynamicListItemToDelete } = useDynamicListsSlicesCombiner();

  return (
    <DialogContent>
      <Typography
        variant="body2"
        display="block"
        style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 16 }}
      >
        Please confirm the deletion of the item{' '}
        <b>
          {dynamicListItemToDelete?.key} | {dynamicListItemToDelete?.value}
        </b>
      </Typography>
    </DialogContent>
  );
};

export default ConfirmDeletionContent;
