import React from 'react';

import { Box, Card, CardHeader, Grid } from '@mui/material';
import { openActionDialog } from 'actions';
import LoadingCircle from 'components/commons/LoadingCircle';
import PageTitle from 'components/commons/PageTitle';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { checkObjectIsNonEmptyArray } from 'utils/general/utils';

import settingsIcon from '../../../../assets/images/icons/settings.png';
import GenericAdministrationOperationsMonotoringModal from '../AdministrationOperationsMonitoring/GenericAdministrationModal';

type GenericCardAndModalCombinerPropsType = {
  title: string;
  actions: any[];
  icon?: any;
};

const GenericCardAndModalCombiner = ({
  title = 'Administration',
  actions,
  icon = settingsIcon,
}: GenericCardAndModalCombinerPropsType) => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <>
      <PageTitle title={title} loading={false} image={icon} />
      <Grid container spacing={2}>
        {checkObjectIsNonEmptyArray(actions) &&
          actions.map((action: any, index: number) => {
            return (
              <Grid key={index} item xs={12} lg={6}>
                <Box
                  onClick={() => dispatch(openActionDialog(action.name))}
                  style={{ cursor: 'pointer' }}
                >
                  <Card style={{ backgroundColor: '#ffffff' }}>
                    <CardHeader
                      avatar={
                        <img src={action.icon} style={{ maxHeight: 20 }} />
                      }
                      title={
                        <span style={{ color: 'black' }}>{action.title}</span>
                      }
                      subheader={
                        <span style={{ color: 'grey' }}>{action.subtitle}</span>
                      }
                    />
                    {action.loading && (
                      <LoadingCircle style={{ marginLeft: 10, top: 5 }} />
                    )}
                  </Card>
                </Box>
              </Grid>
            );
          })}
      </Grid>
      <GenericAdministrationOperationsMonotoringModal />
    </>
  );
};

export default GenericCardAndModalCombiner;
