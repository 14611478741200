import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useDecreaseBalanceFormConfirmationModalConfig from '../../Configuration/useDecreaseBalanceFormConfirmationModalConfig';

const ModalFooterDecreaseBalanceConfirmation = () => {
  const { footer } = useDecreaseBalanceFormConfirmationModalConfig();

  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterDecreaseBalanceConfirmation;
