import useLoginSlices from 'components/Balances/Redux/Slices/useLoginSlices';

import useOperationsSlices from './useOperationsSlices';

const useOperationsSlicesCombiner = () => {
  return {
    ...useOperationsSlices(),
    ...useLoginSlices(),
  };
};

export default useOperationsSlicesCombiner;
