import React from 'react';

export type AggregationCardContextType = {
  title?: string;
  source?: string;
  dynamicDataSourcesToFilterOn?: any[];
  fileUrl?: string;
};

const aggregationCardContext: AggregationCardContextType = {
  title: '',
  source: '',
  dynamicDataSourcesToFilterOn: [],
  fileUrl: '',
};
const AggregationCardContext = React.createContext(aggregationCardContext);

export default AggregationCardContext;
