import React from 'react';

import MuiTextField from '@mui/material/TextField';
import { fieldToTextField } from 'formik-mui';

const SetTextField = (props: any) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;

  const { formIsBeingFilledOutHandler, ...fieldProps } = props;

  const onChange = React.useCallback(
    (event: any) => {
      setFieldValue(name, event.target.value);
    },
    [setFieldValue, name]
  );

  return <MuiTextField {...fieldToTextField(fieldProps)} onChange={onChange} />;
};

export default SetTextField;
