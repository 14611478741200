import { Box, Typography } from '@mui/material';
import { UseDynamicListsSlicesCombinerType } from 'components/DynamicList/Redux/CustomHooks/types';
import useDynamicListsSlicesCombiner from 'components/DynamicList/Redux/Slices/useDynamicListsSlicesCombiner';
import Loading from 'components/GenericComponents/Async/Loading/Loading';

export type ToolbarPropsType = {
  title?: string;
  id?: string;
};

const ToolbarTitle = ({
  title = 'List items',
  id = 'list-toolbar-title',
}: ToolbarPropsType) => {
  const { loading }: UseDynamicListsSlicesCombinerType =
    useDynamicListsSlicesCombiner();

  return (
    <Box sx={{ flexGrow: 1 }} id={id}>
      <Typography variant="subtitle1">
        {title}
        {loading && <Loading size={16} style={{ marginLeft: 10 }} />}
      </Typography>
    </Box>
  );
};

export default ToolbarTitle;
