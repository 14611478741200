import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { toggleWarningDialog } from 'actions';
import useNominationsSlicesCombiner from 'components/Nominations/Redux/Slices/useNominationsSlicesCombiner';
import { useTranslation } from 'react-i18next';
import { FiClock } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import certificateIcon from '../../../../../assets/images/icons/certificate.png';
import NominationsHead from '../../Header/NominationsHead';
import NominationsToolbar from '../../Toolbar/NominationsToolbar';
import NominationsExchangesList from '../ExhangesList/NominationsExchangesList';

type NominationsExchangesPropsType = {
  exchanges: any[];
  text: string;
};

const NominationsExchanges = ({
  exchanges,
  text,
}: NominationsExchangesPropsType) => {
  const { t } = useTranslation();
  const { buyerOrganization, loading, nominationsRequestsToDoLength } =
    useNominationsSlicesCombiner();
  const dispatch: AppDispatch = useDispatch();

  return (
    <div>
      <NominationsToolbar title={`${text}ly nominated volumes`} />
      <div>
        <Table>
          <NominationsHead column_1={text} organization={buyerOrganization} />
          <TableBody>
            {loading && (
              <TableRow style={{ height: 49 }}>
                <TableCell colSpan={7}>
                  <Typography
                    variant="body2"
                    style={{ margin: 10, color: '#CECECE' }}
                  >
                    <FiClock
                      style={{
                        position: 'relative',
                        top: 3,
                        marginRight: 10,
                      }}
                    />
                    {t('Loading')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {exchanges.length <= 0 && !loading && (
              <TableRow style={{ height: 49 }}>
                <TableCell colSpan={7}>
                  <Typography
                    variant="body2"
                    style={{ margin: 10, color: '#CECECE' }}
                  >
                    <img
                      src={certificateIcon}
                      style={{
                        maxHeight: 20,
                        position: 'relative',
                        top: 3,
                        marginRight: 10,
                      }}
                      alt=""
                    />
                    {t('No exchanges yet')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <NominationsExchangesList exchanges={exchanges} />
          </TableBody>
        </Table>
        {text === 'Month' && (
          <Button
            onClick={() => dispatch(toggleWarningDialog())}
            disabled={loading || nominationsRequestsToDoLength === 0}
            style={{ margin: 20 }}
            variant="contained"
            color="primary"
          >
            Nominate
          </Button>
        )}
      </div>
    </div>
  );
};

export default NominationsExchanges;
