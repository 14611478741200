/* eslint-disable */
import { handleErrors } from './ErrorActions';
import { fetchProductDataForMonth } from './AssetsProducingActions';
import {
  openSuccessSnack,
  openErrorSnack,
  apiPut,
  apiGet,
} from './UtilsActions';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from 'components/Transactions/utils/constants';
import { checkObjectIsNonEmptyArray } from 'utils/general/utils';

export const getSecretWord = async (_id, year, month) => {
  let secretUrl = process.env.REACT_APP_API_URL;
  secretUrl += '/api/secretwords/organization/';
  secretUrl += _id + '/';
  secretUrl += year + '/';
  secretUrl += month;

  return await fetch(secretUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleErrors)
    .then(async (res) => {
      return res.json();
    })
    .then((result) => {
      return result ? result : null;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getStringHashedBySecretWord = async (
  _id,
  year,
  month,
  stringToGetHash
) => {
  let secretUrl = process.env.REACT_APP_API_URL;
  secretUrl += '/api/secretwords/organization/';
  secretUrl += _id + '/';
  secretUrl += year + '/';
  secretUrl += month + '/';
  secretUrl += stringToGetHash;

  return await fetch(secretUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleErrors)
    .then(async (res) => {
      return res.json();
    })
    .then((result) => {
      return result ? result : null;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getStringHashed = async (stringToGetHash) => {
  let secretUrl = process.env.REACT_APP_API_URL;
  secretUrl += '/api/secretwords/';
  secretUrl += stringToGetHash;

  return await fetch(secretUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleErrors)
    .then(async (res) => {
      return res.json();
    })
    .then((result) => {
      return result ? result : null;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const setCertificatesStandard = (standardId) => (dispatch) => {
  if (standardId === `allStandards`) {
    dispatch({ type: 'SET_CERTIFICATES_STANDARD', payload: undefined });
  } else {
    dispatch({ type: 'SET_CERTIFICATES_STANDARD', payload: standardId });
  }
};

export const setCertificatesProductType = (productTypeId) => (dispatch) => {
  if (productTypeId === `allProductTypes`) {
    dispatch({ type: 'SET_CERTIFICATES_PRODUCT_TYPE', payload: undefined });
  } else {
    dispatch({ type: 'SET_CERTIFICATES_PRODUCT_TYPE', payload: productTypeId });
  }
};

export const setCertificatesStandardType = (standardType) => (dispatch) => {
  if (standardType === `allStandards`) {
    dispatch({ type: 'SET_CERTIFICATES_STANDARD_TYPE', payload: undefined });
  } else {
    dispatch({ type: 'SET_CERTIFICATES_STANDARD_TYPE', payload: standardType });
  }
};

export const cancelCommentChanged = (comment) => (dispatch) => {
  dispatch({ type: 'CANCEL_COMMENT_CHANGED', payload: comment });
};

export const displayCancelFormInDialog = () => (dispatch) => {
  dispatch({ type: 'DISPLAY_FORM_CANCEL_CERTIFICATE' });
};

export const openCancelCertificateFormDialog = (id) => (dispatch) => {
  dispatch({ type: 'OPEN_CANCEL_CERTIFICATE_FORM', payload: id });
};

export const closeCancelCertificateFormDialog = () => (dispatch) => {
  dispatch({ type: 'CLOSE_CANCEL_CERTIFICATE_FORM' });
};

export const displayWarningInCancelDialog = () => (dispatch) => {
  dispatch({ type: 'DISPLAY_WARNING_CANCEL_CERTIFICATE' });
};

export const toggleSecretWordDialog = () => (dispatch) => {
  dispatch({ type: 'TOGGLE_SECRET_WORD_DIALOG_CERTIFICATES' });
};

export const getSecretWordForCertificate =
  (certificate) => async (dispatch) => {
    dispatch({ type: 'LOAD_SECRET_WORD_START' });

    const origin = certificate.origin.assetID;
    const originField = certificate.origin.info3;
    const issuer = certificate.issuer.identifier;
    const from = certificate.from.identifier;
    const client = certificate.client.identifier;

    // const issuerSecretWord = await getSecretWord(certificate.issuer._id, certificate.year, certificate.month)
    // const fromSecretWord = await getSecretWord(certificate.from._id, certificate.year, certificate.month)
    // const clientSecretWord = await getSecretWord(certificate.client._id, certificate.year, certificate.month)
    let isOriginAlreadyHashed = origin.startsWith('0x');
    const originStringHashed = isOriginAlreadyHashed
      ? origin
      : await getStringHashed(origin);
    const issuerStringHashed = await getStringHashedBySecretWord(
      certificate.issuer._id,
      certificate.year,
      certificate.month,
      issuer
    );
    const fromStringHashed = await getStringHashedBySecretWord(
      certificate.from._id,
      certificate.year,
      certificate.month,
      from
    );
    const clientStringHashed = await getStringHashedBySecretWord(
      certificate.client._id,
      certificate.year,
      certificate.month,
      client
    );

    dispatch({
      type: 'LOAD_SECRET_WORD_SUCCESS',
      payload: {
        issuer,
        issuerSecretWord: issuerStringHashed,
        from,
        fromSecretWord: fromStringHashed,
        client,
        clientSecretWord: clientStringHashed,
        origin: originStringHashed,
        originField,
      },
    });

    //dispatch({ type: "LOAD_SECRET_WORD_FAIL" });
  };

export const getSecretWordForCertificateFromTransaction =
  (transaction) => async (dispatch) => {
    dispatch({ type: 'LOAD_SECRET_WORD_START' });

    let { certificates } = await fetch(
      `${process.env.REACT_APP_API_URL}/api/certificates`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }
    )
      .then(handleErrors)
      .then(async (res) => {
        return res.json();
      });
    let certificate =
      checkObjectIsNonEmptyArray(certificates) &&
      certificates.find(
        (certificate) => certificate.txHash === transaction.txHash
      );

    const origin = certificate.origin?.assetID;
    const originField = certificate.origin?.info3;
    const issuer = certificate.issuer?.identifier;
    const from = certificate.from?.identifier;
    const client = certificate.client?.identifier;

    let isOriginAlreadyHashed = origin.startsWith('0x');
    const originStringHashed = isOriginAlreadyHashed
      ? origin
      : await getStringHashed(origin);
    const issuerStringHashed = await getStringHashedBySecretWord(
      certificate.issuer._id,
      certificate.year,
      certificate.month,
      issuer
    );
    const fromStringHashed = await getStringHashedBySecretWord(
      certificate.from._id,
      certificate.year,
      certificate.month,
      from
    );
    const clientStringHashed = await getStringHashedBySecretWord(
      certificate.client._id,
      certificate.year,
      certificate.month,
      client
    );

    dispatch({
      type: 'LOAD_SECRET_WORD_SUCCESS',
      payload: {
        issuer,
        issuerSecretWord: issuerStringHashed,
        from,
        fromSecretWord: fromStringHashed,
        client,
        clientSecretWord: clientStringHashed,
        origin: originStringHashed,
        originField,
      },
    });

    //dispatch({ type: "LOAD_SECRET_WORD_FAIL" });
  };

export const getCertificatesByStandardStart =
  (
    standardId = 'allStandards',
    page = DEFAULT_PAGE_NUMBER,
    pageSize = DEFAULT_PAGE_SIZE
  ) =>
  async (dispatch) => {
    dispatch({ type: 'GET_CERTIFICATES_START' });

    return await fetch(
      `${process.env.REACT_APP_API_URL}/api/certificates/${standardId}?page=${page}&limit=${pageSize}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }
    )
      .then(handleErrors)
      .then(async (res) => {
        return res.json();
      })
      .then(
        async ({
          certificates,
          paginatedCertificatesCount,
          allCertificatesCount,
        }) => {
          dispatch(getCertificatesSuccess(certificates));
          dispatch(
            getCertificatesTotalCountSuccess(paginatedCertificatesCount)
          );
          dispatch(getAllCertificatesTotalCountSuccess(allCertificatesCount));
        }
      )
      .catch((error) => {
        console.error(error);
        dispatch(openErrorSnack(error?.message));
        getCertificatesFail(error, dispatch);
      });
  };

export const getCertificatesSuccess = (certificates) => (dispatch) => {
  dispatch({ type: 'GET_CERTIFICATES_SUCCESS', payload: certificates });
};
export const getCertificatesTotalCountSuccess = (totalCount) => (dispatch) => {
  dispatch({
    type: 'GET_CERTIFICATES_TOTAL_COUNT_SUCCESS',
    payload: totalCount,
  });
};
export const getAllCertificatesTotalCountSuccess =
  (AllCertificatesCount) => (dispatch) => {
    dispatch({
      type: 'GET_ALL_CERTIFICATES_TOTAL_COUNT_SUCCESS',
      payload: AllCertificatesCount,
    });
  };

export const getCertificatesFail = (error, dispatch) => {
  dispatch({ type: 'GET_CERTIFICATES_FAIL' });
};

export const downloadCertificatePdf = (certificate) => async (dispatch) => {
  let url =
    process.env.REACT_APP_API_URL +
    '/api/certificates/offchain/' +
    certificate._id +
    '/pdf';

  let result = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/pdf; charset=utf-8',
    },
  })
    .then(handleErrors)
    .then((res) => res.blob())
    .then((blob) => {
      var file = window.URL.createObjectURL(blob);
      window.open(file);
    })
    .catch((error) => {
      console.error(error);
      dispatch(openErrorSnack(error?.message));
    });
  dispatch({ type: 'CERTIFICATE_SAVED' });

  return result;
};

export const retireCertificate = (id, comment) => async (dispatch) => {
  const cancel = {
    _comment: comment ? comment : 'No comment provided',
  };
  dispatch({ type: 'CANCEL_CERTIFICATE_START', payload: id });
  try {
    const certificates = await apiPut(
      '/api/certificates/' + id + '/cancel',
      cancel
    );
    handleErrors(certificates);

    dispatch(cancelCertificateSuccess(id));
  } catch (error) {
    console.error(error);
    dispatch(cancelCertificateFail(error));
  }
};
export const cancelCertificate = (id, comment) => async (dispatch) => {
  const cancel = {
    _comment: comment ? comment : 'No comment provided',
  };
  dispatch({ type: 'CANCEL_CERTIFICATE_START', payload: id });
  try {
    const certificates = await apiPut(
      '/api/certificates/' + id + '/cancel',
      cancel
    );
    handleErrors(certificates);
    dispatch(cancelCertificateSuccess(id));
  } catch (error) {
    console.error(error);
    dispatch(cancelCertificateFail(error));
  }
};

export const cancelCertificateSuccess = (id) => (dispatch) => {
  dispatch({ type: 'CANCEL_CERTIFICATE_SUCCESS', payload: id });
  dispatch(openSuccessSnack('Certificate removed with success!'));
  dispatch({ type: 'CLOSE_CANCEL_CERTIFICATE_FORM' });
};

export const cancelCertificateFail = (error) => (dispatch) => {
  dispatch({ type: 'CANCEL_CERTIFICATE_FAIL' });
  dispatch(openErrorSnack(error?.message));
};

export const cancelCertificateFromTransactionHash =
  (txHash, comment) => async (dispatch) => {
    try {
      dispatch({
        type: 'RETIRE_CERTIFICATE_FROM_TRANSACTION_HASH_START',
        payload: txHash,
      });

      let cancel = { _comment: comment ? comment : 'No comment provided' };

      let response = await apiPut(
        '/api/transactions/' + txHash + '/cancel',
        cancel
      );
      if (response.status >= 200 && response.status <= 299) {
        const cancelResult = await response.json();
        cancelCertificateFromTransactionHashSuccess(txHash, dispatch);
        return cancelResult;
      } else {
        // Handle errors
        let jsonError = await response.json();
        let errorMessage = jsonError.message
          ? jsonError.message
          : 'An error occured, please try later or reload the page';
        throw new Error(errorMessage);
      }
    } catch (error) {
      cancelCertificateFromTransactionHashFail(error, dispatch);
    }
  };

export const cancelCertificateFromTransactionHashSuccess = (
  txHash,
  dispatch
) => {
  dispatch({
    type: 'CANCEL_CERTIFICATE_FROM_TRANSACTION_HASH_SUCCESS',
    payload: txHash,
  });
  dispatch(openSuccessSnack('Certificate removed with success'));
  dispatch({ type: 'CLOSE_CANCEL_CERTIFICATE_FORM' });
};

export const cancelCertificateFromTransactionHashFail = (error, dispatch) => {
  dispatch({ type: 'CANCEL_CERTIFICATE_FROM_TRANSACTION_HASH_FAIL' });
  dispatch(openErrorSnack(error?.message));
};

export const getDefaultCertificatesPaginationThresholdSuccess =
  (certificatesThreshold) => (dispatch) => {
    dispatch({
      type: 'SET_DEFAULT_CERTIFICATES_PAGINATION_THRESHOLD',
      payload: certificatesThreshold,
    });
  };

export const getDefaultCertificatesPaginationThreshold =
  () => async (dispatch) => {
    try {
      const response = await apiGet('/api/certificates/pagination/threshold');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const { certificatesThreshold } = await response.json();
      dispatch(
        getDefaultCertificatesPaginationThresholdSuccess(certificatesThreshold)
      );
    } catch (error) {
      console.error(error);
    }
  };
