import GenericModalContent from 'components/Administration/Generic/Modal/Content/GenericModalContent';

import { ModalStructurePropsType } from '../../../../../../utils/types/types';
import useUploadConfirmationDataForm from '../../Configuration/useUploadConfirmationDataForm';

const ModalContentUploadConfirmationDataForm = () => {
  const { header, content }: ModalStructurePropsType =
    useUploadConfirmationDataForm();

  return <GenericModalContent header={header} content={content} />;
};

export default ModalContentUploadConfirmationDataForm;
