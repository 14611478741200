import { UseProductSlicesCombinerType } from 'components/Product/Redux/CustomHooks/types';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { DefaultProductType } from 'reducers/productsReducer/types';

import SummaryField from '../../SymmaryField/SummaryField';
import { getStaticFieldsList, SummaryFieldPropsType } from '../utils';

const SummaryStaticContent = (): JSX.Element => {
  const { currentProduct }: UseProductSlicesCombinerType =
    useProductSlicesCombiner();
  const staticFields = getStaticFieldsList(
    currentProduct as DefaultProductType
  );
  return (
    <>
      {staticFields.map(
        ({ labelName, displayedValue }: SummaryFieldPropsType, index) => (
          <SummaryField
            key={index}
            labelName={labelName}
            displayedValue={displayedValue}
          />
        )
      )}
    </>
  );
};

export default SummaryStaticContent;
