export const handleError = async (response) => {
  let jsonError = await response.json();
  let errorMessage =
    jsonError && jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
  throw errorMessage;
};

export const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

export const handleErrorsJson = (response) => {
  if (!response.ok) {
    throw response;
  }
  return response.json();
};

export const handleCatchedErrors = (err) => {
  throw Error(err);
};
