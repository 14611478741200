import { SnackbarCloseButton } from 'components/commons/Snackbar/SnackbarCloseButton';

import { bulkOperationsDatasetChanged } from './AdministrationActions';
import { openSuccessSnack } from './UtilsActions';

export async function loadDataset(
  datasetFile: any,
  uploadFunction: any,
  datasetIdentifier: string,
  dispatch: any,
  resolve: any
) {
  try {
    const uploadDatasetResult = await uploadFunction(datasetFile);
    dispatch({ type: `UPLOAD_${datasetIdentifier}_SUCCESS` });
    dispatch(
      openSuccessSnack('Dataset document uploaded and fully treated', {
        persist: true,
        action: SnackbarCloseButton,
      })
    );
    resolve(uploadDatasetResult);
    dispatch(bulkOperationsDatasetChanged(null));
  } catch (error) {
    dispatch({ type: `UPLOAD_${datasetIdentifier}_FAIL` });
  }
}
