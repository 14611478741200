import { Grid } from '@mui/material';
import { FormikValues } from 'formik';

import DefaultStandardAttributeForm from '../DefaultAttributeForm';

interface InfoTextGridItemProps {
  index: number;
  isEven: boolean;
  onRemove: (
    values: FormikValues,
    setFieldValue: any,
    indexToRemove: number
  ) => void;
}

const InfoTextGridItem = ({
  index,
  isEven,
  onRemove,
}: InfoTextGridItemProps) => {
  return (
    <Grid
      width="100%"
      style={{ backgroundColor: isEven ? '#F5F8FA' : '#FFFFFF' }}
    >
      <DefaultStandardAttributeForm index={index} onRemove={onRemove} />
    </Grid>
  );
};

export default InfoTextGridItem;
