import { useState } from 'react';

import { displayWarningInBulkIssueDialog } from 'actions';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { FormikValues } from 'formik';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const useSeeProducts = () => {
  const { organizations } = useAdministrationSlicesCombiner();
  const dispatch: AppDispatch = useDispatch();
  const [seeProductsButtonClicked, setSeeProductsButtonClicked] =
    useState<boolean>(false);

  const onSubmitHandler = (values: FormikValues) => {
    const fullOrganization: any = organizations.find(
      (org) => org._id === values.organization
    );
    const currentIssueCertificateData = {
      ...values,
      organization: fullOrganization,
    };
    setSeeProductsButtonClicked(true);
    dispatch(displayWarningInBulkIssueDialog(currentIssueCertificateData));
  };
  return { seeProductsButtonClicked, onSubmitHandler };
};

export default useSeeProducts;
