import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Viewer } from 'json-diff-kit';
import ModalFooterJSONDiffCheckAttributes from 'components/Administration/Generic/Form/CustomForms/Blockchain/CheckAttributesForm/JSONCheckAttributes/Form/Footer/ModalFooterJSONCheckAttributes';
import useSummaryPreviewJSONModalConfig from 'components/Administration/Generic/Form/CustomForms/Blockchain/CheckAttributesForm/JSONCheckAttributes/Configuration/useSummaryPreviewJSONModalConfig';
import useProductSlices from 'components/Product/Redux/Slices/useProductSlices';
import { hideJSONDiffCheckProductAttributesModal } from 'actions';
import { AppDispatch } from 'redux/store';
import { useDispatch } from 'react-redux';
import 'json-diff-kit/dist/viewer.css';

export type HashHistory = { hash: string; productId: string };

const JSONDiffModal = () => {
  const dispatch: AppDispatch = useDispatch();
  const { diffResult, currentJSON } = useSummaryPreviewJSONModalConfig();
  const { openJSONDiffCheckProductDialog } = useProductSlices();
  const onCloseHandler = () =>
    dispatch(hideJSONDiffCheckProductAttributesModal());

  return (
    <Dialog
      open={openJSONDiffCheckProductDialog as boolean}
      onClose={onCloseHandler}
      aria-labelledby="dialog-title"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="dialog-title">
        Product attributes JSON Diff Viewer
      </DialogTitle>
      <DialogContent dividers>
        {diffResult ? (
          <Viewer
            diff={diffResult}
            indent={3}
            lineNumbers={true}
            highlightInlineDiff={true}
            inlineDiffOptions={{
              mode: 'char',
              wordSeparator: ' ',
            }}
          />
        ) : (
          <>
            <div>No differences to display</div>
            <pre>{JSON.stringify(currentJSON, null, 2)}</pre>
          </>
        )}
      </DialogContent>
      <ModalFooterJSONDiffCheckAttributes />
    </Dialog>
  );
};

export default JSONDiffModal;
