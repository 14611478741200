import ProductDialog from '../../components/Balances/CustomComponents/Dialogs/ProductDialog/ProductDialog';
import { withRouter } from '../../utils/migrationHelper';

import PdfDialog from './CustomComponents/Dialog/PdfDialog/PdfDialog';
import RemoveCertificatesDialog from './CustomComponents/Dialog/RemoveDialog/RemoveCertificatesDialog';
import SecretWordDialog from './CustomComponents/Dialog/SecretWordDialog/SecretWordDialog';
import CertificatesTable from './CustomComponents/Table/CertificatesTable';

const Certificates = () => {
  return (
    <div style={{ width: '100%' }}>
      <CertificatesTable />
      <PdfDialog />
      <SecretWordDialog />
      <ProductDialog />
      <RemoveCertificatesDialog />
    </div>
  );
};

export default withRouter(Certificates);
