import { SyntheticEvent } from 'react';

import { closeDecreaseBalanceFormDialog } from 'actions';
import GenericAutocompleteField from 'components/Administration/Generic/Form/Fields/Autocomplete/GenericAutocompleteField';
import GenericNumericField from 'components/Administration/Generic/Form/Fields/Numeric/GenericNumericField';
import GenericTextField from 'components/Administration/Generic/Form/Fields/Text/GenericTextField';
import {
  getMonthsMenuWithoutJSXRender,
  getYearsMenu,
  quantityTypeUppercase,
} from 'components/Administration/Generic/Form/utils/utils';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { FormikValues, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { FormFieldsConfigType } from '../../../../utils/types/formFieldsTypes';
import { FooterModalPropsType } from '../../../../utils/types/types';
import useUnitInForm from '../../../IssueCertificate/CustomComponents/IssueCertificateForm/CustomHooks/useUnitInForm';
import { halfYearArray, quarterArray } from '../../../Upload/utils/constants';
import useProductsAndOrganizationsOptions from '../../../utils/CustomHooks/useProductsAndOrganizationsOptions';
import useDecreaseBalanceOrganizationsMenu from '../CustomHooks/useDecreaseBalanceOrganizationsMenu';
import useDecreaseBalanceUnitAndPeriod from '../CustomHooks/useDecreaseBalanceUnitAndPeriod';

const useDecreaseBalanceFormModalConfig = () => {
  const { t } = useTranslation();
  const { setFieldValue, submitForm, values } =
    useFormikContext<FormikValues>();
  const { products, displayDecreaseBalanceFormInDialog } =
    useAdministrationSlicesCombiner();
  const { organizationsInDecreaseMenu } = useDecreaseBalanceOrganizationsMenu();
  const { organizationsOptions, productsOptions } =
    useProductsAndOrganizationsOptions(organizationsInDecreaseMenu);
  const { unitInForm } = useUnitInForm();
  const { period } = useDecreaseBalanceUnitAndPeriod();

  //modal components
  const modalHeader = {
    title: '',
  };
  const modalFooter: FooterModalPropsType = {
    cancelLabel: 'Cancel',
    submitLabel: 'Decrease',
    onCancelHandler: closeDecreaseBalanceFormDialog,
    onSubmitHandler: submitForm,
  };

  const formFieldsConfig: FormFieldsConfigType = [
    [
      {
        component: GenericAutocompleteField,
        name: 'origin',
        label: 'Product',
        options: (products as any[])?.length > 0 ? productsOptions : [],
        selectedValue:
          productsOptions.find(
            (option: any) => option.value === values.origin
          ) || '',
        onChange: (_: SyntheticEvent, selectedOption: any) => {
          setFieldValue('origin', selectedOption?.value);
        },
        condition: () => Boolean(displayDecreaseBalanceFormInDialog),
      },
      {
        component: GenericAutocompleteField,
        name: 'organization',
        label: 'Organization',
        options:
          values.origin && organizationsInDecreaseMenu?.length > 0
            ? organizationsOptions
            : [],
        selectedValue:
          organizationsOptions.find(
            (option: any) => option.value === values.organization
          ) || '',
        onChange: (_: SyntheticEvent, selectedOption: any) => {
          setFieldValue('organization', selectedOption?.value);
        },
        condition: () => Boolean(displayDecreaseBalanceFormInDialog),
      },

      {
        component: GenericNumericField,
        name: 'volume',
        label: t(quantityTypeUppercase + ' (' + unitInForm + ')'),
        onValueChange: (val: any) => {
          setFieldValue('volume', val.floatValue);
        },
        condition: () => Boolean(displayDecreaseBalanceFormInDialog),
      },

      {
        component: GenericTextField,
        name: 'year',
        label: 'Year',
        select: true,
        dynamicMenuItems: true,
        menuItemsCallback: getYearsMenu,
        condition: () => Boolean(displayDecreaseBalanceFormInDialog),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue('year', e.target.value),
      },
      {
        component: GenericTextField,
        name: 'month',
        label:
          period === 'month'
            ? 'Month'
            : period === 'semester'
            ? 'Half year'
            : 'Quarter',
        select: Boolean(
          period === 'month' || period === 'semester' || period === 'quarter'
        ),
        menuItems:
          period === 'month'
            ? getMonthsMenuWithoutJSXRender()
            : period === 'semester'
            ? halfYearArray
            : quarterArray,
        condition: () =>
          Boolean(displayDecreaseBalanceFormInDialog) &&
          (period === 'month' || period === 'semester' || period === 'quarter'),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('month', e.target.value);
        },
      },
      {
        component: GenericTextField,
        name: 'txHash',
        label: t('Transaction hash'),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('txHash', e.target.value);
        },
        condition: () => Boolean(displayDecreaseBalanceFormInDialog),
      },
      {
        component: GenericTextField,
        name: 'comment',
        label: t('Comment'),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('comment', e.target.value);
        },
        condition: () => Boolean(displayDecreaseBalanceFormInDialog),
      },
    ],
  ];
  return {
    header: modalHeader,
    content: formFieldsConfig,
    footer: modalFooter,
  };
};

export default useDecreaseBalanceFormModalConfig;
