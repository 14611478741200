import { GridColDef } from '@mui/x-data-grid-pro';
import { getCertificateInfo } from 'utils/datagridHelpers';

export const getDynamicColumns = (
  condition: boolean,
  iterator: any[],
  type?: string
): Array<GridColDef | undefined> => {
  const dynamicColumns: Array<GridColDef | undefined> = [];

  let standardObject = null;
  if (condition) {
    standardObject = iterator?.find((s: any) => s._id === condition);
  }

  if (standardObject) {
    for (
      let infoIndex = type && type === 'default' ? 0 : 4;
      infoIndex < standardObject.infoTexts.length;
      infoIndex++
    ) {
      const property = 'info' + infoIndex;
      const infoTextItem = standardObject.infoTexts.find(
        (t: any) => t.infoProp === property
      );
      const infoText =
        infoTextItem && !infoTextItem.isHidden
          ? infoTextItem.infoStandardText
          : '';

      if (infoText) {
        dynamicColumns.push({
          field: '_info' + infoIndex,
          headerName: infoText,
          editable: false,
          width: 200,
          valueGetter: (params: any) => getCertificateInfo(params, infoIndex),
        });
      }
    }
  }
  return dynamicColumns;
};
