import Link from '@mui/material/Link';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppDispatch } from 'redux/store';

import { openProductDialog } from '../../../actions';

type LinkOpenProductDialogPropType = {
  product: any;
  month: number;
  year: number;
  text: string;
};

const LinkOpenProductDialog = ({
  product,
  month,
  year,
  text,
}: LinkOpenProductDialogPropType) => {
  const dispatch: AppDispatch = useDispatch();
  //reset location state
  const location = useLocation();
  if (location) {
    location.state = null;
  }

  const handleOpenProductDialog = () => {
    //reset location state
    if (location) {
      location.state = null;
    }
    dispatch(openProductDialog(product, month, year));
  };

  return (
    <Link
      data-testid="link-cell-open-product-dialog"
      component="button"
      variant="body2"
      onClick={handleOpenProductDialog}
      style={{ textDecoration: 'none' }}
    >
      {text}
    </Link>
  );
};

export default LinkOpenProductDialog;
