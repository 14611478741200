import { RightReferences } from 'enums/RightReferences';
import { checkUserHasRight } from 'utils/user/checkRights';

import { StandardTypes } from '../../../../enums/StandardTypes';
import { removeDuplicatesFromArrayByProperty } from '../../../../utils/arrayHelper';
import { sortOrganizationsByName } from '../../../../utils/organization/sortOrganizations';
import { checkUserIsSuperAdmin } from '../../../../utils/user/checkAdmin/CheckAdmin';

const filterAndExcludeOrganizationsByTwoParams = (
  organizations = [],
  param1,
  param2
) => {
  const filteredOrgs =
    Array.isArray(organizations) &&
    param1 &&
    param2 &&
    organizations?.filter((organization) => organization[param1] !== param2);
  return filteredOrgs;
};

const formatBalancesAsDataGrid = (
  balances = [],
  selectedStandard,
  organizationsInTransferMenu,
  organizationsInAllocateMenu
) => {
  return balances.map((n, index) => {
    let isTrustWellOrMiq =
      selectedStandard &&
      (selectedStandard.standardType === StandardTypes.TRUSTWELL.id ||
        selectedStandard.standardType === StandardTypes.TRUSTWELL_DEMO.id ||
        selectedStandard.standardType ===
          StandardTypes.TRUSTWELL_TRANSPORT.id ||
        selectedStandard.standardType === StandardTypes.MIQ.id);

    let rowInDataGrid = {
      id: index,
      _year: n._year,
      _month: n._month,
      _quantity: n._quantity,
      _allocatedQuantity: n._allocatedQuantity,
      _organizationsInTransferMenu: organizationsInTransferMenu,
      _organizationsInAllocateMenu: organizationsInAllocateMenu,
      _origin: n._origin,
      _from: n._from,
    };

    n._origin.standardValues?.map(
      ({ key, value }) =>
        (rowInDataGrid['_' + key] = isTrustWellOrMiq ? value : '')
    );
    return rowInDataGrid;
  });
};

const getOrganizationsInTransferOrAllocateMenu = (
  businessOrganizations = [],
  organizationsInMenu = [],
  withFilterOption,
  param1,
  param2
) => {
  businessOrganizations.forEach((org) => {
    organizationsInMenu.push(org);
  });
  if (withFilterOption) {
    organizationsInMenu = filterAndExcludeOrganizationsByTwoParams(
      organizationsInMenu,
      param1,
      param2
    );
  }
  organizationsInMenu = removeDuplicatesFromArrayByProperty(
    organizationsInMenu,
    '_id'
  );
  return organizationsInMenu;
};

export const prepareBalancesData = (
  organizations = [],
  businessOrganizations = [],
  standards = [],
  selectedStandardId,
  user,
  isAuthenticated = false
) => {
  let selectedStandard = undefined;
  selectedStandard = standards?.find((s) => s._id === selectedStandardId);

  let userOrganization = user?.user_metadata?.organization;
  let organizationsInTransferMenu = organizations;
  // The users's organization should not appear on organizationsInAllocateMenu because it is the organization to which the user allocates by default.
  let organizationsInAllocateMenu = filterAndExcludeOrganizationsByTwoParams(
    organizations,
    '_id',
    userOrganization?._id
  );
  const userHasTransferRight = checkUserHasRight(
    isAuthenticated,
    user,
    RightReferences.TRANSFER
  );
  const userHasRetireRight = checkUserHasRight(
    isAuthenticated,
    user,
    RightReferences.RETIRE
  );
  if (checkUserIsSuperAdmin(isAuthenticated, user)) {
    //Super Admin
    organizationsInTransferMenu = getOrganizationsInTransferOrAllocateMenu(
      businessOrganizations,
      organizationsInTransferMenu
    );
    organizationsInAllocateMenu = getOrganizationsInTransferOrAllocateMenu(
      businessOrganizations,
      organizationsInAllocateMenu
    );
  } else if (userHasTransferRight || userHasRetireRight) {
    if (userHasTransferRight) {
      organizationsInTransferMenu = getOrganizationsInTransferOrAllocateMenu(
        businessOrganizations,
        organizationsInTransferMenu,
        true,
        '_id',
        userOrganization._id
      );
    } else {
      organizationsInTransferMenu = [];
    }
    if (userHasRetireRight) {
      organizationsInAllocateMenu = getOrganizationsInTransferOrAllocateMenu(
        businessOrganizations,
        organizationsInAllocateMenu
      );
    } else {
      organizationsInAllocateMenu = [];
    }
  } else {
    //If no specific roles or features
    organizationsInTransferMenu = [];
    organizationsInAllocateMenu = [];
  }
  organizationsInTransferMenu = sortOrganizationsByName(
    organizationsInTransferMenu
  );
  organizationsInAllocateMenu = sortOrganizationsByName(
    organizationsInAllocateMenu
  );

  return {
    selectedStandard,
    organizationsInTransferMenu,
    organizationsInAllocateMenu,
  };
};

export const getBalancesData = (
  loading = false,
  balances = [],
  organizations = [],
  businessOrganizations = [],
  standards = [],
  selectedStandardId,
  user,
  isAuthenticated
) => {
  if (loading || !organizations || !organizations[0]) {
    return [];
  }
  const {
    selectedStandard,
    organizationsInTransferMenu,
    organizationsInAllocateMenu,
  } = prepareBalancesData(
    organizations,
    businessOrganizations,
    standards,
    selectedStandardId,
    user,
    isAuthenticated
  );

  return formatBalancesAsDataGrid(
    balances,
    selectedStandard,
    organizationsInTransferMenu,
    organizationsInAllocateMenu
  );
};

export const getTransferOrAllocateToName = (
  certificatesData,
  organizations,
  organizationsInTransferAllocateMenuFiltredForSuperAdmin,
  user,
  isAuthenticated,
  type
) => {
  let transferOrAllocateToName = '';
  if (certificatesData._to) {
    let organizationsList = [...organizations];
    if (checkUserIsSuperAdmin(isAuthenticated, user))
      organizationsList = [
        ...organizationsInTransferAllocateMenuFiltredForSuperAdmin,
      ];
    organizationsList.push(user?.user_metadata?.organization);
    let transferOrAllocateToOrganization = organizationsList.find(
      (organization) =>
        organization._id ===
        (type === 'retire' ? certificatesData._to : certificatesData._to._id)
    );
    transferOrAllocateToName = transferOrAllocateToOrganization
      ? transferOrAllocateToOrganization.name
      : 'Error';
  }
  return transferOrAllocateToName;
};
