/* eslint-disable */

import {
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
  openSuccessSnack,
  openErrorSnack,
} from './UtilsActions';
import { handleError } from './ErrorActions';

export const getStandards = async () => {
  let response = await apiGet(`/api/standards`);

  if (response.status >= 200 && response.status <= 299) {
    const standards = await response.json();
    if (!Array.isArray(standards)) {
      throw new Error(
        'Error retrieving standards, returns : ' + JSON.stringify(standards)
      );
    }
    return standards;
  } else {
    // Handle errors
    await handleError(response);
  }
};
export const getUniqueStandardsFromBalances = async () => {
  let response = await apiGet(`/api/tokens/standards`);

  if (response.status >= 200 && response.status <= 299) {
    const uniqueStandards = await response.json();
    if (!Array.isArray(uniqueStandards)) {
      throw new Error(
        'Error retrieving standards, returns : ' +
          JSON.stringify(uniqueStandards)
      );
    }
    return uniqueStandards;
  } else {
    // Handle errors
    await handleError(response);
  }
};

export const getUniqueStandardsFromBalancesStart = () => async (dispatch) => {
  dispatch({ type: 'GET_UNIQUE_STANDARDS_FROM_BALANCES_START' });

  try {
    let standards = await getUniqueStandardsFromBalances();
    dispatch({
      type: 'GET_UNIQUE_STANDARDS_FROM_BALANCES_SUCCESS',
      payload: standards,
    });
  } catch (error) {
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'GET_UNIQUE_STANDARDS_FROM_BALANCES_FAIL' });
  }
};
export const getStandardsStart = () => async (dispatch) => {
  dispatch({ type: 'GET_STANDARDS_START' });

  try {
    let standards = await getStandards();
    dispatch({ type: 'GET_STANDARDS_SUCCESS', payload: standards });
  } catch (error) {
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'GET_STANDARDS_FAIL' });
  }
};

export const standardFormIsBeingFilledOut = () => (dispatch) => {
  dispatch({ type: 'STANDARD_FORM_IS_BEING_FILLED_OUT' });
};

export const addStandard = async (standard) => {
  let response = await apiPost(`/api/standards`, standard);

  if (response.status >= 200 && response.status <= 299) {
    const standardCreated = await response.json();
    return standardCreated;
  } else {
    // Handle errors
    await handleError(response);
  }
};

export const createStandardStart =
  (standardToCreate, navigate) => async (dispatch) => {
    dispatch({ type: 'CREATE_STANDARD_START' });

    try {
      const standardCreated = await addStandard(standardToCreate);
      dispatch(openSuccessSnack('Standard successfully created !'));

      let standards = await getStandards();
      dispatch({ type: 'CREATE_STANDARD_SUCCESS', payload: standards });
      navigate('/standards/');
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'CREATE_STANDARD_FAIL' });
    }
  };

export const getStandardStart = (standardId) => async (dispatch) => {
  dispatch({ type: 'GET_STANDARD_START' });

  try {
    let response = await apiGet(`/api/standards/${standardId}`);

    if (response.status >= 200 && response.status <= 299) {
      const standard = await response.json();
      dispatch({ type: 'GET_STANDARD_SUCCESS', payload: standard });
    } else {
      // Handle errors
      await handleError(response);
    }
  } catch (error) {
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'GET_STANDARD_FAIL' });
  }
};

export const resetCurrentStandard = () => async (dispatch) => {
  dispatch({ type: 'RESET_STANDARD' });
};

export const setStandardStart =
  (standardToUpdate, navigate) => async (dispatch) => {
    dispatch({ type: 'SET_STANDARD_START' });

    try {
      let response = await apiPut(
        `/api/standards/${standardToUpdate._id}`,
        standardToUpdate
      );

      if (response.status >= 200 && response.status <= 299) {
        const standardUpdate = await response.json();
        dispatch(openSuccessSnack('Standard successfully updated !'));
        let standards = await getStandards();
        dispatch({ type: 'SET_STANDARD_SUCCESS', payload: standards });
        navigate('/standards/');
      } else {
        // Handle errors
        await handleError(response);
      }
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'SET_STANDARD_FAIL' });
    }
  };

export const deleteStandardStart =
  (standardToDeleteId, navigate) => async (dispatch) => {
    dispatch({ type: 'DELETE_STANDARD_START' });

    try {
      let response = await apiDelete(`/api/standards/${standardToDeleteId}`);

      if (response.status >= 200 && response.status <= 299) {
        const standardDeleted = await response.json();
        dispatch(openSuccessSnack('Standard successfully deleted !'));

        let standards = await getStandards();
        dispatch({ type: 'DELETE_STANDARD_SUCCESS', payload: standards });
        navigate('/standards/');
      } else {
        // Handle errors
        await handleError(response);
      }
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'DELETE_STANDARD_FAIL' });
    }
  };
