import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { toggleHistoryDialog } from 'actions';
import { removeNonNumeric } from 'components/Administration/Generic/Form/utils/utils';
import { addThousandsSeparatorToNumber } from 'components/Balances/utils/table/helpers';
import LoadingCircle from 'components/commons/LoadingCircle';
import useNominationsSlicesCombiner from 'components/Nominations/Redux/Slices/useNominationsSlicesCombiner';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';

import historyIcon from '../../../../../assets/images/icons/timeMachine.png';

const NominationsHistoryDialog = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const {
    loading,
    buyerOrganization,
    isAuthenticated,
    user,
    clickedNominationHistoryArray,
    isLoadingClickedNominationHistory,
    isHistoryDialogOpen,
    clickedNominationId,
    minMaxRequestedHistory,
  } = useNominationsSlicesCombiner();

  const isSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, user);
  const hasSetBusinessOrganizationNominationFeature = checkUserHasRight(
    isAuthenticated,
    user,
    RightReferences.NOMINATIONS,
    [CrudPermissions.UPDATE]
  );

  const canManageMultipleOrganizationNominations =
    isSuperAdmin || hasSetBusinessOrganizationNominationFeature;
  let organizationName = '';
  if (isHistoryDialogOpen)
    organizationName = canManageMultipleOrganizationNominations
      ? 'for ' + buyerOrganization?.name
      : '';

  if (
    !clickedNominationId ||
    !clickedNominationHistoryArray ||
    clickedNominationHistoryArray.length <= 0
  )
    return <></>;
  const clickedNomination = clickedNominationHistoryArray.find(
    (n) => n._id.toString() === clickedNominationId.toString()
  );
  if (!clickedNomination) return <></>;

  let minMaxRequestedHistoryLabel = '';
  switch (minMaxRequestedHistory) {
    case 'min':
      minMaxRequestedHistoryLabel = 'Min';
      break;

    case 'max':
      minMaxRequestedHistoryLabel = 'Max';
      break;

    case 'requested':
      minMaxRequestedHistoryLabel = 'Requested';
      break;

    default:
      minMaxRequestedHistoryLabel = 'Unknown';
      break;
  }

  const sortedNominationsArray = clickedNominationHistoryArray.sort(
    (a, b) =>
      moment(b.createdAt).toDate().getTime() -
      moment(a.createdAt).toDate().getTime()
  );
  const sortedWithoutDuplicateNominationsArray = [];
  for (let index = 0; index < sortedNominationsArray.length; index++) {
    const currentNomination = sortedNominationsArray[index];
    const nextNomination = sortedNominationsArray[index + 1]
      ? sortedNominationsArray[index + 1]
      : undefined;
    if (nextNomination) {
      let nextNominationHasSameValue = false;
      switch (minMaxRequestedHistory) {
        case 'min':
          if (currentNomination.min === nextNomination.min)
            nextNominationHasSameValue = true;
          break;

        case 'max':
          if (currentNomination.max === nextNomination.max)
            nextNominationHasSameValue = true;
          break;

        case 'requested':
          if (currentNomination.requested === nextNomination.requested)
            nextNominationHasSameValue = true;
          break;

        default:
          break;
      }

      if (!nextNominationHasSameValue)
        sortedWithoutDuplicateNominationsArray.push(currentNomination);
    }
  }

  return (
    <Dialog
      fullScreen={false}
      open={isHistoryDialogOpen}
      onClose={toggleHistoryDialog}
      aria-labelledby="history-dialog"
    >
      <DialogTitle id="history-dialog">
        <img
          src={historyIcon}
          alt={'Data history'}
          title={'Data history'}
          style={{
            width: 20,
            height: 20,
            marginRight: 4,
            position: 'relative',
            top: 3,
          }}
        />{' '}
        {t('Nomination history data')}
      </DialogTitle>
      <DialogContent>
        <div>
          {minMaxRequestedHistoryLabel}{' '}
          {clickedNomination.periodType === 'year'
            ? clickedNomination.year
            : moment(
                clickedNomination.year + '-' + clickedNomination.month,
                'YYYY-MM'
              ).format('MMM-YYYY')}{' '}
          {organizationName}
        </div>
        {isLoadingClickedNominationHistory && <LoadingCircle />}
        {!isLoadingClickedNominationHistory &&
          clickedNominationHistoryArray.length > 0 && (
            <TableContainer
              component={Paper}
              style={{ marginBottom: 24, marginTop: 24 }}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">
                      {minMaxRequestedHistoryLabel}
                    </TableCell>
                    <TableCell align="right">Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedWithoutDuplicateNominationsArray
                    .sort(
                      (a, b) =>
                        moment(b.createdAt).toDate().getTime() -
                        moment(a.createdAt).toDate().getTime()
                    )
                    .map((n) => {
                      let valueToDisplay;

                      switch (minMaxRequestedHistory) {
                        case 'min':
                          valueToDisplay = n.min;
                          break;

                        case 'max':
                          valueToDisplay = n.max;
                          break;

                        case 'requested':
                          valueToDisplay = n.requested;
                          break;

                        default:
                          break;
                      }

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={n._id}
                        >
                          <TableCell align="right">
                            {addThousandsSeparatorToNumber(
                              removeNonNumeric(valueToDisplay)
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {n.createdAt
                              ? moment(n.createdAt).format(
                                  'YYYY-MM-DD HH:mm:ss'
                                )
                              : 'Unknown date'}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => dispatch(toggleHistoryDialog(clickedNominationId))}
          color="primary"
          component="span"
          style={{
            marginRight: 20,
            border: '1px solid rgba(248,119,51, 0.5)',
            borderRadius: 4,
          }}
        >
          {t('Close')}
        </Button>
        {loading && <LoadingCircle />}
      </DialogActions>
    </Dialog>
  );
};

export default NominationsHistoryDialog;
