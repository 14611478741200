import { FC, useContext } from 'react';

import { Button, DialogActions, DialogContent } from '@mui/material';
import { TextField as MaterialUiTextField } from '@mui/material';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import {
  closeTransferFormDialog,
  displayWarningInDialog,
} from '../../../../../actions';
import useBalancesChildComponentsSlices from '../../../Redux/CustomHooks/useBalancesChildComponentsSlices';
import OrganizationsContext, {
  OrganizationContextType,
} from '../../Dialogs/CombinedDialogs/OrganizationsContext';

import TransferedToField from './Fields/TransferedToField';
import VolumeField from './Fields/VolumeField';
import {
  getInitialValues,
  retrieveCurrentTransferCertificatesData,
  validateHandler,
} from './tools/utils';
import { FormikBodyPropertiesType } from './types';

const quantityTypeUppercase: string =
  process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum' ? 'Quantity' : 'Volume';

const TransferForm: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const {
    organizationsInTransferMenu: organizationsInTransferMenuRender,
    activateOzConversion,
  }: OrganizationContextType = useContext(OrganizationsContext);

  const {
    transferCertificatesData,
    selectedBalanceData,
    user,
    isAuthenticated,
    organizationsInTransferMenuFiltredForSuperAdmin,
  }: UseBalancesChildComponentsSlicesType = useBalancesChildComponentsSlices();

  const onSubmitHandler = (values: any): void => {
    const currentTransferCertificatesData =
      retrieveCurrentTransferCertificatesData(
        values,
        activateOzConversion,
        isAuthenticated,
        user,
        organizationsInTransferMenuFiltredForSuperAdmin,
        organizationsInTransferMenuRender
      );
    dispatch(displayWarningInDialog(currentTransferCertificatesData));
  };
  const transferFormikProps = {
    initialValues: getInitialValues(
      transferCertificatesData,
      organizationsInTransferMenuRender,
      activateOzConversion
    ),
    onSubmit: (values: any) => onSubmitHandler(values),
    validate: (values: any) =>
      validateHandler(
        values,
        selectedBalanceData,
        activateOzConversion,
        quantityTypeUppercase
      ),
  };

  const getTransferVolumeNumericProps = (
    values: any,
    setFieldValue: (v: string, vv: any) => void
  ) => {
    return {
      style: {
        marginTop: 20,
        width: 390,
        textAlign: 'center',
      },
      name: 'volume',
      label: `${quantityTypeUppercase} (${
        activateOzConversion ? 'oz' : transferCertificatesData._origin?.unit
      })`,
      value: values.volume,
      thousandSeparator: ' ',
      customInput: MaterialUiTextField,
      onValueChange: (val: any) => {
        setFieldValue('volume', val.floatValue);
      },
    };
  };
  return (
    <div>
      <Formik {...transferFormikProps}>
        {({
          submitForm,
          values,
          errors,
          setFieldValue,
        }: FormikBodyPropertiesType) => {
          return (
            <>
              <DialogContent
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: 20,
                }}
              >
                <TransferedToField errors={errors} />
                <VolumeField
                  getTransferVolumeNumericProps={getTransferVolumeNumericProps}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => dispatch(closeTransferFormDialog())}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={submitForm}
                  variant="contained"
                  color="primary"
                  disabled={
                    errors?.volume !== undefined ||
                    errors?.organization !== undefined
                  }
                >
                  Transfer
                </Button>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default TransferForm;
