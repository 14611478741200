import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';

import useNominationsSlicesCombiner from '../Slices/useNominationsSlicesCombiner';

const useNominationsGuards = () => {
  let organizationToUse, organizationUnit;
  const { isAuthenticated, user, isWarningDialogOpen, buyerOrganization } =
    useNominationsSlicesCombiner();

  const isSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, user);
  const hasNominationFeature = checkUserHasRight(
    isAuthenticated,
    user,
    RightReferences.NOMINATIONS,
    [CrudPermissions.VIEW]
  );
  const hasSetBusinessOrganizationNominationFeature = checkUserHasRight(
    isAuthenticated,
    user,
    RightReferences.NOMINATIONS,
    [CrudPermissions.UPDATE]
  );

  const canManageMultipleOrganizationNominations =
    isSuperAdmin || hasSetBusinessOrganizationNominationFeature;

  if (isWarningDialogOpen) {
    organizationToUse = canManageMultipleOrganizationNominations
      ? buyerOrganization
      : user.user_metadata.organization;

    if (organizationToUse && organizationToUse?.unit) {
      organizationUnit = organizationToUse?.unit;
    }
  }

  return {
    canManageMultipleOrganizationNominations,
    isSuperAdmin,
    hasSetBusinessOrganizationNominationFeature,
    hasNominationFeature,
    organizationToUse,
    organizationUnit,
  };
};

export default useNominationsGuards;
