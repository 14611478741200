/* eslint-disable */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.t('i18n', { lng: 'en' });
i18n.use(initReactI18next);

i18n.init(
  {
    // we init with resources
    nsSeparator: false,
    keySeparator: false,
    resources: {
      en: {
        translations: {
          'No data yet': 'No data yet',
          'To get started, edit <1>src/App.js</1> and save to reload.':
            'To get started, edit <1>src/App.js</1> and save to reload.',
          'Welcome to Attributes': 'Welcome to Attributes',
          'We connect the green energy worlds through Blockchain.':
            'We connect the green energy worlds through Blockchain.',
          Username: 'Username',
          Password: 'Password',
          'Sign In': 'Sign In',
          Users: 'Users',
          Organizations: 'Organizations',
          Countries: 'Countries',
          'Unknown organization': 'Unknown organization',
          'Organization name': 'Organization name',
          'Organization information': 'Organization information',
          'Update organization': 'Update organization',
          'Create organization': 'Create organization',
          'There is no organization yet': 'There is no organization yet',
          'Check certificate': 'Check certificate',
          'Check certificate’s authenticity':
            'Check certificate’s authenticity',
          'Producing assets': 'Producing assets',
          'Made with': 'Made with',
          by: 'by',
          'and certified on the Energy Web Foundation Blockchain':
            'and certified on the Energy Web Foundation Blockchain',
          Site: 'Site',
          'Consumption assets': 'Consumption assets',
          'Production assets': 'Production assets',
          'Retired to': 'Retired to',
          'Consumption asset': 'Consumption asset',
          'Production asset': 'Production asset',
          Certificates: 'Certificates',
          Certificate: 'Certificate',
          Secret: 'Secret',
          'Blockchain proof': 'Blockchain proof',
          'Loading secret word...': 'Loading secret word...',
          'Secret word': 'Secret word',
          'This is the secret used to anonymize your data in the Blockchain.':
            'This is the secret used to anonymize your data in the Blockchain.',
          Retire: 'Retire',
          Dashboard: 'Dashboard',
          'High Fidelity Green Energy Certificates':
            'High Fidelity Green Energy Certificates',
          'Monthly generated certificates': 'Monthly generated certificates',
          'The quantity of green energy retired each month, in MWh':
            'The quantity of green energy retired each month, in MWh',
          'Customer deliveries': 'Customer deliveries',
          Deliveries: 'Deliveries',
          Method: 'Method',
          Logout: 'Logout',
          Asset: 'Asset',
          Owner: 'Owner',
          'Avoided CO2': 'Avoided CO2',
          'of CO2 avoided': 'of CO2 avoided',
          'This is equivalent to': 'This is equivalent to',
          'CO2 equivalent': 'CO2 equivalent',
          'driven by an average passenger vehicle':
            'driven by an average passenger vehicle',
          'World tour conversion': 'World tour conversion',
          'world tours by plane for one passenger':
            'world tours by plane for one passenger',
          'urban tree seedlings grown for 10 years':
            'urban tree seedlings grown for 10 years',
          'Number of urban tree seedlings grown for 10 years':
            'Number of urban tree seedlings grown for 10 years',
          'Urban Tree Planted': 'Urban Tree Planted',
          'Nuclear waste': 'Nuclear waste',
          'of nuclear waste': 'of nuclear waste',
          'The production of 1 MWh of nuclear electricity in France produces about 11 g of radioactive waste':
            'The production of 1 MWh of nuclear electricity in France produces about 11 g of radioactive waste',
          'Homes conversion': 'Homes conversion',
          homes: 'homes',
          'Green electricity': 'Green electricity',
          millions: 'millions',
          billions: 'billions',
          'Calculation method for avoided CO2 emissions':
            'Calculation method for avoided CO2 emissions',
          'For each day, we retrieve the average CO2 emissions for that day (g of CO2 /kWh) from':
            'For each day, we retrieve the average CO2 emissions for that day (g of CO2 /kWh) from',
          'With the avoided CO2, the driven distance is calculated using 119g CO2/km wich is the average in the EU.':
            'With the avoided CO2, the driven distance is calculated using 119g CO2/km wich is the average in the EU.',
          'With the avoided CO2, the plane distance is calculated using 118g CO2/km and a distance of 40075 km for a world tour.':
            'With the avoided CO2, the plane distance is calculated using 118g CO2/km and a distance of 40075 km for a world tour.',
          'Equivalence in electricity consumption of X French homes. Average annual consumption per household in France in 2017. To find out the average electricity consumption of a French residential home, just divide the total amount of residential electricity consumed (in TWh) by the number of residential homes. 158,6 TWh / 32 078 000 homes = 4 944 kWh annual electricity consumption per French household.':
            'Equivalence in electricity consumption of X French homes. Average annual consumption per household in France in 2017. To find out the average electricity consumption of a French residential home, just divide the total amount of residential electricity consumed (in TWh) by the number of residential homes. 158,6 TWh / 32 078 000 homes = 4 944 kWh annual electricity consumption per French household.',
          'Avoided CO2 : Traditional GOs': 'Avoided CO2 : Traditional GOs',
          'Scope 3 : Transport CO2 emissions':
            'Scope 3 : Transport CO2 emissions',
          "Avoided CO2 : Certified On Attributes's blockchain":
            "Avoided CO2 : Certified On Attributes's blockchain",
          'Avoided CO2 : Certified by Attributes':
            'Avoided CO2 : Certified by Attributes',
          'Avoided CO2 in kg': 'Tracked avoided CO2 in kg',
          'Power in kWh': 'Power in kWh',
          Date: 'Date',
          'Delivery Id': 'Delivery Id',
          Product: 'Product',
          'Avoided CO2 (kg)': 'Tracked avoided CO2 (kg)',
          'CO2 Emissions (kg)': 'CO2 Emissions (kg)',
          'Site Id': 'Site Id',
          Reference: 'Reference',
          Customer: 'Customer',
          'Customer Id': 'Customer Id',
          'Download PDF': 'Download PDF',
          'Generating your PDF...': 'Generating your PDF...',
          'Loading the data, please wait': 'Loading the data, please wait',
          'Total Green Energy': 'Total Green Energy',
          on: 'on',
          'out of': 'out of',
          consumed: 'consumed',
          'From wind turbines': 'From wind turbines',
          'From hydroelectric power': 'From hydroelectric power',
          'From solar panels': 'From solar panels',
          'Consuming assets': 'Consuming assets',
          'Energy produced by ': 'Electricity produced by ',
          'Volume produced by ': 'Volume produced by ',
          'Asset production': 'Asset production',
          'Energy from ': 'Electricity from ',
          Wind: 'Wind',
          Solar: 'Solar',
          RunRiverHydro: 'RunRiverHydro',
          BiomassGas: 'BiomassGas',
          'matched energy': 'matched energy',
          'residual energy': 'residual energy',
          'Asset matched production': 'Asset matched production',
          ' (in ': ' (in ',
          Capacity: 'Capacity',
          'Owner  blockchain address': 'Owner  blockchain address',
          Weather: 'Weather',
          Forecast: 'Forecast',
          'Check your certificate': 'Check your certificate',
          "Upload your certificate and check it's authenticity.":
            "Upload your certificate and check it's authenticity.",
          'Drag and drop your certificate here':
            'Drag and drop your certificate here',
          'Checking your certificate...': 'Checking your certificate...',
          'Sorry, we can’t find this certificate':
            'Sorry, we can’t find this certificate',
          'This certificate is authentic': 'This certificate is authentic',
          Power: 'Power',
          'Production date ': 'Production date ',
          'No certificate uploaded': 'No certificate uploaded',
          'No figures': 'No figures',
          Stacked: 'Stacked',
          'Not stacked': 'Not stacked',
          Download: 'Download',
          'Consumption & related Production':
            'Consumption & related Production',
          'The consumption of the asset compared to the renewable energy producing assets':
            'The consumption of the asset compared to the renewable energy producing assets',
          'Key figures': 'Key figures',
          'Last 30 days': 'Last 30 days',
          'Year to date': 'Year to date',
          'Energy sources ratio': 'Electricity sources ratio',
          "The sources of your asset's energy consumption":
            "The sources of your asset's electricity consumption",
          'Yesterday’s energy sources': 'Yesterday’s electricity sources',
          'Energy Sources for the': 'Electricity Sources for the',
          "The sources of your asset's energy consumption (yesterday)":
            "The sources of your asset's electricity consumption (yesterday)",
          "The sources of your asset's energy consumption for this date":
            "The sources of your asset's electricity consumption for this date",
          'Average matching distance': 'Average matching distance',
          'Average distance': 'Average distance',
          ' ton': ' tons',
          RowsPerPage: 'Rows per page:',
          ' of ': ' of ',
          'Filter list': 'Filter list',
          'Export data': 'Export data',
          'Delevery Id': 'Delevery Id',
          'Site id or name': 'Site id or name',
          'Customer id or name': 'Customer id or name',
          'Filter by product': 'Filter by product',
          Start: 'Start',
          End: 'End',
          'Matching date': 'Matching date',
          'Start date': 'Start date',
          'End date': 'End date',
          'check transaction': 'check transaction',
          'Download certificate': 'Download certificate',
          Sort: 'Sort',
          'Wind (Fontanelles)': 'Wind (Fontanelles)',
          'Energy Mix': 'Energy Mix',
          'Lida consumption': 'Lida consumption',
          'Marèges produced energy': 'Marèges produced energy',
          'Fontanelles produced energy': 'Fontanelles produced energy',
          'Eget produced energy': 'Eget produced energy',
          'Soulom produced energy': 'Soulom produced energy',
          YYYY: 'YYYY',
          MM: 'MM',
          'DD ': 'DD ',
          HH: 'HH',
          mm: 'mm',
          'Green Energy Certificate': 'Green Energy Certificate',
          'For the amount of': 'For the amount of',
          ' Kilo Watt-Hour (': ' Kilo Watt-Hour (',
          ' kg of CO2 saved )': ' kg of CO2 saved )',
          'Produced by the renewable ': 'Produced by the renewable ',
          'Produced by the renewable asset': 'Produced by the renewable asset',
          ' resource': ' resource',
          ' Blockchain address ': ' Blockchain address ',
          'On the date': 'On the date',
          'Used for the exclusive and sole account of':
            'Used for the exclusive and sole account of',
          'Transferred on ': 'Transferred on ',
          'Transferred ': 'Transferred ',
          ' to account': ' to account',
          'This information is publicly and unalterably available in the Tobalaba Blockchain,':
            'This information is publicly and unalterably available in the Tobalaba Blockchain,',
          'and can be visible here ': 'and can be visible here ',
          unauthorized:
            "You doesn't have access right to this page, please contact an administrator",
        },
      },
      fr: {
        translations: {
          'To get started, edit <1>src/App.js</1> and save to reload.':
            'Starte in dem du, <1>src/App.js</1> editierst und speicherst.',
          'No data yet': 'Données non disponible',
          'Welcome to Attributes': 'Bienvenue sur Attributes',
          'We connect the green energy worlds through Blockchain.':
            'Nous connectons le monde des énergies renouvelables par la Blockchain',
          Username: 'Nom Utilisateur',
          Password: 'Mot de passe',
          'Sign In': 'Se Connecter',
          Users: 'Utilisateurs',
          Organizations: 'Organisations',
          'Unknown organization': 'Organisation inconnue',
          'Organization name': "Nom de l'organisation",
          'Organization information': "Informations sur l'organisation",
          'Update organization': 'Mettre à jour',
          'Create organization': 'Créer',
          'There is no organization yet': "Il n'y a pas encore d'organisation",
          'Check certificate': 'Vérification de certificat',
          'Check certificate’s authenticity':
            "Vérifier l' authenticité du certificat",
          'Producing assets': 'Sites de Production',
          'Made with': 'Fait avec',
          by: 'par',
          'and certified on the Energy Web Foundation Blockchain':
            "et certifié sur la blockchain de l'Energy Web Foundation",
          Site: 'Site',
          'Consumption assets': 'Sites de consommation',
          'Production assets': 'Sites de production',
          'Consumption asset': 'Site de consommation',
          'Retired to': 'Alloué à',
          'Production asset': 'Site de production',
          Certificates: 'Certificats',
          Certificate: 'Certificat',
          Secret: 'Secret',
          'Blockchain proof': 'Preuve blockchain',
          'Loading secret word...': 'Chargement du mot secret...',
          'Secret word': 'Mot secret',
          'This is the secret used to anonymize your data in the Blockchain.':
            'Le secret utilisé pour anonymiser vos données dans la Blockchain.',
          Retire: 'Retirer',
          Dashboard: 'Tableau de bord',
          'High Fidelity Green Energy Certificates':
            "Certificats d'énergie verte haute fidélité",
          'Monthly generated certificates': 'Certificats agrégés mensuellement',
          'The quantity of green energy allocated each month, in MWh':
            "La quantité d'énergie verte allouée, par mois, en MWh",
          'Customer deliveries': 'Livraisons',
          Deliveries: 'Livraisons',
          Method: 'Méthode',
          Logout: 'Déconnexion',
          Asset: 'Site',
          Owner: 'Propriétaire',
          'Avoided CO2': 'CO2 évité',
          'of CO2 avoided': 'de CO2 évité',
          'This is equivalent to': 'Ceci équivaut à',
          'CO2 equivalent': 'Equivalent CO2',
          'driven by an average passenger vehicle': 'parcourus en voiture',
          'World tour conversion': 'Conversion en tour du monde',
          'world tours by plane for one passenger':
            'tours du monde en avion pour un passager',
          'Urban Tree Planted': "Nombres d'arbres plantés",
          'Number of urban tree seedlings grown for 10 years':
            "Nombre de semis d'arbres urbains cultivés pendant 10 ans",
          'urban tree seedlings grown for 10 years':
            "semis d'arbres urbains cultivés pendant 10 ans",
          'Nuclear waste': 'Déchets nucléaires',
          'of nuclear waste': 'de déchets nucléaires',
          'The production of 1 MWh of nuclear electricity in France produces about 11 g of radioactive waste':
            'La production de 1 MWh d’électricité d’origine nucléaire en France produit environ 11 g de déchets radioactifs',
          'Homes conversion': 'Conversion en nombre de foyers',
          homes: 'foyers',
          'Green electricity': 'Electricité verte',
          millions: 'millions',
          billions: 'milliards',
          'Calculation method for avoided CO2 emissions':
            'Méthode de calcul des émissions de CO2 évitées',
          'For each day, we retrieve the average CO2 emissions for that day (g of CO2 /kWh) from':
            'Pour chaque journée, nous récupérons la moyenne des émissions de CO2 pour ce jour (en g de CO2 /kW) à partir de ',
          'With the avoided CO2, the driven distance is calculated using 119g CO2/km wich is the average in the EU.':
            "Avec la donnée de CO2 évité, la distance parcourue est calculée en utlisant 119g CO2/km qui est la moyenne des émissions au sein de l'UE.",
          'With the avoided CO2, the plane distance is calculated using 118g CO2/km and a distance of 40075 km for a world tour.':
            'Avec la donnée de CO2 évité, la distance parcourue en avions est calculée en utilisant 118g CO2/km et une distance de 40075 km pour un tour du monde.',
          'Equivalence in electricity consumption of X French homes. Average annual consumption per household in France in 2017. To find out the average electricity consumption of a French residential home, just divide the total amount of residential electricity consumed (in TWh) by the number of residential homes. 158,6 TWh / 32 078 000 homes = 4 944 kWh annual electricity consumption per French household.':
            "Equivalence en consommation de x foyers français. Consommation annuelle moyenne par foyer en France en 2017. Pour connaître la consommation électrique moyenne d'un foyer résidentiel français, il suffit de diviser la quantité totale d'électricité résidentielle consommée (en TWh) par le nombre de foyers résidentiels. 158,6 TWh / 32 078 000 sites = 4 944 kWh consommation électrique annuelle par foyer français.",
          'Avoided CO2 : Traditional GOs':
            'Emissions de CO2 évitées : GOs traditionnels',
          'Scope 3 : Transport CO2 emissions':
            'Scope 3 : Emissions de CO2 liées au transport',
          "Avoided CO2 : Certified On Attributes's blockchain":
            'Emissions de CO2 évitées (certifiées sur la blockchain de Attributes)',
          'Avoided CO2 : Certified by Attributes':
            'Emissions de CO2 évitées (certifiées par Attributes)',
          'Avoided CO2 in kg': 'CO2 évité en kg',
          'Power in kWh': 'Puissance en kWh',
          Date: 'Date',
          'Delivery Id': 'Id de livraison',
          Product: 'Produit',
          'Avoided CO2 (kg)': 'CO2 évité (kg)',
          'CO2 Emissions (kg)': 'Émissions de CO2 (kg)',
          'Site Id': 'Id du site',
          Reference: 'Référence',
          Customer: 'Client',
          'Customer Id': 'Id du client',
          'Download PDF': 'Télécharger le PDF',
          'Generating your PDF...': 'PDF en cours de génération...',
          'Loading the data, please wait':
            'Chargement des données, veuillez patienter',
          'Total Green Energy': 'Total énergie verte',
          on: 'sur',
          'out of': 'sur',
          consumed: 'consommés',
          'From wind turbines': "D'éoliennes",
          'From hydroelectric power': 'De barrages',
          'From solar panels': 'De panneaux solaires',
          'Consuming assets': 'Sites de Consommation',
          'Energy produced by ': 'Energie produite par ',
          'Volume produced by ': 'Volume produit par ',
          'Asset production': 'Production du site',
          'Energy from ': '',
          Wind: 'Parc éolien ',
          Solar: 'Solar',
          RunRiverHydro: "Centrale hydroélectrique au fil de l'eau ",
          BiomassGas: 'Gaz de biomasse',
          'matched energy': 'énergie matchée',
          'residual energy': 'énergie résiduelle',
          'Asset matched production': 'Production matchée du site',
          ' (in ': ' (en ',
          Capacity: 'Capacité',
          'Owner  blockchain address': 'Adresse Blockchain ',
          Weather: 'Météo',
          Forecast: 'Prévisions',
          'Check your certificate': 'Vérifiez votre certificat',
          "Upload your certificate and check it's authenticity.":
            'Téléchargez votre certificat et vérifiez son authenticité.',
          'Drag and drop your certificate here':
            'Faites glisser et déposez votre certificat ici',
          'Checking your certificate...': 'Vérification de votre certificat...',
          'Sorry, we can’t find this certificate':
            'Désolé, nous ne pouvons pas trouver ce certificat',
          'This certificate is authentic': 'Ce certificat est authentique',
          Power: 'Puissance',
          'Production date ': 'Date de production ',
          'No certificate uploaded': 'Aucun certificat téléchargé',
          'No figures': 'Pas de chiffres',
          Stacked: 'Empilé',
          'Not stacked': 'Non empilé',
          Download: 'Télécharger',
          'Consumption & related Production': 'Consommation & Production',
          'The consumption of the asset compared to the renewable energy producing assets':
            'Consommation totale et production des sites d’énergie renouvelables connectés',
          'Key figures': 'Chiffres clés',
          'Last 30 days': '30 derniers jours',
          'Year to date': "Depuis le début de l'année",
          'Energy sources ratio': 'Origine de votre électricité',
          "The sources of your asset's energy consumption":
            'Origine de l’électricité consommée par le site (30 derniers jours)',
          'Yesterday’s energy sources':
            'Origine de votre électricité pour hier',
          'Energy Sources for the': 'Origine de votre électricité pour le',
          "The sources of your asset's energy consumption (yesterday)":
            'Origine de l’électricité consommée par le site pour la journée d’hier',
          "The sources of your asset's energy consumption for this date":
            'Origine de l’électricité consommée par le site pour cette date',
          'Average matching distance':
            'Distance moyenne pondérée des sites de productions',
          'Average distance': 'Distance moyenne',
          ' ton': ' tonnes',
          RowsPerPage: 'Lignes par page:',
          ' of ': ' de ',
          'Filter list': 'Liste de filtres',
          'Export data': 'Exporter les données',
          'Delevery Id': 'Id de livraison',
          'Site id or name': 'Id ou nom du site',
          'Customer id or name': 'Id ou nom du client',
          'Filter by product': 'Filtrer par produit',
          Start: 'Début',
          End: 'Fin',
          'Matching date': 'Date du matching',
          'Start date': 'Date de début',
          'End date': 'Date de fin',
          'check transaction': 'vérifier la transaction',
          'Download certificate': 'Télécharger le certificat',
          Sort: 'Trier',
          'Wind (Fontanelles)': '\u00c9olienne (Fontanelles)',
          'Energy Mix': 'Mix Energétique',
          'Lida consumption': 'Consommation de Lida',
          'Marèges produced energy': '\u00c9nergie produite par Marèges',
          'Fontanelles produced energy':
            '\u00c9nergie produite par Fontanelles',
          'Eget produced energy': '\u00c9nergie produite par Eget',
          'Soulom produced energy': '\u00c9nergie produite par Soulom',
          YYYY: 'DD',
          MM: 'MM',
          'DD ': 'YYYY ',
          HH: 'HH',
          mm: 'mm',
          'Green Energy Certificate': "Certificat d'\u00c9nergie Verte",
          'For the amount of': 'Pour une quantité de',
          ' Kilo Watt-Hour (': ' Kilo Watt-heure (',
          ' kg of CO2 saved )': ' kg de CO2 évité )',
          'of CO2 avoided': 'de CO2 évité',
          'Produced by the renewable ': 'Produit par le site renouvelable de ',
          'Produced by the renewable asset': 'Produit par le site renouvelable',
          ' resource': '',
          ' Blockchain address ': ' Compte Blockchain ',
          'On the date': 'En date du',
          'Used for the exclusive and sole account of':
            'Utilisé pour le compte exclusif de',
          'Transferred on ': 'Transféré en date du ',
          'Transferred ': 'Transféré ',
          ' to account': ' au compte',
          'This information is publicly and unalterably available in the Tobalaba Blockchain,':
            'Cette information est stockée publiquement et de manière inaltérable dans la blockchain Tobalaba,',
          'and can be visible here ': 'et peut-être visible ici ',
          unauthorized:
            "Vous n'êtes pas autorisé à accéder à cette page, contactez un administrateur",
          Firstname: 'Firstname',
          Lastname: 'Lastname',
        },
      },
      de: {
        translations: {
          'To get started, edit <1>src/App.js</1> and save to reload.':
            'Starte in dem du, <1>src/App.js</1> editierst und speicherst.',
          'Welcome to Attributes': 'Willkommen bei Attributes',
          Username: 'Nutzername',
          Password: 'Passwort',
          'Sign In': 'Anmelden',
          Users: 'Users',
          Organizations: 'Organizations',
          'There is no organization yet': 'There is no organization yet',
          'Check certificate': 'Zertifikatsverifizierung',
          'Check certificate’s authenticity':
            'Überprüfen Sie die Authentizität des Zertifikats',
          'Producing assets': 'Produktionsgüter',
          'Made with': 'Gemacht mit',
          by: 'durch',
          'Certified on the Energy Web Foundation Blockchain':
            'und auf der Energy Web Foundation Blockchain zertifiziert',
          Site: 'Der Standort',
          'Consumption assets': 'Verbrauchsgüter',
          'Production assets': 'Produktionsanlagen',
          Certificates: 'Zertifikate',
          'Customer deliveries': 'Kundenlieferungen',
          Dashboard: 'Dashboard',
          Method: 'Method',
          Logout: 'Ausloggen',
          Asset: 'Vermögenswert',
          Owner: 'Inhaber',
          'Avoided CO2': 'Avoided CO2',
          'Avoided CO2 : Traditional GOs': 'Avoided CO2 : Traditional GOs',
          'Scope 3 transport CO2 emissions':
            'Scope 3 : Transport CO2 emissions',
          'Avoided CO2 : Tracked through blockchain':
            'Avoided CO2 : Tracked through blockchain',
          'Avoided CO2 in kg': 'Vermiedenes CO2 in kg',
          'Power in kWh': 'Leistung in kWh',
          Date: 'Datum',
          'Delivery Id': 'Liefer-ID',
          Product: 'Produkt',
          'Avoided CO2 (kg)': 'Vermiedenes CO2 (kg)',
          'CO2 Emissions (kg)': 'CO2-Emissionen (kg)',
          'Site Id': 'Webseitenadresse',
          Customer: 'Kunde',
          'Customer Id': 'Kunden-ID',
          'Download PDF': 'PDF herunterladen',
          'Consuming assets': 'Konsumgüter',
          'Energy produced by ': 'Energy produced by ',
          'Volume produced by ': 'Volume produced by ',
          'Asset production': 'Anlagenproduktion',
          'Energy from ': 'Energie aus ',
          Wind: 'Wind',
          Solar: 'Solar',
          RunRiverHydro: 'RunRiverHydro',
          BiomassGas: 'BiomassGas',
          'matched energy': 'matched energy',
          'residual energy': 'residual energy',
          'Asset matched production': 'Asset matched production',
          ' (in ': ' (im ',
          Capacity: 'Kapazität',
          'Owner  blockchain address': 'Besitzer-Blockchain-Adresse',
          Weather: 'Wetter',
          Forecast: 'Prognose',
          'Check your certificate': 'Überprüfen Sie Ihr Zertifikat',
          "Upload your certificate and check it's authenticity.":
            'Laden Sie Ihr Zertifikat hoch und überprüfen Sie es auf Echtheit.',
          'Drag and drop your certificate here':
            'Ziehen Sie Ihr Zertifikat hierher und legen Sie es dort ab',
          'Checking your certificate...': 'Überprüfen Sie Ihr Zertifikat...',
          'Sorry, we can’t find this certificate':
            'Entschuldigung, wir können dieses Zertifikat nicht finden',
          'This certificate is authentic': 'Dieses Zertifikat ist authentisch',
          Power: 'Leistung',
          'Production date ': 'Produktionsdatum ',
          'No certificate uploaded': 'Kein Zertifikat hochgeladen',
          'No figures': 'Keine Zahlen',
          Stacked: 'Gestapelt',
          Download: 'Herunterladen',
          'Consumption & related Production':
            'Verbrauch & verwandte Produktion',
          'The consumption of the asset compared to the renewable energy producing assets':
            'Der Verbrauch der Vermögenswerte im Vergleich zu den erneuerbaren Energie produzierenden Vermögenswerten',
          'Key figures': 'Key figures',
          'Last 30 days': 'Last 30 days',
          'Year to date': 'Year to date',
          'Energy sources ratio': 'Energiequellen Verhältnis',
          "The sources of your asset's energy consumption":
            'Die Quellen des Energieverbrauchs Ihres Assets (Letzte 30 Tage)',
          'Yesterday’s energy sources': 'Energiequellen von gestern',
          "The sources of your asset's energy consumption (yesterday)":
            'Die Quellen des Energieverbrauchs Ihres Assets (gestern)',
          'Average matching distance':
            'Durchschnittliche übereinstimmungsdistanz',
          ' ton': ' tonne',
          RowsPerPage: 'Zeilen pro Seite:',
          ' of ': ' von ',
          'Filter list': 'Filterliste',
          'Export data': 'Daten exportieren',
          'Delevery Id': 'Liefer-ID',
          'Site id or name': 'Site-ID oder Name',
          'Customer id or name': 'Kunden-ID oder Name',
          'Filter by product': 'Nach Produkt filtern',
          Start: 'Anfang',
          End: 'Ende',
          'Matching date': 'Matching date',
          'Start date': 'Anfang',
          'End date': 'Ende',
          'check transaction': 'Transaktion prüfen',
          'Download certificate': 'Zertifikat herunterladen',
          Sort: 'Sortieren',
          'Wind (Fontanelles)': 'Wind (Fontanelles)',
          'Energy Mix': 'Energiemix',
          'Lida consumption': 'Lida-Verbrauch',
          'Marèges produced energy': 'Marèges produzierte Energie',
          'Fontanelles produced energy': 'Fontanelles produzierte Energie',
          'Eget produced energy': 'Eget produzierte Energie',
          'Soulom produced energy': 'Soulom produzierte Energie',
          YYYY: 'YYYY',
          MM: 'MM',
          'DD ': 'DD ',
          HH: 'HH',
          mm: 'mm',
          'Green Energy Certificate': 'Green Energy Certificate',
          'For the amount of': 'For the amount of',
          ' Kilo Watt-Hour (': ' Kilo Watt-Hour (',
          ' kg of CO2 saved )': ' kg of CO2 saved )',
          'of CO2 avoided': 'of CO2 avoided',
          'Produced by the renewable ': 'Produced by the renewable ',
          ' resource': ' resource',
          ' Blockchain address ': ' Blockchain address ',
          'On the date': 'On the date',
          'Used for the exclusive and sole account of':
            'Used for the exclusive and sole account of',
          'Transferred on ': 'Transferred on ',
          ' to account': ' to account',
          'This information is publicly and unalterably available in the Tobalaba Blockchain,':
            'This information is publicly and unalterably available in the Tobalaba Blockchain,',
          'and can be visible here ': 'and can be visible here ',
        },
      },
    },
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    lng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      useSuspense: true, // Set useSuspense option to true
    },
  },
  () => {
    // force loading en
    i18n.t('i18n', { lng: 'en' });
  }
);

i18n.t('i18n', { lng: 'en' });

export default i18n;
