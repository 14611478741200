import { DialogContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';

const ConfirmDeletionContent = () => {
  const { referenceFileToDelete } = useProductSlicesCombiner();

  return (
    <DialogContent>
      <Typography
        variant="body2"
        display="block"
        style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 16 }}
      >
        Please confirm the deletion of the file{' '}
        <b>{referenceFileToDelete?.label}</b>
      </Typography>
    </DialogContent>
  );
};

export default ConfirmDeletionContent;
