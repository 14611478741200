import { Box, Typography } from '@mui/material';
import Loading from 'components/GenericComponents/Async/Loading/Loading';
import { UseProductSlicesCombinerType } from 'components/Product/Redux/CustomHooks/types';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import {
  PRODUCT_DATA_REFERENCE_FILES_TITLE,
  PRODUCT_DATA_REFERENCE_FILES_ID,
} from 'components/ProductDataAcquisition/utils/constants';

export type ToolbarPropsType = {
  title?: string;
  id?: string;
};

const ToolbarTitle = ({
  title = PRODUCT_DATA_REFERENCE_FILES_TITLE,
  id = PRODUCT_DATA_REFERENCE_FILES_ID,
}: ToolbarPropsType) => {
  const { loading }: UseProductSlicesCombinerType = useProductSlicesCombiner();

  return (
    <Box sx={{ flexGrow: 1 }} id={id}>
      <Typography variant="subtitle1">
        {title}
        {loading && <Loading size={16} style={{ marginLeft: 10 }} />}
      </Typography>
    </Box>
  );
};

export default ToolbarTitle;
