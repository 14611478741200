import { DialogContent } from '@mui/material';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';
import ProductReferenceFilesCard from 'components/ReferenceFiles/ProductReferenceFiles';

import SummaryCard from '../../../../../Product/CustomComponents/Card/SummaryCard/SummaryCard';

const ProductContent = () => {
  return (
    <DialogContent>
      <GenericGrid isContainer spacing={2}>
        <SummaryCard removeHiddenItems={true} />
        <ProductReferenceFilesCard
          userCanEditReferenceFiles={false}
          userCanReadReferenceFiles={true}
        />
      </GenericGrid>
    </DialogContent>
  );
};

export default ProductContent;
