/* eslint-disable */
import { AnyAction } from 'redux';
import {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
} from '../../actions/UtilsActions';
import { Units } from '../../enums/Units';
import { UtilsReducerStateType } from './types';

const INITIAL_STATE: UtilsReducerStateType = {
  co2Data: [],
  cookiesAccepted: null,
  loadingCo2: false,
  showDateFilter: false,
  ozOrGramsQuantityUnit: Units.GRAMS,
  unit: 'MWh',
  stacked: true,
  zoom: 1,
  mapCenter: { lat: 0, lng: 0 },
  notifications: [],
  connectionOk: true,
};

const utilsReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case 'ACCEPT_COOKIES':
      return { ...state, cookiesAccepted: true };

    case 'DECLINE_COOKIES':

    case 'SET_OZ_OR_GRAMS_QUANTITY_UNIT':
      const ozOrGramsQuantityUnit = action.payload;
      return { ...state, ozOrGramsQuantityUnit };

    case 'SET_UNIT':
      return { ...state, unit: action.payload };

    case 'TOGGLE_DATE_FILTER':
      const showDateFilter = !state.showDateFilter;
      return { ...state, showDateFilter };

    case 'TOGGLE_DATE_FILTER':
      return { ...state, stacked: !state.stacked };

    case 'TOGGLE_STACKED':
      return { ...state, stacked: !state.stacked };

    case 'SET_MAP_ZOOM_CENTER':
      return {
        ...state,
        zoom: action.payload.zoom,
        mapCenter: action.payload.center,
      };

    case 'GET_CO2_START':
      return { ...state, loadingCo2: true };
    case 'GET_CO2_SUCCESS':
      return { ...state, co2Data: action.payload, loadingCo2: false };
    case 'GET_CO2_FAIL':
      return { ...state, loadingCo2: false };

    case 'IS_DISCONNECTED':
      return { ...state, connectionOk: false };
    case 'IS_CONNECTED':
      return { ...state, connectionOk: true };

    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...(state.notifications || []),
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case CLOSE_SNACKBAR:
      let notificationsOnClose =
        state.notifications &&
        state.notifications.map((notification) => {
          return action.payload.dismissAll ||
            notification.key === action.payload.key
            ? { ...notification, dismissed: true }
            : { ...notification };
        });
      return {
        ...state,
        notifications: notificationsOnClose,
      };

    case REMOVE_SNACKBAR:
      let notificationsOnRemove =
        state.notifications &&
        state.notifications.filter(
          (notification) => notification.key !== action.payload.key
        );

      return {
        ...state,
        notifications: notificationsOnRemove,
      };
    default:
      return state;

    case 'RESET_NOTIFICATIONS':
      return {
        ...state,
        notifications: [],
      };
  }
};

export default utilsReducer;
