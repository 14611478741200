import { CrudPermissions } from 'enums/CrudPermissions';
import { RightCategories } from 'enums/RightCategories';
import { RightReferences } from 'enums/RightReferences';

export const DefaultRightsSet = [
  {
    type: {
      category: RightCategories.MODULES,
      ref: RightReferences.BALANCES,
      permissions: [CrudPermissions.VIEW],
    },
    activePermissions: [CrudPermissions.VIEW],
  },
  {
    type: {
      category: RightCategories.MODULES,
      ref: RightReferences.CERTIFICATES,
      permisisons: [CrudPermissions.VIEW],
    },
    activePermissions: [CrudPermissions.VIEW],
  },
  {
    type: {
      category: RightCategories.MODULES,
      ref: RightReferences.TRANSACTIONS,
      permisisons: [CrudPermissions.VIEW],
    },
    activePermissions: [CrudPermissions.VIEW],
  },
  {
    type: {
      category: RightCategories.OPERATIONS,
      ref: RightReferences.TRANSFER,
    },
  },
  {
    type: {
      category: RightCategories.OPERATIONS,
      ref: RightReferences.RETIRE,
    },
  },
  {
    type: {
      category: RightCategories.MODULES,
      ref: RightReferences.ORGANIZATIONS,
      permisisons: [CrudPermissions.VIEW, CrudPermissions.UPDATE],
    },
    activePermissions: [CrudPermissions.VIEW],
  },
  {
    type: {
      category: RightCategories.MODULES,
      ref: RightReferences.PRODUCTS,
      permisisons: [CrudPermissions.VIEW, CrudPermissions.UPDATE],
    },
    activePermissions: [CrudPermissions.VIEW],
  },
  {
    type: {
      category: RightCategories.MODULES,
      ref: RightReferences.USERS,
      permisisons: [CrudPermissions.VIEW, CrudPermissions.UPDATE],
    },
    activePermissions: [CrudPermissions.VIEW],
  },
];
