import { Button } from '@mui/material';

type GenericButtonPropsType = {
  condition?: boolean;
  label: string;
  onClick: (e: any) => void;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  color?: any;
  size?: 'small' | 'medium' | 'large' | undefined;
  disabled?: boolean;
  className?: any;
};

const GenericButton = ({
  condition,
  label,
  onClick,
  variant,
  color,
  size,
  disabled,
  className,
  ...restProps
}: GenericButtonPropsType) => {
  return condition ? (
    <Button
      onClick={onClick}
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      className={className}
      {...restProps}
    >
      {label}
    </Button>
  ) : (
    <></>
  );
};

export default GenericButton;
