import { useState, useEffect } from 'react';

import { GridSelectionModel, GridRowParams } from '@mui/x-data-grid';
import { setBulkIssuanceList } from 'actions';
import GenericTypography from 'components/Administration/Generic/Form/Labels/GenericTypography';
import GenericTable from 'components/GenericComponents/Tables/GenericTable/GenericTable';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import { getIssuanceListColumns } from '../../utils/columns';

export type IssuanceListPropsType = {
  issuanceList: any[];
};

const IssuanceList = ({ issuanceList }: IssuanceListPropsType) => {
  const dispatch: AppDispatch = useDispatch();

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridSelectionModel>([]);

  useEffect(() => {
    const newIssuanceList = issuanceList.map((productInIssuanceList, index) => {
      const isProductChecked = rowSelectionModel.some((item) => item === index);
      productInIssuanceList.checked = isProductChecked;
      return productInIssuanceList;
    });
    dispatch(setBulkIssuanceList(newIssuanceList));
  }, [rowSelectionModel]);

  const columnData = getIssuanceListColumns();

  return issuanceList && issuanceList.length > 0 ? (
    <div style={{ width: '100%', minHeight: 400, marginTop: 30 }}>
      <GenericTable
        tableRows={issuanceList.map((item, index) => {
          item.id = index;
          return item;
        })}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={setRowSelectionModel}
        tableColumns={columnData}
        pagination={false}
        isRowSelectable={(params: GridRowParams) =>
          params.row.dynamicDataIsAvailableForAllDynamicSources
        }
        checkboxSelection
      />
    </div>
  ) : (
    <GenericTypography
      name="There is no product with available volume for this period."
      size={12}
      styleLabel={{ paddingBottom: 10 }}
    />
  );
};

export default IssuanceList;
