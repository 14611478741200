import { DialogContent } from '@mui/material';

import AssetsMeteringTable from '../../AssetsMeteringTable/AssetsMeteringTable';

const AssetsMeteringContent = () => {
  return (
    <DialogContent style={{ height: 400 }}>
      <AssetsMeteringTable />
    </DialogContent>
  );
};

export default AssetsMeteringContent;
