import React from 'react';

import GenericCardAndModalCombiner from 'components/GenericComponents/Dialog/GenericCardAndModalCombiner/GenericCardAndModalCombiner';
import { WithSnackbarProps } from 'notistack';
import { withRouter } from 'utils/migrationHelper';

import useAdministration from './Redux/CustomHooks/useAdministration';
import useAdministrationSlicesCombiner from './Redux/Slices/useAdministrationSlicesCombiner';
import { getActions } from './utils/actions';

type AdministrationProps = WithSnackbarProps;

const Administration: React.FC<AdministrationProps> = () => {
  const { loadingUpdateBalancesCache } = useAdministrationSlicesCombiner();
  useAdministration();

  const actions = React.useMemo(
    () => getActions(loadingUpdateBalancesCache),
    [loadingUpdateBalancesCache]
  );

  return (
    <GenericCardAndModalCombiner title="Administration" actions={actions} />
  );
};

export default withRouter(Administration);
