import React from 'react';

import GenericModalContent from 'components/Administration/Generic/Modal/Content/GenericModalContent';

import useIssuanceEmailNotificationFormModalConfig from '../../Configuration/useIssuanceEmailNotificationFormModalConfig';

const ModalContentIssuanceEmailNotificationForm = () => {
  const { header, content } = useIssuanceEmailNotificationFormModalConfig();

  return <GenericModalContent header={header} content={content} />;
};

export default ModalContentIssuanceEmailNotificationForm;
