import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';

import DrawerLinkWithChildren from './DrawerLinkWithChildren';
import { LinkType } from './types/LinkType';

const DrawerLink = (props: {
  link: LinkType;
  onClick: any;
  activeLink: string;
  isSubLink?: boolean;
}): JSX.Element => {
  const { link, onClick, activeLink, isSubLink } = props;

  const children = link.children;
  const hasChildren = children && children.length > 0;
  const hasMoreThanOneChild = children && children.length > 1;
  if (hasChildren && hasMoreThanOneChild) {
    let isSubLinkActive = false;
    for (const child of children) {
      if (child.to === activeLink) {
        isSubLinkActive = true;
      }
    }

    return (
      <DrawerLinkWithChildren
        link={link}
        isSubLinkActive={isSubLinkActive}
        activeLink={activeLink}
        onClick={onClick}
      />
    );
  }

  const linkToDisplay =
    hasChildren && !hasMoreThanOneChild && children ? children[0] : link;

  let buttonStyle = {};
  let iconStyle = { maxHeight: 20 };
  if (linkToDisplay.to === activeLink) {
    buttonStyle = styles.activeListItem;
  }
  if (isSubLink && !hasMoreThanOneChild) {
    buttonStyle = { ...buttonStyle, paddingLeft: 40 };
  }

  if (isSubLink) iconStyle = { maxHeight: 16 };

  return (
    <NavLink
      key={linkToDisplay.to}
      to={linkToDisplay.to}
      style={{ textDecoration: 'none' }}
    >
      <ListItemButton
        onClick={() => {
          onClick(linkToDisplay);
        }}
        style={buttonStyle}
      >
        <ListItemIcon>
          <img src={linkToDisplay.icon} style={iconStyle} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography style={styles.listItemText}>
              {linkToDisplay.label}
            </Typography>
          }
        />
      </ListItemButton>
    </NavLink>
  );
};

const styles = {
  subMenuContainer: {
    backgroundColor: '#FCFCFC',
  },
  activeListItem: {
    backgroundColor: '#F2F2F2',
    textDecoration: 'none',
    '&:focus': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '&:visited': {
      textDecoration: 'none',
    },
    '&:active': {
      textDecoration: 'none',
    },
  },
  listItemText: {
    fontSize: '0.8rem',
    fontFamily: [
      'Inter-Regular',
      'Avenir',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
};
export default DrawerLink;
