import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';

import {
  addM2MApp,
  openSuccessSnack,
  openErrorSnack,
  getUserStart,
} from '../../actions';

export const AddM2MCredentials = ({
  user_id,
}: {
  user_id: string;
}): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();

  const connectedUser = useSelector(
    (state: RootState) => state.loginReducer.user
  );
  const isAuthenticated = useSelector(
    (state: RootState) => state.loginReducer.isAuthenticated
  );

  const [loading, setLoading] = useState(false);

  const createM2MApp = async () => {
    setLoading(true);
    try {
      const m2mcredentials = await addM2MApp(user_id);
      if (!m2mcredentials.client_secret)
        throw new Error('Error while creating M2M Application.');
      dispatch(openSuccessSnack('M2M App created'));
      dispatch(getUserStart(user_id.replace('auth0|', '')));
    } catch (error) {
      console.error(error);
      dispatch(openErrorSnack('Error while creating M2M Application.'));
    }
    setLoading(false);
  };

  if (!checkUserIsSuperAdmin(isAuthenticated, connectedUser)) return <></>;

  return (
    <Box style={{ marginTop: 20 }}>
      <Button
        onClick={createM2MApp}
        variant="contained"
        color="primary"
        disabled={loading}
      >
        Create API
      </Button>
    </Box>
  );
};
