import React from 'react';

import { Grid } from '@mui/material';
import Loading from 'components/GenericComponents/Async/Loading/Loading';

import DeleteStandard from '../Atomic/DeleteStandard';
import UpdateOrCreateStandard from '../Atomic/UpdateOrCreateStandard';

type StandardCTABlocPropsType = {
  params: any;
};
const StandardCTABloc = ({ params }: StandardCTABlocPropsType) => {
  return (
    <Grid item xs={12}>
      <Grid
        display="flex"
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <UpdateOrCreateStandard params={params} />
        <DeleteStandard params={params} />
        <Loading />
      </Grid>
    </Grid>
  );
};

export default StandardCTABloc;
