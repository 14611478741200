import { Box, Fab } from '@mui/material';
import { displayUploadReferenceFileDialog } from 'actions';
import { ProductReferenceFilesPropsType } from 'components/ReferenceFiles/ProductReferenceFiles';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import addFileIcon from '../../../../../../assets/images/icons/add-file.png';

const ToolbarUpload = ({
  userCanEditReferenceFiles,
}: ProductReferenceFilesPropsType): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const handleOpenUploadReferenceFileDialog = () => {
    dispatch(displayUploadReferenceFileDialog());
  };

  return userCanEditReferenceFiles ? (
    <Box>
      <Fab
        size="small"
        component="span"
        aria-label="add"
        style={{ backgroundColor: '#ffffff' }}
        onClick={handleOpenUploadReferenceFileDialog}
      >
        <img
          src={addFileIcon}
          alt={'Add reference document'}
          title={'Add reference document'}
          style={{
            width: 22,
            height: 22,
            marginTop: 6,
            marginLeft: 2,
          }}
        />
      </Fab>
    </Box>
  ) : (
    <></>
  );
};

export default ToolbarUpload;
