import React from 'react';

import { Grid, Typography } from '@mui/material';

export type GenericTypographyPropsType = {
  name: string;
  details?: string;
  size?: 6 | 12;
  styleGrid?: React.CSSProperties;
  styleLabel?: React.CSSProperties;
};

const GenericTypography = ({
  name = '',
  details = '',
  size = 6,
  styleGrid = { marginBottom: 6 },
  styleLabel = {},
}: GenericTypographyPropsType) => {
  return (
    <Grid item xs={size} style={styleGrid}>
      <Typography variant="body1" style={styleLabel}>
        {name}
      </Typography>
      {details !== '' && (
        <Typography variant="subtitle2" color="primary">
          {details}
        </Typography>
      )}
    </Grid>
  );
};

export default GenericTypography;
