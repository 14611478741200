import useLoginSlices from 'components/Balances/Redux/Slices/useLoginSlices';
import useStandardsSlices from 'components/Balances/Redux/Slices/useStandardsSlices';

import { UseStandardsCombinerType } from '../../CustomHooks/types';

const useStandardsCombiner = (): UseStandardsCombinerType => {
  return {
    ...useStandardsSlices(),
    ...useLoginSlices(),
  };
};

export default useStandardsCombiner;
