import { SelectChangeEvent } from '@mui/material';
import { setCertificatesStandard, setCertificatesStandardType } from 'actions';
import useCertificesSlicesCombiner from 'components/Certificates/Redux/Slices/useCertificatesSlicesCombiner';
import itemMapper from 'components/Certificates/utils/utils';
import { displayPlatinumUI } from 'components/CreateOrUpdateProduct/utils/utils';
import GenericBasicSelect from 'components/GenericComponents/BasicFields/GenericBasicSelect/GenericBasicSelect';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import LoadingSpinner from '../Loading/LoadingSpinner';

const StandardSelect = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    selectedStandardId,
    uniqueStandardsFromBalances,
    loadingUniqueStandardsFromBalances,
  } = useCertificesSlicesCombiner();

  const handleCertificateStandardChange = (
    event: SelectChangeEvent<string>
  ) => {
    const selectedStandardId = event.target.value;
    displayPlatinumUI
      ? dispatch(setCertificatesStandardType(selectedStandardId))
      : dispatch(setCertificatesStandard(selectedStandardId));
  };

  if (loadingUniqueStandardsFromBalances) return <LoadingSpinner />;

  return (
    <GenericBasicSelect
      labelId="select-certificates-standard"
      id="select-certificates-standard"
      value={selectedStandardId || 'allStandards'}
      onChange={(e) => handleCertificateStandardChange(e)}
      items={[
        {
          key: 'allStandards',
          value: 'allStandards',
          label: 'All standards',
        },
        ...itemMapper(uniqueStandardsFromBalances),
      ]}
    />
  );
};

export default StandardSelect;
