import { Card, CardHeader, Grid } from '@mui/material';

const Unauthorized = () => {
  return (
    <div>
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Unauthorized" />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Unauthorized;
