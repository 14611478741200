import { MenuItem } from '@mui/material';
import useCreateOrUpdateProductSlicesCombiner from 'components/CreateOrUpdateProduct/Redux/Slices/useCreateOrUpdateProductSlicesCombiner';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';
import { enums } from 'enums';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { getTimeZones } from 'utils/timeZone/TimeZones';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';

import ErrorContent from '../../Common/Fields/ErrorContent/ErrorContent';

const OrganizationUnitTimezoneGroup = () => {
  const { organizations, isAuthenticated, user } =
    useCreateOrUpdateProductSlicesCombiner();

  const userOrganizations = checkUserIsSuperAdmin(isAuthenticated, user)
    ? organizations
    : organizations.filter(
        (orga) => orga._id === user.user_metadata.organization._id
      );

  const timezones = getTimeZones().map((tz) => tz.value);
  const units = [
    enums.Units.KWH,
    enums.Units.MMBTU,
    enums.Units.GRAMS,
    enums.Units.KG,
    enums.Units.VOYAGES,
    enums.Units.TONS,
  ];

  return (
    <>
      <GenericGrid isItem xsLength={12} mdLength={6} lgLength={4}>
        <Field
          component={TextField}
          type="text"
          name="organization"
          label="Organization"
          select
          variant="standard"
          margin="normal"
          helperText=""
          style={{ width: 300 }}
          InputLabelProps={{
            shrink: true,
          }}
        >
          {userOrganizations.map((organization: any) => (
            <MenuItem key={organization._id} value={organization._id}>
              {organization.name}
            </MenuItem>
          ))}
        </Field>
        <ErrorContent field="organization" />
      </GenericGrid>

      <GenericGrid isItem xsLength={12} mdLength={6} lgLength={4}>
        <Field
          component={TextField}
          type="text"
          name="unit"
          label="Unit"
          select
          variant="standard"
          margin="normal"
          helperText=""
          style={{ width: 300 }}
          InputLabelProps={{
            shrink: true,
          }}
        >
          {units.map((unit: string) => (
            <MenuItem key={unit} value={unit}>
              {unit}
            </MenuItem>
          ))}
        </Field>
        <ErrorContent field="unit" />
      </GenericGrid>

      <GenericGrid isItem xsLength={12} mdLength={6} lgLength={4}>
        <Field
          component={TextField}
          type="text"
          name="timezone"
          label="Timezone"
          select
          variant="standard"
          margin="normal"
          helperText=""
          style={{ width: 300 }}
          InputLabelProps={{
            shrink: true,
          }}
        >
          {timezones.map((timezone: any) => (
            <MenuItem key={timezone} value={timezone}>
              {timezone}
            </MenuItem>
          ))}
        </Field>
        <ErrorContent field="timezone" />
      </GenericGrid>
    </>
  );
};

export default OrganizationUnitTimezoneGroup;
