import { useEffect, useState } from 'react';

import {
  getUniqueStandardsFromBalancesStart,
  getTransactionsStartByStandard,
  getTransactionsByAggregationIdStart,
  getDefaultTransactionsPaginationThreshold,
} from 'actions';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from 'components/Transactions/utils/constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';

import useTransactionsCombiner from '../Slices/Combiner/useTransactionsCombiner';

const useTransactions = () => {
  const {
    isAuthenticated,
    user,
    selectedStandardId,
    selectedStandardType,
    aggregationId,
  } = useTransactionsCombiner();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  useEffect(() => {
    const initDefaultPaginationThreshold = async () => {
      if (aggregationId) return;
      if (!isAuthenticated || !user) {
        navigate('/');
      } else {
        await dispatch(getDefaultTransactionsPaginationThreshold());
      }
    };
    initDefaultPaginationThreshold();
  }, []);

  // Reset pageSize when selectedStandardId changes
  useEffect(() => {
    setPageSize(DEFAULT_PAGE_SIZE);
    setPage(DEFAULT_PAGE_NUMBER);
  }, [selectedStandardId]);

  useEffect(() => {
    if (!isAuthenticated || !user) navigate('/');
  }, [user, isAuthenticated]);

  useEffect(() => {
    const initStandards = async () => {
      await dispatch(getUniqueStandardsFromBalancesStart());
    };
    initStandards();
  }, [selectedStandardType]);

  useEffect(() => {
    async function initComponent() {
      // If aggregationId is present, we don't need to fetch transactions by standard
      if (aggregationId) return;
      await dispatch(
        getTransactionsStartByStandard(selectedStandardId, page, pageSize)
      );
    }
    initComponent();
  }, [selectedStandardId, page, pageSize]);

  useEffect(() => {
    async function initComponent() {
      if (aggregationId) {
        await dispatch(
          getTransactionsByAggregationIdStart(aggregationId, page, pageSize)
        );
      }
    }
    initComponent();
  }, [aggregationId, page, pageSize]);

  const handlePageChange = (page: any) => {
    setPage(page);
  };
  const handlePageSizeChange = (size: any) => {
    setPageSize(size);
    setPage(0);
  };

  return { handlePageChange, handlePageSizeChange, page, pageSize };
};

export default useTransactions;
