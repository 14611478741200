import { useSelector } from 'react-redux';
import { NominationsReducerStateType } from 'reducers/nominationsReducer/types';
import { RootState } from 'redux/store';

const useNominationsSlices = (): NominationsReducerStateType => {
  //Redux Nominations slices
  const {
    loading,
    nominationsRequests,
    isWarningDialogOpen,
    isHistoryDialogOpen,
    isLoadingClickedNominationHistory,
    clickedNominationHistoryArray,
    clickedNominationId,
    selectedNominationId,
    minMaxRequestedHistory,
    nominationsRequestsToDoLength,
    nominationsRequestsToDo,
    buyerOrganization,
    selectedSellerOrganization,
    sellerOrganizations,
    year,
    independantsWithNominationsFeature,
    selected,
  }: NominationsReducerStateType = useSelector(
    (state: RootState) => state.nominationsRequestsReducer
  );
  return {
    loading,
    nominationsRequests,
    isWarningDialogOpen,
    isHistoryDialogOpen,
    isLoadingClickedNominationHistory,
    clickedNominationHistoryArray,
    clickedNominationId,
    selectedNominationId,
    minMaxRequestedHistory,
    nominationsRequestsToDoLength,
    nominationsRequestsToDo,
    buyerOrganization,
    selectedSellerOrganization,
    sellerOrganizations,
    year,
    independantsWithNominationsFeature,
    selected,
  };
};

export default useNominationsSlices;
