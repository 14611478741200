/* eslint-disable */

import React, { useEffect } from 'react';
import { useTheme, css, ClassNames } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';

import { tabStyles } from '../assets/styles/tabStyles';

import Paper from '@mui/material/Paper';

import Certificates from './Certificates/Certificates';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { withRouter } from '../utils/migrationHelper';
import { withTranslation } from 'react-i18next';
import { checkUserIsSuperAdmin } from '../utils/user/checkAdmin/CheckAdmin';

let displayPlatinumUI = process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';

let CertificateTabs = (props) => {
  const classes = tabStyles;
  const classesTheme = useTheme();
  let navigate = useNavigate();

  useEffect(() => {
    const { isAuthenticated, user } = props;
    if (!isAuthenticated || !user) {
      navigate('/');
    } else {
      //What do we start
    }
  }, []);

  const { user, isAuthenticated } = props;

  let isSuperAdmin = false;
  if (checkUserIsSuperAdmin(isAuthenticated, user)) isSuperAdmin = true;

  return <Certificates props={props} />;
};

const mapStateToProps = ({ loginReducer }) => {
  const { isAuthenticated, user } = loginReducer;
  return {
    isAuthenticated,
    user,
  };
};

const mapDispatchToProps = {};

CertificateTabs = withTranslation('translations')(CertificateTabs);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CertificateTabs)
);
