import { Toolbar } from '@mui/material';

import ToolbarTitle from './ToolbarContent/ToolbarTitle/ToolbarTitle';
import ToolbarUpload from './ToolbarContent/ToolbarUpload/ToolbarUpload';

const DynamicListItemsToolbar = (props: any): JSX.Element => {
  return (
    <Toolbar>
      <ToolbarTitle />
      <ToolbarUpload {...props} />
    </Toolbar>
  );
};

export default DynamicListItemsToolbar;
