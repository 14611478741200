import React from 'react';

import { DataGridPro as XGrid } from '@mui/x-data-grid-pro';

import './Styles/index.css';
import { GenericTablePropsType } from './types';

const GenericTable = ({
  isRowSelectable = () => false,
  checkboxSelection = false,
  rowSelectionModel = [],
  onRowSelectionModelChange = () => undefined,
  loading = false,
  disableVirtualization = false,
  disableSelectionOnClick = true,
  autoHeight = false,
  clickableRow = true,
  pagination = true,
  tableRows = [],
  tableColumns = [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleRowClick = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleRowDoubleClick = () => {},
  ToolbarComponent = () => <></>,
  isCellEditable = () => false,
  apiRef,
  onCellEditCommit,
  onCellEditStop,
  rowReordering = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onRowOrderChange = () => {},
  columnVisibilityModel = {},
}: GenericTablePropsType) => {
  return (
    <XGrid
      isRowSelectable={isRowSelectable}
      selectionModel={rowSelectionModel}
      onSelectionModelChange={onRowSelectionModelChange}
      checkboxSelection={checkboxSelection}
      {...(clickableRow && { className: 'display-clickable-row' })}
      disableVirtualization={disableVirtualization}
      pagination={pagination}
      autoHeight={autoHeight}
      disableSelectionOnClick={disableSelectionOnClick}
      loading={loading}
      rows={tableRows}
      columns={tableColumns}
      onRowClick={handleRowClick}
      onRowDoubleClick={handleRowDoubleClick}
      apiRef={apiRef}
      isCellEditable={isCellEditable}
      onCellEditCommit={onCellEditCommit}
      onCellEditStop={onCellEditStop}
      components={{ Toolbar: ToolbarComponent }}
      rowReordering={rowReordering}
      onRowOrderChange={onRowOrderChange}
      columnVisibilityModel={columnVisibilityModel}
    />
  );
};

export default GenericTable;
