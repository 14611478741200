import { Box, Fab, Toolbar, Typography } from '@mui/material';
import { toggleDisplaySourceForm } from 'actions';
import useCreateOrUpdateProductSlicesCombiner from 'components/CreateOrUpdateProduct/Redux/Slices/useCreateOrUpdateProductSlicesCombiner';
import Loading from 'components/GenericComponents/Async/Loading/Loading';
import { PRODUCT_DATA_ACQUISITION_TITLE } from 'components/ProductDataAcquisition/utils/constants';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import addDataIcon from '../../../../assets/images/icons/add-to-database.png';
import cancelIcon from '../../../../assets/images/icons/cancel.png';

const DataAcquisitionToolbar = () => {
  const dispatch: AppDispatch = useDispatch();
  const { loading, currentProduct } = useCreateOrUpdateProductSlicesCombiner();

  return (
    <Toolbar>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle1">
          {PRODUCT_DATA_ACQUISITION_TITLE}
          {loading && <Loading size={16} style={{ marginLeft: 10 }} />}
        </Typography>
      </Box>
      <Box>
        <Fab
          size="small"
          component="span"
          aria-label="add"
          style={{ backgroundColor: '#ffffff' }}
          onClick={() => dispatch(toggleDisplaySourceForm())}
          data-testid="add-or-cancel-source-btn-id"
        >
          <img
            src={currentProduct?.isSourceFormVisible ? cancelIcon : addDataIcon}
            alt={'Add reference document'}
            title={'Add reference document'}
            style={{
              width: 22,
              height: 22,
              marginTop: 6,
              marginLeft: 2,
            }}
          />
        </Fab>
      </Box>
    </Toolbar>
  );
};

export default DataAcquisitionToolbar;
