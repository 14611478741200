import GenericInputField from 'components/GenericComponents/Forms/Formik/Fields/Input/GenericInputField';
import GenericSelectField from 'components/GenericComponents/Forms/Formik/Fields/Select/GenericSelectField';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';

import FrequencyLabel from './FrequencyContent/FrequencyLabel/FrequencyLabel';

type FrequencyGroupPropsType = {
  inputName: string;
  selectName: string;
  label: string;
};

const FrequencyGroup = ({
  inputName,
  selectName,
  label,
}: FrequencyGroupPropsType) => {
  return (
    <GenericGrid isItem xsLength={12}>
      <GenericGrid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <FrequencyLabel label={label} />
        <GenericInputField
          xs={2}
          name={inputName}
          type="text"
          style={{ marginTop: 20, width: 100 }}
        />

        <GenericSelectField
          xs={2}
          isStaticField={true}
          name={selectName}
          style={{ width: 200 }}
        />
      </GenericGrid>
    </GenericGrid>
  );
};

export default FrequencyGroup;
