/* eslint-disable */
import Paper from '@mui/material/Paper';
import { UseProductSlicesCombinerType } from 'components/Product/Redux/CustomHooks/types';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';
import ProductReferenceFilesToolbar from './CustomComponents/Toolbar/ProductReferenceFilesToolbar';
import ProductReferenceFilesTable from './CustomComponents/Table/ProductReferenceFilesTable';
import ConfirmDeletionDialog from './CustomComponents/Dialog/ConfirmDeletionDialog/ConfirmDeletionDialog';
import UploadDialog from './CustomComponents/Dialog/UploadDialog/UploadDialog';

export type ProductReferenceFilesPropsType = {
  userCanEditReferenceFiles?: boolean;
  userCanReadReferenceFiles?: boolean;
};
const ProductReferenceFiles = ({
  userCanEditReferenceFiles,
  userCanReadReferenceFiles,
}: ProductReferenceFilesPropsType): JSX.Element => {
  const { currentProduct }: UseProductSlicesCombinerType =
    useProductSlicesCombiner();

  if (!userCanReadReferenceFiles) return <></>;
  const referenceFilesList = currentProduct?.referenceFiles;
  if (
    (!referenceFilesList ||
      (Array.isArray(referenceFilesList) && referenceFilesList.length < 1) ||
      !userCanReadReferenceFiles) &&
    !userCanEditReferenceFiles
  )
    return <></>;

  return (
    <GenericGrid isItem xsLength={12}>
      <Paper
        style={{ marginTop: 20 }}
        data-testid="product-references-files-card-id"
      >
        <div style={{ paddingBottom: 63, paddingLeft: 20, paddingRight: 20 }}>
          <ProductReferenceFilesToolbar
            userCanEditReferenceFiles={userCanEditReferenceFiles}
            userCanReadReferenceFiles={userCanReadReferenceFiles}
          />
          <ProductReferenceFilesTable
            userCanEditReferenceFiles={userCanEditReferenceFiles}
            userCanReadReferenceFiles={userCanReadReferenceFiles}
          />
          <ConfirmDeletionDialog />
          <UploadDialog />
        </div>
      </Paper>
    </GenericGrid>
  );
};

export default ProductReferenceFiles;
