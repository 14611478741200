import { AnyAction } from 'redux';

import { InfoTextsType, StandardsReducerStateType } from './types';

const getInitInfoTexts = () => {
  const initialInfoTexts: InfoTextsType[] = [
    {
      infoProp: 'info0',
      infoStandardText: '',
      infoStandardDesc: '',
      type: 'text',
    },
  ];
  return initialInfoTexts;
};

const INITIAL_STATE: StandardsReducerStateType = {
  standards: [],
  uniqueStandardsFromBalances: [],
  standard: null,
  loadingStandards: false,
  loadingUniqueStandardsFromBalances: false,
  loadingStandard: false,
  removeListItemDialogOpen: false,
  createListItemDialogOpen: false,
  listItemDialogOpen: false,
  listItemData: undefined,
  listItemlength: undefined,
  formIsFilledOut: false,
  standardFormError: null,
  currentStandard: {
    name: '',
    standardType: '',
    productType: null,
    infoTexts: getInitInfoTexts(),
  },
};

const standardsReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case 'GET_STANDARDS_START':
      return { ...state, loadingStandards: true };
    case 'GET_UNIQUE_STANDARDS_FROM_BALANCES_START':
      return { ...state, loadingUniqueStandardsFromBalances: true };

    case 'OPEN_LIST_ITEM_DIALOG':
      return {
        ...state,
        listItemDialogOpen: true,
        listItemData: action.payload,
      };

    case 'CLOSE_LIST_ITEM_DIALOG':
      return { ...state, listItemDialogOpen: false, listItemData: undefined };

    case 'OPEN_CREATE_LIST_ITEM_DIALOG':
      return {
        ...state,
        createListItemDialogOpen: true,
        listItemlength: action.payload,
      };

    case 'CLOSE_CREATE_LIST_ITEM_DIALOG':
      return {
        ...state,
        createListItemDialogOpen: false,
        listItemData: undefined,
      };

    case 'OPEN_REMOVE_LIST_ITEM_DIALOG':
      return {
        ...state,
        removeListItemDialogOpen: true,
        listItemData: action.payload,
      };

    case 'CLOSE_REMOVE_LIST_ITEM_DIALOG':
      return {
        ...state,
        removeListItemDialogOpen: false,
        listItemData: undefined,
      };

    case 'REMOVE_LIST_ITEM':
      return { ...state, removeListItemDialogOpen: false };

    case 'GET_STANDARDS_SUCCESS':
      return {
        ...state,
        standards: action.payload,
        loadingStandards: false,
      };
    case 'GET_UNIQUE_STANDARDS_FROM_BALANCES_SUCCESS':
      return {
        ...state,
        uniqueStandardsFromBalances: action.payload,
        loadingUniqueStandardsFromBalances: false,
      };

    case 'GET_STANDARDS_FAIL':
      return { ...state, loadingStandards: false };

    case 'GET_UNIQUE_STANDARDS_FROM_BALANCES_FAIL':
      return { ...state, loadingUniqueStandardsFromBalances: false };

    //Get standard
    case 'GET_STANDARD_START':
      return { ...state, loadingStandard: true };

    case 'GET_STANDARD_SUCCESS':
      return {
        ...state,
        standard: action.payload,
        currentStandard: action.payload,
        loadingStandard: false,
        formIsFilledOut: false,
      };

    case 'GET_STANDARD_FAIL':
      return { ...state, loadingStandard: false };

    //Update standard
    case 'SET_STANDARD_START':
      return { ...state, loadingStandard: true };

    case 'SET_STANDARD_SUCCESS':
      return {
        ...state,
        standards: action.payload,
        loadingStandards: false,
        formIsFilledOut: false,
      };

    case 'SET_STANDARD_FAIL':
      return { ...state, loadingStandard: false };

    //Delete standard
    case 'DELETE_STANDARD_START':
      return { ...state, loadingStandard: true };

    case 'DELETE_STANDARD_SUCCESS':
      return {
        ...state,
        standards: action.payload,
        loadingStandard: false,
        loadingStandards: false,
        formIsFilledOut: false,
      };

    case 'DELETE_STANDARD_FAIL':
      return { ...state, loadingStandard: false };

    //Create standard
    case 'CREATE_STANDARD_START':
      return { ...state, loadingStandard: true };

    case 'CREATE_STANDARD_SUCCESS':
      return {
        ...state,
        standards: action.payload,
        loadingStandard: false,
        loadingStandards: false,
        formIsFilledOut: false,
      };

    case 'CREATE_STANDARD_FAIL':
      return { ...state, loadingStandard: false };

    case 'RESET_STANDARD':
      return {
        ...state,
        currentStandard: {
          name: '',
          standardType: '',
          productType: null,
          infoTexts: getInitInfoTexts(),
        },
      };

    case 'STANDARD_FORM_IS_BEING_FILLED_OUT':
      return {
        ...state,
        formIsFilledOut: true,
      };
    default:
      return state;
  }
};

export default standardsReducer;
