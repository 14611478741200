import { Box, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { convertBytesInOctetsString } from 'utils/convert';

const EXPIRATION_LABELS = {
  EXPIRED: 'expired',
  EXPIRE_SOON: 'expire soon',
  EXPIRE_IN_ONE_MONTH: 'expire in less than one month',
  EXPIRE_IN_THREE_MONTHS: 'expire in less than 3 months',
};

const LabelCell = (props: any): JSX.Element => {
  const { row, userCanEditReferenceFiles = false } = props;

  const sizeText = row.size ? ` (${convertBytesInOctetsString(row.size)})` : '';
  let expirationStyle = {};
  let expirationLabel = '';
  if (row.validityEnd) {
    const validityEnd = moment(row.validityEnd).toDate();
    const today = new Date();
    if (validityEnd < today) {
      expirationStyle = style.expired;
      expirationLabel = EXPIRATION_LABELS.EXPIRED;
    } else if (validityEnd < moment(today).add(3, 'months').toDate()) {
      expirationStyle = style.expiringSoon;
      expirationLabel = EXPIRATION_LABELS.EXPIRE_SOON;
    }
  }
  if (userCanEditReferenceFiles) {
    return (
      <>
        <div>
          <Tooltip
            title="Double click to edit label"
            placement="bottom"
            enterDelay={100}
          >
            <Box style={style.tooltipCell}>
              <span>{row.label} </span>
              <Typography
                color="inherit"
                variant="caption"
                style={{ marginLeft: 10 }}
              >
                {sizeText}
              </Typography>
            </Box>
          </Tooltip>
        </div>
        {expirationLabel && (
          <Tooltip
            title="Upload a new reference document to replace this one before it expires"
            placement="bottom"
            enterDelay={100}
          >
            <Box style={style.tooltipCell}>
              <Typography
                color="inherit"
                variant="caption"
                style={expirationStyle}
              >
                {expirationLabel}
              </Typography>
            </Box>
          </Tooltip>
        )}
      </>
    );
  }

  return (
    <>
      <span>{row.label} </span>
      <Typography color="inherit" variant="caption" style={{ marginLeft: 10 }}>
        {sizeText}
      </Typography>
      {expirationLabel && (
        <Typography color="inherit" variant="caption" style={expirationStyle}>
          {expirationLabel}
        </Typography>
      )}
    </>
  );
};

const style = {
  tooltipCell: {
    minHeight: '100%',
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  expired: {
    marginLeft: 10,
    color: 'red',
  },
  expiringSoon: {
    marginLeft: 10,
    color: 'orange',
  },
};

export default LabelCell;
