import { Button, DialogActions } from '@mui/material';
import { closeActionDialog, updateBalancesCache } from 'actions';
import GenericModalTitle from 'components/Administration/Generic/Modal/Title/GenericModalTitle';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import LoadingCircle from 'components/commons/LoadingCircle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import decacheIcon from '../../../../../../../assets/images/icons/cloud-refresh.png';

const UpdateBalancesCacheForm = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { loadingUpdateBalancesCache } = useAdministrationSlicesCombiner();
  return (
    <>
      <GenericModalTitle
        id="responsive-dialog-title"
        title={t('Update balances cache')}
        icon={decacheIcon}
      />

      <DialogActions>
        <Button onClick={() => dispatch(closeActionDialog())} color="primary">
          Close
        </Button>
        <Button
          onClick={() => dispatch(updateBalancesCache())}
          variant="contained"
          color="primary"
        >
          Update cache
        </Button>
      </DialogActions>

      {loadingUpdateBalancesCache && (
        <div>
          <LoadingCircle style={{ marginLeft: 20 }} />
        </div>
      )}
    </>
  );
};

export default UpdateBalancesCacheForm;
