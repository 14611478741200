import { DEFAULT_PAGE_SIZE } from 'components/Transactions/utils/constants';
import LinkCellContent from '../LinkCellContent/LinkCellContent';

type LoadAllType = {
  onClick: () => void;
  totalRows: number;
  loadedRows: number;
  typeOfData: string;
  source?: string;
};

const LoadAll = ({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick = () => {},
  totalRows = 0,
  loadedRows = 0,
  typeOfData = 'rows',
}: LoadAllType) => {
  const displayLoadAllLink =
    totalRows > DEFAULT_PAGE_SIZE && totalRows > loadedRows;
  const dataIsOfTypeAggregations = typeOfData.includes('aggregations');
  return (
    <div style={styles.loadAllContainer}>
      {displayLoadAllLink ? (
        <>
          <div style={styles.loadAllLink}>
            <div style={styles.loadAllText}>
              Only your 100 last {typeOfData} are loaded
            </div>
            <LinkCellContent value={'Load all'} onClick={onClick} />
          </div>
          {!dataIsOfTypeAggregations && (
            <div style={styles.totalRows}>
              Loaded {typeOfData}:{' '}
              {`${loadedRows.toString()} / ${totalRows.toString()}`}
            </div>
          )}
        </>
      ) : (
        <div style={styles.totalRows}>
          {dataIsOfTypeAggregations
            ? `All ${typeOfData} loaded`
            : `All ${typeOfData} loaded (${totalRows.toString()})`}
        </div>
      )}
    </div>
  );
};

const styles = {
  loadAllContainer: {
    flex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    borderTopWidth: 1,
    borderTopColor: 'rgba(224, 224, 224, 1)',
    flexDirection: 'row' as const,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  loadAllText: { marginRight: 10, paddingBottom: 0 },
  loadAllLink: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
  },
  totalRows: { marginRight: 20 },
};

export default LoadAll;
