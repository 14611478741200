import React from 'react';

import { Box } from '@mui/material';
import DynamicDataFields from 'components/FormStandards/CustomComponents/Fields/DynamicData/DynamicDataFields';
import { FormikValues } from 'formik';

import DefaultStandardAttributeForm from '../DefaultAttributeForm';

type DynamicDataGridItemProps = {
  index: number;
  isEven: boolean;
  onRemove: (
    values: FormikValues,
    setFieldValue: any,
    indexToRemove: number
  ) => void;
};

const DynamicDataGridItem: React.FC<DynamicDataGridItemProps> = ({
  index,
  isEven,
  onRemove,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      style={{ backgroundColor: isEven ? '#F5F8FA' : '#FFFFFF' }}
    >
      <DefaultStandardAttributeForm
        index={index}
        key={index}
        onRemove={onRemove}
      />
      <DynamicDataFields index={index} />
    </Box>
  );
};

export default DynamicDataGridItem;
