import { useEffect } from 'react';

import { getDynamicListsStart } from 'actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';

import useDynamicListsSlicesCombiner from '../Slices/useDynamicListsSlicesCombiner';

const useDynamicLists = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { isAuthenticated } = useDynamicListsSlicesCombiner();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getDynamicListsStart());
    } else {
      navigate('/');
    }
  }, [isAuthenticated, navigate, dispatch]);

  return {};
};

export default useDynamicLists;
