import React from 'react';

import { LinearProgress } from '@mui/material';
import { DataGridPro as XGrid } from '@mui/x-data-grid-pro';
import LoadAll from 'components/commons/LoadAll/LoadAll';
import useProductsSlicesCombiner from 'components/Products/Redux/Slices/useProductsSlicesCombiner';
import {
  FILTER_MODE_CLIENT_SIDE,
  PAGINATION_MODE_SERVER_SIDE,
} from 'components/Transactions/utils/constants';
import { useNavigate } from 'react-router';

import useArchivedProducts from '../../Redux/CustomHooks/useArchivedProducts';
import Columns from '../../utils/tableUtils/columns';

const ArchivedProductsTable = () => {
  const { loading, archivedProducts, totalCount } = useProductsSlicesCombiner();
  const navigate = useNavigate();
  const { handlePageChange, handlePageSizeChange, page, pageSize } =
    useArchivedProducts();
  const dataGridRows = archivedProducts;
  const dataGridColumns = Columns();
  return (
    <XGrid
      disableSelectionOnClick
      loading={loading}
      onRowClick={(rowData: any) => {
        const _id = rowData.row._id;
        navigate('/products/read/' + _id);
      }}
      pagination
      paginationMode={PAGINATION_MODE_SERVER_SIDE}
      filterMode={FILTER_MODE_CLIENT_SIDE}
      rows={dataGridRows as any[]}
      columns={dataGridColumns}
      components={{
        LoadingOverlay: LinearProgress,
        Footer: () => (
          <LoadAll
            onClick={() => {
              handlePageSizeChange(totalCount);
            }}
            loadedRows={(archivedProducts as any[])?.length}
            totalRows={totalCount as number}
            typeOfData="transactions"
          />
        ),
      }}
      page={page}
      pageSize={pageSize}
      rowCount={totalCount}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
    />
  );
};

export default ArchivedProductsTable;
