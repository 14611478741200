import scheduleTasksIcon from '../../../assets/images/icons/schedule-tasks.png';
import logsIcon from '../../../assets/images/icons/transactions-list.png';

export const getMonitoringActions = (userIsSuperAdmin: boolean) => {
  const monitoringActionsArray = [
    {
      name: 'get_scheduled_tasks_list',
      title: `Get scheduled tasks `,
      subtitle: `Monitoring.`,
      icon: scheduleTasksIcon,
      hide: !userIsSuperAdmin,
    },
    {
      name: 'get_user_logs',
      title: `Get user logs `,
      subtitle: `User logs.`,
      icon: logsIcon,
    },
  ];
  return monitoringActionsArray.filter((action) => !action.hide);
};
