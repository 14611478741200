import { CardHeader } from '@mui/material';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { useTranslation } from 'react-i18next';

import barchartIcon from '../../../../../../assets/images/icons/barchart.png';
import { processChartTitle } from '../utils/utils';

const ChartTitle = () => {
  const { t } = useTranslation();
  const { currentProduct, ozOrGramsQuantityUnit } = useProductSlicesCombiner();

  const unit = processChartTitle(currentProduct, ozOrGramsQuantityUnit);

  return (
    <CardHeader
      avatar={<img src={barchartIcon} style={{ maxHeight: 20 }} alt="" />}
      title={t('Production (in ') + unit + ')'}
    />
  );
};

export default ChartTitle;
