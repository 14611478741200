import { Grid } from '@mui/material';
import GenericFieldLabel from 'components/GenericComponents/Grid/Field/FieldLabel/GenericFieldLabel';
import GenericFieldValue from 'components/GenericComponents/Grid/Field/FieldValue/GenericFieldValue';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { prepareDynamicDataWithFilterOption } from 'components/Product/utils/utils';
import { DefaultProductType } from 'reducers/productsReducer/types';

export type SummaryDynamicContentPropsType = {
  removeHiddenItems?: boolean;
};

const SummaryDynamicContent = (
  props: SummaryDynamicContentPropsType
): JSX.Element => {
  const { currentProduct } = useProductSlicesCombiner();
  const { infoXArray } = prepareDynamicDataWithFilterOption(
    currentProduct as DefaultProductType
  );
  if (!currentProduct) return <></>;
  return (
    <>
      {infoXArray.map((infoX, infoXIndex) => {
        const { label, key, desc, type } = infoX;
        const infoTextItem = currentProduct?.standardValues?.find(
          (sv: any) => sv.key === key
        );
        if (infoTextItem?.isHidden && props?.removeHiddenItems) return null;

        let val = infoTextItem?.value;
        let tooltipLabel = label !== desc ? desc : '';

        if (type === 'dynamicData') {
          val = desc;
          tooltipLabel = '';
        }
        let valAsArray = val;
        if (typeof valAsArray === 'number') valAsArray = [val];
        else if (!Array.isArray(valAsArray)) valAsArray = val?.split('|');
        if (!valAsArray) return null;
        return (
          <Grid item xs={12} md={6} lg={3} key={`infoX${infoXIndex}`}>
            <GenericFieldLabel label={label} tooltipLabel={tooltipLabel} />
            {valAsArray.map((item: any, index: number) => (
              <GenericFieldValue field={item} index={index} key={index} />
            ))}
          </Grid>
        );
      })}
    </>
  );
};

export default SummaryDynamicContent;
