import React from 'react';

export type OrganizationContextType = {
  organizationsInAllocateMenu: any[];
  organizationsInTransferMenu: any[];
  activateOzConversion: boolean;
};

const organizationsContext: OrganizationContextType = {
  organizationsInAllocateMenu: [],
  organizationsInTransferMenu: [],
  activateOzConversion: false,
};

const OrganizationsContext = React.createContext(organizationsContext);

export default OrganizationsContext;
