import React from 'react';

import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Notifier extends React.Component {
  constructor(props) {
    super(props);

    /**
     * local state used to store the key of the displayed notifications
     * to avoid displaying them multiple times
     */
    this.state = {
      displayed: [],
    };
  }

  render = () => {
    const { notifications } = this.props;

    for (let notification of notifications) {
      setTimeout(() => {
        // If notification already displayed, abort
        if (
          this.state.displayed.filter((key) => key === notification.options.key)
            .length > 0
        ) {
          return;
        }

        // Display notification using Snackbar
        this.props.enqueueSnackbar(notification.message, {
          variant: notification?.options?.variant
            ? notification.options.variant
            : '',
          action: notification?.options?.action
            ? notification.options.action
            : null,
          persist: notification?.options?.persist
            ? notification.options.persist
            : false,
        });
        // Add notification's key to the local state
        this.setState({
          displayed: [...this.state.displayed, notification.options.key],
        });
        // Dispatch action to remove the notification from the redux store
        this.props.closeSnackbar(notification.options.key);
      }, 60);
    }

    return null;
  };
}

const mapStateToProps = ({ utilsReducer }) => {
  const { notifications } = utilsReducer;
  return { notifications };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Notifier));
