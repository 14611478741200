import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { displayCheckProductAttributesModal } from 'actions';
import SummaryCheckAttributesDialog from 'components/Product/CustomComponents/Card/SummaryCard/SummaryTitle/SummaryCheckAttributesDialog';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import historyIcon from '../../../../../../assets/images/icons/time-white.png';

const SummaryCheckAttributesButton = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const { currentProduct } = useProductSlicesCombiner();

  if (!currentProduct) return <></>;

  return (
    <Box
      style={{
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
        marginTop: 20,
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Show product history">
        <Button
          size="small"
          variant="contained"
          aria-label="history"
          color="primary"
          style={{ minWidth: 50 }}
          onClick={() => {
            dispatch(displayCheckProductAttributesModal());
          }}
        >
          <img
            src={historyIcon}
            alt="Show product history"
            style={{
              width: 20,
              height: 20,
            }}
          />
        </Button>
      </Tooltip>

      <SummaryCheckAttributesDialog />
    </Box>
  );
};

export default SummaryCheckAttributesButton;
