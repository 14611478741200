export const Periods: any = {
  second: {
    value: 'second',
    label: 'Second',
  },
  minute: {
    value: 'min',
    label: 'Minute',
  },
  hour: {
    value: 'hour',
    label: 'Hour',
  },
  day: {
    value: 'day',
    label: 'Day',
  },
  week: {
    value: 'week',
    label: 'Week',
  },
  month: {
    value: 'month',
    label: 'Month',
  },
  quarter: {
    value: 'quarter',
    label: 'Quarter',
  },
  semester: {
    value: 'semester',
    label: 'Semester',
  },
  year: {
    value: 'year',
    label: 'Year',
  },
};
