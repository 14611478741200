import {
  displayIssueCertificatesBulkFormInDialog,
  issueCertificatesInBulkStart,
} from 'actions';
import IssuanceList from 'components/Administration/CustomComponents/IssueCertificateInBulkForm/IssuanceList';
import GenericTypographyV2 from 'components/Administration/Generic/Form/Labels/GenericTypographyV2';
import { formatMonthYear } from 'components/Administration/Generic/Form/utils/utils';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';

import { FormFieldsConfigType } from '../../../../../utils/types/formFieldsTypes';
import { FooterModalPropsType } from '../../../../../utils/types/types';

const useIssueCertificatesInBulkConfirmationModalConfig = () => {
  const {
    numSelected,
    bulkIssuanceList,
    issueCertificatesBulkData,
    loadingIssueCertificatesBulk,
  } = useAdministrationSlicesCombiner();

  //modal components
  const modalHeader = {
    title: '',
  };
  const modalFooter: FooterModalPropsType = {
    cancelLabel: 'Back',
    submitLabel: `${numSelected > 1 ? 'Issue for many' : 'Issue for one'}`,
    onCancelHandler: displayIssueCertificatesBulkFormInDialog,
    onSubmitHandler: issueCertificatesInBulkStart(
      bulkIssuanceList,
      issueCertificatesBulkData._year,
      issueCertificatesBulkData._month
    ),
    displaySubmitBtnElementCondition: numSelected > 0,
    loading: loadingIssueCertificatesBulk,
  };
  const formFieldsConfig: FormFieldsConfigType = [
    [
      {
        component: GenericTypographyV2,
        name: 'Organization',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${issueCertificatesBulkData._organization.name}`,
      },
      {
        component: GenericTypographyV2,
        name: 'Period',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${formatMonthYear(
          issueCertificatesBulkData._year +
            '-' +
            issueCertificatesBulkData._month
        )}`,
      },
      { component: IssuanceList, issuanceList: bulkIssuanceList },
    ],
  ];
  return {
    header: modalHeader,
    content: formFieldsConfig,
    footer: modalFooter,
  };
};

export default useIssueCertificatesInBulkConfirmationModalConfig;
