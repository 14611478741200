import { Dialog } from '@mui/material';
import { hideProductDialog } from 'actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import useBalancesSlices from '../../../Redux/Slices/useBalancesSlices';

import ProductActions from './Actions/ProductActions';
import ProductContent from './Content/ProductContent';
import ProductTitle from './ProductTitle';

const ProductDialog = () => {
  const { openProductDialog } = useBalancesSlices();
  const dispatch: AppDispatch = useDispatch();
  const handleClose = () => {
    dispatch(hideProductDialog());
  };
  const modalProps: any = {
    maxWidth: 'xl',
    open: openProductDialog,
    'data-testid': 'productDialog',
    'aria-labelledby': 'responsive-dialog-title',
    onClose: handleClose,
  };

  return (
    <Dialog {...modalProps}>
      <ProductTitle handleClose={handleClose} />
      <ProductContent />
      <ProductActions handleClose={handleClose} />
    </Dialog>
  );
};

export default ProductDialog;
