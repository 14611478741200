import { useEffect } from 'react';

import {
  flexSellerOrganizationChanged,
  flexBuyerOrganizationChanged,
  getAdminNominationsRequestsStart,
  getIndependantWithNominationsFeature,
  getIssuerNominationsRequestsStartForSellerAndBuyer,
  getIssuerOrganizations,
  getNominationsOfIssuerOrganizationByYear,
} from 'actions';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';
import { checkObjectIsNonEmptyArray } from 'utils/general/utils';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';

import useNominationsSlicesCombiner from '../Slices/useNominationsSlicesCombiner';

const useNominations = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isAuthenticated,
    user,
    year,
    sellerOrganizations,
    buyerOrganization,
    selectedSellerOrganization,
    independantsWithNominationsFeature,
  } = useNominationsSlicesCombiner();

  useEffect(() => {
    const handleSuperAdmin = async () => {
      const isSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, user);

      if (isSuperAdmin) {
        const myIndependantsWithNominationsFeature = (await dispatch(
          getAdminNominationsRequestsStart(undefined, year)
        )) as unknown as any[];
        if (checkObjectIsNonEmptyArray(myIndependantsWithNominationsFeature)) {
          await dispatch(
            getIssuerOrganizations(
              selectedSellerOrganization ||
                myIndependantsWithNominationsFeature[0]
            )
          );
        }
      }
    };
    handleSuperAdmin();
  }, []);

  useEffect(() => {
    const prepareData = async () => {
      if (!isAuthenticated || !user) {
        navigate('/');
        return;
      }
      const isSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, user);

      if (!isSuperAdmin) {
        const userOrganization = user.user_metadata.organization;
        await dispatch(getIndependantWithNominationsFeature(userOrganization));
        await dispatch(getIssuerOrganizations(userOrganization, true));
      }
    };
    prepareData();
  }, []);

  useEffect(() => {
    const initComponent = async () => {
      if (!isAuthenticated || !user) {
        navigate('/');
        return;
      }
      const isSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, user);
      if (!isSuperAdmin) {
        const hasSetBusinessOrganizationNominationFeature = checkUserHasRight(
          isAuthenticated,
          user,
          RightReferences.NOMINATIONS,
          [CrudPermissions.UPDATE]
        );

        if (hasSetBusinessOrganizationNominationFeature) {
          if (
            independantsWithNominationsFeature.length > 0 &&
            sellerOrganizations.length > 0
          ) {
            dispatch(
              flexBuyerOrganizationChanged(
                buyerOrganization || independantsWithNominationsFeature[0]
              )
            );
            if (sellerOrganizations.length === 1) {
              dispatch(
                flexSellerOrganizationChanged(
                  selectedSellerOrganization || sellerOrganizations[0]
                )
              );
            }
            await dispatch(
              getIssuerNominationsRequestsStartForSellerAndBuyer(
                (buyerOrganization || independantsWithNominationsFeature[0])
                  ._id,
                (selectedSellerOrganization || sellerOrganizations[0])._id,
                year
              )
            );
          }
        } else if (
          checkUserHasRight(
            isAuthenticated,
            user,
            RightReferences.NOMINATIONS,
            [CrudPermissions.VIEW]
          )
        ) {
          if (checkObjectIsNonEmptyArray(sellerOrganizations)) {
            const userOrganization = user.user_metadata.organization;
            await dispatch(
              flexSellerOrganizationChanged(sellerOrganizations[0])
            );
            await dispatch(
              getNominationsOfIssuerOrganizationByYear(
                userOrganization._id,
                sellerOrganizations[0]?._id,
                year
              )
            );
          }
        }
      }
    };

    initComponent();
  }, [independantsWithNominationsFeature, sellerOrganizations]);
};

export default useNominations;
