import { useEffect, useRef } from 'react';

import GenericModalTitle from 'components/Administration/Generic/Modal/Title/GenericModalTitle';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import decreaseIcon from '../../../../../../../assets/images/icons/increase.png';

import useDecreaseBalanceHandlers from './CustomHooks/useDecreaseBalanceHandlers';
import ModalContentDecreaseBalanceConfirmation from './Form/Content/ModalContentDecreaseBalanceConfirmation';
import ModalContentDecreaseBalanceForm from './Form/Content/ModalContentDecreaseBalanceForm';
import ModalFooterDecreaseBalanceConfirmation from './Form/Footer/ModalFooterDecreaseBalanceConfirmation';
import ModalFooterDecreaseBalanceForm from './Form/Footer/ModalFooterDecreaseBalanceForm';
import { getValidationForm } from './Form/FormValidation/validationForm';

const DecreaseBalanceDialog = () => {
  const { t } = useTranslation();
  const {
    decreaseBalanceData,
    displayDecreaseBalanceFormInDialog,
    resetDecreaseBalanceForm,
  } = useAdministrationSlicesCombiner();
  const { onSubmitHandler } = useDecreaseBalanceHandlers();
  const formikRef = useRef<FormikProps<any>>(null);

  const initialValues = {
    origin: decreaseBalanceData?._origin?._id,
    organization: decreaseBalanceData?._for?._id,
    volume: decreaseBalanceData?._volume,
    year: decreaseBalanceData?._year,
    month: decreaseBalanceData?._month,
    txHash: decreaseBalanceData?._txHash,
    comment: decreaseBalanceData?._comment,
  };

  useEffect(() => {
    if (resetDecreaseBalanceForm) {
      formikRef.current?.resetForm({
        values: {
          origin: '',
          organization: '',
          volume: '',
          year: '',
          month: '',
          txHash: '',
          comment: '',
        },
      });
    }
  }, [resetDecreaseBalanceForm, formikRef]);

  return (
    <>
      <GenericModalTitle
        id="responsive-dialog-title"
        title={t('Decrease balance')}
        icon={decreaseIcon}
      />

      {displayDecreaseBalanceFormInDialog && (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
          validate={(values) => getValidationForm(values)}
        >
          {() => {
            return (
              <>
                <ModalContentDecreaseBalanceForm />
                <ModalFooterDecreaseBalanceForm />
              </>
            );
          }}
        </Formik>
      )}
      {!displayDecreaseBalanceFormInDialog && (
        <>
          <ModalContentDecreaseBalanceConfirmation />
          <ModalFooterDecreaseBalanceConfirmation />
        </>
      )}
    </>
  );
};

export default DecreaseBalanceDialog;
