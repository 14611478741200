import { MenuItem } from '@mui/material';
import { displayPlatinumUI } from 'components/CreateOrUpdateProduct/utils/utils';
import moment from 'moment';
import { removeDuplicatesFromArrayByProperty } from 'utils/arrayHelper';

export const getFullOrigin = (_originId: string, products: any[]) => {
  const fullOrigin = (products as any[])?.find(
    (product: any) => product._id === _originId
  );
  return fullOrigin;
};

export const getYearsMenu = () => {
  const yearsValues = [];
  for (let index = 0; index < 5; index++) {
    const yearValue = parseInt(moment().subtract(index, 'year').format('YYYY'));
    yearsValues.push(yearValue);
  }
  return yearsValues.map((year) => (
    <MenuItem key={year} value={year}>
      {year}
    </MenuItem>
  ));
};

export const formatMonthYear = (value: string) => {
  let valueToRender = moment(value, 'YYYY-MM').format('MMM-YYYY');
  if (displayPlatinumUI) {
    const year = moment(value, 'YYYY-MM').format('YY');
    const month = parseInt(moment(value, 'YYYY-MM').format('M'));
    let halfYear = 1;
    if (month > 6) halfYear = 2;
    valueToRender = `${halfYear}H${year}`;
  }

  return valueToRender;
};
export const getMonthsMenu = () => {
  const monthsLabels = [];
  const monthsValues: any[] = [];
  for (let index = 0; index < 12; index++) {
    const monthLabel = moment().month(index).format('MMMM');
    const monthValue = moment().month(index).format('MM');
    monthsLabels.push(monthLabel);
    monthsValues.push(monthValue);
  }

  return monthsLabels.map((month, idx) => (
    <MenuItem key={idx} value={monthsValues[idx]}>
      {month}
    </MenuItem>
  ));
};

export const getMonthsMenuWithoutJSXRender = () => {
  const finalResult: any[] = [];
  for (let index = 0; index < 12; index++) {
    const monthLabel = moment().month(index).format('MMMM');
    const monthValue = moment().month(index).format('MM');
    finalResult.push({ key: index, label: monthLabel, value: monthValue });
  }
  return finalResult;
};
export const addThousandsSeparatorToNumber = (num: number | string) => {
  const entry = num ? num : 0;
  return entry.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const removeNonNumeric = (num: number) => {
  const entry = num ? num : 0;
  return entry.toString().replace(/[^0-9]/g, '');
};

export const getOrganizationsInIncreaseDecreaseMenu = (
  _originId: any,
  products: any[],
  organizations: any[]
) => {
  if (!_originId) return [];
  const selectedOrigin = getFullOrigin(_originId, products as any[]);
  const originOrganization = organizations.find(
    (org: any) => org._id === selectedOrigin?.organization?._id
  );

  //We add the business organizations to the list
  let organizationsInSelectMenu = originOrganization?.businessOrganizations
    ? originOrganization?.businessOrganizations
    : [];
  organizationsInSelectMenu.push(originOrganization);

  //Clean the array : remove the duplicates
  organizationsInSelectMenu = removeDuplicatesFromArrayByProperty(
    organizationsInSelectMenu,
    '_id'
  );

  return organizationsInSelectMenu;
};
export const quantityTypeUppercase = displayPlatinumUI ? 'Quantity' : 'Volume';

export const quantity = displayPlatinumUI ? 'quantity' : 'volume';
