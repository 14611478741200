import { useEffect, useRef } from 'react';

import GenericModalTitle from 'components/Administration/Generic/Modal/Title/GenericModalTitle';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import issueIcon from '../../../../../../../../assets/images/icons/issue-certificate-green.png';

import useSeeProducts from './CustomHooks/useSeeProducts';
import ModalContentIssueCertificatesSecondInBulkForm from './Form/Content/ModalContentIssueCertificatesConfirmationInBulkForm';
import ModalContentIssueCertificatesFirstInBulkForm from './Form/Content/ModalContentIssueCertificatesInBulkForm';
import ModalFooterIssueCertificatesSecondInBulkForm from './Form/Footer/ModalFooterIssueCertificatesConfirmationBulkForm';
import ModalFooterIssueCertificatesInBulkForm from './Form/Footer/ModalFooterIssueCertificatesInBulkForm';
import { getValidationSchema } from './Form/FormSchemaValidation/validationForm';

const IssueCertificatesInBulkForm = () => {
  const { t } = useTranslation();
  const { onSubmitHandler } = useSeeProducts();
  const {
    issueCertificatesBulkData,
    displayIssueCertificateBulkFormInDialog,
    resetIssueCertificateInBulkForm,
    user,
  } = useAdministrationSlicesCombiner();
  const formikRef = useRef<FormikProps<any>>(null);

  const initialValues = {
    organization:
      issueCertificatesBulkData?._organization?._id ||
      user?.user_metadata?.organization?._id ||
      '',
    year: issueCertificatesBulkData._year,
    month: issueCertificatesBulkData._month,
  };
  useEffect(() => {
    if (resetIssueCertificateInBulkForm) {
      formikRef.current?.resetForm({
        values: {
          origin: '',
          volume: '',
          year: '',
          month: '',
        },
      });
    }
  }, [resetIssueCertificateInBulkForm, formikRef]);

  return (
    <>
      <GenericModalTitle
        id="responsive-dialog-title"
        title={t('Issue balance for multiple products')}
        icon={issueIcon}
      />
      {displayIssueCertificateBulkFormInDialog && (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
          validate={(values) => getValidationSchema(values)}
        >
          {() => {
            return (
              <>
                <ModalContentIssueCertificatesFirstInBulkForm />
                <ModalFooterIssueCertificatesInBulkForm />
              </>
            );
          }}
        </Formik>
      )}
      {!displayIssueCertificateBulkFormInDialog && (
        <>
          <ModalContentIssueCertificatesSecondInBulkForm />
          <ModalFooterIssueCertificatesSecondInBulkForm />
        </>
      )}
    </>
  );
};

export default IssueCertificatesInBulkForm;
