import { Player } from '@lottiefiles/react-lottie-player';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import * as maintenanceWeb from '../../assets/images/lottie/maintenance-web.json';

const ConnectionLost = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection={'column'}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ marginRight: 110 }}
      >
        <Player
          autoplay
          loop
          src={maintenanceWeb}
          style={{ width: '30%', minWidth: 300 }}
        ></Player>
      </Box>
      <Typography variant="h6" component="div" gutterBottom>
        Sorry, we are currently updating Attributes, we&#39;ll be back soon !
      </Typography>
    </Box>
  );
};

export default ConnectionLost;
