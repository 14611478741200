import {
  downloadCertificatePdf,
  getSecretWordForCertificate,
  openCancelCertificateFormDialog,
  toggleSecretWordDialog,
} from 'actions';
import { AppDispatch } from 'redux/store';

export const handleGenerateCertificatePdf =
  (certificate: any) => async (dispatch: AppDispatch) => {
    await dispatch(downloadCertificatePdf(certificate));
  };

export const handleOpenSecretWordDialog =
  (certificate: any) => async (dispatch: AppDispatch) => {
    dispatch(toggleSecretWordDialog());
    await dispatch(getSecretWordForCertificate(certificate));
  };

export const handleOpenCancelCertificateFormDialog =
  (certificate: any) => (dispatch: AppDispatch) => {
    dispatch(openCancelCertificateFormDialog(certificate.id));
  };
