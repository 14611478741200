/* eslint-disable react/no-unknown-property */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Typography from '@mui/material/Typography';

import closeIcon from '../../../assets/images/icons/close.png';

type ModalTitlePropsType = {
  id?: string;
  icon?: string;
  title: string;
  handleClose: any;
};

const ModalTitle = (props: ModalTitlePropsType) => {
  return (
    <div css={containerStyle}>
      <div css={iconAndTitleContainerStyle}>
        {props.icon && (
          <img
            src={props.icon}
            css={modalTitleImgStyle}
            alt=""
            data-testid="custom-modal-title-icon"
          />
        )}
        <Typography
          variant="body2"
          display="block"
          style={{ fontSize: 20, marginLeft: props.icon ? 0 : 20 }}
        >
          {props.title}
        </Typography>
      </div>
      <img
        data-testid="close-modal-from-title-dialog"
        src={closeIcon}
        css={closeIconStyle}
        alt="Close"
        onClick={props.handleClose}
      />
    </div>
  );
};

const containerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: 30,
  paddingBottom: 10,
  paddingLeft: 10,
  paddingRight: 10,
});

const iconAndTitleContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'flex-start',
  alignItems: 'center',
  justifyContent: 'center',
});

const modalTitleImgStyle = css({
  height: 22,
  width: 22,
  marginRight: 5,
  paddingLeft: 10,
  paddingRight: 10,
});

const closeIconStyle = css({
  height: 24,
  width: 24,
  marginRight: 10,
  cursor: 'pointer',
});

export default ModalTitle;
