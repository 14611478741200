import { withRouter } from '../../utils/migrationHelper';

import CreateButton from './CustomComponents/Buttons/CreateButton';
import DynamicListsCard from './CustomComponents/Card/DynamicListsCard';

const Products = () => {
  return (
    <>
      <CreateButton />
      <DynamicListsCard />
    </>
  );
};

export default withRouter(Products);
