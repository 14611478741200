/* eslint-disable */

import React, { useEffect } from 'react';
import { withCookies } from 'react-cookie';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import { TextField, fieldToTextField } from 'formik-mui';
import { sanitize } from '../../utils/text';

import Card from '@mui/material/Card';
import { CardHeader, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { withRouter } from '../../utils/migrationHelper';
import userIcon from '../../assets/images/icons/person.png';
import Typography from '@mui/material/Typography';
import MuiTextField from '@mui/material/TextField';
import { withTranslation } from 'react-i18next';
import BackButton from '../commons/BackButton/BackButton';
import RightsMultipleChoice from './RightsMultipleChoice';

import {
  createUserStart,
  deleteUserStart,
  uploadUserPicture,
  userPictureChanged,
  updateUserStart,
  getUserFormStart,
  resetCurrentUser,
  getOrganizations,
  formIsBeingFilledOut,
  authorizedExtensions,
  authorizedExtensionMessage,
  getRights,
} from '../../actions';
import { checkUserIsSuperAdmin } from '../../utils/user/checkAdmin/CheckAdmin';

/**
 * Common component import
 */
import LoadingCircle from '../commons/LoadingCircle';
import { checkUserHasRight } from 'utils/user/checkRights';
import { RightReferences } from 'enums/RightReferences';
import { CrudPermissions } from 'enums/CrudPermissions';
import { useFormUserLogo } from 'components/Drawer/utils/logo';

function SetIdentifierTextField(props) {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;

  const { updatingUser, formIsBeingFilledOutHandler, ...fieldProps } = props;

  const onChange = React.useCallback(
    (event) => {
      if (!updatingUser)
        setFieldValue(
          'identifier',
          event.target.value ? sanitize(event.target.value) : ''
        );
      setFieldValue(name, event.target.value);
      formIsBeingFilledOutHandler();
    },
    [setFieldValue, name]
  );

  return <MuiTextField {...fieldToTextField(fieldProps)} onChange={onChange} />;
}

let FormUsers = (props) => {
  let navigate = useNavigate();
  let classes = useTheme();
  let params = props.router?.params;
  const {
    currentUser,
    selectedRights,
    loading,
    loadingForm,
    organizations,
    localUserPicture,
    user,
    isAuthenticated,
    t,
    userFormError,
    formIsBeingFilledOut,
    cookies,
  } = props;

  const { userFormLogo: userSelectedPicture } = useFormUserLogo(
    isAuthenticated,
    currentUser
  );

  useEffect(() => {
    async function initComponent() {
      const {
        isAuthenticated,
        user,
        getOrganizations,
        getUserFormStart,
        resetCurrentUser,
        getRights,
      } = props;
      let userId = user.user_id.split('|')[1];
      if (
        checkUserIsSuperAdmin(isAuthenticated, user) ||
        checkUserHasRight(isAuthenticated, user, RightReferences.USERS, [
          CrudPermissions.UPDATE,
        ]) ||
        userId === params.userId
      ) {
        await getOrganizations();

        if (params.userId) await getUserFormStart(params.userId);
        else resetCurrentUser();
        await getRights();
      } else navigate('/');
    }
    initComponent();
  }, []);

  useEffect(() => {
    const { formIsFilledOut } = props;
    if (formIsFilledOut) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [props.formIsFilledOut]);

  const onDrop = async (file) => {
    props.uploadUserPicture(file, props.currentUser._id);
  };

  const handlePictureChange = (event) => {
    props.userPictureChanged(event.target.files[0]);
  };

  let csrfToken = cookies.cookies._csrf;

  let updatingUser = false;

  if (params?.userId) {
    updatingUser = true;
  }

  if (
    loading ||
    !currentUser ||
    loadingForm ||
    !organizations ||
    organizations.length < 1
  ) {
    return (
      <div>
        <LoadingCircle />
      </div>
    );
  }

  if (updatingUser && !currentUser.app_metadata) {
    <LoadingCircle />;
  }

  let userId = user.user_id.split('|')[1];

  let hasSetUsersFeature = checkUserHasRight(
    isAuthenticated,
    user,
    RightReferences.USERS,
    [CrudPermissions.UPDATE]
  );

  if (
    !checkUserIsSuperAdmin(isAuthenticated, user) &&
    !hasSetUsersFeature &&
    params.userId &&
    userId !== params.userId
  ) {
    return (
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Unauthorized" />
          </Card>
        </Grid>
      </Grid>
    );
  }

  let organizationsInForm = organizations;
  if (hasSetUsersFeature) {
    //The connected user can't update a user that is not in its organization
    organizationsInForm = organizationsInForm.filter((o) => {
      return o._id === user.user_metadata.organization._id;
    });
  }

  return (
    <>
      <BackButton />
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={<img src={userIcon} style={{ maxHeight: 20 }} />}
              title="User information"
            />
            <CardContent>
              <div>
                <Formik
                  initialValues={{
                    firstname:
                      currentUser.user_metadata &&
                      currentUser.user_metadata.firstname &&
                      updatingUser
                        ? currentUser.user_metadata.firstname
                        : '',
                    lastname:
                      currentUser.user_metadata &&
                      currentUser.user_metadata.lastname &&
                      updatingUser
                        ? currentUser.user_metadata.lastname
                        : '',
                    username:
                      currentUser.user_metadata &&
                      currentUser.user_metadata.username &&
                      updatingUser
                        ? currentUser.user_metadata.username
                        : '',
                    email:
                      currentUser.email && currentUser.email && updatingUser
                        ? currentUser.email
                        : '',
                    phoneNumber:
                      currentUser.email &&
                      currentUser.user_metadata.phoneNumber &&
                      updatingUser
                        ? currentUser.user_metadata.phoneNumber
                        : '',
                    organization:
                      currentUser.user_metadata &&
                      currentUser.user_metadata.organization &&
                      currentUser.user_metadata.organization._id &&
                      updatingUser
                        ? currentUser.user_metadata.organization._id
                        : organizationsInForm[0]._id,
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.firstname) {
                      errors.firstname = 'Required';
                    } else if (values.firstname.length < 3) {
                      errors.firstname =
                        'First name must be at least 3 characters';
                    }

                    if (!values.lastname) {
                      errors.lastname = 'Required';
                    } else if (values.lastname.length < 3) {
                      errors.lastname =
                        'Last name must be at least 3 characters';
                    }

                    if (!values.username) {
                      errors.username = 'Required';
                    } else if (values.username.length < 3) {
                      errors.username =
                        'Username  must be at least 3 characters';
                    }

                    if (!values.email) {
                      errors.email = 'Required';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = 'Invalid email address';
                    }

                    let phoneno = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
                    if (
                      values.phoneNumber &&
                      !phoneno.test(String(values.phoneNumber).toLowerCase())
                    ) {
                      errors.phoneNumber = 'Invalid phone number';
                    }

                    if (!values.organization) {
                      errors.organization = 'Required';
                    }

                    return errors;
                  }}
                  onSubmit={(values) => {
                    let currentUser = values;

                    if (params?.userId) {
                      currentUser._id = params.userId;
                    }
                    currentUser.selectedRights = selectedRights;
                    updatingUser
                      ? props.updateUserStart(
                          currentUser._id,
                          currentUser,
                          navigate,
                          localUserPicture
                        )
                      : props.createUserStart(
                          currentUser,
                          navigate,
                          localUserPicture
                        );
                    return false;
                  }}
                >
                  {({ submitForm, values, isSubmitting, errors }) => {
                    let formCurrentUser = values;
                    return (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4}>
                          <input
                            type="hidden"
                            name="_csrf"
                            value={{ csrfToken }}
                          />
                          <Field
                            style={{ width: 300, marginTop: 20 }}
                            component={SetIdentifierTextField}
                            label={t('First name')}
                            name="firstname"
                            type="text"
                            updatingUser={updatingUser}
                            formIsBeingFilledOutHandler={formIsBeingFilledOut}
                          />
                          {userFormError &&
                            userFormError['user_metadata.firstname'] && (
                              <span style={{ fontSize: 10, color: 'red' }}>
                                {userFormError['user_metadata.firstname']}
                              </span>
                            )}
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                          <Field
                            style={{ width: 300, marginTop: 20 }}
                            component={SetIdentifierTextField}
                            label={t('Last name')}
                            name="lastname"
                            type="text"
                            updatingUser={updatingUser}
                            formIsBeingFilledOutHandler={formIsBeingFilledOut}
                          />
                          {userFormError &&
                            userFormError['user_metadata.lastname'] && (
                              <div>
                                <span style={{ fontSize: 10, color: 'red' }}>
                                  {userFormError['user_metadata.lastname']}
                                </span>
                              </div>
                            )}
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                          <Field
                            style={{ width: 300, marginTop: 20 }}
                            component={SetIdentifierTextField}
                            label={t('Username')}
                            name="username"
                            type="text"
                            updatingUser={updatingUser}
                            disabled={updatingUser}
                            formIsBeingFilledOutHandler={formIsBeingFilledOut}
                          />
                          {userFormError &&
                            userFormError['user_metadata.username'] && (
                              <div>
                                <span style={{ fontSize: 10, color: 'red' }}>
                                  {userFormError['user_metadata.username']}
                                </span>
                              </div>
                            )}
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                          <Field
                            style={{ width: 300, marginTop: 20 }}
                            component={SetIdentifierTextField}
                            label={t('E-mail')}
                            name="email"
                            type="email"
                            updatingUser={updatingUser}
                            disabled={updatingUser}
                            formIsBeingFilledOutHandler={formIsBeingFilledOut}
                          />
                          {userFormError && userFormError['email'] && (
                            <div>
                              <span style={{ fontSize: 10, color: 'red' }}>
                                {userFormError['email']}
                              </span>
                            </div>
                          )}
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                          <Field
                            style={{ width: 300, marginTop: 20 }}
                            component={SetIdentifierTextField}
                            label={t('Phone number')}
                            name="phoneNumber"
                            type="text"
                            updatingUser={updatingUser}
                            formIsBeingFilledOutHandler={formIsBeingFilledOut}
                          />
                          {userFormError &&
                            userFormError['user_metadata.phoneNumber'] && (
                              <div>
                                <span style={{ fontSize: 10, color: 'red' }}>
                                  {userFormError['user_metadata.phoneNumber']}
                                </span>
                              </div>
                            )}
                        </Grid>

                        {organizationsInForm &&
                          organizationsInForm.length > 1 && (
                            <Grid item xs={12} md={6} lg={4}>
                              <Field
                                component={TextField}
                                type="text"
                                name="organization"
                                label="Organization"
                                select
                                variant="standard"
                                helperText=""
                                margin="normal"
                                style={{ width: 300 }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              >
                                {organizationsInForm.map((organization) => (
                                  <MenuItem
                                    key={organization._id}
                                    value={organization._id}
                                  >
                                    {organization.name}
                                  </MenuItem>
                                ))}
                              </Field>
                              {userFormError &&
                                userFormError['user_metadata.organization'] && (
                                  <div>
                                    <span
                                      style={{ fontSize: 10, color: 'red' }}
                                    >
                                      {
                                        userFormError[
                                          'user_metadata.organization'
                                        ]
                                      }
                                    </span>
                                  </div>
                                )}
                            </Grid>
                          )}
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <input
                              accept={authorizedExtensions}
                              id="upload-button-file"
                              multiple
                              type="file"
                              style={{ display: 'none' }}
                              onChange={handlePictureChange.bind(this)}
                              onDrop={onDrop.bind(this)}
                            />
                            <Grid item xs={12} md={6} lg={2}>
                              <label htmlFor="upload-button-file">
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  component="span"
                                  disabled={loading}
                                  style={{
                                    border: '1px solid rgba(248,119,51, 0.5)',
                                    borderRadius: 4,
                                  }}
                                >
                                  Select Picture
                                </Button>
                              </label>
                              <Typography color="inherit" variant="caption">
                                {authorizedExtensionMessage}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} md={6} lg={2}>
                              {userSelectedPicture && (
                                <img
                                  src={userSelectedPicture}
                                  style={{
                                    height: 60,
                                    width: 60,
                                    borderRadius: '50%',
                                  }}
                                />
                              )}
                              {!userSelectedPicture && (
                                <Typography color="inherit" variant="caption">
                                  No picture selected
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <RightsMultipleChoice />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={3}
                          >
                            <Grid item xs={12} md={6} lg={2}>
                              <Button
                                onClick={submitForm}
                                variant="contained"
                                color="primary"
                                disabled={loading || isSubmitting}
                              >
                                {updatingUser
                                  ? t('Update user')
                                  : t('Create user')}
                              </Button>
                            </Grid>

                            {updatingUser && formCurrentUser && (
                              <Grid item xs={12} md={6} lg={2}>
                                <Button
                                  variant={'contained'}
                                  color="secondary"
                                  disabled={loading}
                                  onClick={() =>
                                    props.deleteUserStart(
                                      params.userId,
                                      navigate
                                    )
                                  }
                                >
                                  Delete user
                                </Button>
                              </Grid>
                            )}

                            {(loading || isSubmitting) && (
                              <Grid item xs={12} md={6} lg={1}>
                                <LoadingCircle
                                  type="buttonProgress"
                                  classes={classes}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  }}
                </Formik>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = ({
  usersReducer,
  organizationsReducer,
  loginReducer,
}) => {
  const {
    currentUser,
    selectedRights,
    loading,
    loadingForm,
    localUserPicture,
    formIsFilledOut,
    userFormError,
  } = usersReducer;
  const { organizations } = organizationsReducer;
  const { isAuthenticated, username, user } = loginReducer;
  return {
    currentUser,
    selectedRights,
    loading,
    loadingForm,
    isAuthenticated,
    username,
    user,
    organizations,
    localUserPicture,
    formIsFilledOut,
    userFormError,
  };
};

const mapDispatchToProps = {
  createUserStart,
  updateUserStart,
  deleteUserStart,
  uploadUserPicture,
  userPictureChanged,
  getUserFormStart,
  resetCurrentUser,
  getOrganizations,
  formIsBeingFilledOut,
  getRights,
};

FormUsers = withTranslation('translations')(FormUsers);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(FormUsers))
);
