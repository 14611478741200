import useCertificesSlicesCombiner from 'components/Certificates/Redux/Slices/useCertificatesSlicesCombiner';
import PageTitle from 'components/commons/PageTitle';

import certificateIcon from '../../../../assets/images/icons/standard.png';

import CertificatesToolbarBody from './CustomComponents/Content/CertificatesToolbarBody';
import './Styles/CertificatesToolbar.css';

const CertificatesToolbar = () => {
  const { loading } = useCertificesSlicesCombiner();
  return (
    <div className="toolbarContainer">
      <PageTitle
        title={'Proofs of retirement'}
        loading={loading}
        image={certificateIcon}
      />
      <CertificatesToolbarBody />
    </div>
  );
};

export default CertificatesToolbar;
