import { FC } from 'react';

import { ClassNames } from '@emotion/react';
import { Button, CircularProgress } from '@mui/material';
import { getBalancesByStandardAndStartDateAndEndDates } from 'components/Balances/Redux/CustomHooks/helpers/helpers';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import { DEFAULT_PAGE_SIZE } from 'components/Transactions/utils/constants';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import { hideDateFilter, setFromStartButtonPressed } from '../../../../actions';
import useBalancesChildComponentsSlices from '../../Redux/CustomHooks/useBalancesChildComponentsSlices';

const FromStartButton: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    loading,
    fromStartButtonPressed,
    selectedStandardId,
    startDate,
    endDate,
  }: UseBalancesChildComponentsSlicesType = useBalancesChildComponentsSlices();

  const handleFromStartBtnOnClick = async (): Promise<void> => {
    dispatch(setFromStartButtonPressed());
    dispatch(hideDateFilter());
    await dispatch(
      getBalancesByStandardAndStartDateAndEndDates(
        selectedStandardId as string,
        DEFAULT_PAGE_SIZE,
        false,
        startDate,
        endDate
      )
    );
  };

  return (
    <div style={{ marginLeft: 10, marginRight: 10, float: 'left' }}>
      <ClassNames>
        {() => (
          <Button
            variant={fromStartButtonPressed ? 'contained' : 'text'}
            color="primary"
            disabled={loading}
            onClick={handleFromStartBtnOnClick}
            type="submit"
          >
            {loading ? <CircularProgress size={24} /> : 'From start'}
          </Button>
        )}
      </ClassNames>
    </div>
  );
};

export default FromStartButton;
