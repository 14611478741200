/* eslint-disable max-lines */
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

export const getIssuanceListColumns = (): GridColDef[] => {
  const disabledTextStyle = { color: '#CECECE' };

  const columnData = [
    {
      field: 'asset.displayedName',
      headerName: 'Product',
      editable: false,
      width: 245,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <span
            style={
              params?.row?.dynamicDataIsAvailableForAllDynamicSources
                ? {}
                : disabledTextStyle
            }
          >
            {params?.row?.asset.displayedName}
          </span>
        );
      },
    },
    {
      field: 'availableQuantity',
      headerName: 'Available quantity',
      editable: false,
      type: 'number',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <span
            style={
              params?.row?.dynamicDataIsAvailableForAllDynamicSources
                ? {}
                : disabledTextStyle
            }
          >
            {params?.row?.availableQuantity}
          </span>
        );
      },
    },
    {
      field: 'asset.unit',
      headerName: 'Unit',
      editable: false,
      width: 80,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <span
            style={
              params?.row?.dynamicDataIsAvailableForAllDynamicSources
                ? {}
                : disabledTextStyle
            }
          >
            {params?.row?.asset.unit}
          </span>
        );
      },
    },
    {
      field: 'dynamicDataIsAvailableForAllDynamicSources',
      headerName: 'Info',
      editable: false,
      width: 245,
      renderCell: (params: GridRenderCellParams) => {
        if (params?.row?.dynamicDataIsAvailableForAllDynamicSources) return '';

        return (
          <span style={disabledTextStyle}>Missing uploaded dynamic data</span>
        );
      },
    },
  ];

  return columnData;
};
