import { SelectChangeEvent } from '@mui/material';
import { setCertificatesProductType } from 'actions';
import useCertificatesSlicesCombiner from 'components/Certificates/Redux/Slices/useCertificatesSlicesCombiner';
import itemMapper from 'components/Certificates/utils/utils';
import { displayPlatinumUI } from 'components/CreateOrUpdateProduct/utils/utils';
import GenericBasicSelect from 'components/GenericComponents/BasicFields/GenericBasicSelect/GenericBasicSelect';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const ProductTypeSelect = () => {
  const dispatch: AppDispatch = useDispatch();
  const { selectedProductTypeId, productTypes, loadingProductTypes } =
    useCertificatesSlicesCombiner();

  const handleProductTypeChange = (event: SelectChangeEvent<string>) => {
    dispatch(setCertificatesProductType(event.target.value));
  };

  if (loadingProductTypes || !displayPlatinumUI) return null;

  return (
    <GenericBasicSelect
      labelId="select-product-type"
      id="select-product-type"
      value={selectedProductTypeId || 'allProductTypes'}
      onChange={(e) => handleProductTypeChange(e)}
      items={[
        {
          key: 'allProductTypes',
          value: 'allProductTypes',
          label: 'All metals',
        },
        ...itemMapper(productTypes),
      ]}
      style={{ marginRight: 20 }}
    />
  );
};

export default ProductTypeSelect;
