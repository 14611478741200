import { Button, DialogActions } from '@mui/material';
import { deleteProductReferenceFileS3Start } from 'actions';
import Loading from 'components/GenericComponents/Async/Loading/Loading';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const ConfirmDeletionActions = (props: any) => {
  const { referenceFileToDelete, currentProduct, deletingFileIds } =
    useProductSlicesCombiner();

  const referenceFileToDeleteId = referenceFileToDelete?._id
    ? referenceFileToDelete?._id
    : '';
  const deletingFile = deletingFileIds?.includes(referenceFileToDeleteId);
  const dispatch: AppDispatch = useDispatch();
  return (
    <DialogActions>
      <Button onClick={props.handleClose} color="primary">
        Cancel
      </Button>
      <Button
        variant="contained"
        color="secondary"
        disabled={deletingFile}
        onClick={() => {
          dispatch(
            deleteProductReferenceFileS3Start(
              referenceFileToDelete?._id,
              currentProduct?._id
            )
          );
        }}
      >
        Delete
        {deletingFile && <Loading size={16} style={{ marginLeft: 10 }} />}
      </Button>
    </DialogActions>
  );
};

export default ConfirmDeletionActions;
