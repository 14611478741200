import React from 'react';

import { Grid } from '@mui/material';
import { TextField as MaterialUiTextField } from '@mui/material';
import { FormikErrors, useFormikContext } from 'formik';
import { NumericFormat } from 'react-number-format';

import { GenericNumericFieldPropsType } from '../../utils/types/types';

const GenericNumericField = ({
  name = '',
  value = 0,
  label = '',
  gridItemSize = 12,
  onValueChange,
}: GenericNumericFieldPropsType) => {
  const { errors, setFieldTouched, touched } =
    useFormikContext<FormikErrors<any>>();
  const handleBlur = () => {
    setFieldTouched(name, true, true);
  };
  return (
    <Grid item xs={gridItemSize}>
      <NumericFormat
        style={{ width: 750, marginTop: 10 }}
        name={name}
        label={label}
        value={value}
        thousandSeparator={' '}
        customInput={MaterialUiTextField}
        error={Boolean(errors?.name && touched?.name)}
        onValueChange={onValueChange}
        onBlur={handleBlur}
      />
      {errors && errors?.[name] && touched?.[name] && (
        <div style={{ marginLeft: 14 }}>
          <span style={{ fontSize: 11, color: '#d32f2f' }}>{errors[name]}</span>
        </div>
      )}
    </Grid>
  );
};

export default GenericNumericField;
