import { useState } from 'react';

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import expandMoreIcon from '../../assets/images/icons/chevron-down-windows-10.png';
import expandLessIcon from '../../assets/images/icons/chevron-up-windows-10.png';

import DrawerLink from './DrawerLink';
import { LinkType } from './types/LinkType';

const DrawerLinkWithChildren = (props: {
  link: LinkType;
  isSubLinkActive: boolean;
  activeLink: string;
  onClick: any;
}): JSX.Element => {
  const { link, isSubLinkActive, activeLink, onClick } = props;

  const [open, setOpen] = useState(false);

  const handleCollapseClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        key={link.to}
        onClick={handleCollapseClick}
        style={isSubLinkActive ? styles.activeListItem : {}}
      >
        <ListItemIcon>
          <img src={link.icon} style={{ maxHeight: 20 }} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography style={styles.listItemText}>{link.label}</Typography>
          }
        />
        {open ? (
          <img
            src={expandLessIcon}
            alt={'ExpandLess'}
            style={{ width: 10, height: 10 }}
          />
        ) : (
          <img
            src={expandMoreIcon}
            alt={'ExpandMore'}
            style={{ width: 10, height: 10 }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={styles.subMenuContainer}>
          {link.children &&
            link.children.map((childLink) => {
              return (
                <DrawerLink
                  key={childLink.to}
                  link={childLink}
                  isSubLink={true}
                  activeLink={activeLink}
                  onClick={() => {
                    onClick(childLink);
                  }}
                />
              );
            })}
        </List>
      </Collapse>
    </>
  );
};

const styles = {
  subMenuContainer: {
    backgroundColor: '#FCFCFC',
  },
  activeListItem: {
    backgroundColor: '#F2F2F2',
    textDecoration: 'none',
    '&:focus': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '&:visited': {
      textDecoration: 'none',
    },
    '&:active': {
      textDecoration: 'none',
    },
  },
  listItemText: {
    fontSize: '0.8rem',
    fontFamily: [
      'Inter-Regular',
      'Avenir',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
};
export default DrawerLinkWithChildren;
