import React from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import backIcon from '../../../assets/images/icons/back1.png';

const BackButton = (): JSX.Element => {
  const navigate = useNavigate();

  const titleContainerStyle = {
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  };
  return (
    <Link
      component="button"
      variant="body2"
      onClick={() => {
        navigate(-1);
      }}
      style={{ textDecoration: 'none' }}
    >
      <Box flexGrow={1} style={titleContainerStyle as React.CSSProperties}>
        <img src={backIcon} style={{ maxHeight: 20, marginRight: 6 }} />
        <Typography variant="subtitle1" style={{ color: '#F87733' }}>
          Back
        </Typography>
      </Box>
    </Link>
  );
};

export default BackButton;
