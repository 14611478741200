import { displayIncreaseFormInDialog, increaseBalanceStart } from 'actions';
import GenericTypography from 'components/Administration/Generic/Form/Labels/GenericTypography';
import {
  addThousandsSeparatorToNumber,
  quantityTypeUppercase,
  removeNonNumeric,
} from 'components/Administration/Generic/Form/utils/utils';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { formatMonthYear } from 'components/Balances/utils/table/helpers';

import { FormFieldsConfigType } from '../../../../utils/types/formFieldsTypes';
import { FooterModalPropsType } from '../../../../utils/types/types';
import useIncreaseBalanceUnitAndPeriod from '../CustomHooks/useIncreaseBalanceUnitAndPeriod';

const useIncreaseBalanceFormConfirmationModalConfig = () => {
  const { increaseBalanceData, loadingIncreaseBalance } =
    useAdministrationSlicesCombiner();
  const { unit } = useIncreaseBalanceUnitAndPeriod();

  //modal components
  const modalHeader = {
    title: '',
  };
  const modalFooter: FooterModalPropsType = {
    cancelLabel: 'Back',
    submitLabel: 'Confirm',
    onCancelHandler: displayIncreaseFormInDialog,
    onSubmitHandler: increaseBalanceStart(
      increaseBalanceData._for,
      increaseBalanceData._origin,
      increaseBalanceData._year,
      increaseBalanceData._month,
      increaseBalanceData._volume,
      increaseBalanceData._comment,
      increaseBalanceData._txHash
    ),
    loading: loadingIncreaseBalance,
  };
  const formFieldsConfig: FormFieldsConfigType = [
    [
      {
        component: GenericTypography,
        name: 'Please check if the data are Ok before proceeding to the Increase',
        styleGrid: { marginBottom: 10 },
        styleLabel: { paddingBottom: 10 },
        size: 12,
      },
      {
        component: GenericTypography,
        name: 'For',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${
          increaseBalanceData._for && increaseBalanceData._for.name
            ? increaseBalanceData._for.name
            : ''
        }`,
      },
      {
        component: GenericTypography,
        name: 'Origin',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${
          increaseBalanceData._origin &&
          increaseBalanceData._origin.displayedName
            ? increaseBalanceData._origin.displayedName
            : ''
        }`,
      },
      {
        component: GenericTypography,
        name: 'Period',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${formatMonthYear(
          increaseBalanceData._year + '-' + increaseBalanceData._month
        )}`,
      },
      {
        component: GenericTypography,
        name: `${quantityTypeUppercase}`,
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${addThousandsSeparatorToNumber(
          removeNonNumeric(increaseBalanceData._volume)
        )} ${unit}`,
      },
      {
        component: GenericTypography,
        name: 'Comment',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${increaseBalanceData._comment}`,
      },
      {
        component: GenericTypography,
        name: 'Transaction Hash',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${increaseBalanceData._txHash}`,
      },
    ],
  ];
  return {
    header: modalHeader,
    content: formFieldsConfig,
    footer: modalFooter,
  };
};

export default useIncreaseBalanceFormConfirmationModalConfig;
