import { CardHeader } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

export const Block = (props: any) => {
  const { title, subtitle, icon, onClick } = props;
  return (
    <Grid item xs={12} lg={6}>
      <Box onClick={onClick} style={{ cursor: 'pointer' }}>
        <Card style={{ backgroundColor: '#ffffff' }}>
          <CardHeader
            avatar={<img src={icon} style={{ maxHeight: 20 }} />}
            title={<span style={{ color: 'black' }}>{title}</span>}
            subheader={<span style={{ color: 'grey' }}>{subtitle}</span>}
          />
        </Card>
      </Box>
    </Grid>
  );
};
