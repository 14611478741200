import React from 'react';

import GenericCardAndModalCombiner from 'components/GenericComponents/Dialog/GenericCardAndModalCombiner/GenericCardAndModalCombiner';
import { WithSnackbarProps } from 'notistack';
import { withRouter } from 'utils/migrationHelper';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';

import dateToIcon from '../../assets/images/icons/date-to.png';

import useNewOperations from './Redux/CustomHooks/useNewOperations';
import useNewOperationsSlicesCombiner from './Redux/Slices/useNewOperationsSlicesCombiner';
import { getOperationsActions } from './utils/actions';

type NewOperationsProps = WithSnackbarProps;

const NewOperations: React.FC<NewOperationsProps> = () => {
  useNewOperations();

  const { user, isAuthenticated } = useNewOperationsSlicesCombiner();
  const userIsSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, user);
  const userRights = Array.isArray(user?.user_metadata?.rights)
    ? user?.user_metadata?.rights
    : [];

  const actions = React.useMemo(
    () => getOperationsActions(userIsSuperAdmin, userRights),
    [getOperationsActions]
  );

  return (
    <GenericCardAndModalCombiner
      title="Operations"
      actions={actions}
      icon={dateToIcon}
    />
  );
};

export default withRouter(NewOperations);
