import { Field } from 'formik';

const BalancesGenericSelectField = ({ customFieldProps, children }) => {
  return (
    <Field data-testid="beneficiary-select-list-id" {...customFieldProps}>
      {children}
    </Field>
  );
};

export default BalancesGenericSelectField;
