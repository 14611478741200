import useLoginSlices from 'components/Balances/Redux/Slices/useLoginSlices';
import useProductTypesSlices from 'components/Balances/Redux/Slices/useProductTypesSlices';
import useStandardsSlices from 'components/Balances/Redux/Slices/useStandardsSlices';
import useUtilsSlices from 'components/Balances/Redux/Slices/useUtilsSlices';

import useCertificesSlices from './useCertificatesSlices';

const useCertificesSlicesCombiner = () => {
  return {
    ...useCertificesSlices(),
    ...useProductTypesSlices(),
    ...useUtilsSlices(),
    ...useStandardsSlices(),
    ...useLoginSlices(),
  };
};

export default useCertificesSlicesCombiner;
