import { Box, Tooltip } from '@mui/material';
import moment from 'moment';

const DateCell = (props: any): JSX.Element => {
  const {
    cellDate,
    userCanEditReferenceFiles = false,
    startOrEnd = 'start',
  } = props;

  let cellDateString = userCanEditReferenceFiles ? 'double click to edit' : '-';
  let cellDateStyle = { color: 'rgba(0, 0, 0, 0.38)', fontSize: 10 };
  if (cellDate) {
    cellDateString = moment(cellDate).format('YYYY-MM-DD');
    cellDateStyle = { color: 'rgba(0, 0, 0, 1)', fontSize: 14 };
  }

  if (userCanEditReferenceFiles) {
    return (
      <Tooltip
        title={`Double click to edit validity ${startOrEnd}  date`}
        placement="bottom"
        enterDelay={100}
      >
        <Box style={style.tooltipCell}>
          <span style={cellDateStyle}>{cellDateString}</span>
        </Box>
      </Tooltip>
    );
  }

  return <>{cellDateString}</>;
};

const style = {
  tooltipCell: {
    minHeight: '100%',
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
  },
};

export default DateCell;
