import {
  closeActionDialog,
  confirmationDatasetChanged,
  confirmationSourceDocumentChanged,
  uploadConfirmationMultipleDatasetAndSource,
} from 'actions';
import GenericUploadField from 'components/Administration/Generic/Form/Fields/Upload/GenericUploadField';
import GenericUploadLink from 'components/Administration/Generic/Form/Fields/Upload/GenericUploadLink';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import { FormFieldsConfigType } from '../../../../../utils/types/formFieldsTypes';
import { FooterModalPropsType } from '../../../../../utils/types/types';
import { useSourceDocuments } from '../../../utils/CustomHooks/useLocalSourceDocument';

const useUploadConfirmationDataForm = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    loadingConfirmationDataset,
    localConfirmationDataset,
    localConfirmationSourceDocument,
  } = useAdministrationSlicesCombiner();
  const { confirmationSelectedDataset, confirmationSelectedSourceDocument } =
    useSourceDocuments();

  const handleConfirmationDatasetChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      confirmationDatasetChanged(event.target.files && event.target.files[0])
    );
  };
  const handleConfirmationSourceDocumentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      confirmationSourceDocumentChanged(
        event.target.files && event.target.files[0]
      )
    );
  };

  const handleUploadConfirmationDatasetAndSource = async () => {
    dispatch(
      uploadConfirmationMultipleDatasetAndSource({
        dataset: localConfirmationDataset,
        sourceDocument: localConfirmationSourceDocument,
      })
    );
  };
  //modal components
  const modalHeader = {
    title: '',
  };
  const modalFooter: FooterModalPropsType = {
    cancelLabel: 'Cancel',
    submitLabel: 'Upload dataset and source',
    onCancelHandler: closeActionDialog,
    onSubmitHandler: handleUploadConfirmationDatasetAndSource,
    disabled: !confirmationSelectedDataset,
    loading: loadingConfirmationDataset,
  };

  const formFieldsConfig: FormFieldsConfigType = [
    [
      {
        component: GenericUploadField,
        onChange: handleConfirmationDatasetChange,
        idSelector: 'upload-confirmation-dataset-button-ref',
        buttonLabel: 'Select excel dataset',
        disabled: loadingConfirmationDataset,
      },
      {
        component: GenericUploadLink,
        noDocumentFoundLabel: 'No dataset selected',
        documentToUploadRef: confirmationSelectedDataset,
        documentToUpload: localConfirmationDataset,
        linkExampleToDisplay: true,
      },
    ],
    [
      {
        component: GenericUploadField,
        onChange: handleConfirmationSourceDocumentChange,
        idSelector: 'upload-confirmation-source-document-button-ref',
        buttonLabel: ' Select document',
        disabled: loadingConfirmationDataset,
      },
      {
        component: GenericUploadLink,
        noDocumentFoundLabel: 'Upload proof of production or source document ',
        documentToUploadRef: confirmationSelectedSourceDocument,
        documentToUpload: localConfirmationSourceDocument,
      },
    ],
  ];

  return {
    header: modalHeader,
    content: formFieldsConfig,
    footer: modalFooter,
  };
};

export default useUploadConfirmationDataForm;
