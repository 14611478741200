import { Fab, Tooltip } from '@mui/material';
import {
  getSecretWordForCertificateFromTransaction,
  getSecretWordForTransaction,
  toggleSecretWordDialog,
} from 'actions';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import lockIcon from '../../../../assets/images/icons/padlock.png';

type SecretWordButtonProps = {
  transaction: any;
};

const SecretWordButton = ({ transaction }: SecretWordButtonProps) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const handleOpenSecretWordDialog = (transaction: any) => {
    dispatch(toggleSecretWordDialog());
    if (transaction.type === 'allocate_certificate') {
      dispatch(getSecretWordForCertificateFromTransaction(transaction));
    } else {
      dispatch(getSecretWordForTransaction(transaction));
    }
  };
  return (
    <Tooltip title={t('Encrypted data')} placement="bottom" enterDelay={100}>
      <Fab
        size="small"
        color="primary"
        aria-label="add"
        style={{ backgroundColor: '#FFFFFF' }}
        onClick={() => {
          handleOpenSecretWordDialog(transaction);
        }}
      >
        <img
          src={lockIcon}
          alt={'Get secret word'}
          title={'Get secret word'}
          style={{ width: 22, height: 22 }}
        />
      </Fab>
    </Tooltip>
  );
};

export default SecretWordButton;
