import { DataGridPro as XGrid } from '@mui/x-data-grid-pro';
import { withRouter } from 'utils/migrationHelper';

import standardIcon from '../../assets/images/icons/agreement-uploaded.png';
import PageTitle from '../commons/PageTitle';

import CreateButton from './CustomComponents/Buttons/Create';
import GridToolbar from './CustomComponents/Toolbar/GridToolbar';
import useStandards from './Redux/CustomHooks/useStandards';
import useStandardsCombiner from './Redux/Slices/Combiner/useStandardsCombiner';
import StandardsColumns from './utils/table/columns';

const Standards = () => {
  const { standards, loadingStandards } = useStandardsCombiner();
  useStandards();

  const dataGridColumns = StandardsColumns();
  const dataGridRows =
    standards && Array.isArray(standards)
      ? standards.map((c, index) => {
          c.id = index;
          return c;
        })
      : [];

  return (
    <>
      <div
        style={{
          width: '100%',
          height: document.documentElement.clientHeight - 200,
          paddingBottom: 0,
        }}
      >
        <PageTitle
          title={'Standards'}
          loading={loadingStandards}
          image={standardIcon}
        />
        <XGrid
          loading={loadingStandards}
          pagination
          rows={dataGridRows}
          columns={dataGridColumns}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </div>
      <CreateButton />
    </>
  );
};

export default withRouter(Standards);
