import React, { SyntheticEvent } from 'react';

import { closeBulkIssuanceForm } from 'actions';
import GenericAutocompleteField from 'components/Administration/Generic/Form/Fields/Autocomplete/GenericAutocompleteField';
import GenericTextField from 'components/Administration/Generic/Form/Fields/Text/GenericTextField';
import {
  getMonthsMenu,
  getYearsMenu,
} from 'components/Administration/Generic/Form/utils/utils';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { FormikValues, useFormikContext } from 'formik';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';

import { FormFieldsConfigType } from '../../../../../utils/types/formFieldsTypes';
import { FooterModalPropsType } from '../../../../../utils/types/types';
import useProductsAndOrganizationsOptions from '../../../../utils/CustomHooks/useProductsAndOrganizationsOptions';

const useIssueCertificatesInBulkFormModalConfig = () => {
  const { setFieldValue, submitForm, values } =
    useFormikContext<FormikValues>();
  const { organizations, user, isAuthenticated, loadingIssuanceList } =
    useAdministrationSlicesCombiner();

  const organizationsInBulkMenu = [...organizations].filter((organization) => {
    return !organization.archived;
  });
  const { organizationsOptions } = useProductsAndOrganizationsOptions(
    organizationsInBulkMenu
  );

  //modal components
  const modalHeader = {
    title: '',
  };
  const modalFooter: FooterModalPropsType = {
    cancelLabel: 'Close',
    submitLabel: 'See products',
    onCancelHandler: closeBulkIssuanceForm,
    onSubmitHandler: submitForm,
    loading: loadingIssuanceList,
    disabled: loadingIssuanceList,
  };
  const formFieldsConfig: FormFieldsConfigType = [
    [
      {
        component: GenericAutocompleteField,
        name: 'organization',
        label: 'Organization',
        options: organizationsOptions,
        selectedValue:
          organizationsOptions.find(
            (option: any) => option.value === values.organization
          ) || '',
        onChange: (_: SyntheticEvent, selectedOption: any) => {
          setFieldValue('organization', selectedOption?.value);
        },
        showErrors: false,
        condition: () => checkUserIsSuperAdmin(isAuthenticated, user),
      },
      {
        component: GenericTextField,
        name: 'year',
        label: 'Year',
        select: true,
        dynamicMenuItems: true,
        menuItemsCallback: getYearsMenu,
        showErrors: false,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue('year', e.target.value),
      },
      {
        component: GenericTextField,
        name: 'month',
        label: 'Period',
        select: true,
        dynamicMenuItems: true,
        menuItemsCallback: getMonthsMenu,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue('month', e.target.value),
      },
    ],
  ];
  return {
    header: modalHeader,
    content: formFieldsConfig,
    footer: modalFooter,
  };
};

export default useIssueCertificatesInBulkFormModalConfig;
