export const InfoTypes = {
  TEXT: 'text',
  DATE: 'date',
  DYNAMIC_DATA: 'dynamicData',
  LIST: 'list',
  LIST_MULTI: 'listMulti',
  COLLECTION: 'collection',
  COLLECTION_MULTI: 'collectionMulti',
  NUMBER: 'number',
  DEFAULT: 'default',
};
