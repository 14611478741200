import useFormStandardsSlicesCombiner from 'components/FormStandards/Redux/Slices/Combiner/useFormStandardsCombiner';
import { handleAddAttribute } from 'components/FormStandards/utils/handlers';

import { StandardAttributesDraggableGrid } from '../../DragNDrop/StandardAttributesDraggableGrid';
import AddAttributeButton from '../../Grid/Buttons/Atomic/AddAttributeButton';
import FormJsonSwitch from '../../Grid/Buttons/Atomic/FormJsonSwitch';
import StandardCTABloc from '../../Grid/Buttons/Bloc/StandardCTABloc';

type FormikInputsStandardContentTypeProps = {
  props: any;
};
const FormikInputsStandardContent = ({
  props,
}: FormikInputsStandardContentTypeProps) => {
  const { currentStandard } = useFormStandardsSlicesCombiner();

  const { cookies, toggleJsonView, values, setFieldValue } = props;
  const csrfToken = cookies?.cookies?._csrf;

  return (
    <>
      <FormJsonSwitch
        onFormJsonButtonClick={toggleJsonView}
        jsonActivated={false}
      />
      <StandardAttributesDraggableGrid
        infoTexts={values.infoTexts}
        csrfToken={csrfToken}
      >
        <AddAttributeButton
          onAddAttribute={() =>
            handleAddAttribute(values, setFieldValue, currentStandard)
          }
        />
        <StandardCTABloc params={props.router?.params} />
      </StandardAttributesDraggableGrid>
    </>
  );
};

export default FormikInputsStandardContent;
