import { Button, DialogActions } from '@mui/material';
import LoadingCircle from 'components/commons/LoadingCircle';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import { ModalStructurePropsType } from '../../Form/utils/types/types';

const GenericModalFooter = ({ footer }: ModalStructurePropsType) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    onCancelHandler,
    cancelLabel,
    onSubmitHandler,
    submitLabel,
    disabled,
    loading,
    displaySubmitBtnElementCondition = true,
    wideModal = false,
  } = footer ?? {};

  return (
    <DialogActions style={wideModal ? { marginRight: 32 } : {}}>
      {cancelLabel && onCancelHandler && (
        <Button
          onClick={() => onCancelHandler && dispatch(onCancelHandler())}
          color="primary"
        >
          {cancelLabel}
        </Button>
      )}
      {displaySubmitBtnElementCondition && (
        <Button
          onClick={(() => dispatch(onSubmitHandler)) || onSubmitHandler}
          variant="contained"
          disabled={disabled}
          color="primary"
        >
          {submitLabel}
          {loading && (
            <LoadingCircle style={{ color: 'white', marginLeft: 10 }} />
          )}
        </Button>
      )}
    </DialogActions>
  );
};

export default GenericModalFooter;
