import useCreateOrUpdateProductSlicesCombiner from 'components/CreateOrUpdateProduct/Redux/Slices/useCreateOrUpdateProductSlicesCombiner';
import GenericInputField from 'components/GenericComponents/Forms/Formik/Fields/Input/GenericInputField';
import GenericSelectField from 'components/GenericComponents/Forms/Formik/Fields/Select/GenericSelectField';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';
import { getProdSourcesList } from 'components/ProductDataAcquisition/utils/utils';

const SourcesInfoFieldsGroup = () => {
  const { prodSources } = useCreateOrUpdateProductSlicesCombiner();
  const gridDimensionsProps = { xs: 12, md: 6, lg: 4 };
  return (
    <GenericGrid isItem xs={12}>
      <GenericGrid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <GenericSelectField
          {...gridDimensionsProps}
          name="source"
          label="Source"
          listItems={getProdSourcesList(prodSources)}
          style={{ width: 300 }}
        />
        <GenericInputField
          {...gridDimensionsProps}
          name="sourceID"
          label="Source ID"
          type="text"
          style={{ width: 300, marginTop: 20 }}
        />
        <GenericInputField
          {...gridDimensionsProps}
          name="startDate"
          label="Start date"
          type="date"
          style={{ width: 300, marginTop: 20 }}
        />
      </GenericGrid>
    </GenericGrid>
  );
};

export default SourcesInfoFieldsGroup;
