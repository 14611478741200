import React from 'react';

import { convertOzInGrams } from '../../../../../../utils/convert';

const ConvertOzInGrams = ({ condition, values }) => {
  return (
    condition && (
      <div style={{ paddingLeft: 10 }}>
        <span
          style={{
            fontSize: 10,
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          ≈ {parseInt(convertOzInGrams(values.volume))} g
        </span>
      </div>
    )
  );
};

ConvertOzInGrams.propTypes = {};

export default ConvertOzInGrams;
