/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import App from './App';
import Landing from './components/Landing/Landing';
import Certificates from './components/Certificates/Certificates';
import AuthCallback from './components/AuthCallback';
import { store } from 'redux/store';
import history from './history';
import { theme } from './theme';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <Provider store={store}>
      <ConnectedRouter history={history} store={store}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <I18nextProvider i18n={i18n}>
              <SnackbarProvider maxSnack={3}>
                <BrowserRouter>
                  <App history={history}>
                    <Routes>
                      <Route exact path="/" element={<Landing />} />
                      <Route
                        exact
                        path="/get-user-on-authentication"
                        element={<AuthCallback />}
                      />
                      <Route
                        exact
                        path="/certificatesn"
                        element={<Certificates />}
                      />
                    </Routes>
                  </App>
                </BrowserRouter>
              </SnackbarProvider>
            </I18nextProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  </React.Fragment>
);

reportWebVitals();
