import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import CreateObjectDialog from 'components/FormStandards/CustomComponents/Dialogs/CreateObjectDialog';
import RemoveItemDialog from 'components/FormStandards/CustomComponents/Dialogs/RemoveItemDialog';
import { FormikValues } from 'formik';

import AddItemToListButton from '../../../../Buttons/Atomic/AddItemToListButton';
import AddAttributeTable from '../../../../Table/AddAttributeTable/AddAttributeTable';
import DefaultStandardAttributeForm from '../DefaultAttributeForm';

type ListGridItemPropsType = {
  index: number;
  isEven: boolean;
  dataGridColumns: GridColDef[];
  infoText?: any;
  onRemove: (
    values: FormikValues,
    setFieldValue: any,
    indexToRemove: number
  ) => void;
};
const ListGridItem = ({
  index,
  isEven,
  dataGridColumns,
  infoText,
  onRemove,
}: ListGridItemPropsType) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      style={{
        backgroundColor: isEven ? '#F5F8FA' : '#FFFFFF',
        paddingBottom: 20,
      }}
    >
      <DefaultStandardAttributeForm index={index} onRemove={onRemove} />
      <AddItemToListButton />
      <AddAttributeTable
        index={index}
        dataGridColumns={dataGridColumns}
        infoText={infoText}
      />
      <RemoveItemDialog />
      <CreateObjectDialog index={index} />
    </Box>
  );
};

export default ListGridItem;
