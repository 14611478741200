import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const useAdministrationSlices = () => {
  //Redux Administration slices
  const {
    localDataset,
    loadingDataset,
    localSourceDocument,
    minVolumeToUpload,
    alreadyUploadedVolume,
    loadingConfirmationDataset,
    loadingBulkOperationsDataset,
    localConfirmationSourceDocument,
    localBulkOperationsDataset,
    localConfirmationDataset,
    increaseBalanceFormOpen,
    actionDialogOpen,
    formToDisplayInActionDialog,
    decreaseBalanceFormOpen,
    displayIncreaseBalanceFormInDialog,
    displayDecreaseBalanceFormInDialog,
    increaseBalanceData,
    decreaseBalanceData,
    loadingIncreaseBalance,
    loadingDecreaseBalance,
    loadingSetMissingIssuerOnTransactions,
    loadingUpdateBalancesCache,
    issueCertificateFormOpen,
    displayIssueCertificateFormInDialog,
    displayIssueCertificateBulkFormInDialog,
    issueCertificatesBulkFormError,
    loadingIssueCertificatesBulk,
    displayNotificationIssueFormInDialog,
    loadingIssuanceList,
    notifyFutureIssuanceFormError,
    loadingNotificationFutureIssue,
    displayAllFeaturesValue,
    displaySingleOriginDataUploader,
    displayMultipleOriginDataUploader,
    checkAttributesData,
    displayCheckAttributesFormInDialog,
    checkAttributesFormError,
    checkAttributesResult,
    loadingCheckAttributes,
    displayCheckAttributesResult,
    loadingIssueCertificate,
    issueCertificateData,
    issueCertificatesBulkData,
    notifyFutureIssuanceData,
    issueCertificatesFormError,
    increaseBalanceFormError,
    decreaseBalanceFormError,
    bulkIssuanceList,
    notificationFutureIssuanceList,
    maxQuantityForProduct,
    loadingMaxQuantityForProduct,
    numSelected,
    totalIssueBulkProductsCount,
    scheduledTasksMonitoring,
    resetDecreaseBalanceForm,
    resetIncreaseBalanceForm,
    resetIssuanceNotificationEmailForm,
    resetIssueCertificateForm,
    resetIssueCertificateInBulkForm,
    resetCheckAttributesForm,
  } = useSelector((state: RootState) => state.administrationReducer);
  return {
    localDataset,
    loadingDataset,
    localSourceDocument,
    minVolumeToUpload,
    alreadyUploadedVolume,
    loadingConfirmationDataset,
    loadingBulkOperationsDataset,
    localConfirmationSourceDocument,
    localBulkOperationsDataset,
    localConfirmationDataset,
    increaseBalanceFormOpen,
    actionDialogOpen,
    formToDisplayInActionDialog,
    decreaseBalanceFormOpen,
    displayIncreaseBalanceFormInDialog,
    displayDecreaseBalanceFormInDialog,
    increaseBalanceData,
    decreaseBalanceData,
    loadingIncreaseBalance,
    loadingDecreaseBalance,
    loadingSetMissingIssuerOnTransactions,
    loadingUpdateBalancesCache,
    issueCertificateFormOpen,
    displayIssueCertificateFormInDialog,
    displayIssueCertificateBulkFormInDialog,
    issueCertificatesBulkFormError,
    loadingIssueCertificatesBulk,
    displayNotificationIssueFormInDialog,
    loadingIssuanceList,
    notifyFutureIssuanceFormError,
    loadingNotificationFutureIssue,
    displayAllFeaturesValue,
    displaySingleOriginDataUploader,
    displayMultipleOriginDataUploader,
    checkAttributesData,
    displayCheckAttributesFormInDialog,
    checkAttributesFormError,
    checkAttributesResult,
    loadingCheckAttributes,
    displayCheckAttributesResult,
    loadingIssueCertificate,
    issueCertificateData,
    issueCertificatesBulkData,
    notifyFutureIssuanceData,
    issueCertificatesFormError,
    increaseBalanceFormError,
    decreaseBalanceFormError,
    bulkIssuanceList,
    notificationFutureIssuanceList,
    maxQuantityForProduct,
    loadingMaxQuantityForProduct,
    numSelected,
    totalIssueBulkProductsCount,
    scheduledTasksMonitoring,
    resetDecreaseBalanceForm,
    resetIncreaseBalanceForm,
    resetIssuanceNotificationEmailForm,
    resetIssueCertificateForm,
    resetIssueCertificateInBulkForm,
    resetCheckAttributesForm,
  };
};

export default useAdministrationSlices;
