import { CircularProgress, Toolbar, Typography } from '@mui/material';
import useNominationsSlicesCombiner from 'components/Nominations/Redux/Slices/useNominationsSlicesCombiner';

import calendarIcon from '../../../../assets/images/icons/schedule.png';

type NominationsToolbarPropsType = {
  title: string;
};
const NominationsToolbar = ({ title }: NominationsToolbarPropsType) => {
  const { loading } = useNominationsSlicesCombiner();

  return (
    <Toolbar>
      <div>
        <Typography variant="body2">
          <img
            src={calendarIcon}
            style={{
              maxHeight: 20,
              position: 'relative',
              top: 3,
              marginRight: 10,
            }}
            alt=""
          />
          {title}
          {loading && <CircularProgress size={16} style={{ marginLeft: 10 }} />}
        </Typography>
      </div>
    </Toolbar>
  );
};

export default NominationsToolbar;
