import { Dialog } from '@mui/material';
import { hideConfirmDynamicListItemDeletionModal } from 'actions';
import useDynamicListsSlicesCombiner from 'components/DynamicList/Redux/Slices/useDynamicListsSlicesCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import ConfirmDeletionActions from './Actions/ConfirmDeletionActions';
import ConfirmDeletionTitle from './ConfirmDeletionTitle';
import ConfirmDeletionContent from './Content/ConfirmDeletionContent';

const ConfirmDeletionDialog = () => {
  const { openConfirmDynamicListItemDeletion } =
    useDynamicListsSlicesCombiner();
  const dispatch: AppDispatch = useDispatch();
  const handleClose = () => {
    dispatch(hideConfirmDynamicListItemDeletionModal());
  };
  const modalProps: any = {
    fullScreen: false,
    open: openConfirmDynamicListItemDeletion,
    'data-testid': 'confirmDeletionDialog',
    'aria-labelledby': 'responsive-dialog-title',
    onClose: handleClose,
  };

  return (
    <Dialog {...modalProps}>
      <ConfirmDeletionTitle handleClose={handleClose} />
      <ConfirmDeletionContent />
      <ConfirmDeletionActions handleClose={handleClose} />
    </Dialog>
  );
};

export default ConfirmDeletionDialog;
