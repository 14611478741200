import useBalancesSlices from '../Slices/useBalancesSlices';
import useLoginSlices from '../Slices/useLoginSlices';
import useOrganizationsSlices from '../Slices/useOrganizationsSlices';
import useProductTypesSlices from '../Slices/useProductTypesSlices';
import useStandardsSlices from '../Slices/useStandardsSlices';
import useUtilsSlices from '../Slices/useUtilsSlices';

import { UseBalancesChildComponentsSlicesType } from './types';

const useBalancesChildComponentsSlices =
  (): UseBalancesChildComponentsSlicesType => {
    return {
      ...useBalancesSlices(),
      ...useLoginSlices(),
      ...useOrganizationsSlices(),
      ...useProductTypesSlices(),
      ...useStandardsSlices(),
      ...useUtilsSlices(),
    };
  };

export default useBalancesChildComponentsSlices;
