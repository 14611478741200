import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useBulkOperationsModalConfig from '../../Configuration/useBulkOperationsModalConfig';

const ModalFooterBulkOperations = () => {
  const { footer } = useBulkOperationsModalConfig();
  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterBulkOperations;
