import CircularProgress from '@mui/material/CircularProgress';
import {
  closeCheckAttributesForm,
  displayCheckAttributesFormDialog,
} from 'actions';
import CheckAttributesList from 'components/Administration/CustomComponents/CheckAttributes/CheckAttributesList';
import GenericTypography from 'components/Administration/Generic/Form/Labels/GenericTypography';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';

import { FormFieldsConfigType } from '../../../../../utils/types/formFieldsTypes';
import { FooterModalPropsType } from '../../../../../utils/types/types';

const useCheckAttributesConfirmationModalConfig = () => {
  const { checkAttributesResult, loadingCheckAttributes } =
    useAdministrationSlicesCombiner();

  //modal components
  const modalHeader = {
    title: '',
  };
  const modalFooter: FooterModalPropsType = {
    cancelLabel: 'Back',
    submitLabel: 'Close',
    onCancelHandler: displayCheckAttributesFormDialog,
    onSubmitHandler: closeCheckAttributesForm(),
  };
  let formFieldsConfig: FormFieldsConfigType = [
    [
      {
        component: GenericTypography,
        name: 'Product attributes saved in the Blockchain:',
        styleGrid: { marginBottom: 10 },
        styleLabel: { paddingBottom: 10 },
        size: 12,
      },
      {
        component: CheckAttributesList,
        list: checkAttributesResult,
      },
    ],
  ];

  if (loadingCheckAttributes)
    formFieldsConfig = [
      [
        {
          component: CircularProgress,
          size: 12,
        },
      ],
    ];

  return {
    header: modalHeader,
    content: formFieldsConfig,
    footer: modalFooter,
  };
};

export default useCheckAttributesConfirmationModalConfig;
