import React from 'react';

import useLoginSlices from 'components/Balances/Redux/Slices/useLoginSlices';
import GenericCardAndModalCombiner from 'components/GenericComponents/Dialog/GenericCardAndModalCombiner/GenericCardAndModalCombiner';
import { RightReferences } from 'enums/RightReferences';
import { WithSnackbarProps } from 'notistack';
import { withRouter } from 'utils/migrationHelper';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';

import logsIcon from '../../assets/images/icons/transactions-list.png';

import { getMonitoringActions } from './utils/actions';

type MonitoringProps = WithSnackbarProps;

const Monitoring: React.FC<MonitoringProps> = () => {
  const { user, isAuthenticated } = useLoginSlices();
  const userIsSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, user);
  const userHasMonitoringRight = checkUserHasRight(
    isAuthenticated,
    user,
    RightReferences.MONITORING
  );

  if (userIsSuperAdmin || userHasMonitoringRight) {
    const actions = getMonitoringActions(userIsSuperAdmin);
    return (
      <GenericCardAndModalCombiner
        title="Monitoring"
        actions={actions}
        icon={logsIcon}
      />
    );
  } else return <></>;
};

export default withRouter(Monitoring);
