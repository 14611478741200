import { useEffect } from 'react';

import { resetCurrentStandard } from 'actions';
import useFormStandardsSlicesCombiner from 'components/FormStandards/Redux/Slices/Combiner/useFormStandardsCombiner';
import {
  handleFormikSubmit,
  handleFormikValidation,
} from 'components/FormStandards/utils/handlers';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';

type StandardFormikProps = {
  children: (formProps: any) => JSX.Element;
  initialValues: any;
  params: any;
};

export const StandardFormik: React.FC<StandardFormikProps> = ({
  children,
  initialValues,
  params,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { productTypes } = useFormStandardsSlicesCombiner();
  const updatingStandard = Boolean(params?.standardId) || false;

  useEffect(() => {
    if (!updatingStandard) dispatch(resetCurrentStandard());
  }, [updatingStandard]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={handleFormikValidation}
      onSubmit={(values) =>
        handleFormikSubmit(
          values,
          params,
          productTypes,
          updatingStandard,
          navigate
        )(dispatch)
      }
    >
      {children}
    </Formik>
  );
};
