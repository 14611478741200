import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useDecreaseBalanceFormModalConfig from '../../Configuration/useDecreaseBalanceFormModalConfig';

const ModalFooterDecreaseBalanceForm = () => {
  const { footer } = useDecreaseBalanceFormModalConfig();

  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterDecreaseBalanceForm;
