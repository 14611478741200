import useDynamicListsSlicesCombiner from 'components/DynamicList/Redux/Slices/useDynamicListsSlicesCombiner';

import listIcon from '../../../../assets/images/icons/list.png';
import PageTitle from '../../../commons/PageTitle';

const DynamicListsToolbar = (): JSX.Element => {
  const { loading } = useDynamicListsSlicesCombiner();
  return <PageTitle title={'Lists'} loading={loading} image={listIcon} />;
};
export default DynamicListsToolbar;
