import React, { SyntheticEvent } from 'react';

import { closeNotificationOfIssuanceForm } from 'actions';
import GenericAutocompleteField from 'components/Administration/Generic/Form/Fields/Autocomplete/GenericAutocompleteField';
import GenericTextField from 'components/Administration/Generic/Form/Fields/Text/GenericTextField';
import {
  getMonthsMenu,
  getYearsMenu,
} from 'components/Administration/Generic/Form/utils/utils';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { FormikValues, useFormikContext } from 'formik';

import { FormFieldsConfigType } from '../../../../../utils/types/formFieldsTypes';
import { FooterModalPropsType } from '../../../../../utils/types/types';
import useProductsAndOrganizationsOptions from '../../../../utils/CustomHooks/useProductsAndOrganizationsOptions';
import useSeeProducts from '../CustomHooks/useSeeProducts';

const useIssuanceEmailNotificationFormModalConfig = () => {
  const { submitForm, setFieldValue, values } =
    useFormikContext<FormikValues>();
  const { organizations } = useAdministrationSlicesCombiner();
  const { seeProductsButtonClicked } = useSeeProducts();
  const { organizationsOptions } = useProductsAndOrganizationsOptions();

  //modal components
  const modalHeader = {
    title: '',
  };
  const modalFooter: FooterModalPropsType = {
    cancelLabel: 'Close',
    submitLabel: 'See products',
    onCancelHandler: closeNotificationOfIssuanceForm,
    onSubmitHandler: submitForm,
    loading: seeProductsButtonClicked,
  };
  const formFieldsConfig: FormFieldsConfigType = [
    [
      {
        component: GenericAutocompleteField,
        name: 'organization',
        label: 'Organization',
        options: organizations?.length > 0 ? organizationsOptions : [],
        selectedValue:
          organizationsOptions.find(
            (option: any) => option.value === values.organization
          ) || '',
        onChange: (_: SyntheticEvent, selectedOption: any) => {
          setFieldValue('organization', selectedOption?.value);
        },
      },
      {
        component: GenericTextField,
        name: 'year',
        label: 'Year',
        select: true,
        dynamicMenuItems: true,
        menuItemsCallback: getYearsMenu,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue('year', e.target.value),
        showErrors: false,
      },
      {
        component: GenericTextField,
        name: 'month',
        label: 'Month',
        select: true,
        dynamicMenuItems: true,
        menuItemsCallback: getMonthsMenu,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue('month', e.target.value),
        showErrors: false,
      },
    ],
  ];
  return {
    header: modalHeader,
    content: formFieldsConfig,
    footer: modalFooter,
  };
};

export default useIssuanceEmailNotificationFormModalConfig;
