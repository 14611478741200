import { Fab } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { CommodityTypes } from 'enums/CommodityTypes';
import { useNavigate } from 'react-router';
import { getStandardProductTypeName } from 'utils/datagridHelpers';

import createIcon from '../../../../assets/images/icons/create.png';

const StandardsColumns = () => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', editable: false, width: 200 },
    { field: 'standardType', headerName: 'Type', editable: false, width: 200 },
    {
      field: 'productTypeName',
      headerName: CommodityTypes.upperCase,
      editable: false,
      width: 200,
      valueGetter: getStandardProductTypeName,
    },
    {
      field: 'updateButton',
      headerName: 'Update',
      editable: false,
      width: 200,
      disableExport: true,
      renderCell: (params) => (
        <div style={{ textAlign: 'center' }}>
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            style={{ backgroundColor: '#FFFFFF' }}
            onClick={() => {
              navigate('/standards/update/' + params.row._id);
            }}
          >
            <img
              src={createIcon}
              alt={'Update standard'}
              title={'Update standard'}
              style={{ width: 22, height: 22 }}
            />
          </Fab>
        </div>
      ),
    },
  ];

  return columns;
};

export default StandardsColumns;
