import React from 'react';

import GenericModalContent from 'components/Administration/Generic/Modal/Content/GenericModalContent';

import useIssuanceEmailNotificationConfirmationModalConfig from '../../Configuration/useIssuanceEmailNotificationConfirmationModalConfig';

const ModalContentIssuanceEmailNotificationConfirmation = () => {
  const { header, content } =
    useIssuanceEmailNotificationConfirmationModalConfig();

  return <GenericModalContent header={header} content={content} />;
};

export default ModalContentIssuanceEmailNotificationConfirmation;
