/* eslint-disable */

const INITIAL_STATE = {
  myTest: false,
  loading: false,
  testId: null,
  nbOfOperationsToDo: 1,
  nbOfOperationsDone: 0,
  logs: [],
  tests: [],
};

const testReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'TEST_TOGGLE':
      return { ...state, myTest: !state.myTest };

    case 'START_TESTS':
      return { ...state, loading: true };

    case 'ADD_TEST_LOG':
      let log = action.payload;
      let existingLogs = [...state.logs];
      existingLogs.push(log);
      return { ...state, logs: existingLogs };

    case 'UPDATE_TEST_LOG':
      let logToUpdate = action.payload;
      let existingLogsToUpdate = [...state.logs];
      existingLogsToUpdate = existingLogsToUpdate.map((l) => {
        if (l.id === logToUpdate.id) return { ...l, ...logToUpdate };
        return l;
      });
      return { ...state, logs: existingLogsToUpdate };

    case 'UPDATE_TEST_LOG_STEP':
      let logToUpdateStep = action.payload;
      let newStepValues = logToUpdateStep.stepToUpdate;
      let existingLogsToUpdateStep = [...state.logs];
      let existingLogsWithStepUpdated = existingLogsToUpdateStep.map((l) => {
        //Find the log to update
        if (l.id === logToUpdateStep.id) {
          let updatedSteps = [...l.steps].map((step) => {
            //Find the step to update
            if (
              newStepValues.type === step.type &&
              newStepValues.name === step.name
            ) {
              //Update the step of log
              return { ...step, ...newStepValues };
            } else if (newStepValues.type === step.type && !step.name) {
              //Update the step of log
              return { ...step, ...newStepValues };
            }
            return step;
          });
          //Update the log with its new steps
          return { ...l, steps: updatedSteps };
        }

        return l;
      });
      return { ...state, logs: existingLogsWithStepUpdated };

    case 'END_TESTS':
      return { ...state, loading: false };

    case 'PROGRESS_TEST_LOG':
      let nbOfOperationsDone = action.payload.nbOfExecutedOperations;
      let nbOfOperationsToDo = action.payload.nbOfOperationsToExecute;
      return { ...state, nbOfOperationsToDo, nbOfOperationsDone };

    default:
      return state;
  }
};

export default testReducer;
