import { useEffect } from 'react';

import {
  getOrganizations,
  getProductsStart,
  getProductsTotalCount,
} from 'actions';
import { RightReferences } from 'enums/RightReferences';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';

import useNewOperationsSlicesCombiner from '../Slices/useNewOperationsSlicesCombiner';

const useNewOperations = () => {
  const { isAuthenticated, user } = useNewOperationsSlicesCombiner();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;

    async function initComponent() {
      if (isMounted) {
        if (
          !(
            checkUserIsSuperAdmin(isAuthenticated, user) ||
            checkUserHasRight(
              isAuthenticated,
              user,
              RightReferences.DATA_UPLOAD
            ) ||
            checkUserHasRight(isAuthenticated, user, RightReferences.ISSUE) ||
            checkUserHasRight(
              isAuthenticated,
              user,
              RightReferences.MULTIPLE_OPERATIONS
            )
          )
        ) {
          navigate('/');
        }
        await dispatch(getOrganizations());
        const _totalCount = await dispatch(getProductsTotalCount());
        await dispatch(getProductsStart(0, _totalCount));
      }
    }
    initComponent();
    return () => {
      isMounted = false;
    };
  }, [
    user,
    isAuthenticated,
    navigate,
    getOrganizations,
    getProductsTotalCount,
    getProductsStart,
  ]);
};

export default useNewOperations;
