import React, { SyntheticEvent } from 'react';

import {
  getMaxQuantityToIssueForProductStart,
  issueOriginChanged,
} from 'actions';
import { FormikValues, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const useListenersHandlers = () => {
  const dispatch: AppDispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const handleIssueCertificateOriginChange = async (
    _: SyntheticEvent,
    selectedOption: any
  ) => {
    const origin = selectedOption?.value;
    dispatch(issueOriginChanged(origin));
    const maxQuantity = await dispatch(
      getMaxQuantityToIssueForProductStart(origin, values.year, values.month)
    );
    setFieldValue('origin', origin);
    setFieldValue('volume', maxQuantity);
  };

  const handleIssueCertificateYearChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const year = event.target.value;
    const maxQuantity = await dispatch(
      getMaxQuantityToIssueForProductStart(values.origin, year, values.month)
    );
    setFieldValue('year', year);
    setFieldValue('volume', maxQuantity);
  };

  const handleIssueCertificateMonthChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const month = event.target.value;
    const maxQuantity = await dispatch(
      getMaxQuantityToIssueForProductStart(values.origin, values.year, month)
    );
    setFieldValue('month', month);
    setFieldValue('volume', maxQuantity);
  };
  return {
    handleIssueCertificateOriginChange,
    handleIssueCertificateMonthChange,
    handleIssueCertificateYearChange,
  };
};

export default useListenersHandlers;
