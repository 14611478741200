import { DefaultRightsSet } from 'constants/DefaultRightsSet';

import React, { useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { setSelectedUserRights } from 'actions';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightCategories } from 'enums/RightCategories';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { Right } from 'types/Right';
import { RightsListElement } from 'types/RightsListElement';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';

import { RightsSectionWithLabels } from './RightsSectionWithLabels';
import { RightsSimpleSection } from './RightsSimpleSection';

export default function RightsMultipleChoice() {
  const dispatch: AppDispatch = useDispatch();

  const allUserRights = useSelector(
    (state: RootState) => state.usersReducer.rights
  );
  const selectedUserRights = useSelector(
    (state: RootState) => state.usersReducer.selectedRights
  );
  const connectedUser = useSelector(
    (state: RootState) => state.loginReducer.user
  );
  const isAuthenticated = useSelector(
    (state: RootState) => state.loginReducer.isAuthenticated
  );

  const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const [expanded_modules, setExpanded_modules] = React.useState(true);
  const [expanded_operations, setExpanded_operations] = React.useState(true);
  const [expanded_admin, setExpanded_admin] = React.useState(true);

  const currentUser = useSelector(
    (state: RootState) => state.usersReducer.currentUser
  );
  const creatingUser = currentUser?.user_id ? false : true;

  //Initilize the rights list
  useEffect(() => {
    if (
      !allUserRights ||
      !Array.isArray(allUserRights) ||
      allUserRights.length < 1
    ) {
      return;
    }
    //First case : creating an new user
    if (creatingUser) {
      //Default rights must be set
      const defautlRightsWithIds = getDefaultRightsSet();
      dispatch(setSelectedUserRights(defautlRightsWithIds));
    }
    //Second case : updating a user
    else {
      const hasRights = selectedUserRights.length > 0;
      if (!hasRights) {
        //set default rights
        const defautlRightsWithIds = getDefaultRightsSet();
        dispatch(setSelectedUserRights(defautlRightsWithIds));
      }
    }
  }, [allUserRights, creatingUser]);

  const getDefaultRightsSet = () => {
    const defaultRights = DefaultRightsSet.map((defaultRight) => {
      const fullRightForRef = allUserRights.find(
        (right: Right) => right.ref === defaultRight.type.ref
      );

      if (
        fullRightForRef.category === RightCategories.MODULES &&
        defaultRight.activePermissions
      ) {
        const actualActivePermissions = [];
        for (const permission of defaultRight.activePermissions) {
          if (checkIfConnectedUserHasRight(fullRightForRef, permission)) {
            actualActivePermissions.push(permission);
          }
        }
        return {
          type: fullRightForRef,
          activePermissions: actualActivePermissions,
        };
      } else {
        if (checkIfConnectedUserHasRight(fullRightForRef))
          return {
            type: fullRightForRef,
          };
      }
    });
    return defaultRights;
  };

  if (
    !allUserRights ||
    !Array.isArray(allUserRights) ||
    allUserRights.length < 1
  )
    return false;

  const handleExpandClick = (category: RightCategories) => {
    switch (category) {
      case RightCategories.MODULES:
        setExpanded_modules(!expanded_modules);
        break;
      case RightCategories.OPERATIONS:
        setExpanded_operations(!expanded_operations);
        break;
      case RightCategories.ADMIN:
        setExpanded_admin(!expanded_admin);
        break;
    }
  };

  const checkIfConnectedUserHasRight = (
    providedRight: Right,
    providedCrudPermission?: CrudPermissions
  ) => {
    if (checkUserIsSuperAdmin(isAuthenticated, connectedUser)) return true;
    return (
      connectedUser?.user_metadata &&
      Array.isArray(connectedUser.user_metadata.rights) &&
      connectedUser.user_metadata.rights.some((r: RightsListElement) => {
        if (providedRight.category == RightCategories.MODULES) {
          if (providedCrudPermission)
            return (
              r?.type &&
              r?.activePermissions &&
              r.type._id === providedRight._id &&
              r.activePermissions.indexOf(providedCrudPermission) > -1
            );
          else return false;
        } else return r?.type && r.type._id === providedRight._id;
      })
    );
  };

  return (
    <>
      <Typography variant="body2" style={{ marginBottom: '3%', marginTop: 40 }}>
        Set user rights:
      </Typography>
      <RightsSectionWithLabels
        category={RightCategories.MODULES}
        expanded={expanded_modules}
        capitalizeFirstLetter={capitalizeFirstLetter}
        handleExpandClick={handleExpandClick}
        checkIfConnectedUserHasRight={checkIfConnectedUserHasRight}
      />
      <RightsSimpleSection
        category={RightCategories.OPERATIONS}
        expanded={expanded_operations}
        capitalizeFirstLetter={capitalizeFirstLetter}
        handleExpandClick={handleExpandClick}
        checkIfConnectedUserHasRight={checkIfConnectedUserHasRight}
      />
      <RightsSimpleSection
        category={RightCategories.ADMIN}
        expanded={expanded_admin}
        capitalizeFirstLetter={capitalizeFirstLetter}
        handleExpandClick={handleExpandClick}
        checkIfConnectedUserHasRight={checkIfConnectedUserHasRight}
      />
    </>
  );
}
