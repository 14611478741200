import { DialogContent } from '@mui/material';
import TransactionsTable from 'components/Transactions/CustomComponents/Tables/TransactionsTable';

const AggregationTransactionsContent = () => {
  return (
    <DialogContent style={{ height: 400 }}>
      <TransactionsTable />
    </DialogContent>
  );
};

export default AggregationTransactionsContent;
