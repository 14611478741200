import { useContext } from 'react';

import useCreateOrUpdateProductSlicesCombiner from 'components/CreateOrUpdateProduct/Redux/Slices/useCreateOrUpdateProductSlicesCombiner';
import GenericErrorInRed from 'components/GenericComponents/Errors/GenericErrorInRed';
import DataAcquisitionContext from 'components/ProductDataAcquisition/Context/DataAcquisitonContext';
import { getProdSourcesList } from 'components/ProductDataAcquisition/utils/utils';
import { Formik } from 'formik';

import DataAcquisitonFormContent from './Content/DataAcquisitonFormContent';
import {
  initialValuesFormHandler,
  onValidateFormHandler,
} from './handlers/handlers';
import '../../Styles/form.css';

const DataAcquisitonForm = (): JSX.Element => {
  const { addSourceHistoryItem } = useContext(DataAcquisitionContext);
  const { prodSources, currentProduct, productsFormError } =
    useCreateOrUpdateProductSlicesCombiner();

  const formikProps = {
    initialValues: initialValuesFormHandler(
      getProdSourcesList(prodSources),
      currentProduct
    ),
    validate: (values: any) => onValidateFormHandler(values),
    onSubmit: (values: any, { resetForm }: any) => {
      addSourceHistoryItem(values);
      resetForm({});
    },
  };

  if (!currentProduct?.isSourceFormVisible) return <></>;
  return (
    <div className="form" data-testid="data-acquisition-form-id">
      <Formik {...formikProps}>
        <DataAcquisitonFormContent />
      </Formik>
      <GenericErrorInRed
        condition={productsFormError && productsFormError.sourceHistory}
        errors={productsFormError}
        customKey={'sourceHistory'}
      />
    </div>
  );
};

export default DataAcquisitonForm;
