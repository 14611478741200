export type CertificateTableCellPropsType = {
  value: string;
  cancelled: boolean;
};
const CertificateTableCell = ({
  value,
  cancelled,
}: CertificateTableCellPropsType): JSX.Element => {
  return (
    <span
      style={{
        color: cancelled ? 'rgb(206, 206, 206)' : '',
        cursor: 'pointer',
      }}
    >
      {value}
    </span>
  );
};

export default CertificateTableCell;
