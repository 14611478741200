import { Periods } from 'constants/Periods';

import { MenuItem } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';

import GenericGrid from '../../../../Grid/Grid/GenericGrid';

type GenericSelectFieldPropsType = {
  name: string;
  label?: string;
  listItems?: any[];
  isStaticField?: boolean;
  style: any;
  xs?: number;
  md?: number;
  lg?: number;
};

const GenericSelectField = ({
  listItems,
  name,
  label,
  isStaticField = false,
  style,
  xs,
  md,
  lg,
}: GenericSelectFieldPropsType) => {
  return (
    <GenericGrid isItem xs={xs} md={md} lg={lg}>
      <Field
        component={TextField}
        type="text"
        name={name}
        label={label}
        select
        variant="standard"
        helperText=""
        margin="normal"
        style={style}
        InputLabelProps={{
          shrink: true,
        }}
      >
        {!isStaticField &&
          listItems &&
          listItems.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        {isStaticField &&
          Object.keys(Periods).map((periodKey) => (
            <MenuItem
              key={Periods[periodKey].value}
              value={Periods[periodKey].value}
            >
              {Periods[periodKey].label}
            </MenuItem>
          ))}
      </Field>
    </GenericGrid>
  );
};

export default GenericSelectField;
