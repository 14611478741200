import { Dialog } from '@mui/material';
import { hideConfirmReferenceFileDeletionModal } from 'actions';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import ConfirmDeletionActions from './Actions/ConfirmDeletionActions';
import ConfirmDeletionTitle from './ConfirmDeletionTitle';
import ConfirmDeletionContent from './Content/ConfirmDeletionContent';

const ConfirmDeletionDialog = () => {
  const { openConfirmReferenceFileDeletion } = useProductSlicesCombiner();
  const dispatch: AppDispatch = useDispatch();
  const handleClose = () => {
    dispatch(hideConfirmReferenceFileDeletionModal());
  };
  const modalProps: any = {
    fullScreen: false,
    open: openConfirmReferenceFileDeletion,
    'data-testid': 'confirmDeletionDialog',
    'aria-labelledby': 'responsive-dialog-title',
    onClose: handleClose,
  };

  return (
    <Dialog {...modalProps}>
      <ConfirmDeletionTitle handleClose={handleClose} />
      <ConfirmDeletionContent />
      <ConfirmDeletionActions handleClose={handleClose} />
    </Dialog>
  );
};

export default ConfirmDeletionDialog;
