import DataAcquisitonFormButton from '../Fields/Buttons/DataAcquisitonFormButton';
import FrequencyGroup from '../Fields/Frequency/Frequency';
import SourcesInfoFieldsGroup from '../Fields/Groups/SourcesInfoGroup/SourcesInfoFieldsGroup';
import { frequencyProps } from '../utils/utils';

const DataAcquisitonFormContent = () => {
  return (
    <>
      <SourcesInfoFieldsGroup />
      {frequencyProps.map((frequencyProp: any, index) => (
        <FrequencyGroup key={index} {...frequencyProp} />
      ))}
      <DataAcquisitonFormButton buttonName="Add this source" />
    </>
  );
};

export default DataAcquisitonFormContent;
