import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import { useNavigate } from 'react-router';

const CreateButton = () => {
  const navigate = useNavigate();
  return (
    <Fab
      color="primary"
      aria-label="add"
      style={{
        right: 48,
        top: 85,
        left: 'auto',
        position: 'fixed',
      }}
      onClick={() => {
        navigate('/standards/create');
      }}
    >
      <AddIcon />
    </Fab>
  );
};

export default CreateButton;
