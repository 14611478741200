import { TableCell, TableHead, TableRow } from '@mui/material';
import { TextAlign } from 'chart.js';

type NominationsHeadPropsType = {
  column_1: string;
  organization: any;
};
const NominationsHead = ({
  column_1,
  organization,
}: NominationsHeadPropsType) => {
  let organizationUnit = 'kWh';
  if (organization && organization.unit) {
    organizationUnit = organization.unit;
  }

  const columnData = [
    {
      id: column_1,
      numeric: 'false',
      disablePadding: false,
      align: 'left',
      label: column_1,
    },
    {
      id: 'Min',
      numeric: 'true',
      disablePadding: false,
      align: 'left',
      label: 'Min (' + organizationUnit + ')',
    },
    {
      id: 'Max',
      numeric: 'true',
      disablePadding: false,
      align: 'left',
      label: 'Max (' + organizationUnit + ')',
    },
    {
      id: 'Requested',
      numeric: 'true',
      disablePadding: false,
      align: 'left',
      label: 'Nominated (' + organizationUnit + ')',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {columnData.map((column) => {
          return (
            <TableCell
              key={column.id}
              style={{
                textAlign: column.align as TextAlign,
                fontWeight: 'bold',
              }}
              padding={column.disablePadding ? 'none' : 'normal'}
            >
              {column.label}
            </TableCell>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
};

export default NominationsHead;
