import { Buffer } from 'buffer';

import axios from 'axios';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { checkUserHasRight } from 'utils/user/checkRights';

import { getUser } from '../actions/UsersActions';
import { openErrorSnack } from '../actions/UtilsActions';

window.Buffer = Buffer;

export default class Auth0 {
  accessToken;
  expiresAt;
  userProfile;
  tokenId;

  constructor() {
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.setSession = this.setSession.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getUserProfile = this.getUserProfile.bind(this);
    this.getTokenExpirationDate = this.getTokenExpirationDate.bind(this);
    this.cleanSession = this.cleanSession.bind(this);
    this.redirectAfterLogin = this.redirectAfterLogin.bind(this);
    this.logOutOfAuth0AndReturnToUrl =
      this.logOutOfAuth0AndReturnToUrl.bind(this);
  }

  getUserId(user) {
    let ca = user.token.id_token;
    let base64Url = ca.split('.')[1];
    let decodedData = Buffer.from(base64Url, 'base64').toString('ascii');

    let decodedValue = JSON.parse(decodedData);
    let user_id = decodedValue.sub;

    return user_id;
  }

  getAccessToken() {
    return this.accessToken;
  }

  getUserProfile() {
    return this.userProfile;
  }

  getTokenExpirationDate() {
    return this.expiresAt;
  }

  getTokenId() {
    return this.tokenId;
  }

  login(context) {
    if (context === 'auth')
      window.location = `${process.env.REACT_APP_API_URL}/auth/users/login`;
  }

  handleAuthentication(authData, navigate, location, dispatch) {
    if (authData && authData.type === 'auth0') {
      if (
        authData.token.access_token &&
        authData.user &&
        authData.user.app_metadata &&
        authData.user.app_metadata.authorized &&
        !authData.user.user_metadata.organization?.archived
      ) {
        this.setSession(authData, navigate, location, dispatch);
      } else {
        dispatch(openErrorSnack('Unauthorized user'));
        navigate('/unauthorized');
      }
    } else {
      navigate('/');
    }
  }

  async redirectAfterLogin(
    user,
    navigate,
    location,
    authData,
    alreadyAuthenticated
  ) {
    // navigate to the home route
    let hasGetBalances = checkUserHasRight(
      alreadyAuthenticated,
      user,
      RightReferences.BALANCES,
      [CrudPermissions.VIEW]
    );
    let hasGetCertificates = checkUserHasRight(
      alreadyAuthenticated,
      user,
      RightReferences.CERTIFICATES,
      [CrudPermissions.VIEW]
    );
    let hasGetTransactions = checkUserHasRight(
      alreadyAuthenticated,
      user,
      RightReferences.TRANSACTIONS,
      [CrudPermissions.VIEW]
    );
    let hasGetProducts = checkUserHasRight(
      alreadyAuthenticated,
      user,
      RightReferences.PRODUCTS,
      [CrudPermissions.VIEW]
    );
    let hasGetOrganizations = checkUserHasRight(
      alreadyAuthenticated,
      user,
      RightReferences.ORGANIZATIONS,
      [CrudPermissions.VIEW]
    );
    let hasGetUsers = checkUserHasRight(
      alreadyAuthenticated,
      user,
      RightReferences.USERS,
      [CrudPermissions.VIEW]
    );

    if (
      alreadyAuthenticated &&
      location.pathname.toString() !== '/get-user-on-authentication' &&
      location.pathname.toString() !== '/'
    ) {
      navigate(location.pathname);
    } else {
      if (authData.user.roles.includes('independant')) {
        if (hasGetBalances) {
          navigate('/balances');
        } else if (hasGetCertificates) {
          navigate('/certificates');
        } else if (hasGetTransactions) {
          navigate('/transactions');
        } else if (hasGetProducts) {
          navigate('/products');
        } else if (hasGetOrganizations) {
          navigate('/organizations');
        } else if (hasGetUsers) {
          navigate('/users');
        }
      } else {
        navigate('/operations');
      }
    }
  }

  async setSession(authData, navigate, location, dispatch) {
    let alreadyAuthenticated = false;

    if (
      localStorage.getItem('isAuthenticated') === 'true' &&
      localStorage.getItem('access_token') &&
      this.isAuthenticated()
    ) {
      alreadyAuthenticated = true;
    }
    // Set the time that the access token will expire at
    const username = authData.user.user_metadata.username;
    const expiresAt = authData.token.expires_in * 1000 + new Date().getTime();
    this.accessToken = authData.token.access_token;
    this.expiresAt = expiresAt;
    this.userProfile = authData.user;

    // Set isAuthenticated flag in localStorage
    localStorage.setItem('isAuthenticated', true);
    localStorage.setItem('access_token', this.accessToken);
    localStorage.setItem('expires_at', this.expiresAt);
    let auth0UserId = this.getUserId(authData);
    let userId = auth0UserId.split('|')[1];
    let user = await dispatch(getUser(userId));
    user.roles = user.app_metadata.roles;

    dispatch({
      type: 'LOGIN_SUCCESS',
      payload: { username, isAuthenticated: true, user: user },
    });

    this.redirectAfterLogin(
      user,
      navigate,
      location,
      authData,
      alreadyAuthenticated
    );
  }

  logOutOfAuth0AndReturnToUrl(returnToUrl) {
    window.location.href = `https://${process.env.REACT_APP_AUTH_DOMAIN}/v2/logout?returnTo=${returnToUrl}&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}`;
  }

  renewSession(cookies, navigate, location, dispatch) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/users/profile`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (res.data && res.data.type === 'auth0') {
          this.setSession(res.data, navigate, location, dispatch);
        } else {
          throw new Error('Error in renew session, logout is required');
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 401) {
          this.logout(cookies, false, dispatch);
        }
        navigate('/');
      });
  }

  async logout(cookies, withAlert, dispatch) {
    if (this.tokenId) {
      try {
        await axios.get(`${process.env.REACT_APP_API_URL}/auth/admin/logout`, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error', error);
      }
    } else {
      try {
        await axios.get(`${process.env.REACT_APP_API_URL}/auth/users/logout`, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error', error);
      }
    }

    this.cleanSession(cookies);
    dispatch({ type: 'LOGOUT_START' });

    const returnToUrl = `${window.location.origin}`;

    if (withAlert) {
      var r = window.confirm('Logout done, do we redirect ?');
      if (r === true) {
        this.logOutOfAuth0AndReturnToUrl(returnToUrl);
      }
    } else {
      this.logOutOfAuth0AndReturnToUrl(returnToUrl);
    }
  }

  cleanSession(cookies) {
    this.accessToken = null;
    this.expiresAt = 0;
    this.userProfile = null;
    localStorage.removeItem('socketId');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    if (cookies) {
      cookies.remove('session');
      cookies.remove('session.sig');
    }
    localStorage.clear();
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    if (
      this.expiresAt === undefined &&
      localStorage.getItem('expires_at') !== null
    ) {
      this.expiresAt = localStorage.getItem('expires_at');
    }
    return new Date().getTime() < this.expiresAt;
  }

  setUserProfile(user) {
    this.userProfile = user;
  }
}
