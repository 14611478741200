export const getError = (
  value: number,
  minValue: number,
  maxValue: number,
  type: string
) => {
  if (value !== null && isNaN(value)) {
    return `${type.charAt(0).toUpperCase() + type.slice(1)} must be a number`;
  }
  if (type === 'min' && minValue > maxValue) {
    return 'Min > Max!';
  }
  if (type === 'max' && maxValue < minValue) {
    return 'Max < Min!';
  }
  if (type === 'requested' && (value < minValue || value > maxValue)) {
    return 'Min < Requested < Max';
  }
  return '';
};
