import { useEffect, useState } from 'react';

import { Box, Grid, Link, Typography } from '@mui/material';

import { default as multiUploadExample } from '../../../../../../assets/sheets/multi_upload_example.xlsx';
import { GenericUploadLinkPropsType } from '../../utils/types/types';

const GenericUploadLink = ({
  noDocumentFoundLabel = 'No document found',
  gridItemSize = 6,
  boxWrapperStyle = { marginTop: 16, minWidth: 200 },
  boxChildStyle = { paddingTop: 0 },
  failedUploadStyle = { marginTop: 23 },
  documentToUploadRef,
  documentToUpload,
  linkExampleToDisplay = false,
}: GenericUploadLinkPropsType) => {
  const [xlsxDoc, setXlsxDoc] = useState<any>(multiUploadExample);
  useEffect(() => {
    documentToUploadRef && setXlsxDoc(documentToUploadRef);
  }, [documentToUploadRef]);

  return (
    <Grid item xs={gridItemSize}>
      {documentToUploadRef && (
        <Box style={boxWrapperStyle}>
          <Link href={documentToUploadRef} underline="hover" target="_blank">
            <Box style={boxChildStyle}>
              <Typography color="inherit" variant="caption">
                {documentToUpload?.name}
              </Typography>
            </Box>
          </Link>
        </Box>
      )}
      {!documentToUploadRef && (
        <Typography color="inherit" variant="caption" style={failedUploadStyle}>
          {noDocumentFoundLabel}
        </Typography>
      )}
      {linkExampleToDisplay && (
        <Link href={xlsxDoc} underline="hover" target="_blank">
          <Box style={{ paddingTop: 0 }}>
            <Typography color="inherit" variant="caption">
              See Excel dataset example
            </Typography>
          </Box>
        </Link>
      )}
    </Grid>
  );
};

export default GenericUploadLink;
