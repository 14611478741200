import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import AppRoutesList from './AppRoutesList';
import ConnectionLost from './components/ConnectionLost/ConnectionLost';
import Offline from './components/Offline/Offline';

function AppRoutes({ props }) {
  let connectionOk = useSelector((state) => state.utilsReducer.connectionOk);

  const [isOnline, setOnline] = useState(true);
  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener('online', () => {
    setOnline(true);
  });

  window.addEventListener('offline', () => {
    setOnline(false);
  });

  if (!isOnline) return <Offline />;
  if (!connectionOk) return <ConnectionLost />;

  return <AppRoutesList props={props} />;
}

export default AppRoutes;
