import React from 'react';

import useNominationsChangeHandlers from '../../Redux/CustomHooks/useNominationsChangeHandlers';
import useNominationsGuards from '../../Redux/CustomHooks/useNominationsGuards';
import useNominationsSlicesCombiner from '../../Redux/Slices/useNominationsSlicesCombiner';

import OrganizationSelect from './OrganizationSelect';
import '../../Styles/nominations.css';

const OrganizationSelectors = () => {
  const { sellerOrganizations } = useNominationsSlicesCombiner();
  const { canManageMultipleOrganizationNominations } = useNominationsGuards();
  return (
    <div className="organization-select-container">
      {canManageMultipleOrganizationNominations && <BuyerSelector />}
      {sellerOrganizations.length > 0 && <SellerSelector />}
    </div>
  );
};

export default React.memo(OrganizationSelectors);

const BuyerSelector = React.memo(() => {
  const { buyerOrganization, independantsWithNominationsFeature } =
    useNominationsSlicesCombiner();
  const { handleBuyerOrganizationChange } = useNominationsChangeHandlers();
  return (
    <OrganizationSelect
      id="select-buyerOrganization"
      label="Buyer"
      value={
        buyerOrganization?._id || independantsWithNominationsFeature[0]?._id
      }
      onChange={handleBuyerOrganizationChange}
      options={independantsWithNominationsFeature}
      style={{ width: 250 }}
      variant="outlined"
    />
  );
});

BuyerSelector.displayName = 'BuyerSelector';

const SellerSelector = React.memo(() => {
  const { selectedSellerOrganization, sellerOrganizations } =
    useNominationsSlicesCombiner();
  const { handleSellerOrganizationChange } = useNominationsChangeHandlers();

  return (
    <OrganizationSelect
      id="select-issuer-buyerOrganization"
      label="Seller"
      value={selectedSellerOrganization?._id || ''}
      onChange={handleSellerOrganizationChange}
      options={sellerOrganizations}
      style={{ width: 250 }}
      variant="outlined"
    />
  );
});

SellerSelector.displayName = 'SellerSelector';
