export const constructAggregationContextValue = (
  sourceName: any,
  tableTitle: string,
  dynamicDataSourcesToFilterOn: any[] | undefined
) => {
  return {
    source: sourceName,
    title: tableTitle,
    dynamicDataSourcesToFilterOn,
    fileUrl:
      sourceName === 'GALILEO'
        ? `${process.env.REACT_APP_API_URL}/api/files/s3`
        : `${process.env.REACT_APP_API_URL}/api/files`,
  };
};
