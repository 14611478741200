import React from 'react';

import Snackbar from '@mui/material/Snackbar';

export default class InformationPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: true,
    };
  }

  handleClose() {
    this.setState({
      opened: false,
    });
  }

  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.opened}
          autoHideDuration={3000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={this.props.message}
          onClose={() => {
            this.handleClose();
          }}
        />
      </div>
    );
  }
}
