import {
  setOrganizationsInAllocateMenuFiltredForSuperAdmin,
  setOrganizationsInTransferMenuFiltredForSuperAdmin,
} from '../../../../actions';
import { enums } from '../../../../enums';
import { sortOrganizationsByName } from '../../../../utils/organization/sortOrganizations';
import { checkUserIsSuperAdmin } from '../../../../utils/user/checkAdmin/CheckAdmin';

const checkOrganizationsInTransferOrAllocateMenuFilteredForSuperAdmin = (
  organizationsForSuperAdmin
) => {
  return (
    organizationsForSuperAdmin &&
    Array.isArray(organizationsForSuperAdmin) &&
    organizationsForSuperAdmin.length > 0 &&
    organizationsForSuperAdmin[0]
  );
};

export const prepareModalFormsDataForTransfer = (
  _origin,
  _organizationsInTransferMenu,
  _to,
  businessOrganizations,
  organizationsInTransferMenuFiltredForSuperAdmin,
  user,
  isAuthenticated,
  dispatch
) => {
  if (checkUserIsSuperAdmin(isAuthenticated, user)) {
    let productOwner = _origin?.organization;
    let productOwnerBusinessOrganizations = productOwner?.businessOrganizations;
    let productOwnerBusinessOrganizationsIds =
      productOwnerBusinessOrganizations?.map((o) => o.toString());

    _organizationsInTransferMenu = _organizationsInTransferMenu.filter(
      (organization) => {
        let organizationId = organization?._id?.toString();
        let organizationType = organization.type;
        let isOrganizationABusinessOrganizationOfTheProductOwner =
          productOwnerBusinessOrganizationsIds.indexOf(organizationId) >= 0;
        //We keep :
        //business organization of the owner of the product
        return (
          isOrganizationABusinessOrganizationOfTheProductOwner &&
          //We remove Attributes (ex TEO)
          organizationType !== enums.OrganizationTypes.TEO
        );
      }
    );
    businessOrganizations.forEach((bO) => {
      _organizationsInTransferMenu.push(bO);
    });
    _organizationsInTransferMenu = sortOrganizationsByName(
      _organizationsInTransferMenu
    );

    dispatch(
      setOrganizationsInTransferMenuFiltredForSuperAdmin(
        _organizationsInTransferMenu
      )
    );
  }

  let _toOrganization =
    checkOrganizationsInTransferOrAllocateMenuFilteredForSuperAdmin(
      organizationsInTransferMenuFiltredForSuperAdmin
    )
      ? organizationsInTransferMenuFiltredForSuperAdmin[0]
      : _to;

  return _toOrganization;
};

export const prepareModalFormsDataForAllocation = (
  _origin,
  _organizationsInAllocateMenu,
  _to,
  organizationsInAllocateMenuFiltredForSuperAdmin,
  user,
  isAuthenticated,
  dispatch
) => {
  if (checkUserIsSuperAdmin(isAuthenticated, user)) {
    let productOwner = _origin?.organization;
    let productOwnerId = productOwner?._id?.toString();
    let productOwnerBusinessOrganizations = productOwner?.businessOrganizations;
    let productOwnerBusinessOrganizationsIds =
      productOwnerBusinessOrganizations?.map((o) => o.toString());

    _organizationsInAllocateMenu = _organizationsInAllocateMenu.filter(
      (organization) => {
        let organizationId = organization?._id?.toString();
        let organizationType = organization.type;

        let isProductOwnerTheCurrentIndependantOrganization =
          organizationId === productOwnerId;

        let isOrganizationABusinessOrganizationOfTheProductOwner =
          productOwnerBusinessOrganizationsIds.indexOf(organizationId) >= 0;

        return (
          //We keep :
          //Independant and owner of the product
          (isProductOwnerTheCurrentIndependantOrganization ||
            //Or business organization of the owner of the product)
            isOrganizationABusinessOrganizationOfTheProductOwner) &&
          //We remove Attributes (ex TEO)
          organizationType !== enums.OrganizationTypes.TEO
        );
      }
    );
    _organizationsInAllocateMenu = sortOrganizationsByName(
      _organizationsInAllocateMenu
    );
    dispatch(
      setOrganizationsInAllocateMenuFiltredForSuperAdmin(
        _organizationsInAllocateMenu
      )
    );
  }

  let _toOrganization =
    checkOrganizationsInTransferOrAllocateMenuFilteredForSuperAdmin(
      organizationsInAllocateMenuFiltredForSuperAdmin
    )
      ? organizationsInAllocateMenuFiltredForSuperAdmin[0]
      : _to;

  return _toOrganization;
};
