import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useCheckAttributesConfirmationModalConfig from '../../Configuration/Configuration/useCheckAttributesConfirmationModalConfig';

const ModalFooterCheckAttributesConfirmation = () => {
  const { footer } = useCheckAttributesConfirmationModalConfig();
  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterCheckAttributesConfirmation;
