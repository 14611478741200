/* eslint-disable */
import Paper from '@mui/material/Paper';
import { UseDynamicListsSlicesCombinerType } from 'components/DynamicList/Redux/CustomHooks/types';
import useDynamicListsSlicesCombiner from 'components/DynamicList/Redux/Slices/useDynamicListsSlicesCombiner';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';
import DynamicListItemsToolbar from './CustomComponents/Toolbar/DynamicListItemsToolbar';
import DynamicListItemsTable from './CustomComponents/Table/DynamicListItemsTable';
import ConfirmDeletionDialog from './CustomComponents/Dialog/ConfirmDeletionDialog/ConfirmDeletionDialog';
import UploadDialog from './CustomComponents/Dialog/UploadDialog/UploadDialog';

export type DynamicListItemsPropsType = {
  userCanEditDynamicListItems?: boolean;
  userCanReadDynamicListItems?: boolean;
};
const DynamicListItems = ({
  userCanEditDynamicListItems,
  userCanReadDynamicListItems,
}: DynamicListItemsPropsType): JSX.Element => {
  const { currentDynamicList }: UseDynamicListsSlicesCombinerType =
    useDynamicListsSlicesCombiner();

  if (!userCanReadDynamicListItems) return <></>;
  const items = currentDynamicList?.items;
  if (
    (!items ||
      (Array.isArray(items) && items.length < 1) ||
      !userCanReadDynamicListItems) &&
    !userCanEditDynamicListItems
  )
    return <></>;

  return (
    <GenericGrid isItem xsLength={12} data-testid="dynamic-list-items-card-id">
      <div style={{ paddingBottom: 63, paddingLeft: 0, paddingRight: 0 }}>
        <DynamicListItemsToolbar
          userCanEditDynamicListItems={true}
          userCanReadDynamicListItems={true}
        />
        <DynamicListItemsTable />
        <ConfirmDeletionDialog />
        <UploadDialog />
      </div>
    </GenericGrid>
  );
};

export default DynamicListItems;
