/* eslint-disable */

import Auth from '../auth/Auth';
import { apiPut } from './UtilsActions';

export const auth = new Auth();

export const auth0Login = () => (dispatch) => {
  auth.login('auth');
};

export const handleAuthenticationAuth0 =
  (navigate, location, authData) => (dispatch) => {
    auth.handleAuthentication(authData, navigate, location, dispatch);
  };

export const renewSession =
  (cookies, navigate, location) => async (dispatch) => {
    auth.renewSession(cookies, navigate, location, dispatch);
  };

export const checkAuthentication =
  (navigate, location, cookies) => async (dispatch) => {
    if (
      localStorage.getItem('isAuthenticated') === 'true' &&
      localStorage.getItem('access_token') &&
      auth.isAuthenticated()
    ) {
      auth.renewSession(cookies, navigate, location, dispatch);
    } else {
      dispatch({ type: 'CHECK_AUTHENTICATION_FAIL' });
      await localStorage.clear();
      navigate('/');
    }
  };

export const getCurrentUserOrganization = () => {
  const user = auth.getUserProfile();
  const userOrganization = user?.user_metadata?.organization
    ? user?.user_metadata?.organization
    : null;
  return userOrganization;
};

export const logoutStart = (cookies, withAlert) => async (dispatch) => {
  await auth.logout(cookies, withAlert, dispatch);
};

export const setUserConsent = (user, isConsentGiven) => async (dispatch) => {
  const body = { consentGiven: isConsentGiven };
  let response = await apiPut('/api/users/consent/' + user.user_id, body);

  if (response.status >= 200 && response.status <= 299) {
    const updatedUser = await response.json();
    //update the state of user consent in the global store
    let username = user.user_metadata.username;
    user.user_metadata.consentGiven = isConsentGiven;
    dispatch({
      type: 'LOGIN_SUCCESS',
      payload: { username, isAuthenticated: true, user: user },
    });

    return updatedUser;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};
