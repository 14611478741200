import { useSelector } from 'react-redux';
import { UtilsReducerStateType } from 'reducers/utilsReducer/types';
import { RootState } from 'redux/store';

const useUtilsSlices = (): UtilsReducerStateType => {
  //Redux Utils slices
  const { ozOrGramsQuantityUnit, loadingCo2 }: UtilsReducerStateType =
    useSelector((state: RootState) => state.utilsReducer);
  return { ozOrGramsQuantityUnit, loadingCo2 };
};

export default useUtilsSlices;
