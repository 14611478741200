import React from 'react';

import GenericModalContent from 'components/Administration/Generic/Modal/Content/GenericModalContent';

import useIssueCertificatesInBulkConfirmationModalConfig from '../../Configuration/useIssueCertificatesInBulkSecondModalConfig';

const ModalContentIssueCertificatesSecondInBulkForm = () => {
  const { header, content } =
    useIssueCertificatesInBulkConfirmationModalConfig();

  return <GenericModalContent header={header} content={content} />;
};

export default ModalContentIssueCertificatesSecondInBulkForm;
