import AggregationCardContext from 'components/Product/Context/AggregationCardContext';
import { useProductAggregations } from 'components/Product/Redux/CustomHooks/useProductAggregations';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { prepareDynamicDataWithFilterOption } from 'components/Product/utils/utils';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { DefaultProductType } from 'reducers/productsReducer/types';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';

import AggregationContent from './AggregationContent/AggregationContent';
import { constructAggregationContextValue } from './utils/utils';

const AggregationCard = () => {
  useProductAggregations();
  const { isAuthenticated, user, currentProduct } = useProductSlicesCombiner();
  const { dynamicData, dynamicDataSourcesToFilterOn } =
    prepareDynamicDataWithFilterOption(currentProduct as DefaultProductType);

  if (
    !checkUserIsSuperAdmin(isAuthenticated, user) &&
    !checkUserHasRight(isAuthenticated, user, RightReferences.PRODUCTS, [
      CrudPermissions.VIEW,
    ])
  ) {
    return <></>;
  }

  if (!dynamicData || !Array.isArray(dynamicData)) return <></>;
  return (
    <>
      {dynamicData.map(({ sourceName, tableTitle }, index) => {
        return (
          <AggregationCardContext.Provider
            key={index}
            value={constructAggregationContextValue(
              sourceName,
              tableTitle,
              dynamicDataSourcesToFilterOn
            )}
          >
            <AggregationContent />
          </AggregationCardContext.Provider>
        );
      })}
    </>
  );
};

export default AggregationCard;
