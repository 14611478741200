import { CircularProgress } from '@mui/material';
import useFormStandardsSlicesCombiner from 'components/FormStandards/Redux/Slices/Combiner/useFormStandardsCombiner';
import useNominationsSlicesCombiner from 'components/Nominations/Redux/Slices/useNominationsSlicesCombiner';

type LoadingPropsType = {
  size?: number;
  style?: any;
};

const Loading = ({ size, style }: LoadingPropsType): JSX.Element => {
  const { loadingStandard } = useFormStandardsSlicesCombiner();
  const { loading } = useNominationsSlicesCombiner();

  if (!loadingStandard || !loading) return <></>;
  return <CircularProgress size={size} style={style} />;
};

export default Loading;
