import useProductsSlicesCombiner from 'components/Products/Redux/Slices/useProductsSlicesCombiner';

import productIcon from '../../../../assets/images/icons/product.png';
import PageTitle from '../../../commons/PageTitle';

const ProductsToolbar = (): JSX.Element => {
  const { loading } = useProductsSlicesCombiner();
  return <PageTitle title={'Products'} loading={loading} image={productIcon} />;
};
export default ProductsToolbar;
