import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';

import businessReportIcon from '../../../assets/images/icons/business-report.png';
import organizationsIcon from '../../../assets/images/icons/company.png';
import dateToIcon from '../../../assets/images/icons/date-to.png';
import productIcon from '../../../assets/images/icons/product.png';
import scheduleIcon from '../../../assets/images/icons/schedule.png';
import settingsIcon from '../../../assets/images/icons/settings.png';
import certificateIcon from '../../../assets/images/icons/standard.png';
import testsIcon from '../../../assets/images/icons/to-do2.png';
import logsIcon from '../../../assets/images/icons/transactions-list.png';
import transactionsIcon from '../../../assets/images/icons/transactions2.png';
import usersIcon from '../../../assets/images/icons/user-groups.png';
import walletIcon from '../../../assets/images/icons/wallet2.png';
import { enums } from '../../../enums/';
import { LinkType } from '../types/LinkType';

const independantLinks: LinkType[] = [
  {
    to: '/transactions',
    icon: transactionsIcon,
    label: 'Transactions',
    onlyForRight: [
      {
        ref: RightReferences.TRANSACTIONS,
        permissions: [CrudPermissions.VIEW],
      },
    ],
    children: [],
  },
  {
    to: '/nominations',
    icon: scheduleIcon,
    label: 'Contracted volumes',
    onlyForRight: [
      {
        ref: RightReferences.NOMINATIONS,
        permissions: [CrudPermissions.VIEW],
      },
    ],
    hideInPlatinumUi: true,
    children: [],
  },
  {
    to: '/certificates',
    icon: certificateIcon,
    label: 'Proofs of retirement',
    onlyForRight: [
      {
        ref: RightReferences.CERTIFICATES,
        permissions: [CrudPermissions.VIEW],
      },
    ],
    children: [],
  },
  {
    to: '/products',
    icon: productIcon,
    label: 'Products',
    onlyForRight: [
      {
        ref: RightReferences.PRODUCTS,
        permissions: [CrudPermissions.VIEW],
      },
    ],
    children: [],
  },
  {
    to: '/balances',
    icon: walletIcon,
    label: 'Balances',
    onlyForRight: [
      {
        ref: RightReferences.BALANCES,
        permissions: [CrudPermissions.VIEW],
      },
    ],
    children: [],
  },
  {
    to: '/reports',
    icon: businessReportIcon,
    label: 'Reports',
    onlyForRight: [
      {
        ref: RightReferences.REPORTS,
        permissions: [CrudPermissions.VIEW],
      },
    ],
    children: [],
  },
  {
    to: '/organizations',
    icon: organizationsIcon,
    label: 'Organizations',
    onlyForRight: [
      {
        ref: RightReferences.ORGANIZATIONS,
        permissions: [CrudPermissions.VIEW],
      },
    ],
    children: [],
  },
  {
    to: '/users',
    icon: usersIcon,
    label: 'Users',
    onlyForRight: [
      {
        ref: RightReferences.USERS,
        permissions: [CrudPermissions.VIEW],
      },
    ],
    children: [],
  },
  {
    to: '/administration',
    icon: settingsIcon,
    label: 'Administration',
    onlyForRight: [
      {
        ref: RightReferences.ADMINISTRATION,
        permissions: [],
      },
    ],
    children: [],
  },
  {
    to: '/operations',
    icon: dateToIcon,
    label: 'Operations',
    onlyForRight: [
      {
        ref: RightReferences.DATA_UPLOAD,
        permissions: [],
      },
      {
        ref: RightReferences.ISSUE,
        permissions: [],
      },
      {
        ref: RightReferences.MULTIPLE_OPERATIONS,
        permissions: [],
      },
    ],
    children: [],
  },
  {
    to: '/monitoring',
    icon: logsIcon,
    label: 'Monitoring',
    onlyForRight: [
      {
        ref: RightReferences.MONITORING,
        permissions: [],
      },
    ],
    children: [],
  },
];

if (process.env.REACT_APP_NODE_ENV === enums.EnvironmentTypes.DEVELOPMENT)
  independantLinks.push({
    to: '/tests',
    icon: testsIcon,
    label: 'Tests',
    onlyForRight: [
      {
        ref: RightReferences.TEST,
        permissions: [],
      },
    ],
    children: [],
  });

export { independantLinks };
