import { useSelector } from 'react-redux';
import { DynamicListsReducerStateType } from 'reducers/dynamicListsReducer/types';
import { RootState } from 'redux/store';

const useDynamicListsSlices = (): DynamicListsReducerStateType => {
  //Redux DynamicList slices
  const {
    dynamicList,
    currentDynamicList,
    dynamicLists,
    error,
    loadingDynamicLists,
    loadingDynamicList,
    loadingDynamicListItems,
    formIsBeingFilledOut,
    uploadingFile,
    dynamicListItems,
    localDynamicListItems,
    openConfirmDynamicListItemDeletion,
    dynamicListItemToDelete,
    deletingDynamicListItemIds,
    dynamicListItemsGridApiRef,
    openUploadDynamicListItemModal,
  }: DynamicListsReducerStateType = useSelector(
    (state: RootState) => state.dynamicListsReducer
  );
  return {
    dynamicList,
    currentDynamicList,
    dynamicLists,
    error,
    loadingDynamicLists,
    loadingDynamicList,
    loadingDynamicListItems,
    formIsBeingFilledOut,
    uploadingFile,
    dynamicListItems,
    localDynamicListItems,
    openConfirmDynamicListItemDeletion,
    dynamicListItemToDelete,
    deletingDynamicListItemIds,
    dynamicListItemsGridApiRef,
    openUploadDynamicListItemModal,
  };
};

export default useDynamicListsSlices;
