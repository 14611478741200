import { red, green } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

let theme = createTheme();

export const listStyles = createTheme(theme, {
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  buttonProgress: {
    color: green[500],
    position: 'relative',
    bottom: -30,
    left: 100,
    marginTop: -12,
    marginLeft: -12,
  },
  upToDateCell: {
    color: green[500],
  },
  deprecatedCell: {
    color: red[400],
  },
  pendingCell: {
    color: '#0000008A',
  },
  defaultCell: {
    color: '#000000DE',
  },
});
