import { useSelector } from 'react-redux';
import { BalancesReducerStateType } from 'reducers/balancesReducer/types';
import { RootState } from 'redux/store';

const useBalancesSlices = (): BalancesReducerStateType => {
  //Redux Balances slices
  const {
    loading,
    customButtonPressed,
    showDateFilter,
    startDate,
    endDate,
    fromStartButtonPressed,
    selectedStandardId,
    loadingTransferCertificate,
    transferCertificatesData,
    allocateCertificatesData,
    allocateCertificatesFormOpen,
    allBalances,
    balances,
    displayAllocateCertificateFormInDialog,
    transferCertificatesFormOpen,
    displayTransferCertificateFormInDialog,
    allocateCertificatesFormError,
    selectedBalanceData,
    transferCertificatesFormError,
    selectedStandardType,
    selectedProductTypeId,
    dialogEndDateOpen,
    dialogStartDateOpen,
    displayAllocateCertificateWarningInDialog,
    displayTransferCertificateWarningInDialog,
    infoOpen,
    loadingAllocateCertificate,
    page,
    rowsPerPage,
    openProductDialog,
    totalCount,
    pageSize,
    allBalancesCount,
    paginatedBalancesCount,
    defaultBalancesPaginationThreshold,
  }: BalancesReducerStateType = useSelector(
    (state: RootState) => state.balancesReducer
  );

  return {
    loading,
    customButtonPressed,
    showDateFilter,
    startDate,
    endDate,
    fromStartButtonPressed,
    selectedStandardId,
    loadingTransferCertificate,
    transferCertificatesData,
    allocateCertificatesData,
    allocateCertificatesFormOpen,
    allBalances,
    balances,
    displayAllocateCertificateFormInDialog,
    transferCertificatesFormOpen,
    displayTransferCertificateFormInDialog,
    allocateCertificatesFormError,
    selectedBalanceData,
    transferCertificatesFormError,
    selectedStandardType,
    selectedProductTypeId,
    dialogEndDateOpen,
    dialogStartDateOpen,
    displayAllocateCertificateWarningInDialog,
    displayTransferCertificateWarningInDialog,
    infoOpen,
    loadingAllocateCertificate,
    page,
    rowsPerPage,
    openProductDialog,
    totalCount,
    pageSize,
    allBalancesCount,
    paginatedBalancesCount,
    defaultBalancesPaginationThreshold,
  };
};

export default useBalancesSlices;
