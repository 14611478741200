import { DialogTitle } from '@mui/material';
import useCertificesSlicesCombiner from 'components/Certificates/Redux/Slices/useCertificatesSlicesCombiner';

import lockIcon from '../../../../../../../assets/images/icons/padlock.png';

const SecretWordDialogTitle = () => {
  const { loadingSecretWord } = useCertificesSlicesCombiner();
  return (
    <DialogTitle>
      {loadingSecretWord && 'Loading Encrypted data...'}
      {!loadingSecretWord && (
        <div>
          <img
            src={lockIcon}
            alt={'Encrypted data'}
            title={'Encrypted data'}
            style={{ width: 16, height: 16, marginRight: 20 }}
          />
          Encrypted data
        </div>
      )}
    </DialogTitle>
  );
};

export default SecretWordDialogTitle;
