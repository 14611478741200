import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartJSTooltip,
  Legend,
} from 'chart.js';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';

import { BalanceChartOptionsType, BalanceChartPropsType } from './types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartJSTooltip,
  Legend
);

const BalanceChart = ({
  balancesByMonthWithUnit,
  labels,
  openOrRetire,
}: BalanceChartPropsType): JSX.Element => {
  if (!balancesByMonthWithUnit || balancesByMonthWithUnit?.length <= 0)
    return <></>;

  let unitToDisplay = '';
  let datasetsLabel = '';
  let chartBackgroundColor = '#FFFFFF';
  let chartGridLinesColor = 'rgba(255, 255, 255, .3)';
  let dataSet = [];
  switch (openOrRetire) {
    case 'open':
      unitToDisplay = balancesByMonthWithUnit.quantityUnit;
      dataSet = balancesByMonthWithUnit.quantityValues;
      datasetsLabel = 'Monthly open balances';
      chartBackgroundColor = 'linear-gradient(60deg, #21453C, #162F29)';
      chartGridLinesColor = 'rgba(255, 255, 255, .1)';
      break;

    case 'retire':
      unitToDisplay = balancesByMonthWithUnit.allocatedUnit;
      dataSet = balancesByMonthWithUnit.allocatedQuantityValues;
      datasetsLabel = 'Monthly retired quantity';
      chartBackgroundColor = 'linear-gradient(60deg, #CFDFD9, #BFCFC9)';
      chartGridLinesColor = 'rgba(255, 255, 255, .3)';
      break;

    default:
      break;
  }

  labels = labels.map((label) => {
    return moment(label, 'YYYY-MM').format('MMM-YYYY');
  });

  const data = {
    labels,
    datasets: [
      {
        label: datasetsLabel,
        backgroundColor: '#ffffff',
        borderColor: '#ffffff',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255, 255, 255,.7)',
        hoverBorderColor: 'rgba(255, 255, 255,.7)',
        barPercentage: 0.4,
        data: dataSet,
      },
    ],
  };

  const options: BalanceChartOptionsType = {
    maintainAspectRatio: false,
    responsive: true,
    layout: { padding: 0 },
    scales: {
      x: {
        ticks: { color: '#ffffff' },
        grid: {
          color: chartGridLinesColor,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          color: '#ffffff',
          callback: (value: number) =>
            Math.round(value).toLocaleString() + ' ' + unitToDisplay,
        },
        grid: {
          color: chartGridLinesColor,
        },
      },
    },
    plugins: {
      legend: { display: false },
      title: { display: false },
      tooltip: {
        callbacks: {
          title: (tooltipItem: any) => {
            if (tooltipItem[0]) {
              return tooltipItem[0].label;
            } else {
              return null;
            }
          },
          label: (tooltipItem: any) => {
            const dataDisplayed =
              tooltipItem.dataset.label +
              ' : ' +
              Math.round(tooltipItem.raw).toLocaleString() +
              ' ' +
              unitToDisplay;

            return dataDisplayed;
          },
          labelColor: () => {
            const color = '#ffffff';
            return {
              borderColor: color,
              backgroundColor: color,
            };
          },
        },
      },
    },
  };

  return (
    <div style={{ marginTop: 0, marginBottom: 64 }}>
      <div
        style={{
          background: chartBackgroundColor,
          margin: 20,
          marginTop: -375,
          padding: 30,
          borderRadius: 4,
          boxShadow:
            '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193,.4)',
        }}
      >
        <Bar data={data} width={400} height={200} options={options} />
      </div>
    </div>
  );
};

export default BalanceChart;
