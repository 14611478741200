import { GridColDef } from '@mui/x-data-grid-pro';
import { getCertificateInfo } from 'utils/datagridHelpers';

import CertificateTableCell from '../CustomCell/CertificateTableCell';

const generateColumns = (
  standard: any,
  dynamicColumns: GridColDef[],
  startInfoIndex = 0
) => {
  for (
    let infoIndex = startInfoIndex;
    infoIndex < standard?.infoTexts?.length;
    infoIndex++
  ) {
    const property = 'info' + infoIndex;
    const infoTextItem = standard.infoTexts.find(
      (t: any) => t.infoProp === property
    );
    const infoText =
      infoTextItem && !infoTextItem.isHidden
        ? infoTextItem.infoStandardText
        : '';

    if (infoText) {
      dynamicColumns.push({
        field: '_info' + infoIndex,
        headerName: infoText,
        editable: false,
        width: 200,
        valueGetter: (params: any) => getCertificateInfo(params, infoIndex),
        renderCell: (params: any) => (
          <CertificateTableCell
            value={
              params.row?.origin?.standardValues?.find(
                (sv: any) => sv.key === property
              )?.value
            }
            cancelled={params.row?.cancelled}
          />
        ),
      });
    }
  }
};

const getDynamicColumns = (
  selectedStandard: any,
  selectedStandardType: any,
  standards: any[]
) => {
  const dynamicColumns: GridColDef[] = [];

  if (selectedStandard) {
    generateColumns(selectedStandard, dynamicColumns);
  }

  if (selectedStandardType) {
    const myStandard = standards.find(
      (s) => s.standardType === selectedStandardType
    );
    if (myStandard) {
      generateColumns(myStandard, dynamicColumns, 4);
    }
  }

  return dynamicColumns;
};

export default getDynamicColumns;
