import { decreaseBalanceStart, displayDecreaseFormInDialog } from 'actions';
import GenericTypography from 'components/Administration/Generic/Form/Labels/GenericTypography';
import {
  addThousandsSeparatorToNumber,
  quantityTypeUppercase,
  removeNonNumeric,
} from 'components/Administration/Generic/Form/utils/utils';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { formatMonthYear } from 'components/Balances/utils/table/helpers';

import { FormFieldsConfigType } from '../../../../utils/types/formFieldsTypes';
import { FooterModalPropsType } from '../../../../utils/types/types';
import useDecreaseBalanceUnitAndPeriod from '../CustomHooks/useDecreaseBalanceUnitAndPeriod';

const useDecreaseBalanceFormConfirmationModalConfig = () => {
  const { decreaseBalanceData, loadingDecreaseBalance } =
    useAdministrationSlicesCombiner();
  const { unit } = useDecreaseBalanceUnitAndPeriod();

  //modal components
  const modalHeader = {
    title: '',
  };
  const modalFooter: FooterModalPropsType = {
    cancelLabel: 'Back',
    submitLabel: 'Confirm',
    onCancelHandler: displayDecreaseFormInDialog,
    onSubmitHandler: decreaseBalanceStart(
      decreaseBalanceData._for,
      decreaseBalanceData._origin,
      decreaseBalanceData._year,
      decreaseBalanceData._month,
      decreaseBalanceData._volume,
      decreaseBalanceData._comment,
      decreaseBalanceData._txHash
    ),
    loading: loadingDecreaseBalance,
  };
  const formFieldsConfig: FormFieldsConfigType = [
    [
      {
        component: GenericTypography,
        name: 'Please check if the data are Ok before proceeding to the decrease',
        styleGrid: { marginBottom: 10 },
        styleLabel: { paddingBottom: 10 },
        size: 12,
      },
      {
        component: GenericTypography,
        name: 'For',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${
          decreaseBalanceData._for && decreaseBalanceData._for.name
            ? decreaseBalanceData._for.name
            : ''
        }`,
      },
      {
        component: GenericTypography,
        name: 'Origin',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${
          decreaseBalanceData._origin &&
          decreaseBalanceData._origin.displayedName
            ? decreaseBalanceData._origin.displayedName
            : ''
        }`,
      },
      {
        component: GenericTypography,
        name: 'Period',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${formatMonthYear(
          decreaseBalanceData._year + '-' + decreaseBalanceData._month
        )}`,
      },
      {
        component: GenericTypography,
        name: `${quantityTypeUppercase}`,
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${addThousandsSeparatorToNumber(
          removeNonNumeric(decreaseBalanceData._volume)
        )} ${unit}`,
      },
      {
        component: GenericTypography,
        name: 'Comment',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${decreaseBalanceData._comment}`,
      },
      {
        component: GenericTypography,
        name: 'Transaction Hash',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${decreaseBalanceData._txHash}`,
      },
    ],
  ];
  return {
    header: modalHeader,
    content: formFieldsConfig,
    footer: modalFooter,
  };
};

export default useDecreaseBalanceFormConfirmationModalConfig;
