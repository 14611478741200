import { useEffect, useRef } from 'react';

import { checkAttributesStart } from 'actions';
import GenericModalTitle from 'components/Administration/Generic/Modal/Title/GenericModalTitle';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { Formik, FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import checkAttributesIcon from '../../../../../../../assets/images/icons/check-document.png';

import ModalContentCheckAttributesConfirmation from './Form/Content/ModalContentCheckAttributesConfirmation';
import ModalContentCheckAttributesForm from './Form/Content/ModalContentCheckAttributesForm';
import ModalFooterCheckAttributesConfirmation from './Form/Footer/ModalFooterCheckAttributesConfirmation';
import ModalFooterCheckAttributesForm from './Form/Footer/ModalFooterCheckAttributesForm';
import { getValidationForm } from './Form/FormValidation/validationForm';

const CheckAttributesForm = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const formikRef = useRef<FormikProps<any>>(null);
  const {
    products,
    displayCheckAttributesFormInDialog,
    checkAttributesData,
    displayCheckAttributesResult,
    resetCheckAttributesForm,
  } = useAdministrationSlicesCombiner();

  const initialValues = { origin: checkAttributesData?._origin?._id };
  const onSubmitHandler = (values: FormikValues) => {
    const fullOrigin = (products as any[]).find(
      (product) => product._id === values.origin
    );
    dispatch(checkAttributesStart(fullOrigin));
  };

  useEffect(() => {
    if (resetCheckAttributesForm) {
      formikRef.current?.resetForm({
        values: {
          origin: '',
        },
      });
    }
  }, [resetCheckAttributesForm]);

  return (
    <>
      <GenericModalTitle
        id="responsive-dialog-title"
        title={t('Check product attributes')}
        icon={checkAttributesIcon}
      />

      {displayCheckAttributesFormInDialog && (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
          validate={(values) => getValidationForm(values)}
        >
          {() => {
            return (
              <>
                <ModalContentCheckAttributesForm />
                <ModalFooterCheckAttributesForm />
              </>
            );
          }}
        </Formik>
      )}
      {!displayCheckAttributesFormInDialog && displayCheckAttributesResult && (
        <>
          <ModalContentCheckAttributesConfirmation />
          <ModalFooterCheckAttributesConfirmation />
        </>
      )}
    </>
  );
};

export default CheckAttributesForm;
