import React from 'react';

import { MenuItem } from '@mui/material';

const GenericConditionalMenuItem = ({ condition, iterator }) => {
  return (
    condition &&
    iterator &&
    Array.isArray(iterator) &&
    iterator.map((organization) => (
      <MenuItem key={organization._id} value={organization._id}>
        {organization.name}
      </MenuItem>
    ))
  );
};

export default GenericConditionalMenuItem;
