import useLoginSlices from 'components/Balances/Redux/Slices/useLoginSlices';
import useOrganizationsSlices from 'components/Balances/Redux/Slices/useOrganizationsSlices';
import useStandardsSlices from 'components/Balances/Redux/Slices/useStandardsSlices';
import useProductsSlices from 'components/Products/Redux/Slices/useProductsSlices';

import useAdministrationSlices from './useAdministrationSlices';

const useAdministrationSlicesCombiner = () => {
  return {
    ...useAdministrationSlices(),
    ...useLoginSlices(),
    ...useStandardsSlices(),
    ...useOrganizationsSlices(),
    ...useProductsSlices(),
  };
};

export default useAdministrationSlicesCombiner;
