/* eslint-disable */
import { createTheme } from '@mui/material/styles';

let theme = createTheme();

export const styles = createTheme(theme, {
  sectionOdd: {
    backgroundColor: '#ffffff',
    paddingTop: 70,
    paddingBottom: 70,
    paddingLeft: 30,
    paddingRight: 30,
  },
  sectionEven: {
    backgroundColor: '#efefef',
    paddingTop: 70,
    paddingBottom: 70,
    paddingLeft: 30,
    paddingRight: 30,
  },
  link: {
    textDecoration: 'none',
    '&:focus': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
    },
    '&:visited': {
      textDecoration: 'none',
    },
    '&:active': {
      textDecoration: 'none',
    },
  },
  videoBg: {
    overflow: 'hidden',
    //position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'relative',
    width: '100%',
  },

  videoContent: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -10000,
    objectFit: 'cover',
  },

  fullscreenBg: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
    zIndex: -100,
  },

  fullscreenBgVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },

  mySection: {
    paddingTop: 80,
    paddingTop: 80,
  },

  myHeaderTextContainer: {
    lineHeight: '54px',
    float: 'right',
  },
  myHeaderText: {
    color: '#ffffff',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 12,
    lineHeight: '54px',
  },
  contactLink: {
    color: '#ffffff',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'none',
    textDecoration: 'underline',
    marginLeft: 10,
    marginRight: 10,
    lineHeight: '54px',
    cursor: 'pointer',
  },
  footerLink: {
    color: '#FFFFFF',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'none',
    textDecoration: 'underline',
    marginLeft: 10,
    marginRight: 10,
    lineHeight: '54px',
    cursor: 'pointer',
  },
  myFooterTextNew: {
    color: '#b0b9b6',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 12,
  },
  cookieConsentTitle: {
    fontSize: 20,
    marginBottom: 10,
    color: '#232D36',
  },
  cookieConsentText: {
    fontSize: 16,
    marginBottom: 10,
    color: '#232D36',
  },
  cookieConsentDeclineButton: {
    fontSize: 20,
    marginBottom: 10,
    color: '#232D36',
  },
  cookieConsentAcceptButton: {
    fontSize: 20,
    marginBottom: 10,
    color: '#232D36',
  },
  myHeroTitle: {
    color: '#232D36',
    fontFamily:
      '"MatterSQ-Regular", "Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: 50,
  },
  myBaseline: {
    color: '#232D36',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: 18,
  },
  mySubtitle: {
    color: '#34b2ff',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: 18,
    textTransform: 'none',
  },
  myDialogEmail: {
    color: '#34b2ff',
    textTransform: 'none',
  },
  myTitle: {
    color: '#1E2B38',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: 24,
    textTransform: 'none',
  },
  myCard: {},
  myCardImage: {},
  myCardTitle: {
    color: '#1E2B38',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: 18,
  },
  myCardDetails: {
    color: '#9CB1BB',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
  },
  myCardDetailsBold: {
    color: '#1E2B38',
    fontWeight: 'bold',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
  },
  myFooterText: {
    color: '#FFFFFF',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 12,
  },
  buttonHome: {
    margin: 0,
    padding: '0 30px',
    opacity: 1,
    fontSize: '0.7rem',
    letterSpacing: '1.3px',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    lineHeight: '2.55rem',
    color: '#ffffff',
    border: 'none',
    borderRadius: 4,
    textTransform: 'uppercase',
    backgroundColor: '#F87733',
    backgroundImage: 'linear-gradient(#F59B6B,#F87733)',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    boxShadow: '0px 8px 24px 0px rgb(97 47 20 / 40%)',
    transition: 'all .5s ease',
    textDecoration: 'none',
  },
  button: {
    margin: theme.spacing.unit,
    borderRadius: 24,
    backgroundColor: '#ffffff',
    color: 'rgba(52,178,255,1)',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: 12,
    textTransform: 'none',
  },
  buttonSchedule: {
    margin: theme.spacing.unit,
    borderRadius: 24,
    backgroundColor: '#abd7f5',
    color: 'rgba(52,178,255,1)',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: 12,
    textTransform: 'none',
    textDecoration: 'none',
  },
  buttonScheduleTop: {
    margin: theme.spacing.unit,
    borderRadius: 24,
    backgroundColor: '#ffffff',
    color: 'rgba(52,178,255,1)',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: 12,
    textTransform: 'none',
    textDecoration: 'none',
  },

  button2: {
    margin: theme.spacing.unit,
    borderRadius: 24,
    backgroundColor: 'rgba(52,178,255,0.4)',
    color: 'rgba(52,178,255,1)',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: 12,
    textTransform: 'none',
    textDecoration: 'none',
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  myNumberList: {
    '&::before': {
      counterIncrement: 'li',
      content: 'counter(li)',
      position: 'absolute',
      top: '50%',
      left: -35,
      boxSizing: 'border-box',
      width: 35,
      height: 35,
      marginRight: 8,
      padding: 6,
      border: '1px solid',
      borderRadius: '50%',
      textAlign: 'center',
      transform: 'translateY(-50%)',
    },
  },
});
