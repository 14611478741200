import {
  getBalancesStartByStandard,
  getFirstTransactionAssociatedWithUserOrganization_Date,
  openErrorSnack,
  setEndDate,
  setStartDate,
} from 'actions';
import moment from 'moment';
import { AppDispatch } from 'redux/store';

export const getBalancesByStandardAndStartDateAndEndDates =
  (
    standardId: string,
    limit: number,
    customButtonPressed: boolean,
    customStartDate?: Date,
    customEndDate?: Date
  ) =>
  async (dispatch: AppDispatch) => {
    //Calculate Start and End Date Values
    let startDate = '';
    let endDate = '';
    if (customButtonPressed && customStartDate && customEndDate) {
      startDate = moment(customStartDate).format('YYYY-MM-DD');
      endDate = moment(customEndDate).format('YYYY-MM-DD');
    } else {
      startDate =
        await getFirstTransactionAssociatedWithUserOrganization_Date();
      endDate = moment().format('YYYY-MM-DD');
    }
    if (moment(startDate).isAfter(moment(endDate))) {
      dispatch(
        openErrorSnack(
          'The Start Date should not be later than the End Date. Please select a valid date range.'
        )
      );
    } else {
      dispatch(setStartDate(startDate));
      dispatch(setEndDate(endDate));
      dispatch(
        getBalancesStartByStandard(standardId, startDate, endDate, 0, limit)
      );
    }
  };
