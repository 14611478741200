import { enums } from 'enums';
import { DefaultProductType } from 'reducers/productsReducer/types';

export const processChartTitle = (
  currentProduct: DefaultProductType | undefined,
  ozOrGramsQuantityUnit: string
) => {
  let unit = '';
  if (
    process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum' &&
    unit === enums.Units.GRAMS &&
    ozOrGramsQuantityUnit !== enums.Units.GRAMS
  ) {
    unit = ozOrGramsQuantityUnit;
  }
  unit = currentProduct && currentProduct.unit ? currentProduct.unit : 'kWh';
  return unit;
};
