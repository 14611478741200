import { CurrentCertificateSecretWordType } from 'reducers/certificatesReducer/types';
type CurrentCertificateSecretWordKeys =
  | 'issuer'
  | 'from'
  | 'client'
  | 'to'
  | 'origin';

export const getSecretWordInputs = (
  currentCertificateSecretWord: CurrentCertificateSecretWordType
): Array<{ label: string; value: string; message: string }> => [
  {
    label: 'Issuer',
    value: currentCertificateSecretWord.issuerSecretWord,
    message: 'Issuer copied to clipboard!',
  },
  {
    label: 'From',
    value: currentCertificateSecretWord.fromSecretWord,
    message: 'From copied to clipboard!',
  },
  {
    label: 'Client',
    value: currentCertificateSecretWord.clientSecretWord,
    message: 'Client copied to clipboard!',
  },
  {
    label: 'To',
    value: currentCertificateSecretWord.toSecretWord,
    message: 'Transaction receiver copied to clipboard!',
  },
  {
    label: 'Product Id',
    value: currentCertificateSecretWord.origin,
    message: 'Product Id copied to clipboard!',
  },
];

export const isValidKey = (
  key: string
): key is CurrentCertificateSecretWordKeys => {
  return (
    key === 'issuer' ||
    key === 'from' ||
    key === 'client' ||
    key === 'to' ||
    key === 'origin' ||
    key == 'product id'
  );
};
