import React, { useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { handleAuthenticationAuth0, logoutStart } from '../actions';
import { withRouter } from '../utils/migrationHelper';

let AuthCallback = ({ cookies, handleAuthenticationAuth0, logoutStart }) => {
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/users/profile`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        handleAuthenticationAuth0(navigate, location, res.data);
      })
      .catch((err) => {
        if (err) {
          console.error(
            '### Error when try to get user data, cleaning the session and cookies',
            err
          );
          logoutStart(cookies);
          navigate('/');
        }
      });
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress size={72} />
    </div>
  );
};

const mapStateToProps = ({ loginReducer, router }) => {
  const { user, loading, isAuthenticated, loadingCheck } = loginReducer;
  const { location } = router;
  return { user, loading, isAuthenticated, loadingCheck, location };
};

const mapDispatchToProps = {
  handleAuthenticationAuth0,
  logoutStart,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthCallback)
);
