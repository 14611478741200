import { Fab, TableCell, Tooltip } from '@mui/material';
import Link from '@mui/material/Link';
import MoreButton from 'components/ReferenceFiles/CustomComponents/MoreButton/MoreButton';
import BeNotifiedCell from 'components/ReferenceFiles/CustomComponents/Table/BeNotifiedCell';
import DateCell from 'components/ReferenceFiles/CustomComponents/Table/DateCell';
import DownloadCell from 'components/ReferenceFiles/CustomComponents/Table/DownloadCell';
import LabelCell from 'components/ReferenceFiles/CustomComponents/Table/LabelCell';

import downloadIcon from '../../../assets/images/icons/download-from-cloud.png';

export const getAllDataGridColumns = (
  product,
  deletingFileIds,
  userCanEditReferenceFiles,
  onSwitchChange
) => {
  let columns = [
    {
      field: 'label',
      headerName: 'Name',
      editable: userCanEditReferenceFiles ? true : false,
      width: 300,
      renderCell: (params) => (
        <LabelCell
          row={params.row}
          userCanEditReferenceFiles={userCanEditReferenceFiles}
        />
      ),
    },
    {
      field: 'validityStart',
      headerName: 'Validity start',
      editable: userCanEditReferenceFiles ? true : false,
      type: 'date',
      width: 130,
      renderCell: (params) => (
        <DateCell
          cellDate={params.row.validityStart}
          startOrEnd={'start'}
          userCanEditReferenceFiles={userCanEditReferenceFiles}
        />
      ),
    },
    {
      field: 'validityEnd',
      headerName: 'Validity end',
      editable: userCanEditReferenceFiles ? true : false,
      type: 'date',
      width: 130,
      renderCell: (params) => (
        <DateCell
          cellDate={params.row.validityEnd}
          startOrEnd={'end'}
          userCanEditReferenceFiles={userCanEditReferenceFiles}
        />
      ),
    },
    {
      field: 'url',
      headerName: 'Download',
      editable: false,
      width: 100,
      renderCell: (params) => (
        <DownloadCell blob={params.row.blob} url={params.row.url} />
      ),
    },
    {
      field: 'uploadedAt',
      hide: true,
      headerName: 'Uploaded at',
      editable: false,
      width: 200,
      type: 'date',
    },
    {
      field: 'updatedAt',
      headerName: 'Updated at',
      editable: false,
      width: 200,
      type: 'date',
    },
    {
      field: 'menu',
      headerName: 'Options',
      editable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <MoreButton
            row={params.row}
            deleting={deletingFileIds.includes(params.row._id)}
            userCanEditReferenceFiles={userCanEditReferenceFiles}
          />
        );
      },
    },
  ];

  if (userCanEditReferenceFiles) {
    columns = [
      ...columns,
      {
        field: 'notify',
        headerName: 'Be notified',
        editable: userCanEditReferenceFiles ? true : false,
        type: 'boolean',
        width: 130,
        renderCell: (params) => (
          <BeNotifiedCell
            params={params}
            userCanEditReferenceFiles={userCanEditReferenceFiles}
            onSwitchChange={onSwitchChange}
          />
        ),
      },
    ];
  }

  return columns;
};

export const getReferenceFilesDataGridColumns = ({
  userCanEditReferenceFiles,
  navigate,
}) => {
  return [
    {
      field: 'product.displayedName',
      headerName: 'Product',
      editable: false,
      width: 300,
      valueGetter: (params) => {
        return params.row.product.displayedName;
      },
      renderCell: (params) => {
        const product = params?.row?.product;
        if (!userCanEditReferenceFiles) return product?.displayedName;
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigate(`/products/update/${product._id}`);
            }}
            style={{ textDecoration: 'none' }}
          >
            {product?.displayedName}
          </Link>
        );
      },
    },
    {
      field: 'product.productId',
      headerName: 'Product ID',
      editable: false,
      width: 300,
      valueGetter: (params) => {
        return params.row.product.productId;
      },
      renderCell: (params) => {
        const product = params?.row?.product;
        if (!userCanEditReferenceFiles) return product?.displayedName;
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigate(`/products/update/${product._id}`);
            }}
            style={{ textDecoration: 'none' }}
          >
            {product?.productId}
          </Link>
        );
      },
    },
    {
      field: 'label',
      headerName: 'Name',
      editable: false,
      width: 300,
    },
    {
      field: 'validityStart',
      headerName: 'Validity start',
      editable: false,
      type: 'date',
      width: 130,
      renderCell: (params) => (
        <DateCell cellDate={params.row.validityStart} startOrEnd={'start'} />
      ),
    },
    {
      field: 'validityEnd',
      headerName: 'Validity end',
      editable: false,
      type: 'date',
      width: 130,
      renderCell: (params) => (
        <DateCell cellDate={params.row.validityEnd} startOrEnd={'start'} />
      ),
    },
    {
      field: 'url',
      headerName: 'Download',
      editable: false,
      width: 100,
      renderCell: (params) => {
        const s3FileUrl = new URL(params.row.url);
        const fileUrl = `${process.env.REACT_APP_API_URL}/api/files/s3${s3FileUrl.pathname}`;

        return (
          <TableCell style={{ textAlign: 'center' }}>
            <Tooltip
              title={'Download source'}
              placement="bottom"
              enterDelay={100}
            >
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                style={{ backgroundColor: '#FFFFFF' }}
              >
                <a href={`${fileUrl}`} target="_blank" rel="noreferrer">
                  <img
                    src={downloadIcon}
                    alt={'Download reference document'}
                    title={'Download reference document'}
                    style={{
                      width: 22,
                      height: 22,
                      marginTop: 6,
                      marginLeft: 2,
                    }}
                  />
                </a>
              </Fab>
            </Tooltip>
          </TableCell>
        );
      },
    },
    {
      field: 'uploadedAt',
      hide: true,
      headerName: 'Uploaded at',
      editable: false,
      width: 200,
      type: 'date',
    },
    {
      field: 'updatedAt',
      headerName: 'Updated at',
      editable: false,
      width: 200,
      type: 'date',
    },
  ];
};
