export const AdministrationActionTypes = [
  {
    value: 'MATCHING_LIVE',
    label: 'matching live',
  },
  {
    value: 'MATCHING_OFFICIAL',
    label: 'matching official',
  },
  {
    value: 'UPDATE_DEMO',
    label: 'update demo',
  },
  {
    value: 'CANCEL_CERTIFICATES',
    label: 'cancel certificates',
  } /*,
  {
    value: "CANCEL_CERTIFICATES_AND_REPLAY_LIVE",
    label: "cancel certificates and replay matching live"
  },
  {
    value: "CANCEL_CERTIFICATES_AND_REPLAY_OFFICIAL",
    label: "cancel certificates and replay matching official"
  }*/,
];
