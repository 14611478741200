import { useEffect, useState } from 'react';

import { getProductsStart } from 'actions';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from 'components/Transactions/utils/constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';

import useProductsSlicesCombiner from '../Slices/useProductsSlicesCombiner';

const useProducts = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { isAuthenticated } = useProductsSlicesCombiner();

  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const handlePageChange = (page: any) => {
    setPage(page);
  };
  const handlePageSizeChange = (size: any) => {
    setPageSize(size);
    setPage(0);
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getProductsStart(page, pageSize));
    } else {
      navigate('/');
    }
  }, [isAuthenticated, navigate, dispatch, page, pageSize]);

  return { handlePageChange, handlePageSizeChange, page, pageSize };
};

export default useProducts;
