import { Card, CardContent } from '@mui/material';
import BackButton from 'components/commons/BackButton/BackButton';
import StandardCardHeader from 'components/FormStandards/CustomComponents/Cards/Header/CardHeader';
import FormikStandardContent from 'components/FormStandards/CustomComponents/Formik/FormikStandardContent';
import useFormCreateStandard from 'components/FormStandards/Redux/CustomHooks/useFormCreateStandard';

const CreateFormStandard = (props: any) => {
  useFormCreateStandard();
  return (
    <>
      <BackButton />
      <Card>
        <StandardCardHeader />
        <CardContent>
          <FormikStandardContent props={props} />
        </CardContent>
      </Card>
    </>
  );
};

export default CreateFormStandard;
