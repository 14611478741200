import { InfoTypes } from 'constants/InfoTypes';

import React, { useState } from 'react';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Button, Grid, Typography } from '@mui/material';
import { standardFormIsBeingFilledOut } from 'actions';
import SetTextField from 'components/FormStandards/CustomComponents/Fields/Texts/SetTextField';
import useFormStandardsSlicesCombiner from 'components/FormStandards/Redux/Slices/Combiner/useFormStandardsCombiner';
import GenericField from 'components/GenericComponents/Grid/Field/Field/GenericField';
import { Field, FormikValues, useFormikContext } from 'formik';
import { Switch } from 'formik-mui';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import trashIcon from '../../../../../../../assets/images/icons/trash.png';
import ConfirmationModal from '../../../Modal/ConfirmationModal';

type CollectionAttributeFormProps = {
  index: number;
  onRemove: (
    values: FormikValues,
    setFieldValue: any,
    indexToRemove: number
  ) => void;
};

const CollectionAttributeForm: React.FC<CollectionAttributeFormProps> = ({
  index,
  onRemove,
}) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const { dynamicLists } = useFormStandardsSlicesCombiner();

  const dispatch: AppDispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleConfirm = () => {
    onRemove(values, setFieldValue, index);
  };
  const handleFormBeingFilledOut = () => {
    dispatch(standardFormIsBeingFilledOut());
  };

  return (
    <Grid
      key={`infoText${index}`}
      item
      xs={12}
      style={{
        marginLeft: 6,
        paddingLeft: 10,
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{
          paddingBottom: 10,
        }}
        spacing={2}
      >
        <Grid item xs={0.5}>
          <DragIndicatorIcon className="dragIcon" />
        </Grid>
        <Grid item xs={1.5}>
          <GenericField
            name={`infoTexts[${index}].type`}
            label={`attribute ${index + 1} type`}
            select={true}
            items={InfoTypes.map((infoType) => ({
              id: infoType.value,
              name: infoType.label,
            }))}
            formIsBeingFilledOutHandler={handleFormBeingFilledOut}
            sizes={{ xs: 2 }}
            style={{ width: 150 }}
          />
        </Grid>
        <Grid item xs={1.5}>
          <GenericField
            name={`infoTexts[${index}].collection`}
            label={`attribute ${index + 1} collection`}
            select={true}
            items={dynamicLists.map((dynamicList) => ({
              id: dynamicList._id,
              name: dynamicList.name,
            }))}
            formIsBeingFilledOutHandler={handleFormBeingFilledOut}
            sizes={{ xs: 2 }}
            style={{ width: 150 }}
          />
        </Grid>
        <Grid item xs={2.5}>
          <GenericField
            name={`infoTexts[${index}].infoStandardText`}
            label={`attribute ${index + 1} name`}
            component={SetTextField}
            formIsBeingFilledOutHandler={handleFormBeingFilledOut}
            sizes={{ xs: 3 }}
            style={{ width: 200, marginTop: 20, marginLeft: 40 }}
          />
        </Grid>
        <Grid item xs={2.5}>
          <GenericField
            name={`infoTexts[${index}].infoStandardDesc`}
            label={`attribute ${index + 1} tooltip description`}
            component={SetTextField}
            formIsBeingFilledOutHandler={handleFormBeingFilledOut}
            sizes={{ xs: 3 }}
            style={{ width: 200, marginTop: 20 }}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography
            variant="caption"
            display="block"
            style={{
              color: 'rgba(0, 0, 0, 0.6)',
              marginTop: '2px',
              marginRight: '30px',
            }}
          >
            Hide attribute {index + 1} in Balances, Transactions and
            Certificates pages
          </Typography>
          <Field
            name={`infoTexts[${index}].isHidden`}
            type="checkbox"
            color="secondary"
            component={Switch}
            checked={Boolean(values.infoTexts[index].isHidden)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(`infoTexts[${index}].isHidden`, e.target.checked);
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <Button
            onClick={() => setModalOpen(true)}
            style={{ marginTop: 20 }}
            data-testid="remove-icon-id"
          >
            <img
              src={trashIcon}
              alt={'Remove'}
              title={'Remove'}
              style={{ width: 30, height: 30 }}
            />
          </Button>
          <ConfirmationModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onConfirm={handleConfirm}
            title="Remove standard attribute"
            message={`Are you sure you want to delete the standard <strong>attribute ${
              index + 1
            }</strong> ?`}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CollectionAttributeForm;
