import { DialogContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { dynamicListItemsChanged } from 'actions';
import useDynamicListsSlicesCombiner from 'components/DynamicList/Redux/Slices/useDynamicListsSlicesCombiner';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const UploadContent = () => {
  const { uploadingFile } = useDynamicListsSlicesCombiner();

  const dispatch: AppDispatch = useDispatch();
  const handleDynamicListItemsChanged = (acceptedFiles: any[]) => {
    dispatch(dynamicListItemsChanged(acceptedFiles));
  };

  return (
    <DialogContent>
      <Dropzone onDrop={handleDynamicListItemsChanged} disabled={uploadingFile}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} style={dropZoneStyle}>
              <input {...getInputProps()} />
              <Typography
                variant="body2"
                display="block"
                style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 16 }}
              >
                Drag &apos;n&apos; drop your files here, or click to select
                files
              </Typography>
            </div>
          </section>
        )}
      </Dropzone>
    </DialogContent>
  );
};

export default UploadContent;

const dropZoneStyle = {
  backgroundColor: '#F0F0F0',
  padding: 20,
  height: 80,
  border: 'dashed',
  borderColor: '#C6C6C6',
};
