/* eslint-disable */

import {
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
  openSuccessSnack,
  openErrorSnack,
} from './UtilsActions';
import { handleError } from './ErrorActions';

export const closeRemoveListItemDialog = () => (dispatch) => {
  dispatch({ type: 'CLOSE_REMOVE_LIST_ITEM_DIALOG' });
};

export const openCreateListItemDialog = (listItemLength) => (dispatch) => {
  dispatch({ type: 'OPEN_CREATE_LIST_ITEM_DIALOG', payload: listItemLength });
};

export const closeCreateListItemDialog = () => (dispatch) => {
  dispatch({ type: 'CLOSE_CREATE_LIST_ITEM_DIALOG' });
};

export const removeListItem = (listItemData) => (dispatch) => {
  dispatch({ type: 'REMOVE_LIST_ITEM', payload: listItemData });
};

const getWells = async () => {
  let response = await apiGet(`/api/wells`);

  if (response.status >= 200 && response.status <= 299) {
    const wells = await response.json();
    if (!Array.isArray(wells))
      throw new Error(
        'Error retrieving wells, returns : ' + JSON.stringify(wells)
      );
    return wells;
  } else {
    // Handle errors
    await handleError(response);
  }
};

export const getWellsStart = () => async (dispatch) => {
  dispatch({ type: 'GET_WELLS_START' });

  try {
    let wells = await getWells();
    dispatch({ type: 'GET_WELLS_SUCCESS', payload: wells });
  } catch (error) {
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'GET_WELLS_FAIL' });
  }
};

export const wellFormIsBeingFilledOut = () => (dispatch) => {
  dispatch({ type: 'WELL_FORM_IS_BEING_FILLED_OUT' });
};

export const createWellStart = (wellToCreate, navigate) => async (dispatch) => {
  dispatch({ type: 'CREATE_WELL_START' });

  try {
    let response = await apiPost(`/api/wells`, wellToCreate);

    if (response.status >= 200 && response.status <= 299) {
      const wellCreated = await response.json();

      dispatch(openSuccessSnack('Well successfully created !'));

      let wells = await getWells();
      dispatch({ type: 'CREATE_WELL_SUCCESS', payload: wells });
      navigate('/wells/');
    } else {
      // Handle errors
      await handleError(response);
    }
  } catch (error) {
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'CREATE_WELL_FAIL' });
  }
};

export const getWellStart = (wellId) => async (dispatch) => {
  dispatch({ type: 'GET_WELL_START' });

  try {
    let response = await apiGet(`/api/wells/${wellId}`);

    if (response.status >= 200 && response.status <= 299) {
      const well = await response.json();
      dispatch({ type: 'GET_WELL_SUCCESS', payload: well });
    } else {
      // Handle errors
      await handleError(response);
    }
  } catch (error) {
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'GET_WELL_FAIL' });
  }
};

export const resetCurrentWell = () => async (dispatch) => {
  dispatch({ type: 'RESET_WELL' });
};

export const setWellStart = (wellToUpdate, navigate) => async (dispatch) => {
  dispatch({ type: 'SET_WELL_START' });

  try {
    let response = await apiPut(`/api/wells/${wellToUpdate._id}`, wellToUpdate);

    if (response.status >= 200 && response.status <= 299) {
      const wellUpdate = await response.json();

      dispatch(openSuccessSnack('Well successfully updated !'));

      let wells = await getWells();
      dispatch({ type: 'SET_WELL_SUCCESS', payload: wells });
      navigate('/wells/');
    } else {
      // Handle errors
      await handleError(response);
    }
  } catch (error) {
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'SET_WELL_FAIL' });
  }
};

export const deleteWellStart =
  (wellToDeleteId, navigate) => async (dispatch) => {
    dispatch({ type: 'DELETE_WELL_START' });

    try {
      let response = await apiDelete(`/api/wells/${wellToDeleteId}`);

      if (response.status >= 200 && response.status <= 299) {
        const wellDeleted = await response.json();

        dispatch(openSuccessSnack('Well successfully deleted !'));

        let wells = await getWells();
        dispatch({ type: 'DELETE_WELL_SUCCESS', payload: wells });
        navigate('/wells/');
      } else {
        // Handle errors
        await handleError(response);
      }
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'DELETE_WELL_FAIL' });
    }
  };
