import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const useStandardsReducerSlices = () => {
  const {
    currentStandard,
    formIsFilledOut,
    standardFormError,
    loadingStandard,
    removeListItemDialogOpen,
    createListItemDialogOpen,
    listItemData,
    listItemlength,
  } = useSelector((state: RootState) => state.standardsReducer);
  return {
    currentStandard,
    formIsFilledOut,
    standardFormError,
    loadingStandard,
    removeListItemDialogOpen,
    createListItemDialogOpen,
    listItemData,
    listItemlength,
  };
};

export default useStandardsReducerSlices;
