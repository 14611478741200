import { enums } from 'enums';
import { TFunction } from 'i18next';
import { DefaultProductType } from 'reducers/productsReducer/types';

export const getInitialInfosArray = (
  currentProduct: DefaultProductType,
  infoX: any
) => {
  const standardValueMultiList = currentProduct['standardValues']?.find(
    (sv: any) => sv.key === infoX.key
  );

  return (
    Array.isArray(standardValueMultiList?.value) &&
    standardValueMultiList['value']
  );
};

export const getFullListInfosArray = (infoX: any) => {
  return [...infoX.listItems]
    .filter((isItem) => isItem.value)
    .map((isItem) => isItem.value);
};

export const handleListMultiChange = (
  event: any,
  values: any,
  setValues: (value: any) => void,
  infoX: any
) => {
  const newValues = { ...values };
  newValues[infoX.key] = event.target.value;
  setValues(newValues);
};

export const processFieldLabel = (
  infoX: any,
  fieldType: string | undefined,
  t: TFunction
) => {
  let label = '';
  const infoXLabel = infoX.label ? infoX.label : t('Attribute ' + infoX.pos);
  const unit = infoX?.unit;
  if (!fieldType)
    return (label = infoX.label ? infoX.label : t('Attribute ' + infoX.pos));
  switch (fieldType) {
    case enums.InfoTypes.DATE:
      label = infoX.label ? infoX.label : t('Attribute ' + infoX.pos);
      break;

    case enums.InfoTypes.NUMBER:
      label = infoX.label ? infoX.label : t('Attribute ' + infoX.pos);
      break;

    case enums.InfoTypes.DYNAMIC_DATA:
      label = `${infoXLabel} (${unit})`;
      break;

    default:
      label = infoX.label ? infoX.label : t('Attribute ' + infoX.pos);
      break;
  }
  return label;
};

export const processFieldType = (fieldType: string | undefined) => {
  switch (fieldType) {
    case enums.InfoTypes.LIST:
      return 'text';
    case enums.InfoTypes.DATE:
      return 'date';
    case enums.InfoTypes.NUMBER:
      return 'number';
    default:
      return 'text';
  }
};
