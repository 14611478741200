import { StandardTypes } from 'constants/StandardTypes';

import { enums } from 'enums';

export const initInfosTexts = () => {
  const initialInfoTexts = [];
  for (let index = 1; index < 12; index++) {
    initialInfoTexts.push({
      infoProp: 'info' + index,
      infoStandardText: '',
      infoStandardDesc: '',
      type: 'text',
    });
  }
  return initialInfoTexts;
};

export const getStandardFromContent = ({
  content,
  currentStandard,
}: {
  content: any;
  currentStandard: any;
}) => {
  let jsonEditorStandard = content.json;
  if (!jsonEditorStandard && content.text) {
    try {
      jsonEditorStandard = JSON.parse(content.text);
    } catch (error) {
      return false;
    }
  }
  const standard = getAttributeStandard({
    jsonEditorStandard: jsonEditorStandard as any,
    _id: currentStandard._id as string,
    productType: currentStandard.productType as any,
    standardType: currentStandard.standardType as string,
    name: currentStandard.name as string,
  });
  return standard;
};

export const getJsonEditorStandardFromValues = (values: any) => {
  const attributes = values.infoTexts.map((_infoText: any) => {
    const infoText = { ..._infoText };
    delete infoText.infoProp;
    if (isListType(infoText)) {
      infoText.listItems = infoText.listItems.map((item: any) => {
        delete item.id;
        return item;
      });
    }
    infoText.name = infoText.infoStandardText;
    infoText.description = infoText.infoStandardDesc || '';
    if (!infoText.description) delete infoText.description;
    delete infoText.infoStandardText;
    delete infoText.infoStandardDesc;
    if (infoText.type === enums.InfoTypes.TEXT) {
      delete infoText.type;
    }

    return infoText;
  });
  return { attributes };
};

export const getJsonEditorStandard = (standard: any) => {
  const jsonEditorStandard = { ...standard };
  delete jsonEditorStandard.name;
  delete jsonEditorStandard._id;
  delete jsonEditorStandard.__v;
  delete jsonEditorStandard.productType;
  delete jsonEditorStandard.standardType;

  jsonEditorStandard.attributes = jsonEditorStandard.infoTexts.map(
    (infoText: any) => {
      return mapInfoText(infoText);
    }
  );
  delete jsonEditorStandard.infoTexts;
  return jsonEditorStandard;
};

const mapInfoText = (infoText: any) => {
  const mappedInfoText = { ...infoText };
  delete mappedInfoText.infoProp;
  if (isListType(mappedInfoText)) {
    mappedInfoText.listItems = mappedInfoText.listItems.map((item: any) => {
      delete item.id;
      return item;
    });
  }
  mappedInfoText.name = mappedInfoText.infoStandardText;
  mappedInfoText.description = mappedInfoText.infoStandardDesc || '';
  if (!mappedInfoText.description) delete mappedInfoText.description;
  delete mappedInfoText.infoStandardText;
  delete mappedInfoText.infoStandardDesc;
  if (mappedInfoText.type === enums.InfoTypes.TEXT) {
    delete mappedInfoText.type;
  }

  return mappedInfoText;
};

export const getAttributeStandard = ({
  jsonEditorStandard,
  _id,
  productType,
  standardType,
  name,
}: {
  jsonEditorStandard: any;
  _id: string;
  productType: any;
  standardType: string;
  name: string;
}) => {
  const standard = {
    ...jsonEditorStandard,
    _id,
    productType,
    standardType,
    name,
  };
  standard.infoTexts = standard.attributes.map(
    (attribute: any, index: number) => {
      const infoText = { ...attribute };
      infoText.infoProp = 'info' + index;
      infoText.infoStandardText = infoText.name || '';
      infoText.infoStandardDesc = infoText.description || '';
      delete infoText.name;
      delete infoText.description;
      if (!infoText.type) infoText.type = 'text';
      if (isListType(infoText)) {
        infoText.listItems = infoText.listItems.map(
          (item: any, listItemIndex: number) => {
            item.id = listItemIndex;
            return item;
          }
        );
      }
      return infoText;
    }
  );
  delete standard.attributes;
  return standard;
};

export const getStandardTypes = () => {
  const category = process.env.REACT_APP_PRODUCT_CATEGORY || 'gas';
  return StandardTypes.filter((s: any) => s.category === category);
};

export const getStandardInfoTexts = (currentStandard: any) => {
  return currentStandard.infoTexts.map((item: any) => {
    item.type = item.type || 'text';
    return item;
  });
};

export const areFieldsUntouchedOrEmpty = (
  index: number,
  currentStandard: any
) => {
  if (index < 0 || index >= currentStandard.infoTexts.length) return true;

  const currentInfoText = currentStandard.infoTexts[index];
  const isEmpty =
    !currentInfoText.infoStandardText && !currentInfoText.infoStandardDesc;

  return (
    isEmpty ||
    (isTypeSpecificFieldsEmpty(currentInfoText) && !currentInfoText.isHidden)
  );
};

const isTypeSpecificFieldsEmpty = (infoText: any) => {
  switch (infoText.type) {
    case enums.InfoTypes.TEXT:
    case enums.InfoTypes.NUMBER:
    case enums.InfoTypes.DATE:
      return !infoText.infoStandardText && !infoText.infoStandardDesc;
    case enums.InfoTypes.LIST:
    case enums.InfoTypes.LIST_MULTI:
      return (
        !infoText.infoStandardText &&
        !infoText.infoStandardDesc &&
        (!infoText.listItems || infoText.listItems.length === 0)
      );
    case enums.InfoTypes.DYNAMIC_DATA:
      return (
        !infoText.infoStandardText &&
        !infoText.infoStandardDesc &&
        !infoText.tableTitle &&
        !infoText.sourceName &&
        !infoText.unit
      );
    default:
      return false;
  }
};

const isListType = (infoText: any) => {
  return infoText.type === 'list' || infoText.type === 'list-multi';
};
