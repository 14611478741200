import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { setSelectedUserRights } from 'actions';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightCategories } from 'enums/RightCategories';
import { RightReferences } from 'enums/RightReferences';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/hooks';
import { AppDispatch, RootState } from 'redux/store';
import { Right } from 'types/Right';
import { RightsListElement } from 'types/RightsListElement';

import { RightsFormGroupPropsType } from './types';

export const RightsFormGroup = ({
  capitalizeFirstLetter,
  data,
  associatedRight,
  verifyIfChecked,
  verifyIfCurrentUserHasRight,
}: RightsFormGroupPropsType): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();

  let selectedUserRights = useSelector(
    (state: RootState) => state.usersReducer.selectedRights
  );

  const allUserRights = useSelector(
    (state: RootState) => state.usersReducer.rights
  );

  const updateSelectedUserRightsOfModuleCategory = (
    checked: boolean,
    right: Right,
    permission: CrudPermissions
  ) => {
    if (
      !selectedUserRights.find(
        (r: RightsListElement) => r.type._id === right._id
      )
    ) {
      selectedUserRights = [
        ...selectedUserRights,
        { type: right, activePermissions: [] },
      ];
    }
    selectedUserRights = selectedUserRights.map((r: RightsListElement) => {
      if (r.type._id === right._id) {
        if (checked) {
          if (r.activePermissions.indexOf(permission) < 0)
            r.activePermissions.push(permission);
          if (
            permission === CrudPermissions.UPDATE &&
            r.activePermissions.indexOf(CrudPermissions.VIEW) < 0
          )
            r.activePermissions.push(CrudPermissions.VIEW);
        } else {
          if (
            !(
              permission === CrudPermissions.VIEW &&
              r.activePermissions.indexOf(CrudPermissions.UPDATE) > -1
            )
          )
            r.activePermissions = r.activePermissions.filter(
              (p: CrudPermissions) => p !== permission
            );
        }
      }
      return r;
    });
    selectedUserRights = selectedUserRights.filter(
      (r: RightsListElement) =>
        !(
          r.activePermissions &&
          Array.isArray(r.activePermissions) &&
          r.activePermissions.length === 0
        )
    );
  };

  const updateSelectedUserRightsOfOperationsOrAdminCategory = (
    checked: boolean,
    right: Right
  ) => {
    if (checked) {
      selectedUserRights = [...selectedUserRights, { type: right }];
      if (right.category === RightCategories.OPERATIONS) {
        const additionalRightToAdd = allUserRights.find(
          (right: Right) => right.ref === RightReferences.BALANCES
        );
        updateSelectedUserRights(
          true,
          additionalRightToAdd,
          CrudPermissions.VIEW
        );
      }
    } else
      selectedUserRights = selectedUserRights.filter(
        (r: RightsListElement) => r.type._id !== right._id
      );
  };

  const updateSelectedUserRights = (
    checked: boolean,
    right: Right,
    permission?: CrudPermissions
  ) => {
    if (right.category === RightCategories.MODULES && permission != null) {
      updateSelectedUserRightsOfModuleCategory(checked, right, permission);
    } else {
      updateSelectedUserRightsOfOperationsOrAdminCategory(checked, right);
    }

    dispatch(setSelectedUserRights(selectedUserRights));
  };

  return (
    <FormGroup
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '20px',
      }}
    >
      {data &&
        Array.isArray(data) &&
        data.map((element: any, index: number) => {
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  disabled={
                    !verifyIfCurrentUserHasRight(element) &&
                    !verifyIfChecked(element)
                  }
                  checked={verifyIfChecked(element)}
                  onChange={(e, checked) => {
                    associatedRight
                      ? updateSelectedUserRights(
                          checked,
                          associatedRight,
                          element
                        )
                      : updateSelectedUserRights(checked, element);
                  }}
                />
              }
              label={capitalizeFirstLetter(element)}
            />
          );
        })}
    </FormGroup>
  );
};
