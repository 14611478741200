/* eslint-disable */

import { combineReducers } from 'redux';
import loginReducer from './loginReducer/loginReducer';
import dynamicListsReducer from './dynamicListsReducer/dynamicListsReducer';
import testReducer from './testReducer';
import productsReducer from './productsReducer/productsReducer';
import certificatesReducer from './certificatesReducer/certificatesReducer';
import operationsReducer from './operationsReducer/operationsReducer';
import transactionsReducer from './transactionsReducer/transactionsReducer';
import nominationsRequestsReducer from './nominationsReducer/nominationsRequestsReducer';
import balancesReducer from './balancesReducer/balancesReducer';
import utilsReducer from './utilsReducer/utilsReducer';
import usersReducer from './usersReducer';
import organizationsReducer from './organizationsReducer/organizationsReducer';
import standardsReducer from './standardsReducer/standardsReducer';
import wellsReducer from './wellsReducer';
import matchingTypesReducer from './matchingTypesReducer';
import administrationReducer from './administrationReducer';
import productTypesReducer from './productTypesReducer/productTypesReducer';
import { routerReducer } from 'react-router-redux';

export default combineReducers({
  loginReducer,
  testReducer,
  productsReducer,
  certificatesReducer,
  transactionsReducer,
  nominationsRequestsReducer,
  balancesReducer,
  utilsReducer,
  usersReducer,
  organizationsReducer,
  standardsReducer,
  wellsReducer,
  matchingTypesReducer,
  administrationReducer,
  productTypesReducer,
  dynamicListsReducer,
  router: routerReducer,
  operationsReducer,
});
