/* eslint-disable */

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { fieldToTextField } from 'formik-mui';

import Card from '@mui/material/Card';
import { CardHeader, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { withRouter } from '../utils/migrationHelper';
import userIcon from '../assets/images/icons/person.png';
import MuiTextField from '@mui/material/TextField';
import { withTranslation } from 'react-i18next';
import { withCookies } from 'react-cookie';
import { CommodityTypes } from '../enums/CommodityTypes';
import FieldTooltip from './commons/FieldTooltip/FieldTooltip';
import FieldError from './commons/FieldError/FieldError';
import BackButton from './commons/BackButton/BackButton';

import {
  createProductTypeStart,
  deleteProductTypeStart,
  setProductTypeStart,
  getProductTypeStart,
  resetCurrentProductType,
  productTypeFormIsBeingFilledOut,
} from '../actions';
import { checkUserIsSuperAdmin } from '../utils/user/checkAdmin/CheckAdmin';

/**
 * Common component import
 */
import LoadingCircle from '../../src/components/commons/LoadingCircle';
import { formStyles } from '../assets/styles/formStyles';

function SetTextField(props) {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;

  const { formIsBeingFilledOutHandler, ...fieldProps } = props;

  const onChange = React.useCallback(
    (event) => {
      setFieldValue(name, event.target.value);
      formIsBeingFilledOutHandler();
    },
    [setFieldValue, name]
  );

  return <MuiTextField {...fieldToTextField(fieldProps)} onChange={onChange} />;
}

let ProductTypeForm = (props) => {
  let navigate = useNavigate();
  const classes = formStyles;
  let params = props.router?.params;

  useEffect(() => {
    async function initComponent() {
      const { isAuthenticated, user } = props;

      if (checkUserIsSuperAdmin(isAuthenticated, user)) {
        if (params.productTypeId)
          await props.getProductTypeStart(params.productTypeId);
        else props.resetCurrentProductType();
      } else {
        navigate('/');
      }
    }
    initComponent();
  }, []);

  useEffect(() => {
    const { formIsFilledOut } = props;
    if (formIsFilledOut) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [props.formIsFilledOut]);

  const {
    currentProductType,
    loadingProductType,
    user,
    isAuthenticated,
    t,
    formIsFilledOut,
    loadingStandards,
    productTypeFormError,
    cookies,
  } = props;

  let csrfToken = cookies.cookies._csrf;

  if (loadingProductType || !currentProductType || loadingStandards) {
    return (
      <div>
        <LoadingCircle />
      </div>
    );
  }
  let updatingProductType = false;
  if (params.productTypeId) {
    updatingProductType = true;
  }

  let isSuperAdmin = false;
  if (checkUserIsSuperAdmin(isAuthenticated, user)) isSuperAdmin = true;

  if (!isSuperAdmin) {
    return (
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Unauthorized" />
          </Card>
        </Grid>
      </Grid>
    );
  }

  let initialValues = {
    name: currentProductType.name ? currentProductType.name : '',
    shortName: currentProductType.shortName ? currentProductType.shortName : '',
  };

  return (
    <>
      <BackButton />
      <Card>
        <CardHeader
          avatar={<img src={userIcon} style={{ maxHeight: 20 }} />}
          title={`${CommodityTypes.upperCase} information`}
        />
        <CardContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = 'Required';
              }
              if (!values.shortName) {
                errors.shortName = 'Required';
              }
              return errors;
            }}
            onSubmit={(values) => {
              let currentProductType = values;

              if (params?.productTypeId) {
                currentProductType._id = params.productTypeId;
              }

              updatingProductType
                ? props.setProductTypeStart(currentProductType, navigate)
                : props.createProductTypeStart(currentProductType, navigate);
              return false;
            }}
          >
            {({ submitForm, values, isSubmitting, errors }) => {
              let formCurrentProductType = values;
              return (
                <>
                  <Grid item xs={12}>
                    <input type="hidden" name="_csrf" value={{ csrfToken }} />
                    <Field
                      style={{ width: 300, marginTop: 20 }}
                      component={SetTextField}
                      label={t('Name')}
                      name="name"
                      type="text"
                      formIsBeingFilledOutHandler={
                        props.productTypeFormIsBeingFilledOut
                      }
                    />
                    {productTypeFormError && productTypeFormError['name'] && (
                      <div>
                        <span style={{ fontSize: 10, color: 'red' }}>
                          {productTypeFormError['name']}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      style={{ width: 300, marginTop: 20 }}
                      component={SetTextField}
                      label={t('Short Name')}
                      name="shortName"
                      type="text"
                      formIsBeingFilledOutHandler={
                        props.productTypeFormIsBeingFilledOut
                      }
                    />
                    <FieldError
                      error={
                        productTypeFormError &&
                        productTypeFormError['shortName']
                          ? productTypeFormError['shortName']
                          : ''
                      }
                    />
                    <FieldTooltip
                      fieldInError={
                        productTypeFormError &&
                        productTypeFormError['shortName']
                      }
                      description="Short Name is used to generate incremental unique product Ids for each commodity in the form ProductShortName-0000i"
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 30 }}>
                    <Button
                      data-testid={updatingProductType ? 'updateCommodityBtn' : 'createCommodityBtn' }
                      onClick={submitForm}
                      variant="contained"
                      color="primary"
                      disabled={loadingProductType || isSubmitting}
                    >
                      {updatingProductType
                        ? `Update ${CommodityTypes.lowerCase}`
                        : `Create ${CommodityTypes.lowerCase}`}
                    </Button>

                    {updatingProductType && formCurrentProductType && (
                      <Button
                      data-testid={'deleteCommodityBtn' }
                        style={{ marginLeft: 20 }}
                        variant={'contained'}
                        color="secondary"
                        disabled={loadingProductType}
                        onClick={() =>
                          props.deleteProductTypeStart(
                            params.productTypeId,
                            navigate
                          )
                        }
                      >{`Delete ${CommodityTypes.lowerCase}`}
                      </Button>
                    )}

                    {(loadingProductType || isSubmitting) && <LoadingCircle />}
                  </Grid>
                </>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = ({
  productTypesReducer,
  organizationsReducer,
  loginReducer,
  standardsReducer,
}) => {
  const {
    currentProductType,
    loadingProductType,
    formIsFilledOut,
    productTypeFormError,
  } = productTypesReducer;
  const { organizations } = organizationsReducer;
  const { standards, loadingStandards } = standardsReducer;
  const { isAuthenticated, user } = loginReducer;
  return {
    currentProductType,
    loadingProductType,
    isAuthenticated,
    user,
    organizations,
    formIsFilledOut,
    standards,
    loadingStandards,
    productTypeFormError,
  };
};

const mapDispatchToProps = {
  createProductTypeStart,
  deleteProductTypeStart,
  setProductTypeStart,
  getProductTypeStart,
  resetCurrentProductType,
  productTypeFormIsBeingFilledOut,
};

ProductTypeForm = withTranslation('translations')(ProductTypeForm);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(ProductTypeForm))
);
