/* eslint-disable */

import {
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
  openSuccessSnack,
  openErrorSnack,
} from './UtilsActions';
import { CommodityTypes } from '../enums/CommodityTypes';
import { handleError } from './ErrorActions';

export const getProductTypes = async () => {
  let response = await apiGet(`/api/product-types`);

  if (response.status >= 200 && response.status <= 299) {
    const productTypes = await response.json();
    if (!Array.isArray(productTypes))
      throw new Error(
        `Error retrieving ${CommodityTypes.lowerCasePlural}, returns : ` +
          JSON.stringify(productTypes)
      );
    return productTypes;
  } else {
    // Handle errors
    await handleError(response);
  }
};

export const getProductTypesStart = () => async (dispatch) => {
  dispatch({ type: 'GET_PRODUCT_TYPES_START' });

  try {
    let productTypes = await getProductTypes();
    dispatch({ type: 'GET_PRODUCT_TYPES_SUCCESS', payload: productTypes });
  } catch (error) {
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'GET_PRODUCT_TYPES_FAIL' });
  }
};

export const productTypeFormIsBeingFilledOut = () => (dispatch) => {
  dispatch({ type: 'PRODUCT_TYPE_FORM_IS_BEING_FILLED_OUT' });
};

export const addProductType = async (productType) => {
  let response = await apiPost(`/api/product-types`, productType);

  if (response.status >= 200 && response.status <= 299) {
    const productTypeCreated = await response.json();

    return productTypeCreated;
  } else {
    // Handle errors
    await handleError(response);
  }
};

export const createProductTypeStart =
  (productTypeToCreate, navigate) => async (dispatch) => {
    dispatch({ type: 'CREATE_PRODUCT_TYPE_START' });

    try {
      await addProductType(productTypeToCreate);
      dispatch(
        openSuccessSnack(`${CommodityTypes.upperCase} successfully created !`)
      );

      let productTypes = await getProductTypes();
      dispatch({ type: 'CREATE_PRODUCT_TYPE_SUCCESS', payload: productTypes });
      navigate('/product-types/');
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'CREATE_PRODUCT_TYPE_FAIL' });
    }
  };

export const getProductTypeStart = (productTypeId) => async (dispatch) => {
  dispatch({ type: 'GET_PRODUCT_TYPE_START' });

  try {
    let response = await apiGet(`/api/product-types/${productTypeId}`);

    if (response.status >= 200 && response.status <= 299) {
      const productType = await response.json();
      dispatch({ type: 'GET_PRODUCT_TYPE_SUCCESS', payload: productType });
    } else {
      // Handle errors
      await handleError(response);
    }
  } catch (error) {
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'GET_PRODUCT_TYPE_FAIL' });
  }
};

export const resetCurrentProductType = () => async (dispatch) => {
  dispatch({ type: 'RESET_PRODUCT_TYPE' });
};

export const setProductTypeStart =
  (productTypeToUpdate, navigate) => async (dispatch) => {
    dispatch({ type: 'SET_PRODUCT_TYPE_START' });

    try {
      let response = await apiPut(
        `/api/product-types/${productTypeToUpdate._id}`,
        productTypeToUpdate
      );

      if (response.status >= 200 && response.status <= 299) {
        const productTypeUpdate = await response.json();
        dispatch(
          openSuccessSnack(`${CommodityTypes.upperCase} successfully updated !`)
        );

        let productTypes = await getProductTypes();
        dispatch({ type: 'SET_PRODUCT_TYPE_SUCCESS', payload: productTypes });
        navigate('/product-types/');
      } else {
        // Handle errors
        await handleError(response);
      }
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'SET_PRODUCT_TYPE_FAIL' });
    }
  };

export const deleteProductTypeStart =
  (productTypeToDeleteId, navigate) => async (dispatch) => {
    dispatch({ type: 'DELETE_PRODUCT_TYPE_START' });

    try {
      let response = await apiDelete(
        `/api/product-types/${productTypeToDeleteId}`
      );

      if (response.status >= 200 && response.status <= 299) {
        const productTypeDeleted = await response.json();

        dispatch(
          openSuccessSnack(`${CommodityTypes.upperCase} successfully deleted !`)
        );

        let productTypes = await getProductTypes();
        dispatch({
          type: 'DELETE_PRODUCT_TYPE_SUCCESS',
          payload: productTypes,
        });
        navigate('/product-types/');
      } else {
        // Handle errors
        await handleError(response);
      }
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'DELETE_PRODUCT_TYPE_FAIL' });
    }
  };
