import { CertificatesDataType } from 'reducers/balancesReducer/types';

import {
  convertGramsInOz,
  convertOzInGrams,
} from '../../../../../../utils/convert';
import { checkUserIsSuperAdmin } from '../../../../../../utils/user/checkAdmin/CheckAdmin';
import { FormikPropsInitialValuesType } from '../types';

export const getInitialValues = (
  transferCertificatesData: CertificatesDataType,
  organizationsInTransferMenuRender: any[],
  activateOzConversion: boolean
): FormikPropsInitialValuesType => ({
  organization:
    transferCertificatesData &&
    transferCertificatesData._to &&
    transferCertificatesData._to._id
      ? transferCertificatesData._to._id
      : organizationsInTransferMenuRender &&
        organizationsInTransferMenuRender[0]
      ? organizationsInTransferMenuRender[0]._id
      : '',
  volume: activateOzConversion
    ? convertGramsInOz(transferCertificatesData._volume)
    : transferCertificatesData._volume,
});

export const retrieveCurrentTransferCertificatesData = (
  values: any,
  activateOzConversion: boolean,
  isAuthenticated: boolean,
  user: any,
  organizationsInTransferMenuFiltredForSuperAdmin: any[],
  organizationsInTransferMenuRender: any[]
) => {
  const currentTransferCertificatesData = values;
  if (activateOzConversion)
    currentTransferCertificatesData.volume = convertOzInGrams(values.volume);

  const _toOrganization = checkUserIsSuperAdmin(isAuthenticated, user)
    ? organizationsInTransferMenuFiltredForSuperAdmin.find((organization) => {
        return (
          organization._id === currentTransferCertificatesData.organization
        );
      })
    : organizationsInTransferMenuRender.find((organization) => {
        return (
          organization._id === currentTransferCertificatesData.organization
        );
      });
  currentTransferCertificatesData.organization = _toOrganization;
  return currentTransferCertificatesData;
};

export const validateHandler = (
  values: any,
  selectedBalanceData: any,
  activateOzConversion: boolean,
  quantityTypeUppercase: string
): any => {
  const errors: any = {};

  let quantity = values.volume;
  const maxQuantity = selectedBalanceData._quantity;
  let maxQuantityToDisplay = selectedBalanceData._quantity;
  if (activateOzConversion) {
    quantity = convertOzInGrams(values.volume);
    maxQuantityToDisplay = convertGramsInOz(selectedBalanceData._quantity);
  }
  if (!values.organization) {
    errors.organization = 'Required';
  }
  if (values.volume == null) {
    errors.volume = 'Required';
  } else if (isNaN(parseFloat(values.volume))) {
    errors.volume = 'Invalid number';
  } else if (values.volume === 0) {
    errors.volume = quantityTypeUppercase + ' cannot be null';
  } else if (values.volume % 1 !== 0) {
    errors.volume = quantityTypeUppercase + ' must be a whole number';
  } else if (quantity > maxQuantity) {
    errors.volume = 'Overcapacity. Max is ' + parseInt(maxQuantityToDisplay);
  }
  return errors;
};
