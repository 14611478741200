type FieldErrorPropsType = {
  error: string;
};
const FieldError = ({ error }: FieldErrorPropsType) => {
  if (!error) return <></>;
  return (
    <div>
      <span style={{ fontSize: 10, color: 'red' }}>{error}</span>
    </div>
  );
};

export default FieldError;
