import { Box, Button, Grid } from '@mui/material';

type AddAttributeButtonPropsType = {
  onAddAttribute: () => void;
};
const AddAttributeButton = ({
  onAddAttribute,
}: AddAttributeButtonPropsType) => {
  return (
    <Grid
      item
      xs={12}
      style={{
        backgroundColor: '#FFFFFF',
        paddingBottom: 20,
      }}
    >
      <Box
        sx={{ display: 'flex' }}
        style={{
          width: '100%',
          paddingBottom: 10,
          justifyContent: 'right',
        }}
      >
        <Button onClick={onAddAttribute} variant="contained" color="primary">
          + Add Attribute
        </Button>
      </Box>
    </Grid>
  );
};

export default AddAttributeButton;
