import { useSelector } from 'react-redux';
import { ProductTypesReducerStateType } from 'reducers/productTypesReducer/types';
import { RootState } from 'redux/store';

const useProductTypesSlices = (): ProductTypesReducerStateType => {
  //Redux ProductTypes slices
  const { productTypes, loadingProductTypes }: ProductTypesReducerStateType =
    useSelector((state: RootState) => state.productTypesReducer);
  return { productTypes, loadingProductTypes };
};

export default useProductTypesSlices;
