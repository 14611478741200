import { FC } from 'react';

import { Button, CircularProgress, Grid } from '@mui/material';
import { TextField as MaterialUiTextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getBalancesByStandardAndStartDateAndEndDates } from 'components/Balances/Redux/CustomHooks/helpers/helpers';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import { DEFAULT_PAGE_SIZE } from 'components/Transactions/utils/constants';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import { setEndDate, setStartDate } from '../../../../actions';
import useBalancesChildComponentsSlices from '../../Redux/CustomHooks/useBalancesChildComponentsSlices';

const DateFormContainer: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    loading,
    showDateFilter,
    startDate,
    endDate,
    customButtonPressed,
    selectedStandardId,
  }: UseBalancesChildComponentsSlicesType = useBalancesChildComponentsSlices();

  const handleStartDateChange = (newValue: any): void => {
    dispatch(setStartDate(newValue));
  };
  const handleEndDateChange = (newValue: any): void => {
    dispatch(setEndDate(newValue));
  };

  if (!showDateFilter) return <></>;
  return (
    <Grid
      item
      xs={12}
      style={{
        backgroundColor: '#F5F8FA',
        borderRadius: 8,
        paddingBottom: 16,
      }}
      data-testid="date-form-container-id"
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <DesktopDatePicker
              label="Start date"
              inputFormat="MM/DD/YYYY"
              value={startDate}
              onChange={handleStartDateChange}
              renderInput={(params) => <MaterialUiTextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <DesktopDatePicker
              label="End date"
              inputFormat="MM/DD/YYYY"
              value={endDate}
              onChange={handleEndDateChange}
              renderInput={(params) => <MaterialUiTextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} lg={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={async () => {
                dispatch(
                  await getBalancesByStandardAndStartDateAndEndDates(
                    selectedStandardId as string,
                    DEFAULT_PAGE_SIZE as number,
                    customButtonPressed,
                    startDate,
                    endDate
                  )
                );
              }}
              type="submit"
            >
              {loading ? <CircularProgress size={24} /> : 'Ok'}
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default DateFormContainer;
