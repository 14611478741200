import ProductDialog from '../../components/Balances/CustomComponents/Dialogs/ProductDialog/ProductDialog';
import { withRouter } from '../../utils/migrationHelper';

import TransactionsDialog from './CustomComponents/Dialogs/TransactionsDialog';
import TransactionsTable from './CustomComponents/Tables/TransactionsTable';
import TransactionsToolbar from './CustomComponents/Toolbar/TransactionsToolbar/TransactionToolbar';

import './Styles/index.css';

const Transactions = () => {
  return (
    <>
      <div className="transactions" data-testid={'transactions-table'}>
        <TransactionsToolbar />
        <TransactionsTable />
      </div>
      <ProductDialog />
      <TransactionsDialog />
    </>
  );
};

export default withRouter(Transactions);
