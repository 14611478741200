import Card from '@mui/material/Card';

import BackButton from '../../../commons/BackButton/BackButton';

import ProductDetailsCardContent from './CustomComponents/Card/ProductDetails/CardContent/ProductDetailsCardContent';
import ProductDetailsCardTitle from './CustomComponents/Card/ProductDetails/CardTitle/ProductDetailsCardTitle';

const GasProductFormCard = () => {
  return (
    <>
      <BackButton />
      <Card key={`productDetailsCard`}>
        <ProductDetailsCardTitle />
        <ProductDetailsCardContent />
      </Card>
    </>
  );
};

export default GasProductFormCard;
