import { DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

const RemoveCertificateDialogTitle = () => {
  const { t } = useTranslation();

  return (
    <DialogTitle id="responsive-dialog-title">
      {t('Remove certificate')}
    </DialogTitle>
  );
};

export default RemoveCertificateDialogTitle;
