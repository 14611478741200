import React from 'react';

import { MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

type OrganizationSelectProps = {
  id: string;
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: { _id: string; name: string }[];
  style?: React.CSSProperties;
  variant?: 'outlined' | 'filled' | 'standard';
};

const OrganizationSelect: React.FC<OrganizationSelectProps> = ({
  id,
  label,
  value,
  onChange,
  options,
  style,
  variant = 'outlined',
}) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onChange(event);
  };

  return (
    <TextField
      id={id}
      select
      label={t(label)}
      value={value}
      onChange={handleChange}
      margin="normal"
      style={style}
      variant={variant}
      fullWidth
    >
      {options.map((option) => (
        <MenuItem key={option._id} value={option._id}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default OrganizationSelect;
