import { useEffect, useRef } from 'react';

import { JSONEditor } from 'vanilla-jsoneditor';
import './VanillaJSONEditor.css';

export default function VanillaJSONEditor(props: any) {
  const refContainer: any = useRef(null);
  const refEditor = useRef(null);

  interface SvelteComponentConstructor {
    target: HTMLElement;
    props?: any;
  }

  useEffect(() => {
    // create editor
    refEditor.current = new JSONEditor({
      target: refContainer.current,
      props: {},
    } as SvelteComponentConstructor) as any;

    return () => {
      // destroy editor
      if (refEditor.current) {
        (refEditor.current as JSONEditor).destroy();
        refEditor.current = null;
      }
    };
  }, []);

  // update props
  useEffect(() => {
    if (refEditor.current) {
      (refEditor.current as JSONEditor).updateProps(props);
    }
  }, [props]);

  return <div className="vanilla-jsoneditor-react" ref={refContainer}></div>;
}
