import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';

import DeprecatedCard from '../DeprecatedCard/DeprecatedCard';

import ChartContent from './ChartContent/ChartContent';
import ChartTitle from './ChartTitle/ChartTitle';

const ChartCard = (): JSX.Element => {
  return (
    <GenericGrid isCard isItem xsLength={12} dataTestId="product-chart-id">
      <ChartTitle />
      <DeprecatedCard />
      <ChartContent />
    </GenericGrid>
  );
};

export default ChartCard;
