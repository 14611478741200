import GenericTable from 'components/GenericComponents/Tables/GenericTable/GenericTable';
import { GenericTablePropsType } from 'components/GenericComponents/Tables/GenericTable/types';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';

import { getDataGridAssetMeteringColumns } from './utils/columns';

const AssetsMeteringTable = () => {
  const { assetsMeteringToDisplayInDialog } = useProductSlicesCombiner();
  const dataGridAssetMeteringColumns = getDataGridAssetMeteringColumns();

  const assetsMeteringProps: GenericTablePropsType = {
    tableColumns: dataGridAssetMeteringColumns,
    tableRows: assetsMeteringToDisplayInDialog as any[],
  };
  return <GenericTable {...assetsMeteringProps} />;
};

export default AssetsMeteringTable;
