import GenericModalContent from 'components/Administration/Generic/Modal/Content/GenericModalContent';

import useDecreaseBalanceFormModalConfig from '../../Configuration/useDecreaseBalanceFormModalConfig';

const ModalContentDecreaseBalanceForm = () => {
  const { header, content } = useDecreaseBalanceFormModalConfig();

  return <GenericModalContent header={header} content={content} />;
};

export default ModalContentDecreaseBalanceForm;
