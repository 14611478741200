/* eslint-disable */

import moment from 'moment';
import { AnyAction } from 'redux';
import { multipleFilter } from '../../utils/arrayHelper';
import { BalancesReducerStateType } from './types';
import { DEFAULT_PAGE_SIZE } from 'components/Transactions/utils/constants';

const INITIAL_STATE: BalancesReducerStateType = {
  allBalances: [],
  balances: [],
  loading: false,
  loadingTransferCertificate: false,
  loadingAllocateCertificate: false,
  page: 0,
  rowsPerPage: 10,
  transferCertificatesFormOpen: false,
  selectedBalanceData: {
    _volume: 0,
    _origin: null,
    _month: null,
    _year: null,
  },
  transferCertificatesData: {
    _to: null,
    _volume: 0,
    _origin: null,
    _month: null,
    _year: null,
  },
  allocateCertificatesFormOpen: false,
  allocateCertificatesData: {
    _to: null,
    _volume: 0,
    _origin: null,
    _month: null,
    _year: null,
    _showRetailerOnPdfCertificate: false,
  },
  infoOpen: false,
  displayTransferCertificateFormInDialog: true,
  displayTransferCertificateWarningInDialog: false,
  displayAllocateCertificateFormInDialog: true,
  displayAllocateCertificateWarningInDialog: false,
  transferCertificatesFormError: undefined,
  allocateCertificatesFormError: undefined,
  fromStartButtonPressed: true,
  customButtonPressed: false,
  showDateFilter: false,
  selectedStandardId: undefined,
  selectedStandardType: undefined,
  selectedProductTypeId: undefined,
  dialogStartDateOpen: false,
  dialogEndDateOpen: false,
  startDate: moment().toDate(),
  endDate: moment().toDate(),
  pageSize: 100,
  openProductDialog: false,
  allBalancesCount: 0,
  paginatedBalancesCount: 0,
  defaultBalancesPaginationThreshold: 500,
};

const balancesReducer = (
  state: BalancesReducerStateType = INITIAL_STATE,
  action: AnyAction
) => {
  switch (action.type) {
    //Getting Balances
    case 'GET_BALANCES_START':
      return { ...state, loading: true };

    case 'GET_BALANCES_SUCCESS':
      let balanceSuccessStandardIdFilter = state.selectedStandardId;
      let balanceSuccessStandardTypeFilter = state.selectedStandardType;
      let successAllBalances = action.payload;
      let successFilteredBalances = successAllBalances;
      if (balanceSuccessStandardIdFilter) {
        successFilteredBalances = successFilteredBalances.filter(
          (balance: any) => {
            let isBalanceSelected = balance?._origin?.standard
              ? balance._origin.standard._id === balanceSuccessStandardIdFilter
              : false;
            return isBalanceSelected;
          }
        );
      }
      if (balanceSuccessStandardTypeFilter) {
        successFilteredBalances = successFilteredBalances.filter(
          (balance: any) => {
            let isBalanceSelected = balance?._origin?.standard
              ? balance._origin.standard.standardType ===
                balanceSuccessStandardTypeFilter
              : false;
            return isBalanceSelected;
          }
        );
      }

      return {
        ...state,
        balances: successFilteredBalances,
        allBalances: successAllBalances,
        loading: false,
      };

    case 'GET_BALANCES_FAIL':
      return { ...state, loading: false };

    case 'SHOW_RETAILER_FLAG_CHANGED':
      return {
        ...state,
        allocateCertificatesData: {
          ...state.allocateCertificatesData,
          _showRetailerOnPdfCertificate: action.payload,
        },
      };

    //Transfer certificates
    case 'TRANSFER_ORIGIN_CHANGED':
      return {
        ...state,
        transferCertificatesData: {
          ...state.transferCertificatesData,
          _origin: action.payload,
        },
      };

    case 'TRANSFER_FROM_CHANGED':
      return {
        ...state,
        transferCertificatesData: {
          ...state.transferCertificatesData,
          _from: action.payload,
        },
      };

    case 'TRANSFER_MONTH_CHANGED':
      return {
        ...state,
        transferCertificatesData: {
          ...state.transferCertificatesData,
          _month: action.payload,
        },
      };

    case 'TRANSFER_YEAR_CHANGED':
      return {
        ...state,
        transferCertificatesData: {
          ...state.transferCertificatesData,
          _year: action.payload,
        },
      };

    case 'TRANSFER_TO_CHANGED':
      return {
        ...state,
        transferCertificatesData: {
          ...state.transferCertificatesData,
          _to: action.payload,
        },
      };

    case 'TRANSFER_VOLUME_CHANGED':
      return {
        ...state,
        transferCertificatesData: {
          ...state.transferCertificatesData,
          _volume: action.payload,
        },
      };

    case 'SET_SELECTED_BALANCE_DATA':
      return {
        ...state,
        selectedBalanceData: action.payload,
      };

    case 'TRANSFER_CERTIFICATES_FORM_ERROR':
      return {
        ...state,
        transferCertificatesFormError: action.payload,
      };

    case 'ALLOCATE_CERTIFICATES_FORM_ERROR':
      return {
        ...state,
        allocateCertificatesFormError: action.payload,
      };

    case 'DISPLAY_WARNING_TRANSFER_CERTIFICATES':
      return {
        ...state,
        displayTransferCertificateFormInDialog: false,
        transferCertificatesData: {
          ...state.transferCertificatesData,
          _volume: action.payload.volume,
          _to: action.payload.organization,
        },
      };

    case 'FROM_START_BUTTON_PRESSED':
      return {
        ...state,
        customButtonPressed: false,
        fromStartButtonPressed: true,
      };

    case 'CUSTOM_BUTTON_PRESSED':
      return {
        ...state,
        fromStartButtonPressed: false,
        customButtonPressed: true,
      };

    case 'DISPLAY_DATE_FILTER':
      return {
        ...state,
        showDateFilter: true,
      };

    case 'HIDE_DATE_FILTER':
      return {
        ...state,
        showDateFilter: false,
      };

    case 'SET_BALANCE_PRODUCT_TYPE':
      let balanceProductTypeIdFilter = action.payload;

      let productTypefilters = [
        { filter: 'productType', value: balanceProductTypeIdFilter },
        { filter: 'standard', value: state.selectedStandardId },
        { filter: 'standardType', value: state.selectedStandardType },
      ];

      let productTypefilteredBalances = multipleFilter(
        state.allBalances,
        productTypefilters,
        true
      );
      return {
        ...state,
        selectedProductTypeId: action.payload,
        balances: productTypefilteredBalances,
      };

    case 'SET_BALANCE_STANDARD':
      let balanceStandardIdFilter = action.payload;

      let standardFilters = [
        { filter: 'productType', value: state.selectedProductTypeId },
        { filter: 'standard', value: balanceStandardIdFilter },
        { filter: 'standardType', value: undefined },
      ];

      let standardFilteredBalances = multipleFilter(
        state.allBalances,
        standardFilters,
        true
      );

      return {
        ...state,
        selectedStandardId: action.payload,
        balances: standardFilteredBalances,
      };

    case 'SET_BALANCE_STANDARD_TYPE':
      let balanceStandardTypeFilter = action.payload;

      let standardTypeFilters = [
        { filter: 'productType', value: state.selectedProductTypeId },
        { filter: 'standard', value: undefined },
        { filter: 'standardType', value: balanceStandardTypeFilter },
      ];

      let standardTypeFilteredBalances = multipleFilter(
        state.allBalances,
        standardTypeFilters,
        true
      );

      return {
        ...state,
        selectedStandardType: action.payload,
        balances: standardTypeFilteredBalances,
      };

    case 'TOGGLE_START_DATE_DIALOG':
      return {
        ...state,
        dialogStartDateOpen: !state.dialogStartDateOpen,
      };

    case 'TOGGLE_END_DATE_DIALOG':
      return {
        ...state,
        dialogEndDateOpen: !state.dialogEndDateOpen,
      };

    case 'SET_START_DATE':
      return {
        ...state,
        startDate: action.payload,
      };

    case 'SET_END_DATE':
      return {
        ...state,
        endDate: action.payload,
      };

    case 'DISPLAY_FORM_TRANSFER_CERTIFICATES':
      return { ...state, displayTransferCertificateFormInDialog: true };

    case 'TOGGLE_TRANSFER_CERTIFICATES_FORM':
      const transferCertificatesFormOpen = !state.transferCertificatesFormOpen;
      return {
        ...state,
        transferCertificatesFormOpen,
        displayTransferCertificateFormInDialog: true,
      };

    case 'OPEN_TRANSFER_CERTIFICATES_FORM':
      return {
        ...state,
        transferCertificatesFormOpen: true,
        displayTransferCertificateFormInDialog: true,
        transferCertificatesFormError: undefined,
      };

    case 'CLOSE_TRANSFER_CERTIFICATES_FORM':
      return {
        ...state,
        transferCertificatesFormOpen: false,
        displayTransferCertificateFormInDialog: true,
      };

    case 'TRANSFERING_CERTIFICATES_START':
      return { ...state, loadingTransferCertificate: true };

    case 'TRANSFERING_CERTIFICATES_SUCCESS':
      return {
        ...state,
        loadingTransferCertificate: false,
      };

    case 'TRANSFERING_CERTIFICATES_FAIL':
      return { ...state, loadingTransferCertificate: false };

    //Retire certificates
    case 'ALLOCATE_ORIGIN_CHANGED':
      return {
        ...state,
        allocateCertificatesData: {
          ...state.allocateCertificatesData,
          _origin: action.payload,
        },
      };

    case 'ALLOCATE_FROM_CHANGED':
      return {
        ...state,
        allocateCertificatesData: {
          ...state.allocateCertificatesData,
          _from: action.payload,
        },
      };

    case 'ALLOCATE_MONTH_CHANGED':
      return {
        ...state,
        allocateCertificatesData: {
          ...state.allocateCertificatesData,
          _month: action.payload,
        },
      };

    case 'ALLOCATE_YEAR_CHANGED':
      return {
        ...state,
        allocateCertificatesData: {
          ...state.allocateCertificatesData,
          _year: action.payload,
        },
      };

    case 'ALLOCATE_TO_CHANGED':
      return {
        ...state,
        allocateCertificatesData: {
          ...state.allocateCertificatesData,
          _to: action.payload,
        },
      };

    case 'ALLOCATE_VOLUME_CHANGED':
      return {
        ...state,
        allocateCertificatesData: {
          ...state.allocateCertificatesData,
          _volume: action.payload,
        },
      };

    case 'HIDE_PRODUCT_DIALOG':
      return {
        ...state,
        openProductDialog: false,
      };

    case 'OPEN_PRODUCT_DIALOG':
      return {
        ...state,
        openProductDialog: true,
      };

    case 'DISPLAY_WARNING_ALLOCATE_CERTIFICATES':
      return {
        ...state,
        displayAllocateCertificateFormInDialog: false,
        allocateCertificatesData: {
          ...state.allocateCertificatesData,
          _volume: action.payload.volume,
          _to: action.payload.organization,
          _showRetailerOnPdfCertificate: action.payload.showRetailer,
        },
      };

    case 'DISPLAY_FORM_ALLOCATE_CERTIFICATES':
      return { ...state, displayAllocateCertificateFormInDialog: true };

    case 'TOGGLE_ALLOCATE_CERTIFICATES_FORM':
      const allocateCertificatesFormOpen = !state.allocateCertificatesFormOpen;
      return {
        ...state,
        allocateCertificatesFormOpen,
        displayAllocateCertificateFormInDialog: true,
      };

    case 'OPEN_ALLOCATE_CERTIFICATES_FORM':
      return {
        ...state,
        allocateCertificatesFormOpen: true,
        displayAllocateCertificateFormInDialog: true,
        allocateCertificatesFormError: undefined,
      };

    case 'CLOSE_ALLOCATE_CERTIFICATES_FORM':
      return {
        ...state,
        allocateCertificatesFormOpen: false,
        displayAllocateCertificateFormInDialog: true,
      };

    case 'ALLOCATE_CERTIFICATES_START':
      return { ...state, loadingAllocateCertificate: true };

    case 'ALLOCATE_CERTIFICATES_SUCCESS':
      return {
        ...state,
        loadingAllocateCertificate: false,
      };

    case 'ALLOCATE_CERTIFICATES_FAIL':
      return { ...state, loadingAllocateCertificate: false };

    case 'LOGOUT_SUCCESS':
      return INITIAL_STATE;

    case 'GET_BALANCES_TOTAL_COUNT_SUCCESS':
      return {
        ...state,
        paginatedBalancesCount: action.payload,
        loading: false,
      };

    case 'GET_ALL_BALANCES_TOTAL_COUNT_SUCCESS':
      return {
        ...state,
        allBalancesCount: action.payload,
        loading: false,
      };

    case 'SET_PAGE_SIZE':
      return {
        ...state,
        pageSize: action.payload,
      };

    case 'RESET_PAGE_SIZE':
      return {
        ...state,
        pageSize: DEFAULT_PAGE_SIZE,
      };
    case 'SET_DEFAULT_BALANCES_PAGINATION_THRESHOLD':
      return {
        ...state,
        defaultBalancesPaginationThreshold: action.payload,
      };

    default:
      return state;
  }
};

export default balancesReducer;
