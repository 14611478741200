import Link from '@mui/material/Link';

type LinkCellPropType = {
  value: string;
  onClick?: (params: any) => any;
};

const LinkCellContent = ({ value, onClick }: LinkCellPropType) => {
  return (
    <Link
      data-testid="link-cell-content"
      component="button"
      variant="body2"
      onClick={onClick ? onClick : () => console.log('no click function')}
      style={{ textDecoration: 'none' }}
    >
      {value}
    </Link>
  );
};

export default LinkCellContent;
