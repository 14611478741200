import {
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import { closeCreateListItemDialog } from 'actions';
import useFormStandardsSlicesCombiner from 'components/FormStandards/Redux/Slices/Combiner/useFormStandardsCombiner';
import { Field, FormikValues, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

type CreateObjectDialogPropsType = { index: number };
const CreateObjectDialog = ({ index }: CreateObjectDialogPropsType) => {
  const { createListItemDialogOpen } = useFormStandardsSlicesCombiner();
  const { setValues, values } = useFormikContext<FormikValues>();
  const dispatch: AppDispatch = useDispatch();
  return (
    <Dialog
      disableEnforceFocus
      fullScreen={false}
      open={createListItemDialogOpen as boolean}
      aria-labelledby="action-dialog-title"
    >
      <DialogContent>
        <CardHeader
          title={`Create a new item`}
          subheader={`Create a new object {id, value} for your list`}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                style={{ width: 300, marginTop: 20 }}
                component={TextField}
                label="Id"
                name={`idToCreate`}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                style={{ width: 300, marginTop: 20 }}
                component={TextField}
                label="Value"
                name={`valueToCreate`}
                type="text"
              />
            </Grid>
          </Grid>
        </CardContent>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => dispatch(closeCreateListItemDialog())}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            const newValues = { ...values };
            const newListItems = newValues.infoTexts[index]?.listItems
              ? [...newValues.infoTexts[index].listItems]
              : [];
            newListItems.push({
              ref: newValues.idToCreate,
              value: newValues.valueToCreate,
              id: newListItems.length,
            });
            newValues.infoTexts[index].listItems = newListItems;
            setValues(newValues);
            dispatch(closeCreateListItemDialog());
          }}
          variant="contained"
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateObjectDialog;
