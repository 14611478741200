import { CardContent, CardHeader, DialogContent, Grid } from '@mui/material';

import { ModalStructurePropsType } from '../../Form/utils/types/types';

const GenericModalContent = ({ header, content }: ModalStructurePropsType) => {
  return (
    <DialogContent style={{ width: 800 }}>
      {header?.title && <CardHeader title={header?.title} />}
      <CardContent>
        <Grid container spacing={2}>
          {content?.map((section, sectionIndex) => (
            <Grid container item spacing={2} key={sectionIndex}>
              {section.map(
                ({ component: Component, condition, ...restProps }, index) => {
                  const shouldRender = condition ? condition() : true;
                  return shouldRender ? (
                    <Component key={index} {...restProps} />
                  ) : (
                    <></>
                  );
                }
              )}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </DialogContent>
  );
};

export default GenericModalContent;
