import { Button, Grid } from '@mui/material';
import { deleteStandardStart } from 'actions';
import useFormStandardsSlicesCombiner from 'components/FormStandards/Redux/Slices/Combiner/useFormStandardsCombiner';
import { FormikValues, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';

type DeleteStandardPropsType = {
  params: any;
};
const DeleteStandard = ({ params }: DeleteStandardPropsType) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { loadingStandard } = useFormStandardsSlicesCombiner();
  const { values } = useFormikContext<FormikValues>();

  const updatingStandard = Boolean(params?.standardId);
  if (!updatingStandard || !values) return null;

  return (
    <Grid item xs={12} md={6} lg={3}>
      <Button
        variant={'contained'}
        color="secondary"
        disabled={loadingStandard}
        onClick={() =>
          dispatch(deleteStandardStart(params.standardId, navigate))
        }
      >
        Delete standard
      </Button>
    </Grid>
  );
};

export default DeleteStandard;
