/* eslint-disable */

import React, { useEffect } from 'react';
import { useTheme, css, ClassNames } from '@emotion/react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { DataGridPro as XGrid } from '@mui/x-data-grid-pro';
import { getFullname, getOrganizationName } from '../../utils/datagridHelpers';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { withRouter } from '../../utils/migrationHelper';
import { withTranslation } from 'react-i18next';

import { listStyles } from '../../assets/styles/listStyles';

import { getOrganizationUsers } from '../../actions';

let OrganizationUsersList = ({
  isAuthenticated,
  getOrganizationUsers,
  organizationUsers,
  loading,
  t,
}) => {
  let navigate = useNavigate();
  let params = useParams();
  const classes = listStyles;

  useEffect(() => {
    if (isAuthenticated) {
      getOrganizationUsers(params.organizationId);
    } else {
      navigate('/');
    }
  }, []);

  const dataGridRows = organizationUsers.map((u) => {
    return { ...u, id: u.user_id };
  });
  const dataGridColumns = [
    {
      field: '_fullname',
      headerName: 'Name',
      editable: false,
      width: 300,
      valueGetter: getFullname,
    },
    {
      field: '_organizationName',
      headerName: 'Organization',
      editable: false,
      width: 600,
      valueGetter: getOrganizationName,
    },
  ];

  return (
    <ClassNames>
      {({ css }) => (
        <div className={css(classes.root)}>
          <Paper className={css(classes.root)} style={{ marginTop: 0 }}>
            <div
              style={{
                height: document.documentElement.clientHeight - 200,
                paddingBottom: 83,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <Toolbar className={css(classes.root)}>
                <Box flexGrow={1}>
                  <div className={css(classes.title)}>
                    <Typography variant="title">
                      {t('Users')}
                      {loading && (
                        <CircularProgress
                          size={16}
                          style={{ marginLeft: 10 }}
                        />
                      )}
                    </Typography>
                  </div>
                </Box>
              </Toolbar>
              <XGrid
                pagination
                disableSelectionOnClick={true}
                loading={loading}
                rows={dataGridRows}
                columns={dataGridColumns}
                onRowClick={(rowData) => {
                  let clickedUserId = rowData.row.identities[0].user_id;
                  navigate('/users/read/' + clickedUserId);
                }}
              />
            </div>
          </Paper>
        </div>
      )}
    </ClassNames>
  );
};

const mapStateToProps = ({ organizationsReducer, loginReducer }) => {
  const { organizationUsers, loading } = organizationsReducer;
  const { isAuthenticated, user } = loginReducer;
  return {
    organizationUsers,
    loading,
    isAuthenticated,
    user,
  };
};

const mapDispatchToProps = {
  getOrganizationUsers,
};

OrganizationUsersList = withTranslation('translations')(OrganizationUsersList);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationUsersList)
);
