import { Button } from '@mui/material';
import useCreateOrUpdateProductSlicesCombiner from 'components/CreateOrUpdateProduct/Redux/Slices/useCreateOrUpdateProductSlicesCombiner';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';
import { useFormikContext } from 'formik';

type DataAcquisitonFormButtonPropsType = {
  buttonName: string;
};

const DataAcquisitonFormButton = ({
  buttonName,
}: DataAcquisitonFormButtonPropsType) => {
  const { loading } = useCreateOrUpdateProductSlicesCombiner();
  const { submitForm } = useFormikContext();
  return (
    <GenericGrid isItem xsLength={12}>
      <Button
        variant="outlined"
        color="primary"
        component="span"
        disabled={loading}
        style={{
          marginTop: 13,
          border: '1px solid rgba(248,119,51, 0.5)',
          borderRadius: 4,
        }}
        onClick={submitForm}
      >
        {buttonName}
      </Button>
    </GenericGrid>
  );
};

export default DataAcquisitonFormButton;
