/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@mui/material';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import moment from 'moment';

import warningIcon from '../../../../../assets/images/icons/warning.png';

const DeprecatedCard = (): JSX.Element => {
  const { currentProduct } = useProductSlicesCombiner();
  if (!currentProduct) return <></>;

  const {
    active,
    archiveComment,
    archiveDate,
    deprecationDate,
    deprecated,
    deprecationComment,
  } = currentProduct;

  if (deprecated && deprecationDate) {
    return (
      <Typography variant="body2" style={{ paddingBottom: 30 }}>
        <img
          src={warningIcon}
          style={{ marginLeft: 20, marginRight: 10, width: 14 }}
          alt=""
        />
        {`Deprecated on : ${moment(deprecationDate).format('YYYY-MM-DD')} - ${
          currentProduct.deprecationComment
        }`}
      </Typography>
    );
  }

  if (!active && archiveComment && archiveDate)
    return (
      <Typography variant="body2" style={{ paddingBottom: 30 }}>
        <img
          src={warningIcon}
          style={{ marginLeft: 20, marginRight: 10, width: 14 }}
          alt=""
        />
        {`Archived on : ${moment(archiveDate).format('YYYY-MM-DD')} - ${
          currentProduct.archiveComment
        }`}
      </Typography>
    );

  return <></>;
};
export default DeprecatedCard;
