/* eslint-disable */

import { enums } from '../enums/';
import { apiGet, apiPut, apiPost } from './UtilsActions';
import { handleErrors } from './ErrorActions';
import { getCurrentUserOrganization } from './AuthActions';
import { sortOrganizationsByName } from '../utils/organization/sortOrganizations';
import { openSuccessSnack, openErrorSnack } from './UtilsActions';

export const createOrganizationStart =
  (currentOrganization, file, navigate, locationState) => async (dispatch) => {
    dispatch({ type: 'CREATE_ORGANIZATION_START' });

    const createdOrganization = await createOrganization(
      currentOrganization
    ).catch((error) => {
      //Catch generic error
      console.error(error);
      dispatch({ type: 'CREATE_ORGANIZATION_FAIL' });
      dispatch(openErrorSnack(error?.message));
    });

    if (createdOrganization && createdOrganization.error) {
      //Catch form error
      dispatch({ type: 'CREATE_ORGANIZATION_FAIL' });
      dispatch({
        type: 'ORGANIZATION_FORM_ERROR',
        payload: createdOrganization.error,
      });
    } else if (createdOrganization) {
      //No form error
      if (file) {
        try {
          createdOrganization.pictureFileName = await uploadOrganizationPicture(
            file,
            createdOrganization._id
          );
        } catch (error) {
          dispatch(openErrorSnack('An error occured when uploading picture'));
        }

        await setOrganization(
          createdOrganization._id,
          createdOrganization
        ).catch((error) => {
          dispatch(
            openErrorSnack(
              'An error occured, try later or refresh the page: ' +
                error.message
            )
          );
        });
      }

      createOrganizationSuccess(
        createdOrganization._id,
        navigate,
        dispatch,
        locationState
      );
    }
  };

export const createOrganizationSuccess = (
  organizationId,
  navigate,
  dispatch,
  locationState
) => {
  dispatch({ type: 'CREATE_ORGANIZATION_SUCCESS' });
  dispatch(openSuccessSnack('New organization created !'));
  if (locationState && locationState.getBackToAllocatePopUp) {
    // pass popup values to balances page through location state
    navigate('/balances', {
      state: {
        allocateVolume: locationState.allocateVolume,
        allocateShowRetailerFlag: locationState.allocateShowRetailerFlag,
        blockBalancesRendering: true,
        displayAllocatePopUp: true,
      },
    });
  } else {
    navigate('/organizations/read/' + organizationId);
  }
};

export const updateOrganizationStart =
  (organizationId, organization, file, navigate) => async (dispatch) => {
    dispatch({ type: 'UPDATE_ORGANIZATION_START' });

    let updatedOrganization = await setOrganization(
      organizationId,
      organization
    ).catch((error) => {
      //Catch generic error
      console.error(error);
      dispatch({ type: 'UPDATE_ORGANIZATION_FAIL' });
      dispatch(openErrorSnack(error?.message));
    });

    if (updatedOrganization && updatedOrganization.error) {
      //Catch form error
      dispatch({ type: 'UPDATE_ORGANIZATION_FAIL' });
      dispatch({
        type: 'ORGANIZATION_FORM_ERROR',
        payload: updatedOrganization.error,
      });
    } else if (updatedOrganization) {
      if (file) {
        try {
          updatedOrganization.pictureFileName = await uploadOrganizationPicture(
            file,
            organizationId
          );
        } catch (error) {
          dispatch(openErrorSnack('An error occured when uploading picture'));
        }

        await setOrganization(organizationId, updatedOrganization).catch(
          (error) => {
            dispatch(
              openErrorSnack(
                'An error occured, try later or refresh the page: ' +
                  error.message
              )
            );
          }
        );
      }
      updateOrganizationSuccess(organizationId, navigate, dispatch);
    }
  };

export const archiveOrganizationStart =
  (organizationId, navigate) => async (dispatch) => {
    dispatch({ type: 'ARCHIVE_ORGANIZATION_START' });

    let archivedOrganization = await archiveOrganization(organizationId).catch(
      (error) => {
        //Catch generic error
        console.error(error);
        dispatch({ type: 'ARCHIVE_ORGANIZATION_FAIL' });
        dispatch(openErrorSnack(error?.message));
      }
    );

    if (archivedOrganization && archivedOrganization.error) {
      //Catch form error
      dispatch({ type: 'ARCHIVE_ORGANIZATION_FAIL' });
      dispatch({
        type: 'ORGANIZATION_FORM_ERROR',
        payload: archivedOrganization.error,
      });
    } else if (archivedOrganization) {
      dispatch({ type: 'ARCHIVE_ORGANIZATION_SUCCESS' });
      dispatch(openSuccessSnack('Organization archived !'));
      navigate('/organizations/');
    }
  };

export const restoreOrganizationStart =
  (organizationId, navigate) => async (dispatch) => {
    dispatch({ type: 'RESTORE_ORGANIZATION_START' });

    let restoredOrganization = await restoreOrganization(organizationId).catch(
      (error) => {
        //Catch generic error
        console.error(error);
        dispatch({ type: 'RESTORE_ORGANIZATION_FAIL' });
        dispatch(openErrorSnack(error?.message));
      }
    );

    if (restoredOrganization && restoredOrganization.error) {
      //Catch form error
      dispatch({ type: 'RESTORE_ORGANIZATION_FAIL' });
      dispatch({
        type: 'ORGANIZATION_FORM_ERROR',
        payload: restoredOrganization.error,
      });
    } else if (restoredOrganization) {
      dispatch({ type: 'RESTORE_ORGANIZATION_SUCCESS' });
      dispatch(openSuccessSnack('Organization restored !'));
      navigate('/organizations/');
    }
  };

export const updateOrganizationSuccess = (
  organizationId,
  navigate,
  dispatch
) => {
  dispatch({ type: 'UPDATE_ORGANIZATION_SUCCESS' });
  dispatch(openSuccessSnack('Organization updated !'));
  navigate('/organizations/read/' + organizationId);
};

export const organizationPictureChanged = (file) => (dispatch) => {
  dispatch({ type: 'ORGANIZATION_PICTURE_CHANGED', payload: file });
};

const uploadOrganizationPicture = async (file, organizationId) => {
  var formData = new FormData();
  formData.append('files', file);
  let uploadPictureUrl =
    process.env.REACT_APP_API_URL +
    '/api/organizations/upload-file/' +
    organizationId;
  let options = { method: 'POST', credentials: 'include', body: formData };

  const result = await fetch(uploadPictureUrl, options)
    .then(handleErrors)
    .then((res) => res.json());

  return result;
};

export const getOrganizationStart = (organizationId) => async (dispatch) => {
  dispatch({ type: 'GET_ORGANIZATION_START' });
  try {
    let organization = await getOrganization(organizationId);
    dispatch({ type: 'GET_ORGANIZATION_SUCCESS', payload: organization });

    return organization;
  } catch (error) {
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'GET_ORGANIZATION_FAIL' });
  }
};

export const getOrganizationFormStart =
  (organizationId) => async (dispatch) => {
    dispatch({ type: 'GET_ORGANIZATION_FORM_START' });

    try {
      let organization = await getOrganization(organizationId);

      dispatch({
        type: 'GET_ORGANIZATION_FORM_SUCCESS',
        payload: organization,
      });
      dispatch({ type: 'SET_ORGANIZATION_TYPE', payload: organization.type });

      return organization;
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'GET_ORGANIZATION_FORM_FAIL' });
    }
  };

export const resetCurrentOrganization = () => async (dispatch) => {
  dispatch({ type: 'RESET_ORGANIZATION' });
};

export const getOrganization = async (organizationId) => {
  let response = await apiGet('/api/organizations/' + organizationId);
  if (response.status >= 200 && response.status <= 299) {
    const organization = await response.json();
    return organization;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const getOrganizationMailingListsStart =
  (organizationId) => async (dispatch) => {
    dispatch({ type: 'GET_ORGANIZATION_MAILING_LISTS_START' });
    try {
      let mailingLists = await getOrganizationMailingLists(organizationId);
      dispatch({
        type: 'GET_ORGANIZATION_MAILING_LISTS_SUCCESS',
        payload: mailingLists,
      });

      return mailingLists;
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'GET_ORGANIZATION_MAILING_LISTS_FAIL' });
    }
  };

export const getOrganizationMailingLists = async (organizationId) => {
  let response = await apiGet(
    '/api/organizations/' + organizationId + '/mailing-lists'
  );
  if (response.status >= 200 && response.status <= 299) {
    const mailingLists = await response.json();
    return mailingLists;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const getOrganizations = () => async (dispatch) => {
  dispatch({ type: 'GET_ORGANIZATIONS_START' });

  let response = await apiGet('/api/organizations/');

  if (response.status >= 200 && response.status <= 299) {
    let organizations = await response.json();
    organizations = organizations?.filter(
      (organization) => !organization.archived
    );
    let currentUserOrganization = await getCurrentUserOrganization();
    let currentUserOrganizationWithBusinessOrganization = organizations.find(
      (org) => org._id === currentUserOrganization?._id
    );
    let businessOrganizations =
      currentUserOrganizationWithBusinessOrganization?.businessOrganizations;

    organizations = sortOrganizationsByName(organizations);
    businessOrganizations = sortOrganizationsByName(businessOrganizations);

    dispatch({ type: 'GET_ORGANIZATIONS_SUCCESS', payload: organizations });
    dispatch({
      type: 'GET_BUSINESS_ORGANIZATIONS_SUCCESS',
      payload: businessOrganizations,
    });
    return organizations;
  } else {
    // Handle errors
    try {
      let jsonError = await response.json();
      console.error(jsonError);
      dispatch(openErrorSnack(jsonError?.message));
      dispatch({ type: 'GET_ORGANIZATIONS_FAIL' });
    } catch (error) {
      console.error(error);
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'GET_ORGANIZATIONS_FAIL' });
    }
  }
};

export const getArchivedOrganizations = () => async (dispatch) => {
  dispatch({ type: 'GET_ARCHIVED_ORGANIZATIONS_START' });
  return await fetch(process.env.REACT_APP_API_URL + '/api/organizations/', {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then(async (organizations) => {
      let archivedOrganizations = organizations.filter(
        (organization) => organization.archived
      );
      dispatch({
        type: 'GET_ARCHIVED_ORGANIZATIONS_SUCCESS',
        payload: archivedOrganizations,
      });
      return organizations;
    })
    .catch((error) => {
      console.error(error);
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'GET_ARCHIVED_ORGANIZATIONS_FAIL' });
    });
};

export const setOrganizationsToMakeBusinessWith =
  (selectedOrganizationIds) => async (dispatch) => {
    dispatch({
      type: 'SET_ORGANIZATION_TO_MAKE_BUSINESS_WITH_START',
      payload: selectedOrganizationIds,
    });
  };

export const setOrganizationType = (type) => async (dispatch) => {
  dispatch({ type: 'SET_ORGANIZATION_TYPE', payload: type });
};

export const getOrganizationUsers = (organizationId) => async (dispatch) => {
  dispatch({ type: 'GET_ORGANIZATION_USERS_START' });
  return await fetch(
    process.env.REACT_APP_API_URL +
      '/api/users/organizations/' +
      organizationId,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    }
  )
    .then(handleErrors)
    .then((res) => res.json())
    .then(async (organizationUsers) => {
      getOrganizationUsersSuccess(organizationUsers, dispatch);
      return organizationUsers;
    })
    .catch((error) => {
      console.error(error);
      getOrganizationUsersFail(error, dispatch);
    });
};

export const getOrganizationUsersSuccess = (organizationUsers, dispatch) => {
  dispatch({
    type: 'GET_ORGANIZATION_USERS_SUCCESS',
    payload: organizationUsers,
  });
};

export const getOrganizationUsersFail = (error, dispatch) => {
  dispatch({ type: 'GET_ORGANIZATION_USERS_FAIL' });
  dispatch(openErrorSnack(error?.message));
};

//////////
export const getOrganizationProducts = (organizationId) => async (dispatch) => {
  dispatch({ type: 'GET_ORGANIZATION_PRODUCTS_START' });
  return await fetch(
    process.env.REACT_APP_API_URL + '/api/prod/organization/' + organizationId,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    }
  )
    .then(handleErrors)
    .then((res) => res.json())
    .then(async (organizationProducts) => {
      getOrganizationProductsSuccess(organizationProducts, dispatch);
      return organizationProducts;
    })
    .catch((error) => {
      console.error(error);
      getOrganizationProductsFail(error, dispatch);
    });
};

export const getOrganizationProductsSuccess = (
  organizationProducts,
  dispatch
) => {
  dispatch({
    type: 'GET_ORGANIZATION_PRODUCTS_SUCCESS',
    payload: organizationProducts,
  });
};

export const getOrganizationProductsFail = (error, dispatch) => {
  dispatch({ type: 'GET_ORGANIZATION_PRODUCTS_FAIL' });
  dispatch(openErrorSnack(error?.message));
};

export const createOrganization = async (organization) => {
  let response = await apiPost('/api/organizations/add', organization);
  if (response.status >= 200 && response.status <= 299) {
    const updatedOrganization = await response.json();
    return updatedOrganization;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const setOrganization = async (organizationId, organization) => {
  let response = await apiPut(
    '/api/organizations/' + organizationId,
    organization
  );
  if (response.status >= 200 && response.status <= 299) {
    const updatedOrganization = await response.json();
    return updatedOrganization;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

const archiveOrganization = async (organizationId) => {
  let response = await apiPut('/api/organizations/archive/' + organizationId);
  if (response.status >= 200 && response.status <= 299) {
    const updatedOrganization = await response.json();
    return updatedOrganization;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

const restoreOrganization = async (organizationId) => {
  let response = await apiPut('/api/organizations/restore/' + organizationId);
  if (response.status >= 200 && response.status <= 299) {
    const updatedOrganization = await response.json();
    return updatedOrganization;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};
export const getParentOrganizations = () => async (dispatch) => {
  dispatch({ type: 'GET_PARENT_ORGANIZATIONS_START' });
  try {
    let response = await apiGet('/api/organizations/');
    if (response.status >= 200 && response.status <= 299) {
      let parentOrganizations = await response.json();
      parentOrganizations = parentOrganizations.filter(
        (organization) => organization.type !== enums.OrganizationTypes.TEO
      );

      parentOrganizations = sortOrganizationsByName(parentOrganizations);

      dispatch({
        type: 'GET_PARENT_ORGANIZATIONS_SUCCESS',
        payload: parentOrganizations,
      });

      return parentOrganizations;
    } else {
      // Handle errors
      let jsonError = await response.json();
      let errorMessage = jsonError.message
        ? jsonError.message
        : 'An error occured, please try later or reload the page';
      throw new Error(errorMessage);
    }
  } catch (error) {
    dispatch({ type: 'GET_PARENT_ORGANIZATIONS_FAIL' });
    dispatch(openErrorSnack(error?.message));
  }
};

export const setOrganizationsInTransferMenuRender =
  (organizationsInTransferMenuRender) => (dispatch) => {
    dispatch({
      type: 'SET_ORGANIZATIONS_IN_TRANSFER_MENU_RENDER',
      payload: organizationsInTransferMenuRender,
    });
  };
export const setOrganizationsInTransferMenuFiltredForSuperAdmin =
  (organizationsInTransferMenuFiltredForSuperAdmin) => (dispatch) => {
    dispatch({
      type: 'SET_ORGANIZATIONS_IN_TRANSFER_MENU_FILTERED_FOR_SUPER_ADMIN',
      payload: organizationsInTransferMenuFiltredForSuperAdmin,
    });
  };

export const setOrganizationsInAllocateMenuRender =
  (organizationsInAllocateMenuRender) => (dispatch) => {
    dispatch({
      type: 'SET_ORGANIZATIONS_IN_ALLOCATE_MENU_RENDER',
      payload: organizationsInAllocateMenuRender,
    });
  };
export const setOrganizationsInAllocateMenuFiltredForSuperAdmin =
  (organizationsInAllocateMenuFiltredForSuperAdmin) => (dispatch) => {
    dispatch({
      type: 'SET_ORGANIZATIONS_IN_ALLOCATE_MENU_FILTERED_FOR_SUPER_ADMIN',
      payload: organizationsInAllocateMenuFiltredForSuperAdmin,
    });
  };
