import { Grid } from '@mui/material';
import { formStyles } from 'assets/styles/formStyles';

const AttributesListTitle = () => {
  const classes = formStyles;
  return (
    <Grid item xs={12}>
      <span style={classes.fieldTitle}>
        List of attributes attached to the product
      </span>
    </Grid>
  );
};

export default AttributesListTitle;
