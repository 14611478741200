import { Grid } from '@mui/material';
import { GridColDef, DataGridPro as XGrid } from '@mui/x-data-grid-pro';
import GridToolbar from 'components/GenericComponents/Toolbar/GridToolbar';
import { FormikValues, useFormikContext } from 'formik';

type AddAttributeTablePropsType = {
  index: number;
  dataGridColumns: GridColDef[];
  infoText?: any;
};
const AddAttributeTable = ({
  index,
  dataGridColumns,
  infoText,
}: AddAttributeTablePropsType) => {
  const { values, setValues } = useFormikContext<FormikValues>();
  return (
    <Grid
      item
      xs={12}
      container
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      style={{ margin: '20px 0' }}
    >
      <XGrid
        autoHeight
        style={{
          maxWidth: '90%', // or any desired maximum width
          margin: '0 auto', // centers the grid horizontally
        }}
        rows={
          infoText?.listItems
            ? infoText?.listItems?.map((it: any, index: number) => {
                it.id = index;
                return it;
              })
            : []
        }
        columns={dataGridColumns}
        components={{
          Toolbar: GridToolbar,
        }}
        experimentalFeatures={{ newEditingApi: true }}
        onCellEditStop={(params, event) => {
          const targetEvent = event as React.ChangeEvent<HTMLInputElement>;
          const arrayIndex = values.infoTexts[index].listItems.findIndex(
            (el: any) => el.id === params.row.id
          );
          const newValues = { ...values };
          if (arrayIndex > -1 && targetEvent.target.value) {
            // only splice array when item is found
            const newListItemsArray = [
              ...newValues.infoTexts[index].listItems,
            ].map((i, currentIndex) => {
              if (currentIndex === arrayIndex)
                i[params.field] = targetEvent.target.value;
              return i;
            });
            newValues.infoTexts[index].listItems = newListItemsArray;
            setValues(newValues);
          }
        }}
      />
    </Grid>
  );
};

export default AddAttributeTable;
