import React from 'react';

import { Grid, MenuItem } from '@mui/material';
import useFormStandardsSlicesCombiner from 'components/FormStandards/Redux/Slices/Combiner/useFormStandardsCombiner';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

type Item = {
  _id?: string;
  name?: string;
  id: string;
  category?: string;
};

type GenericFieldProps = {
  name: string;
  label: string;
  select?: boolean;
  items?: Item[];
  token?: any;
  formIsBeingFilledOutHandler?: any;
  style?: React.CSSProperties;
  component?: JSX.Element | ((props: any) => JSX.Element);
  sizes?: { xs?: number; md?: number; lg?: number };
  errorKey?: string;
};

const GenericField: React.FC<GenericFieldProps> = ({
  name,
  label,
  select = false,
  items,
  token,
  formIsBeingFilledOutHandler,
  style = { width: 300 },
  component = TextField,
  sizes = { xs: 12, md: 6, lg: 4 },
  errorKey,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { standardFormError } = useFormStandardsSlicesCombiner();

  return (
    <Grid item {...sizes}>
      {token && <input type="hidden" name="_csrf" value={token} />}
      <Field
        component={component}
        type="text"
        name={name}
        label={t(label)}
        select={select}
        {...(select && { variant: 'standard' })}
        margin="normal"
        helperText=""
        style={style}
        InputLabelProps={{ shrink: true }}
        formIsBeingFilledOutHandler={() => {
          dispatch(formIsBeingFilledOutHandler());
        }}
      >
        {select && items
          ? items.map((item, index) => (
              <MenuItem
                key={item._id || item.id || index}
                value={item._id || item.id}
              >
                {item.name || item.id}
              </MenuItem>
            ))
          : null}
      </Field>
      {standardFormError && errorKey && standardFormError[errorKey] && (
        <div>
          <span style={{ fontSize: 10, color: 'red' }}>
            {standardFormError[errorKey]}
          </span>
        </div>
      )}
    </Grid>
  );
};

export default GenericField;
