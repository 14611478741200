import { DynamicListItemType } from 'reducers/dynamicListsReducer/types';

export const getAllRows = (
  listItems: DynamicListItemType[],
  localDynamicListItems: DynamicListItemType[]
) => {
  const dataGridRows: any[] = Array.isArray(listItems)
    ? [...listItems].map((listItem) => {
        return { ...listItem, id: listItem._id };
      })
    : [];

  for (const localDynamicListItem of localDynamicListItems as any[]) {
    const dataGridLocalRefFile = {
      id: localDynamicListItem.id,
      _id: undefined,
      key: localDynamicListItem.key,
      value: localDynamicListItem.value,
    };
    dataGridRows.push(dataGridLocalRefFile);
  }
  return dataGridRows;
};

export const getAllDynamicListItems = (listItems: DynamicListItemType[]) => {
  const dataGridRows: any[] = Array.isArray(listItems)
    ? [...listItems].map((listItem) => {
        return { ...listItem, id: listItem._id };
      })
    : [];
  return dataGridRows;
};

export const checkRowOnCommit = (
  field: string,
  newValue: string,
  otherRows: any[],
  onError: (err: string) => void
) => {
  if (!newValue || newValue?.trim() === '') {
    const errorMessage = `Error while saving list item : ${field} can't be empty.`;
    onError(errorMessage);
    throw new Error(errorMessage);
  }

  for (const otherRow of otherRows) {
    if (otherRow[field] === newValue) {
      const errorMessage = `Error while saving list item : ${field} must be unique.`;
      onError(errorMessage);
      throw new Error(errorMessage);
    }
  }
};
