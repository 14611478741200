import { createStandardStart, setStandardStart } from 'actions';
import { enums } from 'enums';
import { FormikValues } from 'formik';
import { AppDispatch } from 'redux/store';

import { areFieldsUntouchedOrEmpty } from './utils';

type StandardErrorType = {
  name?: string;
  standardType?: string;
};

export const handleFormikValidation = (values: any) => {
  const errors: StandardErrorType = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.standardType) {
    errors.standardType = 'Required';
  }
  return errors;
};

export const handleFormikSubmit =
  (
    values: any,
    params: any,
    productTypes: any,
    updatingStandard: boolean,
    navigate: any
  ) =>
  (dispatch: AppDispatch) => {
    const currentStandard = values;
    if (params?.standardId) {
      currentStandard._id = params.standardId;
    }
    const productType = productTypes.find(
      (s: any) => s?._id === values.productType
    );
    const currentStandardWithProductType = {
      ...values,
      productType: productType,
    };
    currentStandardWithProductType.infoTexts =
      currentStandardWithProductType.infoTexts.filter(
        (infoText: any, index: number) => {
          return !areFieldsUntouchedOrEmpty(
            index,
            currentStandardWithProductType
          );
        }
      );
    updatingStandard
      ? dispatch(setStandardStart(currentStandardWithProductType, navigate))
      : dispatch(createStandardStart(currentStandardWithProductType, navigate));
  };

export const handleAddAttribute = (
  values: FormikValues,
  setFieldValue: any,
  currentStandard?: any
) => {
  const infoTextsToUpdate = currentStandard
    ? currentStandard.infoTexts
    : values.infoTexts;

  // Find the highest index in the existing infoText array
  const highestIndex = infoTextsToUpdate.reduce(
    (maxIndex: number, attribute: any) => {
      const currentIndex = parseInt(attribute.infoProp.replace('info', ''));
      return currentIndex > maxIndex ? currentIndex : maxIndex;
    },
    -1
  );
  const newIndex = highestIndex + 1;
  const newAttribute = {
    infoProp: 'info' + newIndex,
    infoStandardText: '',
    infoStandardDesc: '',
    type: enums.InfoTypes.TEXT,
    isNew: true,
  };
  const updatedInfoTexts = [...values.infoTexts, newAttribute];
  if (currentStandard) currentStandard.infoTexts = updatedInfoTexts;
  setFieldValue('infoTexts', updatedInfoTexts);
};

export const handleRemoveAttribute = (
  values: FormikValues,
  setFieldValue: any,
  indexToRemove: number
) => {
  const updatedInfoTexts = values.infoTexts.filter(
    (_: any, index: number) => index !== indexToRemove
  );
  setFieldValue('infoTexts', updatedInfoTexts);
};

export const moveItem = (
  formCurrentStandard: any,
  fromIndex: number,
  toIndex: number,
  setFieldValue: any
) => {
  const updatedInfoTexts = [...formCurrentStandard.infoTexts];
  const [movedItem] = updatedInfoTexts.splice(fromIndex, 1);
  updatedInfoTexts.splice(toIndex, 0, movedItem);
  setFieldValue('infoTexts', updatedInfoTexts);
};

export const onDragEnd = (
  result: any,
  formCurrentStandard: any,
  setFieldValue: any
) => {
  const { destination, source } = result;

  if (!destination) {
    return;
  }

  const reorderedInfoTexts = Array.from(formCurrentStandard.infoTexts);
  const [removed] = reorderedInfoTexts.splice(source.index, 1);
  reorderedInfoTexts.splice(destination.index, 0, removed);

  setFieldValue('infoTexts', reorderedInfoTexts);
};
