import DynamicListsTable from '../Table/DynamicListsTable';
import DynamicListsToolbar from '../Toolbar/DynamicListsToolbar';
import './Styles/index.css';

const DynamicListsCard = (): JSX.Element => {
  return (
    <>
      <div className="table">
        <DynamicListsToolbar />
        <DynamicListsTable />
      </div>
    </>
  );
};

export default DynamicListsCard;
