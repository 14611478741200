/* eslint-disable */
const INITIAL_STATE = {
  currentUser: {
    id: undefined,
    firstname: '',
    surname: '',
    organization: {},
    street: '',
    number: '',
    zip: '',
    city: '',
    country: '',
    state: '',
    roles: [],
    hasAdminRole: false,
    hasUserAdminRole: false,
    hasAssetAdminRole: false,
    hasAgreementAdminRole: false,
    hasAssetManagerRole: false,
    hasTraderRole: false,
    hasMatcherRole: false,
    active: true,
    address: '',
  },
  localUserPicture: null,
  users: [],
  rights: [],
  selectedRights: [],
  error: { message: '', isVisible: false },
  loading: false,
  loadingForm: false,
  selected: [],
  page: 0,
  rowsPerPage: 10,
  formIsFilledOut: false,
  userFormError: undefined,
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_USERS_START':
      return { ...state, loading: true, currentUser: INITIAL_STATE };
    case 'GET_USERS_SUCCESS':
      return { ...state, users: action.payload, loading: false };
    case 'GET_USERS_FAIL':
      return { ...state, loading: false };

    case 'GET_USER_START':
      return { ...state, loading: true, selectedRights: [] };

    case 'GET_USER_SUCCESS':
      return {
        ...state,
        currentUser: action.payload,
        loading: false,
        selectedRights: action.payload.user_metadata.rights,
      };

    case 'GET_USER_FAIL':
      return { ...state, loading: false };

    case 'GET_RIGHTS_START':
      return { ...state, loading: true };

    case 'GET_RIGHTS_SUCCESS':
      return { ...state, rights: action.payload, loading: false };

    case 'GET_RIGHTS_FAIL':
      return { ...state, loading: false };

    case 'SET_SELECTED_USER_RIGHTS':
      return { ...state, selectedRights: action.payload };

    case 'GET_USER_FORM_START':
      return { ...state, loadingForm: true };

    case 'GET_USER_FORM_SUCCESS':
      return {
        ...state,
        currentUser: action.payload,
        loadingForm: false,
        localUserPicture: null,
        selectedRights: action.payload.user_metadata.rights,
      };

    case 'GET_USER_FORM_FAIL':
      return { ...state, loadingForm: false };

    case 'CREATE_USER_START':
      return { ...state, loading: true, currentUser: INITIAL_STATE };
    case 'CREATE_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        formIsFilledOut: false,
        localUserPicture: null,
      };

    case 'CREATE_USER_FAIL':
      return { ...state, loading: false };

    case 'USER_FORM_ERROR':
      return { ...state, userFormError: action.payload };

    case 'UPDATE_USER_START':
      return { ...state, loading: true };

    case 'DELETE_USER_START':
      return { ...state, loading: true };

    case 'UPDATE_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        formIsFilledOut: false,
        localUserPicture: null,
      };

    case 'DELETE_USER_SUCCESS':
      return { ...state, loading: false, formIsFilledOut: false };

    case 'UPDATE_USER_FAIL':
      return { ...state, loading: false };

    case 'DELETE_USER_FAIL':
      return { ...state, loading: false };

    case 'FIRSTNAME_CHANGED':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          firstName: action.payload,
          offchainData: {
            ...state.currentUser.offchainData,
            user_metadata: {
              ...state.currentUser.offchainData.user_metadata,
              firstname: action.payload,
            },
          },
        },
      };

    case 'NAME_CHANGED':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          user_metadata: {
            ...state.currentUser.user_metadata,
            name: action.payload,
          },
        },
      };

    case 'FORM_IS_BEING_FILLED_OUT':
      return {
        ...state,
        formIsFilledOut: true,
      };

    case 'SURNAME_CHANGED':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          surname: action.payload,
          offchainData: {
            ...state.currentUser.offchainData,
            user_metadata: {
              ...state.currentUser.offchainData.user_metadata,
              surname: action.payload,
            },
          },
        },
      };

    case 'ADDRESS_CHANGED':
      let fullAddress = state.currentUser.number + ' ';
      fullAddress += state.currentUser.street
        ? state.currentUser.street + ', '
        : '';
      fullAddress += state.currentUser.zip ? state.currentUser.zip + ', ' : '';
      fullAddress += state.currentUser.city
        ? state.currentUser.city + ', '
        : '';
      fullAddress += state.currentUser.region
        ? state.currentUser.region + ', '
        : '';
      fullAddress += state.currentUser.country
        ? state.currentUser.country + ' '
        : '';

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          street: action.payload.street,
          number: action.payload.houseNumber,
          zip: action.payload.zip,
          city: action.payload.city,
          country: action.payload.country,
          region: action.payload.region,
          offchainData: {
            ...state.currentUser.offchainData,
            user_metadata: {
              ...state.currentUser.offchainData.user_metadata,
              street: action.payload.street,
              number: action.payload.houseNumber,
              zip: action.payload.zip,
              city: action.payload.city,
              country: action.payload.country,
              region: action.payload.region,
            },
          },
        },
      };

    case 'RESET_USER':
      return {
        ...state,
        currentUser: {
          id: undefined,
          firstname: '',
          surname: '',
          organization: {},
          street: '',
          number: '',
          zip: '',
          city: '',
          country: '',
          state: '',
          roles: '',
          hasAdminRole: false,
          hasUserAdminRole: false,
          hasAssetAdminRole: false,
          hasAgreementAdminRole: false,
          hasAssetManagerRole: false,
          hasTraderRole: false,
          hasMatcherRole: false,
          active: true,
          address: '',
          rights: [],
        },
        localUserPicture: null,
      };

    case 'USER_PICTURE_CHANGED':
      return {
        ...state,
        localUserPicture: action.payload,
        formIsFilledOut: true,
      };

    case 'ROLES_ADMIN_CHANGED':
      const newHasAdminRole = !state.currentUser.hasAdminRole;
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          hasAdminRole: newHasAdminRole,
          hasUserAdminRole: newHasAdminRole ? true : false,
          hasAssetAdminRole: newHasAdminRole ? true : false,
          hasAgreementAdminRole: newHasAdminRole ? true : false,
          hasAssetManagerRole: newHasAdminRole ? true : false,
          hasTraderRole: newHasAdminRole ? true : false,
          hasMatcherRole: newHasAdminRole ? true : false,
        },
      };

    case 'ROLES_USER_ADMIN_CHANGED':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          hasUserAdminRole: !state.currentUser.hasUserAdminRole,
        },
      };

    case 'ROLES_ASSET_ADMIN_CHANGED':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          hasAssetAdminRole: !state.currentUser.hasAssetAdminRole,
        },
      };

    case 'ROLES_AGREEMENT_ADMIN_CHANGED':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          hasAgreementAdminRole: !state.currentUser.hasAgreementAdminRole,
        },
      };

    case 'ROLES_ASSET_MANAGER_CHANGED':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          hasAssetManagerRole: !state.currentUser.hasAssetManagerRole,
        },
      };

    case 'ROLES_TRADER_CHANGED':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          hasTraderRole: !state.currentUser.hasTraderRole,
        },
      };

    case 'ROLES_MATCHER_CHANGED':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          hasMatcherRole: !state.currentUser.hasMatcherRole,
        },
      };

    case 'ACTIVE_USER_CHANGED':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          active: action.payload,
        },
      };

    default:
      return state;
  }
};

export default usersReducer;
