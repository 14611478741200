export type OperationTableCellPropsType = {
  value: string;
  statusCode: number;
};
const OperationTableCell = ({
  value,
  statusCode,
}: OperationTableCellPropsType): JSX.Element => {
  return (
    <span
      style={{
        color: statusCode > 299 ? 'rgb(194, 14, 14)' : '',
        cursor: 'pointer',
      }}
    >
      {value}
    </span>
  );
};

export default OperationTableCell;
