import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import {
  displayConfirmDynamicListItemDeletionModal,
  removeLocalDynamicListItem,
} from 'actions';
import MenuItemIcon from 'components/commons/MenuItemIcon/MenuItemIcon';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import deleteIcon from '../../../../assets/images/icons/delete-file.png';

const MoreButton = (props: any): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const { row, userCanEditDynamicListItems } = props;
  const { id, _id } = row;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    if (_id) {
      dispatch(displayConfirmDynamicListItemDeletionModal(row));
    } else {
      dispatch(removeLocalDynamicListItem(id));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip
        title={'Click to see more actions'}
        placement="bottom"
        enterDelay={100}
      >
        <IconButton aria-label="open more menu" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {userCanEditDynamicListItems && (
          <MenuItemIcon
            key={'deleteItem'}
            icon={deleteIcon}
            onClick={handleDelete}
            title={'Delete item'}
            imgTitle={'Delete item'}
          />
        )}
      </Menu>
    </>
  );
};

export default MoreButton;
