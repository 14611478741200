import {
  bulkOperationsDatasetChanged,
  confirmationDatasetChanged,
} from 'actions';
import { AppDispatch } from 'redux/store';

const handleConfirmationDatasetChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  dispatch: AppDispatch
) => {
  dispatch(
    confirmationDatasetChanged(event.target.files && event.target.files[0])
  );
};

const handleBulkOperationsDatasetChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  dispatch: AppDispatch
) => {
  dispatch(
    bulkOperationsDatasetChanged(event.target.files && event.target.files[0])
  );
};

export { handleConfirmationDatasetChange, handleBulkOperationsDatasetChange };
