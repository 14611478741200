import { useEffect, useRef } from 'react';

import GenericModalTitle from 'components/Administration/Generic/Modal/Title/GenericModalTitle';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import notifyIcon from '../../../../../../../../assets/images/icons/notify.png';

import useSeeProducts from './CustomHooks/useSeeProducts';
import ModalContentIssuanceEmailNotificationConfirmation from './Form/Content/ModalContentIssuanceEmailNotificationConfirmation';
import ModalContentIssuanceEmailNotificationForm from './Form/Content/ModalContentIssuanceEmailNotificationForm';
import ModalFooterIssuanceEmailNotificationConfirmation from './Form/Footer/ModalFooterIssuanceConfirmation';
import ModalFooterIssuanceEmailNotificationForm from './Form/Footer/ModalFooterIssuanceEmailNotificationForm';
import { getValidationSchema } from './Form/FormValidation/validationForm';

const IssuanceEmailNotificationForm = () => {
  const { t } = useTranslation();
  const {
    notifyFutureIssuanceData,
    displayNotificationIssueFormInDialog,
    resetIssuanceNotificationEmailForm,
  } = useAdministrationSlicesCombiner();
  const { onSubmitHandler } = useSeeProducts();
  const formikRef = useRef<FormikProps<any>>(null);

  const initialValues = {
    organization:
      notifyFutureIssuanceData &&
      notifyFutureIssuanceData._organization &&
      notifyFutureIssuanceData._organization._id
        ? notifyFutureIssuanceData._organization._id
        : '',
    year: notifyFutureIssuanceData._year,
    month: notifyFutureIssuanceData._month,
  };
  useEffect(() => {
    if (resetIssuanceNotificationEmailForm) {
      formikRef.current?.resetForm({
        values: {
          organization: '',
          year: '',
          month: '',
        },
      });
    }
  }, [resetIssuanceNotificationEmailForm, formikRef]);

  return (
    <>
      <GenericModalTitle
        id="responsive-dialog-title"
        title={t('Notify organization of issuance')}
        icon={notifyIcon}
      />

      {displayNotificationIssueFormInDialog && (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
          validate={(values) => getValidationSchema(values)}
        >
          {() => {
            return (
              <>
                <ModalContentIssuanceEmailNotificationForm />
                <ModalFooterIssuanceEmailNotificationForm />
              </>
            );
          }}
        </Formik>
      )}
      {!displayNotificationIssueFormInDialog && (
        <>
          <ModalContentIssuanceEmailNotificationConfirmation />
          <ModalFooterIssuanceEmailNotificationConfirmation />
        </>
      )}
    </>
  );
};

export default IssuanceEmailNotificationForm;
