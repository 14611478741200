const OneMmbtuInWh = 293071.069444444;
//1 MMBtu = 293071.07 Wh
//1 MMBtu = 293071.069444444 Wh
//1 MMBtu = 293,071069444444 kWh
const OneMmbtuInKwh = OneMmbtuInWh / 1000;
const OneOzInGrams = 31.1035; //Specific conversation rate for platinum, in other cases let OneOzInGrams = 28.3495

export const convertGramsInOz = (quantityInGrams: number): number => {
  const quantityInOz = quantityInGrams / OneOzInGrams;
  return quantityInOz;
};

export const convertOzInGrams = (quantityInOz: number): number => {
  const quantityInGrams = quantityInOz * OneOzInGrams;
  return quantityInGrams;
};

export const convertWhInMmbtu = (volumeInWh: number): number => {
  const volumeInMmbtu = volumeInWh / OneMmbtuInWh;
  return volumeInMmbtu;
};

export const convertWhInMmbtuInt = (volumeInWh: string): number => {
  const volumeInMmbtu = parseFloat(volumeInWh) / OneMmbtuInWh;
  return volumeInMmbtu;
};

export const convertKwhInMmbtu = (volumeInKwh: string): number => {
  const volumeInMmbtu = parseFloat(volumeInKwh) / OneMmbtuInKwh;
  return volumeInMmbtu;
};

export const convertKwhInMmbtuInt = (volumeInKwh: string): number => {
  const volumeInMmbtu = parseInt(volumeInKwh) / OneMmbtuInKwh;
  return volumeInMmbtu;
};

export const convertMmbtuInKwh = (volumeInMmbtu: number): number => {
  const volumeInKwh = volumeInMmbtu * OneMmbtuInKwh;
  return volumeInKwh;
};

export const convertMmbtuInKwhInt = (volumeInMmbtu: string): number => {
  const volumeInKwh = parseInt(volumeInMmbtu) * OneMmbtuInKwh;
  return volumeInKwh;
};

export const convertWhInkWh = (volumeInWh: any): number => {
  //1 kWh = 1000 wh
  const volumeInKwh = parseFloat(volumeInWh) / 1000;
  return volumeInKwh;
};

export const convertWhInGWh = (volumeInWh: string): number => {
  //1 GWh = 1 000 000 000 wh
  const volumeInGwh = parseFloat(volumeInWh) / 1000000000;
  return volumeInGwh;
};

export const convertBytesInOctetsString = (aSize: number): string => {
  if (typeof aSize !== 'number') return '';
  const def = [
    { octetsBounds: 1, unit: 'octets' },
    { octetsBounds: 1024, unit: 'ko' },
    { octetsBounds: 1024 * 1024, unit: 'Mo' },
    { octetsBounds: 1024 * 1024 * 1024, unit: 'Go' },
    { octetsBounds: 1024 * 1024 * 1024 * 1024, unit: 'To' },
  ];
  for (let i = 0; i < def.length; i++) {
    if (aSize < def[i].octetsBounds)
      return (
        (aSize / def[i - 1].octetsBounds).toFixed(2) + ' ' + def[i - 1].unit
      );
  }
  return '';
};
