import React, { useContext } from 'react';

import { Grid } from '@mui/material';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import { NumericFormat } from 'react-number-format';

import GenericErrorInRed from '../../../../../GenericComponents/Errors/GenericErrorInRed';
import useBalancesChildComponentsSlices from '../../../../Redux/CustomHooks/useBalancesChildComponentsSlices';
import OrganizationsContext, {
  OrganizationContextType,
} from '../../../Dialogs/CombinedDialogs/OrganizationsContext';
import ConvertOzInGrams from '../../common/Converter/ConvertOzInGrams';

type VolumeFieldPropsType = {
  getTransferVolumeNumericProps: (
    values: any,
    setFieldValue: (v: string, vv: any) => void
  ) => any;
  values: any;
  setFieldValue: any;
  errors: any;
};
const VolumeField = ({
  getTransferVolumeNumericProps,
  values,
  setFieldValue,
  errors,
}: VolumeFieldPropsType) => {
  const { activateOzConversion }: OrganizationContextType =
    useContext(OrganizationsContext);
  const {
    transferCertificatesFormError,
  }: UseBalancesChildComponentsSlicesType = useBalancesChildComponentsSlices();

  return (
    <Grid item xs={12}>
      <NumericFormat
        {...getTransferVolumeNumericProps(values, setFieldValue)}
      />
      <GenericErrorInRed
        condition={transferCertificatesFormError?._volume}
        errors={transferCertificatesFormError}
        customKey={'_volume'}
      />
      <GenericErrorInRed
        condition={errors?.volume}
        errors={errors}
        customKey={'volume'}
      />
      <ConvertOzInGrams
        condition={activateOzConversion && values.volume}
        values={values}
      />
    </Grid>
  );
};

export default VolumeField;
