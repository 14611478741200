import { Button, DialogActions, DialogContent } from '@mui/material';
import { DataGridPro as XGrid } from '@mui/x-data-grid-pro';
import { closeActionDialog } from 'actions';
import GenericModalTitle from 'components/Administration/Generic/Modal/Title/GenericModalTitle';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import scheduleTasksIcon from '../../../../../../assets/images/icons/schedule-tasks.png';

import useScheduledTasksApi from './CustomHooks/useScheduledTasksApi';
import useScheduledTasksGridData from './CustomHooks/useScheduledTasksGridData';

const TaskMonitoringList = () => {
  const dispatch: AppDispatch = useDispatch();
  useScheduledTasksApi();
  const { dataGridColumns, dataGridRows } = useScheduledTasksGridData();
  return (
    <>
      <GenericModalTitle title={'Scheduled tasks'} icon={scheduleTasksIcon} />
      <DialogContent>
        <XGrid
          pagination
          rows={dataGridRows}
          columns={dataGridColumns}
          style={{ height: 650 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => dispatch(closeActionDialog())}
          color="primary"
          style={{ marginRight: 20, marginBottom: 30 }}
        >
          Close
        </Button>
      </DialogActions>
    </>
  );
};

export default TaskMonitoringList;
