import { useContext } from 'react';

import useCreateOrUpdateProductSlicesCombiner from 'components/CreateOrUpdateProduct/Redux/Slices/useCreateOrUpdateProductSlicesCombiner';
import GenericTable from 'components/GenericComponents/Tables/GenericTable/GenericTable';
import DataAcquisitionContext from 'components/ProductDataAcquisition/Context/DataAcquisitonContext';
import getDataGridColumns from 'components/ProductDataAcquisition/utils/table/columns';
import getDataGridRows from 'components/ProductDataAcquisition/utils/table/rows';

const DataAcquisitonTable = () => {
  const { currentProduct, loading } = useCreateOrUpdateProductSlicesCombiner();
  const { sourceHistory, removeSourceHistoryItem } = useContext(
    DataAcquisitionContext
  );
  const dataGridColumns = getDataGridColumns(removeSourceHistoryItem);
  const dataGridRows = getDataGridRows(sourceHistory, currentProduct);

  return (
    <GenericTable
      pagination
      autoHeight
      disableSelectionOnClick={true}
      loading={loading}
      tableRows={dataGridRows}
      tableColumns={dataGridColumns}
    />
  );
};

export default DataAcquisitonTable;
