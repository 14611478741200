import { FC } from 'react';
import { withRouter } from '../../utils/migrationHelper';
import BalancesTable from './CustomComponents/Grid/DisplayTable/BalancesTable';
import { Unauthorized } from './CustomComponents/Routes/Unauthorized/Unauthorized';
import { Charts } from './CustomComponents/Chart/Charts';
import BalanceTransactionsDialogs from './CustomComponents/Dialogs/CombinedDialogs/BalanceTransactionsDialogs';

const Balances: FC = () => {
  return (
    <>
      <Unauthorized />
      <Charts />
      <BalancesTable />
      <BalanceTransactionsDialogs />
    </>
  );
};

export default withRouter(Balances);
