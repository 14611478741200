import { CircularProgress, DialogContent } from '@mui/material';
import useCertificesSlicesCombiner from 'components/Certificates/Redux/Slices/useCertificatesSlicesCombiner';
import SecretWordInput from 'components/Transactions/CustomComponents/Dialogs/Components/Common/SecretWordInput/SecretWordInput';

import { getSecretWords } from './utils';

const SecretWordContent = () => {
  const { loadingSecretWord, currentCertificateSecretWord } =
    useCertificesSlicesCombiner();
  const secretWords = getSecretWords(currentCertificateSecretWord);
  return (
    <DialogContent>
      {loadingSecretWord ? (
        <CircularProgress size={16} style={{ paddingLeft: 10 }} />
      ) : (
        secretWords.map((word) => (
          <SecretWordInput
            key={word.id}
            label={word.label}
            value={word.value}
            message={word.message}
          />
        ))
      )}
    </DialogContent>
  );
};

export default SecretWordContent;
