/* eslint-disable */

import { AnyAction } from 'redux';
import { LoginReducerStateType } from './types';

const INITIAL_STATE: LoginReducerStateType = {
  username: '',
  password: '',
  isAuthenticated: false,
  user: null,
  error: { message: '', isVisible: false },
  loading: false,
  loadingCheck: false,
  passwordVisibility: false,
};

const loginReducer = (
  state: LoginReducerStateType = INITIAL_STATE,
  action: AnyAction
) => {
  switch (action.type) {
    case 'USERNAME_CHANGED':
      return { ...state, username: action.text };
    case 'PASSWORD_CHANGED':
      return { ...state, password: action.text };
    case 'PASSWORD_VISIBILITY_TOGGLE':
      const passwordVisibility = state.passwordVisibility;

      return { ...state, passwordVisibility: !passwordVisibility };
    case 'CHECK_AUTHENTICATION_START':
      return { ...state, loadingCheck: true };
    case 'CHECK_AUTHENTICATION_SUCCESS':
      return {
        ...state,
        password: '',
        isAuthenticated: true,
        loadingCheck: false,
      };
    case 'CHECK_AUTHENTICATION_FAIL':
      return {
        ...state,
        password: '',
        isAuthenticated: false,
        username: undefined,
        loadingCheck: false,
      };
    case 'LOGIN_START':
      return { ...state, loading: true };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loading: false,
        password: '',
        user: action.payload.user,
        username: action.payload.username,
        isAuthenticated: true,
        loadingCheck: false,
      };
    case 'LOGIN_ERROR':
      return { ...state, loading: false };
    case 'LOGOUT_START':
      return { ...state, loading: true };
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        loading: false,
        password: '',
        username: '',
        isAuthenticated: false,
      };
    case 'LOGOUT_ERROR':
      return { ...state, loading: false };
    case 'LOGOUT_SUCCESS':
      return INITIAL_STATE;
    case 'GET_CURRENTUSER':
      return { ...state, user: action.payload.user };

    default:
      return state;
  }
};

export default loginReducer;
