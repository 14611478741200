/* eslint-disable */

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { DataGridPro as XGrid } from '@mui/x-data-grid-pro';
import { getFullname, getOrganizationName } from '../../utils/datagridHelpers';
import Card from '@mui/material/Card';
import { CardHeader, CardContent } from '@mui/material';

import usersIcon from '../../assets/images/icons/user-groups.png';

let OrganizationUsersListComponent = ({ organizationUsers, loading }) => {
  let navigate = useNavigate();
  const dataGridRows = organizationUsers.map((u) => {
    return { ...u, id: u.user_id };
  });
  const dataGridColumns = [
    {
      field: '_fullname',
      headerName: 'Name',
      editable: false,
      width: 300,
      valueGetter: getFullname,
    },
    {
      field: '_organizationName',
      headerName: 'Organization',
      editable: false,
      width: 600,
      valueGetter: getOrganizationName,
    },
  ];
  return (
    <Card>
      <CardHeader
        avatar={<img src={usersIcon} style={{ maxHeight: 20 }} />}
        title={'Users'}
      />
      <CardContent>
        <div style={{ height: 300, padding: 20 }}>
          <XGrid
            pagination
            disableSelectionOnClick={true}
            loading={loading}
            rows={dataGridRows}
            columns={dataGridColumns}
            onRowClick={(rowData) => {
              let clickedUserId = rowData.row.identities[0].user_id;
              navigate('/users/read/' + clickedUserId);
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default OrganizationUsersListComponent;
