import moment from 'moment';
import { ProductFileType } from 'reducers/productsReducer/types';

const isIntersectingWithPeriodOrNoDates = (
  file: any,
  firstDayOfMonth: Date,
  lastDayOfMonth: Date
): boolean => {
  const { validityStart, validityEnd } = file;

  if (!validityStart && !validityEnd) {
    return true;
  }

  const start = moment(validityStart);
  const end = moment(validityEnd);
  const firstDay = moment(firstDayOfMonth);
  const lastDay = moment(lastDayOfMonth);

  return (
    (!validityEnd || end.isSameOrAfter(firstDay)) &&
    (!validityStart || start.isSameOrBefore(lastDay))
  );
};

export const getAllRows = (
  referenceFilesList: ProductFileType[],
  localProductReferenceFiles: any[],
  balancePeriod?: string
) => {
  const dataGridRows: any[] = Array.isArray(referenceFilesList)
    ? [...referenceFilesList].map((refFile) => {
        return { ...refFile, id: refFile._id };
      })
    : [];

  for (const localProductReferenceFile of localProductReferenceFiles as any[]) {
    const dataGridLocalRefFile = {
      id: localProductReferenceFile.id,
      _id: undefined,
      label: localProductReferenceFile.file.name,
      size: localProductReferenceFile.file.size,
      dataHash: undefined,
      txHash: undefined,
      updatedAt: undefined,
      uploadedAt: undefined,
      validityStart: localProductReferenceFile.validityStart,
      validityEnd: localProductReferenceFile.validityEnd,
      notify: localProductReferenceFile.notify,
      url: undefined,
      blob: localProductReferenceFile.file,
    };
    dataGridRows.push(dataGridLocalRefFile);
  }
  if (!balancePeriod) {
    return dataGridRows;
  }
  const [month, year] = balancePeriod.split('-').map(Number);
  const firstDayOfMonth = new Date(year, month - 1, 1);
  const lastDayOfMonth = new Date(year, month, 0);

  const filteredRows = dataGridRows.filter((row) =>
    isIntersectingWithPeriodOrNoDates(row, firstDayOfMonth, lastDayOfMonth)
  );
  return filteredRows;
};

export const getAllReferenceFiles = (referenceFilesList: any[]) => {
  const dataGridRows: any[] = Array.isArray(referenceFilesList)
    ? [...referenceFilesList].map((refFile) => {
        return { ...refFile, id: refFile._id };
      })
    : [];
  return dataGridRows;
};

export const checkRowOnCommit = (
  field: string,
  newValue: any,
  row: any,
  onError: (err: string) => void
) => {
  let attributeText = field;
  let errorMessage = '';
  let validityStart;
  let validityEnd;

  switch (field) {
    case 'label':
      attributeText = field;
      if (!newValue || newValue.trim() === '') {
        errorMessage = `Error while saving reference document: ${attributeText} can't be empty.`;
      }
      break;

    case 'validityStart':
      attributeText = 'validity start date';
      validityStart = moment(newValue);

      if (!validityStart.isValid()) {
        errorMessage = `Error while saving reference document: ${attributeText} is not a valid date.`;
      } else {
        validityEnd = row['validityEnd'] ? moment(row['validityEnd']) : null;
        if (validityEnd && !validityEnd.isValid()) {
          validityEnd = null;
        }
        if (validityEnd && validityStart.isAfter(validityEnd, 'day')) {
          errorMessage = `Error while saving reference document: Validity start date is after validity end date.`;
        }
      }
      break;

    case 'validityEnd':
      attributeText = 'validity end date';
      validityEnd = moment(newValue);

      if (!validityEnd.isValid()) {
        errorMessage = `Error while saving reference document: ${attributeText} is not a valid date.`;
      } else {
        validityStart = row['validityStart']
          ? moment(row['validityStart'])
          : null;
        if (validityStart && !validityStart.isValid()) {
          validityStart = null;
        }
        if (validityStart && validityEnd.isBefore(validityStart, 'day')) {
          errorMessage = `Error while saving reference document: Validity end date is before validity start date.`;
        }
      }
      break;

    default:
      break;
  }

  if (errorMessage) {
    onError(errorMessage);
    throw new Error(errorMessage);
  }
};
