import { Dialog } from '@mui/material';
import { hideUploadDynamicListItemModal } from 'actions';
import useDynamicListsSlicesCombiner from 'components/DynamicList/Redux/Slices/useDynamicListsSlicesCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import UploadActions from './Actions/UploadActions';
import UploadContent from './Content/UploadContent';
import UploadTitle from './UploadTitle';

const UploadDialog = () => {
  const { openUploadDynamicListItemModal } = useDynamicListsSlicesCombiner();
  const dispatch: AppDispatch = useDispatch();
  const handleClose = () => {
    dispatch(hideUploadDynamicListItemModal());
  };

  const modalProps: any = {
    fullScreen: false,
    open: !!openUploadDynamicListItemModal,
    'data-testid': 'uploadDynamicListItemDialog',
    'aria-labelledby': 'responsive-dialog-title',
    onClose: handleClose,
  };

  return (
    <Dialog {...modalProps}>
      <UploadTitle handleClose={handleClose} />
      <UploadContent />
      <UploadActions handleClose={handleClose} />
    </Dialog>
  );
};

export default UploadDialog;
