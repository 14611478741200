export const StandardTypes = {
  TRUSTWELL_DEMO: {
    id: 'trustwell demo',
    category: 'gas',
  },
  TRUSTWELL: {
    id: 'trustwell',
    category: 'gas',
  },
  TRUSTWELL_TRANSPORT: {
    id: 'trustwell_transport',
    category: 'gas',
  },
  MIQ: {
    id: 'miq',
    category: 'gas',
  },
  EU_DEFAULT: {
    id: 'default-eu',
    category: 'gas',
  },
  NG_PLUS: {
    id: 'NG+',
    category: 'gas',
  },
  EU_NGO: {
    id: 'eu-natural-gas-origin',
    category: 'gas',
  },
  CCUS: {
    id: 'CCUS',
    category: 'gas',
  },
  SAF: {
    id: 'SAF',
    category: 'gas',
  },
  POWER: {
    id: 'go_power',
    category: 'gas',
  },
  MINING: {
    id: 'mining',
    category: 'platinum',
  },
  RECYCLING: {
    id: 'recycling',
    category: 'platinum',
  },
  ANGLO_AMERICAN: {
    id: 'Anglo American',
    category: 'platinum',
  },
  UMICORE: {
    id: 'Umicore',
    category: 'platinum',
  },
  MINING_COMPANY_1: {
    id: 'Mining company 1',
    category: 'platinum',
  },
  RECYCLING_COMPANY_1: {
    id: 'Recycling company 1',
    category: 'platinum',
  },
  TOLLING_COMPANY_1: {
    id: 'Tolling company 1',
    category: 'platinum',
  },
  MINING_COMPANY_1_TOLLING_COMPANY_1: {
    id: 'Mining Company 1 – Tolling Company 1',
    category: 'platinum',
  },
};
