import { SyntheticEvent } from 'react';

import { closeActionDialog } from 'actions';
import GenericAutocompleteField from 'components/Administration/Generic/Form/Fields/Autocomplete/GenericAutocompleteField';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { FormikValues, useFormikContext } from 'formik';

import { FormFieldsConfigType } from '../../../../../utils/types/formFieldsTypes';
import { FooterModalPropsType } from '../../../../../utils/types/types';
import useProductsAndOrganizationsOptions from '../../../../utils/CustomHooks/useProductsAndOrganizationsOptions';

const useCheckAttributesFormModalConfig = () => {
  const { submitForm, setFieldValue, values } =
    useFormikContext<FormikValues>();
  const { productsOptions } = useProductsAndOrganizationsOptions();
  const {
    products,
    displayCheckAttributesFormInDialog,
    loadingCheckAttributes,
  } = useAdministrationSlicesCombiner();

  //modal components
  const modalHeader = {
    title: '',
  };
  const modalFooter: FooterModalPropsType = {
    cancelLabel: 'Close',
    submitLabel: 'Check',
    onCancelHandler: closeActionDialog,
    onSubmitHandler: submitForm,
    loading: loadingCheckAttributes,
  };
  const formFieldsConfig: FormFieldsConfigType = [
    [
      {
        component: GenericAutocompleteField,
        name: 'origin',
        label: 'Product',
        options: (products as any[])?.length > 0 ? productsOptions : [],
        selectedValue:
          productsOptions.find(
            (option: any) => option.value === values.origin
          ) || '',
        onChange: (_: SyntheticEvent, selectedOption: any) => {
          setFieldValue('origin', selectedOption?.value);
        },
        condition: () => Boolean(displayCheckAttributesFormInDialog),
      },
    ],
  ];
  return {
    header: modalHeader,
    content: formFieldsConfig,
    footer: modalFooter,
  };
};

export default useCheckAttributesFormModalConfig;
