import { Button, Grid } from '@mui/material';

import { GenericUploadFieldPropsType } from '../../utils/types/types';

const GenericUploadField = ({
  buttonLabel = 'Select document',
  gridItemSize = 6,
  idSelector = 'upload-source-document-button-ref',
  onChange = () => console.log('onChange triggered'),
  style = { display: 'none' },
  disabled = false,
}: GenericUploadFieldPropsType) => {
  return (
    <Grid item xs={gridItemSize}>
      <input
        accept=".doc, .docx, application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        id={idSelector}
        type="file"
        multiple
        style={style}
        onChange={onChange as React.ChangeEventHandler<HTMLInputElement>}
      />
      <label htmlFor={idSelector}>
        <Button
          variant="outlined"
          color="primary"
          component="span"
          disabled={disabled}
          style={{
            marginTop: 13,
            border: '1px solid rgba(248,119,51, 0.5)',
            borderRadius: 4,
          }}
        >
          {buttonLabel}
        </Button>
      </label>
    </Grid>
  );
};

export default GenericUploadField;
