import { checkUserHasRight } from 'utils/user/checkRights';

import { enums } from '../../../enums/';

import { independantLinks } from './independantLinks';
import { superAdminLinks } from './superAdminLinks';

export const getDrawerLinks = (props: {
  isAuthenticated: boolean;
  user: any;
}) => {
  const { isAuthenticated, user } = props;

  const independantLinksWithFeatures = independantLinks.filter((link) => {
    let displayLink = false;

    Array.isArray(link.onlyForRight) &&
      link.onlyForRight.forEach((rightElement) => {
        if (
          rightElement.ref &&
          checkUserHasRight(
            isAuthenticated,
            user,
            rightElement.ref,
            rightElement.permissions
          )
        )
          displayLink = true;
      });
    return displayLink;
  });

  let linksToDisplay = [];
  if (user?.roles.includes(enums.UserRole.SUPER_ADMIN)) {
    linksToDisplay = superAdminLinks;
  } else {
    linksToDisplay = independantLinksWithFeatures;
  }

  return linksToDisplay;
};
