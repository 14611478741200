import { useRef } from 'react';

import { Dialog } from '@mui/material';
import { retireCertificate, displayWarningInCancelDialog } from 'actions';
import useCertificesSlicesCombiner from 'components/Certificates/Redux/Slices/useCertificatesSlicesCombiner';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import RemoveCertificateDialogContent from './CustomComponents/Content/RemoveCertificatesDialogContent';
import RemoveCertificatesDialogFooter from './CustomComponents/Footer/RemoveCertificatesDialogFooter';
import RemoveCertificateDialogTitle from './CustomComponents/Title/RemoveCertificatesDialogTitle';

const RemoveCertificatesDialog = () => {
  const dispatch: AppDispatch = useDispatch();
  const formCancelCertificateRef = useRef<InstanceType<
    typeof ValidatorForm
  > | null>(null);
  const {
    cancelCertificateFormOpen,
    retiringCertificateId,
    cancelComment,
    displayCancelCertificateFormInDialog,
  } = useCertificesSlicesCombiner();

  const handleSubmit = () => {
    if (displayCancelCertificateFormInDialog) {
      return dispatch(displayWarningInCancelDialog());
    }
    return dispatch(retireCertificate(retiringCertificateId, cancelComment));
  };

  return (
    <Dialog
      disableEnforceFocus
      fullScreen={false}
      open={cancelCertificateFormOpen as boolean}
      aria-labelledby="responsive-dialog-title"
    >
      <ValidatorForm
        ref={formCancelCertificateRef}
        onSubmit={handleSubmit}
        onError={(errors) => console.log(errors)}
      >
        <RemoveCertificateDialogTitle />
        <RemoveCertificateDialogContent />
        <RemoveCertificatesDialogFooter />
      </ValidatorForm>
    </Dialog>
  );
};

export default RemoveCertificatesDialog;
