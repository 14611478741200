import { useEffect, useState } from 'react';

import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';

const useProductsAndOrganizationsOptions = (organizationsParam?: any[]) => {
  const { products } = useAdministrationSlicesCombiner();
  const [productsOptions, setProductsOptions] = useState<any[]>([]);
  const [activeProductsOptions, setActiveProductsOptions] = useState<any[]>([]);
  const [organizationsOptions, setOrganizationsOptions] = useState<any[]>([]);
  const { organizations } = useAdministrationSlicesCombiner();

  const formatedOrganizationsParam = organizationsParam?.map((orga: any) => ({
    key: orga?._id,
    value: orga?._id,
    label: `${orga?.name}`,
  }));

  const formatedDefaultOrganizations = organizations.map((orga: any) => ({
    key: orga?._id,
    value: orga?._id,
    label: `${orga?.name}`,
  }));

  const formatedActiveProducts = (products as any[])
    .filter((product: any) => product.active && !product.deprecated)
    .map((product: any) => ({
      key: product._id,
      value: product._id,
      label: `${product.displayedName} - ${product.productId}`,
    }));

  const formatedProducts = (products as any[]).map((product: any) => ({
    key: product._id,
    value: product._id,
    label: `${product.displayedName} - ${product.productId}`,
  }));

  useEffect(() => {
    setActiveProductsOptions(formatedActiveProducts);
    setProductsOptions(formatedProducts);
  }, [products]);

  useEffect(() => {
    organizationsParam
      ? setOrganizationsOptions(formatedOrganizationsParam as any[])
      : setOrganizationsOptions(formatedDefaultOrganizations);
  }, [organizationsParam, organizations]);

  return { productsOptions, activeProductsOptions, organizationsOptions };
};

export default useProductsAndOrganizationsOptions;
