import { Typography } from '@mui/material';

type GenericFieldValuePropsType = {
  field: any;
  index?: number;
};
const GenericFieldValue = ({ field, index }: GenericFieldValuePropsType) => {
  if (!field) return <></>;
  return (
    <Typography
      variant="body2"
      {...(index && { key: `value${index?.toString()}` })}
    >
      {field.toString().trim()}
    </Typography>
  );
};

export default GenericFieldValue;
