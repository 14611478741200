import { Dialog } from '@mui/material';
import { toggleSecretWordDialog } from 'actions';
import useCertificesSlicesCombiner from 'components/Certificates/Redux/Slices/useCertificatesSlicesCombiner';

import SecretWordContent from './CustomComponents/Content/SecretWordContent';
import SecretWordFooter from './CustomComponents/Footer/SecretWordFooter';
import SecretWordTitle from './CustomComponents/Title/SecretWordTitle';

const SecretWordDialog = () => {
  const { dialogSecretWordOpen } = useCertificesSlicesCombiner();

  return (
    <Dialog
      fullScreen={false}
      open={dialogSecretWordOpen as boolean}
      onClose={toggleSecretWordDialog}
      aria-labelledby="responsive-dialog-title"
    >
      <SecretWordTitle />
      <SecretWordContent />
      <SecretWordFooter />
    </Dialog>
  );
};

export default SecretWordDialog;
