import GenericModalContent from 'components/Administration/Generic/Modal/Content/GenericModalContent';

import useIncreaseBalanceFormConfirmationModalConfig from '../../Configuration/useIncreaseBalanceFormConfirmationModalConfig';

const ModalContentIncreaseBalanceConfirmation = () => {
  const { header, content } = useIncreaseBalanceFormConfirmationModalConfig();

  return <GenericModalContent header={header} content={content} />;
};

export default ModalContentIncreaseBalanceConfirmation;
