import React from 'react';

import NominationsGlobalExchanges from '../Content/NominationsGlobalExchanges/NominationsGlobalExchanges';

import NominationsHistoryDialog from './HistoryDialog/NominationsHistoryDialog';
import NominationsMainDialog from './MainDialog/NominationsMainDialog';

const NominationsDialogs = () => {
  return (
    <>
      <NominationsGlobalExchanges />
      <NominationsMainDialog />
      <NominationsHistoryDialog />
    </>
  );
};

export default React.memo(NominationsDialogs);
