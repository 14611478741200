import React from 'react';

import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useIssueCertificatesFormModalConfig from '../../Configuration/useIssueCertificatesFormModalConfig';

const ModalFooterIssueCertificatesForm = () => {
  const { footer } = useIssueCertificatesFormModalConfig();
  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterIssueCertificatesForm;
