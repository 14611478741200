import { useEffect, useState } from 'react';

import {
  getCertificatesByStandardStart,
  getDefaultCertificatesPaginationThreshold,
  getProductTypesStart,
  getUniqueStandardsFromBalancesStart,
} from 'actions';
import { displayPlatinumUI } from 'components/CreateOrUpdateProduct/utils/utils';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from 'components/Transactions/utils/constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';

import useCertificatesSlicesCombiner from '../Slices/useCertificatesSlicesCombiner';

const useCertificates = () => {
  const { isAuthenticated, user, selectedStandardId } =
    useCertificatesSlicesCombiner();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  useEffect(() => {
    const initDefaultPaginationThreshold = async () => {
      if (!isAuthenticated || !user) {
        navigate('/');
      } else {
        await dispatch(getDefaultCertificatesPaginationThreshold());
      }
    };
    initDefaultPaginationThreshold();
  }, []);

  useEffect(() => {
    const initStandardsList = async () => {
      if (!isAuthenticated || !user) {
        navigate('/');
      } else {
        await dispatch(getUniqueStandardsFromBalancesStart());
        if (displayPlatinumUI) await dispatch(getProductTypesStart());
      }
    };
    initStandardsList();
  }, []);

  useEffect(() => {
    async function initComponent() {
      if (!isAuthenticated || !user) {
        navigate('/');
      } else {
        await dispatch(
          getCertificatesByStandardStart(selectedStandardId, page, pageSize)
        );
      }
    }
    initComponent();
  }, [
    page,
    pageSize,
    dispatch,
    selectedStandardId,
    isAuthenticated,
    user,
    navigate,
  ]);

  // Reset pageSize when selectedStandardId changes
  useEffect(() => {
    setPageSize(DEFAULT_PAGE_SIZE);
    setPage(DEFAULT_PAGE_NUMBER);
  }, [selectedStandardId]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };
  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setPage(0);
  };

  return { handlePageChange, handlePageSizeChange, page, pageSize };
};

export default useCertificates;
