import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import { drawerWidth } from '../../theme';

import DrawerContent from './DrawerContent';

const DrawerContainer = (props: {
  mobileOpen: boolean;
  handleDrawerToggle: any;
  isAuthenticated: boolean;
  user: any;
  cookies: any;
}): JSX.Element => {
  const { mobileOpen, handleDrawerToggle, isAuthenticated, user, cookies } =
    props;

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={() => {
          handleDrawerToggle();
        }}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            borderWidth: 0,
          },
        }}
      >
        <DrawerContent
          isAuthenticated={isAuthenticated}
          user={user}
          cookies={cookies}
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
        />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            borderWidth: 0,
          },
        }}
        open
      >
        <DrawerContent
          isAuthenticated={isAuthenticated}
          user={user}
          cookies={cookies}
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
        />
      </Drawer>
    </Box>
  );
};

export default DrawerContainer;
