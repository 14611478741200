/* eslint-disable */

import {
  getStringHashedBySecretWord,
  getStringHashed,
  apiGet,
} from '../actions';
import { fetchProductDataForMonth } from './AssetsProducingActions';
import { DEFAULT_PAGE_SIZE } from 'components/Transactions/utils/constants';

export const setTransactionsStandard = (standardId) => (dispatch) => {
  if (standardId === `allStandards`) {
    dispatch({ type: 'SET_TRANSACTIONS_STANDARD', payload: undefined });
  } else {
    dispatch({ type: 'SET_TRANSACTIONS_STANDARD', payload: standardId });
  }
};

export const setTransactionsStandardType = (standardType) => (dispatch) => {
  if (standardType === `allStandards`) {
    dispatch({ type: 'SET_TRANSACTIONS_STANDARD_TYPE', payload: undefined });
  } else {
    dispatch({ type: 'SET_TRANSACTIONS_STANDARD_TYPE', payload: standardType });
  }
};

export const setTransactionsProductType = (productTypeId) => (dispatch) => {
  if (productTypeId === `allProductTypes`) {
    dispatch({ type: 'SET_TRANSACTIONS_PRODUCT_TYPE', payload: undefined });
  } else {
    dispatch({ type: 'SET_TRANSACTIONS_PRODUCT_TYPE', payload: productTypeId });
  }
};
export const getDefaultTransactionsPaginationThresholdSuccess =
  (threshold) => (dispatch) => {
    dispatch({
      type: 'SET_DEFAULT_TRANSACTIONS_PAGINATION_THRESHOLD',
      payload: threshold,
    });
  };

const wholeStandardObjectAlreadyExists = (array, params) => {
  return array.some((item) => {
    if (item.productId === params.productId) {
      return item.month === params.month && item.year === params.year;
    }
    return false;
  });
};

export const getTransactionsStart =
  (page, pageSize = DEFAULT_PAGE_SIZE) =>
  async (dispatch) => {
    dispatch({ type: 'RESET_TRANSACTIONS' });
    dispatch({ type: 'GET_TRANSACTIONS_START' });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/transactions?page=${page}&limit=${pageSize}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      );
      const { transactions, totalCount } = await response.json();
      const dynamicTransactions = [];
      const productQueryParamsByMonthAndYear = [];
      for (let index = 0; index < transactions?.length; index++) {
        const t = transactions[index];
        const transactionToPush = { ...t };
        const currentOrigin = t?.origin;

        const productStandard = currentOrigin.standard;
        const dynamicDatas = productStandard?.infoTexts.filter(
          (infoX) => infoX.type === 'dynamicData'
        );

        if (dynamicDatas && dynamicDatas.length > 0) {
          let dataForMonth = null;
          const paramsToCheckAgainst = {
            productId: currentOrigin?._id,
            month: t.month,
            year: t.year,
          };
          //only execute fetchProductDataForMonth when the params standard id, month and year
          //are not already in the array of temporary standardQueryParams
          if (
            !wholeStandardObjectAlreadyExists(
              productQueryParamsByMonthAndYear,
              paramsToCheckAgainst
            )
          ) {
            dataForMonth = await fetchProductDataForMonth(
              currentOrigin?._id,
              t.year,
              t.month
            );

            //array of product productQueryParamsByMonthAndYear to check against (to avoid duplicates in api calls)
            productQueryParamsByMonthAndYear.push(paramsToCheckAgainst);

            const dataForSourceArray = dataForMonth?.data;

            for (const dynamicData of dynamicDatas) {
              const dynamicDataSourceName = dynamicData?.sourceName;
              const infoX = dynamicData?.infoProp;

              if (dynamicDataSourceName) {
                let dataForSource;

                if (dataForSourceArray && Array.isArray(dataForSourceArray)) {
                  dataForSource = dataForSourceArray?.find(
                    (d) => d.source === dynamicDataSourceName
                  );
                }
                currentOrigin.standardValues = currentOrigin.standardValues.map(
                  ({ key, value }) => {
                    if (key === infoX) {
                      return { key, value: dataForSource?.measure || value };
                    }
                    return { key, value };
                  }
                );
              }
            }

            transactionToPush.origin = currentOrigin;
          } else {
            //Data has already been fetched for this product / month / year
            //Let's retrieve the related origin already updated with the dynamicData for this month
            const transactionForMonthYearProduct = dynamicTransactions.find(
              (_t) => {
                return (
                  t?.origin?._id === _t?.origin?._id &&
                  t?.year === _t?.year &&
                  t?.month === _t?.month
                );
              }
            );
            if (transactionForMonthYearProduct) {
              //The already completed transaction has been found, let's set to the new one the origin of the previous one
              transactionToPush.origin = transactionForMonthYearProduct?.origin;
            } else {
              //This case should not happen, but trigger an error is useless, just
              console.error('No product found (and it should be found) ', {
                product: currentOrigin,
                year: t.year,
                month: t.month,
              });
            }
          }
        }
        dynamicTransactions.push(transactionToPush);
      }

      dispatch(getTransactionsSuccess(dynamicTransactions));
      dispatch(getTransactionsTotalCountSuccess(totalCount));
    } catch (error) {
      console.error(error);
      getTransactionsFail(error, dispatch);
    }
  };
export const getDefaultTransactionsPaginationThreshold =
  () => async (dispatch) => {
    try {
      const response = await apiGet('/api/transactions/pagination/threshold');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const { threshold } = await response.json();
      dispatch(getDefaultTransactionsPaginationThresholdSuccess(threshold));
    } catch (error) {
      console.error(error);
    }
  };

export const getTransactionsStartByStandard =
  (standardId = 'allStandards', page, pageSize) =>
  async (dispatch) => {
    dispatch({ type: 'RESET_TRANSACTIONS' });
    dispatch({ type: 'GET_TRANSACTIONS_START' });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/transactions/${standardId}?page=${page}&limit=${pageSize}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      );
      const { transactions, paginatedTransactionsCount, allTransactionsCount } =
        await response.json();

      dispatch(getTransactionsSuccess(transactions));
      dispatch(getTransactionsTotalCountSuccess(paginatedTransactionsCount));
      dispatch(getAllTransactionsCountSuccess(allTransactionsCount));
    } catch (error) {
      console.error(error);
      getTransactionsFail(error, dispatch);
    }
  };

export const getTransactionsSuccess = (transactions) => (dispatch) => {
  dispatch({ type: 'GET_TRANSACTIONS_SUCCESS', payload: transactions });
};
export const getTransactionsTotalCountSuccess = (totalCount) => (dispatch) => {
  dispatch({
    type: 'GET_TRANSACTIONS_TOTAL_COUNT_SUCCESS',
    payload: totalCount,
  });
};
export const getAllTransactionsCountSuccess =
  (allTransactionsCount) => (dispatch) => {
    dispatch({
      type: 'GET_ALL_TRANSACTIONS_TOTAL_COUNT_SUCCESS',
      payload: allTransactionsCount,
    });
  };

export const getTransactionsFail = (error, dispatch) => {
  dispatch({ type: 'GET_TRANSACTIONS_FAIL' });
};

export const getTransactionsByAggregationIdStart =
  (aggregationId, page, pageSize = DEFAULT_PAGE_SIZE) =>
  async (dispatch) => {
    dispatch({ type: 'RESET_TRANSACTIONS' });
    dispatch({ type: 'GET_TRANSACTIONS_BY_AGGREGATION_ID_START' });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/transactions/aggregations/${aggregationId}?page=${page}&limit=${pageSize}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      );
      const { transactions, totalCount } = await response.json();
      const dynamicTransactions = [];
      const productQueryParamsByMonthAndYear = [];
      for (let index = 0; index < transactions?.length; index++) {
        const t = transactions[index];
        const transactionToPush = { ...t };
        const currentOrigin = t?.origin;

        const productStandard = currentOrigin.standard;
        const dynamicDatas = productStandard?.infoTexts.filter(
          (infoX) => infoX.type === 'dynamicData'
        );

        if (dynamicDatas && dynamicDatas.length > 0) {
          let dataForMonth = null;
          const paramsToCheckAgainst = {
            productId: currentOrigin?._id,
            month: t.month,
            year: t.year,
          };
          //only execute fetchProductDataForMonth when the params standard id, month and year
          //are not already in the array of temporary standardQueryParams
          if (
            !wholeStandardObjectAlreadyExists(
              productQueryParamsByMonthAndYear,
              paramsToCheckAgainst
            )
          ) {
            dataForMonth = await fetchProductDataForMonth(
              currentOrigin?._id,
              t.year,
              t.month
            );

            //array of product productQueryParamsByMonthAndYear to check against (to avoid duplicates in api calls)
            productQueryParamsByMonthAndYear.push(paramsToCheckAgainst);

            const dataForSourceArray = dataForMonth?.data;

            for (const dynamicData of dynamicDatas) {
              const dynamicDataSourceName = dynamicData?.sourceName;
              const infoX = dynamicData?.infoProp;

              if (dynamicDataSourceName) {
                let dataForSource;

                if (dataForSourceArray && Array.isArray(dataForSourceArray)) {
                  dataForSource = dataForSourceArray?.find(
                    (d) => d.source === dynamicDataSourceName
                  );
                }
                currentOrigin.standardValues = currentOrigin.standardValues.map(
                  ({ key, value }) => {
                    if (key === infoX) {
                      return { key, value: dataForSource?.measure || value };
                    }
                    return { key, value };
                  }
                );
              }
            }

            transactionToPush.origin = currentOrigin;
          } else {
            //Data has already been fetched for this product / month / year
            //Let's retrieve the related origin already updated with the dynamicData for this month
            const transactionForMonthYearProduct = dynamicTransactions.find(
              (_t) => {
                return (
                  t?.origin?._id === _t?.origin?._id &&
                  t?.year === _t?.year &&
                  t?.month === _t?.month
                );
              }
            );
            if (transactionForMonthYearProduct) {
              //The already completed transaction has been found, let's set to the new one the origin of the previous one
              transactionToPush.origin = transactionForMonthYearProduct?.origin;
            } else {
              //This case should not happen, but trigger an error is useless, just
              console.error('No product found (and it should be found) ', {
                product: currentOrigin,
                year: t.year,
                month: t.month,
              });
            }
          }
        }
        dynamicTransactions.push(transactionToPush);
      }

      dispatch(getTransactionsByAggregationIdSuccess(dynamicTransactions));
      dispatch(getTransactionsByAggregationIdTotalCountSuccess(totalCount));
    } catch (error) {
      console.error(error);
      getTransactionsByAggregationIdFail(error, dispatch);
    }
  };

export const getTransactionsByAggregationIdSuccess =
  (transactions) => (dispatch) => {
    dispatch({
      type: 'GET_TRANSACTIONS_BY_AGGREGATION_ID_SUCCESS',
      payload: transactions,
    });
  };
export const getTransactionsByAggregationIdTotalCountSuccess =
  (totalCount) => (dispatch) => {
    dispatch({
      type: 'GET_TRANSACTIONS_BY_AGGREGATION_ID_TOTAL_COUNT_SUCCESS',
      payload: totalCount,
    });
  };
export const getAllTransactionsByAggregationIdTotalCountSuccess =
  (totalCount) => (dispatch) => {
    dispatch({
      type: 'GET_ALL_TRANSACTIONS_BY_AGGREGATION_ID_TOTAL_COUNT_SUCCESS',
      payload: totalCount,
    });
  };

export const getTransactionsByAggregationIdFail = (error, dispatch) => {
  dispatch({ type: 'GET_TRANSACTIONS_BY_AGGREGATION_ID_FAIL' });
};

export const getSecretWordForTransaction =
  (transaction) => async (dispatch) => {
    dispatch({ type: 'LOAD_SECRET_WORD_START' });

    const origin = transaction.origin.assetID;
    const originField = transaction.origin.displayedName;

    const issuer =
      transaction &&
      transaction.type &&
      transaction.type != 'transfer_certificate' &&
      transaction.issuer &&
      transaction.issuer.identifier
        ? transaction.issuer.identifier
        : '';
    const from =
      transaction && transaction.from && transaction.from.identifier
        ? transaction.from.identifier
        : '';
    const to =
      transaction && transaction.to && transaction.to.identifier
        ? transaction.to.identifier
        : '';

    let isOriginAlreadyHashed = origin.startsWith('0x');
    const originStringHashed = isOriginAlreadyHashed
      ? origin
      : await getStringHashed(origin);
    const issuerStringHashed = issuer
      ? await getStringHashedBySecretWord(
          transaction.issuer._id,
          transaction.year,
          transaction.month,
          issuer
        )
      : '';
    const fromStringHashed = from
      ? await getStringHashedBySecretWord(
          transaction.from._id,
          transaction.year,
          transaction.month,
          from
        )
      : '';
    const toStringHashed = to
      ? await getStringHashedBySecretWord(
          transaction.to._id,
          transaction.year,
          transaction.month,
          to
        )
      : '';

    dispatch({
      type: 'LOAD_SECRET_WORD_SUCCESS',
      payload: {
        issuer,
        issuerSecretWord: issuerStringHashed,
        from,
        fromSecretWord: fromStringHashed,
        to,
        toSecretWord: toStringHashed,
        origin: originStringHashed,
        originField,
      },
    });

    //dispatch({ type: "LOAD_SECRET_WORD_FAIL" });
  };
