import { Tooltip } from '@mui/material';
import useCreateOrUpdateProductSlicesCombiner from 'components/CreateOrUpdateProduct/Redux/Slices/useCreateOrUpdateProductSlicesCombiner';

import infoIcon from '../../../../../../../../../../assets/images/icons/info.png';

type CustomTooltipPropsType = {
  infoX: any;
  field: string;
};

const CustomTooltip = ({ infoX, field }: CustomTooltipPropsType) => {
  const { productsFormError } = useCreateOrUpdateProductSlicesCombiner();

  return (
    <div
      style={{
        position: 'relative',
        top: productsFormError && productsFormError[field] ? -85 : -66,
        left: 285,
        width: 22,
        height: 22,
      }}
    >
      {infoX.desc && (
        <Tooltip title={infoX.desc}>
          <img
            src={infoIcon}
            alt={infoX.desc}
            style={{ width: 22, height: 22 }}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default CustomTooltip;
