import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useIssueCertificatesInBulkConfirmationModalConfig from '../../Configuration/useIssueCertificatesInBulkSecondModalConfig';

const ModalFooterIssueCertificatesSecondInBulkForm = () => {
  const { footer } = useIssueCertificatesInBulkConfirmationModalConfig();
  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterIssueCertificatesSecondInBulkForm;
