import GenericModalTitle from 'components/Administration/Generic/Modal/Title/GenericModalTitle';

import transferInBulkIcon from '../../../../../../../../assets/images/icons/transfer-certificate-green.png';

import ModalContentBulkOperations from './Form/Content/ModalContentBulkOperations';
import ModalFooterBulkOperations from './Form/Footer/ModalFooterBulkOperations';

const BulkOperationsDialog = () => {
  return (
    <>
      <GenericModalTitle
        title={'Issue/Transfer/Retire for multiple products'}
        icon={transferInBulkIcon}
      />

      <ModalContentBulkOperations />
      <ModalFooterBulkOperations />
    </>
  );
};

export default BulkOperationsDialog;
