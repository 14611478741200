import useLoginSlices from 'components/Balances/Redux/Slices/useLoginSlices';

import { UseDynamicListsSlicesCombinerType } from '../CustomHooks/types';

import useDynamicListsSlices from './useDynamicListsSlices';

const useDynamicListsSlicesCombiner = (): UseDynamicListsSlicesCombinerType => {
  return {
    ...useDynamicListsSlices(),
    ...useLoginSlices(),
  };
};

export default useDynamicListsSlicesCombiner;
