/* eslint-disable */
import {
  DEFAULT_FILTER_MODEL,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from 'components/Transactions/utils/constants';
import { multipleFilter } from '../../utils/arrayHelper';

const INITIAL_STATE = {
  transactions: [],
  allTransactions: [],
  aggregationId: undefined,
  error: { message: '', isVisible: false },
  loading: false,
  selected: [],
  page: DEFAULT_PAGE_NUMBER,
  pageSize: DEFAULT_PAGE_SIZE,
  paginatedTransactionsCount: 0,
  allTransactionsCount: 0,
  defaultTransactionsPaginationThreshold: 1000,
  filterModel: DEFAULT_FILTER_MODEL,
  dialogSecretWordOpen: false,
  dialogLoadingPdfOpen: false,
  selectedStandardId: undefined,
  selectedStandardType: undefined,
  selectedProductTypeId: undefined,
};

const transactionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_TRANSACTIONS_PRODUCT_TYPE':
      let transactionProductTypeIdFilter = action.payload;

      let productTypefilters = [
        { filter: 'productType', value: transactionProductTypeIdFilter },
        { filter: 'standard', value: state.selectedStandardId },
        { filter: 'standardType', value: state.selectedStandardType },
      ];

      let productTypefilteredTransactions = multipleFilter(
        state.allTransactions,
        productTypefilters
      );
      return {
        ...state,
        selectedProductTypeId: action.payload,
        transactions: productTypefilteredTransactions,
      };

    case 'SET_TRANSACTIONS_STANDARD':
      let transactionStandardIdFilter = action.payload;

      let standardFilters = [
        { filter: 'productType', value: state.selectedProductTypeId },
        { filter: 'standard', value: transactionStandardIdFilter },
        { filter: 'standardType', value: undefined },
      ];

      let standardFilteredTransactions = multipleFilter(
        state.allTransactions,
        standardFilters
      );

      return {
        ...state,
        selectedStandardId: action.payload,
        transactions: standardFilteredTransactions,
      };

    case 'SET_TRANSACTIONS_STANDARD_TYPE':
      let transactionStandardTypeFilter = action.payload;

      let standardTypeFilters = [
        { filter: 'productType', value: state.selectedProductTypeId },
        { filter: 'standard', value: undefined },
        { filter: 'standardType', value: transactionStandardTypeFilter },
      ];

      let standardTypeFilteredTransactions = multipleFilter(
        state.allTransactions,
        standardTypeFilters
      );

      return {
        ...state,
        selectedStandardType: action.payload,
        transactions: standardTypeFilteredTransactions,
      };

    //Getting transactions
    case 'GET_TRANSACTIONS_START':
      return { ...state, aggregationId: undefined, loading: true };

    case 'GET_TRANSACTIONS_SUCCESS':
      return {
        ...state,
        transactions: action.payload,
        allTransactions: action.payload,
        loading: false,
      };

    case 'GET_TRANSACTIONS_TOTAL_COUNT_SUCCESS':
      return {
        ...state,
        paginatedTransactionsCount: action.payload,
        loading: false,
      };
    case 'GET_ALL_TRANSACTIONS_TOTAL_COUNT_SUCCESS':
      return {
        ...state,
        allTransactionsCount: action.payload,
        loading: false,
      };

    case 'GET_TRANSACTIONS_FAIL':
      return { ...state, loading: false };

    case 'GET_TRANSACTIONS_BY_AGGREGATION_ID_START':
      return { ...state, loading: true };

    case 'GET_TRANSACTIONS_BY_AGGREGATION_ID_SUCCESS':
      return {
        ...state,
        transactions: action.payload,
        allTransactions: action.payload,
        loading: false,
      };

    case 'GET_TRANSACTIONS_BY_AGGREGATION_ID_TOTAL_COUNT_SUCCESS':
      return {
        ...state,
        paginatedTransactionsCount: action.payload,
        loading: false,
      };
    case 'GET_ALL_TRANSACTIONS_BY_AGGREGATION_ID_TOTAL_COUNT_SUCCESS':
      return {
        ...state,
        allTransactionsCount: action.payload,
        loading: false,
      };

    case 'GET_TRANSACTIONS_BY_AGGREGATION_ID_FAIL':
      return { ...state, loading: false };

    case 'SET_AGGREGATION_ID':
      return { ...state, aggregationId: action.payload };

    case 'RESET_TRANSACTIONS':
      return {
        ...state,
        loading: false,
        paginatedTransactionsCount: 0,
        allTransactionsCount: 0,
        transactions: [],
        allTransactions: [],
        aggregationId: undefined,
        page: DEFAULT_PAGE_NUMBER,
        pageSize: DEFAULT_PAGE_SIZE,
      };

    case 'LOGOUT_SUCCESS':
      return INITIAL_STATE;

    case 'SET_PAGE_NUMBER':
      return {
        ...state,
        page: action.payload,
      };
    case 'SET_PAGE_SIZE':
      return {
        ...state,
        pageSize: action.payload,
      };
    case 'SET_FILTER_MODEL':
      return {
        ...state,
        filterModel: action.payload,
      };
    case 'SET_DEFAULT_TRANSACTIONS_PAGINATION_THRESHOLD':
      return {
        ...state,
        defaultTransactionsPaginationThreshold: action.payload,
      };
    default:
      return state;
  }
};

export default transactionsReducer;
