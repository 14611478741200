import { Typography } from '@mui/material';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';

type FrequencyLabelPropsType = {
  label: string;
};

const FrequencyLabel = ({ label }: FrequencyLabelPropsType) => {
  return (
    <GenericGrid isItem xsLength={2}>
      <Typography
        variant="body2"
        gutterBottom
        color="textSecondary"
        style={{ fontSize: '0.875rem' }}
      >
        {label} :
      </Typography>
    </GenericGrid>
  );
};

export default FrequencyLabel;
