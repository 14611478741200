import { displayPlatinumUI } from 'components/CreateOrUpdateProduct/utils/utils';

export const halfYearArray = [
  { key: 'H1', value: '01', label: 'H1' },
  { key: 'H2', value: '07', label: 'H2' },
];

export const quarterArray = [
  { key: 'Q1', value: '01', label: 'Q1' },
  { key: 'Q2', value: '04', label: 'Q2' },
  { key: 'Q3', value: '07', label: 'Q2' },
  { key: 'Q4', value: '10', label: 'Q2' },
];
export const quantityTypeUppercase = displayPlatinumUI ? 'Quantity' : 'Volume';
