import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';

type GenericInputFieldPropsType = {
  name: string;
  label?: string;
  type: 'number' | 'text' | 'date';
  style: any;
  xs?: number;
  md?: number;
  lg?: number;
};

const GenericInputField = ({
  name,
  label = '',
  type,
  style,
  xs,
  md,
  lg,
}: GenericInputFieldPropsType) => {
  const { t } = useTranslation();
  return (
    <GenericGrid isItem xs={xs} md={md} lg={lg}>
      <Field
        style={style}
        component={TextField}
        label={t(label)}
        name={name}
        type={type}
      />
    </GenericGrid>
  );
};

export default GenericInputField;
