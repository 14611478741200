import Loading from 'components/GenericComponents/Async/Loading/Loading';

import calendarIcon from '../../assets/images/icons/schedule.png';
import { withRouter } from '../../utils/migrationHelper';
import PageTitle from '../commons/PageTitle';

import NominationsDialogs from './CustomComponents/Dialog/NominationsDialogs';
import NoOrganization from './CustomComponents/Forbiden/NoOrganization';
import Unauthorized from './CustomComponents/Forbiden/Unauthorized';
import OrganizationSelectors from './CustomComponents/OrganizationSelect/OrganizationSelectors';
import useNominations from './Redux/CustomHooks/useNominations';
import useNominationsGuards from './Redux/CustomHooks/useNominationsGuards';
import useNominationsSlicesCombiner from './Redux/Slices/useNominationsSlicesCombiner';

import './Styles/nominations.css';

const Nominations = () => {
  useNominations();
  const { buyerOrganization, independantsWithNominationsFeature, loading } =
    useNominationsSlicesCombiner();

  const {
    isSuperAdmin,
    hasNominationFeature,
    canManageMultipleOrganizationNominations,
  } = useNominationsGuards();

  if (loading) {
    return <Loading />;
  }

  if (!(isSuperAdmin || hasNominationFeature)) {
    return <Unauthorized />;
  }

  if (
    (!buyerOrganization || !independantsWithNominationsFeature[0]) &&
    canManageMultipleOrganizationNominations
  ) {
    return <NoOrganization />;
  }

  return (
    <>
      <PageTitle
        title={'Contracted volumes'}
        loading={loading}
        image={calendarIcon}
      />
      <OrganizationSelectors />
      <NominationsDialogs />
    </>
  );
};

export default withRouter(Nominations);
