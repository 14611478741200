import { GridColDef } from '@mui/x-data-grid-pro';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { CommodityTypes } from 'enums/CommodityTypes';
import {
  getProductOrganizationName,
  getProductProductTypeName,
} from 'utils/datagridHelpers';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';

const Columns = () => {
  const { user, isAuthenticated } = useProductSlicesCombiner();
  const dataGridColumns: GridColDef[] = [
    {
      field: 'displayedName',
      headerName: 'Product',
      editable: false,
      width: 200,
    },
    {
      field: 'productId',
      headerName: 'Product Id',
      editable: false,
      width: 200,
    },
    {
      field: '_productTypeName',
      headerName: CommodityTypes.upperCase,
      editable: false,
      width: 300,
      valueGetter: getProductProductTypeName,
      hide: !checkUserIsSuperAdmin(isAuthenticated, user),
    },
    {
      field: '_organizationName',
      headerName: 'Organization',
      editable: false,
      width: 300,
      valueGetter: getProductOrganizationName,
      hide: !checkUserIsSuperAdmin(isAuthenticated, user),
    },
  ];

  return dataGridColumns;
};

export default Columns;
