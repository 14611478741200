import { Location } from 'react-router';
import { CertificatesDataType } from 'reducers/balancesReducer/types';

import {
  convertGramsInOz,
  convertOzInGrams,
} from '../../../../../../utils/convert';
import { getAllocatedInitialVolume } from '../../../../utils/table/helpers';

export const getInitialValues = (
  allocateCertificatesData: CertificatesDataType,
  organizationsInAllocateMenu: any[],
  activateOzConversion: boolean,
  location: Location
) => ({
  organization:
    organizationsInAllocateMenu && organizationsInAllocateMenu[0]
      ? organizationsInAllocateMenu[0]._id
      : '',
  volume: getAllocatedInitialVolume(
    location,
    allocateCertificatesData,
    activateOzConversion
  ),
  showRetailer: location?.state?.allocateShowRetailerFlag
    ? location.state.allocateShowRetailerFlag
    : false, //set popup values with location state if available
});

export const retrieveCurrentAllocateCertificatesData = (
  location: Location,
  values: any,
  activateOzConversion: boolean,
  userOrganization: any
) => {
  const currentAllocateCertificatesData = values;
  //By default, allocation the action is carried out for the customer's own account, unless "for the benefit of" is selected
  if (!currentAllocateCertificatesData.showRetailer) {
    currentAllocateCertificatesData.organization = userOrganization._id;
  }

  if (location?.state?.displayAllocatePopUp) {
    location['state']['displayAllocatePopUp'] = false; // do not display retire popup
  }

  if (activateOzConversion)
    currentAllocateCertificatesData.volume = convertOzInGrams(values.volume);

  return currentAllocateCertificatesData;
};

export const onValidateHandler = (
  values: any,
  selectedBalanceData: any,
  activateOzConversion: boolean,
  quantityTypeUppercase: string
) => {
  const errors: any = {};

  let quantity = values.volume;
  const maxQuantity = selectedBalanceData._quantity;
  let maxQuantityToDisplay = selectedBalanceData._quantity;
  if (activateOzConversion) {
    quantity = convertOzInGrams(values.volume);
    maxQuantityToDisplay = convertGramsInOz(selectedBalanceData._quantity);
  }

  if (values.showRetailer && !values.organization) {
    errors.organization = 'Required.';
  }
  if (values.volume == null) {
    errors.volume = 'Required';
  } else if (isNaN(parseFloat(values.volume))) {
    errors.volume = 'Invalid number';
  } else if (values.volume === 0) {
    errors.volume = quantityTypeUppercase + ' cannot be null';
  } else if (values.volume % 1 !== 0) {
    errors.volume = quantityTypeUppercase + ' must be a whole number';
  } else if (quantity > maxQuantity) {
    errors.volume = 'Overcapacity. Max is ' + parseInt(maxQuantityToDisplay);
  }
  return errors;
};
