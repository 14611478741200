import { useEffect } from 'react';

import {
  getDynamicListsStart,
  getProductTypesStart,
  getStandardStart,
  resetCurrentStandard,
} from 'actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';

import useFormStandardsSlicesCombiner from '../Slices/Combiner/useFormStandardsCombiner';

type UseFormStandardPropsType = {
  standardId: string;
};
const useFormStandard = ({ standardId }: UseFormStandardPropsType) => {
  const { isAuthenticated, user, formIsFilledOut } =
    useFormStandardsSlicesCombiner();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    async function initComponent() {
      if (checkUserIsSuperAdmin(isAuthenticated, user)) {
        if (standardId) {
          await dispatch(getStandardStart(standardId));
        } else {
          dispatch(resetCurrentStandard());
        }
        await dispatch(getProductTypesStart());
        await dispatch(getDynamicListsStart());
      } else navigate('/');
    }
    initComponent();
  }, []);

  useEffect(() => {
    if (formIsFilledOut) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [formIsFilledOut]);
};

export default useFormStandard;
