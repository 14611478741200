import { useEffect } from 'react';

import { getStandardsStart } from 'actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';

import useStandardsCombiner from '../Slices/Combiner/useStandardsCombiner';

const useStandards = async () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { isAuthenticated, user } = useStandardsCombiner();

  useEffect(() => {
    async function initComponent() {
      if (!isAuthenticated || !user) {
        navigate('/');
      } else {
        await dispatch(getStandardsStart());
      }
    }
    initComponent();
  }, []);
};

export default useStandards;
