import { LinearProgress } from '@mui/material';
import { GridColDef, DataGridPro as Table } from '@mui/x-data-grid-pro';
import useCertificates from 'components/Certificates/Redux/CustomHooks/useCertificates';
import useCertificesSlicesCombiner from 'components/Certificates/Redux/Slices/useCertificatesSlicesCombiner';
import LoadAll from 'components/commons/LoadAll/LoadAll';
import { FILTER_MODE_CLIENT_SIDE } from 'components/Transactions/utils/constants';

import CertificatesToolbar from '../Toolbar/CertificatesToolbar';

import GridToolbar from './CustomTableToolbar/GridToolbar';
import CertificatesColumns from './utils/columns';

const CertificatesTable = () => {
  const {
    certificates,
    allCertificatesCount,
    paginatedCertificatesCount,
    defaultCertificatesPaginationThreshold,
    loading,
  } = useCertificesSlicesCombiner();

  const { handlePageChange, handlePageSizeChange, page, pageSize } =
    useCertificates();

  const dataGridColumns: GridColDef[] = CertificatesColumns();
  const useServerSidePagination =
    (allCertificatesCount as number) >
    (defaultCertificatesPaginationThreshold as number);
  return (
    <div
      style={{
        height: document.documentElement.clientHeight - 200,
        boxShadow: 'none',
        paddingBottom: 63,
      }}
      data-testid={'certificates-table'}
    >
      <CertificatesToolbar />
      <Table
        pagination
        loading={loading}
        paginationMode={useServerSidePagination ? 'server' : 'client'}
        filterMode={FILTER_MODE_CLIENT_SIDE}
        rows={certificates as any[]}
        columns={dataGridColumns}
        components={{
          LoadingOverlay: LinearProgress,
          Toolbar: GridToolbar,
          Footer: useServerSidePagination
            ? () => (
                <LoadAll
                  onClick={() => {
                    handlePageSizeChange(allCertificatesCount as number);
                  }}
                  loadedRows={paginatedCertificatesCount as number}
                  totalRows={allCertificatesCount as number}
                  typeOfData="certificates"
                />
              )
            : undefined,
        }}
        page={useServerSidePagination ? page : undefined}
        pageSize={useServerSidePagination ? pageSize : undefined}
        rowCount={paginatedCertificatesCount}
        onPageChange={useServerSidePagination ? handlePageChange : undefined}
        onPageSizeChange={
          useServerSidePagination ? handlePageSizeChange : undefined
        }
      />
    </div>
  );
};

export default CertificatesTable;
