import { Button, DialogActions } from '@mui/material';
import { toggleSecretWordDialog } from 'actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const TransactionsDialogFooter = () => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <DialogActions>
      <Button
        onClick={() => dispatch(toggleSecretWordDialog())}
        color="primary"
      >
        Close
      </Button>
    </DialogActions>
  );
};

export default TransactionsDialogFooter;
