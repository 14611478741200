import { CardContent, Grid } from '@mui/material';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';

import DynamicFieldsGroup from './FieldGroups/DynamicFieldsGroup/DynamicFieldsGroup';
import OrganizationUnitTimezoneGroup from './FieldGroups/OrganizationUnitTimezoneGroup/OrganizationUnitTimezoneGroup';
import ProductInfoGroup from './FieldGroups/ProductInfoGroup/ProductInfoGroup';
import ProductTypeAndStandardGroup from './FieldGroups/ProductTypeAndStandardGroup/ProductTypeAndStandardGroup';

const ProductDetailsCardContent = () => {
  return (
    <CardContent style={{ marginLeft: 24, marginBottom: 30 }}>
      <GenericGrid isContainer spacing={2}>
        <Grid
          container
          spacing={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ProductInfoGroup />
          <ProductTypeAndStandardGroup />
          <DynamicFieldsGroup />
          <OrganizationUnitTimezoneGroup />
        </Grid>
      </GenericGrid>
    </CardContent>
  );
};

export default ProductDetailsCardContent;
