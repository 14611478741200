import React, { useEffect } from 'react';

import { useTheme } from '@emotion/react';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux';

import { setOrganizationsToMakeBusinessWith } from '../../actions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 550,
    },
  },
};

function getStyles(organizationId, selectedOrganization, theme) {
  return {
    fontWeight:
      selectedOrganization.indexOf(organizationId) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function OrganizationMultipleSelect() {
  const dispatch = useDispatch();

  let organizations = useSelector(
    (state) => state.organizationsReducer.parentOrganizations
  );
  let currentOrganization = useSelector(
    (state) => state.organizationsReducer.currentOrganization
  );

  const classes = useTheme();
  const [selectedOrganization, setSelectedOrganization] = React.useState([]);

  useEffect(() => {
    if (currentOrganization && currentOrganization.businessOrganizations) {
      let businessOrganizationIds =
        currentOrganization.businessOrganizations.map((org) => org._id);
      setSelectedOrganization(businessOrganizationIds);
    }
  }, [currentOrganization]);

  const handleChange = (event) => {
    let selectedOrganizationIds = event.target.value;
    setSelectedOrganization(selectedOrganizationIds);
    dispatch(setOrganizationsToMakeBusinessWith(selectedOrganizationIds));
  };

  if (!organizations || organizations.length < 1) return false;
  return (
    <FormControl style={classes.formControl}>
      <InputLabel id="demo-mutiple-chip-label">
        Can make business with
      </InputLabel>
      <Select
        labelId="demo-mutiple-chip-label"
        id="demo-mutiple-chip"
        multiple
        value={selectedOrganization}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div style={classes.chips}>
            {selected.map((value) => {
              let org = organizations.find((org) => org._id === value);
              return <Chip key={value} label={org.name} style={classes.chip} />;
            })}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {organizations.map((organization) => (
          <MenuItem
            key={organization._id}
            value={organization._id}
            style={getStyles(organization._id, selectedOrganization, classes)}
          >
            {organization.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
