import { DefaultProductType } from 'reducers/productsReducer/types';

import { CommodityTypes } from '../../../../../../enums/CommodityTypes';

export type SummaryFieldPropsType = {
  labelName: string;
  displayedValue: string;
};
export const getStaticFieldsList = (
  currentProduct: DefaultProductType
): SummaryFieldPropsType[] => {
  const { displayedName, unit, standard } = currentProduct;
  return [
    { labelName: 'Name', displayedValue: displayedName },
    { labelName: 'Unit', displayedValue: unit },
    {
      labelName: CommodityTypes.upperCase,
      displayedValue: standard?.productType?.name,
    },
    { labelName: 'Standard', displayedValue: standard?.name },
  ];
};
