import { red, orange, green, blue } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

import BackgroundImage from './assets/images/background-american-public-power-association-unsplash.jpg';

let themeInit = createTheme();
export const drawerWidth = 240;

export const theme = createTheme(themeInit, {
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#F87733',
      contrastText: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: '#f50057',
      contrastText: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  typography: {
    fontFamily: [
      'Inter-Regular',
      'Avenir',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiTypography: {
      // Name of the component ⚛️ / style sheet
      styleOverrides: {
        root: {
          fontFamily: [
            'Inter-Regular',
            'Avenir',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
          ].join(','),
          letterSpacing: 0,
        },
        body1: {
          color: 'rgba(0, 0, 0, 0.87)', // Some CSS
        },
        body2: {
          color: 'rgba(0, 0, 0, 0.87)', // Some CSS
          fontWeight: 500,
        },
        caption: {
          display: 'block',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          fontFamily: [
            'Inter-Regular',
            'Avenir',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
          ].join(','),
        },
        displayedRows: {
          fontFamily: [
            'Inter-Regular',
            'Avenir',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
          ].join(','),
        },
        select: {
          fontFamily: [
            'Inter-Regular',
            'Avenir',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
          ].join(','),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: [
            'Inter-Regular',
            'Avenir',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
          ].join(','),
          letterSpacing: 0,
          '&:hover': { backgroundColor: '#DC530A' },
        },
        text: {
          '&:hover': { backgroundColor: '#FFBB97' },
        },
        outlined: {
          '&:hover': { backgroundColor: '#FFBB97' },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontFamily: [
            'Inter-Regular',
            'Avenir',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
          ].join(','),
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: [
            'Inter-Regular',
            'Avenir',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
          ].join(','),
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: [
            'Inter-Regular',
            'Avenir',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
          ].join(','),
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': { borderBottom: '1px solid rgba(0, 0, 0, 0.42)' },
        },
        root: {
          fontFamily: [
            'Inter-Regular',
            'Avenir',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
          ].join(','),
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: [
            'Inter-Regular',
            'Avenir',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
          ].join(','),
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '14px 40px 14px 16px',
          fontSize: '0.8125rem',
          fontFamily: [
            'Inter-Regular',
            'Avenir',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
          ].join(','),
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: '#F87733',
        },
      },
    },
  },
  appRoot: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  snackbarError: {
    backgroundColor: red[400],
    color: '#ffffff',
  },
  snackbarWarning: {
    backgroundColor: orange[500],
    color: '#ffffff',
  },
  snackbarSuccess: {
    backgroundColor: green[600],
    color: '#ffffff',
  },
  snackbarInfo: {
    backgroundColor: blue[500],
    color: '#ffffff',
  },
  listItemText: {
    fontSize: '0.8rem',
    fontFamily: [
      'Inter-Regular',
      'Avenir',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  appBar: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.54)',
    zIndex: themeInit.zIndex.drawer + 1,
    position: 'absolute',
    [themeInit.breakpoints.up('md')]: {},
  },
  appBarLanding: {
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.54)',
    boxShadow: 'none',
    position: 'absolute',
    marginLeft: drawerWidth,
    [themeInit.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  navIconHide: {
    [themeInit.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: {
    minHeight: 56,
    '@media (minWidth:0px) and (orientation: landscape)': {
      minHeight: 48,
    },
    '@media (minWidth:600px)': { minHeight: 64 },
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'relative',
    borderRight: 0,
  },
  content: {
    flexGrow: 1,
    backgroundColor: themeInit.palette.background.default,
    padding: themeInit.spacing(3),
  },
  imageBackgroundNotAuthenticated: {
    backgroundImage: 'url(' + BackgroundImage + ')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
  },
  fieldTitle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 400,
    fontSize: 12,
    fontFamily:
      '"Inter-Regular", "Inter-Regular","Avenir","Roboto","Helvetica","Arial","sans-serif"',
  },
  formControl: {
    minWidth: 300,
    maxWidth: 600,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: themeInit.spacing(3),
  },
});
