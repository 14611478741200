export const removeDuplicatesFromArrayByProperty = (arr, prop) =>
  arr.reduce((accumulator, currentValue) => {
    if (!accumulator.find((obj) => obj[prop] === currentValue[prop])) {
      accumulator.push(currentValue);
    }
    return accumulator;
  }, []);

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const filter = (items, filterType, filterValue, itemsAreBalances) => {
  if (!filterType || !filterValue) return items;
  if (
    (filterType === 'standardType' || filterType === 'standard') &&
    filterValue === 'allStandards'
  )
    return items;
  if (filterType === 'productType' && filterValue === 'allProductTypes')
    return items;

  if (filterType === 'standardType') {
    let itemsFilteredByStandardType = items.filter((item) => {
      let standard = itemsAreBalances
        ? item?._origin?.standard
        : item?.origin?.standard;
      let isCertificateSelected = standard
        ? standard.standardType === filterValue
        : false;
      return isCertificateSelected;
    });

    return itemsFilteredByStandardType;
  } else if (filterType === 'standard') {
    let itemsFilteredByStandard = items.filter((item) => {
      let standard = itemsAreBalances
        ? item?._origin?.standard
        : item?.origin?.standard;
      let isCertificateSelected = standard
        ? standard._id === filterValue
        : false;
      return isCertificateSelected;
    });

    return itemsFilteredByStandard;
  } else if (filterType === 'productType') {
    let itemsFilteredByProductType = items.filter((item) => {
      let standard = itemsAreBalances
        ? item?._origin?.standard
        : item?.origin?.standard;
      let isCertificateSelected = standard?.productType
        ? standard.productType._id === filterValue
        : false;
      return isCertificateSelected;
    });

    return itemsFilteredByProductType;
  } else return items;
};

export const multipleFilter = (items, filtersAndValues, itemsAreBalances) => {
  let filteredItems = [...items];
  for (const filterAndValue of filtersAndValues) {
    filteredItems = filter(
      [...filteredItems],
      filterAndValue.filter,
      filterAndValue.value,
      itemsAreBalances
    );
  }

  return filteredItems;
};
