export const frequencyProps = [
  {
    inputName: 'aggregationPeriodNumber',
    selectName: 'aggregationPeriodType',
    label: 'Certificate time granularity ',
  },
  {
    inputName: 'periodNumber',
    selectName: 'periodType',
    label: 'Raw data granularity',
  },
];
