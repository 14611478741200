import { Grid } from '@mui/material';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';

import GenericErrorInRed from '../../../../../GenericComponents/Errors/GenericErrorInRed';
import useBalancesChildComponentsSlices from '../../../../Redux/CustomHooks/useBalancesChildComponentsSlices';
import SelectOrganizationsList from '../../common/Fields/SelectList/OrganizationsList';

type TransferedToFieldPropsType = {
  errors: any;
};

const TransferedToField = ({ errors }: TransferedToFieldPropsType) => {
  const {
    transferCertificatesFormError,
  }: UseBalancesChildComponentsSlicesType = useBalancesChildComponentsSlices();
  return (
    <Grid item xs={12}>
      <SelectOrganizationsList label="To" />
      <>
        <GenericErrorInRed
          condition={
            transferCertificatesFormError && transferCertificatesFormError._to
          }
          errors={transferCertificatesFormError}
          customKey={'_to'}
        />
        <GenericErrorInRed
          condition={errors?.organization}
          errors={errors}
          customKey={'organization'}
        />
      </>
    </Grid>
  );
};

export default TransferedToField;
