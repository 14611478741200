import InformationPopup from 'components/commons/InformationPopup';
import useLoadingResources from 'components/CreateOrUpdateProduct/Redux/CustomHooks/useLoadingResources';

import { CommodityTypes } from '../../../../../enums/CommodityTypes';

const OrganizationPopupMessage = (): JSX.Element => {
  const {
    getNoOrganizationsCondition,
    getNoProductTypesCondition,
    getNoStandardCondition,
  } = useLoadingResources();

  const renderNoResourceMessage = (resourceType: string) => {
    const message = `There is no ${resourceType} setup, please create at least one ${resourceType}.`;
    return <InformationPopup message={message} />;
  };

  if (getNoOrganizationsCondition()) {
    return renderNoResourceMessage('organization');
  }

  if (getNoStandardCondition()) {
    return renderNoResourceMessage('standard');
  }

  if (getNoProductTypesCondition()) {
    return renderNoResourceMessage(CommodityTypes.lowerCase);
  }
  return <></>;
};

export default OrganizationPopupMessage;
