import React from 'react';

import GenericModalContent from 'components/Administration/Generic/Modal/Content/GenericModalContent';

import useCheckAttributesFormModalConfig from '../../Configuration/Configuration/useCheckAttributesFormModalConfig';

const ModalContentCheckAttributesForm = () => {
  const { header, content } = useCheckAttributesFormModalConfig();

  return <GenericModalContent header={header} content={content} />;
};

export default ModalContentCheckAttributesForm;
