import React from 'react';

import { Tab, Tabs } from '@mui/material';
import {
  flexYearChanged,
  getNominationsOfIssuerOrganizationByYear,
} from 'actions';
import useNominationsSlicesCombiner from 'components/Nominations/Redux/Slices/useNominationsSlicesCombiner';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';

const NominationsYearsTabs = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    year,
    user,
    isAuthenticated,
    buyerOrganization,
    selectedSellerOrganization,
  } = useNominationsSlicesCombiner();
  const yearsArray = [
    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
  ];

  const handleYearChange = async (event: any, newValue: string) => {
    dispatch(flexYearChanged(newValue));
    const userOrganization = user.user_metadata.organization;

    const isSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, user);
    const hasSetBusinessOrganizationNominationFeature = checkUserHasRight(
      isAuthenticated,
      user,
      RightReferences.NOMINATIONS,
      [CrudPermissions.UPDATE]
    );

    if (isSuperAdmin) {
      await dispatch(
        getNominationsOfIssuerOrganizationByYear(
          buyerOrganization?._id,
          selectedSellerOrganization?._id,
          newValue
        )
      );
    } else if (hasSetBusinessOrganizationNominationFeature) {
      await dispatch(
        getNominationsOfIssuerOrganizationByYear(
          buyerOrganization?._id,
          selectedSellerOrganization?._id,
          newValue
        )
      );
    } else if (
      checkUserHasRight(isAuthenticated, user, RightReferences.NOMINATIONS, [
        CrudPermissions.VIEW,
      ])
    ) {
      await dispatch(
        getNominationsOfIssuerOrganizationByYear(
          userOrganization?._id,
          selectedSellerOrganization?._id,
          newValue
        )
      );
    }
  };

  return (
    <Tabs
      value={parseInt(year)}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleYearChange}
      variant="scrollable"
      scrollButtons="auto"
    >
      {yearsArray.map((year) => {
        const disabled = Boolean(year > 2025);
        return <Tab label={year} value={year} key={year} disabled={disabled} />;
      })}
    </Tabs>
  );
};

export default NominationsYearsTabs;
