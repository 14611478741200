/* eslint-disable */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { withRouter } from '../../utils/migrationHelper';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import moment from 'moment';

import { withTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate, useLocation } from 'react-router-dom';
import teoLogo from '../../assets/images/teoLogoBleu.png';
import popLogo from '../../assets/images/teoPopLogoBleu.png';
import { default as HeaderImage } from '../../assets/images/landing/artwork-gas.jpg';
import { default as HeaderImagePlatinum } from '../../assets/images/landing/artwork-platinum.jpg';

import CookieConsent from 'react-cookie-consent';
import { ClassNames } from '@emotion/react';

import {
  auth0Login,
  handleAuthenticationAuth0,
  checkCookieConsent,
  acceptCookies,
  declineCookies,
  logoutStart,
} from '../../actions';

import { styles } from './style';

const cookiePolicyUrl = 'https://attributes.digital/cookies-policy';
const privacyPolicyUrl = 'https://attributes.digital/privacy-policy';

let Landing = ({
  handleAuthenticationAuth0,
  checkCookieConsent,
  t,
  loadingCheck,
  cookiesAccepted,
  cookies,
  auth0Login,
  logoutStart,
  declineCookies,
  acceptCookies,
}) => {
  let navigate = useNavigate();
  let location = useLocation();
  const classes = styles;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'CHECK_AUTHENTICATION_START' });
    try {
      handleAuthenticationAuth0(navigate, location);
      dispatch({ type: 'CHECK_AUTHENTICATION_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'CHECK_AUTHENTICATION_FAIL' });
    }
    checkCookieConsent();
  }, []);

  if (loadingCheck) {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
        <CircularProgress size={32} />
      </div>
    );
  }

  let platinumData = {
    headerSource: HeaderImagePlatinum,
    title: 'Certificate of Origin by PGM Origin Proof (POP)',
    baseline: 'Your metal has known ESG credentials.',
  };

  let gasData = {
    headerSource: HeaderImage,
    title: 'Transparency Certificates',
    baseline:
      'We bring transparency to energy markets and empower consumers with the tools necessary to choose the origin and ESG impact of their energy.',
  };

  let data =
    process.env?.REACT_APP_PRODUCT_CATEGORY === 'platinum'
      ? platinumData
      : gasData;
  let logo =
    process.env?.REACT_APP_PRODUCT_CATEGORY === 'platinum' ? popLogo : teoLogo;

  return (
    <ClassNames>
      {({ css }) => (
        <div>
          <div
            style={{
              backgroundColor: '#ffffff',
              minHeight: '100vh',
              flexDirection: 'column',
              display: 'flex',
              padding: 20,
            }}
          >
            <Grid container spacing={2} style={{ flex: 1 }}>
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <img
                  src={logo}
                  style={{ height: 45, marginLeft: 35, marginTop: 46 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <img src={data.headerSource} style={{ width: '80%' }} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ textAlign: 'center' }}
                >
                  <span className={css(classes.myHeroTitle)} id="heroTitle">
                    {data.title}
                  </span>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ textAlign: 'center' }}
                >
                  <span className={css(classes.myBaseline)}>
                    {data.baseline}
                  </span>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Button
                    variant="flat"
                    id="logInButton"
                    aria-label={t('Log in')}
                    className={css(classes.buttonHome)}
                    onClick={() => {
                      if (
                        cookiesAccepted === null ||
                        cookiesAccepted === false
                      ) {
                        alert('Cookies must be accepted to use Attributes');
                      } else {
                        dispatch(auth0Login);
                      }
                    }}
                  >
                    {t('Log in')}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} style={{ padding: 20 }}>
                <span className={css(classes.myFooterTextNew)}>
                  &copy; {moment().format('YYYY')} Attributes SAS | 
                </span>
                <a
                  href="mailto:contact@attributes.digital"
                  className={css(classes.myFooterTextNew)}
                >
                  contact@attributes.digital
                </a>
                <span className={css(classes.myFooterTextNew)}>
                  {' '}
                  | 1 Place Samuel-Champlain 92400 Courbevoie France | 
                </span>
                <a
                  href={privacyPolicyUrl}
                  className={css(classes.myFooterTextNew)}
                  target="_blank"
                >
                  {t('Privacy policy')}
                </a>
                <span className={css(classes.myFooterTextNew)}> | </span>
                <a
                  onClick={() => {
                    logoutStart(cookies, true);
                  }}
                  className={css(classes.myFooterTextNew)}
                  target="_blank"
                >
                  Logout
                </a>
              </Grid>
            </Grid>

            <CookieConsent
              buttonId="acceptCookieButton"
              location="bottom"
              buttonText="I accept"
              enableDeclineButton
              onDecline={() => {
                declineCookies();
              }}
              onAccept={() => {
                acceptCookies();
              }}
              style={{
                background: '#ffffff',
                color: '#F87733',
                borderTop: '1px solid #F87733',
              }}
              buttonStyle={{
                margin: 0,
                marginRight: 20,
                padding: '0 16px',
                opacity: 1,
                fontSize: '0.5rem',
                letterSpacing: '1.3px',
                fontFamily:
                  '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 'bold',
                lineHeight: '1.55rem',
                color: '#ffffff',
                border: 'none',
                borderRadius: 4,
                textTransform: 'uppercase',
                backgroundColor: '#F87733',
                backgroundImage: 'linear-gradient(#F59B6B,#F87733)',
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
                boxShadow: '0px 8px 24px 0px rgb(248 119 51 / 40%)',
                transition: 'all .5s ease',
                textDecoration: 'none',
              }}
              declineButtonStyle={{
                margin: 0,
                marginRight: 20,
                padding: '0 16px',
                opacity: 1,
                fontSize: '0.5rem',
                letterSpacing: '1.3px',
                fontFamily:
                  '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 'bold',
                lineHeight: '1.55rem',
                color: '#F87733',
                borderRadius: 4,
                textTransform: 'uppercase',
                backgroundColor: '#ffffff',
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
                boxShadow: '0px 8px 24px 0px rgb(248 119 51 / 40%)',
                transition: 'all .5s ease',
                textDecoration: 'none',
                border: '1px solid #F87733',
              }}
              expires={150}
            >
              <Typography
                className={css(classes.cookieConsentTitle)}
                gutterBottom
                id="cookieBannerTitle"
              >
                What do we use cookies for ?
              </Typography>
              <Typography
                className={css(classes.cookieConsentText)}
                gutterBottom
              >
                We use cookies and similar technologies to recognize you when
                you are logged in. To learn more about cookies, including how to
                disable them, view our{' '}
                <a
                  href={cookiePolicyUrl}
                  style={{ color: '#F87733' }}
                  target="_blank"
                >
                  cookie policy
                </a>
                . By clicking "I accept" on this banner, you consent to the use
                of cookies unless you have disabled them.
              </Typography>
            </CookieConsent>
          </div>
        </div>
      )}
    </ClassNames>
  );
};

Landing.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = ({ loginReducer, utilsReducer }) => {
  const { loading, user, isAuthenticated, loadingCheck } = loginReducer;
  const { cookiesAccepted } = utilsReducer;
  return {
    loading,
    user,
    isAuthenticated,
    location,
    loadingCheck,
    cookiesAccepted,
  };
};

const mapDispatchToProps = {
  auth0Login,
  handleAuthenticationAuth0,
  checkCookieConsent,
  acceptCookies,
  declineCookies,
  logoutStart,
};

Landing = withTranslation('translations')(Landing);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(Landing))
);
