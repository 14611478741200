import React from 'react';

import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useIssuanceEmailNotificationFormModalConfig from '../../Configuration/useIssuanceEmailNotificationFormModalConfig';

const ModalFooterIssuanceEmailNotificationForm = () => {
  const { footer } = useIssuanceEmailNotificationFormModalConfig();
  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterIssuanceEmailNotificationForm;
