import { PropsWithChildren } from 'react';

import { Card, Grid, Grid2Props } from '@mui/material';

export type GenericGridPropsWithChildrenType = PropsWithChildren &
  Grid2Props & {
    isCard?: boolean;
    isContainer?: boolean;
    isItem?: boolean;
    spacing?: number;
    xsLength?: number;
    mdLength?: number;
    lgLength?: number;
    style?: any;
    dataTestId?: string;
  };

const GenericGrid = ({
  isCard = false,
  isContainer,
  isItem,
  spacing,
  xsLength,
  mdLength,
  lgLength,
  style,
  dataTestId,
  children,
  ...restProps
}: GenericGridPropsWithChildrenType) => {
  return (
    <Grid
      {...restProps}
      {...(isContainer && { container: true })}
      {...(isItem && { item: true })}
      spacing={spacing}
      xs={xsLength}
      md={mdLength}
      lg={lgLength}
      style={style}
      data-testid={dataTestId}
    >
      {!isCard ? children : <Card>{children}</Card>}
    </Grid>
  );
};

export default GenericGrid;
