import { FormControlLabel, FormGroup, Typography, Switch } from '@mui/material';

type BlockchainDataSwitchPropsType = {
  onToggleVisibility: (param: boolean) => void;
};
const BlockchainDataSwitch = ({
  onToggleVisibility,
}: BlockchainDataSwitchPropsType) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            size="small"
            onChange={(event) => {
              onToggleVisibility(event.target.checked);
            }}
          />
        }
        label={
          <Typography
            variant="subtitle2"
            color="grey"
          >{`Display blockchain data`}</Typography>
        }
        labelPlacement="start"
      />
    </FormGroup>
  );
};

export default BlockchainDataSwitch;
