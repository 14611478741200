import { createTheme } from '@mui/material/styles';

let theme = createTheme();

export const tabStyles = createTheme(theme, {
  root: {
    width: '99%',
    marginTop: theme.spacing(3),
  },
  tabList: {
    borderBottom: '4px solid #0F324A',
    margin: '0 0 10px',
    padding: 0,
  },
  tab: {
    fontSize: '1rem',
    fontFamily:
      '"Inter-Regular", "Avenir", "Roboto", "Helvetica", "Arial", sans-serif',
    display: 'inline-block',
    border: '1px solid transparent',
    borderBottom: 'none',
    bottom: '-1px',
    position: 'relative',
    listStyle: 'none',
    padding: '8px 16px',
    margin: '0px 24px 0px 0px',
    cursor: 'pointer',
  },
  tabSelected: {
    background: '#0F324A',
    borderColor: '#0F324A',
    color: '#ffffff',
    borderRadius: '0px 0px 0 0',
  },
  tabDisabled: {
    color: '#000000',
    cursor: 'default',
  },
});
