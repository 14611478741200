let displayPlatinumUI = process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';
const commodityOrMetal = displayPlatinumUI ? 'metal' : 'commodity';
const commodityOrMetalUppercase = displayPlatinumUI ? 'Metal' : 'Commodity';
const commoditysOrMetals = displayPlatinumUI ? 'metals' : 'commodities';
const commoditysOrMetalsUppercase = displayPlatinumUI
  ? 'Metals'
  : 'Commodities';

export const CommodityTypes = {
  lowerCase: commodityOrMetal,
  upperCase: commodityOrMetalUppercase,
  lowerCasePlural: commoditysOrMetals,
  upperCasePlural: commoditysOrMetalsUppercase,
};
