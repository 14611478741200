import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

const MenuItemIcon = (props: any): JSX.Element => {
  const { title, imgTitle, altTitle, onClick, icon, key } = props;

  const _altTitle = altTitle || imgTitle || title;
  const _imgTitle = imgTitle || title;
  const _title = title || '';

  return (
    <MenuItem key={key} onClick={onClick}>
      <ListItemIcon>
        <img
          src={icon}
          alt={_altTitle}
          title={_imgTitle}
          style={{
            width: 22,
            height: 22,
            marginTop: 6,
            marginLeft: 2,
          }}
        />
      </ListItemIcon>
      <ListItemText>{_title}</ListItemText>
    </MenuItem>
  );
};

export default MenuItemIcon;
