import { useEffect, useRef } from 'react';

import GenericModalTitle from 'components/Administration/Generic/Modal/Title/GenericModalTitle';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import increaseIcon from '../../../../../../../assets/images/icons/increase.png';

import useIncreaseBalanceHandlers from './CustomHooks/useIncreaseBalanceHandlers';
import ModalContentIncreaseBalanceConfirmation from './Form/Content/ModalContentIncreaseBalanceConfirmation';
import ModalContentIncreaseBalanceForm from './Form/Content/ModalContentIncreaseBalanceForm';
import ModalFooterIncreaseBalanceConfirmation from './Form/Footer/ModalFooterIncreaseBalanceConfirmation';
import ModalFooterIncreaseBalanceForm from './Form/Footer/ModalFooterIncreaseBalanceForm';
import { getValidationForm } from './Form/FormValidation/validationForm';

const IncreaseBalanceDialog = () => {
  const { t } = useTranslation();
  const {
    increaseBalanceData,
    displayIncreaseBalanceFormInDialog,
    resetIncreaseBalanceForm,
  } = useAdministrationSlicesCombiner();
  const { onSubmitHandler } = useIncreaseBalanceHandlers();
  const formikRef = useRef<FormikProps<any>>(null);

  const initialValues = {
    origin: increaseBalanceData?._origin?._id,
    organization: increaseBalanceData?._for?._id,
    volume: increaseBalanceData?._volume,
    year: increaseBalanceData?._year,
    month: increaseBalanceData?._month,
    txHash: increaseBalanceData?._txHash,
    comment: increaseBalanceData?._comment,
  };

  useEffect(() => {
    if (resetIncreaseBalanceForm) {
      formikRef.current?.resetForm({
        values: {
          origin: '',
          organization: '',
          volume: '',
          year: '',
          month: '',
          txHash: '',
          comment: '',
        },
      });
    }
  }, [resetIncreaseBalanceForm, formikRef]);

  return (
    <>
      <GenericModalTitle
        id="responsive-dialog-title"
        title={t('Increase balance')}
        icon={increaseIcon}
      />

      {displayIncreaseBalanceFormInDialog && (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
          validate={(values) => getValidationForm(values)}
        >
          {() => {
            return (
              <>
                <ModalContentIncreaseBalanceForm />
                <ModalFooterIncreaseBalanceForm />
              </>
            );
          }}
        </Formik>
      )}
      {!displayIncreaseBalanceFormInDialog && (
        <>
          <ModalContentIncreaseBalanceConfirmation />
          <ModalFooterIncreaseBalanceConfirmation />
        </>
      )}
    </>
  );
};

export default IncreaseBalanceDialog;
