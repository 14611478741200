/* eslint-disable */

const INITIAL_STATE = {
  matchingTypes: [],
  loading: false,
};

const matchingTypesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_MATCHING_TYPES_START':
      return { ...state, loading: true };

    case 'GET_MATCHING_TYPES_SUCCESS':
      return { ...state, matchingTypes: action.payload, loading: false };

    case 'GET_MATCHING_TYPES_FAIL':
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default matchingTypesReducer;
