import { Box } from '@mui/system';
import {
  setTransactionsProductType,
  setTransactionsStandard,
  setTransactionsStandardType,
} from 'actions';
import GramsToOzToggleSwitch from 'components/commons/GramsToOzToggleSwitch';
import LoadingCircle from 'components/commons/LoadingCircle';
import PageTitle from 'components/commons/PageTitle';
import { displayPlatinumUI } from 'components/CreateOrUpdateProduct/utils/utils';
import GenericBasicSelect from 'components/GenericComponents/BasicFields/GenericBasicSelect/GenericBasicSelect';
import useTransactionsCombiner from 'components/Transactions/Redux/Slices/Combiner/useTransactionsCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import transactionsIcon from '../../../../../assets/images/icons/transactions2.png';

import {
  getProductTypeItems,
  getTransactionsStandardItems,
} from './utils/utils';

const TransactionsToolbar = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    loading,
    loadingUniqueStandardsFromBalances,
    uniqueStandardsFromBalances,
    selectedStandardId,
    selectedStandardType,
    selectedProductTypeId,
    loadingProductTypes,
    productTypes,
  } = useTransactionsCombiner();

  const handleProductTypeChange = (event: any) => {
    const newProductTypeId = event.target.value;
    dispatch(setTransactionsProductType(newProductTypeId));
  };

  const handleTransactionsStandardChange = (event: any) => {
    if (!displayPlatinumUI) {
      const newStandardId = event.target.value;
      dispatch(setTransactionsStandard(newStandardId));
    } else {
      const newStandardType = event.target.value;
      dispatch(setTransactionsStandardType(newStandardType));
    }
  };

  const productTypeItems = getProductTypeItems(productTypes);
  const transactionsStandardItems = getTransactionsStandardItems(
    uniqueStandardsFromBalances,
    displayPlatinumUI
  );
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
      }}
    >
      <PageTitle
        title={'Transactions'}
        loading={loading}
        image={transactionsIcon}
      />
      <Box>
        <div style={{ float: 'left', marginRight: 20, marginTop: 14 }}>
          <GramsToOzToggleSwitch />
        </div>
        {loadingUniqueStandardsFromBalances && (
          <LoadingCircle style={{ marginLeft: 20 }} />
        )}
        {!loadingUniqueStandardsFromBalances && !displayPlatinumUI && (
          <GenericBasicSelect
            labelId="select-transactions-standard"
            id="select-transactions-standard"
            value={selectedStandardId || 'allStandards'}
            onChange={handleTransactionsStandardChange}
            items={transactionsStandardItems}
          />
        )}
        {!loadingProductTypes && displayPlatinumUI && (
          <GenericBasicSelect
            labelId="select-product-type"
            id="select-product-type"
            value={selectedProductTypeId || 'allProductTypes'}
            onChange={handleProductTypeChange}
            items={productTypeItems}
            style={{ marginRight: 20 }}
          />
        )}

        {!loadingUniqueStandardsFromBalances && displayPlatinumUI && (
          <GenericBasicSelect
            labelId="select-transactions-standard"
            id="select-transactions-standard"
            value={selectedStandardType || 'allStandards'}
            onChange={handleTransactionsStandardChange}
            items={transactionsStandardItems}
          />
        )}
      </Box>
    </div>
  );
};

export default TransactionsToolbar;
