import { useSelector } from 'react-redux';
import { TransactionsReducerStateType } from 'reducers/transactionsReducer/types';
import { RootState } from 'redux/store';

const useTransactionsReducerSlices = (): TransactionsReducerStateType => {
  //Redux Product slices
  const {
    transactions,
    aggregationId,
    allTransactions,
    loading,
    selectedStandardId,
    selectedStandardType,
    selectedProductTypeId,
    paginatedTransactionsCount,
    allTransactionsCount,
    defaultTransactionsPaginationThreshold,
    page,
    pageSize,
  }: TransactionsReducerStateType = useSelector(
    (state: RootState) => state.transactionsReducer
  );
  return {
    aggregationId,
    allTransactions,
    transactions,
    loading,
    selectedStandardId,
    selectedStandardType,
    selectedProductTypeId,
    paginatedTransactionsCount,
    allTransactionsCount,
    defaultTransactionsPaginationThreshold,
    page,
    pageSize,
  };
};

export default useTransactionsReducerSlices;
