import useLoginSlices from 'components/Balances/Redux/Slices/useLoginSlices';

import { UseProductsSlicesCombinerType } from '../CustomHooks/types';

import useProductsSlices from './useProductsSlices';

const useProductsSlicesCombiner = (): UseProductsSlicesCombinerType => {
  return {
    ...useProductsSlices(),
    ...useLoginSlices(),
  };
};

export default useProductsSlicesCombiner;
