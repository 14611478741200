import { useEffect } from 'react';

import { Dialog } from '@mui/material';
import { hideCheckProductAttributesModal, checkAttributesStart } from 'actions';
import ModalContentCheckAttributesConfirmation from 'components/Administration/Generic/Form/CustomForms/Blockchain/CheckAttributesForm/Form/Content/ModalContentCheckAttributesConfirmation';
import GenericModalTitle from 'components/Administration/Generic/Modal/Title/GenericModalTitle';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import checkAttributesIcon from '../../../../../../assets/images/icons/check-document.png';

const SummaryCheckAttributesDialog = () => {
  const dispatch: AppDispatch = useDispatch();

  const { currentProduct, openCheckProductAttributesDialog } =
    useProductSlicesCombiner();

  useEffect(() => {
    if (!currentProduct || !openCheckProductAttributesDialog) return;

    dispatch(checkAttributesStart(currentProduct));
  }, [currentProduct, openCheckProductAttributesDialog]);

  const handleClose = () => {
    dispatch(hideCheckProductAttributesModal());
  };
  const modalProps: any = {
    fullScreen: false,
    maxWidth: 'lg',
    open: openCheckProductAttributesDialog,
    'data-testid': 'checkProductAttributesDialog',
    'aria-labelledby': 'responsive-dialog-title',
    onClose: handleClose,
  };

  return (
    <Dialog {...modalProps}>
      <GenericModalTitle
        id="responsive-dialog-title"
        title={'Check product attributes'}
        icon={checkAttributesIcon}
        closeModal={handleClose}
      />
      <ModalContentCheckAttributesConfirmation />
    </Dialog>
  );
};

export default SummaryCheckAttributesDialog;
