import { useEffect, useState } from 'react';
import { Differ, DiffResult } from 'json-diff-kit';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { apiGet } from 'actions';

const useSummaryPreviewJSONModalConfig = () => {
  const { currentProduct, productHistoryInJSON } = useProductSlicesCombiner();
  const [currentJSON, setCurrentJSON] = useState<object | null>(null);
  const [previousJSON, setPreviousJSON] = useState<object | null>(null);
  const [diffResult, setDiffResult] = useState<
    [DiffResult[], DiffResult[]] | null
  >(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchJSONContent = async () => {
      if (
        !currentProduct ||
        !productHistoryInJSON ||
        productHistoryInJSON.length === 0
      ) {
        return;
      }

      setLoading(true);

      try {
        const currentRes = await apiGet(
          `/api/fields/s3/${currentProduct.productId}/${
            productHistoryInJSON[productHistoryInJSON.length - 1].hash
          }`
        );
        const currentData = await currentRes.json();

        if (currentData.success) {
          setCurrentJSON(currentData.data);
        } else {
          console.error('Error fetching current JSON:', currentData.error);
        }

        if (productHistoryInJSON.length > 1) {
          const previousRes = await apiGet(
            `/api/fields/s3/${currentProduct.productId}/${
              productHistoryInJSON[productHistoryInJSON.length - 2].hash
            }`
          );
          const previousData = await previousRes.json();

          if (previousData.success) {
            setPreviousJSON(previousData.data);
          } else {
            console.error('Error fetching previous JSON:', previousData.error);
          }
        }
      } catch (error) {
        console.error('Error fetching JSON content:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJSONContent();
  }, [currentProduct, productHistoryInJSON]);

  useEffect(() => {
    if (currentJSON && previousJSON) {
      const differ = new Differ({
        detectCircular: true,
        maxDepth: Infinity,
        showModifications: true,
        arrayDiffMethod: 'normal',
      });

      const diff = differ.diff(previousJSON, currentJSON) as [
        DiffResult[],
        DiffResult[]
      ];
      setDiffResult(diff.length > 0 ? [...diff] : null);
    }
  }, [currentJSON, previousJSON]);

  return {
    currentJSON,
    previousJSON,
    diffResult,
    loading,
  };
};

export default useSummaryPreviewJSONModalConfig;
