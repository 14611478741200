import { Dialog } from '@mui/material';
import useTransactionsCombiner from 'components/Transactions/Redux/Slices/Combiner/useTransactionsCombiner';

import TransactionsDialogContent from './Components/TransactionsDialogComponents/TransactionsDialogContent';
import TransactionsDialogFooter from './Components/TransactionsDialogComponents/TransactionsDialogFooter';
import TransactionsDialogTitle from './Components/TransactionsDialogComponents/TransactionsDialogTitle';

const TransactionsDialog = () => {
  const {
    dialogSecretWordOpen,
    currentCertificateSecretWord,
    loadingSecretWord,
  } = useTransactionsCombiner();

  return (
    <Dialog
      fullScreen={false}
      open={dialogSecretWordOpen as boolean}
      aria-labelledby="responsive-dialog-title"
    >
      <TransactionsDialogTitle loadingSecretWord={loadingSecretWord} />
      <TransactionsDialogContent
        loadingSecretWord={loadingSecretWord}
        currentCertificateSecretWord={currentCertificateSecretWord}
      />
      <TransactionsDialogFooter />
    </Dialog>
  );
};

export default TransactionsDialog;
