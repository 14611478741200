import useLoginSlices from 'components/Balances/Redux/Slices/useLoginSlices';
import useProductTypesSlices from 'components/Balances/Redux/Slices/useProductTypesSlices';
import useStandardsSlices from 'components/Balances/Redux/Slices/useStandardsSlices';
import useUtilsSlices from 'components/Balances/Redux/Slices/useUtilsSlices';

import { UseTransactionCombinerType } from '../../CustomHooks/types';
import useCertificatesReducerSlices from '../useCertificatesReducerSlices';
import useTransactionsReducerSlices from '../useTransactionsReducerSlices';

const useTransactionsCombiner = (): UseTransactionCombinerType => {
  return {
    ...useCertificatesReducerSlices(),
    ...useLoginSlices(),
    ...useTransactionsReducerSlices(),
    ...useProductTypesSlices(),
    ...useStandardsSlices(),
    ...useUtilsSlices(),
  };
};

export default useTransactionsCombiner;
