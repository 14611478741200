type Item = {
  key: string;
  value: string;
  label: string;
};

export const getProductTypeItems = (productTypes: any): Item[] => [
  { key: 'allProductTypes', value: 'allProductTypes', label: 'All metals' },
  ...productTypes.map((p: any) => ({
    key: p._id,
    value: p._id,
    label: `${p.name} ${p.shortName}`,
  })),
];

export const getTransactionsStandardItems = (
  standards: any[],
  displayPlatinumUI: boolean
): Item[] => {
  if (displayPlatinumUI) {
    return [
      { key: 'allStandards', value: 'allStandards', label: 'All standards' },
      ...standards
        .filter(
          (standard, index, array) =>
            array.findIndex(
              (std) => std.standardType === standard.standardType
            ) === index
        )
        .map((s) => ({
          key: s.standardType,
          value: s.standardType,
          label: s.standardType,
        })),
    ];
  } else {
    return [
      { key: 'allStandards', value: 'allStandards', label: 'All standards' },
      ...standards.map((s) => ({ key: s._id, value: s._id, label: s.name })),
    ];
  }
};
