import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useUploadDataFormModalConfig from '../../Configuration/useUploadDataFormModalConfig';

const ModalFooterUploadDataForm = () => {
  const { footer } = useUploadDataFormModalConfig();

  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterUploadDataForm;
