import { useState } from 'react';

import { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';

const useBlockchainDataSwitchConfig = () => {
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      status: false,
      BC_field: false,
      secret: false,
      hash: false,
      transactionLink: false,
    });

  const toggleVisibility = (visibility: boolean) => {
    setColumnVisibilityModel((prevModel) => {
      const newModel = { ...prevModel };
      Object.keys(newModel).forEach((key) => {
        newModel[key] = visibility;
      });
      return newModel;
    });
  };

  return {
    columnVisibilityModel,
    toggleVisibility,
  };
};

export default useBlockchainDataSwitchConfig;
