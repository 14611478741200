import { useEffect, useState } from 'react';

import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import { getQuantityForProduct } from '../../../../../../../../actions';
import { quantityTypeUppercase } from '../constants';

const useSetupFormikFields = (values: any) => {
  const dispatch: AppDispatch = useDispatch();

  const [unitInForm, setUnitInForm] = useState<string>('kWh');
  const [period, setPeriod] = useState<string>('month');
  const [typesOfDataToUpload, setTypesOfDataToUpload] = useState<any[]>([]);
  const [quantityInputLabel, setQuantityInputLabel] = useState<string>('');
  const [displayTypeOfDataSelector, setDisplayTypeOfDataSelector] =
    useState<boolean>(false);
  const [selectedOrigin, setSelectedOrigin] = useState<any>(null);
  const { products, loadingDataset } = useAdministrationSlicesCombiner();
  const [
    quantityForIncrementationInputLabel,
    setQuantityForIncrementationInputLabel,
  ] = useState<string>('');
  const [productVolume, setProductVolume] = useState<number>(0);
  const [productAlreadyIssuedVolume, setProductAlreadyIssuedVolume] =
    useState<number>(0);
  const [withOverwritePreviousValue, setWithOverwritePreviousValue] =
    useState<boolean>(values?.overwritePreviousValue);

  useEffect(() => {
    const selectedOrigin = (products as any[]).find(
      (product) => product._id === values.origin
    );
    setSelectedOrigin(selectedOrigin);

    if (selectedOrigin?.unit) setUnitInForm(selectedOrigin.unit);
    if (selectedOrigin?.aggregationPeriodType)
      setPeriod(selectedOrigin.aggregationPeriodType);

    const initialTypesOfDataToUploadLabel = `${quantityTypeUppercase} (${unitInForm})`;
    let quantityInputLabel = initialTypesOfDataToUploadLabel;
    if (productAlreadyIssuedVolume > 0) {
      quantityInputLabel = `${quantityTypeUppercase} (${productAlreadyIssuedVolume} ${unitInForm} already issued)`;
    } else if (productVolume > 0) {
      quantityInputLabel = `${quantityTypeUppercase} (${productVolume} ${unitInForm} already uploaded but not issued )`;
    }

    const initialTypesOfDataToUpload = [
      {
        label: initialTypesOfDataToUploadLabel,
        value: 'quantity',
      },
    ];
    setQuantityForIncrementationInputLabel(
      `Volume to add to previously uploaded volume (⚠️ ${productVolume} ${unitInForm} already uploaded)`
    );

    const dynamicData = selectedOrigin?.standard?.infoTexts
      ?.filter((infoX: any) => infoX.type === 'dynamicData')
      ?.map((infoX: any) => ({
        label: `${infoX.infoStandardText} (${infoX.unit})`,
        value: infoX.sourceName,
      }));

    let newTypesOfDataToUpload = initialTypesOfDataToUpload;
    if (dynamicData?.length) {
      newTypesOfDataToUpload = [...initialTypesOfDataToUpload, ...dynamicData];
      setDisplayTypeOfDataSelector(true);
    } else {
      setDisplayTypeOfDataSelector(false);
    }
    setTypesOfDataToUpload(newTypesOfDataToUpload);

    const currentTypeData = newTypesOfDataToUpload.find(
      (data: any) => data.value === values.type
    );
    if (currentTypeData) {
      if (withOverwritePreviousValue) setQuantityInputLabel(quantityInputLabel);
      else setQuantityInputLabel(currentTypeData.label);
    }

    setWithOverwritePreviousValue(values.overwritePreviousValue);
  }, [
    values.origin,
    values.month,
    values.year,
    values.overwritePreviousValue,
    values.type,
    products,
    unitInForm,
    productVolume,
    productAlreadyIssuedVolume,
    withOverwritePreviousValue,
  ]);

  useEffect(() => {
    const setUp = async () => {
      const selectedProduct = (products as any[]).find(
        (p) => p._id === values.origin
      );

      const _canFetchVolumeData =
        loadingDataset === false &&
        values.month &&
        values.year &&
        values.origin &&
        values.type === 'quantity' &&
        selectedProduct?.source;
      let _productVolume = 0;
      let _productAlreadyIssuedVolume = 0;
      if (_canFetchVolumeData) {
        const availableVolume = await getQuantityForProduct(
          values.origin,
          values.year,
          values.month
        );
        _productVolume = availableVolume.production;
        _productAlreadyIssuedVolume = availableVolume.issuedQuantity;
      }
      setProductVolume(_productVolume);
      dispatch({
        type: 'SET_ALREADY_UPLOADED_VOLUME',
        payload: _productVolume,
      });
      setProductAlreadyIssuedVolume(_productAlreadyIssuedVolume);
      dispatch({
        type: 'SET_MINIMUM_VOLUME_TO_UPLOAD',
        payload: _productAlreadyIssuedVolume,
      });
    };
    setUp();
  }, [
    values.origin,
    values.overwritePreviousValue,
    values.type,
    values.month,
    values.year,
    loadingDataset,
    products,
  ]);

  return {
    selectedOrigin,
    unitInForm,
    period,
    typesOfDataToUpload,
    quantityInputLabel,
    quantityForIncrementationInputLabel,
    displayTypeOfDataSelector,
    productVolume,
    withOverwritePreviousValue,
  };
};

export default useSetupFormikFields;
