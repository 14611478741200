import { FormikValues } from 'formik';

export const getValidationSchema = (values: FormikValues) => {
  const errors: any = {};
  if (!values.organization) {
    errors.organization = 'Required';
  }
  const numberRegEx = /^\d*$/;

  if (!values.year) {
    errors.year = 'Required';
  } else if (!numberRegEx.test(String(values.year).toLowerCase())) {
    errors.year = 'Invalid number';
  } else if (String(values.year).length != 4) {
    errors.year = 'Invalid year !';
  }
  if (!values.month) {
    errors.month = 'Required';
  } else if (!numberRegEx.test(String(values.month).toLowerCase())) {
    errors.month = 'Invalid number';
  } else if (String(values.month).length > 2) {
    errors.month = 'Invalid month format ! Must be MM';
  } else if (parseInt(values.month) < 1 || parseInt(values.month, 10) > 12) {
    errors.month = 'Invalid month ! Must be betweend [1, 12]';
  }
  return errors;
};
