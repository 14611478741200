import { useSelector } from 'react-redux';
import { ProductsReducerStateType } from 'reducers/productsReducer/types';
import { RootState } from 'redux/store';

const useProductSlices = (): ProductsReducerStateType => {
  //Redux Product slices
  const {
    loadingProductAggregations,
    loading,
    graphDataProducer,
    currentProduct,
    productAggregations,
    assetsMeteringToDisplayInDialog,
    openAggregationDetails,
    localProductReferenceFiles,
    deletingFileIds,
    openConfirmReferenceFileDeletion,
    referenceFileToDelete,
    referenceDocuments,
    uploadingFile,
    openUploadReferenceFileModal,
    totalCountAggregations,
    openAggregationTransactionsDialog,
    openCheckProductAttributesDialog,
    productHistoryInJSON,
    openJSONDiffCheckProductDialog,
  }: ProductsReducerStateType = useSelector(
    (state: RootState) => state.productsReducer
  );
  return {
    loadingProductAggregations,
    loading,
    graphDataProducer,
    currentProduct,
    productAggregations,
    assetsMeteringToDisplayInDialog,
    openAggregationDetails,
    localProductReferenceFiles,
    deletingFileIds,
    openConfirmReferenceFileDeletion,
    referenceFileToDelete,
    referenceDocuments,
    uploadingFile,
    openUploadReferenceFileModal,
    totalCountAggregations,
    openAggregationTransactionsDialog,
    openCheckProductAttributesDialog,
    productHistoryInJSON,
    openJSONDiffCheckProductDialog,
  };
};

export default useProductSlices;
