import { useEffect, useState } from 'react';

import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';

export const useSourceDocuments = () => {
  const {
    localSourceDocument,
    localConfirmationDataset,
    localConfirmationSourceDocument,
  } = useAdministrationSlicesCombiner();
  const [selectedSourceDocument, setSelectedSourceDocument] =
    useState<any>(null);
  const [confirmationSelectedDataset, setConfirmationSelectedDataset] =
    useState<any>(null);
  const [
    confirmationSelectedSourceDocument,
    setConfirmationSelectedSourceDocument,
  ] = useState<any>(null);

  useEffect(() => {
    if (localSourceDocument)
      setSelectedSourceDocument(URL.createObjectURL(localSourceDocument));
    else setSelectedSourceDocument(null);

    if (localConfirmationSourceDocument)
      setConfirmationSelectedSourceDocument(
        URL.createObjectURL(localConfirmationSourceDocument)
      );
    else setConfirmationSelectedSourceDocument(null);

    if (localConfirmationDataset)
      setConfirmationSelectedDataset(
        URL.createObjectURL(localConfirmationDataset)
      );
    else setConfirmationSelectedDataset(null);
  }, [
    localSourceDocument,
    localConfirmationDataset,
    localConfirmationSourceDocument,
  ]);

  return {
    selectedSourceDocument,
    confirmationSelectedDataset,
    confirmationSelectedSourceDocument,
  };
};
