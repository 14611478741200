import { FC } from 'react';

import { ClassNames } from '@emotion/react';
import { Button, CircularProgress } from '@mui/material';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import { displayDateFilter, setCustomButtonPressed } from '../../../../actions';
import useBalancesChildComponentsSlices from '../../Redux/CustomHooks/useBalancesChildComponentsSlices';

const CustomButton: FC = () => {
  //Redux
  const dispatch: AppDispatch = useDispatch();

  const { loading, customButtonPressed }: UseBalancesChildComponentsSlicesType =
    useBalancesChildComponentsSlices();

  const handleCustomBtnOnClick = (): void => {
    dispatch(setCustomButtonPressed());
    dispatch(displayDateFilter());
  };

  return (
    <div style={{ marginLeft: 10, float: 'left' }}>
      <ClassNames>
        {() => (
          <Button
            color="primary"
            variant={customButtonPressed ? 'contained' : 'text'}
            disabled={loading}
            onClick={handleCustomBtnOnClick}
          >
            {loading ? <CircularProgress size={24} /> : 'Custom'}
          </Button>
        )}
      </ClassNames>
    </div>
  );
};
export default CustomButton;
