import { Button, DialogActions } from '@mui/material';
import {
  setAssetsMeteringToDisplayInDialog,
  setOpenAggregationDetails,
} from 'actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const AssetsMeteringActions = () => {
  const dispatch: AppDispatch = useDispatch();
  return (
    <DialogActions>
      <Button
        onClick={() => {
          dispatch(setOpenAggregationDetails(false));
          dispatch(setAssetsMeteringToDisplayInDialog([]));
        }}
        color="primary"
      >
        Close
      </Button>
    </DialogActions>
  );
};

export default AssetsMeteringActions;
