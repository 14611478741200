import React from 'react';

import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useCheckAttributesFormModalConfig from '../../Configuration/Configuration/useCheckAttributesFormModalConfig';

const ModalFooterCheckAttributesForm = () => {
  const { footer } = useCheckAttributesFormModalConfig();
  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterCheckAttributesForm;
