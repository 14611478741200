import { Grid, Paper } from '@mui/material';
import AggregationTable from 'components/Product/CustomComponents/Tables/Display/AggregationTable/AggregationTable';
import AggregationTransactionsDialog from 'components/Product/CustomComponents/Tables/Display/AggregationTable/Dialog/AggregationTransactionsDialog';
import AssetsMeteringDialog from 'components/Product/CustomComponents/Tables/Display/AggregationTable/Dialog/AssetsMeteringDialog';
import AggregationToolbar from 'components/Product/CustomComponents/Toolbar/AggregationToolbar/AggregationToolbar';

const AggregationContent = () => {
  return (
    <Grid item xs={12}>
      <Paper style={{ marginTop: 0 }}>
        <div style={{ paddingBottom: 63, paddingLeft: 20, paddingRight: 20 }}>
          <AggregationToolbar />
          <div style={{ height: 600 }}>
            <AggregationTable />
          </div>
        </div>
        <AssetsMeteringDialog />
        <AggregationTransactionsDialog />
      </Paper>
    </Grid>
  );
};

export default AggregationContent;
