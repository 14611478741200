import { useEffect, useState } from 'react';

import {
  getOffchainProdSources,
  getOrganizations,
  getProductFormStart,
  getProductTypesStart,
  getStandardsStart,
  getWellsStart,
  resetCurrentProduct,
  getDynamicListsStart,
} from 'actions';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { AppDispatch } from 'redux/store';
import { checkObjectIsArray } from 'utils/general/utils';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';
import { v4 as uuidv4 } from 'uuid';

import useCreateOrUpdateProductSlicesCombiner from '../Slices/useCreateOrUpdateProductSlicesCombiner';

import { UseCreateOrUpdateProductType } from './types';

const useCreateOrUpdateProduct = (): UseCreateOrUpdateProductType => {
  const [sourceHistory, setSourceHistory] = useState<any[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { assetId } = useParams();

  const { isAuthenticated, user } = useCreateOrUpdateProductSlicesCombiner();

  useEffect(() => {
    const initComponent = async () => {
      if (
        !(
          checkUserIsSuperAdmin(isAuthenticated, user) ||
          checkUserHasRight(isAuthenticated, user, RightReferences.PRODUCTS, [
            CrudPermissions.UPDATE,
          ])
        )
      )
        navigate('/');

      dispatch(getOffchainProdSources('Production'));
      dispatch(getOrganizations());
      dispatch(getStandardsStart());
      dispatch(getProductTypesStart());
      dispatch(getDynamicListsStart());
      dispatch(getWellsStart());

      if (assetId) {
        const currentProductForm: any = await dispatch(
          getProductFormStart(assetId)
        );
        const sourceHistoryWithIds =
          currentProductForm &&
          checkObjectIsArray(currentProductForm.sourceHistory) &&
          [...currentProductForm.sourceHistory].map((s) => {
            s.id = uuidv4();
            return s;
          });
        setSourceHistory(sourceHistoryWithIds);
      } else {
        await dispatch(resetCurrentProduct());
      }
    };
    initComponent();
  }, [assetId, dispatch, isAuthenticated, navigate, user]);

  const addSourceHistoryItem = (values: any) => {
    const updatedSourceHistory = [...sourceHistory];
    updatedSourceHistory.push({
      source: values.source,
      sourceID: values.sourceID,
      startDate: values.startDate,
      periodNumber: values.periodNumber,
      periodType: values.periodType,
      aggregationPeriodNumber: values.aggregationPeriodNumber,
      aggregationPeriodType: values.aggregationPeriodType,
      id: uuidv4(),
    });

    setSourceHistory(updatedSourceHistory);
  };

  const removeSourceHistoryItem = (item: any) => {
    const index = sourceHistory.findIndex((s) => s.id === item.id);
    const updatedSourceHistory = [...sourceHistory];
    updatedSourceHistory.splice(index, 1);
    setSourceHistory(updatedSourceHistory);
  };

  return { sourceHistory, addSourceHistoryItem, removeSourceHistoryItem };
};

export default useCreateOrUpdateProduct;
