export const FILTER_MODE_CLIENT_SIDE = 'client';
export const PAGINATION_MODE_SERVER_SIDE = 'server';
export const PAGINATION_MODE_CLIENT_SIDE = 'client';
export const ALL_TABLE_ROWS_OPTION = 'All';
export const DEFAULT_PAGE_SIZE = 100;
export const DEFAULT_FILTER_MODEL = null;
export const DEFAULT_PAGE_NUMBER = 0;
export const getRowsPerPageOptions = (totalNbOfRows: number) => [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  {
    value: totalNbOfRows,
    label: `${ALL_TABLE_ROWS_OPTION} (filter on all results)`,
  },
];
