export const getSecretWords = (currentCertificateSecretWord: any) => {
  return [
    {
      id: 'secret-word-issuer',
      value: currentCertificateSecretWord.issuerSecretWord,
      label: 'Issuer',
      message: 'Issuer copied to clipboard !',
    },
    {
      id: 'secret-word-from',
      value: currentCertificateSecretWord.fromSecretWord,
      label: 'From',
      message: 'From copied to clipboard !',
    },
    {
      id: 'secret-word-client',
      value: currentCertificateSecretWord.clientSecretWord,
      label: 'Client',
      message: 'Client copied to clipboard !',
    },
    {
      id: 'secret-word-origin',
      value: currentCertificateSecretWord.origin,
      label: 'Product Id',
      message: 'Product Id copied to clipboard !',
    },
  ];
};
