import React from 'react';

export type DataAcquisitionContextType = {
  addSourceHistoryItem: (values: any) => void;
  removeSourceHistoryItem: (value: any) => void;
  sourceHistory: any[];
};

const dataAcquisitonContext: DataAcquisitionContextType = {
  addSourceHistoryItem: () => null,
  removeSourceHistoryItem: () => null,
  sourceHistory: [],
};

const DataAcquisitionContext = React.createContext(dataAcquisitonContext);

export default DataAcquisitionContext;
