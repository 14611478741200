/* eslint-disable react/no-unknown-property */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CircularProgress, DialogTitle } from '@mui/material';
import Typography from '@mui/material/Typography';
import { closeActionDialog } from 'actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import closeIcon from '../../../../../assets/images/icons/close.png';
import productIcon from '../../../../../assets/images/icons/product.png';

type GenericModalTitlePropsType = {
  title: string;
  id?: string;
  icon?: any;
  loading?: boolean;
  closeModal?: () => void;
};

const GenericModalTitle = ({
  title = 'Modal title',
  id = 'id',
  icon = productIcon,
  loading = false,
  closeModal,
}: GenericModalTitlePropsType) => {
  const dispatch: AppDispatch = useDispatch();
  return (
    <DialogTitle id={id}>
      <div css={containerStyle}>
        <div css={iconAndTitleContainerStyle}>
          <img src={icon} css={deleteImgStyle} alt="" />
          <Typography variant="body2" display="block" style={{ fontSize: 20 }}>
            {title}
          </Typography>
        </div>
        <img
          data-testid="close-product-dialog"
          src={closeIcon}
          css={closeIconStyle}
          alt="Close"
          onClick={() => {
            if (closeModal) closeModal();
            else dispatch(closeActionDialog());
          }}
        />
        {loading && <CircularProgress size={16} style={{ marginLeft: 10 }} />}
      </div>
    </DialogTitle>
  );
};

const containerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const iconAndTitleContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'flex-start',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 20,
});

const deleteImgStyle = css({
  height: 22,
  width: 22,
  marginRight: 5,
  paddingLeft: 10,
  paddingRight: 10,
});

const closeIconStyle = css({
  height: 24,
  width: 24,
  marginRight: -10,
  cursor: 'pointer',
});

export default GenericModalTitle;
