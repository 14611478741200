/* eslint-disable */

import { AnyAction } from 'redux';
import { OperationsReducerStateType } from './types/types';

const INITIAL_STATE : OperationsReducerStateType = { 
  operations: [],
  allOperations: [],
  loading: false,
  totalCount: 0,  

};

const operationsReducer = (state = INITIAL_STATE, action: AnyAction)  => {

switch(action.type) {
  case 'GET_OPERATIONS_START':
    return { ...state, loading: true };

  case 'GET_OPERATIONS_SUCCESS':
    return {
      ...state,
      operations: action.payload,
      allOperations: action.payload,
      loading: false,
    };

    case 'GET_OPERATIONS_TOTAL_COUNT_SUCCESS':
      return {
        ...state,
        totalCount: action.payload,
        loading: false,
      };

  case 'GET_OPERATIONS_FAIL':
    return { ...state, loading: false };
    
  default:
    return state;
}
};

export default operationsReducer;
