import { Dialog } from '@mui/material';
import { hideAggregationTransactionsModal } from 'actions';
import ModalTitle from 'components/commons/ModalTitle/ModalTitle';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import eventsIcon from '../../../../../../../assets/images/icons/events.png';

import AggregationTransactionsActions from './Actions/AggregationTransactionsActions';
import AggregationTransactionsContent from './Content/AggregationTransactionsContent';

const AggregationTransactionsDialog = () => {
  const { openAggregationTransactionsDialog } = useProductSlicesCombiner();
  const dispatch: AppDispatch = useDispatch();
  const handleClose = () => {
    dispatch(hideAggregationTransactionsModal());
  };
  const modalProps: any = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'lg',
    disableEnforceFocus: false,
    open: openAggregationTransactionsDialog,
    'aria-labelledby': 'responsive-dialog-title',
    onClose: handleClose,
  };

  return (
    <Dialog {...modalProps}>
      <ModalTitle
        id="responsive-dialog-title"
        title="Related transactions"
        handleClose={handleClose}
        icon={eventsIcon}
      />
      <AggregationTransactionsContent />
      <AggregationTransactionsActions />
    </Dialog>
  );
};

export default AggregationTransactionsDialog;
