import { Collapse, FormControl, FormLabel, Grid } from '@mui/material';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Right } from 'types/Right';
import { RightsListElement } from 'types/RightsListElement';

import { RightsCollapsedSectionTitle } from './RightsCollapsedSectionTitle';
import { RightsFormGroup } from './RightsFormGroup';
import { RightsSectionPropsType } from './types';

export const RightsSectionWithLabels = ({
  category,
  expanded,
  capitalizeFirstLetter,
  handleExpandClick,
  checkIfConnectedUserHasRight,
}: RightsSectionPropsType): JSX.Element => {
  const allUserRights = useSelector(
    (state: RootState) => state.usersReducer.rights
  );
  const selectedUserRights = useSelector(
    (state: RootState) => state.usersReducer.selectedRights
  );

  return (
    <Grid container id={category}>
      <Grid container>
        <Grid item xs={2}>
          <RightsCollapsedSectionTitle
            category={category}
            expanded={expanded}
            capitalizeFirstLetter={capitalizeFirstLetter}
            handleExpandClick={handleExpandClick}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={0.5} />
        <Grid item xs={11.5}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              {allUserRights &&
                allUserRights
                  .filter((right: Right) => right.category === category)
                  .map((right: Right, rightIndex: number) => {
                    return (
                      <div key={rightIndex}>
                        <FormLabel component="legend">
                          {capitalizeFirstLetter(
                            right.ref === RightReferences.TRANSACTIONS
                              ? 'audit trail'
                              : right.ref
                          )}
                        </FormLabel>
                        <RightsFormGroup
                          data={right?.permissions}
                          associatedRight={right}
                          capitalizeFirstLetter={capitalizeFirstLetter}
                          verifyIfChecked={(element: CrudPermissions) => {
                            return Boolean(
                              selectedUserRights &&
                                selectedUserRights.find(
                                  (r: RightsListElement) => {
                                    return (
                                      r?.type &&
                                      r?.activePermissions &&
                                      r.type._id === right._id &&
                                      r.activePermissions.indexOf(element) > -1
                                    );
                                  }
                                )
                            );
                          }}
                          verifyIfCurrentUserHasRight={(
                            element: CrudPermissions
                          ) => {
                            return checkIfConnectedUserHasRight(right, element);
                          }}
                        />
                      </div>
                    );
                  })}
            </FormControl>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );
};
