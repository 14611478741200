import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useIssuanceEmailNotificationConfirmationModalConfig from '../../Configuration/useIssuanceEmailNotificationConfirmationModalConfig';

const ModalFooterIssuanceEmailNotificationConfirmation = () => {
  const { footer } = useIssuanceEmailNotificationConfirmationModalConfig();
  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterIssuanceEmailNotificationConfirmation;
