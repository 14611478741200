import { useState } from 'react';

import { useTheme } from '@emotion/react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { create } from 'zustand';

import myPDF from './terms_of_use.pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const userTermsOfUseDialogStore = create((set) => ({
  onSubmit: undefined,
  close: () => set({ onSubmit: undefined }),
}));

const TermsOfUseDialog = () => {
  // destructure the store data and functions
  const { onSubmit, close } = userTermsOfUseDialogStore();
  const classes = useTheme();

  const [numPages, setNumPages] = useState(null);
  const setDocumentNumberOfPages = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    // if the onSubmit is undefined the dialog will be closed.
    // close() function sets the onSubmit to undefined,
    // so it will close the dialog, if we pass it to the onClose attribute.
    <Dialog
      open={Boolean(onSubmit)}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          close();
        }
      }}
      maxWidth="md"
      scroll={'paper'}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle>
        You must agree to the terms and conditions before continuing:{' '}
      </DialogTitle>
      <DialogContent>
        <Typography>
          <Document
            file={myPDF}
            onLoadError={(error) =>
              alert('Error while loading document! ' + error.message)
            }
            onLoadSuccess={setDocumentNumberOfPages}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (onSubmit) {
              onSubmit();
            }
            close();
          }}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const termsOfUseDialog = (onSubmit) => {
  userTermsOfUseDialogStore.setState({
    onSubmit,
  });
};

export default TermsOfUseDialog;
