import { enums } from '../enums';

export let blockchainExplorerUrl;
switch (process.env.REACT_APP_NODE_ENV) {
  case enums.EnvironmentTypes.PRODUCTION:
    blockchainExplorerUrl = enums.BlockchainExplorerUrl.EWC;
    break;
  case enums.EnvironmentTypes.STAGING:
    blockchainExplorerUrl = enums.BlockchainExplorerUrl.EWC;
    break;
  case enums.EnvironmentTypes.DEVELOPMENT:
    blockchainExplorerUrl = enums.BlockchainExplorerUrl.EWC;
    break;
  default:
    blockchainExplorerUrl = enums.BlockchainExplorerUrl.EWC;
}
