import { getStandardTypes } from 'components/FormStandards/utils/utils';
import GenericField from 'components/GenericComponents/Grid/Field/Field/GenericField';

const StandardTypeField = () => {
  return (
    <GenericField
      name="standardType"
      label="Retirement certificate PDF template"
      select
      items={getStandardTypes()}
      errorKey="standardType"
    />
  );
};

export default StandardTypeField;
