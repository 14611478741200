import React from 'react';

import { useTheme } from '@emotion/react';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 550,
    },
  },
};

function getStyles(info, selectedInfo, theme) {
  return {
    fontWeight:
      selectedInfo.indexOf(info) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ProductInfoMultipleSelect({
  infoArray,
  initialInfos,
  label,
  onChange,
  id,
}) {
  const classes = useTheme();

  //Remove undefined and null items
  if (Array.isArray(infoArray)) infoArray = infoArray.filter((item) => item);
  //Remove from initialInfos items that are not in the predefined list
  let defaultInfos =
    initialInfos && Array.isArray(initialInfos) ? initialInfos : [];

  const [selectedInfo, setSelectedInfo] = React.useState(defaultInfos);

  const handleChange = (event) => {
    let selectedInfos = event.target.value;
    setSelectedInfo(selectedInfos);
    onChange(event);
  };

  if (!infoArray || infoArray.length < 1) return <></>;
  return (
    <FormControl
      style={{
        ...classes.formControl,
        width: 300,
        marginLeft: 0,
      }}
    >
      <InputLabel
        id={`input-label-${id}`}
        style={{ backgroundColor: '#FFFFFF' }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={id}
        id={`multi-select-${id}`}
        multiple
        value={selectedInfo}
        onChange={handleChange}
        input={<Input id={id} />}
        renderValue={(selected) => (
          <div style={classes.chips}>
            {selected.map((value) => {
              let info = infoArray.find((info) => info === value);
              return <Chip key={value} label={info} style={classes.chip} />;
            })}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {infoArray.map((info) => (
          <MenuItem
            key={info}
            value={info}
            style={getStyles(info, selectedInfo, classes)}
          >
            {info}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
