import { FC, MutableRefObject, useContext, useRef } from 'react';

import { Dialog } from '@mui/material';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import ModalTitle from 'components/commons/ModalTitle/ModalTitle';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppDispatch } from 'redux/store';

import {
  allocateCertificatesStart,
  closeAllocateFormDialog,
} from '../../../../../actions';
import depositIcon from '../../../../../assets/images/icons/deposit.png';
import { checkUserIsSuperAdmin } from '../../../../../utils/user/checkAdmin/CheckAdmin';
import useBalancesChildComponentsSlices from '../../../Redux/CustomHooks/useBalancesChildComponentsSlices';
import RetireForm from '../../Forms/RetireForm/RetireForm';
import OrganizationsContext, {
  OrganizationContextType,
} from '../CombinedDialogs/OrganizationsContext';
import NextTransferOrAllocateConfirmButtonActions from '../Generic/FormConfirmation/NextTransferOrAllocateConfirmButtonActions';
import NextTransferOrAllocateConfirmFormContent from '../Generic/FormConfirmation/NextTransferOrAllocateConfirmFormContent';

import { EnumOperationTransferOrRetire } from './enums';

const RetireDialog: FC = () => {
  const { t }: UseTranslationResponse<'translation', any> =
    useTranslation<'translation'>();
  const formAllocateCertificateRef: MutableRefObject<any> = useRef<any>();

  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  const { organizationsInAllocateMenu }: OrganizationContextType =
    useContext(OrganizationsContext);
  const {
    allocateCertificatesFormOpen,
    allocateCertificatesData,
    allBalances,
    displayAllocateCertificateFormInDialog,
    user,
    isAuthenticated,
    organizationsInAllocateMenuFiltredForSuperAdmin,
  }: UseBalancesChildComponentsSlicesType = useBalancesChildComponentsSlices();

  const onSubmitHandler = (): void => {
    let _toOrganization = null;
    if (allocateCertificatesData._to) {
      let organizationsAllocateList = [...organizationsInAllocateMenu];
      if (checkUserIsSuperAdmin(isAuthenticated, user))
        organizationsAllocateList = [
          ...organizationsInAllocateMenuFiltredForSuperAdmin,
        ];
      organizationsAllocateList.push(user?.user_metadata?.organization);
      _toOrganization = organizationsAllocateList.find(
        (organization) => organization._id === allocateCertificatesData._to
      );
    }
    if (!_toOrganization) {
      alert('Error with _to organization');
    } else {
      dispatch(
        allocateCertificatesStart(
          allocateCertificatesData._origin,
          allocateCertificatesData._year,
          allocateCertificatesData._month,
          allocateCertificatesData._from,
          _toOrganization,
          parseInt(allocateCertificatesData._volume.toString()),
          allocateCertificatesData._showRetailerOnPdfCertificate as boolean,
          allBalances
        )
      );
    }
  };
  const onErrorHandler = (errors: any[]): void => console.log(errors);

  const handleClose = () => {
    dispatch(closeAllocateFormDialog());
  };

  return (
    <Dialog
      data-testid="retire-dialog-id"
      fullScreen={false}
      fullWidth={true}
      maxWidth="sm"
      disableEnforceFocus
      open={allocateCertificatesFormOpen}
      aria-labelledby="responsive-dialog-title"
      onClose={handleClose}
    >
      <ValidatorForm
        ref={formAllocateCertificateRef}
        onSubmit={onSubmitHandler}
        onError={onErrorHandler}
      >
        <ModalTitle
          id="responsive-dialog-title"
          title={t('Retire certificates')}
          handleClose={handleClose}
          icon={depositIcon}
        />

        {(location?.state?.displayAllocatePopUp ||
          displayAllocateCertificateFormInDialog) && ( // keep popup open if location state is available
          <RetireForm />
        )}
        {!displayAllocateCertificateFormInDialog && (
          <div>
            <NextTransferOrAllocateConfirmFormContent
              operationName={EnumOperationTransferOrRetire.RETIRE}
            />
            <NextTransferOrAllocateConfirmButtonActions
              operationName={EnumOperationTransferOrRetire.RETIRE}
            />
          </div>
        )}
      </ValidatorForm>
    </Dialog>
  );
};

export default RetireDialog;
