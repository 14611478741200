import { FC } from 'react';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import useBalancesChildComponentsSlices from 'components/Balances/Redux/CustomHooks/useBalancesChildComponentsSlices';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import { setOzOrGramsQuantityUnit } from '../../actions';
import { Units } from '../../enums/Units';

const GramsToOzToggleSwitch: FC = () => {
  const { ozOrGramsQuantityUnit }: UseBalancesChildComponentsSlicesType =
    useBalancesChildComponentsSlices();
  const dispatch: AppDispatch = useDispatch();

  if (process.env.REACT_APP_PRODUCT_CATEGORY !== 'platinum') return <></>;

  return (
    <div style={{ float: 'right' }} data-testid="grams-to-oz-toggle-switch-id">
      <ButtonGroup size="small" aria-label="small button group">
        <Button
          key="oz"
          onClick={() => dispatch(setOzOrGramsQuantityUnit('oz'))}
          variant={
            ozOrGramsQuantityUnit === Units.GRAMS ? 'outlined' : 'contained'
          }
        >
          oz
        </Button>
        <Button
          key="grams"
          onClick={() => dispatch(setOzOrGramsQuantityUnit(Units.GRAMS))}
          variant={
            ozOrGramsQuantityUnit === Units.GRAMS ? 'contained' : 'outlined'
          }
        >
          g
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default GramsToOzToggleSwitch;
