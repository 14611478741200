export enum RightReferences {
  USERS = 'users',
  ORGANIZATIONS = 'organizations',
  NOMINATIONS = 'contracted volumes',
  PRODUCTS = 'products',
  BALANCES = 'balances',
  CERTIFICATES = 'certificates',
  ISSUE = 'issue',
  TRANSFER = 'transfer',
  RETIRE = 'retire',
  MULTIPLE_OPERATIONS = 'multiple operations',
  DATA_UPLOAD = 'data upload',
  ADMINISTRATION = 'administration',
  TEST = 'test',
  CANCEL = 'cancel',
  TRANSACTIONS = 'transactions',
  MONITORING = 'monitoring',
  REPORTS = 'reports',
}
