import { useEffect, useState } from 'react';

import { getFullOrigin } from 'components/Administration/Generic/Form/utils/utils';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { FormikValues, useFormikContext } from 'formik';

const useUnitInForm = () => {
  const { values } = useFormikContext<FormikValues>();
  const [unitInForm, setUnitInForm] = useState<string>('kWh');
  const { products } = useAdministrationSlicesCombiner();

  useEffect(() => {
    const selectedOrigin = getFullOrigin(values?.origin, products as any[]);
    selectedOrigin && setUnitInForm(selectedOrigin?.unit);
  }, [values?.origin, products]);

  return { unitInForm };
};

export default useUnitInForm;
