import ArchiveAssetsButton from '../Buttons/ArchiveAssetsButton';
import ProductsTable from '../Table/ProductsTable';
import ProductsToolbar from '../Toolbar/ProductsToolbar';
import './Styles/index.css';

const ProductsCard = (): JSX.Element => {
  return (
    <>
      <div className="table">
        <ProductsToolbar />
        <ProductsTable />
      </div>
      <ArchiveAssetsButton />
    </>
  );
};

export default ProductsCard;
