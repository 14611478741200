import { Button, DialogActions } from '@mui/material';
const UploadActions = (props: any) => {
  return (
    <DialogActions>
      <Button onClick={props.handleClose} color="primary">
        Close
      </Button>
    </DialogActions>
  );
};

export default UploadActions;
