const fetchStaticData = async (filePath) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/static/${filePath}?v=${Date.now()}`,
      {
        method: 'GET',
        credentials: 'include',
      }
    );

    if (!response.ok) {
      throw new Error(`Network response was not ok for ${filePath}`);
    }

    const imageBlob = await response.blob();
    return URL.createObjectURL(imageBlob);
  } catch (error) {
    console.error(`Error fetching static data from ${filePath}:`, error);
    return null;
  }
};

export default fetchStaticData;
