import { GridColDef } from '@mui/x-data-grid-pro';

const getDynamicListsColumns = () => {
  const dataGridColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      width: 200,
    },
    {
      field: 'description',
      headerName: 'Description',
      editable: false,
      width: 200,
    },
  ];
  return dataGridColumns;
};

export default getDynamicListsColumns;
