/* eslint-disable */

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Formik, Field } from 'formik';
import { fieldToTextField } from 'formik-mui';

import { connect, useDispatch } from 'react-redux';

import Card from '@mui/material/Card';
import { CardHeader, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { withRouter } from '../../utils/migrationHelper';
import userIcon from '../../assets/images/icons/person.png';
import MuiTextField from '@mui/material/TextField';
import { withTranslation } from 'react-i18next';
import { withCookies } from 'react-cookie';
import FieldTooltip from '../commons/FieldTooltip/FieldTooltip';
import FieldError from '../commons/FieldError/FieldError';
import BackButton from '../commons/BackButton/BackButton';
import DynamicListItems from '../DynamicListItems/DynamicListItems';
import useDynamicListsSlicesCombiner from './Redux/Slices/useDynamicListsSlicesCombiner';

import {
  createDynamicListStart,
  deleteDynamicListStart,
  setDynamicListStart,
  getDynamicListStart,
  resetCurrentDynamicList,
  dynamicListFormIsBeingFilledOut,
  onDynamicListFieldChange,
} from '../../actions';
import { checkUserIsSuperAdmin } from '../../utils/user/checkAdmin/CheckAdmin';

/**
 * Common component import
 */
import LoadingCircle from '../commons/LoadingCircle';

function SetTextField(props) {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const dispatch = useDispatch();

  const { formIsBeingFilledOutHandler, ...fieldProps } = props;

  const onChange = React.useCallback(
    (event) => {
      setFieldValue(name, event.target.value);
      dispatch(onDynamicListFieldChange(name, event.target.value));
      formIsBeingFilledOutHandler();
    },
    [setFieldValue, name]
  );

  return <MuiTextField {...fieldToTextField(fieldProps)} onChange={onChange} />;
}

let DynamicListForm = (props) => {
  let navigate = useNavigate();
  let params = props.router?.params;

  const { localDynamicListItems } = useDynamicListsSlicesCombiner();

  useEffect(() => {
    async function initComponent() {
      const { isAuthenticated, user } = props;

      if (checkUserIsSuperAdmin(isAuthenticated, user)) {
        if (params.dynamicListId)
          await props.getDynamicListStart(params.dynamicListId);
        else props.resetCurrentDynamicList();
      } else {
        navigate('/');
      }
    }
    initComponent();
  }, []);

  const {
    currentDynamicList,
    loadingDynamicList,
    user,
    isAuthenticated,
    t,
    loadingStandards,
    dynamicListFormError,
    cookies,
  } = props;

  let csrfToken = cookies.cookies._csrf;

  if (loadingDynamicList || !currentDynamicList || loadingStandards) {
    return (
      <div>
        <LoadingCircle />
      </div>
    );
  }
  let updatingDynamicList = false;
  if (params.dynamicListId) {
    updatingDynamicList = true;
  }

  let isSuperAdmin = false;
  if (checkUserIsSuperAdmin(isAuthenticated, user)) isSuperAdmin = true;

  if (!isSuperAdmin) {
    return (
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Unauthorized" />
          </Card>
        </Grid>
      </Grid>
    );
  }

  let initialValues = {
    name: currentDynamicList.name ? currentDynamicList.name : '',
    items: currentDynamicList.items ? currentDynamicList.items : [],
    description: currentDynamicList.description
      ? currentDynamicList.description
      : '',
  };

  return (
    <>
      <BackButton />
      <Card>
        <CardHeader
          avatar={<img src={userIcon} style={{ maxHeight: 20 }} />}
          title={`List information`}
        />
        <CardContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={(values) => {
              const errors = {};
              if (!values.name) errors.name = 'Required';
              if (!values.description) errors.description = 'Required';
              return errors;
            }}
            onSubmit={(values) => {
              let dynamicListToSubmit = values;

              if (updatingDynamicList) {
                dynamicListToSubmit._id = params.dynamicListId;
                props.setDynamicListStart(dynamicListToSubmit, navigate);
              } else {
                dynamicListToSubmit.items = localDynamicListItems.map(
                  (item) => {
                    item._id = item.id;
                    delete item.id;
                    return item;
                  }
                );
                props.createDynamicListStart(dynamicListToSubmit, navigate);
              }
              return false;
            }}
          >
            {({ submitForm, values, isSubmitting, errors }) => {
              let formCurrentDynamicList = values;
              return (
                <>
                  <Grid item xs={12}>
                    <input type="hidden" name="_csrf" value={{ csrfToken }} />
                    <Field
                      style={{ width: 300, marginTop: 20 }}
                      component={SetTextField}
                      label="Name"
                      name="name"
                      type="text"
                      formIsBeingFilledOutHandler={
                        props.dynamicListFormIsBeingFilledOut
                      }
                    />
                    {dynamicListFormError && dynamicListFormError['name'] && (
                      <div>
                        <span style={{ fontSize: 10, color: 'red' }}>
                          {dynamicListFormError['name']}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      style={{ width: 300, marginTop: 20 }}
                      component={SetTextField}
                      label="Description"
                      name="description"
                      type="text"
                      formIsBeingFilledOutHandler={
                        props.dynamicListFormIsBeingFilledOut
                      }
                    />
                    <FieldError
                      error={
                        dynamicListFormError &&
                        dynamicListFormError['description']
                          ? dynamicListFormError['description']
                          : ''
                      }
                    />
                    <FieldTooltip
                      fieldInError={
                        dynamicListFormError &&
                        dynamicListFormError['description']
                      }
                      description="Description is used to describe the list and will appear as a tooltip"
                    />
                  </Grid>

                  <DynamicListItems
                    userCanEditDynamicListItems={true}
                    userCanReadDynamicListItems={true}
                  />

                  <Grid item xs={12} style={{ marginTop: 30 }}>
                    <Button
                      data-testid={
                        updatingDynamicList ? 'updateListBtn' : 'createListBtn'
                      }
                      onClick={submitForm}
                      variant="contained"
                      color="primary"
                      disabled={loadingDynamicList || isSubmitting}
                    >
                      {updatingDynamicList ? `Update list` : `Create list`}
                    </Button>

                    {updatingDynamicList && formCurrentDynamicList && (
                      <Button
                        data-testid={'deleteListBtn'}
                        style={{ marginLeft: 20 }}
                        variant={'contained'}
                        color="secondary"
                        disabled={loadingDynamicList}
                        onClick={() =>
                          props.deleteDynamicListStart(
                            params.dynamicListId,
                            navigate
                          )
                        }
                      >
                        {`Delete list`}
                      </Button>
                    )}

                    {(loadingDynamicList || isSubmitting) && <LoadingCircle />}
                  </Grid>
                </>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = ({ dynamicListsReducer, loginReducer }) => {
  const {
    currentDynamicList,
    loadingDynamicList,
    formIsFilledOut,
    dynamicListFormError,
  } = dynamicListsReducer;
  const { isAuthenticated, user } = loginReducer;
  return {
    currentDynamicList,
    loadingDynamicList,
    isAuthenticated,
    user,
    formIsFilledOut,
    dynamicListFormError,
  };
};

const mapDispatchToProps = {
  createDynamicListStart,
  deleteDynamicListStart,
  setDynamicListStart,
  getDynamicListStart,
  resetCurrentDynamicList,
  dynamicListFormIsBeingFilledOut,
};

DynamicListForm = withTranslation('translations')(DynamicListForm);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(DynamicListForm))
);
