import { Tooltip } from '@mui/material';

import infoIcon from '../../../assets/images/icons/info.png';

type FieldTooltipPropsType = {
  description: string;
  fieldInError: boolean;
};

const FieldTooltip = ({ description, fieldInError }: FieldTooltipPropsType) => {
  return (
    <div
      style={{
        position: 'relative',
        top: fieldInError ? -85 : -66,
        left: 285,
        width: 22,
        height: 22,
      }}
    >
      {description && (
        <Tooltip title={description}>
          <img
            src={infoIcon}
            alt={description}
            style={{ width: 22, height: 22 }}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default FieldTooltip;
