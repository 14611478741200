import { quantityTypeUppercase } from 'components/Administration/Generic/Form/utils/utils';
import { FormikValues } from 'formik';

import { quantity } from '../../utils/constants';

export const getValidationSchema = (
  values: FormikValues,
  products: any[],
  maxQuantityForProduct: any,
  unit: string
) => {
  const errors: any = {};
  if (!values.origin) {
    errors.origin = 'Required';
  }
  const numberRegEx = /^\d*$/;

  if (!values.volume) {
    errors.volume = 'Required';
  }
  //else if (!numberRegEx.test(String(values.volume).toLowerCase())) { errors.volume = 'Invalid number'; }
  else if (isNaN(values.volume)) {
    errors.volume = 'Invalid number';
  } else if (values.volume == 0) {
    errors.volume = quantityTypeUppercase + ' cannot be null';
  } else if (values.volume > maxQuantityForProduct) {
    errors.volume = `Maximum available ${quantity} is ${maxQuantityForProduct} ${unit}`;
  }
  if (!values.year) {
    errors.year = 'Required';
  } else if (!numberRegEx.test(String(values.year).toLowerCase())) {
    errors.year = 'Invalid number';
  } else if (String(values.year).length != 4) {
    errors.year = 'Invalid year !';
  }
  if (!values.month) {
    errors.month = 'Required';
  } else if (!numberRegEx.test(String(values.month).toLowerCase())) {
    errors.month = 'Invalid number';
  } else if (String(values.month).length > 2) {
    errors.month = 'Invalid month format ! Must be MM';
  } else if (parseInt(values.month) < 1 || parseInt(values.month, 10) > 12) {
    errors.month = 'Invalid month ! Must be betweend [1, 12]';
  }
  return errors;
};
