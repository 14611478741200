import { FC, MutableRefObject, useRef } from 'react';

import { Dialog } from '@mui/material';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import ModalTitle from 'components/commons/ModalTitle/ModalTitle';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import {
  transferCertificatesStart,
  closeTransferFormDialog,
} from '../../../../../actions';
import transferIcon from '../../../../../assets/images/icons/money-transfer-v3.png';
import { checkUserIsSuperAdmin } from '../../../../../utils/user/checkAdmin/CheckAdmin';
import useBalancesChildComponentsSlices from '../../../Redux/CustomHooks/useBalancesChildComponentsSlices';
import TransferForm from '../../Forms/TransferForm/TransferForm';
import NextTransferOrAllocateConfirmButtonActions from '../Generic/FormConfirmation/NextTransferOrAllocateConfirmButtonActions';
import NextTransferOrAllocateConfirmFormContent from '../Generic/FormConfirmation/NextTransferOrAllocateConfirmFormContent';

const TransferDialog: FC = () => {
  const { t }: UseTranslationResponse<'translation', any> =
    useTranslation<'translation'>();
  const formTransferCertificateRef: MutableRefObject<any> = useRef<any>();
  const dispatch: AppDispatch = useDispatch();
  const {
    transferCertificatesFormOpen,
    transferCertificatesData,
    allBalances,
    displayTransferCertificateFormInDialog,
    user,
    isAuthenticated,
  }: UseBalancesChildComponentsSlicesType = useBalancesChildComponentsSlices();

  const onSubmitHandler = (): void => {
    dispatch(
      transferCertificatesStart(
        transferCertificatesData._origin,
        transferCertificatesData._year,
        transferCertificatesData._month,
        transferCertificatesData._from,
        transferCertificatesData._to,
        parseInt(transferCertificatesData._volume.toString()),
        allBalances,
        checkUserIsSuperAdmin(isAuthenticated, user)
      )
    );
  };
  const onErrorHandler = (errors: any[]): void => console.log(errors);

  const handleClose = () => {
    dispatch(closeTransferFormDialog());
  };

  return (
    <Dialog
      data-testid="transfer-dialog-id"
      fullScreen={false}
      fullWidth={true}
      maxWidth="sm"
      disableEnforceFocus
      open={transferCertificatesFormOpen}
      aria-labelledby="responsive-dialog-title"
      onClose={handleClose}
    >
      <ValidatorForm
        ref={formTransferCertificateRef}
        onSubmit={onSubmitHandler}
        onError={onErrorHandler}
      >
        <ModalTitle
          id="responsive-dialog-title"
          title={t('Transfer certificates')}
          handleClose={handleClose}
          icon={transferIcon}
        />

        {displayTransferCertificateFormInDialog && <TransferForm />}
        {!displayTransferCertificateFormInDialog && (
          <>
            <NextTransferOrAllocateConfirmFormContent operationName="transfer" />
            <NextTransferOrAllocateConfirmButtonActions operationName="transfer" />
          </>
        )}
      </ValidatorForm>
    </Dialog>
  );
};

export default TransferDialog;
