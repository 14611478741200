import { useEffect } from 'react';

import {
  getOrganizations,
  getProducts,
  getProductsStart,
  getProductsTotalCount,
} from 'actions';
import { RightReferences } from 'enums/RightReferences';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';

import useAdministrationSlicesCombiner from '../Slices/useAdministrationSlicesCombiner';

const useAdministration = () => {
  const { isAuthenticated, user } = useAdministrationSlicesCombiner();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;

    async function initComponent() {
      if (isMounted) {
        if (
          !(
            checkUserIsSuperAdmin(isAuthenticated, user) ||
            checkUserHasRight(
              isAuthenticated,
              user,
              RightReferences.ADMINISTRATION
            )
          )
        ) {
          navigate('/');
        }

        await dispatch(getOrganizations());
        const _totalCount = await dispatch(getProductsTotalCount());
        await dispatch(getProductsStart(0, _totalCount));
      }
    }
    initComponent();
    return () => {
      isMounted = false;
    };
  }, [
    user,
    isAuthenticated,
    dispatch,
    navigate,
    getProducts,
    getOrganizations,
    getProductsTotalCount,
  ]);
};

export default useAdministration;
