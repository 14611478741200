import { useSelector } from 'react-redux';
import { CertificatesReducerStateType } from 'reducers/certificatesReducer/types';
import { RootState } from 'redux/store';

const useCertificesSlices = (): CertificatesReducerStateType => {
  const {
    certificates,
    allCertificates,
    loading,
    retiringCertificateId,
    dialogSecretWordOpen,
    currentCertificateSecretWord,
    loadingSecretWord,
    dialogLoadingPdfOpen,
    cancelCertificateFormOpen,
    displayCancelCertificateFormInDialog,
    cancelComment,
    loadingCancelCertificate,
    selectedStandardId,
    selectedStandardType,
    selectedProductTypeId,
    paginatedCertificatesCount,
    allCertificatesCount,
    defaultCertificatesPaginationThreshold,
  } = useSelector((state: RootState) => state.certificatesReducer);

  return {
    certificates,
    allCertificates,
    loading,
    retiringCertificateId,
    dialogSecretWordOpen,
    currentCertificateSecretWord,
    loadingSecretWord,
    dialogLoadingPdfOpen,
    cancelCertificateFormOpen,
    displayCancelCertificateFormInDialog,
    cancelComment,
    loadingCancelCertificate,
    selectedStandardId,
    selectedStandardType,
    selectedProductTypeId,
    paginatedCertificatesCount,
    allCertificatesCount,
    defaultCertificatesPaginationThreshold,
  };
};

export default useCertificesSlices;
