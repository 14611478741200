import { LinearProgress } from '@mui/material';
import { DataGridPro as Table } from '@mui/x-data-grid-pro';
import LoadAll from 'components/commons/LoadAll/LoadAll';
import useProducts from 'components/Products/Redux/CustomHooks/useProducts';
import useProductsSlicesCombiner from 'components/Products/Redux/Slices/useProductsSlicesCombiner';
import getProductsColumns from 'components/Products/utils/table/columns';
import getRows from 'components/Products/utils/table/rows';
import {
  FILTER_MODE_CLIENT_SIDE,
  PAGINATION_MODE_SERVER_SIDE,
} from 'components/Transactions/utils/constants';
import { useNavigate } from 'react-router';
import './Styles/index.css';

const ProductsTable = (): JSX.Element => {
  const navigate = useNavigate();
  const { handlePageChange, handlePageSizeChange, page, pageSize } =
    useProducts();

  const { allProducts, loading, isAuthenticated, user, products, totalCount } =
    useProductsSlicesCombiner();

  return (
    <Table
      disableSelectionOnClick
      loading={loading}
      onRowClick={(rowData: any) => {
        const _id = rowData.row._id;
        navigate('/products/read/' + _id);
      }}
      pagination
      paginationMode={PAGINATION_MODE_SERVER_SIDE}
      filterMode={FILTER_MODE_CLIENT_SIDE}
      rows={getRows(products)}
      columns={getProductsColumns(isAuthenticated, user)}
      components={{
        LoadingOverlay: LinearProgress,
        Footer: () => (
          <LoadAll
            onClick={() => {
              handlePageSizeChange(totalCount);
            }}
            loadedRows={(allProducts as any[])?.length}
            totalRows={totalCount as number}
            typeOfData="products"
          />
        ),
      }}
      page={page}
      pageSize={pageSize}
      rowCount={totalCount}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
    />
  );
};

export default ProductsTable;
