import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { fetchHash } from 'actions';
import GenericTable from 'components/GenericComponents/Tables/GenericTable/GenericTable';
import { GenericTablePropsType } from 'components/GenericComponents/Tables/GenericTable/types';
import { blockchainExplorerUrl } from 'config/blockchainExplorerConfig';
import moment from 'moment';
import { Link } from 'react-router-dom';

import BlockchainDataSwitch from './BlockchainDataSwitch';
import useBlockchainDataSwitchConfig from './Redux/CustomHooks/useBlockchainDataSwitchConfig';

const columnData = [
  {
    field: 'transactionTimestamp',
    headerName: 'Date',
    editable: false,
    width: 250,
  },
  {
    field: 'DB_fieldName',
    headerName: 'Attribute',
    editable: false,
    width: 250,
  },
  {
    field: 'DB_fieldValue',
    headerName: 'Value',
    editable: false,
    width: 250,
  },
  {
    field: 'status',
    headerName: 'Status',
    editable: false,
    width: 80,
  },
  {
    field: 'BC_field',
    headerName: 'Blockchain value (encrypted)',
    editable: false,
    width: 400,
  },
  {
    field: 'secret',
    headerName: 'Secret',
    editable: false,
    width: 300,
  },
  {
    field: 'hash',
    headerName: 'Hash("value"+"secret")',
    editable: false,
    width: 300,
  },
  {
    field: 'transactionLink',
    headerName: 'Transaction',
    editable: false,
    renderCell: (params: any) => (
      <Link rel="noopener noreferrer" to={params.value} target="_blank">
        {params.value}
      </Link>
    ),
    width: 400,
  },
];

export type CheckAttributesListPropsType = {
  list: any[];
};

const CheckAttributesList = ({ list }: CheckAttributesListPropsType) => {
  const { columnVisibilityModel, toggleVisibility } =
    useBlockchainDataSwitchConfig();
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    async function fetchHashes() {
      const _rows: any[] = [];
      for (const monthAttributes of list) {
        const numberOfAttributes = monthAttributes.DB_fields?.length;

        for (let i = 0; i < numberOfAttributes; i++) {
          const DB_field = {
            fieldValue: monthAttributes.DB_fields[i],
            fieldName: '',
            transactionHash: '',
            transactionTimestamp: null,
          };

          if (monthAttributes.DB_fields[i].fieldValue) {
            Object.assign(DB_field, {
              fieldValue: monthAttributes.DB_fields[i].fieldValue,
              fieldName: monthAttributes.DB_fields[i].fieldName,
              transactionHash: monthAttributes.DB_fields[i].transactionHash,
              transactionTimestamp:
                monthAttributes.DB_fields[i].transactionTimestamp,
            });
          }

          const hash = await fetchHash(
            DB_field.fieldValue + monthAttributes.secrets[i]
          );
          _rows.push({
            id: `${DB_field.fieldName}-${DB_field.transactionTimestamp}-${i}`,
            DB_fieldName: DB_field.fieldName,
            DB_fieldValue: DB_field.fieldValue,
            BC_field: monthAttributes.BC_fields[i],
            secret: monthAttributes.secrets[i],
            status: hash === monthAttributes.BC_fields[i] ? '✅' : '❌',
            hash: hash,
            transactionLink: DB_field.transactionHash
              ? `${blockchainExplorerUrl}/tx/${DB_field.transactionHash}`
              : '',
            transactionTimestamp: DB_field.transactionTimestamp
              ? `${moment
                  .unix(DB_field.transactionTimestamp)
                  .format('YYYY-MM-DD HH:mm:ss Z')}`
              : '',
          });
        }
      }
      setRows(_rows);
    }
    fetchHashes();
  }, [list]);

  const attributesProps: GenericTablePropsType = {
    tableColumns: columnData,
    tableRows: rows,
    autoHeight: true,
    columnVisibilityModel,
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <BlockchainDataSwitch onToggleVisibility={toggleVisibility} />
      </Grid>
      <Grid item xs={12}>
        <GenericTable {...attributesProps} />
      </Grid>
    </Grid>
  );
};

export default CheckAttributesList;
