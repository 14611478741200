import { Button, DialogActions } from '@mui/material';
import { deleteDynamicListItemStart } from 'actions';
import useDynamicListsSlicesCombiner from 'components/DynamicList/Redux/Slices/useDynamicListsSlicesCombiner';
import Loading from 'components/GenericComponents/Async/Loading/Loading';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const ConfirmDeletionActions = (props: any) => {
  const {
    dynamicListItemToDelete,
    currentDynamicList,
    deletingDynamicListItemIds,
  } = useDynamicListsSlicesCombiner();

  const dynamicListItemToDeleteId = dynamicListItemToDelete?._id
    ? dynamicListItemToDelete?._id
    : '';
  const deletingDynamicListItem = deletingDynamicListItemIds?.includes(
    dynamicListItemToDeleteId
  );
  const dispatch: AppDispatch = useDispatch();
  return (
    <DialogActions>
      <Button onClick={props.handleClose} color="primary">
        Cancel
      </Button>
      <Button
        variant="contained"
        color="secondary"
        disabled={deletingDynamicListItem}
        onClick={() => {
          dispatch(
            deleteDynamicListItemStart(
              dynamicListItemToDelete?._id,
              currentDynamicList?._id
            )
          );
        }}
      >
        Delete
        {deletingDynamicListItem && (
          <Loading size={16} style={{ marginLeft: 10 }} />
        )}
      </Button>
    </DialogActions>
  );
};

export default ConfirmDeletionActions;
