type GenericErrorInRedPropsType = {
  condition: boolean;
  errors: any;
  customKey: string | number;
};

const GenericErrorInRed = ({
  condition,
  errors,
  customKey,
}: GenericErrorInRedPropsType) => {
  return condition ? (
    <div>
      <span
        style={{
          fontSize: 10,
          color: 'red',
        }}
      >
        {errors[customKey]}
      </span>
    </div>
  ) : (
    <></>
  );
};

export default GenericErrorInRed;
