import { Grid } from '@mui/material';
import GenericFieldLabel from 'components/GenericComponents/Grid/Field/FieldLabel/GenericFieldLabel';
import GenericFieldValue from 'components/GenericComponents/Grid/Field/FieldValue/GenericFieldValue';

export type SummaryFieldPropsType = {
  labelName: string;
  displayedValue: string;
  keyIndex?: string;
};

const SummaryField = ({
  labelName,
  displayedValue,
  keyIndex,
}: SummaryFieldPropsType) => {
  return (
    <Grid item xs={12} md={6} lg={3} key={keyIndex}>
      <GenericFieldLabel label={labelName} />
      <GenericFieldValue field={displayedValue} />
    </Grid>
  );
};

export default SummaryField;
