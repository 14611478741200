import { FC } from 'react';

import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import LoadingCircle from 'components/commons/LoadingCircle';
import { displayPlatinumUI } from 'components/CreateOrUpdateProduct/utils/utils';

import { Units } from '../../../../../enums/Units';
import useBalancesChildComponentsSlices from '../../../Redux/CustomHooks/useBalancesChildComponentsSlices';
import { prepareBalancesData } from '../../../utils/data/utils';
import RetireDialog from '../RetireDialog/RetireDialog';
import TransferDialog from '../TransferDialog/TransferDialog';

import OrganizationsContext from './OrganizationsContext';

const BalanceTransactionsDialogs: FC = () => {
  const {
    organizations,
    businessOrganizations,
    standards,
    selectedStandardId,
    user,
    isAuthenticated,
    ozOrGramsQuantityUnit,
    loading,
  }: UseBalancesChildComponentsSlicesType = useBalancesChildComponentsSlices();

  const activateOzConversion: boolean =
    displayPlatinumUI &&
    ozOrGramsQuantityUnit !== undefined &&
    ozOrGramsQuantityUnit !== Units.GRAMS;

  const {
    organizationsInAllocateMenu,
    organizationsInTransferMenu: organizationsInTransferMenuRender,
  } = prepareBalancesData(
    organizations,
    businessOrganizations,
    standards,
    selectedStandardId,
    user,
    isAuthenticated
  );
  const dataAreLoading = loading || !organizations || organizations.length < 1;

  if (dataAreLoading) return <LoadingCircle />;
  return (
    !dataAreLoading && (
      <>
        <OrganizationsContext.Provider
          value={{
            organizationsInAllocateMenu: organizationsInAllocateMenu,
            organizationsInTransferMenu: organizationsInTransferMenuRender,
            activateOzConversion: activateOzConversion,
          }}
        >
          <TransferDialog />
          <RetireDialog />
        </OrganizationsContext.Provider>
      </>
    )
  );
};

export default BalanceTransactionsDialogs;
