import { useSelector } from 'react-redux';
import { CertificatesReducerStateType } from 'reducers/certificatesReducer/types';
import { RootState } from 'redux/store';

const useCertificatesReducerSlices = (): CertificatesReducerStateType => {
  //Redux Product slices
  const {
    retiringCertificateTxHash,
    dialogSecretWordOpen,
    currentCertificateSecretWord,
    loadingSecretWord,
    dialogLoadingPdfOpen,
  }: CertificatesReducerStateType = useSelector(
    (state: RootState) => state.certificatesReducer
  );
  return {
    retiringCertificateTxHash,
    dialogSecretWordOpen,
    currentCertificateSecretWord,
    loadingSecretWord,
    dialogLoadingPdfOpen,
  };
};

export default useCertificatesReducerSlices;
