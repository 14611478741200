import { v4 as uuidv4 } from 'uuid';

const getDataGridRows = (sourceHistory: any, currentProduct: any) => {
  const dataGridRows = [...sourceHistory];

  if (currentProduct && currentProduct.source) {
    dataGridRows.unshift({
      id: uuidv4(),
      source: currentProduct.source,
      sourceID: currentProduct.sourceID,
      periodNumber: currentProduct.periodNumber,
      periodType: currentProduct.periodType,
      aggregationPeriodNumber: currentProduct.aggregationPeriodNumber,
      aggregationPeriodType: currentProduct.aggregationPeriodType,
    });
  }
  return dataGridRows;
};

export default getDataGridRows;
