/* eslint-disable */

import {
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
  openSuccessSnack,
  openErrorSnack,
} from './UtilsActions';
import { handleError } from './ErrorActions';
import { capitalize } from 'utils/text';

export const getDynamicLists = async () => {
  let response = await apiGet(`/api/lists`);

  if (response.status >= 200 && response.status <= 299) {
    const dynamicLists = await response.json();
    if (!Array.isArray(dynamicLists))
      throw new Error(
        `Error retrieving lists, returns : ` + JSON.stringify(dynamicLists)
      );
    return dynamicLists;
  } else {
    // Handle errors
    await handleError(response);
  }
};

export const getDynamicList = async (dynamicListId) => {
  let response = await apiGet(`/api/lists/${dynamicListId}`);

  if (response.status >= 200 && response.status <= 299) {
    return await response.json();
  } else {
    // Handle errors
    await handleError(response);
  }
};

export const getDynamicListsStart = () => async (dispatch) => {
  dispatch({ type: 'GET_DYNAMIC_LISTS_START' });

  try {
    let dynamicLists = await getDynamicLists();
    dispatch({ type: 'GET_DYNAMIC_LISTS_SUCCESS', payload: dynamicLists });
  } catch (error) {
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'GET_DYNAMIC_LISTS_FAIL' });
  }
};

export const dynamicListFormIsBeingFilledOut = () => (dispatch) => {
  dispatch({ type: 'DYNAMIC_LIST_FORM_IS_BEING_FILLED_OUT' });
};

export const addDynamicList = async (dynamicList) => {
  let response = await apiPost(`/api/lists`, dynamicList);

  if (response.status >= 200 && response.status <= 299) {
    const dynamicListCreated = await response.json();

    return dynamicListCreated;
  } else {
    // Handle errors
    await handleError(response);
  }
};

export const createDynamicListStart =
  (dynamicListToCreate, navigate) => async (dispatch) => {
    dispatch({ type: 'CREATE_DYNAMIC_LIST_START' });

    try {
      await addDynamicList(dynamicListToCreate);
      dispatch(openSuccessSnack(`List successfully created !`));

      let dynamicLists = await getDynamicLists();
      dispatch({ type: 'CREATE_DYNAMIC_LIST_SUCCESS', payload: dynamicLists });
      navigate('/lists/');
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'CREATE_DYNAMIC_LIST_FAIL' });
    }
  };

export const getDynamicListStart = (dynamicListId) => async (dispatch) => {
  dispatch({ type: 'GET_DYNAMIC_LIST_START' });

  try {
    let response = await apiGet(`/api/lists/${dynamicListId}`);

    if (response.status >= 200 && response.status <= 299) {
      const dynamicList = await response.json();
      dispatch({ type: 'GET_DYNAMIC_LIST_SUCCESS', payload: dynamicList });
    } else {
      // Handle errors
      await handleError(response);
    }
  } catch (error) {
    dispatch(openErrorSnack(error?.message));
    dispatch({ type: 'GET_DYNAMIC_LIST_FAIL' });
  }
};

export const onDynamicListFieldChange = (field, value) => async (dispatch) => {
  dispatch({ type: 'SET_DYNAMIC_LIST_FIELD', payload: { field, value } });
};

export const resetCurrentDynamicList = () => async (dispatch) => {
  dispatch({ type: 'RESET_DYNAMIC_LIST' });
};

export const setDynamicList = async (dynamicListToUpdate) => {
  try {
    let response = await apiPut(
      `/api/lists/${dynamicListToUpdate._id}`,
      dynamicListToUpdate
    );

    if (response.status >= 200 && response.status <= 299) {
      const dynamicListUpdate = await response.json();
      return dynamicListUpdate;
    } else {
      // Handle errors
      await handleError(response);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const setDynamicListStart =
  (dynamicListToUpdate, navigate) => async (dispatch) => {
    dispatch({ type: 'SET_DYNAMIC_LIST_START' });

    try {
      await setDynamicList(dynamicListToUpdate);
      dispatch(openSuccessSnack(`List successfully updated !`));

      let dynamicLists = await getDynamicLists();

      dispatch({ type: 'SET_DYNAMIC_LIST_SUCCESS', payload: dynamicLists });
      navigate('/lists/');
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'SET_DYNAMIC_LIST_FAIL' });
    }
  };

export const deleteDynamicListStart =
  (dynamicListToDeleteId, navigate) => async (dispatch) => {
    dispatch({ type: 'DELETE_DYNAMIC_LIST_START' });

    try {
      let response = await apiDelete(`/api/lists/${dynamicListToDeleteId}`);

      if (response.status >= 200 && response.status <= 299) {
        const dynamicListDeleted = await response.json();

        dispatch(openSuccessSnack(`List successfully deleted !`));

        let dynamicLists = await getDynamicLists();
        dispatch({
          type: 'DELETE_DYNAMIC_LIST_SUCCESS',
          payload: dynamicLists,
        });
        navigate('/lists/');
      } else {
        // Handle errors
        await handleError(response);
      }
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'DELETE_DYNAMIC_LIST_FAIL' });
    }
  };

export const addLocalDynamicListItem = (listItem) => async (dispatch) => {
  dispatch({ type: 'ADD_LOCAL_LIST_ITEM', payload: listItem });
};

export const removeLocalDynamicListItem =
  (localDynamicListItemId) => async (dispatch) => {
    dispatch({
      type: 'REMOVE_LOCAL_LIST_ITEM',
      payload: { localDynamicListItemId },
    });
  };

export const addDynamicListItem = async (dynamicListItem, dynamicListId) => {
  let apiCreateUrl = `/api/lists/${dynamicListId}`;

  let response = await apiPost(apiCreateUrl, dynamicListItem);

  if (response.status >= 200 && response.status <= 299) {
    const createResult = await response.json();
    return createResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const setDynamicListItem = async (dynamicListItem, dynamicListId) => {
  let apiUpdateUrl = `/api/lists/${dynamicListId}/${dynamicListItem._id}`;

  let response = await apiPut(apiUpdateUrl, dynamicListItem);

  if (response.status >= 200 && response.status <= 299) {
    const updateResult = await response.json();
    return updateResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};
export const flushLocalDynamicListItems = () => async (dispatch) => {
  dispatch({ type: 'REMOVE_LOCAL_LIST_ITEMS' });
};

export const addOrSetDynamicListItemStart =
  async (dynamicListItem, dynamicListId) => async (dispatch) => {
    try {
      let dynamicList = await getDynamicList(dynamicListId);
      for (const item of dynamicList.items) {
        //id is for local row in datagrid when no db insertion is done, _id is for mongodb id
        if (
          item.id === dynamicListItem.id ||
          item._id === dynamicListItem._id
        ) {
          await setDynamicListItem(dynamicListItem, dynamicListId);
          dispatch(openSuccessSnack(`List item set successfully !`));
          let updatedDynamicList = await getDynamicList(dynamicListId);
          dispatch({
            type: 'GET_DYNAMIC_LIST_ITEMS_SUCCESS',
            payload: updatedDynamicList.items,
          });
          return;
        }
      }
      await addDynamicListItem(dynamicListItem, dynamicListId);
      dispatch(openSuccessSnack(`List item added successfully !`));
      let updatedDynamicList = await getDynamicList(dynamicListId);
      dispatch({
        type: 'GET_DYNAMIC_LIST_ITEMS_SUCCESS',
        payload: updatedDynamicList.items,
      });
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
    }
  };

export const setDynamicListGridApiRef = (apiRef) => async (dispatch) => {
  dispatch({
    type: 'SET_DATAGRID_API_REF',
    payload: apiRef,
  });
};

export const updateDynamicListItemsOrderStart =
  (dynamicListId, items) => async (dispatch) => {
    try {
      let dynamicList = await getDynamicList(dynamicListId);
      dynamicList.items = items;
      await setDynamicList(dynamicList);
      dispatch(openSuccessSnack(`List items order updated successfully !`));
      dispatch({
        type: 'GET_DYNAMIC_LIST_ITEMS_SUCCESS',
        payload: items,
      });
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
    }
  };

export const updateLocalDynamicListItemsOrderStart =
  (items) => async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LOCAL_LIST_ITEMS',
        payload: items,
      });
    } catch (error) {
      dispatch(openErrorSnack(error?.message));
    }
  };

export const setLocalDynamicListItemField =
  (dynamicListItemId, field, fieldValue) => (dispatch) => {
    dispatch({
      type: 'SET_LOCAL_LIST_ITEM_FIELD',
      payload: { field, fieldValue, dynamicListItemId },
    });
  };

export const deleteDynamicListItem = async (
  dynamicListItemId,
  dynamicListId
) => {
  let apiDeleteUrl = `/api/lists/${dynamicListId}/${dynamicListItemId}`;

  let response = await apiDelete(apiDeleteUrl, {});

  if (response.status >= 200 && response.status <= 299) {
    const deleteResult = await response.json();
    return deleteResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const displayConfirmDynamicListItemDeletionModal =
  (dynamicListItem) => async (dispatch) => {
    dispatch({
      type: 'DISPLAY_CONFIRM_LIST_ITEM_DELETION_MODAL',
      payload: { dynamicListItem },
    });
  };

export const hideConfirmDynamicListItemDeletionModal =
  () => async (dispatch) => {
    dispatch({
      type: 'HIDE_CONFIRM_LIST_ITEM_DELETION_MODAL',
    });
  };

export const displayUploadDynamicListItemDialog = () => async (dispatch) => {
  dispatch({
    type: 'DISPLAY_UPLOAD_LIST_ITEM_MODAL',
  });
};

export const hideUploadDynamicListItemModal = () => async (dispatch) => {
  dispatch({
    type: 'HIDE_UPLOAD_LIST_ITEM_MODAL',
  });
};

export const deleteDynamicListItemStart =
  (dynamicListItemId, dynamicListId) => async (dispatch) => {
    dispatch({
      type: 'DELETE_LIST_ITEM_START',
      payload: { dynamicListItemId },
    });

    let deleteResult = await deleteDynamicListItem(
      dynamicListItemId,
      dynamicListId
    ).catch((error) => {
      //Catch generic error
      console.error(error);
      dispatch(openErrorSnack(error?.message));
      dispatch({
        type: 'DELETE_LIST_ITEM_FAIL',
        payload: { dynamicListItemId },
      });
    });
    if (deleteResult) {
      dispatch(openSuccessSnack('List item deleted'));
      dispatch({
        type: 'DELETE_LIST_ITEM_SUCCESS',
        payload: { dynamicListItemId },
      });
      dispatch({
        type: 'HIDE_CONFIRM_LIST_ITEM_DELETION_MODAL',
      });
    }
  };

export const dynamicListItemsChanged = (items) => (dispatch) => {
  dispatch({ type: 'ADD_LOCAL_LIST_ITEMS', payload: items });
  dispatch({ type: 'HIDE_UPLOAD_LIST_ITEM_MODAL' });
};
