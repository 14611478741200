import GenericModalTitle from 'components/Administration/Generic/Modal/Title/GenericModalTitle';
import useOperationsSlicesCombiner from 'components/Operations/Redux/Slices/useOperationsSlicesCombiner';

import dateToIcon from '../../../../assets/images/icons/date-to.png';

const OperationsToolbar = () => {
  const { loading } = useOperationsSlicesCombiner();
  return (
    <GenericModalTitle
      title={'User logs'}
      icon={dateToIcon}
      loading={loading}
    />
  );
};

export default OperationsToolbar;
