import { Tooltip } from '@mui/material';
import Switch from '@mui/material/Switch';

const BeNotifiedCell = (props: any): JSX.Element => {
  const { params, userCanEditReferenceFiles, onSwitchChange } = props;

  if (userCanEditReferenceFiles) {
    const label = {
      inputProps: {
        'aria-label': 'Activate or deactivate notification for this document.',
      },
    };
    return (
      <Tooltip
        title="Activate or deactivate notification for this document."
        placement="bottom"
        enterDelay={100}
      >
        <Switch
          {...label}
          size="small"
          checked={params.row.notify ? true : false}
          onChange={(event) => {
            onSwitchChange(event, params);
          }}
        />
      </Tooltip>
    );
  }

  return <></>;
};

export default BeNotifiedCell;
