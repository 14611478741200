import GenericModalFooter from 'components/Administration/Generic/Modal/Footer/GenericModalFooter';

import useIncreaseBalanceFormModalConfig from '../../Configuration/useIncreaseBalanceFormModalConfig';

const ModalFooterIncreaseBalanceForm = () => {
  const { footer } = useIncreaseBalanceFormModalConfig();

  return <GenericModalFooter footer={footer} />;
};

export default ModalFooterIncreaseBalanceForm;
