import { DefaultProductType } from 'reducers/productsReducer/types';

export type DynamicDataType = {
  dynamicDataSourcesToFilterOn?: any[];
  infoXArray: any[];
  dynamicData?: any[];
};

export const prepareDynamicDataWithFilterOption = (
  currentProduct: DefaultProductType
): DynamicDataType => {
  const selectedStandard = currentProduct?.standard;

  const infoXArray: any[] = [];
  const dynamicData = [];
  const dynamicDataSourcesToFilterOn: any[] = [];
  dynamicData.push({
    sourceName: '',
    tableTitle: 'Production history',
  });

  if (selectedStandard && selectedStandard.infoTexts) {
    selectedStandard.infoTexts.forEach((infoText: any, index: number) => {
      const {
        infoStandardText: label,
        infoStandardDesc: desc,
        infoProp: key,
        type,
        listItems,
        unit: infoXUnit,
        sourceName,
        tableTitle,
      } = infoText;

      if (label) {
        infoXArray.push({
          label,
          desc,
          pos: index,
          type,
          listItems,
          unit: infoXUnit,
          sourceName,
          tableTitle,
          key,
        });
      }

      if (infoXUnit && sourceName && tableTitle) {
        dynamicData.push({
          sourceName,
          tableTitle,
        });
        dynamicDataSourcesToFilterOn.push(sourceName);
      }
    });
  }

  return { dynamicDataSourcesToFilterOn, infoXArray, dynamicData };
};
