import { DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import lockIcon from '../../../../../../assets/images/icons/padlock.png';

type TransactionsDialogTitleProps = {
  loadingSecretWord: boolean;
};

const TransactionsDialogTitle = ({
  loadingSecretWord,
}: TransactionsDialogTitleProps) => {
  const { t } = useTranslation();

  return (
    <DialogTitle id="responsive-dialog-title">
      {loadingSecretWord && t('Loading encrypted data...')}
      {!loadingSecretWord && (
        <div>
          <img
            src={lockIcon}
            alt={'Encrypted data'}
            title={'Encrypted data'}
            style={{ width: 16, height: 16, marginRight: 20 }}
          />
          Encrypted data
        </div>
      )}
    </DialogTitle>
  );
};

export default TransactionsDialogTitle;
