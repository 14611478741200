import { areFieldsUntouchedOrEmpty } from 'components/FormStandards/utils/utils';
import { enums } from 'enums';
import { FormikValues, useFormikContext } from 'formik';

import AddAttributeColumns from '../../Table/Columns';

import CollectionGridItem from './Items/Types/CollectionGridItem';
import DynamicDataGridItem from './Items/Types/DynamicDataGridItem';
import InfoTextGridItem from './Items/Types/InfoTextGridItem';
import ListGridItem from './Items/Types/ListGridItem';

type StandardAttributeBlocPropsType = {
  index: number;
  infoText: { type?: string; isNew?: boolean };
  isEven: boolean;
  onRemove: (
    values: FormikValues,
    setFieldValue: any,
    indexToRemove: number
  ) => void;
};

const StandardAttributeBloc = ({
  index,
  infoText,
  isEven,
  onRemove,
}: StandardAttributeBlocPropsType) => {
  const { values } = useFormikContext<FormikValues>();
  const dataGridColumns = AddAttributeColumns({ index });
  // Retrieve the form values from the form context
  const currentStandard = values;

  if (infoText?.isNew || !areFieldsUntouchedOrEmpty(index, currentStandard)) {
    if (
      infoText.type === enums.InfoTypes.TEXT ||
      infoText.type === enums.InfoTypes.NUMBER ||
      infoText.type === enums.InfoTypes.DATE
    ) {
      return (
        <InfoTextGridItem index={index} isEven={isEven} onRemove={onRemove} />
      );
    } else if (
      infoText.type === enums.InfoTypes.LIST ||
      infoText.type === enums.InfoTypes.LIST_MULTI
    ) {
      return (
        <ListGridItem
          index={index}
          isEven={isEven}
          infoText={infoText}
          dataGridColumns={dataGridColumns}
          onRemove={onRemove}
        />
      );
    } else if (
      infoText.type === enums.InfoTypes.COLLECTION ||
      infoText.type === enums.InfoTypes.COLLECTION_MULTI
    ) {
      return (
        <CollectionGridItem
          index={index}
          isEven={isEven}
          infoText={infoText}
          dataGridColumns={dataGridColumns}
          onRemove={onRemove}
        />
      );
    } else if (infoText.type === enums.InfoTypes.DYNAMIC_DATA) {
      return (
        <DynamicDataGridItem
          index={index}
          isEven={isEven}
          onRemove={onRemove}
        />
      );
    } else return null;
  }
  return null;
};

export default StandardAttributeBloc;
