import {
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import { toggleWarningDialog } from 'actions';
import useNominationsGuards from 'components/Nominations/Redux/CustomHooks/useNominationsGuards';
import useNominationsSlicesCombiner from 'components/Nominations/Redux/Slices/useNominationsSlicesCombiner';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import warningIcon from '../../../../../assets/images/icons/warning.png';
import NominationsTableHeader from '../../Table/NominationsTableHeader';
import NominationsTableRow from '../../Table/NominationsTableRow';

import NominationsDialogActions from './MainDialogNominationsCustomComponents/NominationsDialogActions';

const NominationsDialog = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const { nominationsRequestsToDo, isWarningDialogOpen } =
    useNominationsSlicesCombiner();
  const { canManageMultipleOrganizationNominations, organizationToUse } =
    useNominationsGuards();

  let warningMessage = '';

  const requestMessage = t(
    "You will validate the following requests. You CAN'T modify these requests later, please check carefully before validation"
  );
  const nominationsnMessage = t(
    'You will update the following nominations. Please check carefully before validation'
  );
  warningMessage = canManageMultipleOrganizationNominations
    ? nominationsnMessage
    : requestMessage;

  return (
    <Dialog
      fullScreen={false}
      open={isWarningDialogOpen}
      onClose={() => dispatch(toggleWarningDialog())}
      aria-labelledby="warning-dialog"
    >
      <DialogTitle id="warning-dialog">
        <img
          src={warningIcon}
          alt={'Be careful'}
          title={'Be careful'}
          style={{ width: 24, height: 24 }}
        />
        {t('Check your parameters')}
      </DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: 10 }}>{warningMessage}</div>
        {canManageMultipleOrganizationNominations && (
          <div>
            <Typography
              variant="body1"
              style={{ color: 'rgba(0, 0, 0, 0.54)' }}
            >
              {t('Organization')} :{' '}
              <span style={{ color: 'rgba(0, 0, 0, 1)', fontWeight: 'bold' }}>
                {organizationToUse?.name}
              </span>
            </Typography>
          </div>
        )}
        {nominationsRequestsToDo.length === 0 && (
          <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
            No requests to validate, please fill the form
          </Typography>
        )}
        <TableContainer
          component={Paper}
          style={{ marginBottom: 40, marginTop: 40 }}
        >
          <Table size="small" aria-label="a dense table">
            <NominationsTableHeader />
            <TableBody>
              {nominationsRequestsToDo.map((nominationsRequestToDo) => (
                <NominationsTableRow
                  key={nominationsRequestToDo._id}
                  nominationsRequestToDo={nominationsRequestToDo}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <NominationsDialogActions />
    </Dialog>
  );
};

export default NominationsDialog;
