import { useEffect, useState } from 'react';

import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { displayPlatinumUI } from 'components/CreateOrUpdateProduct/utils/utils';

const useDecreaseBalanceUnitAndPeriod = () => {
  const { decreaseBalanceData } = useAdministrationSlicesCombiner();
  const [unit, setUnit] = useState<string>('kWh');
  const [period, setPeriod] = useState<string>(
    displayPlatinumUI ? 'semester' : 'month'
  );
  useEffect(() => {
    if (decreaseBalanceData?._origin?.unit) {
      setUnit(decreaseBalanceData._origin.unit);
    }
    if (decreaseBalanceData?._origin?.aggregationPeriodType)
      setPeriod(decreaseBalanceData?._origin?.aggregationPeriodType);
  }, [decreaseBalanceData]);
  return { unit, period };
};

export default useDecreaseBalanceUnitAndPeriod;
