import GenericModalContent from 'components/Administration/Generic/Modal/Content/GenericModalContent';

import useBulkOperationsModalConfig from '../../Configuration/useBulkOperationsModalConfig';

const ModalContentBulkOperations = () => {
  const { header, content } = useBulkOperationsModalConfig();
  return <GenericModalContent header={header} content={content} />;
};

export default ModalContentBulkOperations;
