import useLoginSlices from 'components/Balances/Redux/Slices/useLoginSlices';

import useNominationsSlices from './useNominationsSlices';

const useNominationsSlicesCombiner = () => {
  return {
    ...useLoginSlices(),
    ...useNominationsSlices(),
  };
};

export default useNominationsSlicesCombiner;
