import { useEffect, useState } from 'react';

import { Fab, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import DateCell from 'components/commons/DateCell';
import LinkOpenProductDialog from 'components/commons/LinkOpenProductDialog/LinkOpenProductDialog';
import useTransactionsCombiner from 'components/Transactions/Redux/Slices/Combiner/useTransactionsCombiner';
import { blockchainExplorerUrl } from 'config/blockchainExplorerConfig';
import { enums } from 'enums';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { convertGramsInOz } from 'utils/convert';
import {
  certificateQuantityRenderer,
  getCertificateDateFromMonthAndYearText,
  getCertificateFromName,
  getCertificateIssuerName,
  getCertificateOriginName,
  getCertificateOriginUnit,
  getCertificateProductId,
  getCertificateQuantity,
  getCertificateToName,
  getTransactionTypeName,
  getTransactionUser,
  monthYearComparator,
} from 'utils/datagridHelpers';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';

import blockchainLogo from '../../../../../../assets/images/blockchain.png';
import SecretWordButton from '../../../Buttons/SecretWordButton';
import { periodTitle, quantityTitle } from '../constants';

import { getDynamicColumns } from './dynamicColumns';

const TransactionColumns = (): GridColDef[] => {
  const { t } = useTranslation();
  const {
    ozOrGramsQuantityUnit,
    selectedStandardId,
    selectedStandardType,
    uniqueStandardsFromBalances,
    isAuthenticated,
    user,
    aggregationId,
  } = useTransactionsCombiner();
  const [dynamicDefaultColumns, setDynamicDefaultColumns] = useState<
    Array<GridColDef | undefined>
  >([]);
  const [dynamicColumns, setDynamicColumns] = useState<
    Array<GridColDef | undefined>
  >([]);

  useEffect(() => {
    setDynamicDefaultColumns(
      getDynamicColumns(
        selectedStandardId,
        uniqueStandardsFromBalances,
        'default'
      )
    );
  }, [selectedStandardId, uniqueStandardsFromBalances]);

  useEffect(() => {
    setDynamicColumns(
      getDynamicColumns(selectedStandardType, uniqueStandardsFromBalances)
    );
  }, [selectedStandardType, uniqueStandardsFromBalances]);

  let dataGridColumns: GridColDef[] = [
    {
      field: 'typeName',
      headerName: 'Action',
      editable: false,
      width: 200,
      valueGetter: getTransactionTypeName,
    },

    {
      field: 'fromName',
      headerName: 'From',
      editable: false,
      width: 200,
      valueGetter: getCertificateFromName,
    },
    {
      field: 'toName',
      headerName: 'To',
      editable: false,
      width: 200,
      valueGetter: getCertificateToName,
    },

    {
      field: 'creationDate',
      headerName: 'Date',
      editable: false,
      width: 120,
      type: 'dateTime',
      valueFormatter: ({ value }: any) =>
        value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '',
      renderCell: (params: any) => (
        <DateCell
          date={params.row.creationDate}
          cancelled={params.row.cancelled}
        />
      ),
    },

    {
      field: 'issuerName',
      headerName: 'Issuer',
      editable: false,
      width: 200,
      valueGetter: getCertificateIssuerName,
    },
    {
      field: 'productIdValue',
      headerName: 'Product Id',
      editable: false,
      width: 200,
      valueGetter: getCertificateProductId,
      renderCell: (params) => {
        return (
          <LinkOpenProductDialog
            product={params?.row?.origin}
            month={params?.row?.month}
            year={params?.row?.year}
            text={params?.row?.origin?.productId}
          />
        );
      },
    },
    {
      field: 'productNameValue',
      headerName: 'Product',
      editable: false,
      width: 200,
      valueGetter: getCertificateOriginName,
      renderCell: (params) => {
        return (
          <LinkOpenProductDialog
            product={params?.row?.origin}
            month={params?.row?.month}
            year={params?.row?.year}
            text={params?.row?.origin?.displayedName}
          />
        );
      },
    },
    ...(dynamicColumns as GridColDef[]),
    ...(dynamicDefaultColumns as GridColDef[]),
    {
      field: 'volume',
      headerName: quantityTitle,
      editable: false,
      width: 140,
      type: 'number',
      valueGetter: (params: any) => {
        const _quantity = getCertificateQuantity(params);
        const productUnit = getCertificateOriginUnit(params);
        if (
          process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum' &&
          productUnit === enums.Units.GRAMS &&
          ozOrGramsQuantityUnit !== enums.Units.GRAMS
        ) {
          return convertGramsInOz(_quantity);
        }
        return _quantity;
      },
      valueFormatter: ({ value }: any) => parseInt(value),
      renderCell: certificateQuantityRenderer,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      editable: false,
      width: 80,
      valueGetter: (params: any) => {
        const productUnit = getCertificateOriginUnit(params);
        if (
          process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum' &&
          productUnit === enums.Units.GRAMS &&
          ozOrGramsQuantityUnit !== enums.Units.GRAMS
        ) {
          return 'oz';
        }
        return productUnit;
      },
    },

    {
      field: 'monthAndYear',
      headerName: periodTitle,
      editable: false,
      width: 120,
      type: 'string',
      sortComparator: monthYearComparator,
      valueGetter: getCertificateDateFromMonthAndYearText,
    },

    {
      field: 'blockchainProof',
      headerName: 'Blockchain proof',
      editable: false,
      width: 140,
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params: any) => (
        <div style={{ textAlign: 'center' }}>
          <Tooltip
            title={t('Check transaction')}
            placement="bottom"
            enterDelay={100}
          >
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              style={{ backgroundColor: '#FFFFFF' }}
            >
              <a
                href={`${blockchainExplorerUrl}/tx/${params.row.txHash}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={blockchainLogo}
                  alt="check transaction"
                  title="check transaction"
                  style={{ width: 22, height: 22, marginTop: 6, marginLeft: 2 }}
                />
              </a>
            </Fab>
          </Tooltip>
        </div>
      ),
    },

    {
      field: 'secret',
      headerName: 'Secret',
      editable: false,
      width: 80,
      filterable: false,
      sortable: false,
      disableExport: true,
      hide:
        !checkUserIsSuperAdmin(isAuthenticated, user) &&
        !checkUserHasRight(
          isAuthenticated,
          user,
          RightReferences.CERTIFICATES,
          [CrudPermissions.VIEW]
        ),
      renderCell: (params: any) => (
        <SecretWordButton transaction={params.row} />
      ),
    },
    {
      field: 'user',
      headerName: 'User',
      editable: false,
      width: 200,
      valueGetter: getTransactionUser,
    },
  ];
  if (aggregationId) {
    dataGridColumns = dataGridColumns.filter(
      (column) =>
        column.field !== 'fromName' &&
        column.field !== 'toName' &&
        column.field !== 'secret' &&
        column.field !== 'issuerName' &&
        column.field !== 'typeName' &&
        column.field !== 'user'
    );
  }

  return dataGridColumns;
};

export default TransactionColumns;
