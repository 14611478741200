import MoreButton from 'components/DynamicListItems/CustomComponents/MoreButton/MoreButton';
import TextCell from 'components/DynamicListItems/CustomComponents/Table/TextCell';

export const getAllDataGridColumns = (deletingDynamicListItemIds) => {
  let columns = [
    {
      field: 'key',
      headerName: 'Key',
      width: 300,
      editable: true,
      renderCell: (params) => (
        <TextCell userCanEditDynamicListItems={true} params={params} />
      ),
    },
    {
      field: 'value',
      headerName: 'Value in menu',
      editable: true,
      width: 300,
      renderCell: (params) => (
        <TextCell userCanEditDynamicListItems={true} params={params} />
      ),
    },
    {
      field: 'menu',
      headerName: 'Options',
      editable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <MoreButton
            row={params.row}
            deleting={deletingDynamicListItemIds.includes(params.row._id)}
            userCanEditDynamicListItems={true}
          />
        );
      },
    },
  ];

  return columns;
};
