import { Button } from '@mui/material';
import useProductsSlicesCombiner from 'components/Products/Redux/Slices/useProductsSlicesCombiner';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { useNavigate } from 'react-router';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';

import archiveIcon from '../../../../assets/images/icons/open-box.png';
import './Styles/index.css';

const ArchiveAssetsButton = (): JSX.Element => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useProductsSlicesCombiner();

  if (
    !(
      checkUserIsSuperAdmin(isAuthenticated, user) ||
      checkUserHasRight(isAuthenticated, user, RightReferences.PRODUCTS, [
        CrudPermissions.UPDATE,
      ])
    )
  )
    return <></>;

  return (
    <Button
      size="small"
      color="primary"
      aria-label="archived-assets"
      onClick={() => {
        navigate('/products/archived');
      }}
      className="archive-btn"
    >
      <img
        alt="archive icon"
        src={archiveIcon}
        style={{ maxHeight: 20, marginRight: 5, marginBottom: 5 }}
      />
      Archived or deprecated products
    </Button>
  );
};

export default ArchiveAssetsButton;
