import { useEffect, useState } from 'react';

import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { FormikValues, useFormikContext } from 'formik';

import { quantity } from '../utils/constants';

const useDisplayVolumeErrorTypes = () => {
  const [volumeFieldError, setVolumeFieldError] = useState<string>('');
  const [displayVolumeField, setDisplayVolumeField] = useState<boolean>(false);
  const [displayVolumeFieldError, setDisplayVolumeFieldError] =
    useState<boolean>(false);
  const [
    isIssueCertificateButtonDisabled,
    setIsIssueCertificateButtonDisabled,
  ] = useState<boolean>(false);
  const { maxQuantityForProduct } = useAdministrationSlicesCombiner();
  const { values, errors } = useFormikContext<FormikValues>();

  useEffect(() => {
    setDisplayVolumeField(
      values.year &&
        values.month &&
        values.origin &&
        maxQuantityForProduct &&
        maxQuantityForProduct > 0
    );

    setDisplayVolumeFieldError(
      !displayVolumeField &&
        values.origin &&
        (maxQuantityForProduct === 0 || maxQuantityForProduct === null)
    );

    if (displayVolumeFieldError && maxQuantityForProduct === 0)
      setVolumeFieldError(
        `There is no more available ${quantity} for this month and this product.`
      );
    else if (displayVolumeFieldError && maxQuantityForProduct === null)
      setVolumeFieldError(`No data retrieved for this month and this product`);

    setIsIssueCertificateButtonDisabled(
      !(
        displayVolumeField &&
        values.volume > 0 &&
        Object.keys(errors).length === 0
      )
    );
  }, [values, quantity, maxQuantityForProduct, errors]);

  return {
    volumeFieldError,
    displayVolumeField,
    displayVolumeFieldError,
    isIssueCertificateButtonDisabled,
  };
};

export default useDisplayVolumeErrorTypes;
