import Typography from '@mui/material/Typography';
import moment from 'moment';

type DateCellPropsType = {
  cancelled?: boolean;
  date?: Date;
};

const DateCell = ({
  cancelled = false,
  date,
}: DateCellPropsType): JSX.Element => {
  return (
    <div
      style={{
        color: cancelled ? 'rgb(206, 206, 206)' : '',
        cursor: 'pointer',
        minWidth: 68,
        maxWidth: 68,
        width: 68,
      }}
    >
      {date ? moment(date).format('YYYY-MM-DD') : 'Unknown date'}
      <Typography variant="caption" style={{ color: '#CECECE' }}>
        {date ? moment(date).format('HH:mm:ss') : ''}
      </Typography>
    </div>
  );
};

export default DateCell;
