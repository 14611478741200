import { Paper } from '@mui/material';
import useNominationsSlicesCombiner from 'components/Nominations/Redux/Slices/useNominationsSlicesCombiner';

import NominationsExchanges from '../Exchanges/NominationsExchanges';
import NominationsYearsTabs from '../YearsTabs/NominationsYearsTabs';

const NominationsGlobalExchanges = () => {
  const { nominationsRequests } = useNominationsSlicesCombiner();
  const monthlyExchanges =
    nominationsRequests && nominationsRequests.monthlyNominationsManagementItems
      ? nominationsRequests.monthlyNominationsManagementItems
      : [];
  const yearlyExchanges =
    nominationsRequests && nominationsRequests.yearlyNominationsManagementItems
      ? [nominationsRequests.yearlyNominationsManagementItems]
      : [];
  return (
    <Paper>
      <NominationsYearsTabs />
      <NominationsExchanges exchanges={yearlyExchanges} text="Year" />
      <NominationsExchanges exchanges={monthlyExchanges} text="Month" />
    </Paper>
  );
};

export default NominationsGlobalExchanges;
