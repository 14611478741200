import { Dialog } from '@mui/material';
import {
  setAssetsMeteringToDisplayInDialog,
  setOpenAggregationDetails,
} from 'actions';
import ModalTitle from 'components/commons/ModalTitle/ModalTitle';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import eventsIcon from '../../../../../../../assets/images/icons/events.png';

import AssetsMeteringActions from './Actions/AssetsMeteringActions';
import AssetsMeteringContent from './Content/AssetsMeteringContent';

const AssetsMeteringDialog = () => {
  const { openAggregationDetails } = useProductSlicesCombiner();
  const dispatch: AppDispatch = useDispatch();
  const handleClose = () => {
    dispatch(setOpenAggregationDetails(false));
    dispatch(setAssetsMeteringToDisplayInDialog([]));
  };
  const modalProps: any = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'lg',
    disableEnforceFocus: false,
    open: openAggregationDetails,
    'aria-labelledby': 'responsive-dialog-title',
    onClose: handleClose,
  };

  return (
    <Dialog {...modalProps}>
      <ModalTitle
        id="responsive-dialog-title"
        title="Assets metering"
        handleClose={handleClose}
        icon={eventsIcon}
      />
      <AssetsMeteringContent />
      <AssetsMeteringActions />
    </Dialog>
  );
};

export default AssetsMeteringDialog;
