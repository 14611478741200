import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { FormikValues } from 'formik';

import CollectionAttributeForm from '../CollectionAttributeForm';

type CollectionGridItemPropsType = {
  index: number;
  isEven: boolean;
  dataGridColumns: GridColDef[];
  infoText?: any;
  onRemove: (
    values: FormikValues,
    setFieldValue: any,
    indexToRemove: number
  ) => void;
};
const CollectionGridItem = ({
  index,
  isEven,
  onRemove,
}: CollectionGridItemPropsType) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      style={{
        backgroundColor: isEven ? '#F5F8FA' : '#FFFFFF',
        paddingBottom: 20,
      }}
    >
      <CollectionAttributeForm index={index} onRemove={onRemove} />
    </Box>
  );
};

export default CollectionGridItem;
