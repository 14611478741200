import React, { useCallback } from 'react';

import {
  flexSellerOrganizationChanged,
  flexBuyerOrganizationChanged,
  getNominationsOfIssuerOrganizationByYear,
  getIssuerOrganizations,
} from 'actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';

import useNominationsSlicesCombiner from '../Slices/useNominationsSlicesCombiner';

const useNominationsChangeHandlers = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    independantsWithNominationsFeature,
    isAuthenticated,
    user,
    year,
    sellerOrganizations,
    buyerOrganization,
    selectedSellerOrganization,
  } = useNominationsSlicesCombiner();

  const handleBuyerOrganizationChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedOrganization = independantsWithNominationsFeature.find(
        (org) => org._id === event.target.value
      );

      dispatch(flexBuyerOrganizationChanged(selectedOrganization));
      dispatch(getIssuerOrganizations(selectedOrganization));
      dispatch(flexSellerOrganizationChanged(null));
    },
    [dispatch, independantsWithNominationsFeature, isAuthenticated, user, year]
  );

  const handleSellerOrganizationChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedOrganization = sellerOrganizations.find(
        (org) => org._id === event.target.value
      );
      dispatch(flexSellerOrganizationChanged(selectedOrganization));
      const isSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, user);
      if (isSuperAdmin) {
        await dispatch(
          getNominationsOfIssuerOrganizationByYear(
            buyerOrganization?._id,
            selectedOrganization?._id,
            year
          )
        );
      } else {
        await dispatch(
          getNominationsOfIssuerOrganizationByYear(
            buyerOrganization?._id,
            selectedOrganization?._id,
            year
          )
        );
      }
    },
    [
      dispatch,
      sellerOrganizations,
      selectedSellerOrganization,
      buyerOrganization,
      isAuthenticated,
      user,
      year,
    ]
  );

  return {
    handleBuyerOrganizationChange,
    handleSellerOrganizationChange,
  };
};

export default useNominationsChangeHandlers;
