/* eslint-disable */

import { sleep } from 'utils/general/utils';
import { getOrganizations } from '../actions';
import { enums } from '../../src/enums/';
import { apiPut, apiPost, apiDelete } from './UtilsActions';
import { getOrganization } from './OrganizationActions';
import { handleErrors } from './ErrorActions';
import { openSuccessSnack, openErrorSnack } from './UtilsActions';
import { apiGet } from './UtilsActions';

/* CONSTANTS */
const CONNECTION = 'Username-Password-Authentication';
const BLOCKCHAINADDRESS = '0x004....';

export const createUserStart =
  (currentUser, navigate, file) => async (dispatch) => {
    dispatch({ type: 'CREATE_USER_START' });

    const createdUser = await createUser(currentUser).catch((error) => {
      //Catch generic error
      console.error(error);
      dispatch({ type: 'CREATE_USER_FAIL' });
      dispatch(openErrorSnack(error?.message));
    });

    if (createdUser && createdUser.error) {
      //Catch form error
      dispatch({ type: 'CREATE_USER_FAIL' });
      dispatch({ type: 'USER_FORM_ERROR', payload: createdUser.error });
    } else if (createdUser) {
      //No form error
      if (file) {
        let userId = createdUser.user_id.split('|')[1];
        let userToUpdateWithPicture = {
          user_id: createdUser.user_id,
          email: currentUser.email,
          app_metadata: createdUser.app_metadata,
          user_metadata: {
            username: currentUser.identifier,
            firstname: currentUser.firstname,
            lastname: currentUser.lastname,
            phoneNumber: currentUser.phoneNumber,
            organization: currentUser.organization,
          },
        };
        try {
          userToUpdateWithPicture.pictureFileName = await uploadUserPicture(
            file,
            userId
          );
        } catch (error) {
          dispatch(openErrorSnack('An error occured when uploading picture'));
        }

        await sleep(500); //Wait for the user to be added in auth0
        await setUser(userId, userToUpdateWithPicture).catch((error) => {
          dispatch(
            openErrorSnack('An error occured, try later or refresh the page')
          );
        });
      }
      dispatch(openSuccessSnack('New user created !'));
      navigate('/users/');
    }
  };

export const deleteUserStart = (userId, navigate) => async (dispatch) => {
  dispatch({ type: 'DELETE_USER_START' });

  let deletionResult = await deleteUser(userId).catch((error) => {
    //Catch generic error
    console.error(error);
    dispatch({ type: 'DELETE_USER_FAIL' });
    dispatch(openErrorSnack(error?.message));
  });

  if (deletionResult && deletionResult.error) {
    //Catch form error
    dispatch({ type: 'DELETE_USER_FAIL' });
    dispatch({ type: 'USER_FORM_ERROR', payload: deletionResult.error });
  } else if (deletionResult) {
    //No form error
    dispatch({ type: 'DELETE_USER_SUCCESS' });
    dispatch(openSuccessSnack('User deleted !'));
    navigate('/users/');
  }
};

const deleteUser = async (userId) => {
  let response = await apiDelete(`/api/users/${userId}`);
  if (response.status >= 200 && response.status <= 299) {
    const deletionResult = await response.json();
    return deletionResult;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const updateUserStart =
  (userId, user, navigate, file) => async (dispatch) => {
    dispatch({ type: 'UPDATE_USER_START' });

    //we force user role regarding its organization type
    let organization = await getOrganization(user.organization);
    let role = enums.UserRole.INDEPENDANT;
    if (organization.type === enums.OrganizationTypes.TEO)
      role = enums.UserRole.SUPER_ADMIN;
    user.roles = [role];

    const completeUser = {
      user_id: 'auth0|' + userId,
      email: user.email,
      app_metadata: {
        roles: user.roles,
        realAccount: true,
      },
      user_metadata: {
        username: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        phoneNumber: user.phoneNumber,
        organization: user.organization,
        rights: user.selectedRights,
      },
    };
    let updatedUser = await setUser(userId, completeUser).catch((error) => {
      //Catch generic error
      console.error(error);
      dispatch({ type: 'UPDATE_USER_FAIL' });
      dispatch(openErrorSnack(error?.message));
    });

    if (updatedUser && updatedUser.error) {
      //Catch form error
      dispatch({ type: 'UPDATE_USER_FAIL' });
      dispatch({ type: 'USER_FORM_ERROR', payload: updatedUser.error });
    } else if (updatedUser) {
      if (file) {
        try {
          completeUser.pictureFileName = await uploadUserPicture(file, userId);
        } catch (error) {
          dispatch(openErrorSnack('An error occured when uploading picture'));
        }
        await setUser(userId, completeUser).catch((error) => {
          dispatch(openErrorSnack('Error updating user: ' + error.message));
        });
      }
      dispatch(openSuccessSnack('User updated !'));
      navigate('/users/');
    }
  };

export const getRights = () => async (dispatch) => {
  dispatch({ type: 'GET_RIGHTS_START' });
  try {
    let response = await apiGet('/api/rights/');
    if (response.status < 200 || response.status > 299) {
      // Handle errors
      let jsonError = await response.json();
      let errorMessage = jsonError.message
        ? jsonError.message
        : 'An error occured, please try later or reload the page';
      throw new Error(errorMessage);
    }
    const rights = await response.json();
    dispatch({ type: 'GET_RIGHTS_SUCCESS', payload: rights });
    return rights;
  } catch (error) {
    dispatch({ type: 'GET_RIGHTS_FAIL' });
    dispatch(openErrorSnack(error?.message));
  }
};

export const setSelectedUserRights = (selectedRights) => async (dispatch) => {
  dispatch({ type: 'SET_SELECTED_USER_RIGHTS', payload: selectedRights });
};

export const getUserStart = (userId) => async (dispatch) => {
  dispatch({ type: 'GET_USER_START' });
  let user = await dispatch(getUser(userId));

  dispatch({ type: 'GET_USER_SUCCESS', payload: user });
};

export const getUserFormStart = (userId) => async (dispatch) => {
  dispatch({ type: 'GET_USER_FORM_START' });

  let user = await dispatch(getUser(userId));
  await dispatch(getOrganizations());

  dispatch({ type: 'GET_USER_FORM_SUCCESS', payload: user });
  return user;
};

export const resetCurrentUser = () => async (dispatch) => {
  dispatch({ type: 'RESET_USER' });
};

export const getUser = (userId) => async (dispatch) => {
  const user = await fetch(
    process.env.REACT_APP_API_URL + '/api/users/read/' + userId,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    }
  )
    .then(handleErrors)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(openErrorSnack(error?.message));
    });
  return user;
};

export const addM2MApp = async (userId) => {
  let response = await apiPost('/api/users/m2m', {
    user_id: userId,
    name: 'M2M App for ' + userId + ' created on ' + new Date().toISOString(),
  });

  if (response.status >= 200 && response.status <= 299) {
    const createdM2MApp = await response.json();
    return createdM2MApp;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const getM2MAppCredentials = async (userId, clientId) => {
  const m2mAppCredentials = await fetch(
    process.env.REACT_APP_API_URL + '/api/users/m2m/' + userId + '/' + clientId,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    }
  )
    .then(handleErrors)
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return m2mAppCredentials;
};

export const deleteM2MApp = async (userId, clientId) => {
  const result = await fetch(
    process.env.REACT_APP_API_URL + '/api/users/m2m/' + userId + '/' + clientId,
    {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    }
  )
    .then(handleErrors)
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return result;
};

export const getUsersStart = () => async (dispatch) => {
  dispatch({ type: 'GET_USERS_START' });
  return await fetch(process.env.REACT_APP_API_URL + '/api/users/', {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleErrors)
    .then(async (res) => res.json())
    .then(async (users) => {
      dispatch({ type: 'GET_USERS_SUCCESS', payload: users });
    })
    .catch((error) => {
      console.error(error);
      dispatch(openErrorSnack(error?.message));
      dispatch({ type: 'GET_USERS_FAIL' });
    });
};

const createUser = async (user) => {
  //we force user role regarding its organization type
  let organization = await getOrganization(user.organization);
  let role = enums.UserRole.INDEPENDANT;
  if (organization.type === enums.OrganizationTypes.TEO)
    role = enums.UserRole.SUPER_ADMIN;
  user.roles = [role];

  const completeUser = {
    connection: CONNECTION,
    email: user.email,
    email_verified: false,
    verify_email: false,
    app_metadata: {
      roles: user.roles,
      address: BLOCKCHAINADDRESS,
      authorized: true,
      realAccount: true,
    },
    user_metadata: {
      logo: '',
      username: user.username,
      firstname: user.firstname,
      lastname: user.lastname,
      phoneNumber: user.phoneNumber,
      organization: user.organization,
      rights: user.selectedRights,
    },
  };

  let response = await apiPost('/api/users/create', completeUser);

  if (response.status >= 200 && response.status <= 299) {
    const createdUser = await response.json();
    return createdUser;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};

export const userPictureChanged = (file) => (dispatch) => {
  dispatch({ type: 'USER_PICTURE_CHANGED', payload: file });
};

export const uploadUserPicture = async (file, userId) => {
  var formData = new FormData();
  formData.append('files', file);

  const pictureFileName = await fetch(
    process.env.REACT_APP_API_URL + '/api/users/upload-file/' + userId,
    {
      method: 'POST',
      credentials: 'include',
      body: formData,
    }
  )
    .then(handleErrors)
    .then((res) => res.json());

  return pictureFileName;
};

const setUser = async (userId, user) => {
  let response = await apiPut('/api/users/' + userId, user);
  if (response.status >= 200 && response.status <= 299) {
    const updatedUser = await response.json();
    return updatedUser;
  } else if (response.status === 422) {
    // Handle form content / parameters errors
    let res = await response.json();
    return res;
  } else {
    // Handle errors
    let jsonError = await response.json();
    let errorMessage = jsonError.message
      ? jsonError.message
      : 'An error occured, please try later or reload the page';
    throw new Error(errorMessage);
  }
};
