import { Box, CardHeader } from '@mui/material';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';

import { productIcon } from '../icons';

const SummaryTitle = (): JSX.Element => {
  const { currentProduct } = useProductSlicesCombiner();
  if (!currentProduct) return <></>;
  const subHeader = 'product id : ' + currentProduct.productId;
  return (
    <Box
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <CardHeader
        avatar={<img src={productIcon} style={{ maxHeight: 20 }} alt="" />}
        title={currentProduct.displayedName}
        subheader={subHeader}
      />
    </Box>
  );
};

export default SummaryTitle;
