import { Button, DialogActions } from '@mui/material';
import {
  closeCancelCertificateFormDialog,
  displayCancelFormInDialog,
} from 'actions';
import useCertificesSlicesCombiner from 'components/Certificates/Redux/Slices/useCertificatesSlicesCombiner';
import LoadingCircle from 'components/commons/LoadingCircle';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const RemoveCertificateDialogFooter = () => {
  const dispatch: AppDispatch = useDispatch();

  const { displayCancelCertificateFormInDialog, loadingCancelCertificate } =
    useCertificesSlicesCombiner();

  return (
    <DialogActions>
      {displayCancelCertificateFormInDialog ? (
        <>
          <Button
            onClick={() => dispatch(closeCancelCertificateFormDialog())}
            color="primary"
          >
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Remove certificate
          </Button>
        </>
      ) : (
        <>
          <Button
            onClick={() => dispatch(displayCancelFormInDialog())}
            color="primary"
          >
            Back
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={loadingCancelCertificate}
          >
            Confirm{' '}
            {loadingCancelCertificate && (
              <LoadingCircle style={{ marginLeft: 20 }} />
            )}
          </Button>
        </>
      )}
    </DialogActions>
  );
};

export default RemoveCertificateDialogFooter;
