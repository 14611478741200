import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';

import trashIcon from '../../assets/images/icons/trash.png';
import { withRouter } from '../../utils/migrationHelper';
import BackButton from '../commons/BackButton/BackButton';
import PageTitle from '../commons/PageTitle';

import ArchivedProductsTable from './CustomComponents/Table/ArchivedProductsTable';

const ArchivedProducts = () => {
  const { loading } = useProductSlicesCombiner();

  return (
    <div
      style={{
        height: document.documentElement.clientHeight - 200,
        paddingBottom: 183,
      }}
    >
      <BackButton />
      <PageTitle
        title={'Archived or deprecated products'}
        loading={loading}
        image={trashIcon}
      />

      <ArchivedProductsTable />
    </div>
  );
};

export default withRouter(ArchivedProducts);
