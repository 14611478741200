import { standardFormIsBeingFilledOut } from 'actions';
import GenericField from 'components/GenericComponents/Grid/Field/Field/GenericField';

const NameField = ({ token }: { token: any }) => {
  return (
    <GenericField
      name="name"
      label="Name"
      token={token}
      formIsBeingFilledOutHandler={standardFormIsBeingFilledOut}
      errorKey="name"
    />
  );
};
export default NameField;
