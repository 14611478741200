import { CardContent, Grid } from '@mui/material';
import { UseProductSlicesCombinerType } from 'components/Product/Redux/CustomHooks/types';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';

import SummaryStaticContent from './SummaryStaticContent/SummaryStaticContent';
import SummaryDynamicContent, {
  SummaryDynamicContentPropsType,
} from './SymmaryDynamicContent/SummaryDynamicContent';

const SummaryContent = (props: SummaryDynamicContentPropsType) => {
  const { currentProduct }: UseProductSlicesCombinerType =
    useProductSlicesCombiner();
  if (!currentProduct) return <></>;
  return (
    <CardContent>
      <Grid container spacing={2}>
        <SummaryStaticContent />
        <SummaryDynamicContent removeHiddenItems={props.removeHiddenItems} />
      </Grid>
    </CardContent>
  );
};

export default SummaryContent;
