import { Box, Fab } from '@mui/material';
import { addLocalDynamicListItem } from 'actions';
import { DynamicListItemsPropsType } from 'components/DynamicListItems/DynamicListItems';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { v4 as uuidv4 } from 'uuid';

import addFileIcon from '../../../../../../assets/images/icons/add-file.png';

const ToolbarUpload = ({
  userCanEditDynamicListItems,
}: DynamicListItemsPropsType): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const handleOpenUploadDynamicListItemDialog = async () => {
    const newListItemId = uuidv4();

    dispatch(
      addLocalDynamicListItem({
        id: newListItemId,
        key: '',
        value: '',
      })
    );
  };

  return userCanEditDynamicListItems ? (
    <Box>
      <Fab
        size="small"
        component="span"
        aria-label="add"
        style={{ backgroundColor: '#ffffff' }}
        onClick={handleOpenUploadDynamicListItemDialog}
      >
        <img
          src={addFileIcon}
          alt={'Add list item'}
          title={'Add list item'}
          style={{
            width: 22,
            height: 22,
            marginTop: 6,
            marginLeft: 2,
          }}
        />
      </Fab>
    </Box>
  ) : (
    <></>
  );
};

export default ToolbarUpload;
