import GenericModalContent from 'components/Administration/Generic/Modal/Content/GenericModalContent';

import useDecreaseBalanceFormConfirmationModalConfig from '../../Configuration/useDecreaseBalanceFormConfirmationModalConfig';

const ModalContentDecreaseBalanceConfirmation = () => {
  const { header, content } = useDecreaseBalanceFormConfirmationModalConfig();

  return <GenericModalContent header={header} content={content} />;
};

export default ModalContentDecreaseBalanceConfirmation;
