import { Grid, MenuItem } from '@mui/material';
import { Field, FormikErrors, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { checkObjectIsNonEmptyArray } from 'utils/general/utils';

import { GenericTextFieldPropsType } from '../../utils/types/types';

const GenericTextField = ({
  gridItemSize = 12,
  name = '',
  label = 'label',
  type = 'text',
  helperText = '',
  select = false,
  variant = 'standard',
  margin = 'normal',
  style = { width: 750, marginBottom: 10 },
  errorWrapperStyle = { marginLeft: 14 },
  errorChildStyle = { fontSize: 11, color: '#d32f2f' },
  inputLabelProps = { shrink: true },
  disabled = false,
  onChange = () => console.log('onChange triggered'),
  dynamicMenuItems = false,
  menuItems = [{ key: '', value: '', label: '' }] || [],
  menuItemsCallback = () => [],
  showErrors = false,
}: GenericTextFieldPropsType) => {
  const { errors, touched } = useFormikContext<FormikErrors<any>>();
  return (
    <Grid item xs={gridItemSize}>
      <Field
        component={TextField}
        type={type}
        name={name}
        label={label}
        select={select}
        variant={variant}
        margin={margin}
        helperText={helperText}
        style={style}
        InputLabelProps={inputLabelProps}
        onChange={onChange}
        disabled={disabled}
      >
        {!dynamicMenuItems &&
          checkObjectIsNonEmptyArray(menuItems) &&
          menuItems.map((menuItem: any) => {
            return (
              <MenuItem
                key={menuItem?.key || JSON.stringify(menuItem)}
                value={menuItem?.value || menuItem}
              >
                {menuItem?.label || menuItem}
              </MenuItem>
            );
          })}
        {dynamicMenuItems && menuItemsCallback()}
      </Field>
      {showErrors && errors?.[name] && touched?.[name] && (
        <div style={errorWrapperStyle}>
          <span style={errorChildStyle}>{errors[name]}</span>
        </div>
      )}
    </Grid>
  );
};

export default GenericTextField;
