import { green } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

let theme = createTheme();

export const formStyles = {
  root: {
    display: 'flex',
    flexWrap: 'center',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    flexBasis: 200,
    margin: 20,
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  fieldTitle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 400,
    fontSize: 12,
    fontFamily:
      '"Inter-Regular","Avenir","Roboto","Helvetica","Arial","sans-serif"',
  },
};
