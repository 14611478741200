import { Box } from '@mui/material';

import ProductTypeSelect from '../Select/ProductTypeSelect';
import StandardSelect from '../Select/StandardSelect';
import GramsToOzToggle from '../Switch/GramsToOzToggle';

const CertificatesToolbarBody = () => {
  return (
    <Box>
      <GramsToOzToggle />
      <StandardSelect />
      <ProductTypeSelect />
    </Box>
  );
};

export default CertificatesToolbarBody;
