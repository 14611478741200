import { Grid } from '@mui/material';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import { checkUserHasRight } from 'utils/user/checkRights';

import { checkUserIsSuperAdmin } from '../../../../../../utils/user/checkAdmin/CheckAdmin';
import GenericButton from '../../../../../GenericComponents/Buttons/GenericButton';
import useBalancesChildComponentsSlices from '../../../../Redux/CustomHooks/useBalancesChildComponentsSlices';

type AddNewBeneficiaryBtnPropsType = {
  retireHandler: (values: any) => void;
  values: any;
};
const AddNewBeneficiaryBtn = ({
  retireHandler,
  values,
}: AddNewBeneficiaryBtnPropsType) => {
  const { isAuthenticated, user }: UseBalancesChildComponentsSlicesType =
    useBalancesChildComponentsSlices();
  return (
    <Grid item xs={12} data-testid="add-new-beneficiary-btn-id">
      <GenericButton
        label={'Add a new beneficiary '}
        onClick={() => retireHandler(values)}
        color="primary"
        variant="outlined"
        size="small"
        condition={
          values.showRetailer &&
          (checkUserIsSuperAdmin(isAuthenticated, user) ||
            (checkUserHasRight(isAuthenticated, user, RightReferences.RETIRE) &&
              checkUserHasRight(
                isAuthenticated,
                user,
                RightReferences.ORGANIZATIONS,
                [CrudPermissions.UPDATE]
              )))
        }
      />
    </Grid>
  );
};

export default AddNewBeneficiaryBtn;
