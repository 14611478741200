import moment from 'moment';

export const sortAssetsMeteringAggregations = (selectedAggregation: any) => {
  if (
    selectedAggregation &&
    Array.isArray(selectedAggregation.aggregated_asset_metering_id_array)
  )
    return selectedAggregation.aggregated_asset_metering_id_array
      .map((am: any) => {
        am.id = am._id;
        return am;
      })
      .sort((a: any, b: any) => {
        const aTimestamp = moment(a.creation_date).format('x');
        const bTimestamp = moment(b.creation_date).format('x');

        if (aTimestamp < bTimestamp) {
          return 1;
        } else if (bTimestamp < aTimestamp) {
          return -1;
        } else {
          return 0;
        }
      });
};
