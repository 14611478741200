import { Button, DialogActions } from '@mui/material';
import { UseBalancesChildComponentsSlicesType } from 'components/Balances/Redux/CustomHooks/types';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import {
  displayAllocateFormInDialog,
  displayFormInDialog,
} from '../../../../../../actions';
import LoadingCircle from '../../../../../commons/LoadingCircle';
import useBalancesChildComponentsSlices from '../../../../Redux/CustomHooks/useBalancesChildComponentsSlices';

import { NextTransferOrAllocateConfirmButtonActionsPropsType } from './types';

const NextTransferOrAllocateConfirmButtonActions = ({
  operationName = 'transfer',
}: NextTransferOrAllocateConfirmButtonActionsPropsType) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    loadingTransferCertificate,
    loadingAllocateCertificate,
  }: UseBalancesChildComponentsSlicesType = useBalancesChildComponentsSlices();

  const loading =
    (operationName === 'transfer' && loadingTransferCertificate) ||
    (operationName === 'retire' && loadingAllocateCertificate);

  return (
    <DialogActions>
      <Button
        onClick={() =>
          operationName === 'transfer'
            ? dispatch(displayFormInDialog())
            : dispatch(displayAllocateFormInDialog())
        }
        color="primary"
      >
        Back
      </Button>

      <Button
        type="submit"
        color="primary"
        variant="contained"
        disabled={loading}
      >
        Confirm {loading && <LoadingCircle style={{ marginLeft: 20 }} />}
      </Button>
    </DialogActions>
  );
};

export default NextTransferOrAllocateConfirmButtonActions;
