import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@mui/material';
import { DataGridPro as Table } from '@mui/x-data-grid-pro';
import {
  setAssetsMeteringToDisplayInDialog,
  displayAggregationTransactionsModal,
  setOpenAggregationDetails,
} from 'actions';
import LoadAll from 'components/commons/LoadAll/LoadAll';
import AggregationCardContext from 'components/Product/Context/AggregationCardContext';
import GridToolbar from 'components/Product/CustomComponents/Toolbar/AggregationToolbar/CustomTableToolbar/GridToolbar';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import {
  FILTER_MODE_CLIENT_SIDE,
  PAGINATION_MODE_SERVER_SIDE,
} from 'components/Transactions/utils/constants';
import { AppDispatch } from 'redux/store';
import { getColumns } from './utils/columns';
import { getRows } from './utils/rows';
import { sortAssetsMeteringAggregations } from './utils/utils';
import { useProductAggregations } from 'components/Product/Redux/CustomHooks/useProductAggregations';

const AggregationTable = () => {
  const dispatch: AppDispatch = useDispatch();
  const { source, dynamicDataSourcesToFilterOn } = useContext(
    AggregationCardContext
  );

  const { handlePageChange, handlePageSizeChange, page, pageSize } =
    useProductAggregations();

  const {
    loadingProductAggregations,
    productAggregations,
    totalCountAggregations,
  } = useProductSlicesCombiner();

  const handleRowClick = (rowData: any, event: any) => {
    if (event.ignore) return true;
    const selectedAggregation = rowData.row;
    const assetsMeteringToDisplayInDialogSorted =
      sortAssetsMeteringAggregations(selectedAggregation);
    dispatch(setOpenAggregationDetails(true));
    dispatch(
      setAssetsMeteringToDisplayInDialog(assetsMeteringToDisplayInDialogSorted)
    );
  };

  const handleOpenTransactionDialog = (aggregationId: string) => {
    dispatch(displayAggregationTransactionsModal(aggregationId));
  };

  const rows = getRows(
    productAggregations as any[],
    source,
    dynamicDataSourcesToFilterOn as any[]
  );

  return (
    <Table
      loading={loadingProductAggregations as boolean}
      pagination
      paginationMode={PAGINATION_MODE_SERVER_SIDE}
      filterMode={FILTER_MODE_CLIENT_SIDE}
      columns={getColumns(handleOpenTransactionDialog)}
      onRowClick={handleRowClick}
      rows={rows}
      components={{
        LoadingOverlay: LinearProgress,
        Toolbar: GridToolbar,
        Footer: () => (
          <LoadAll
            onClick={() =>
              handlePageSizeChange(totalCountAggregations as number)
            }
            loadedRows={(productAggregations as any[]).length}
            totalRows={totalCountAggregations as number}
            typeOfData={`${source}`.toLowerCase() + ` aggregations`}
            source={source}
          />
        ),
      }}
      page={page}
      pageSize={pageSize}
      rowCount={totalCountAggregations}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
    />
  );
};

export default AggregationTable;
