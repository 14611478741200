import { LinearProgress } from '@mui/material';
import { DataGridPro as XGrid } from '@mui/x-data-grid-pro';
import LoadAll from 'components/commons/LoadAll/LoadAll';
import useTransactions from 'components/Transactions/Redux/CustomHooks/useTransactions';
import useTransactionsCombiner from 'components/Transactions/Redux/Slices/Combiner/useTransactionsCombiner';

import GridToolbar from '../Toolbar/GridToolbar';

import TransactionColumns from './utils/Columns/columns';

const TransactionsTable = () => {
  const { handlePageChange, handlePageSizeChange, page, pageSize } =
    useTransactions();
  const {
    transactions,
    loading,
    paginatedTransactionsCount,
    allTransactionsCount,
    defaultTransactionsPaginationThreshold,
  } = useTransactionsCombiner();

  const dataGridColumns = TransactionColumns();
  const dataGridRows = transactions?.map((t, index) => ({ ...t, id: index }));

  const isTesting = process.env.NODE_ENV === 'test';
  const useServerSidePagination =
    allTransactionsCount > (defaultTransactionsPaginationThreshold as number);

  return (
    <XGrid
      disableVirtualization={isTesting}
      disableSelectionOnClick
      loading={loading}
      pagination
      paginationMode={useServerSidePagination ? 'server' : 'client'}
      filterMode={useServerSidePagination ? 'server' : 'client'}
      rows={dataGridRows}
      columns={dataGridColumns}
      components={{
        Toolbar: GridToolbar,
        LoadingOverlay: LinearProgress,
        Footer: useServerSidePagination
          ? () => (
              <LoadAll
                onClick={() => {
                  handlePageSizeChange(allTransactionsCount);
                }}
                loadedRows={paginatedTransactionsCount}
                totalRows={allTransactionsCount}
                typeOfData="transactions"
              />
            )
          : undefined,
      }}
      page={useServerSidePagination ? page : undefined}
      pageSize={useServerSidePagination ? pageSize : undefined}
      rowCount={paginatedTransactionsCount}
      onPageChange={useServerSidePagination ? handlePageChange : undefined}
      onPageSizeChange={
        useServerSidePagination ? handlePageSizeChange : undefined
      }
    />
  );
};

export default TransactionsTable;
