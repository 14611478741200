import React from 'react';

import { Button } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid'; // Import type from MUI X-DataGrid
import { FormikValues, useFormikContext } from 'formik';

import trashIcon from '../../../../../../assets/images/icons/trash.png';

interface RemoveButtonProps {
  params: GridCellParams;
  index: number;
}

const RemoveButton: React.FC<RemoveButtonProps> = ({ params, index }) => {
  const { values, setValues } = useFormikContext<FormikValues>();

  return (
    <Button
      onClick={() => {
        const newValues = { ...values };
        newValues.infoTexts[index].listItems = newValues.infoTexts[
          index
        ].listItems.filter((el: any) => el.ref !== params.row.ref);
        setValues(newValues);
      }}
    >
      <img
        src={trashIcon}
        alt={'Remove'}
        title={'Remove'}
        style={{ width: 22, height: 22 }}
      />
    </Button>
  );
};

export default RemoveButton;
