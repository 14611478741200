import { Box, Tooltip } from '@mui/material';

const TextCell = (props: any): JSX.Element => {
  const { userCanEditDynamicListItems = false, params } = props;

  if (userCanEditDynamicListItems) {
    return (
      <>
        <div>
          <Tooltip
            title="Double click to edit key"
            placement="bottom"
            enterDelay={100}
          >
            <Box style={style.tooltipCell}>
              <span>{params.row[params.field]} </span>
            </Box>
          </Tooltip>
        </div>
      </>
    );
  }

  return (
    <>
      <span>{params.row[params.field]}</span>
    </>
  );
};

const style = {
  tooltipCell: {
    minHeight: '100%',
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  expired: {
    marginLeft: 10,
    color: 'red',
  },
  expiringSoon: {
    marginLeft: 10,
    color: 'orange',
  },
};

export default TextCell;
