import { IconButton, TableCell, TableRow, TextField } from '@mui/material';
import {
  getClickedNominationHistory,
  updateNominationsRequestsToDo,
} from 'actions';
import LoadingCircle from 'components/commons/LoadingCircle';
import useNominationsGuards from 'components/Nominations/Redux/CustomHooks/useNominationsGuards';
import useNominationsSlicesCombiner from 'components/Nominations/Redux/Slices/useNominationsSlicesCombiner';
import {
  addThousandsSeparatorToNumber,
  removeNonNumeric,
} from 'components/Nominations/utils/utils';
import moment from 'moment';
import numeral from 'numeral';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import historyIcon from '../../../../../assets/images/icons/timeMachine.png';

import { getError } from './utils';

type NominationsExchangesListPropsType = {
  exchanges: any[];
};

const NominationsExchangesList = ({
  exchanges,
}: NominationsExchangesListPropsType) => {
  const dispatch: AppDispatch = useDispatch();
  const { loading, selected } = useNominationsSlicesCombiner();
  const { canManageMultipleOrganizationNominations } = useNominationsGuards();

  if (loading) {
    return <LoadingCircle />;
  }

  const handleRequestToDoChange = (flex: any, type: string) => (event: any) => {
    flex[`_${type}`] = (numeral(event.target.value) as any)._value;

    dispatch(updateNominationsRequestsToDo(flex, type));
  };
  const isIdSelected = (id: string) => selected.includes(id);

  return (
    <>
      {exchanges.map((n, index) => {
        const isSelected = isIdSelected(n._id);

        const minValueToDisplay = n._min ?? n.min ?? null;
        const maxValueToDisplay = n._max ?? n.max ?? null;
        const requestedValueToDisplay = n._requested ?? n.requested ?? null;

        const minError = getError(minValueToDisplay, n.min, n.max, 'min');
        const maxError = getError(maxValueToDisplay, n.min, n.max, 'max');
        const requestedError = getError(
          requestedValueToDisplay,
          n.min,
          n.max,
          'requested'
        );

        return (
          <TableRow
            hover
            role="checkbox"
            aria-checked={isSelected}
            tabIndex={-1}
            key={index}
            selected={isSelected}
          >
            <TableCell
              style={{
                backgroundColor:
                  n.requested > 0 || !n.month ? 'rgb(237, 237, 237)' : '',
                cursor: 'pointer',
              }}
            >
              {n.month
                ? moment(`${n.year}-${n.month}`, 'YYYY-MM').format('MMM-YYYY')
                : n.year}
            </TableCell>
            <TableCell
              style={{
                backgroundColor:
                  n.requested > 0 || !n.month ? 'rgb(237, 237, 237)' : '',
                cursor: 'pointer',
              }}
            >
              {canManageMultipleOrganizationNominations ? (
                <div>
                  <TextField
                    error={Boolean(minError)}
                    helperText={minError}
                    variant="outlined"
                    type="text"
                    size="small"
                    value={addThousandsSeparatorToNumber(
                      removeNonNumeric(minValueToDisplay)
                    )}
                    onChange={handleRequestToDoChange(n, 'min')}
                  />
                  <IconButton
                    onClick={() =>
                      dispatch(getClickedNominationHistory(n._id, 'min'))
                    }
                  >
                    <img
                      src={historyIcon}
                      alt="Data history"
                      title="Data history"
                      style={{ width: 24, height: 24 }}
                    />
                  </IconButton>
                </div>
              ) : (
                addThousandsSeparatorToNumber(removeNonNumeric(n.min))
              )}
            </TableCell>
            <TableCell
              style={{
                backgroundColor:
                  n.requested > 0 || !n.month ? 'rgb(237, 237, 237)' : '',
                cursor: 'pointer',
              }}
            >
              {canManageMultipleOrganizationNominations ? (
                <div>
                  <TextField
                    error={Boolean(maxError)}
                    helperText={maxError}
                    variant="outlined"
                    type="text"
                    size="small"
                    value={addThousandsSeparatorToNumber(
                      removeNonNumeric(maxValueToDisplay)
                    )}
                    onChange={handleRequestToDoChange(n, 'max')}
                  />
                  <IconButton
                    onClick={() =>
                      dispatch(getClickedNominationHistory(n._id, 'max'))
                    }
                  >
                    <img
                      src={historyIcon}
                      alt="Data history"
                      title="Data history"
                      style={{ width: 24, height: 24 }}
                    />
                  </IconButton>
                </div>
              ) : (
                addThousandsSeparatorToNumber(removeNonNumeric(n.max))
              )}
            </TableCell>
            <TableCell
              style={{
                backgroundColor:
                  n.requested > 0 || !n.month ? 'rgb(237, 237, 237)' : '',
                cursor: 'pointer',
              }}
            >
              {n.requested > 0 ||
              !n.month ||
              canManageMultipleOrganizationNominations ? (
                addThousandsSeparatorToNumber(removeNonNumeric(n.requested))
              ) : (
                <TextField
                  error={Boolean(requestedError)}
                  helperText={requestedError}
                  variant="outlined"
                  type="text"
                  size="small"
                  value={addThousandsSeparatorToNumber(
                    removeNonNumeric(requestedValueToDisplay)
                  )}
                  onChange={handleRequestToDoChange(n, 'requested')}
                />
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default NominationsExchangesList;
