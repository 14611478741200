import { CardContent } from '@mui/material';
import Card from '@mui/material/Card';

import { withRouter } from '../../../../utils/migrationHelper';
import BackButton from '../../../commons/BackButton/BackButton';
import StandardCardHeader from '../../CustomComponents/Cards/Header/CardHeader';
import FormikStandardContent from '../../CustomComponents/Formik/FormikStandardContent';
import useFormStandard from '../../Redux/CustomHooks/useFormStandard';

const UpdateFormStandard = (props: any) => {
  const params = props.router?.params;
  useFormStandard(params);

  return (
    <>
      <BackButton />
      <Card>
        <StandardCardHeader />
        <CardContent>
          <FormikStandardContent props={props} />
        </CardContent>
      </Card>
    </>
  );
};
export default withRouter(UpdateFormStandard);
