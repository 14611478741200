import { useEffect, useState } from 'react';

import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { renderDataGridTaskMonitoringColumns } from 'components/ScheduledTaskMonitoring/scheduledTaskMonitoringHelper';

const useScheduledTasksGridData = () => {
  const { scheduledTasksMonitoring } = useAdministrationSlicesCombiner();

  const [dataGridRows, setDataGridRows] = useState<any[]>([]);
  const [dataGridColumns, setDataGridColumns] = useState<any[]>([]);
  useEffect(() => {
    const mappedScheduledTasksMonitoring = scheduledTasksMonitoring.map(
      (u: any) => {
        return { ...u, id: u._id };
      }
    );
    setDataGridRows(mappedScheduledTasksMonitoring);
    setDataGridColumns(renderDataGridTaskMonitoringColumns());
  }, [scheduledTasksMonitoring, renderDataGridTaskMonitoringColumns]);

  return { dataGridRows, dataGridColumns };
};

export default useScheduledTasksGridData;
