import { useEffect, useState } from 'react';

import { useGridApiRef } from '@mui/x-data-grid-pro';
import { getReferenceDocumentsStart } from 'actions';
import GenericTable from 'components/GenericComponents/Tables/GenericTable/GenericTable';
import { UseProductSlicesCombinerType } from 'components/Product/Redux/CustomHooks/types';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { getReferenceFilesDataGridColumns } from 'components/ReferenceFiles/utils/columns';
import { getAllReferenceFiles } from 'components/ReferenceFiles/utils/rows';
import { CrudPermissions } from 'enums/CrudPermissions';
import { RightReferences } from 'enums/RightReferences';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { ProductFileType } from 'reducers/productsReducer/types';
import { AppDispatch } from 'redux/store';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';
import { checkUserHasRight } from 'utils/user/checkRights';

export type ReferenceFilesTablePropsType = {
  status?: string;
};

const ReferenceFilesTable = ({ status }: ReferenceFilesTablePropsType) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const {
    referenceDocuments,
    loading,
    isAuthenticated,
    user,
  }: UseProductSlicesCombinerType = useProductSlicesCombiner();

  const userCanEditReferenceFiles =
    checkUserHasRight(isAuthenticated, user, RightReferences.PRODUCTS, [
      CrudPermissions.UPDATE,
    ]) || checkUserIsSuperAdmin(isAuthenticated, user);

  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getReferenceDocumentsStart());
    }
  }, []);

  useEffect(() => {
    let rowsToDisplay = [...referenceDocuments];

    switch (status) {
      case 'expired':
        rowsToDisplay = [...referenceDocuments].filter((referenceFile) => {
          if (
            !referenceFile.validityEnd ||
            !moment(referenceFile.validityEnd).isValid()
          )
            return false;
          const validityEndDate = moment(referenceFile.validityEnd).toDate();
          return validityEndDate <= new Date();
        });
        break;
      case 'will-expire-in-1-month':
        rowsToDisplay = [...referenceDocuments].filter((referenceFile) => {
          if (
            !referenceFile.validityEnd ||
            !moment(referenceFile.validityEnd).isValid()
          )
            return false;
          const validityEndDate = moment(referenceFile.validityEnd).toDate();
          return (
            validityEndDate > new Date() &&
            validityEndDate <
              new Date(new Date().setDate(new Date().getDate() + 30))
          );
        });
        break;
      case 'will-expire-in-3-months':
        rowsToDisplay = [...referenceDocuments].filter((referenceFile) => {
          if (
            !referenceFile.validityEnd ||
            !moment(referenceFile.validityEnd).isValid()
          )
            return false;
          const validityEndDate = moment(referenceFile.validityEnd).toDate();
          return (
            validityEndDate > new Date() &&
            validityEndDate >=
              new Date(new Date().setDate(new Date().getDate() + 30)) &&
            validityEndDate <
              new Date(new Date().setDate(new Date().getDate() + 90))
          );
        });
        break;
      case 'ok':
        rowsToDisplay = [...referenceDocuments].filter((referenceFile) => {
          if (
            !referenceFile.validityEnd ||
            !moment(referenceFile.validityEnd).isValid()
          )
            return false;
          const validityEndDate = moment(referenceFile.validityEnd).toDate();
          return (
            validityEndDate > new Date() &&
            validityEndDate >=
              new Date(new Date().setDate(new Date().getDate() + 90))
          );
        });
        break;
      case 'missing-validity-date':
        rowsToDisplay = [...referenceDocuments].filter((referenceFile) => {
          if (
            !referenceFile.validityEnd ||
            !referenceFile.validityStart ||
            !moment(referenceFile.validityEnd).isValid() ||
            !moment(referenceFile.validityStart).isValid()
          )
            return true;
          return false;
        });
        break;
      default:
        break;
    }

    const updatedDatagridRows: any[] = getAllReferenceFiles(
      rowsToDisplay as ProductFileType[]
    );
    setRows(updatedDatagridRows);
  }, [referenceDocuments]);

  const apiRef = useGridApiRef();

  const dataGridColumns = getReferenceFilesDataGridColumns({
    userCanEditReferenceFiles,
    navigate,
  });

  return (
    <GenericTable
      pagination
      autoHeight
      clickableRow={false}
      disableSelectionOnClick={true}
      loading={loading}
      tableColumns={dataGridColumns}
      tableRows={rows}
      isCellEditable={() => false}
      apiRef={apiRef}
    />
  );
};

export default ReferenceFilesTable;
