import { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import {
  handleRemoveAttribute,
  onDragEnd,
} from 'components/FormStandards/utils/handlers';
import { FormikValues, useFormikContext } from 'formik';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import CommonFields from '../Fields/Common/CommonFields';
import AttributesListTitle from '../Fields/Texts/AttributesListTitle';
import StandardAttributeBloc from '../Grid/InfoTexts/InfoTextGrid/StandardAttributeBloc';

import '../../Styles/index.css';

type DraggableStandardAttributesGridType = {
  infoTexts: any[];
  csrfToken: string;
  children?: React.ReactNode;
};

export const StandardAttributesDraggableGrid: React.FC<
  DraggableStandardAttributesGridType
> = ({ infoTexts, csrfToken, children }) => {
  const [lastDroppedIndex, setLastDroppedIndex] = useState<number | null>(null);

  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const handleDragEnd = (item: any) => {
    onDragEnd(item, values, setFieldValue);
    setLastDroppedIndex(item?.destination.index);
  };

  useEffect(() => {
    if (lastDroppedIndex !== null) {
      const timer = setTimeout(() => {
        setLastDroppedIndex(null);
      }, 2000); // 2000ms or 2 seconds to match the CSS transition duration

      return () => clearTimeout(timer);
    }
  }, [lastDroppedIndex]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <Grid
            container
            spacing={1}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <Grid item xs={12} className="common-fields">
              <CommonFields token={csrfToken} />
            </Grid>

            <Grid item xs={12} className="attributes-list-title">
              <AttributesListTitle />
            </Grid>

            {infoTexts.map((infoText: any, index: number) => (
              <Grid item xs={12} key={index}>
                <Draggable draggableId={index.toString()} index={index}>
                  {(provided, snapshot) => (
                    <Box
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      display="flex"
                      alignItems="center"
                      ref={provided.innerRef}
                      className={` shiftedGrid draggableBox ${
                        snapshot.isDragging ? 'isDragging' : ''
                      } ${index === lastDroppedIndex ? 'highlighted' : ''} `}
                    >
                      <StandardAttributeBloc
                        index={index}
                        infoText={infoText}
                        isEven={index % 2 === 0}
                        onRemove={handleRemoveAttribute}
                      />
                    </Box>
                  )}
                </Draggable>
              </Grid>
            ))}
            <Grid item xs={12}>
              {provided.placeholder}
            </Grid>

            {children && (
              <Grid item xs={12}>
                {children}
              </Grid>
            )}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};
