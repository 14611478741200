export const getRows = (
  productAggregations: any[],
  source: any,
  dynamicDataSourcesToFilterOn: any[]
) => {
  let dataGridRows: any[] = [];
  if (source) {
    //In this case, we just need the source
    //We are NOT retrieving the quantities, volume of energy,
    //We are getting the dynamic data that are related to the standard
    dataGridRows = [...(productAggregations as any[])].filter(
      (ag) => ag.source === source
    );
  } else {
    //In this case, we need the dynamic sources to avoid to add them in the array
    //We are retrieving the quantities, volume of energy,
    //We are NOT retrieving the dynamic data that are related to the standard
    dataGridRows = [...(productAggregations as any[])].filter(
      (ag) => !dynamicDataSourcesToFilterOn.includes(ag.source)
    );
  }
  return dataGridRows;
};
