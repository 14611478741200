import { useEffect, useState } from 'react';

import { getFullOrigin } from 'components/Administration/Generic/Form/utils/utils';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';

const useUnit = () => {
  const [unit, setUnit] = useState<string>('kWh');
  const { issueCertificateData, products } = useAdministrationSlicesCombiner();
  useEffect(() => {
    const selectedOrigin = getFullOrigin(
      issueCertificateData?._origin?._id,
      products as any[]
    );
    selectedOrigin && setUnit(selectedOrigin?.unit);
  }, [issueCertificateData?._origin, products]);
  return { unit };
};

export default useUnit;
