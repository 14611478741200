import { useTheme } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { RightsCollapsedSectionTitlePropsType } from './types';

function getStyles(theme: any, expandOpen: boolean) {
  return {
    transform: expandOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  };
}

export const RightsCollapsedSectionTitle = ({
  category,
  expanded,
  capitalizeFirstLetter,
  handleExpandClick,
}: RightsCollapsedSectionTitlePropsType): JSX.Element => {
  const classes = useTheme();
  return (
    <Typography>
      <IconButton
        aria-label="expand-more"
        style={getStyles(classes, expanded)}
        onClick={() => handleExpandClick(category)}
        aria-expanded={expanded}
      >
        <ExpandMoreIcon />
      </IconButton>
      {capitalizeFirstLetter(`${category}:`)}
    </Typography>
  );
};
