/* eslint-disable */

import moment from 'moment';
import { AnyAction } from 'redux';
import { multipleFilter } from '../../utils/arrayHelper';
import { CertificatesReducerStateType } from './types';

const INITIAL_STATE: CertificatesReducerStateType = {
  currentCertificate: {
    assetId: 0,
    powerInW: null,
    productionDate: moment()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .subtract(1, 'days')
      .toDate(),
  },
  currentCertificateSecretWord: {
    issuer: '',
    from: '',
    client: '',
    origin: '',
    originField: '',
    issuerSecretWord: '',
    fromSecretWord: '',
    clientSecretWord: '',
    to: null,
    toSecretWord: null,
  },
  certificates: [],
  allCertificates: [],
  selectedStandardId: undefined,
  selectedProductTypeId: undefined,
  selectedStandardType: undefined,
  error: { message: '', isVisible: false },
  loading: false,
  loadingSecretWord: false,
  retiringCertificateId: null,
  retiringCertificateTxHash: null,
  isCertificateAuthentic: 'unknown',
  selected: [],
  rowsPerPage: 10,
  dialogMatchingDateOpen: false,
  dialogMatchingWarningOpen: false,
  dialogSecretWordOpen: false,
  dialogLoadingPdfOpen: false,
  matchingDate: moment().subtract(1, 'days').toDate(),
  dialogProductionDateOpen: false,
  loadingMatching: false,
  newOwner: {
    address: null,
    name: null,
  },
  loadingTransferCertificate: false,
  generateCertificatesChecked: false,
  forceCertificatesGenerationChecked: false,
  loadingCheckCertificates: false,
  existingCertificates: null,
  cancelCertificateFormOpen: false,
  displayCancelCertificateFormInDialog: true,
  loadingCancelCertificate: false,
  cancelComment: '',
  paginatedCertificatesCount: 0,
  allCertificatesCount: 0,
  defaultCertificatesPaginationThreshold: 500,
};

const certificatesReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case 'SET_CERTIFICATES_STANDARD':
      let certificateStandardIdFilter = action.payload;
      let standardFilters = [
        { filter: 'productType', value: state.selectedProductTypeId },
        { filter: 'standard', value: certificateStandardIdFilter },
        { filter: 'standardType', value: undefined },
      ];

      let standardFilteredCertificates = multipleFilter(
        state.allCertificates,
        standardFilters
      );

      return {
        ...state,
        selectedStandardId: certificateStandardIdFilter,
        certificates: standardFilteredCertificates,
      };

    case 'SET_CERTIFICATES_PRODUCT_TYPE':
      let certificateProductTypeIdFilter = action.payload;

      let productTypefilters = [
        { filter: 'productType', value: certificateProductTypeIdFilter },
        { filter: 'standard', value: state.selectedStandardId },
        { filter: 'standardType', value: state.selectedStandardType },
      ];

      let productTypefilteredCertificates = multipleFilter(
        state.allCertificates,
        productTypefilters
      );
      return {
        ...state,
        selectedProductTypeId: action.payload,
        certificates: productTypefilteredCertificates,
      };

    case 'SET_CERTIFICATES_STANDARD_TYPE':
      let certificateStandardTypeFilter = action.payload;
      let standardTypeFilters = [
        { filter: 'productType', value: state.selectedProductTypeId },
        { filter: 'standard', value: undefined },
        { filter: 'standardType', value: certificateStandardTypeFilter },
      ];

      let standardTypeFilteredCertificates = multipleFilter(
        state.allCertificates,
        standardTypeFilters
      );

      return {
        ...state,
        selectedStandardType: action.payload,
        certificates: standardTypeFilteredCertificates,
      };

    case 'TOGGLE_SECRET_WORD_DIALOG_CERTIFICATES':
      const dialogSecretWordOpen = !state.dialogSecretWordOpen;
      return { ...state, dialogSecretWordOpen };

    case 'TOGGLE_LOADING_PDF_DIALOG_CERTIFICATES':
      const dialogLoadingPdfOpen = !state.dialogLoadingPdfOpen;
      return { ...state, dialogLoadingPdfOpen };

    case 'CERTIFICATE_LOADING_PDF_START':
      return { ...state, dialogLoadingPdfOpen: true };

    case 'CERTIFICATE_LOADING_PDF_SUCCESS':
      return { ...state, dialogLoadingPdfOpen: false };

    case 'TRANSFERING_CERTIFICATE_START':
      return { ...state, loadingTransferCertificate: true };

    case 'TRANSFERING_CERTIFICATE_SUCCESS':
      let certificate = action.payload;
      let _newOwner = state.currentCertificate?.owners?.find((user: any) => {
        return user.address === certificate.owner;
      });

      return {
        ...state,
        loadingTransferCertificate: false,
        currentCertificate: {
          ...state.currentCertificate,
          owner: _newOwner.address,
          ownerName: _newOwner.offchainData.user_metadata.organization,
        },
      };

    case 'TRANSFERING_CERTIFICATE_FAIL':
      return { ...state, loadingTransferCertificate: false };

    //Getting certificates
    case 'GET_CERTIFICATES_START':
      return { ...state, loading: true };

    case 'GET_CERTIFICATES_SUCCESS':
      return {
        ...state,
        certificates: action.payload,
        allCertificates: action.payload,
        loading: false,
      };

    case 'GET_CERTIFICATES_FAIL':
      return { ...state, loading: false };

    case 'CANCEL_CERTIFICATE_START':
      return { ...state, loadingCancelCertificate: true };

    case 'CANCEL_CERTIFICATE_SUCCESS':
      const retiredCertificateId = action.payload;
      const updatedCertificates = state.certificates?.map((certificate) => {
        if (certificate.id === retiredCertificateId) {
          certificate.cancelled = true;
        }
        return certificate;
      });
      return {
        ...state,
        certificates: updatedCertificates,
        retiringCertificateId: null,
        loadingCancelCertificate: false,
      };

    case 'CANCEL_CERTIFICATE_FAIL':
      return {
        ...state,
        retiringCertificateId: null,
        loadingCancelCertificate: false,
      };

    case 'RETIRE_CERTIFICATE_FROM_TRANSACTION_HASH_START':
      return { ...state, loadingCancelCertificate: true };

    case 'CANCEL_CERTIFICATE_FROM_TRANSACTION_HASH_SUCCESS':
      const retiredCertificateTxHash = action.payload;
      const updatedCertificatesWithTxHash = state.certificates?.map(
        (certificate) => {
          if (certificate.txHash === retiredCertificateTxHash) {
            certificate.cancelled = true;
          }
          return certificate;
        }
      );
      return {
        ...state,
        certificates: updatedCertificatesWithTxHash,
        retiringCertificateTxHash: null,
        loadingCancelCertificate: false,
      };

    case 'CANCEL_CERTIFICATE_FROM_TRANSACTION_HASH_FAIL':
      return {
        ...state,
        retiringCertificateTxHash: null,
        loadingCancelCertificate: false,
      };

    case 'CANCEL_COMMENT_CHANGED':
      return {
        ...state,
        cancelComment: action.payload,
      };

    case 'OPEN_CANCEL_CERTIFICATE_FORM':
      return {
        ...state,
        cancelCertificateFormOpen: true,
        displayCancelCertificateFormInDialog: true,
        retiringCertificateId: action.payload,
      };

    case 'CLOSE_CANCEL_CERTIFICATE_FORM':
      return {
        ...state,
        retiringCertificateId: null,
        retiringCertificateTxHash: null,
        cancelCertificateFormOpen: false,
        displayCancelCertificateFormInDialog: true,
      };

    case 'DISPLAY_WARNING_CANCEL_CERTIFICATE':
      return { ...state, displayCancelCertificateFormInDialog: false };

    case 'DISPLAY_FORM_CANCEL_CERTIFICATE':
      return { ...state, displayCancelCertificateFormInDialog: true };

    //Loading the certificate secret word
    case 'LOAD_SECRET_WORD_START':
      const loadCertificateSecretWord = {
        productionAssetSecretWord: 'loading...',
        consumptionAssetSecretWord: '',
      };

      return {
        ...state,
        currentCertificateSecretWord: loadCertificateSecretWord,
        loadingSecretWord: true,
      };

    case 'LOAD_SECRET_WORD_SUCCESS':
      const successCertificateSecretWord = {
        issuer: action.payload.issuer,
        from: action.payload.from,
        client: action.payload.client,
        to: action.payload.to,
        origin: action.payload.origin,
        originField: action.payload.originField,
        issuerSecretWord: action.payload.issuerSecretWord,
        fromSecretWord: action.payload.fromSecretWord,
        clientSecretWord: action.payload.clientSecretWord,
        toSecretWord: action.payload.toSecretWord,
      };

      return {
        ...state,
        currentCertificateSecretWord: successCertificateSecretWord,
        loadingSecretWord: false,
      };

    case 'LOAD_SECRET_WORD_FAIL':
      const failCertificateSecretWord = {
        issuerSecretWord: 'An error has occured',
        fromSecretWord: '',
        clientSecretWord: '',
        issuer: 'An error has occured',
        from: '',
        client: '',
      };
      return {
        ...state,
        currentCertificateSecretWord: failCertificateSecretWord,
        loadingSecretWord: false,
      };

    case 'LOGOUT_SUCCESS':
      return INITIAL_STATE;

    case 'GET_CERTIFICATES_TOTAL_COUNT_SUCCESS':
      return {
        ...state,
        paginatedCertificatesCount: action.payload,
        loading: false,
      };
    case 'GET_ALL_CERTIFICATES_TOTAL_COUNT_SUCCESS':
      return {
        ...state,
        allCertificatesCount: action.payload,
        loading: false,
      };

    case 'SET_DEFAULT_CERTIFICATES_PAGINATION_THRESHOLD':
      return {
        ...state,
        defaultCertificatesPaginationThreshold: action.payload,
      };

    default:
      return state;
  }
};

export default certificatesReducer;
