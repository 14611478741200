import { defaultPeriodValue } from 'components/CreateOrUpdateProduct/utils/utils';
import moment from 'moment';
import { DefaultProductType } from 'reducers/productsReducer/types';

export const initialValuesFormHandler = (
  prodSourcesList: any[] | undefined,
  currentProduct: DefaultProductType | undefined
) => {
  return {
    source:
      prodSourcesList && prodSourcesList[0] ? prodSourcesList[0].value : '',
    sourceID:
      currentProduct && currentProduct.productId
        ? currentProduct.productId
        : '',
    startDate: moment().subtract(5, 'month').format('YYYY-MM-DD'),
    periodNumber: 1,
    periodType: defaultPeriodValue,
    aggregationPeriodNumber: 1,
    aggregationPeriodType: defaultPeriodValue,
  };
};

export const onValidateFormHandler = (values: any) => {
  const errors: any = {};
  if (!values.source) {
    errors.source = 'Required';
  }
  if (!values.sourceID) {
    errors.sourceID = 'Required';
  }
  if (!moment(values.startDate).isValid()) {
    errors.startDate = 'Required';
  }
  const numberRegEx = /^\d*$/;
  if (!values.periodNumber) {
    errors.periodNumber = 'Required';
  } else if (!numberRegEx.test(String(values.periodNumber).toLowerCase())) {
    errors.periodNumber = 'Invalid number';
  } else if (values.periodNumber === 0) {
    errors.periodNumber = 'Number cannot be null';
  }
  if (!values.periodType) {
    errors.periodType = 'Required';
  }
  if (!values.aggregationPeriodNumber) {
    errors.aggregationPeriodNumber = 'Required';
  } else if (
    !numberRegEx.test(String(values.aggregationPeriodNumber).toLowerCase())
  ) {
    errors.aggregationPeriodNumber = 'Invalid number';
  } else if (values.aggregationPeriodNumber === 0) {
    errors.aggregationPeriodNumber = 'Number cannot be null';
  }
  if (!values.aggregationPeriodType) {
    errors.aggregationPeriodType = 'Required';
  }
  return errors;
};
