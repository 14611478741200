import { CardHeader } from '@mui/material';

import standardIcon from '../../../../../assets/images/icons/agreement-uploaded.png';

const StandardCardHeader = () => {
  return (
    <CardHeader
      avatar={<img src={standardIcon} style={{ maxHeight: 20 }} />}
      title="Standard information"
    />
  );
};

export default StandardCardHeader;
