import { Button, DialogActions } from '@mui/material';
import { toggleWarningDialog, setNominationsRequestsStart } from 'actions';
import LoadingCircle from 'components/commons/LoadingCircle';
import useNominationsGuards from 'components/Nominations/Redux/CustomHooks/useNominationsGuards';
import useNominationsSlicesCombiner from 'components/Nominations/Redux/Slices/useNominationsSlicesCombiner';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const NominationsDialogActions = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const {
    loading,
    nominationsRequestsToDo,
    nominationsRequestsToDoLength,
    selectedSellerOrganization,
    year,
    isWarningDialogOpen,
    buyerOrganization,
    user,
  } = useNominationsSlicesCombiner();
  const { canManageMultipleOrganizationNominations } = useNominationsGuards();

  let organizationToUse: any, organizationUnit: string;
  if (isWarningDialogOpen) {
    organizationToUse = canManageMultipleOrganizationNominations
      ? buyerOrganization
      : user.user_metadata.organization;

    if (organizationToUse && organizationToUse?.unit) {
      organizationUnit = organizationToUse?.unit;
    }
  }
  return (
    <DialogActions>
      <Button
        variant="outlined"
        onClick={() => dispatch(toggleWarningDialog())}
        color="primary"
        component="span"
        style={{
          marginRight: 20,
          border: '1px solid rgba(248,119,51, 0.5)',
          borderRadius: 4,
        }}
      >
        {t('Close')}
      </Button>
      <Button
        type="submit"
        color="secondary"
        variant="contained"
        onClick={() => {
          dispatch(
            setNominationsRequestsStart(
              nominationsRequestsToDo,
              organizationToUse._id,
              selectedSellerOrganization._id,
              year,
              organizationUnit
            )
          );
        }}
        disabled={loading || nominationsRequestsToDoLength === 0}
      >
        {t('Validate your requests')}
      </Button>
      {loading && <LoadingCircle />}
    </DialogActions>
  );
};

export default NominationsDialogActions;
