import React from 'react';

import { CircularProgress, DialogContent } from '@mui/material';
import { styled } from '@mui/system';
import { CurrentCertificateSecretWordType } from 'reducers/certificatesReducer/types';

import { getSecretWordInputs, isValidKey } from '../../utils/utils';
import SecretWordInput from '../Common/SecretWordInput/SecretWordInput';

type TransactionsDialogContentProps = {
  loadingSecretWord: boolean;
  currentCertificateSecretWord: CurrentCertificateSecretWordType;
};

const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const TransactionsDialogContent = ({
  loadingSecretWord,
  currentCertificateSecretWord,
}: TransactionsDialogContentProps) => {
  const secretWordInputs = getSecretWordInputs(currentCertificateSecretWord);

  return (
    <StyledDialogContent>
      {loadingSecretWord && (
        <CircularProgress size={16} style={{ paddingLeft: 10 }} />
      )}

      {!loadingSecretWord &&
        secretWordInputs.map((input) => {
          let key: string = input.label.toLowerCase();
          if (key === 'product id') key = 'origin';
          return (
            isValidKey(key) &&
            currentCertificateSecretWord[key] && (
              <SecretWordInput
                label={input.label}
                value={input.value}
                message={input.message}
              />
            )
          );
        })}
    </StyledDialogContent>
  );
};

export default TransactionsDialogContent;
