import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { closeGoToAdministrationAfterAddingProductModal } from 'actions';
import useCreateOrUpdateProductSlicesCombiner from 'components/CreateOrUpdateProduct/Redux/Slices/useCreateOrUpdateProductSlicesCombiner';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';

const AddDataProductDataDialog = () => {
  const dispatch: AppDispatch = useDispatch();
  const { displayGoToAdministrationAfterAddingProductModal } =
    useCreateOrUpdateProductSlicesCombiner();

  const navigate = useNavigate();

  const handleGoToProductsAndCloseModal = () => {
    dispatch(closeGoToAdministrationAfterAddingProductModal());
    navigate('/products/');
  };

  const handleGoToAdministrationAndCloseModal = () => {
    dispatch(closeGoToAdministrationAfterAddingProductModal());

    navigate('/operations');
  };
  return (
    <Dialog
      fullScreen={false}
      open={displayGoToAdministrationAfterAddingProductModal as boolean}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Add product data ?</DialogTitle>

      <DialogContent>
        <Typography variant="body2" gutterBottom>
          Do you want to add data manually right now ?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleGoToProductsAndCloseModal} color="primary">
          Later
        </Button>
        <Button
          onClick={handleGoToAdministrationAndCloseModal}
          color="primary"
          variant="contained"
        >
          yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDataProductDataDialog;
