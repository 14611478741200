import { displayWarningInIncreaseDialog } from 'actions';
import { getFullOrigin } from 'components/Administration/Generic/Form/utils/utils';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { FormikValues } from 'formik';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const useIncreaseBalanceHandlers = () => {
  const { products, organizations } = useAdministrationSlicesCombiner();
  const dispatch: AppDispatch = useDispatch();

  const onSubmitHandler = (values: FormikValues) => {
    const fullOrigin = getFullOrigin(values?.origin, products as any[]);
    const fullOrganization = organizations?.find(
      (org: any) => org._id === values.organization
    );
    const currentIncreaseBalanceData = {
      ...values,
      origin: fullOrigin,
      organization: fullOrganization,
    };
    dispatch(displayWarningInIncreaseDialog(currentIncreaseBalanceData));
  };
  return { onSubmitHandler };
};

export default useIncreaseBalanceHandlers;
